import {createSelector} from "reselect";
import {activeCurrencyPairsSelector} from "gui-common/orm/ormSelectors";
import {activeClientBankAccountsSelector} from "xpool-gui-common/features/client/clientSelectors";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {entityIsActive} from "gui-common/audit/auditFunctions";


const currencyPairs = state => activeCurrencyPairsSelector(state);
export const simulatorAvailableCurrencyPairsSelector = createSelector(
    [currencyPairs],
    (currencyPairs) => {
        return currencyPairs;
    }
);
export const simulatorAvailableTenorsSelector = createSelector(
    [],
    () => {
        return ['TD', 'TM', "SP"];
    }
);

const activeAccounts = state => activeClientBankAccountsSelector(state);
const activeCurrencyPairs = state => activeCurrencyPairsSelector(state);
export const marketSimulatorActiveConfigurationsSelector = createSelector(
    [activeAccounts, activeCurrencyPairs],
    (activeAccounts, activeCurrencyPairs) => {
        if (!activeCurrencyPairs || !activeAccounts) return [];
        let configArray = [];

        const getCurrencyPairs = (ccyId1, ccyId2) => {
            let returnArray = [];
            const pair1 = activeCurrencyPairs.find(ccyPair => (ccyPair.baseCurrencyId === ccyId1) && (ccyPair.quoteCurrencyId === ccyId2));
            const pair2 = activeCurrencyPairs.find(ccyPair => (ccyPair.baseCurrencyId === ccyId2) && (ccyPair.quoteCurrencyId === ccyId1));
            if (pair1) returnArray.push(pair1);
            if (pair2) returnArray.push(pair2);
            return returnArray;
        }

        const addConfig = (currencyPair, bankAccount) => {

            if (configArray.find(item =>
                (item.sessionId === bankAccount.legalEntityUnit.fxProvider.sessionID) &&
                (item.currencyPair === currencyPair.key) &&
                (item.tenor === 'TD') )
            ) {
                return;
            }

            configArray.push({
                sessionId: bankAccount.legalEntityUnit.fxProvider.sessionID,
                currencyPairId: currencyPair.id,
                tenor: "TD",
                fixAccount: "",
            });
        };

        for (let bankAccount of activeAccounts) {
            if (!bankAccount.bankAccountRelations?.length) continue;
            for (let bar of bankAccount.bankAccountRelations) {
                const counterPartyAccount = activeAccounts.find(account => account.id === bar.counterpartyAccountId);
                if (!counterPartyAccount) {
                    continue;
                }
                const ccyPairs = getCurrencyPairs(bankAccount.currency.id, counterPartyAccount.currency.id);
                for (const ccyPair of ccyPairs) {
                    addConfig(ccyPair, bankAccount);
                }
            }
        }
        return configArray;
    }
);
export const fixAccountsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector legalEntityUnitsSelector running ");

        const fixAccounts = session.LegalEntityUnit.filter(entityIsActive).orderBy('id', 'desc').toRefArray().map(leu => leu.fxShortName);

        return fixAccounts;
    }
);
