import {
    SET_APP_LANGUAGE,
} from "gui-common/appLocale/oldLang/appLocaleReducer";

import {
    SET_AMOUNT_DECIMALS,
    SET_AMOUNT_DENOMINATOR,
} from "gui-common/numberFormat/numberFormatReducer";

import {
    USER_LOGOUT
} from "gui-common/api/webSessionReducer";
import cloneDeep from "lodash/cloneDeep";
import {getAppSpecificUserPreferencesInitialState} from "appConfig/appConfig";
import {XP_TRANSLATED_SET_ACTIVE_LANGUAGE} from "gui-common/appLocale/xpTranslated/xpTranslatedReducer";

export const USER_PREFERENCES_RELOAD_STATE = 'USER_PREFERENCES_RELOAD_STATE';
export const USER_PREFERENCES_RESET_STATE  = 'USER_PREFERENCES_RESET_STATE';
export const USER_PREFERENCES_SET_PROP  = 'USER_PREFERENCES_SET_PROP';
export const SET_ACTIVE_CLIENT           = 'SET_ACTIVE_CLIENT';


export function userPreferencesReloadState(newState) {
    return {type: USER_PREFERENCES_RELOAD_STATE, payload: newState}
}
export function userPreferencesResetState() {
    return {type: USER_PREFERENCES_RESET_STATE}
}
export function userPreferencesSetProp(name, value) {
    return {type: USER_PREFERENCES_SET_PROP, payload: {name: name, value: value}}
}

export function setActiveClient(client) {
    return { type: SET_ACTIVE_CLIENT,       payload: client ? client.id : null};
}
function getUserPreferencesInitialState() {
    return {
        activeClientId          : null,
        amountDenominator       : 1,
        amountDecimals          : 0,
        activeLanguage          : 'en',
        homeCurrency            : undefined,
        ...getAppSpecificUserPreferencesInitialState(),
    }
}

export function userPreferencesReducer(state = getUserPreferencesInitialState(), action)
{
    switch (action.type) {
        case USER_PREFERENCES_SET_PROP:
            let newState = cloneDeep(state);
            newState[action.payload.name] = action.payload.value;
            return newState;
        case USER_PREFERENCES_RELOAD_STATE:
            return action.payload;
        case USER_PREFERENCES_RESET_STATE:
            return getUserPreferencesInitialState();
        case USER_LOGOUT:
            if (action.payload.status !== "LOGOUT") {
                console.error("User logout. Wrong Status: " + action.payload.status);
            }
            return getUserPreferencesInitialState();
        case SET_AMOUNT_DENOMINATOR:
            return {...state, amountDenominator: action.payload};
        case SET_AMOUNT_DECIMALS:
            return {...state, amountDecimals: action.payload};
        case XP_TRANSLATED_SET_ACTIVE_LANGUAGE:
            return {...state, activeLanguage: action.payload};
        case SET_ACTIVE_CLIENT:
            return {...state, activeClientId: action.payload ? action.payload : undefined};
        default:
            return state;
    }
}
