import React, { Component } from 'react'
import {connect} from "react-redux"
import {NavItem, NavDropdown} from "react-bootstrap";

import {addUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import {hideContextMenu} from "gui-common/contextMenu/contextMenuReducer";
import {launchIntegrationAction} from "gui-common/integrationStatus/integrationStatusThunks";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


export class IntegrationStatusItemMenu extends Component {

    onActionClicked (action) {
        this.props.addUserMessageThunk("info", "userMessages.info.contextMenuTest", {name: action.description});
        this.props.launchIntegrationAction(action);
        this.props.hideContextMenu();
    };

    render() {
        return (

            <ul className="dropdown-menu" style={{display: 'inline'}} id="contextMenuDiv">
{/*
                <li><a >{this.props.statusItem.id}</a></li>
*/}
                <li>{this.props.statusItem.id}</li>
                <li className="divider"></li>
                <NavDropdown
                    disabled={!this.props.statusItem.actions.length}
                    title={this.props.translate('integrationStatus.contextMenu.actions')}
                    id="integrationStatusCM1"
                >
                    {this.props.statusItem.actions.map((action, index) =>
                    <NavItem key={index}>
                        <span onClick={() => this.onActionClicked(action)}>{action.description}</span>
                    </NavItem>
                    )}
                </NavDropdown>
            </ul>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        translate:          selectTranslateFunction(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideContextMenu:        ()                      => dispatch(hideContextMenu()),
        addUserMessageThunk:     (type, textPath, parameters)  => dispatch(addUserMessageThunk(type, textPath, parameters)),
        launchIntegrationAction:(action)                => dispatch(launchIntegrationAction(action)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationStatusItemMenu);
