import React, { Component } from 'react'
import PropTypes from 'prop-types';
import removeIcon from 'gui-common/resources/removeIcon.png'
import XpTooltip from "gui-common/components/XpTooltip";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";


class UserTopicsConfigTable extends Component {

    topicRow (index, topic) {
        if (!topic) return null;
        return (
            <tr key = {index}>
                <td style={{border: 'none', paddingBottom: '2px'}}>
                    {this.props.disabled ? null :
                        <div className="tableField">
                            <XpTooltip trKey='userTopicAdmin.removeTopicTooltip' trParams={{topic: topic}}>
                                <img style={{height: '18px', marginTop: "2px" }} src={removeIcon} alt="Remove" className="closeDashboardComponentButton" onClick={() => this.props.deleteTopicCallback(topic)}/>
                            </XpTooltip>
                        </div>}
                </td>
                <td style={{paddingLeft: '5px', border: 'none'}}>
                    <div>
                        <XpTranslated trKey={"userTopicAdmin.topicToAdd.topics." + topic}/>
                    </div>
                </td>
            </tr>
        )
    }

    render() {
        if (!this.props.user) return null;
        let settingsArray = this.props.user.topics ? this.props.user.topics : [];
        const tableWidth = "100%";
        return (
            <div className={this.props.className}>
                <table style={{width: tableWidth, marginBottom: '5px'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '25px', border: 'none'}}            > </td>
                        <td style={{width: 'calc(100%-25px)', border: 'none'}} > </td>
                    </tr>
                    {settingsArray.map((topic, index) => this.topicRow(index, topic))}
                    </tbody>
                </table>
            </div>
        );
    }
}

UserTopicsConfigTable.propTypes = {
    user:                PropTypes.object.isRequired,
    deleteTopicCallback: PropTypes.func.isRequired,
    translate:           PropTypes.func.isRequired,
    className:           PropTypes.string,
    disabled:            PropTypes.bool
};

UserTopicsConfigTable.defaultProps = {
    className: undefined,
    disabled : false,
};

export default UserTopicsConfigTable;

