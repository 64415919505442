import {createSelector} from "reselect";
import {xpGridsReloadState, xpGridsResetState} from "gui-common/xpGrid/xpGridsReducer";
import {dashboardReloadState, dashboardResetState} from "gui-common/dashboard/dashboardReducer";
import {
    integrationStatusReloadState,
    integrationStatusResetState
} from "gui-common/integrationStatus/integrationStatusReducer";
import {xpAccordionReloadState, xpAccordionResetState} from "gui-common/xpAccordion/xpAccordionReducer";
import {userPreferencesReloadState, userPreferencesResetState} from "gui-common/userSettings/userPreferencesReducer";
import {reloadSavedUserState, resetSavedUserState} from "gui-common/userSettings/sharedSettings/savedUserStatesReducer";
import {getAppSpecificUserStates} from "appConfig/appConfig";
import {xpTabsReloadState, xpTabsResetState} from "gui-common/components/xpTabs/xpTabsReducer";

export const userStatesSelector = createSelector(
    [],
    () => {
        return {
            "xpGridsState"           : {reloadFunction: xpGridsReloadState           , resetFunction: xpGridsResetState           },
            "dashboardState"         : {reloadFunction: dashboardReloadState         , resetFunction: dashboardResetState         },
            "integrationStatusState" : {reloadFunction: integrationStatusReloadState , resetFunction: integrationStatusResetState },
            "xpAccordionState"       : {reloadFunction: xpAccordionReloadState       , resetFunction: xpAccordionResetState       },
            "xpTabsState"            : {reloadFunction: xpTabsReloadState            , resetFunction: xpTabsResetState            },
            "userPreferencesState"   : {reloadFunction: userPreferencesReloadState   , resetFunction: userPreferencesResetState   },
            "savedUserStatesState"   : {reloadFunction: reloadSavedUserState         , resetFunction: resetSavedUserState         },
            ...getAppSpecificUserStates(),
        };
    }
);
