import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from "react";
import {Resizable} from "re-resizable";
import {useResizeObserver} from "gui-common/functions/hooks";
import {animated, useSpring} from "@react-spring/web";

function XpSplitPane (props) {
    const [primaryWidth   , setPrimaryWidth   ] = useState();
    const [maxSizePrimary, setPrimaryMaxWidth] = useState();
    const [containerWidth , setContainerWidth ] = useState();
    const [managed, setManaged] = useState(false);


    const observerCallback = useCallback(box => {
        if (containerWidth === box.width) {
            // No need to update!
            return;
        }
        // console.log("Container observer: ", box);
        setContainerWidth(box.width);
    }, []);
    const containerRef = useResizeObserver(observerCallback);

    const minSizePrimary   = props.minSizePrimary   ? props.minSizePrimary   : 50;
    const minSizeSecondary = props.minSizeSecondary ? props.minSizeSecondary : 50;


    function calculateSecondaryWidth(setWidth) {
        if (props.maxSizeSecondary && (setWidth > props.maxSizeSecondary)) return props.maxSizeSecondary;
        if (setWidth <= minSizeSecondary) return minSizeSecondary;
        return setWidth;
    }
    function calculatePrimaryWidthFromResize(setWidth) {
        if (setWidth > maxSizePrimary) return maxSizePrimary;
        if (setWidth < minSizePrimary ) return minSizePrimary;
        return setWidth;
    }
    function calculatePrimaryMaxWidth() {
        const fromMinSizeSecondary = containerWidth - minSizeSecondary - 1;
        const maxSize = props.maxSizePrimary ? Math.min(fromMinSizeSecondary, props.maxSizePrimary) : fromMinSizeSecondary;
        return Math.max(minSizePrimary, maxSize)
    }

    function getDefaultWidthPrimary() {
        if (props.defaultWidthPercent) {
            return containerWidth * props.defaultWidthPercent / 100;
        }
        if (props.defaultWidthPixels) {
            return ((containerWidth - props.defaultWidthPixels) > minSizeSecondary) ? props.defaultWidthPixels : (containerWidth - minSizeSecondary);
        }
        if (props.defaultWidthSecondaryPixels) {
            return ((containerWidth - props.defaultWidthSecondaryPixels) > minSizePrimary) ? (containerWidth - props.defaultWidthSecondaryPixels) : minSizePrimary;
        }
        return containerWidth * 0.5;
    }

    useEffect(
        () => {
            if (containerWidth === undefined) {
                return;
            }
            if (props.fixedSecondaryWidth) {
                setPrimaryWidth(containerWidth - props.fixedSecondaryWidth);
                setManaged(false);
                return;
            }
            setPrimaryMaxWidth(calculatePrimaryMaxWidth());
            if (managed) {
                return;
            }
            const setWidth = calculatePrimaryWidthFromResize(getDefaultWidthPrimary());
            setPrimaryWidth(setWidth);
        },
        [containerWidth, props.fixedSecondaryWidth],
    );

    const secondaryWidth = (props.fixedSecondaryWidth) ? props.fixedSecondaryWidth : calculateSecondaryWidth(containerWidth - primaryWidth);

    const {animatedSecondaryWidth} = useSpring({
        from: {animatedSecondaryWidth: secondaryWidth},
        animatedSecondaryWidth: secondaryWidth,
    });
    const {animatedPrimaryWidth} = useSpring({
        from: {animatedPrimaryWidth: primaryWidth},
        animatedPrimaryWidth: primaryWidth,
    });

    function getHandleClassName() {
        return "xpVerticalHandle";
        let base = "xpVerticalHandle";
        if (props.isDisabled) return base + " xpResizableHandleDisabled ";
        return base + " xpResizableHandle ";
    }

    if (props.children?.length !== 2) return null;

    const verticalHandleStyle = {
        position: 'absolute',
        userSelect: 'none',
        width: '12px',
        height: '100%',
        top: '0px',
        right: '-6px',
    };

    return (
        <div style={{overflow: 'auto'}} ref={containerRef} >
            {(primaryWidth !== undefined) &&
            <div style={{width: primaryWidth + secondaryWidth, display: 'flex'}}>
                <Resizable
                    style = {{ display: "inline-block", paddingRight: props.hideDragHandle ? '0px' : '6px'}}
                    size  = {{
                        width: primaryWidth,
                        // width: animatedPrimaryWidth,
                        // width: animatedPrimaryWidth ? animatedPrimaryWidth.to(y => y + 'px') : undefined,
                    }}
                    minWidth={minSizePrimary}
                    maxWidth={props.fixedSecondaryWidth ? undefined : maxSizePrimary}
                    onResize={(e, direction, ref, d) => {
                        // console.log("Resize: ", direction, d, ref.scrollWidth);
                        setPrimaryWidth(ref.scrollWidth - 6);
                        setManaged(true);
                    }}
                    handleComponent={{right: (
                        <div className="xpVerticalHandle"/>
                        )}}
                    handleStyles={{right: verticalHandleStyle}}
                    handleClasses={{
                        // right: getHandleClassName()
                }}
                    enable={{right: !props.isDisabled, top:false, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false}}
                >
                    {props.children[0]}
                    {props.isDisabled && <div className={'xpSplitPaneHandleDisabled'} style={verticalHandleStyle}><div className="xpVerticalHandleDisabled"/></div>}
                </Resizable>
                <div
                    style={{
                        display: "inline-block",
                        paddingLeft: props.hideDragHandle ? '0px' : '6px',
                        // width: animatedSecondaryWidth ? animatedSecondaryWidth.to(y => y + 'px') : undefined,
                        width: secondaryWidth
                    }}
                >
                    {props.children[1]}
                </div>
            </div>}
        </div>
    )
}
XpSplitPane.propTypes = {
    children            : PropTypes.array.isRequired,
    defaultWidthPercent : PropTypes.number,
    defaultWidthPixels  : PropTypes.number,
    defaultWidthSecondaryPixels : PropTypes.number,
    maxSizePrimary      : PropTypes.number,
    maxSizeSecondary    : PropTypes.number,
    minSizePrimary      : PropTypes.number,
    minSizeSecondary    : PropTypes.number,
    fixedSecondaryWidth : PropTypes.number,
    isDisabled          : PropTypes.bool,
    hideDragHandle      : PropTypes.bool,
};
export default XpSplitPane
