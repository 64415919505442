import React, {Component} from "react";
import {connect} from "react-redux";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getClientWithLegalEntitiesSelector} from "xpool-gui-common/features/client/clientSelectors";
import EprConnectStatusButton from "gui-common/erpIntegration/ErpConnectStatusButton";

class ErpStatusCellRenderer extends Component {

/*
    constructor(props) {
        super(props);
    }
*/

    refresh(params) {
        return true;
    }

    render () {
        if (!this.props.data) return null;
        return (
            <span>
                <ClientErpConnectionsWrapper
                    client = {this.props.data}
                />
            </span>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(ErpStatusCellRenderer);

function ClientErpConnectionsWrapper(props) {
    const client = useSelectorInstance(getClientWithLegalEntitiesSelector, {selectId: props.client?.id});

    if (!client) return null;

    return (
        <div>
            {client.legalEntities.map((le, index) =>
                <span style={{marginRight: '2px'}} key={index}>
                    <EprConnectStatusButton
                        key={le.erpIntegrationId}
                        erpIntegrationId={le.erpIntegrationId}
                        businessObjectName={le.name}
                        noRenderWhenDisabled={true}
                    />
                </span>
            )}
        </div>
    )
}

