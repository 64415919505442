import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {padNumberStringToTwoDigits} from "gui-common/numberFormat/numberFormatFunctions";

export const stringIsOnlySpaces = (val) => val && val.length && !val.replace(/\s/g, '').length;

export const stringContainSpace = (val) => /\s/.test(val);

export const stringContainAlpha = (val) => val && val.length && val.replace(/^[0-9]*$/, '').length;


/*****************************************************************************************************************************
 *
 * Time input related validators and support functions
 *
 *****************************************************************************************************************************/

export const incorrectTimeFormat = (val) => {
    if (!val) return false;
    if (!val.length) return false;

    // Check that there is a colon in the string
    const firstColonIndex = val.indexOf(":");
    if (firstColonIndex === -1) return false;

    // Check that there is only one colon in the string
    const secondColonIndex = val.substring(firstColonIndex+1).indexOf(":");
    if (secondColonIndex !== -1) return true;
};

export const reformatTimeString = (value) => {
    if (!value || (value === "")) return "";
    if (typeof value !== 'string') {
        console.warn("Incorrect type in reformatTimeString", value);
        return "";
    }
    let outString = value;
    const firstColonIndex = value.indexOf(":");
    // Time formatting below. NOTE: We are not VALIDATING here, just formatting on a best effort basis. Validation functions will later validate.
    if (firstColonIndex === -1) {// No colon, i.e. Only hour entered or time without colon
        if      (value.length === 1) outString = "0" + value + ":00";
        else if (value.length === 2) outString =       value + ":00";
        else if (value.length <=  4) outString = padNumberStringToTwoDigits(value.substring(0, value.length-2)) + ":" + value.substring(value.length-2);
    }
    else {
        const hourString   = padNumberStringToTwoDigits(value.substring(0, firstColonIndex));
        const minuteString = padNumberStringToTwoDigits(value.substring(firstColonIndex + 1));
        outString = hourString + ":" + minuteString;
    }
    return outString;
};

export const hourToLarge = (input) => {
    if (incorrectTimeFormat(input)) return false;
    let val = reformatTimeString(input);
    const firstColonIndex = val.indexOf(":");
    let hourString   = val;
    if (firstColonIndex !== -1) hourString = val.substring(0, val.indexOf(":"));
    return (Number(hourString) > 23);
};

export const minuteToLarge = (input) => {
    if (incorrectTimeFormat(input)) return false;
    let val = reformatTimeString(input);
    if (val.indexOf(":") === -1) return false;
    let minuteString   = val.substring(val.indexOf(":") + 1);
    return (Number(minuteString) > 59);
};

const validInput = (val, compare) => {
    if (val     === "") return false;
    if (compare === "") return false;
    if (incorrectTimeFormat(val)) return false;
    if (incorrectTimeFormat(compare)) return false;
    if (hourToLarge(val)) return false;
    if (hourToLarge(compare)) return false;
    if (minuteToLarge(val)) return false;
    if (minuteToLarge(compare)) return false;
    return true;
};
export function isValidTimeInput(val) {
    if (val     === "") return false;
    if (incorrectTimeFormat(val)) return false;
    if (hourToLarge(val)) return false;
    if (minuteToLarge(val)) return false;
    return true;
}

const timeToNumber = (val) => Number(val.replace(/[^0-9]/g, ''));

export const timeEarlierOrSameThanParameter = (input, compare) => {
    // console.log("Earlier val: "+val+" comp: "+compare);
    let val = reformatTimeString(input);
    if (!validInput(val, compare)) return false;
    return timeToNumber(val) <= timeToNumber(compare);
};
export const timeEarlierThanParameter = (input, compare) => {
    // console.log("Earlier val: "+val+" comp: "+compare);
    let val = reformatTimeString(input);
    if (!validInput(val, compare)) return false;
    return timeToNumber(val) < timeToNumber(compare);
};
export const timeLaterThanParameter = (input, compare) => {
    let val = reformatTimeString(input);
    if (!validInput(val, compare)) return false;
    return timeToNumber(val) > timeToNumber(compare);
};
export const timeLaterOrSameThanParameter = (input, compare) => {
    let val = reformatTimeString(input);
    if (!validInput(val, compare)) return false;
    return timeToNumber(val) >= timeToNumber(compare);
};
export const timeRequiredIfRelatedFieldsAreFilled = (val, fieldArray) => {
//    if (incorrectTimeFormat(val)) return false;
//    if (val && val.indexOf(":") !== -1) return false; // valid input
    if (val) return false; // Field is filled.
    if (!fieldArray || !fieldArray.length) return false; // no fields to compare
    return fieldArray.some(time =>
        time !== null && time.indexOf(":") !== -1
    );
};

/*****************************************************************************************************************************
 *
 * Number input related validators and support functions
 *
 *****************************************************************************************************************************/

const str2num = (val, decDenLangState) => stringNumberToFloat(val, decDenLangState);

const inputNumberFieldValid = (val) => {
    if (!val) return false;
    if (!(/\d/.test(val))) return false;
    return true;
};
const inputNumberFieldsValid = (in1, in2) => {
    if (!(/\d/.test(String(in1)))) return false;
    if (!(/\d/.test(String(in2)))) return false;
    return true;
};
export const firstNumberLowerThanSecond = (first, second, checkActive, decDenLangState) => {
    if (!decDenLangState) return false;
    if (!checkActive) return false;
    if (!inputNumberFieldsValid(first, second)) return false;
    return (str2num(first, decDenLangState) < str2num(second, decDenLangState));
};
export const firstNumberHigherThanSecond = (first, second, checkActive, decDenLangState) => {
    if (!decDenLangState) return false;
    if (!checkActive) return false;
    if (!inputNumberFieldsValid(first, second)) return false;
    return (str2num(first, decDenLangState) > str2num(second, decDenLangState));
};
export const numberLargerThanZero = (val, decDenLangState) => {
    if (!decDenLangState) return false;
    if (!inputNumberFieldValid(val)) return false;
    return (str2num(val, decDenLangState) > 0);
};
export const numberLargerThanMaxAmount = (val, maxValue, decDenLangState) => {
    if (!decDenLangState) return false;
    if (!inputNumberFieldValid(val)) return false;
    return (str2num(val, decDenLangState) > maxValue);
};
export const numberLowerThanMinAmount = (val, minValue, decDenLangState) => {
    if (!decDenLangState) return false;
    if (!inputNumberFieldValid(val)) return false;
    return (str2num(val, decDenLangState) < minValue);
};





