import React from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {
    activeCountriesSelector,
} from "gui-common/orm/ormSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormRadioInput from "gui-common/xpForm/core/XpFormRadioInput";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import XpFormCheckboxInput from "gui-common/xpForm/core/XpFormCheckboxInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {xpFormUserCanEditBase} from "gui-common/xpAdminForm/xpAdminFormFunctions";
// import {selectedParentIsChildOrNotActive} from "gui-common/functions/functions";

function LegalEntityBaseStatic (props) {

    const formConfig                = useSelector(state => selectAppEnvProperty(state, 'legalEntityFormConfig'));
    const availableLegalPersonTypes = useSelector(state => selectAppEnvProperty(state, 'availableLegalPersonTypes'));
    const ormCountries              = useSelector(activeCountriesSelector);
    const formContext             = useXpFormContext();
    const formDataLegalPersonType = useXpFormField(formContext.formModel + '.legalPersonType');
    const formDataHasLEI          = useXpFormField(formContext.formModel + '.hasLEI');

    /* // Skipped parent child feature for LE in oxyGen v2.
        const legalEntities = useMemo(
            () => {
                let legalEntities = [];
                if (clientData) legalEntities = clientData.legalEntities;
                else            legalEntities = superEntity && superEntity.legalEntities ? superEntity.legalEntities : [];
                return legalEntities;
            },
            [clientData, superEntity]
        );

        function entityAllowedAsParent(entity) {
            if (entityData.form.id === entity.id) return false;
            // Check if entity can be used as parent without creating circular tree structure or inactive root bankAccount.
            return !(selectedParentIsChildOrNotActive(legalEntities, entity, "parentEntityId", entityData.form.id));
        }
        const availableParentEntities = useMemo(
            () => {
                let returnArray = [];
                legalEntities.forEach((entity) => {
                    if (entityAllowedAsParent(entity)) {
                        let allowedParent = {...entity};
                        returnArray.push(allowedParent);
                    }
                });
                return returnArray
            },
            [legalEntities]
        );
    */

    function fieldIsDisabled() {
        if (props.enforceUseCanEdit) return false;
        return !xpFormUserCanEditBase(formContext, props.ormModel, props.isSubForm);
    }

    return (
        <div className={props.className}>
            <XpFormSelectorInput
                field="countryId"
                selectList={ormCountries}
                itemTextField="country"
                disabled={fieldIsDisabled()}
                isRequired
                inLineLayout noTemplate
            />

            {((availableLegalPersonTypes.length > 1) && !formConfig.fieldsConfig.legalPersonType.hide) &&
            <XpFormRadioInput
                field="legalPersonType"
                radioItems={availableLegalPersonTypes}
                disabled={fieldIsDisabled()}
            />}

            {((formDataLegalPersonType !== "NATURAL") && !formConfig.fieldsConfig.lei.hide) &&
            <div>
                {(formContext.formUseState !== XP_FORM_VIEW) &&
                <XpFormCheckboxInput
                    field= "hasLEI"
                    disabled={fieldIsDisabled()}
                />}

                {((formContext.formUseState === XP_FORM_VIEW) ? (formContext?.currentData?.hasLEI === true) : (formDataHasLEI === true)) &&
                <XpFormTextInput
                    field= "lei"
                    disabled={fieldIsDisabled()}
                    checkNoSpaces={true}
                    isRequired
                    checkOnlySpaces={false}
                    exactLength={20}
                />}
            </div>}

{/* // Skipped parent child feature for LE in oxyGen v2.
            {(availableParentEntities.length > 0) &&
            <FormSelectorInput
                field="parentEntityId"
                formTemplate={this.props.formTemplate}
                formState     = {formState}
                entityData    = {entityData}
                selectList={availableParentEntities}
                itemTextField="name"
                disabled={this.fieldIsDisabled()}
                isRequired={false}
            />}
*/}

        </div>
    );
}

LegalEntityBaseStatic.propTypes = {
    className:        PropTypes.string,
};

LegalEntityBaseStatic.defaultProps = {
    className:  undefined,
};

export default LegalEntityBaseStatic;

