import React, {useEffect} from "react";
import {pushModalWindow} from "redux-promising-modals";
import {useDispatch, useSelector} from "react-redux";
import {getTranslatedTextClassName} from "gui-common/appLocale/appLocaleFunctions"
import {selectIsDevMode} from "gui-common/app/appEnvSelectors";
import {SET_TRANSLATION_DIALOG} from "gui-common/modals/modalConstants";
import {
    replaceParametersInText
} from "gui-common/appLocale/xpTranslated/xpTranslatedFunctions";
import {xpTranslatedLoadTextGenerations} from "gui-common/appLocale/xpTranslated/xpTranslatedReducer";
import {
    useXpTranslatedActiveLanguage, useXpTranslatedDefaultLanguage,
    useXpTranslatedEditTextMode,
    useXpTranslatedLanguages, useXpTranslationObject
} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import PropTypes from "prop-types";


export function XpTranslated (props) {
    const devMode           = useSelector(selectIsDevMode);
    const dispatch          = useDispatch();

    const languages         = useXpTranslatedLanguages();
    const activeLang        = useXpTranslatedActiveLanguage();
    const defaultLang       = useXpTranslatedDefaultLanguage();
    const editTextState     = useXpTranslatedEditTextMode()

    const trObject           = useXpTranslationObject(props.trKey);
    const fallbackTrObject   = useXpTranslationObject(props.fallbackKey);

    useEffect(
        () => {
            if (trObject || fallbackTrObject) {
                return;
            }

            let params = "";
            if (props.trParams) for (let key in props.trParams) params = params + " ${" + key + "}";

            dispatch(xpTranslatedLoadTextGenerations([{
                textKey         : props.trKey,
                languageCode    : defaultLang,
                text            : props.fallBackText ? props.fallBackText : "No text defined for " + props.trKey + '.' + (params ? " Parameters:" + params : ""),
                isNewKey        : true,
            }]));
        },
        [props.trKey],
    );

    if (!languages) {
        return null;
    }

    const editTextMode      = (props.suppressEditTranslation || !props.trKey) ? false : editTextState;

    function onRightClick(e) {
        if (!editTextMode) return;
        e.preventDefault();
        e.stopPropagation();
        dispatch(pushModalWindow(SET_TRANSLATION_DIALOG, {trKey : props.trKey, trParams: props.trParams})).then((result) => {});
    }
    function onDoubleClick(e) {
        if (!devMode) return;
        e.preventDefault();
        e.stopPropagation();
        dispatch(pushModalWindow(SET_TRANSLATION_DIALOG, {trKey : props.trKey, trParams: props.trParams})).then((result) => {});
    }

    const trObjectToUse = trObject ? trObject : fallbackTrObject;
    const codeText = trObjectToUse ? (trObjectToUse[activeLang] ? trObjectToUse[activeLang][0].text : trObjectToUse[defaultLang][0].text) : props.fallBackText;

    if (!codeText) {
        if (props.hideMissingTranslation) {
            return null;
        }
        else {
            return <span>No trKey</span>;
        }
    }
    const displayText = replaceParametersInText(codeText, props.trParams)

    return (
        <span
            onContextMenu={(e) => onRightClick(e)}
            onDoubleClick={(e) => onDoubleClick(e)}
            className={(props.className ? (props.className + " ") : "") + getTranslatedTextClassName(editTextMode, trObjectToUse ? trObjectToUse[activeLang] : undefined)}
            style = {props.spanStyle}
        >
            {displayText}
        </span>
    )
}
XpTranslated.propTypes = {
    trKey       : PropTypes.string,
    trParams    : PropTypes.object,
    fallBackText: PropTypes.string,
    className   : PropTypes.string,
    spanStyle   : PropTypes.object,
    suppressEditTranslation: PropTypes.bool,
};
/*
export function XpTranslated ({trKey, trParams, hideMissingTranslation, fallbackKey, fallBackText, suppressEditTranslation, className, spanStyle}) {
    const translate         = useSelector(selectTranslateFunction);
    const languages         = useSelector(state => getLanguages(state.locale));
    const textGenerations   = useSelector(selectTextGenerations);
    const devMode           = useSelector(selectIsDevMode);
    const dispatch          = useDispatch();

    const editTextModeState = useSelector(selectAppEditTextMode);

    useEffect(
        () => {
            if (!translate || !languages) {
                return;
            }
            createMissingTextGeneration(
                trKey,
                trParams,
                fallBackText,
                translate,
                languages,
                textGenerations,
                textItems => dispatch(setAppTextGeneration(textItems)),
            );
        },
        [],
    );

    if (!translate || !languages) {
        return null;
    }

    const editTextMode      = (suppressEditTranslation || !trKey) ? false : editTextModeState;

    function onRightClick(e) {
        if (!editTextMode) return;
        e.preventDefault();
        e.stopPropagation();
        dispatch(pushModalWindow(SET_TRANSLATION_DIALOG, {trKey : trKey, trParams: trParams})).then((result) => {});
    }
    function onDoubleClick(e) {
        if (!devMode) return;
        e.preventDefault();
        e.stopPropagation();
        dispatch(pushModalWindow(SET_TRANSLATION_DIALOG, {trKey : trKey, trParams: trParams})).then((result) => {});
    }

    let displayText = "";
    if      (trKey)        displayText = translate(trKey, trParams);
    else if (fallbackKey)  displayText = translate(fallbackKey, trParams);
    else if (fallBackText) displayText = replaceParametersInText(fallBackText, trParams);
    else if (hideMissingTranslation) return;
    else return <span>No trKey</span>;

    if ((typeof displayText === 'object') && (displayText !== null)) {
        return displayText;
    }

    if (displayText.includes("Missing translation") && fallbackKey) displayText = translate(fallbackKey, trParams);
    if (displayText.includes("Missing translation") && hideMissingTranslation) return;
    if (displayText.includes("Missing translation") && fallBackText) displayText = replaceParametersInText(fallBackText, trParams);

    return (
        <span
            onContextMenu={(e) => onRightClick(e)}
            onDoubleClick={(e) => onDoubleClick(e)}
            className={(className ? (className + " ") : "") + getTranslatedTextClassName(editTextMode, textGenerations, trKey)}
            style = {spanStyle}
        >
            {displayText}
        </span>
    )
}
*/




