import React from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

function XpFormTextAreaInput (props) {
    return (
        <XpFormLabeledInput
            {...props}

            CompToRender={TextField}
            getValueFromEvent={event => event.target.value}

            placeBeforeTextUnderLabel
            controlProps={{
                multiline: true,
                maxRows  : props.maxRows,
                rows     : props.rows,
            }}
        />
    )
}
XpFormTextAreaInput.propTypes = {
    ...xpFormBasePropTypes,
    maxRows      : PropTypes.number,
    rows         : PropTypes.number,
};
export default XpFormTextAreaInput;
