import React, {useMemo} from "react";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {getCurrencyDashboardClientSettingsFormConfig} from "xpool-gui-common/appConfig/xpAdminFormConfig";

export default function CurrencyDashboardClientSettingsForm(props) {
    const xpAdminFormConfig = useMemo(
        () => {
            return getCurrencyDashboardClientSettingsFormConfig();
        },
        []
    );

    if (!props.client) return null;
    return (
        <XpAdminForm
            entityId              = {props.client.id}
            xpAdminFormInstanceId = 'clientSettingsForm'
            xpAdminFormConfig     = {xpAdminFormConfig}
            ormModel              = 'Client'
            suppressAccordion     = {true}
            hideDeleteButton      = {true}
            // hideEditButton        = {!props.bankAccount.parentAccountId}
        />
    )
}
