import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {createSelector} from "reselect";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getClientOrdersSelector} from "xpool-gui-common/features/order/orderSelectors";
import {getAppEnvPropertySelector} from "gui-common/app/appEnvSelectors";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";

export const getDisableAccountStructureChangeSelector = () => createSelector(
    (state, props) => getClientOrdersSelector()(state, props),
    (state, props) => getAppEnvPropertySelector()(state, 'noFormValidation'),
    (state, props) => props.formContext,
    (clientOrders, noFormValidation, formContext) => {
        if (noFormValidation) {
            return false;
        }
        if (formContext && (formContext.formUseState !== XP_FORM_EDIT)) {
            return false;
        }
        if (!clientOrders?.length) {
            return false;
        }
        return clientOrders.some(item => ['EXECUTED', 'IN_MARKET', 'TO_MARKET'].includes(item.state))
    })

export function useDisableAccountPropertyOnActiveOrders(clientId, formContext) {
    return useSelectorInstance(getDisableAccountStructureChangeSelector, {clientId, formContext})
}

/********* BankAccount cash ladder selectors *****************************************************************/

export const bankAccountsCashLadderSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector bankAccountsLadderSelector running ");
        let returnArray = []

        /*
                let cashedAgreements  = {};

                const listRates = session.ListRate.orderBy('id', 'desc').toRefArray();
                for (let listRate of listRates) {
                    // These lookups consumes a lot of rendering time. With 321 orders in the system, calculation of the list went from 46ms to 9 ms without the three lines below.
                    const agreement       = getOrAddCashedObject(listRate.agreementId  , 'Agreement'  , cashedAgreements  , session);

                    returnArray.push({
                        ...listRate,
                        currencyPair         : agreement ? agreement.currencyPair : undefined,
                        legalEntityUnitId    : agreement ? agreement.legalEntityUnitId : undefined,
                        currencyPairStr      : agreement ? getCurrencyPairString(agreement.currencyPair) : "",
                        gracePeriodT         : listRate.gracePeriod + 's',
                        listRatePeriodT      : listRate.listRatePeriod ? listRate.listRatePeriod.period         : "",
                        dispatchTradePeriodT : listRate.dispatchTradePeriod ? listRate.dispatchTradePeriod.period : "",
                    });
                }
        */
        // console.log("Selected listRatesSelector:", returnArray);
        return returnArray;
    }
);

const projection = (state, props) => props ? props.projection : undefined;
const dates      = (state, props) => props ? props.dates      : undefined;
export const bankAccountsCashLadderListDataSelector = () => createSelector(
    [projection, dates],
    (projection, dates) => {
        // console.log("Selector bankAccountsCashLadderListDataSelector running ");

        let returnArray       = [];
        for (let acc of accArray) {
            returnArray.push({
                id: acc.id,
                name: acc.name,
                currencyStr: acc.currencyStr,
                ...acc.projections[projection],
            });

            /*
                        const newRow = {
                            id: acc.id,
                            name: acc.name,
                            currencyStr: acc.currencyStr,
                        }
                        const cashLadder = acc.projections[projection];
                        let beforeStartDate = (startDate !== undefined);
                        for (let date in cashLadder) {
                            if (date === startDate) beforeStartDate = false;
                            if (beforeStartDate) continue;

                            newRow[date] = cashLadder[date];
                            if (date === endDate) break;
                        }
                        returnArray.push(newRow);
            */
        }
        // console.log("Selected bankAccountsCashLadderListDataSelector:", returnArray);
        return returnArray;
    }
);

export const bankAccountsCashLadderProjectionsSelector = () => createSelector(
    [],
    () => {
        // console.log("Selector bankAccountsCashLadderDatesSelector running ");

        let returnArray       = [];
        for (let acc of accArray) {
            for (let projection in acc.projections) {
                if (returnArray.indexOf(projection) === -1) returnArray.push(projection);
            }
        }
        // console.log("Selected bankAccountsCashLadderDatesSelector:", returnArray);
        return returnArray;
    }
);

export const bankAccountsCashLadderDatesSelector = () => createSelector(
    [],
    () => {
        // console.log("Selector bankAccountsCashLadderDatesSelector running ");

        let returnArray       = [];
        for (let acc of accArray) {
            for (let projection in acc.projections) {
                for (let date in acc.projections[projection]) {
                    if (returnArray.indexOf(date) === -1) returnArray.push(date);
                }
            }
        }
        // console.log("Selected bankAccountsCashLadderDatesSelector:", returnArray);
        return returnArray;
    }
);

const accArray = [
    {
        id: 13,
        name: "EUR account",
        currencyStr: 'EUR',
        projections: {
            sod: {
                '2020-11-13': 123000,
                '2020-11-16': 123000,
                '2020-11-17': 173000,
                '2020-11-18': 173000,
                '2020-11-24': 173000,
                '2020-12-03': 173000,
            },
            trs: {
                '2020-11-13': 123000,
                '2020-11-16': 123000,
                '2020-11-17': 173000,
                '2020-11-18': 143000,
                '2020-11-24': 263000,
                '2020-12-03': 263000,
            },
            post: {
                '2020-11-13': 123000,
                '2020-11-16': 123000,
                '2020-11-17': 173000,
                '2020-11-18': 143000,
                '2020-11-24': 150000,
                '2020-12-03': 150000,
            },
        }
    }, {
        id: 14,
        name: "USD account",
        currencyStr: 'USD',
        projections: {
            sod: {
                '2020-11-13': 38000,
                '2020-11-16': 78000,
                '2020-11-17': 78000,
                '2020-11-18': 78000,
                '2020-11-24': 48000,
                '2020-12-03': 48000,
            },
            trs: {
                '2020-11-13': 38000,
                '2020-11-16': 78000,
                '2020-11-17': 78000,
                '2020-11-18': 208000,
                '2020-11-24': 178000,
                '2020-12-03': 138000,
            },
            post: {
                '2020-11-13': 38000,
                '2020-11-16': 78000,
                '2020-11-17': 78000,
                '2020-11-18': 100000,
                '2020-11-24': 70000,
                '2020-12-03': 30000,
            },
        }
    }
];
