import React, { Component } from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from 'moment';
import {push} from "connected-react-router";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

class WelcomeScreen extends Component {

    gotoWizard() {
/*
        this.props.push("/setupWizard"); // Must be changed to new wizard when we want to activate the welcome screen
*/
    }

    render() {
        moment.locale(this.props.currentLanguage);

        return (
            <div className="welcomeScreen ">
                {this.props.ormActiveClient &&
                <h2 className="centeredText">{this.props.translate('welcomeScreen.headerWithClient', {name: this.props.ormActiveClient.name})}</h2>}
                {!this.props.ormActiveClient &&
                <h2 className="centeredText">{this.props.translate('welcomeScreen.headerNoClient')}</h2>}

                {(this.props.ormActiveClient) &&
                <div>
                    <hr></hr>
                    <p className="centeredText">{this.props.translate('welcomeScreen.configMessage1')}</p>
                    <button className="xPoolButton floatMiddle" onClick={() => this.gotoWizard()} autoFocus>
                        {this.props.translate('welcomeScreen.startWizardButton')}
                    </button>
                    <p className="centeredText">{this.props.translate('welcomeScreen.configMessage2')}</p>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translate:          selectTranslateFunction(state),
//        translate:          getTranslate(state.appLocale),
        ormActiveClient:    shallowActiveClientSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        push:               (loadPath)  => dispatch(push(loadPath))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen));


