import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavDropdown} from "react-bootstrap";
import {activeCurrenciesSelector} from "gui-common/orm/ormSelectors";
import {userPreferencesSetProp} from "gui-common/userSettings/userPreferencesReducer";
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";

export function XpHomeCurrencySelector (props) {

    const activeCurrencies    = useSelector(activeCurrenciesSelector);
    const selectedHomeCurrency= useSelector(state => state.userPreferencesState.homeCurrency);
    const defaultHomeCurrency = useAppEnvProperty('defaultHomeCurrency');
    const useHomeCurrency     = useAppEnvProperty('useHomeCurrency');
    const appReady            = useSelector(selectAppReadyState);
    const dispatch            = useDispatch();


    const ccyList = useMemo(
        () => {
            if (!props.availableCurrencies) {
                return activeCurrencies;
            }
            return props.availableCurrencies.reduce((ccyList, ccy) => {
                if (!ccyList.find(c => c.currency === ccy.currency)) {
                    ccyList.push(ccy);
                }
                return ccyList;
            }, [])
        },
        [props.availableCurrencies, activeCurrencies]
    );

    useEffect(
        () => {
            if (selectedHomeCurrency && ccyList.find(ccy => ccy.currency === selectedHomeCurrency)) {
                return;
            }

            if (defaultHomeCurrency && ccyList.find(ccy => ccy.currency === defaultHomeCurrency)) {
                dispatch(userPreferencesSetProp('homeCurrency', defaultHomeCurrency));
            }

            if (!ccyList?.length) {
                return;
            }

            dispatch(userPreferencesSetProp('homeCurrency', ccyList[0].currency));
        },
        [ccyList],
    );

    function setCurrency(currency) {
        dispatch(userPreferencesSetProp('homeCurrency', currency));
    }

    if (!appReady) return null;
    if (!useHomeCurrency && !props.forceDisplay) return null;

    return (
        <NavDropdown title={<span>{selectedHomeCurrency}</span>} id="homeCurrency-nav-dropdown" xp-test-id="mainMenuHomeCurrency">
            {ccyList.map((currency, index) =>
                <NavDropdown.Item key={index} onClick={() => setCurrency(currency.currency)}>
                    <span style={(selectedHomeCurrency && (currency.currency === selectedHomeCurrency)) ? {fontWeight: '800', fontSize: '16px'} : {}}>
                        {currency.currency}
                    </span>
                </NavDropdown.Item>
            )}
        </NavDropdown>
    )
}



