

const marketSimBaseUrl = "/test-market/v1/";
export const appSimulatorsApiConfig = {
    marketSimulator: {
        defaultPriceSpread  : marketSimBaseUrl + "client-market/default-price-spread",
        clientPriceOverride : marketSimBaseUrl + "client-prices/price-override",
        clientPrice         : marketSimBaseUrl + "client-prices",
        fixAccounts         : marketSimBaseUrl + "client-prices/fix-accounts",
    }
}
