import {ormEntitiesBatchAction} from "gui-common/orm/ormReducer";
import {
    setWebsocketState,
    resetWebsocketHandle,
    setWebsocketHandle,
} from "gui-common/api/webSocketReducer"
import {webSessionPushAwt} from "./webSessionReducer";

import {addUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import {setLoginMessage} from "gui-common/api/loginProcessReducer";
import {loadDataFromApi} from "gui-common/api/loginProcess";
import {apiTransformerMap} from "appConfig/api/apiTransformers";
import {filterSocketMessage} from "appConfig/api/appWebSocketConfig"
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {socketPayloadPrototypeMap} from "gui-common/api/webSocketConfig";

let socketQueueTimer;

let lastReceivedSocketTimestamp = 0;
let messageReceivedSequenceNumber = 0;

/* -----------------------------------------------------------------------------------------------------------------
* Function for opening web socket and starting event listeners.
* -----------------------------------------------------------------------------------------------------------------*/
export function wsOpenConnectionThunk () {
    return (dispatch, getState) => {
        const wsHandle = getState().webSocketState.webSocketHandle;

        if (wsHandle) {
            console.log("Closing socket ");
            wsHandle.close();
            dispatch(resetWebsocketHandle());
        }
        clearTimeout(socketQueueTimer);
        dispatch(setWebsocketState("starting"));
        const translate = selectTranslateFunction(getState());
        dispatch(setLoginMessage(translate("loginProcess.loadingMessage.startingWebSocket")));
        dispatch(setWebsocketHandle(getState, dispatch));
    }
}
/* -----------------------------------------------------------------------------------------------------------------
* Function for closing web socket.
* -----------------------------------------------------------------------------------------------------------------*/
export function wsCloseConnectionThunk () {
    return (dispatch, getState) => {
        const wsHandle = getState().webSocketState.webSocketHandle;
        if (!wsHandle) return;
        console.log("Closing socket ");
        wsHandle.close();
    }
}
/* -----------------------------------------------------------------------------------------------------------------
* Callback function for opening web socket message.
* -----------------------------------------------------------------------------------------------------------------*/
export function wsOpenCallback (msg) {
    return (dispatch, getState) => {
        webSocketQ.singleQ.length = 0; // Empty q at startup
        webSocketQ.batchQ.length  = 0; // Empty q at startup
        clearTimeout(socketQueueTimer);
        console.log("**************** Socket open. Msg: ", msg, getState().webSocketState.socketStatus);
        dispatch(setWebsocketState("running"));
        dispatch(loadDataFromApi()); // Load data from API
    }
}
/* -----------------------------------------------------------------------------------------------------------------
* Callback function for closing web socket message.
* -----------------------------------------------------------------------------------------------------------------*/
export function wsClosedCallback (msg) {
    return (dispatch, getState) => {
        console.log("**************** Socket closed. Msg: ", msg);
        if (getState().webSocketState.socketStatus === "running") {
            dispatch(addUserMessageThunk("error", "userMessages.error.webSocketClosed"));
            dispatch(setWebsocketState("down"));
        }
    }
}
/* -----------------------------------------------------------------------------------------------------------------
* Callback function for web socket errors.
* -----------------------------------------------------------------------------------------------------------------*/
export function wsErrorCallback (msg) {
    return (dispatch, getState) => {
        console.log("**************** Socket error: ", msg);
        if (getState().webSocketState.socketStatus === "running") dispatch(addUserMessageThunk("error", "userMessages.error.errorFromWebSocket", {errorMessage: msg.type}));
        dispatch(setWebsocketState("down"));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Socket message processing functions.
* -----------------------------------------------------------------------------------------------------------------*/
function processSocketMessage(message, state, dispatch, dispatchMessage) {

    let transformedPayload;
    if (message.model) {
        const transformer   = apiTransformerMap[message.model];
        if (!transformer)               {console.error("No transformer for socket message model : ", message)           ; return undefined;}
        if (!message.functionToDispatch){console.error("No call function for socket message : ", message)               ; return undefined;}

        try {
            transformedPayload = transformer(message.payload); // no params provided means that removeExecutionRights is set to false in nestled transformers under main object. Set like this now to check BE fix. Remove permanently later.
        }
        catch (error)                   {console.error("Could not transform socket message: ", message, error)          ; return undefined;}
        if (!transformedPayload)        {console.error("Transformed payload is undefined in socket message: ", message) ; return undefined;}
    }
    else {
        transformedPayload = message.payload;
    }

    // All is fine, log message to process.
    if (message.logMessage) console.log("Parsed socket message ", message.payloadType, " : ", message);

    // Dispatch function if not batch process.
    if (dispatchMessage) dispatch(message.functionToDispatch(message.model, transformedPayload));

    return {model: message.model, itemData: transformedPayload, ormEventType: message.ormEventType};
}

/* -----------------------------------------------------------------------------------------------------------------
* Socket message queue handling.
* -----------------------------------------------------------------------------------------------------------------*/
const webSocketQ = {
    batchQ:  [],
    singleQ: [],
};

const getLogString = (queue) => {
    let logStr = "";
    try {
        for (let msg of queue) logStr = logStr + "<" + msg.functionType.slice(msg.functionType.lastIndexOf('.')+1) + msg.payloadType.slice(msg.payloadType.lastIndexOf('.')) + "(" + msg.payload.id + ")> \n";
    }
    catch (error) {
        console.log("Error in getLogString: ", error);
        return "<could not resolve log string>";
    }
    return logStr;
};

function emptyQ1untilAfterQ2(q1, q2, logString) {
    if (q1.length === 0) return [[],[]];

    let itemsToPop = [];
    let remainingQ = [];
    const firstSequenceNumberInQ2 = q2?.length ? q2[0].receivedSequenceNumber : undefined;
    for (const [index, item] of q1.entries()) {
        if (firstSequenceNumberInQ2 && (firstSequenceNumberInQ2 < item.receivedSequenceNumber)) {
            remainingQ = q1.slice(index); // Wait with processing of this message and the rest of the q until the other q has been emptied.
            break;
        }
        itemsToPop.push(item);
    }
    if (itemsToPop.length) console.log(logString + getLogString(itemsToPop), [...itemsToPop]);
    return [itemsToPop, remainingQ];
}

export function emptySocketQ(startTimer, timerDelay) {
    return (dispatch, getState) => {
        const state = getState();

        const totalNumberOfMessages = webSocketQ.batchQ.length + webSocketQ.singleQ.length;
        let processedNumberOfMessages = 0;
        let singleQprefix = " ";
        let batchQprefix  = " ";

        while (webSocketQ.batchQ.length || webSocketQ.singleQ.length) {

            if (webSocketQ.singleQ.length) {
                const [singleQItemsToProcess, singleQRemainingItems] = emptyQ1untilAfterQ2(webSocketQ.singleQ, webSocketQ.batchQ, singleQprefix + "Empty socket singleQ:\n");
                for (let item of singleQItemsToProcess) {
                    processSocketMessage(item, state, dispatch, true);
                    processedNumberOfMessages++;
                }
                webSocketQ.singleQ = singleQRemainingItems;
            }

            if (webSocketQ.batchQ.length) {
                let batchItemsToDispatch = [];

                const [batchQItemsToProcess, batchQRemainingItems] = emptyQ1untilAfterQ2(webSocketQ.batchQ, webSocketQ.singleQ, batchQprefix + "Empty socket batchQ:\n");
                for (let item of batchQItemsToProcess) {
                    const messageObject = processSocketMessage(item, state, dispatch, false);
                    if (!messageObject) continue;
                    if (!messageObject.model) {
                        console.warn("No model in batched socket item, skipping!", messageObject);
                        continue;
                    }
                    batchItemsToDispatch.push(messageObject);
                    processedNumberOfMessages++;
                }
                if (batchItemsToDispatch.length > 0) dispatch(ormEntitiesBatchAction(batchItemsToDispatch));
                webSocketQ.batchQ = batchQRemainingItems;
            }
            singleQprefix = "+";
            batchQprefix  = "+";
        }
        if (processedNumberOfMessages !== totalNumberOfMessages) console.error("processedNumberOfMessages not equal to totalNumberOfMessages! Messages lost.", processedNumberOfMessages, totalNumberOfMessages);
        if (startTimer) socketQueueTimer = setTimeout(() => {dispatch(emptySocketQ(true, timerDelay))}, timerDelay);
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Callback function for web socket message.
* -----------------------------------------------------------------------------------------------------------------*/
export function wsMessageCallback (msg) {
    return (dispatch, getState) => {
       // console.log("Socket message: ", msg);
        if (!msg.data) {
            console.log("Socket message received without data: ", msg);
            return;
        }
        let parsedObjectArray;
        try {
            parsedObjectArray = JSON.parse(msg.data);
        }
        catch (error) {
            console.error("Could not parse socket message: ", error, msg.data);
            return;
        }
        for (let parsedObject of parsedObjectArray) {
            parsedObject.receivedSequenceNumber = messageReceivedSequenceNumber++;
            if (!parsedObject.timestamp) {
                console.warn("No timestamp in socket message", parsedObject);
            }
            else {
                if (parsedObject.timestamp < lastReceivedSocketTimestamp) {
                    console.warn("Received timestamp in socket message earlier than lastReceivedSocketTimestamp", parsedObject, lastReceivedSocketTimestamp);
                }
                lastReceivedSocketTimestamp = parsedObject.timestamp;
            }

            if (filterSocketMessage(parsedObject, getState(), dispatch)) {
                continue;
            }

            // If AWT message, handle before main processing code and return. This will not affect ORM or other parts of the state.
            if ((parsedObject.payloadType === 'se.nordicfc.common.authentication.tokens.AWTToken') && (parsedObject.functionType === 'se.nordicfc.common.message.functions.Create')) {
//                console.log("Got AWT token");
                dispatch(webSessionPushAwt([parsedObject.payload]));
                continue;
            }

            // *********************************************************************************************************************************************************************
            // NOTE!!! Only message types with objects that are processed in the ORM may be batched. E.g. User Messages cannot be batched since they are not processed in the ORM.
            // *********************************************************************************************************************************************************************
/*
            const batchMessage = () => {
                const prototypeConfig = socketPayloadPrototypeMap[parsedObject.prototypeName];
                if (!prototypeConfig || !prototypeConfig.batch) return false;
                if (prototypeConfig.batchType && !prototypeConfig.batchType[parsedObject.type]) return false;
                return true;
            };
*/
            const payloadConfig = socketPayloadPrototypeMap[parsedObject.payloadType];
            if (!payloadConfig) {
                console.warn("Socket message payloadType is not supported: ", parsedObject.payloadType, parsedObject);
                continue;
            }
            const functionConfig = payloadConfig.functionMap[parsedObject.functionType];
            if (!functionConfig) {
                console.warn("Socket message functionType is not supported: ", parsedObject.functionType, parsedObject.payloadType, parsedObject);
                continue;
            }
            const enrichObject = (obj) => ({
                    ...obj,
                    functionToDispatch: functionConfig.functionToDispatch,
                    model:              payloadConfig.model,
                    logMessage:         payloadConfig.logMessage,
                    ormEventType:       functionConfig.ormEventType,
                })
            // Add specific batchable models and event types to batchQ
            if (payloadConfig.batchPossible && functionConfig.batchPossible) webSocketQ.batchQ.push( enrichObject(parsedObject));
            else                                                             webSocketQ.singleQ.push(enrichObject(parsedObject));
        }
    }
}

