import {createReducer} from 'gui-common/functions/reducer.js'
import {getFormatter} from "gui-common/numberFormat/numberFormatFunctions";

export const SET_AMOUNT_DENOMINATOR     = 'SET_AMOUNT_DENOMINATOR';
export const SET_AMOUNT_DECIMALS        = 'SET_AMOUNT_DECIMALS';

// Action creators ********************************************************
// ************************************************************************
export function setAmountDenominator(denominator) {
    return {
        type: SET_AMOUNT_DENOMINATOR,
        payload: denominator
    };
}
export function setAmountDecimals(decimals) {
    return {
        type: SET_AMOUNT_DECIMALS,
        payload: decimals
    };
}

// Reducer functions ********************************************************
// ************************************************************************

function setDen(state, payload) {
    const formatter = getFormatter({...state, amountDenominator: payload});
    return {...state, amountDenominator: payload, formatter: formatter};
}
function setDec(state, payload) {
    const formatter = getFormatter({...state, amountDecimals: payload});
    return {...state, amountDecimals: payload, formatter: formatter};
}


const getInitialState = () => {
    const init = {
        amountDenominator   : 1,
        amountDecimals      : 0,
        activeLanguage      : 'en',
    }
    return {...init, formatter: getFormatter(init)};
}

export default createReducer(getInitialState(), {
    [SET_AMOUNT_DENOMINATOR] : setDen,
    [SET_AMOUNT_DECIMALS]    : setDec,
});

