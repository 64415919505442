import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {selectMyXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import {createTranslatedFieldData} from "gui-common/xpGrid/xpGridFunctions";
import {selectUserPreferencesActiveClientId} from "gui-common/userSettings/userSettingsSelectors";
import {selectDecDenLangState, selectNumberFormatter} from "gui-common/numberFormat/numberFormatSelectors";
import {speedFormatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import {entityIsActive} from "gui-common/audit/auditFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {selectedAccountIdInCashPool} from "features/cashPool/cashPoolSelectors";


function enrichWithNominalAndCpAmountCcy(enrichedOrder, decDenLangState, numberFormatter) {
    enrichedOrder.nominalCcy = (enrichedOrder.instructedCurrencyId === enrichedOrder.baseCurrencyId) ? enrichedOrder.baseCurrencyStr : enrichedOrder.quoteCurrencyStr;
    enrichedOrder.counterCcy = (enrichedOrder.instructedCurrencyId === enrichedOrder.baseCurrencyId) ? enrichedOrder.quoteCurrencyStr : enrichedOrder.baseCurrencyStr;
    enrichedOrder.nominalAmountCcy = enrichedOrder.nominalCcy + ' ' + speedFormatAmount(Math.abs(enrichedOrder.nominalAmount), decDenLangState, numberFormatter);
    enrichedOrder.counterAmountCcy = enrichedOrder.counterCcy + ' ' + speedFormatAmount(Math.abs(enrichedOrder.displayCpAmount), decDenLangState, numberFormatter);
}

export const cashPoolOrdersListDataSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => selectUserPreferencesActiveClientId(state),
    (state, props) => selectedOrderInCashPoolOrderList(state),
    (state, props) => selectedAccountIdInCashPool(state),
    state => selectTranslateFunction(state),
    selectNumberFormatter,
    selectDecDenLangState,
    (session, activeClientId, selectedOrderInCashPoolOrderList, selectedAccountIdInCashPool, translate, numberFormatter, decDenLangState) => {

        //        console.log("Running activeClientBankAccountsSelector ", activeClientId);
        if (!activeClientId) return []
        const client = getOrmModelObjectById(session, 'Client', activeClientId);

        let returnArray = [];
        client.orders.toModelArray().forEach(order => {

            const enrichedOrder = {...order.ref};
            enrichedOrder.client = client.ref;
            enrichedOrder.clientName = client.ref.name;
            enrichedOrder.clientId = client.ref.id;

            enrichedOrder.isSelectedInCashPool = (selectedOrderInCashPoolOrderList && (selectedOrderInCashPoolOrderList.id === enrichedOrder.id));
            createTranslatedFieldData(enrichedOrder, 'displayState', translate, ("orderStates." + enrichedOrder.displayState));
            if (order.orderType !== 'TRANSFER') {
                createTranslatedFieldData(enrichedOrder, 'adjustedSide', translate, ("buySellIndicators." + enrichedOrder.adjustedSide));
            }

            const barModel = order.barId;

            if (barModel && barModel.counterpartyAccountId && barModel.instructingAccountId) {
                // Should we use adjustedSide to flip for swaps?
                const sourceAccount = (order.nominalAmount < 0) ? barModel.instructingAccountId.ref : barModel.counterpartyAccountId.ref;
                const destAccount   = (order.nominalAmount > 0) ? barModel.instructingAccountId.ref : barModel.counterpartyAccountId.ref;

                enrichedOrder.sourceAccountSelectedInCashPool      =  selectedAccountIdInCashPool === sourceAccount.id
                enrichedOrder.destinationAccountSelectedInCashPool =  selectedAccountIdInCashPool === destAccount.id

                enrichedOrder.sourceAccountName      = sourceAccount.name;
                enrichedOrder.destinationAccountName = destAccount.name;
                enrichedOrder.sourceAccountId        = sourceAccount.id;
                enrichedOrder.destinationAccountId   = destAccount.id;

                if (order.orderType === 'TRANSFER') {
                    enrichedOrder.sourceAmountCcy        = sourceAccount.currency.currency + ' ' + speedFormatAmount(enrichedOrder.sourceAmount, decDenLangState, numberFormatter);
                    enrichedOrder.destinationAmountCcy   = sourceAccount.currency.currency + ' ' + speedFormatAmount(enrichedOrder.destinationAmount, decDenLangState, numberFormatter);
                }
                else {
                    const counterAmountCcy  = (order.instructedCurrencyId === order.baseCurrencyId) ? order.quoteCurrencyStr : order.baseCurrencyStr;

                    enrichedOrder.sourceAmount         = (order.nominalAmount < 0) ? order.nominalAmount         : order.displayCpAmount;
                    enrichedOrder.sourceCcy            = (order.nominalAmount < 0) ? order.instructedCurrencyStr : counterAmountCcy;

                    enrichedOrder.destinationAmount    = (order.nominalAmount > 0) ? order.nominalAmount         : order.displayCpAmount;
                    enrichedOrder.destinationCcy       = (order.nominalAmount > 0) ? order.instructedCurrencyStr : counterAmountCcy;

                    enrichedOrder.sourceAmountCcy      = enrichedOrder.sourceCcy      + ' ' + speedFormatAmount(enrichedOrder.sourceAmount     , decDenLangState, numberFormatter);
                    enrichedOrder.destinationAmountCcy = enrichedOrder.destinationCcy + ' ' + speedFormatAmount(enrichedOrder.destinationAmount, decDenLangState, numberFormatter);
                }
                enrichWithNominalAndCpAmountCcy(enrichedOrder, decDenLangState, numberFormatter);

                // This is needed to re-render in grid and get right background color.
                if (enrichedOrder.sourceAccountSelectedInCashPool) {
                    enrichedOrder.sourceAccountName = enrichedOrder.sourceAccountName + ' ';
                    enrichedOrder.sourceAmountCcy = ' ' + enrichedOrder.sourceAmountCcy;
                }
                if (enrichedOrder.destinationAccountSelectedInCashPool) {
                    enrichedOrder.destinationAccountName = enrichedOrder.destinationAccountName + ' ';
                    enrichedOrder.destinationAmountCcy = ' ' + enrichedOrder.destinationAmountCcy;
                }
            }
           returnArray.push(enrichedOrder)
        })

        return returnArray;
    }
);

export const clientOrdersListDataSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => selectTranslateFunction(state),
    (state, props) => props ? props.filterFunction : undefined,
    (state, props) => props ? props.clientId : undefined,
    selectNumberFormatter,
    selectDecDenLangState,
    (session, translate, filterFunction, clientId, numberFormatter, decDenLangState) => {
        const client = getOrmModelObjectById(session, 'Client', clientId);
        if (!client) return [];

        let returnArray = [];

        client.orders.toRefArray().forEach(order => {
            if (filterFunction && !filterFunction(order)) return;
            const enrichedOrder = {
                ...order,
                client: client.ref,
            }
            enrichWithNominalAndCpAmountCcy(enrichedOrder, decDenLangState, numberFormatter);
            returnArray.push(enrichedOrder);
        })
        returnArray.forEach(order => {
            createTranslatedFieldData(order, 'displayState', translate, ("orderStates." + order.displayState));
            createTranslatedFieldData(order, 'adjustedSide', translate, ("buySellIndicators." + order.adjustedSide));
        })
        return returnArray;
    }
);
export const getClientOrdersSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.clientId : undefined,
    (session, clientId) => {
        const client = getOrmModelObjectById(session, 'Client', clientId);
        if (!client) return [];
        return client.orders.toRefArray();
    }
);
export const allOrdersListDataSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => selectTranslateFunction(state),
    (state, props) => props ? props.filterFunction : undefined,
    selectNumberFormatter,
    selectDecDenLangState,
    (session, translate, filterFunction, numberFormatter, decDenLangState) => {
        //        console.log("Running activeClientBankAccountsSelector ", activeClientId);
        let clients = session.Client.all().filter(entityIsActive).toModelArray();

        let returnArray = [];
        for (const client of clients) {
            if (filterFunction && !filterFunction(client)) continue; // Ugly but efficcient. Filer is applied on per client, therefore no need to iterate over orders for non selected client.
            client.orders.toRefArray().forEach(order => {
                const enrichedOrder = {
                    ...order,
                    client: client.ref,
                    clientId: client.ref.id,
                    clientName: client.ref.name,
                }
                enrichWithNominalAndCpAmountCcy(enrichedOrder, decDenLangState, numberFormatter);
                returnArray.push(enrichedOrder);
            })
        }
        returnArray.forEach(order => {
            createTranslatedFieldData(order, 'displayState', translate, ("orderStates." + order.displayState));
            createTranslatedFieldData(order, 'adjustedSide', translate, ("buySellIndicators." + order.adjustedSide));
        })
        return returnArray;
    }
);

function setLeadText(id, trKey, amount, translate) {
    return {
        leadText: translate(trKey), leadTextRenderData: {trKey: trKey},
        cpAccountName: "", id: id, orderType: "", orderStatusT: "", currencyPairStr: "", fxRate: "", externalReference: "", modifiedDateTime: "", createdDateTime: "", valueDate: "",
        amount: amount,
    }
}

export const accountingOrdersListDataSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    cashPoolOrdersListDataSelector,
    state => selectMyXpGridState(state, {instanceId: "cashPoolOrderList"}),
    (state, props) => selectedAccountIdInCashPool(state),
    state => selectTranslateFunction(state),
    (session, allCashPoolOrders, orderListState, selectedAccountId, translate) => {
        //        console.log("Running activeClientBankAccountsSelector ", activeClientId);
        if (!allCashPoolOrders?.length || !selectedAccountId) return [];

        const selectedAccountModel = getOrmModelObjectById(session, 'BankAccount', selectedAccountId);
        if (!selectedAccountModel) return [];

        // const selectedAccountCurrencyId = selectedAccountModel.ref.currency.id;

        const ledgerBalanceLa      = getOrmModelObjectById(session, 'LedgerAccount', selectedAccountModel.ref.ledgerBalanceLaId)
        const postPoolingBalanceLa = getOrmModelObjectById(session, 'LedgerAccount', selectedAccountModel.ref.postPoolingBalanceLaId)

        let returnArray = [];
        if (ledgerBalanceLa) returnArray.push(setLeadText('in', "accountingOrdersList.ledgerBalance", ledgerBalanceLa.ref.balance, translate))

        for (const order of allCashPoolOrders) {
            let addParams = {};

            if ((order.state === "IN_BALANCE") || (order.state === "REJECTED")) continue;

            if (order.sourceAccountId      === selectedAccountId) {
                addParams.cpAccountName = order.destinationAccountName;
                addParams.amount        = order.sourceAmount;
            }
            else if (order.destinationAccountId === selectedAccountId) {
                addParams.cpAccountName = order.sourceAccountName;
                addParams.amount        = order.destinationAmount;
            }
            else continue;

            addParams.isSelectedInCashPool = (order.id === orderListState.selectedId);

            createTranslatedFieldData(order, 'displayState', translate, ("orderStates." + order.displayState));

            returnArray.push({
                ...order,
                ...addParams,
            })
        }
        if (postPoolingBalanceLa) returnArray.push(setLeadText('out', "accountingOrdersList.postPoolingBalance", postPoolingBalanceLa.ref.balance, translate))

        if (returnArray.length === 2) return []; // Only header and footer, i.e. no affecting orders.

        return returnArray;
    }
);

export const selectedOrderInCashPoolOrderList = ormCreateSelector(
    orm,
    dbStateSelector,
    state => selectMyXpGridState(state, {instanceId: "cashPoolOrderList"}),
    (session, orderListState) => {
        if (!orderListState) return undefined;

        const selectedOrderModel = getOrmModelObjectById(session, 'Order', orderListState.selectedId);
        if (!selectedOrderModel) return undefined;

        const bar = selectedOrderModel.barId?.ref;

        return {
            ...selectedOrderModel.ref,
            instructingAccountId : bar?.instructingAccountId,
            counterpartyAccountId: bar?.counterpartyAccountId,
        };
    }
);
