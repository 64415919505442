import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import cloneDeep from "lodash/cloneDeep";
import UserMessageConfirmButton from "gui-common/userMessages/UserMessageConfirmButton2";
import {confirmUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import {push} from "connected-react-router";
import {setActiveClientThunk} from "gui-common/functions/thunks";
import {getEntityFromId} from "gui-common/functions/functions";
import {
    formatUserMessagesGridData,
    prepareUserMessagesGridColumns
} from "gui-common/userMessages/userMessagesFunctions";
import {shallowActiveClientsSelector} from "features/client/clientSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

class UserMessagesList extends React.Component {

    onGridCellClicked(params) {return true;}
    onGridRowClicked(params) {return true;}
    onGridObjectSelected(data) {return true;}
    gridContextMenuItems(params) {
        let menuItems = [];
        let menuItem = {};

        const useGoToClient = this.props.userMessagesConfig && this.props.userMessagesConfig.useGoToClient;
        if (!useGoToClient) return menuItems;

        let client = getEntityFromId(params.node.data.parameters ? params.node.data.parameters.clientId : null, this.props.ormClients);
        if (client) {
            menuItem = {
                name: this.props.translate('userMessages.goToClientButton'),
                action: function () {
                    this.context.context.callingContext.props.setActiveClientThunk(client);
                    this.context.context.callingContext.props.push(this.context.context.callingContext.appStartPath);
                },
                context: params,
                disabled: this.props.suppressRowClickSelection,
            };
            menuItems.push(menuItem);
        }

        return menuItems;
    }

    render() {
        const hiddenState = this.props.hiddenState ? this.props.hiddenState : {
            createdDateTimeT:   false,
            clientName:         false,
            clientId:           true,
            userId:             false,
            type:               false,
            topic:              false,
            text:               false,
            confirmedDateTimeT: false,
            id:                 true
        };

        const dataToRender = cloneDeep(this.props.userMessages); // Get separate copy.
        const gridData = formatUserMessagesGridData(dataToRender, this.props.translate, this.props.currentLanguage, this.props.ormClients);
        let preparedColumns = prepareUserMessagesGridColumns(this.props.translate, hiddenState);

        return (
            <XpGrid
                gridCellClicked={(params) => this.onGridCellClicked(params)}
                gridRowClicked={(params) => this.onGridRowClicked(params)}
                gridObjectSelected={(data) => this.onGridObjectSelected(data)}
                gridContextMenuItems={(params) => this.gridContextMenuItems(params)}

                gridId={"userMessagesGrid" + this.props.instanceId}
                instanceId={this.props.instanceId}

                columnDefs={preparedColumns}
                rowData={gridData}
                callingContext={this}

                treeData={false}
                suppressDragLeaveHidesColumns={true}
                suppressMakeColumnVisibleAfterUnGroup={true}
                // rowGroupPanelShow="always"
                groupUseEntireRow={false}
                overlayNoRowsTemplate={this.props.translate('userMessages.noUserMessages')}
                suppressRowClickSelection={true}

                useResponsiveRowHeight={true}

                fillAvailableSpace={true}

                frameworkComponents= {{
                    xpConfirmUmRenderer: UserMessageConfirmButton,
                }}
            >
            </XpGrid>
        );
    };
}
const mapStateToProps = (state) => {
    return {
        userMessagesConfig: selectAppEnvProperty(state, 'userMessages'),
        appStartPath:       state.appConfigState.appStartPath,
        translate:          selectTranslateFunction(state),
        currentLanguage:    selectActiveLanguage(state),
        userMessages:       state.userMessagesState,
        ormClients:         shallowActiveClientsSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        confirm:        (message)     => dispatch(confirmUserMessageThunk(message)),
        setActiveClientThunk: (client)              => dispatch(setActiveClientThunk(client)),
        push:                 (loadPath)           => dispatch(push(loadPath)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMessagesList));
