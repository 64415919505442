import React from "react";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";

function SchedulingForm (props) {

    return (
        <div style={{marginBottom: '10px'}}>
            <XpFormSwitchInput
                noMargin
                field         = "isActive"
                disabled ={props.disabled}
            />
        </div>
    )
}
export default SchedulingForm
