import {showContextMenu} from "gui-common/contextMenu/contextMenuReducer";

export function contextMenuClickThunk (e, disableContextMenu, contextMenuId, parameters) {
    return (dispatch, getState) => {
        e.preventDefault();
        e.stopPropagation();

        if (disableContextMenu) return;
        const {pageX, pageY} = e;
        dispatch(showContextMenu(pageX, pageY, contextMenuId, parameters));
    }
}
