import cloneDeep from "lodash/cloneDeep";

export const ADMIN_USER_RIGHTS_SET_INSTANCE_RIGHT = 'ADMIN_USER_RIGHTS_SET_INSTANCE_RIGHT';
export const ADMIN_USER_RIGHTS_SET_DOMAIN_RIGHT   = 'ADMIN_USER_RIGHTS_SET_DOMAIN_RIGHT';
export const ADMIN_USER_RIGHTS_SET_SYSTEM_RIGHT   = 'ADMIN_USER_RIGHTS_SET_SYSTEM_RIGHT';

export const ADMIN_USER_RIGHTS_RESET_SYSTEM_RIGHT           = 'ADMIN_USER_RIGHTS_RESET_SYSTEM_RIGHT';
export const ADMIN_USER_RIGHTS_RESET_DATA_RIGHTS_COLLECTION = 'ADMIN_USER_RIGHTS_RESET_DATA_RIGHTS_COLLECTION';
export const ADMIN_USER_RIGHTS_RESET_USER_DATA_RIGHTS       = 'ADMIN_USER_RIGHTS_RESET_USER_DATA_RIGHTS';

export const ADMIN_USER_RIGHTS_ADD_INSTANCE        = 'ADMIN_USER_RIGHTS_ADD_INSTANCE';
export const ADMIN_USER_RIGHTS_REMOVE_INSTANCE     = 'ADMIN_USER_RIGHTS_REMOVE_INSTANCE';

export const ADMIN_USER_RIGHTS_SELECT_INSTANCE     = 'ADMIN_USER_RIGHTS_SELECT_INSTANCE';

export const ADMIN_USER_RIGHTS_SET_COMPRESSED_VIEW = 'ADMIN_USER_RIGHTS_SET_COMPRESSED_VIEW';
export const ADMIN_USER_RIGHTS_SET_READ_ONLY       = 'ADMIN_USER_RIGHTS_SET_READ_ONLY';

// Action creators ********************************************************
// ************************************************************************
export function userRightsAdminSetInstanceRight(user, entityDataRight, userRight, isAllowed) {
    return {
        type: ADMIN_USER_RIGHTS_SET_INSTANCE_RIGHT,
        payload: {
            user       : user,
            entityDataRight   : entityDataRight,
            userRight  : userRight,
            isAllowed  : isAllowed
        }
    };
}
export function userRightsAdminSetDomainRight(user, entityDataRight, domain, userRight, isAllowed) {
    return {
        type: ADMIN_USER_RIGHTS_SET_DOMAIN_RIGHT,
        payload: {
            user       : user,
            entityDataRight   : entityDataRight,
            domain     : domain,
            userRight  : userRight,
            isAllowed  : isAllowed
        }
    };
}
export function userRightsAdminSetSystemRight(userSystemRight, domain, userRight, isAllowed) {
    return {
        type: ADMIN_USER_RIGHTS_SET_SYSTEM_RIGHT,
        payload: {
            userSystemRight : userSystemRight,
            domain          : domain,
            userRight       : userRight,
            isAllowed       : isAllowed
        }
    };
}
export function userRightsAdminResetSystemRight(userSystemRight) {
    return {
        type: ADMIN_USER_RIGHTS_RESET_SYSTEM_RIGHT,
        payload: {
            userSystemRight : userSystemRight,
        }
    };
}
export function userRightsAdminResetDataRightsCollection(dataRightsCollection) {
    return {
        type: ADMIN_USER_RIGHTS_RESET_DATA_RIGHTS_COLLECTION,
        payload: {
            dataRightsCollection : dataRightsCollection,
        }
    };
}
export function userRightsAdminResetUserDataRights(dataRightsCollection, resetUserChanged) {
    return {
        type: ADMIN_USER_RIGHTS_RESET_USER_DATA_RIGHTS,
        payload: {
            dataRightsCollection : dataRightsCollection,
            resetUserChanged     : resetUserChanged
        }
    };
}

export function userRightsAdminAddInstance(dataRightsCollection, entityDataRight) {
    return {
        type: ADMIN_USER_RIGHTS_ADD_INSTANCE,
        payload: {
            dataRightsCollection : dataRightsCollection,
            entityDataRight      : entityDataRight,
        },
    };
}
export function userRightsAdminRemoveInstance(dataRightsCollection, entityDataRight) {
    return {
        type: ADMIN_USER_RIGHTS_REMOVE_INSTANCE,
        payload: {
            dataRightsCollection : dataRightsCollection,
            entityDataRight      : entityDataRight,
        },
    };
}

export function userRightsAdminSelectInstance(entityDataRight) {
    return {
        type: ADMIN_USER_RIGHTS_SELECT_INSTANCE,
        payload: entityDataRight,
    };
}

export function userRightsAdminSetCompressedView(compressedMode) {
    return {
        type: ADMIN_USER_RIGHTS_SET_COMPRESSED_VIEW,
        payload: {compressedMode: compressedMode},
    };
}

export function userRightsAdminSetReadOnly(readOnly) {
    return {
        type: ADMIN_USER_RIGHTS_SET_READ_ONLY,
        payload: {readOnly: readOnly},
    };
}


function createNewStateWithUserSystemRight(state, newUserSystemRight) {
    const newState = {...state};
    newState.modifiedUserSystemRights = {...newState.modifiedUserSystemRights}
    newState.modifiedUserSystemRights[newUserSystemRight.id] = newUserSystemRight;
    return newState;
}
function setSystemRight(state, payload) {
    if (!payload.userSystemRight) {
        console.error("No userSystemRight in setSystemRight", payload);
        return state;
    }
    const stateUserSystemRight = state.modifiedUserSystemRights[payload.userSystemRight.id];
    if (stateUserSystemRight && domainRightIsNotChanged(stateUserSystemRight.systemRights, payload)) {
        // No change or error!
        return state;
    }
    if (!stateUserSystemRight && domainRightIsNotChanged(payload.userSystemRight.systemRights, payload)) {
        // No change or error!
        return state;
    }

    const newUserSystemRight = stateUserSystemRight ? cloneDeep(stateUserSystemRight) : cloneDeep(payload.userSystemRight);
    newUserSystemRight.systemRights[payload.domain].entityPrototypeRights[payload.userRight] = payload.isAllowed;

    let newState = createNewStateWithUserSystemRight(state, newUserSystemRight);
    return newState;
}

function resetSystemRight(state, payload) {
    if (!payload.userSystemRight) {
        console.error("No userSystemRight in resetSystemRight", payload);
        return state;
    }
    const stateUserSystemRight = state.modifiedUserSystemRights[payload.userSystemRight.id];
    if (!stateUserSystemRight) {
        // No userSystemRight in modified state!
        return state;
    }

    const newState = {...state};
    newState.modifiedUserSystemRights = {...newState.modifiedUserSystemRights}
    delete newState.modifiedUserSystemRights[payload.userSystemRight.id];

    return newState;
}

function allDomainRightsAreFalse(entityDataRight, userRight) {
    for (let prototype in entityDataRight.domainRights) {
        const thisEntityPrototypeRights = entityDataRight.domainRights[prototype].entityPrototypeRights;
        for (let right in thisEntityPrototypeRights) {
            if (right !== userRight) continue;
            if (thisEntityPrototypeRights[right] === true) return false;
        }
    }
    return true;
}
function setAllDomainRightsTrue(entityDataRight, userRight) {
    for (let prototype in entityDataRight.domainRights) {
        const thisEntityPrototypeRights = entityDataRight.domainRights[prototype].entityPrototypeRights;
        for (let right in thisEntityPrototypeRights) {
            if (right !== userRight) continue;
            thisEntityPrototypeRights[right] = true;
        }
    }
}

function createNewStateWithEntityDataRight(state, newEntityDataRight, user) {
    const newState = {...state};
    newState.modifiedEntityDataRights = {...newState.modifiedEntityDataRights}
    newState.modifiedEntityDataRights[newEntityDataRight.id] = newEntityDataRight;
    newState.modifiedUsers = {...newState.modifiedUsers}
    newState.modifiedUsers[user.userId] = true;
    return newState;
}

function setInstanceRight(state, payload) {
    if (!payload.entityDataRight) {
        console.error("No entityDataRight in setInstanceRight", payload);
        return state;
    }
    const stateEntityDataRight = state.modifiedEntityDataRights[payload.entityDataRight.id];
    if (stateEntityDataRight && (stateEntityDataRight.instanceRights[payload.userRight] === payload.isAllowed)) {
        // No change!
        return state;
    }
    const newEntityDataRight = stateEntityDataRight ? cloneDeep(stateEntityDataRight) : cloneDeep(payload.entityDataRight);

    if (payload.isAllowed && (state.selectedInstance[payload.entityDataRight.entityPrototypeName]  === payload.entityDataRight.id) && allDomainRightsAreFalse(newEntityDataRight, payload.userRight)) {
        setAllDomainRightsTrue(newEntityDataRight, payload.userRight);
    }
    newEntityDataRight.instanceRights[payload.userRight] = payload.isAllowed;

    let newState = createNewStateWithEntityDataRight(state, newEntityDataRight, payload.user);
    return newState;
}

function domainRightIsNotChanged(domainRights, payload) {
    if (!domainRights || !domainRights[payload.domain]) {
        console.error("Error in setDomainRight-domainRightIsNotChanged, no domain found", domainRights, payload);
        return false;
    }
    const entityPrototypeRights = domainRights[payload.domain].entityPrototypeRights;
    if (!entityPrototypeRights) {
        console.error("Error in setDomainRight-domainRightChange, no entityPrototypeRights found", domainRights, payload);
        return false;
    }
    return (entityPrototypeRights[payload.userRight] === payload.isAllowed);
}

function setDomainRight(state, payload) {
    if (!payload.entityDataRight) {
        console.error("No entityDataRight in setInstanceRight", payload);
        return state;
    }
    const stateEntityDataRight = state.modifiedEntityDataRights[payload.entityDataRight.id];
    if (stateEntityDataRight && domainRightIsNotChanged(stateEntityDataRight.domainRights, payload)) {
        // No change or error!
        return state;
    }
    if (!stateEntityDataRight && domainRightIsNotChanged(payload.entityDataRight.domainRights, payload)) {
        // No change or error!
        return state;
    }

    const newEntityDataRight = stateEntityDataRight ? cloneDeep(stateEntityDataRight) : cloneDeep(payload.entityDataRight);
    newEntityDataRight.domainRights[payload.domain].entityPrototypeRights[payload.userRight] = payload.isAllowed;

    let newState = createNewStateWithEntityDataRight(state, newEntityDataRight, payload.user);
    return newState;
}

function resetDataRightsCollection(state, payload) {
    if (!payload.dataRightsCollection) {
        console.error("No dataRightsCollection in resetDataRightsCollection", payload);
        return state;
    }
    const stateDataRightCollection = state.modifiedDataRightsCollections[payload.dataRightsCollection.id];
    if (!stateDataRightCollection) {
        // No userSystemRight in modified state!
        return state;
    }

    const newState = {...state};
    newState.modifiedDataRightsCollections = {...newState.modifiedDataRightsCollections}
    delete state.modifiedDataRightsCollections[payload.dataRightsCollection.id];

    return newState;
}


function clearDataRightsCollection(modifiedDataRightsCollections, modifiedEntityDataRights, dataRightsCollection) {

    if (modifiedDataRightsCollections[dataRightsCollection.id]) delete modifiedDataRightsCollections[dataRightsCollection.id];

    for (let entityDataRight of dataRightsCollection.assigned) {
        if (modifiedEntityDataRights[entityDataRight.id]) delete modifiedEntityDataRights[entityDataRight.id];
        if (entityDataRight.childDataRightsCollection) clearDataRightsCollection(modifiedDataRightsCollections, modifiedEntityDataRights, entityDataRight.childDataRightsCollection);
    }
    for (let entityDataRight of dataRightsCollection.assignable) {
        if (!entityDataRight.id) continue;
        if (modifiedEntityDataRights[entityDataRight.id]) delete modifiedEntityDataRights[entityDataRight.id];
        if (entityDataRight.childDataRightsCollection) clearDataRightsCollection(modifiedDataRightsCollections, modifiedEntityDataRights, entityDataRight.childDataRightsCollection);
    }
}

function resetUserDataRights(state, payload) {
    if (!payload.dataRightsCollection) {
        console.error("No dataRightsCollection in resetUserDataRights", payload);
        return state;
    }

    const newState = {...state};
    newState.modifiedDataRightsCollections = {...newState.modifiedDataRightsCollections}
    newState.modifiedEntityDataRights      = {...newState.modifiedEntityDataRights}
    newState.modifiedUsers                 = {...newState.modifiedUsers}

    clearDataRightsCollection(newState.modifiedDataRightsCollections, newState.modifiedEntityDataRights, payload.dataRightsCollection)
    if (payload.resetUserChanged && newState.modifiedUsers[payload.dataRightsCollection.user.userId]) delete newState.modifiedUsers[payload.dataRightsCollection.user.userId];
    return newState;
}

function createNewStateWithDataRightsCollection(state, newDataRightsCollection) {
    const newState = {...state};
    newState.modifiedDataRightsCollections = {...newState.modifiedDataRightsCollections}
    newState.modifiedDataRightsCollections[newDataRightsCollection.id] = newDataRightsCollection;

    newState.modifiedUsers = {...newState.modifiedUsers}
    newState.modifiedUsers[newDataRightsCollection.user.userId] = true;
    return newState;
}

function addInstance(state, payload) { // This will ony be called when "adding back" an entityDataRight that was moved to assignable without saving.
    if (!payload.dataRightsCollection ||!payload.entityDataRight) {
        console.error("No dataRightsCollection or entityDataRight in addInstance", payload);
        return state;
    }

    const stateDataRightsCollection = state.modifiedDataRightsCollections[payload.dataRightsCollection.id];
    if (stateDataRightsCollection && stateDataRightsCollection.assigned.find(item => item.id === payload.entityDataRight.id)) {
        console.error("Tried to add instance to assigned when already there!", stateDataRightsCollection, payload);
        return state;
    }
    if (!stateDataRightsCollection && payload.dataRightsCollection.assigned.find(item => item.id === payload.entityDataRight.id)) {
        console.error("Tried to add instance to assigned when already there!", payload);
        return state;
    }
    const newDataRightsCollection = stateDataRightsCollection ? cloneDeep(stateDataRightsCollection) : cloneDeep(payload.dataRightsCollection);
    newDataRightsCollection.assigned.push(payload.entityDataRight);

    const indexOfAssignable = newDataRightsCollection.assignable.findIndex(item => item.id === payload.entityDataRight.id);
    if (indexOfAssignable !== -1) {
        newDataRightsCollection.assignable.splice(indexOfAssignable,1);
    }
    else {
        console.error("Failed to find index of removed assignable!", newDataRightsCollection, payload);
    }

    let newState = createNewStateWithDataRightsCollection(state, newDataRightsCollection);
    return newState;
}
function removeInstance(state, payload) {
    if (!payload.dataRightsCollection ||!payload.entityDataRight) {
        console.error("No dataRightsCollection or entityDataRight in removeInstance", payload);
        return state;
    }

    const stateDataRightsCollection = state.modifiedDataRightsCollections[payload.dataRightsCollection.id];
    if (stateDataRightsCollection && stateDataRightsCollection.assignable.find(item =>
        (item.entityPrototypeName === payload.entityDataRight.entityPrototypeName) && (item.entity.id === payload.entityDataRight.entity.id)
    )) {
        console.error("Tried to move instance to assignable when already there!", stateDataRightsCollection, payload);
        return state;
    }
    if (!stateDataRightsCollection && payload.dataRightsCollection.assignable.find(item => item.id === payload.entityDataRight.id)) {
        console.error("Tried to add instance to assignable when already there!", payload);
        return state;
    }
    const newDataRightsCollection = stateDataRightsCollection ? cloneDeep(stateDataRightsCollection) : cloneDeep(payload.dataRightsCollection);
    newDataRightsCollection.assignable.push(payload.entityDataRight);

    const indexOfAssigned = newDataRightsCollection.assigned.findIndex(item => item.id === payload.entityDataRight.id);
    if (indexOfAssigned !== -1) {
        newDataRightsCollection.assigned.splice(indexOfAssigned,1);
    }
    else {
        console.error("Failed to fid index of removed assigned!", newDataRightsCollection, payload);
    }

    let newState = createNewStateWithDataRightsCollection(state, newDataRightsCollection);
    return newState;
}

function selectInstance(state, payload) {
    let newState = cloneDeep(state);
    if (newState.selectedInstance[payload.entityPrototypeName] === payload.id) newState.selectedInstance[payload.entityPrototypeName] = undefined;
    else                                                                       newState.selectedInstance[payload.entityPrototypeName] = payload.id;
    return newState;
}

function setCompressedView(state, payload) {
    if (!payload) return initialState;
    return {
        ...state,
        compressedMode: payload.compressedMode,
    };
}
function setReadOnly(state, payload) {
    if (!payload) return initialState;
    return {
        ...state,
        readOnly: payload.readOnly,
    };
}

// Reducer action handlers map ************************************************
// ************************************************************************
const actionHandler = {
    [ADMIN_USER_RIGHTS_SET_INSTANCE_RIGHT] : setInstanceRight,
    [ADMIN_USER_RIGHTS_SET_DOMAIN_RIGHT]   : setDomainRight,
    [ADMIN_USER_RIGHTS_SET_SYSTEM_RIGHT]   : setSystemRight,

    [ADMIN_USER_RIGHTS_RESET_SYSTEM_RIGHT] : resetSystemRight,
    [ADMIN_USER_RIGHTS_RESET_DATA_RIGHTS_COLLECTION] : resetDataRightsCollection,
    [ADMIN_USER_RIGHTS_RESET_USER_DATA_RIGHTS]       : resetUserDataRights,

    [ADMIN_USER_RIGHTS_ADD_INSTANCE]       : addInstance,
    [ADMIN_USER_RIGHTS_REMOVE_INSTANCE]    : removeInstance,
    [ADMIN_USER_RIGHTS_SELECT_INSTANCE]    : selectInstance,
    [ADMIN_USER_RIGHTS_SET_COMPRESSED_VIEW]: setCompressedView,
    [ADMIN_USER_RIGHTS_SET_READ_ONLY]      : setReadOnly,
};

const initialState = {
    modifiedDataRightsCollections: {},
    modifiedEntityDataRights     : {},
    modifiedUserSystemRights     : {},
    selectedInstance             : {},
    modifiedUsers                : {},
    compressedMode               : true,
    readOnly                     : false,
};

export function adminUserRightsReducer(state = initialState, action) {
//    if (!action.payload) return state;
    const handler = actionHandler[action.type];
    return handler ? handler(state, action.payload) : state;
}
