import {pushModalWindow} from "redux-promising-modals";
import moment from "moment";
import {fetchUserSettings} from "gui-common/userSettings/userState/userSettingsApi";
import {addMockedData, getOrmItemFromApi, getOrmItemsFromApi} from "gui-common/orm/ormApi";
import {getUserMessagesFromApi} from "gui-common/userMessages/userMessagesApi";
import {setActiveClientThunk} from "gui-common/functions/thunks";
import {loadProcessFailedThunk, loadProcessSuccessThunk} from "gui-common/api/loginProcess";
import {getSystemTextsFromApi} from "gui-common/appLocale/xpTranslated/systemTextApi";
import {loadMatrixInSequence, loadMatrixParallel} from "gui-common/api/apiCommon";
import {getUserDataRightsFromApi, getUserSystemRightFromApi} from "gui-common/adminUserRights/adminUserRightsApi";
import {loadClosestSixtyDaysBankHolidays} from "gui-common/bankHolidays/bankHolidaysFunctions";
import {INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {selectUserPreferencesActiveClientId} from "gui-common/userSettings/userSettingsSelectors";
import {getInBalanceOrdersFromApi} from "xpool-gui-common/features/order/orderApi";
import {getFxRejectConfigFromApi} from "gui-common/fxRejectConfig/fxRejectConfigApi";
import {shallowActiveClientSelector, shallowActiveClientsSelector} from "features/client/clientSelectors";
import {getShallowClientsFromApi} from "xpool-gui-common/features/client/clientApi";


const baseUrl = "/xpool-api/v2/";
const erpBaseUrl = "/erp-connection/v1/"
export const ormApiBaseUrlMap = {
    AuditRootModel          : baseUrl + "audit-entries/root-prototypes",
    AuditUserActionType     : baseUrl + "audit-entries/action-prototypes",
    Client                  : baseUrl + "clients",
    LegalEntity             : baseUrl + "legal-entities",
    LegalEntityUnit         : baseUrl + "legal-entity-units",
    BankAccount             : baseUrl + "bank-accounts",
    BankAccountInstitution  : baseUrl + "bank-account-institutions",
    LedgerAccount           : baseUrl + "ledger-accounts",
    Order                   : baseUrl + "orders",
    Currency                : baseUrl + "currencies",
    CurrencyPair            : baseUrl + "currency-pairs",
    Country                 : baseUrl + "countries",
    FxPrice                 : baseUrl + "fx-prices",
    FixMessage              : baseUrl + "fix-log",
    Provider                : baseUrl + "providers",
    ErpSystem               : erpBaseUrl + "erp-systems",
    OperatingUnit           : baseUrl + "operating-units",
    SystemEvent             : baseUrl + "system-events",
    User                    : baseUrl + "users",
    Topic                   : baseUrl + "user-messages/topics",
    NoRunDate               : baseUrl + "no-run-dates",
    SharedSetting           : baseUrl + "shared-settings",
    DataRightsCollection    : baseUrl + "data-rights-collection",
    UserSystemRight         : baseUrl + "user-system-rights",
    BankHoliday             : baseUrl + "bank-holidays",
    RunState                : baseUrl + "run-state",
};

export const apiBaseUrlMap = {
    shallowClients          : baseUrl + "clients/shallow-clients",
    executeOrders           : baseUrl + "clients/execute-order",
    userPreferences         : baseUrl + "user-settings",
    userMessage             : baseUrl + "user-messages",
    // userMessageLanguage     : baseUrl + "user-messages/translations", // not used anymore
    audit                   : baseUrl + "audit-entries",
    historicAudit           : baseUrl + "audit-entries-historic",
    reportSet               : baseUrl + "report-sets",
    fxRejectConfig          : baseUrl + "fx-reject-configurations",
    systemText              : baseUrl + "system-texts",
    requestReloadBalance    : baseUrl + "bank-accounts/request-new-balance-for",
    requestReloadBalances   : baseUrl + "bank-accounts/request-new-balances",
    signIn                  : "/aaa/v1/sign-in/",
    uploadFile              : baseUrl + "upload-file",
    reverseBalanceMovement  : ormApiBaseUrlMap.BankAccount + "/reverse-erp-transaction",
    hedgeBalanceMovement    : ormApiBaseUrlMap.BankAccount + "/hedge-erp-transaction",
    erpOathConnection       : erpBaseUrl + "oauth2/erp-integrations",
    erpDataRequest          : erpBaseUrl + "data-request",
};


const getDataLoadFunctionMatrix = (getState) =>  {

    let fromDateStr = moment().subtract(1, 'days').format('YYYY-MM-DD');

    return [
/*
        [
            {function: getOrmItemsFromApi, parameters: ['Currency'    ]},
            {function: getOrmItemsFromApi, parameters: ['CurrencyPair']},
            {function: getOrmItemsFromApi, parameters: ['Country'    ]},
//            {function: getOrmItemsFromApi, parameters: ['Tenor'    ]},
            {function: getOrmItemsFromApi, parameters: ['Provider'    ]},
            {function: getOrmItemsFromApi, parameters: ['ErpSystem'   ]},
            {function: getOrmItemsFromApi, parameters: ['BankAccountInstitution']},
            {function: getOrmItemsFromApi, parameters: ['SystemEvent']},
            {function: getOrmItemsFromApi, parameters: ['User']},
            {function: getOrmItemsFromApi, parameters: ['OperatingUnit']},
            {function: getOrmItemsFromApi, parameters: ['Topic']},
            {function: getOrmItemsFromApi, parameters: ['NoRunDate']},
            {function: fetchUserSettings, parameters: []}, // Get the user's blobs early so that selected IDs are overridden when setting default client.
            {function: getOrmItemsFromApi, parameters: ['SharedSetting']},
            {function: loadClosestSixtyDaysBankHolidays, parameters: []},
        ], [
            {function: getOrmItemsFromApi, parameters: ['AuditRootModel']},
            {function: getOrmItemsFromApi, parameters: ['AuditUserActionType']},
            {function: getShallowClientsFromApi, parameters: []},
            // {function: getOrmItemsFromApi, parameters: ['Client', undefined, {includeDisabled: true}]},
            {function: getOrmItemsFromApi, parameters: ['FxPrice']},
            {function: getOrmItemFromApi , parameters: ['User', getState().webSessionState.userSystemId]},
            {function: getSystemTextsFromApi, parameters: []},
            // {function: getUserMessageLanguageFromApi, parameters: []}, // Must load before SystemText in order to get it overwritten, not used anymore.
            {function: getUserMessagesFromApi, parameters: [fromDateStr]},
            // {function: getOrmItemsFromApi, parameters: ['Order']},
            {function: getUserDataRightsFromApi, parameters: []},
            {function: getUserSystemRightFromApi, parameters: []},
            {function: getFxRejectConfigFromApi, parameters: ['Quote']},
            {function: getFxRejectConfigFromApi, parameters: ['Order']},
        ], [
            {function: getInBalanceOrdersFromApi, parameters: []},
        ]
*/
        [
            {function: getShallowClientsFromApi, parameters: []},
        ],
        [
            {function: getOrmItemsFromApi, parameters: ['AuditRootModel']},
        ],
        [
            {function: getOrmItemsFromApi, parameters: ['Currency'    ]},
            {function: getOrmItemsFromApi, parameters: ['CurrencyPair']},
            {function: getOrmItemsFromApi, parameters: ['Country'    ]},
//            {function: getOrmItemsFromApi, parameters: ['Tenor'    ]},
            {function: getOrmItemsFromApi, parameters: ['Provider'    ]},
            {function: getOrmItemsFromApi, parameters: ['ErpSystem'   ]},
            {function: getOrmItemsFromApi, parameters: ['BankAccountInstitution']},
            {function: getOrmItemsFromApi, parameters: ['SystemEvent']},
            {function: getOrmItemsFromApi, parameters: ['User']},
            {function: getOrmItemsFromApi, parameters: ['OperatingUnit']},
            {function: getOrmItemsFromApi, parameters: ['Topic']},
            {function: getOrmItemsFromApi, parameters: ['NoRunDate']},
            // {function: fetchUserSettings, parameters: []}, // Get the user's blobs early so that selected IDs are overridden when setting default client.
            {function: getOrmItemsFromApi, parameters: ['SharedSetting']},
            {function: loadClosestSixtyDaysBankHolidays, parameters: []},
        ], [
            {function: getOrmItemsFromApi, parameters: ['AuditUserActionType']},
            {function: getOrmItemsFromApi, parameters: ['FxPrice']},
            {function: getOrmItemFromApi , parameters: ['User', getState().webSessionState.userSystemId]},
            {function: getSystemTextsFromApi, parameters: []},
            // {function: getUserMessageLanguageFromApi, parameters: []}, // Must load before SystemText in order to get it overwritten, not used anymore.
            {function: getUserMessagesFromApi, parameters: [fromDateStr]},
            // {function: getOrmItemsFromApi, parameters: ['Order']},
            {function: getUserDataRightsFromApi, parameters: []},
            {function: getUserSystemRightFromApi, parameters: []},
            {function: getFxRejectConfigFromApi, parameters: ['Quote']},
            {function: getFxRejectConfigFromApi, parameters: ['Order']},
        ], [
            {function: getInBalanceOrdersFromApi, parameters: []},
        ]
    ];
};


export function loadApplicationData() {
    return (dispatch, getState) => {

        let data = require('./restReturnClientData');

        dispatch(fetchUserSettings())
            .then(result => {
                loadMatrixParallel(getDataLoadFunctionMatrix(getState), dispatch)
                    .then(result => {
                        // addMockedData('ErpSystem'        , data.erpSystems, dispatch);
                        // addMockedData('Client'                  , data.clients, dispatch);
                        // addMockedData('FxPrice'                 , data.fxPrices, dispatch);
                        // addMockedData('Order'                   , data.orders, dispatch);
                        // addMockedData('BankAccountInstitution'  , data.institutions, dispatch);
                        // addMockedData('NoRunDate'               , data.noRunDates, dispatch);
                    })
                    .then(result => {
                        // addMockedData('AuditRootModel'      , data.auditRootModels      , dispatch);
                        // addMockedData('DataRightsCollection', data.dataRightsCollections, dispatch);
                        // addMockedData('UserSystemRight'     , data.userSystemRights     , dispatch);
                        /*
                                        dispatch(setActiveClient(data.clients[0]));
                                        dispatch(xpGridSetSelected('clientForm', data.clients[0].id));
                                        dispatch(ormFormSetActive(data.clients[0].id, 'clientForm', true));
                        */
                    })
                    .then(result => { // Get active client
                        const activeClientId = selectUserPreferencesActiveClientId(getState());
                        const activeClient = shallowActiveClientSelector(getState());

                        if (activeClientId && !activeClient) {
                            dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'general.modalConfirmationActiveClientNotFound'}));
                        }

                        let clientToLoad = activeClient;
                        if (!activeClient) {
                            const clients = shallowActiveClientsSelector(getState());
                            clientToLoad = (clients && clients.length) ? clients[0] : undefined;
                        }
                        dispatch(setActiveClientThunk(clientToLoad));
                        // return clientToLoad ? dispatch(getOrmItemFromApi('Client', clientToLoad.id)) : undefined;
                    })
                    .then(result => {
                        dispatch(loadProcessSuccessThunk());
                    })
                    .catch(err => {
                        console.error("Data load process failed: " + err);
                        dispatch(loadProcessFailedThunk());
                    });
            })
    }
}
