import React, {useMemo} from 'react';
import PropTypes from "prop-types";

import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {prepareReportGridColumns, transformReportData} from "gui-common/report/reportFunctions";
import {useSelector} from "react-redux";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {dataSetTypes} from "appConfig/report/appReportConstants";
import {selectActiveLanguage, useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


function ReportDataList (props) {

    const reportEnvConfig = useSelector(state => selectAppEnvProperty(state, 'reportSetConfig'));
    const activeLanguage  = useSelector(selectActiveLanguage);
    const translate       = useXpTranslateFunction();

    function exitWithoutRendering() {
        if (!props.loadedDataSetType || !reportEnvConfig[props.loadedDataSetType]) return true;
        if (!props.dataSetConfig) return true;
        if (!props.reportData) return true;
        return false;
    }

    const preparedColumns = useMemo(() =>
        {
            if (exitWithoutRendering()) return undefined;
            return prepareReportGridColumns(reportEnvConfig[props.loadedDataSetType].fieldConfig, props.reportData);
        },
        [props.reportData, props.loadedDataSetType]
    );

    const reportDataToGrid = useMemo(() =>
        {
            if (exitWithoutRendering()) return undefined;
            return transformReportData(props.reportData, activeLanguage, dataSetTypes[props.loadedDataSetType], reportEnvConfig[props.loadedDataSetType].fieldConfig);
        },
        [props.reportData, activeLanguage, props.loadedDataSetType]
    );

    if (!preparedColumns || !reportDataToGrid) return null;

    function onGridCellClicked(params) {}
    function onGridRowClicked(params) {}


    function onGridObjectSelected(data) { // not used in this list
        if (!props.itemSelectedCallback) return;
        if (data === undefined) props.itemSelectedCallback(undefined);
        else props.itemSelectedCallback(data.id);
    }

    function gridContextMenuItems(params) {
        let menuItems = [];
        // let menuItem = {};
        return menuItems;
    }



    return (
        <XpGrid
            gridCellClicked={(params)      => onGridCellClicked(params)}
            gridRowClicked={(params)       => onGridRowClicked(params)}
            gridObjectSelected={(data)     => onGridObjectSelected(data)}
            gridContextMenuItems={(params) => gridContextMenuItems(params)}

            gridId     = {props.instanceId}
            instanceId = {props.instanceId}

            defaultSelectedId={undefined}

            columnDefs={preparedColumns}
            rowData={reportDataToGrid}

            treeData={false}
            suppressDragLeaveHidesColumns={true}
            suppressMakeColumnVisibleAfterUnGroup={true}

            overlayNoRowsTemplate={translate('report.noReportDataToShow')}

            fillAvailableSpace       ={true}
            suppressRowClickSelection={true}
        >
        </XpGrid>
    );
}
ReportDataList.propTypes = {
    reportData           : PropTypes.array,
    instanceId           : PropTypes.string,
    dataSetConfig        : PropTypes.object,
    itemSelectedCallback : PropTypes.func
};

export default ReportDataList;
