import React from 'react';
import {useSelector} from "react-redux";
import {useSelectedCashPoolAccount} from 'features/cashPool/cashPoolSelectors';
import CashPoolAccountForm from 'features/bankAccount/CashPoolAccountForm';
import 'features/cashPool/cashPool.css';
import CashPoolBalanceList from "features/bankAccount/formComponents/CashPoolBalanceList";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import AccountBalanceGraph from "xpool-gui-common/features/bankAccount/AccountBalanceGraph";
import XpSplitPane from "gui-common/components/XpSplitPane";
import XpResizable from "gui-common/components/XpResizable";
import "xpool-gui-common/features/currencyDashboard/currencyDashboard.css"
import {XpTab, XpTabs} from "gui-common/components/xpTabs/XpTabs";
import CashPoolClientForm from "features/client/CashPoolClientForm";
import {activeClientSelector} from "xpool-gui-common/features/client/clientSelectors";


function AccountDetails (props) {

    const selectedAccount = useSelectedCashPoolAccount();
    const activeClient = useSelector(activeClientSelector);

    if (!selectedAccount) return null;
    return (
        <div xp-test-id={'xpAccountDetails'}>
            <div className="cashPoolDetails2">
                <XpSplitPane defaultWidthSecondaryPixels={780} minSizePrimary={600} minSizeSecondary={400}>
                    <div className="balanceHistory">
                        <XpResizable
                            className='balanceGraphInCurrencyDashboard'
                            defaultHeight={300}
                        >
                            <AccountBalanceGraph
                                selectedAccount={selectedAccount}
                                bankAccountFormModel={selectedAccount ? 'xpAdminForm.cashPoolAccountForm.BankAccount.' + selectedAccount.id : undefined}
                                hideSwitchForm
                            />
                        </XpResizable>
                        <CashPoolBalanceList
                            account={selectedAccount}
                        />
                    </div>
                    <div className="poolAccountForm" >
                        <XpTabs instanceId={'cashPoolParametersSettings'} disableNoneSelectable>
                            <XpTab label={(<XpTranslated trKey={'cashPoolConfig.accountSettings'} trParams={{name: selectedAccount?.name, currency: selectedAccount?.currency?.currency}}/>)} >
                                    {selectedAccount?.parentAccountId ?
                                        <CashPoolAccountForm account={selectedAccount}/>
                                        :
                                        <div className="admin1ColumnsContainer">
                                            <div className="admin1Column">
                                                <XpTranslated trKey='cashPoolAccountForm.noPoolingParamsForTopAccount'/>
                                            </div>
                                        </div>
                                    }
                            </XpTab>
                            <XpTab label={<XpTranslated trKey={'cashPoolConfig.clientSettings'} />}>
                                {activeClient &&
                                    <CashPoolClientForm client={activeClient}/>
                                }
                            </XpTab>
                        </XpTabs>
                    </div>
                </XpSplitPane>
            </div>
        </div>
    )
}

export default AccountDetails;

/*
{selectedAccount.parentAccountId ?
    <CashPoolAccountForm account={selectedAccount}/>
    :
    <div className="admin1ColumnsContainer">
        <div className="admin1Column">
            <XpTranslated trKey='cashPoolAccountForm.noPoolingParamsForTopAccount'/>
        </div>
    </div>
}
*/
