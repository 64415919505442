import React from 'react'
import {useSelector} from "react-redux";
import {
    erpSystemsSelector,
} from "gui-common/orm/ormSelectors";
import XpFormFieldGroup from "gui-common/xpForm/XpFormFieldGroup";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {xpFormUserCanEditBase} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import EprConnectStatusButton from "gui-common/erpIntegration/ErpConnectStatusButton";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";

const connectButtonWidth = 30;
function ErpIntegrationForm(props) {

    const erpSystems           = useSelector(erpSystemsSelector);
    const formContext          = useXpFormContext();
    const formDataErpEnabled   = useXpFormField(formContext.formModel + '.erpIntegration.erpEnabled');
    const erpConfig = useAppEnvProperty('erpIntegrationConfig');


    function fieldIsDisabled() {
        if (props.enforceUseCanEdit) return false;
        return !xpFormUserCanEditBase(formContext, props.ormModel, props.isSubForm);
    }

    if (!erpConfig.erpIntegrationEnabled) return null;
    if (!erpSystems.length) return null;

    return (
        <div className={props.className}>
            <XpFormFieldset
                field={'erpIntegration'}
                formTemplate={'erpIntegration.configurationForm'}
            >
                <XpFormFieldGroup
                    bypass={props.bypassFieldGroup}
                    isExpanded={(formDataErpEnabled === true)}
                    disableAnimations = {formContext?.formUseState === XP_FORM_VIEW}
                    controlComponent={
                        <XpFormSwitchInput
                            noMargin
                            field= "erpEnabled"
                            // label={<div>Fish</div>}
                            disabled={fieldIsDisabled()}
                        />
                    }
                >
                    <div style={{display: 'flex', alignContent: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div style={{width: (100 - connectButtonWidth) + '%'}}>
                            <XpFormSelectorInput
                                field="erpSystemId"
                                selectList={erpSystems}
                                itemTextField="name"
                                disabled={fieldIsDisabled()}
                                inLineLayout noTemplate
                                isRequired
                                defaultValue={erpSystems[0].id}
                            />
                        </div>
                        {(formContext?.currentData?.erpIntegration && (formContext?.formUseState === XP_FORM_VIEW)) &&
                        <EprConnectStatusButton
                            erpIntegrationId={formContext?.currentData?.erpIntegrationId}
                            businessObjectName={formContext?.currentData?.name}

                        />}
                    </div>
                </XpFormFieldGroup>
            </XpFormFieldset>
        </div>
    )
}

export default ErpIntegrationForm;
