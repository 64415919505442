import {createReducer} from "gui-common/functions/reducer";

const appUserStates = {
    /*
        "xpGridsState"           : {reloadFunction: xpGridsReloadState           , resetFunction: xpGridsResetState           },
    */
};

const initialState = {
    appStartPath        : '/cashPool',
    appUserStates       : appUserStates,
};

export default createReducer(initialState, {
});
