import {reformatTimeInputOnChange} from "gui-common/xpForm/formComponentsFunctions";
import {hourToLarge, incorrectTimeFormat, minuteToLarge} from "gui-common/xpForm/formComponentsValidators";
import TextField from "@mui/material/TextField";
import React from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";

function XpFormTimeInput (props) {
    return (
        <XpFormLabeledInput
            alignRight
            updateOn = "blur"
            parser = {value => {
                if (!value) return "";
                return value.replace(/[^0-9:]/g, '');
            }}
            {...props}

            CompToRender={TextField}
            getValueFromEvent={event => event.target.value}

            valueFormatter = {reformatTimeInputOnChange}
            errors = {{
                ...props.errors,
                isRequired: (val) => props.isRequired && (!val || (val.length === 0) || (val === " ")),
                incorrectTimeFormat: (val) => (val) && (val.length !== 0) && incorrectTimeFormat(val),
                hourToLarge: (val) => (val) && (val.length !== 0) && hourToLarge(val),
                minuteToLarge: (val) => (val) && (val.length !== 0) && minuteToLarge(val),
            }}
        />
    )
}
XpFormTimeInput.propTypes    = xpFormBasePropTypes;
export default XpFormTimeInput
