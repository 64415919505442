import {useDispatch, useSelector} from "react-redux";
import {activeClientSelector} from "xpool-gui-common/features/client/clientSelectors";
import {useSelectedHomeCurrency, useSelectorInstance} from "gui-common/functions/hooks";
import {getLegalEntityBankAccountsSelector} from "xpool-gui-common/features/legalEntity/legalEntitySelectors";
import {activeCurrenciesSelector, activeFxPricesSelector} from "gui-common/orm/ormSelectors";
import {useMemo} from "react";
import {useLedgerAccountsArray} from "gui-common/ledger/ledgerHooks";

export function useActiveClientLE0Accounts() {
    const activeClient = useSelector(activeClientSelector);
    const legalEntity = activeClient?.legalEntities[0];

    return useSelectorInstance(getLegalEntityBankAccountsSelector, {selectId: legalEntity?.id});
}

export function useBaseCurrency() {
    const selectedHomeCurrency = useSelectedHomeCurrency();
    const activeCurrencies    = useSelector(activeCurrenciesSelector);
    return useMemo(
        () => {
            if (!activeCurrencies?.length) return undefined;

            if (!selectedHomeCurrency) return undefined;

            return activeCurrencies.find(ccy => ccy.currency === selectedHomeCurrency);
        },
        [selectedHomeCurrency, activeCurrencies]
    );
}

export function useFxRateMap() {
    const accounts = useActiveClientLE0Accounts();
    const fxPrices = useSelector(activeFxPricesSelector);

    const baseCurrency = useBaseCurrency()

    return useMemo(
        () => {
            if (!baseCurrency ||!accounts?.length) return undefined;

            return accounts.reduce((map, account) => {
                map[account.ledgerBalanceLaId] = {rateToBaseCurrency: 1, currency: account.currency};

                if (account.currency.id === baseCurrency.id) {
                    return map;
                }
                if (!fxPrices?.length) {
                    return map;
                }
                const fxPrice = fxPrices.find(price => ((price.baseCurrencyId === account.currency.id) && (price.quoteCurrencyId === baseCurrency.id)))
                if (fxPrice) {
                    map[account.ledgerBalanceLaId].rateToBaseCurrency = fxPrice.midRate;
                    return map;
                }
                const invertedFxPrice = fxPrices.find(price => ((price.quoteCurrencyId === account.currency.id) && (price.baseCurrencyId === baseCurrency.id)))
                if (invertedFxPrice) {
                    map[account.ledgerBalanceLaId].rateToBaseCurrency = 1 / invertedFxPrice.midRate;
                    return map;
                }

                if (!invertedFxPrice) {
                    console.error("Could not find fxPrice in useFxRateMap", account.currency.currency, baseCurrency.currency, account.currency.id, baseCurrency.id);
                    return map;
                }
            }, {});
        },
        [fxPrices, baseCurrency]
    );
}

export function useTotalLiquidity() {
    const accounts = useActiveClientLE0Accounts();
    const ledgerAccounts = useLedgerAccountsArray(accounts ? accounts.map(acc => acc.ledgerBalanceLaId) : undefined);
    const baseCurrency   = useBaseCurrency()
    const fxPriceMap     = useFxRateMap();

    return useMemo(
        () => {
            if (!accounts || !baseCurrency || !ledgerAccounts || !fxPriceMap) {
                return undefined;
            }
            return ledgerAccounts.reduce((tot, acc) => {
                return tot + acc.balance * fxPriceMap[acc.id].rateToBaseCurrency
            }, 0)
        },
        [ledgerAccounts, fxPriceMap, baseCurrency]
    );
}

export function useCurrencyData (props) {

    const accounts = useActiveClientLE0Accounts();
    const ledgerAccounts = useLedgerAccountsArray(accounts ? accounts.map(acc => acc.ledgerBalanceLaId) : undefined);
    const baseCurrency = useBaseCurrency()
    const fxPriceMap = useFxRateMap();
    const totalBalanceInBase = useTotalLiquidity();

    return useMemo(
        () => {
            if (!baseCurrency || !ledgerAccounts || !fxPriceMap) {
                return undefined;
            }

            const currencyMap = ledgerAccounts.reduce((currencyMap, ledgerAccount) => {
                const ccy = fxPriceMap[ledgerAccount.id].currency.currency;
                const rate = fxPriceMap[ledgerAccount.id].rateToBaseCurrency;
                if (!currencyMap[ccy]) {
                    currencyMap[ccy] = {
                        balanceInBase    : ledgerAccount.balance * rate,
                        balanceInCurrency: ledgerAccount.balance,
                        currency: ccy,
                        baseCurrency: baseCurrency.currency,
                        rate: rate,
                    }
                }
                else {
                    currencyMap[ccy].balanceInBase     += ledgerAccount.balance * rate;
                    currencyMap[ccy].balanceInCurrency += ledgerAccount.balance;
                }
                currencyMap[ccy].percentExposure = currencyMap[ccy].balanceInBase * 100 / totalBalanceInBase;
                return currencyMap;
            }, {});

            return Object.keys(currencyMap).map(key => currencyMap[key]);
        },
        [baseCurrency, ledgerAccounts, fxPriceMap]
    );
}

export function useTotalCurrencyInBase() {
    const currencyData = useCurrencyData()
    return useMemo(
        () => {
            if (!currencyData) {
                return undefined;
            }
            return currencyData.reduce((tot, item) => tot += item.balanceInBase, 0);
        },
        [currencyData]
    );

}
