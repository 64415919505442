import PropTypes from "prop-types";
import React, {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    ReferenceLine,
    AreaChart, Area
} from "recharts";
import {formatDateField, isNumber} from "gui-common/functions/functions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {XpDateTime} from "gui-common/components/XpDateTime";
import {formatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import moment from "moment";
import {useResizeObserver} from "gui-common/functions/hooks";
import XpContextMenu from "gui-common/contextMenu/XpContextMenu";
import XpSlider from "gui-common/components/XpSlider";
import {useSpring, animated} from "@react-spring/web";
import {selectActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const pixelsNeededForXLabel  = 85;
const pixelsNeededForXTick   = 60;
const pixelsNeededForYTick   = 70;
const minPixelsBetweenYTicks = 40;
function XpChart (props) {

    const decDenLangState = useSelector(selectDecDenLangState);
    const currentLanguage = useSelector(selectActiveLanguage);
    const [chartAreaState, setChartAreaState]   = useState(undefined);
    const [brushState, setBrushState]           = useState(undefined);

    const chartProps = useMemo(
        () => {
            return {
                // height: '100%',
                margin: {top : 25, right : 35, left : 10, bottom : 0}
            };
        },
        []
    );
    const chartData = useMemo(
        () => {
            return calculateChartData(props.lineConfig, props.previousDefaultFromLine);
        },
        [props.lineConfig]
    );
    const brushedChartData = useMemo(
        () => {
            return chartData;
            if (!brushState) return chartData;
            let returnArray = [];
            for (let i = 0; i < chartData.length ; i++) {
                if (i < brushState[0]) continue;
                if (i > brushState[1]) return returnArray;
                returnArray.push(chartData[i]);
            }
            return returnArray;
        },
        [chartData, brushState]
    );

    const lineConfig = useMemo(
        () => {
            return props.lineConfig.map((item,index) => ({
                type                : "monotone",
                // type                : "stepAfter",
                name                : item.lineInstanceId,
                stroke              : item.stroke,
                // stroke              : "url(#gradient" + index + ")",
                fill                : item.fill ? item.fill : "url(#gradient" + index + ")",
                fillOpacity         : item.fillOpacity ? item.fillOpacity : 0.9,
                strokeWidth         : item.strokeWidth ? item.strokeWidth : (props.strokeWidth ? props.strokeWidth : 4),
                activeDot           : {r: 6},
                animationDuration   : 500,
                fromIndex           : item.visibleFrom ? chartData.findIndex(dataPoint => datesAreEqual(dataPoint.momentDate, item.visibleFrom)) : null,
                toIndex             : item.visibleTo   ? chartData.findIndex(dataPoint => datesAreEqual(dataPoint.momentDate, item.visibleTo))   : null,
                firstIndex          : chartData.findIndex(dataPoint => dataPoint[item.lineInstanceId] !== undefined),
                ...item,
                dataKey             : item.lineInstanceId,
                data                : undefined, // Data must NOT be sent to line. Makes Brush not working.
            }));
        },
        [props.lineConfig, chartData]
    );

    const areaGradients = useMemo(
        () => {
            return createAreaGradients(lineConfig);
        },
        [lineConfig]
    );

    const observerCallback = useCallback(box => {
        if (chartAreaState && (chartAreaState.height === box.height && chartAreaState.width === box.width)) {
            // No need to update!
            return;
        }
        setChartAreaState({height: box.height - 40, width: box.width})
    }, []);
    const resizedContainerRef = useResizeObserver(observerCallback);

    const yMinMax = useMemo(
        () => {
            if (!chartData) return {};
            return getYMinMax(chartData, lineConfig);
        },
        [chartData, lineConfig]
    );

    const xMinMax = useMemo(() => {
            if (!chartData?.length) return undefined;
            return {
                min: chartData[0].momentDate,
                max: chartData[chartData.length-1].momentDate,
            };
        },
        [chartData]
    );

    function getTickIndexToAdd(chartData, currentIndexInput, period) {
        const currentIndex = Math.floor(currentIndexInput);
        if (period === 'day') {
            return currentIndex + 1;
        }
        const eop = chartData[currentIndex].momentDate.clone().endOf(period);
        const dur = moment.duration(eop.diff(chartData[currentIndex].momentDate));
        const diff = dur.asDays();
        return currentIndex + Math.round(diff) + 1;
    }

    function getPeriod(spanDuration, ticksToAdd) {
        if (     Math.floor(spanDuration.asDays())   <= ticksToAdd) return 'day';
        // else if (Math.floor(spanDuration.asWeeks())  <= ticksToAdd) return 'week';
        else if (Math.floor(spanDuration.asMonths()) <= ticksToAdd) return 'month';
        else if (Math.floor(spanDuration.asMonths() / 3) <= ticksToAdd) return 'quarter';
        else if (Math.floor(spanDuration.asYears())  <= ticksToAdd) return 'year';
        return undefined;
    }

    const xAxisTicks = useMemo(() => {
            if (!xMinMax || !chartAreaState) return undefined;

            const brushedDataLength = brushState ? (brushState[1] - brushState[0]) : chartData.length;
            const brushedFirstIndex = brushState ? brushState[0] : 0;
            const brushedLastIndex  = brushState ? brushState[1] : chartData.length - 1;

            if (brushedFirstIndex > chartData.length) return;
            if (brushedLastIndex > chartData.length) return;

            let returnObject = {[brushedFirstIndex]: {label: true}};

            if (chartAreaState.width > (pixelsNeededForXLabel * 2)) {
                const pixelsPerIndex      = chartAreaState.width / brushedDataLength;
                const indexPerTickLabel   = (pixelsPerIndex >= pixelsNeededForXLabel) ? 1 : Math.ceil(pixelsNeededForXLabel / pixelsPerIndex);
                const indexPerTick        = (pixelsPerIndex >= pixelsNeededForXTick)  ? 1 : Math.ceil(pixelsNeededForXTick  / pixelsPerIndex);

                const tickLabelsToAdd     = Math.floor(chartAreaState.width / pixelsNeededForXLabel) - 1;
                const ticksToAdd          = Math.floor(chartAreaState.width / pixelsNeededForXTick)  - 1;

                if (ticksToAdd && (brushedDataLength > (2 + (indexPerTick * 2)))) { // Need room for first and last tick (*0.5) and one more tick in between (*1)

                    const spanDuration = moment.duration(chartData[brushedLastIndex].momentDate.diff(chartData[brushedFirstIndex].momentDate));

                    const tickPeriod  = getPeriod(spanDuration, ticksToAdd);
                    const labelPeriod = getPeriod(spanDuration, tickLabelsToAdd);

                    if (tickPeriod) {
                        let tickIndexToAdd = getTickIndexToAdd(chartData, brushedFirstIndex, tickPeriod);
                        while (tickIndexToAdd < brushedLastIndex) {
                            returnObject[tickIndexToAdd] = {label: false};
                            tickIndexToAdd = getTickIndexToAdd(chartData, tickIndexToAdd, tickPeriod);
                        }
                    }
                    if (labelPeriod) {
                        let tickIndexToAdd = getTickIndexToAdd(chartData, brushedFirstIndex, labelPeriod);
                        while (tickIndexToAdd < (brushedLastIndex - indexPerTickLabel)) {
                            returnObject[tickIndexToAdd] = {
                                label: (tickIndexToAdd > (indexPerTickLabel + brushedFirstIndex)) && (tickIndexToAdd < (brushedLastIndex - indexPerTickLabel)),
                            };
                            tickIndexToAdd = getTickIndexToAdd(chartData, tickIndexToAdd, labelPeriod);
                        }
                    }
                }
            }

            returnObject[brushedLastIndex] = {label: true};
            // console.log("xAxisTicks: ", returnObject);

            return returnObject;
        },
        [chartData, chartAreaState, brushState]
    );

    const xTicks = useMemo(
        () => {
            if (!xAxisTicks) return undefined;
            const tickArray = Object.keys(xAxisTicks).map(Number);
            const sorted = tickArray.sort((a, b) => a - b);
            // console.log("xTicks: ", sorted);
            return sorted;
        },
        [xAxisTicks]
    );

    const yAxisTicks = useMemo(
        () => {
            if (!yMinMax || !chartAreaState) return undefined;

            let returnObject = {[yMinMax.max]: true, [yMinMax.min]: true};

            const yRange = yMinMax.max - yMinMax.min;
            const yValuePerPixel = yRange / chartAreaState.height;
            const yMinTickValue = yValuePerPixel * minPixelsBetweenYTicks;

            if ((yMinMax.max > 0) && (yMinMax.min < 0) && (yMinMax.max >= yMinTickValue) && (yMinMax.min <= - yMinTickValue)) {
                returnObject[0] = true;
            }

            const yNeededTickValue = yValuePerPixel * pixelsNeededForYTick;
            const yTickValue = roundToRelevantYAxisTick(yNeededTickValue);

            let tickToAdd = yMinMax.min + (Math.abs(yMinMax.min) % yTickValue);
            while (tickToAdd < (yMinMax.max - yMinTickValue)) {
                returnObject[tickToAdd] = true;
                tickToAdd += yTickValue;
            }
            return returnObject;
        },
        [chartAreaState, yMinMax]
    );

    const yTicks = useMemo(
        () => {
            if (!yAxisTicks) return undefined;
            const tickArray = Object.keys(yAxisTicks).map(Number);
            const sorted = tickArray.sort((a, b) => a - b);
            // console.log("xTicks: ", sorted);
            return sorted;
        },
        [yAxisTicks]
    );

    // const ChartComponent = LineChart;
    // const LineComponent = Line;
    const ChartComponent = AreaChart;
    const LineComponent = Area;

    return (
        <div style={{height: '100%', paddingBottom: '40px'}} ref={resizedContainerRef}>
            <ResponsiveContainer height='100%' width='100%' >
                <ChartComponent {...chartProps} data={brushedChartData} o>
                    {/*{lineGradients}*/}
                    {props.areaFill && areaGradients}
                    <XAxis
                        stroke="ghostwhite"
                        dataKey="xIndex"
                        type='number'
                        allowDataOverflow={true}
                        // domain={['dataMin', 'dataMax']}
                        domain={brushState ? brushState : ['dataMin', 'dataMax']}
                        interval={0}
                        tick={<CustomizedXAxisTick chartData={chartData} ticks={xAxisTicks}/>}
                        ticks={xTicks}
                    />
                    <YAxis stroke="ghostwhite" domain={[yMinMax.min, yMinMax.max]} tick={<CustomizedYAxisTick />} ticks={yTicks}/>

                    <CartesianGrid  strokeDasharray='1 1'/* horizontalPoints={yTicks.filter(tick => tick !== 0)}*//>

                    {props.xReferenceLines.map((line, index) => {
                        const dateIndex = chartData.findIndex(dataPoint => datesAreEqual(line.momentDate, dataPoint.momentDate))
                        return (
                            <ReferenceLine key={'x-ref-'+index} x={dateIndex} strokeWidth={line.strokeWidth} stroke={line.color} strokeDasharray={line.strokeDasharray} label={<XReferenceLineLabel {...line}/>} isFront={true}/>
                        )
                    })}
                    {props.yReferenceLines.map((line, index) => {
                        return (
                            <ReferenceLine key={'y-ref-'+index} y={line.value} strokeWidth={line.strokeWidth} stroke={line.color} strokeDasharray={line.strokeDasharray} label={<YReferenceLineLabel {...line}/>} isFront={true} ifOverflow="extendDomain"/>
                        )
                    })}

                    {lineConfig.map(lineProps => (!lineProps.hidden &&
                        <LineComponent
                            key={lineProps.lineInstanceId}
                            {...lineProps}
                            dataKey={lineProps.deltaKey ? lineProps.dataKey + '-delta' : lineProps.dataKey}
                            // fillOpacity={1}
                            dot={false}
                            activeDot={<XpChartActiveDot lineProps={lineProps} contextMenuItemsFn={lineProps.contextMenuItemsFn}/>}
                        />
                    ))}


                    <Tooltip
                        content={props.tooltipContentFn ? e => props.tooltipContentFn(e, lineConfig) : e => renderToolTip(e, decDenLangState, lineConfig)}
                        cursor={{ stroke: 'rgba(0,0,0,0.15)', strokeWidth: 1 , strokeDasharray:'1 1'}}
                    />

                </ChartComponent>
            </ResponsiveContainer>
            <XpSlider
                dataSetId={props.dataSetId}
                onChangeCallback={value => {
                    setBrushState(value);
                }}
                minValue={chartData[0].xIndex}
                maxValue={chartData[chartData.length - 1].xIndex}
                getLabelFn={val => {
                    if (chartData[val] === undefined) {
                        return "";
                    }
                    return formatDateField(chartData[val].momentDate, currentLanguage, 'L');
                }}
                containerClass={'xpChartSliderContainer'}
            />
        </div>
    )
}
XpChart.propTypes = {
    dataSetId        : PropTypes.string.isRequired,
    lineConfig       : PropTypes.array.isRequired,
    tooltipContentFn : PropTypes.func,
};
export default XpChart


const defaultStopsConfig = [{offset: 0, stopOpacity: 0.8}, {offset: 0.9, stopOpacity: 0.05}]
function createAreaGradients(lineConfig) {
    return (
        <defs>
            {lineConfig.map((config, index) => {
                return config.areaGradient ? config.areaGradient : (
                    <linearGradient id={"gradient" + index} x1="0" y1="0" x2="0" y2="1" key={config.lineInstanceId}>
                        {(config.gradientStopsConfig ? config.gradientStopsConfig : defaultStopsConfig).map((stopConfig, stopIndex) =>
                            <stop key={config.lineInstanceId + stopIndex} offset={stopConfig.offset} stopColor={stopConfig.color ? stopConfig.color : config.stroke} stopOpacity={stopConfig.stopOpacity}/>
                        )}
                    </linearGradient>
                )
            })}
        </defs>
    )
}


function getDeltaArrow(props, opacity, arrowMove) {
    if (!props.lineProps.deltaKey) {
        return null;
    }
    if (!props.payload[props.dataKey]) {
        return null;
    }
    const Icon     = props.payload[props.dataKey] > 0 ? ArrowUpwardIcon : ArrowDownwardIcon;

    // const cssClass = 'xpChartActiveDotArrowBase ' + (props.payload[props.dataKey] > 0 ? 'xpChartActiveDotArrowUp' : 'xpChartActiveDotArrowDown');
    // const top      = props.payload[props.dataKey] > 0 ? (5 - arrowMove) : (arrowMove - 5);

    return (
        <animated.div className={'xpChartActiveDotArrowBase'} style={{
            opacity: opacity,
            top: arrowMove ? arrowMove.to(top => (props.payload[props.dataKey] < 0 ? (-7 - top) : (top + 7)) + 'px') : undefined
        }}>
            <Icon size={24} sx={{color: 'gray'}}/>
        </animated.div>
    )
}

function XpChartActiveDot(props) {
    const {y} = useSpring({
        from: {y: props.cy - 18},
        y: props.cy - 12,
    });
    const {opacity} = useSpring({
        from: {opacity: 0},
        opacity: 1,
        loop: {delay: 300, reset: true},
        config: { duration: 800}
    });

    const {arrowMove} = useSpring({
        from: {arrowMove: 20},
        arrowMove: 0,
        loop: {delay: 300, reset: true},
        // delay: 500,
        config: { duration: 800}
    });

    if (props.payload[props.dataKey] === undefined) return null;
    // console.log("Active dot", props.value, props);

    return (
        <g>
            <animated.foreignObject x={props.cx - 12} y={y} width={24} height={24} overflow={'visible'} >
                <XpContextMenu
                    bypass    = {!props.contextMenuItemsFn}
                    menuItems = {props.contextMenuItemsFn}
                    data      = {props}
                >
                    <div style={{position: 'relative'}}>
                        <div className='xpChartActiveDot' style={{background: props.lineProps.stroke}}/>
                        <div className={'xpChartActiveDotInner'}/>
                        {getDeltaArrow(props, opacity, arrowMove)}
                    </div>

                </XpContextMenu>
            </animated.foreignObject>
        </g>
    )
}

function YReferenceLineLabel(props) {
    if (!props.trKey) {
        return null;
    }
    return (
        <g>
            <foreignObject x={props.viewBox.x + 10} y={props.viewBox.y - 15} width={150} height={20}>
                <div style={{width:'100%', textAlign: 'left'}}>
                    <XpTranslated className='xpChartReferenceLine' trKey={props.trKey} trParams={props.trParams} spanStyle={{color: props.color}}/>
                </div>
            </foreignObject>
        </g>
    );
}

function XReferenceLineLabel(props) {
    if (!props.trKey) {
        return null;
    }
    return (
        <g>
            <foreignObject x={props.viewBox.x - 100} y={props.viewBox.y - 22  + props.yOffset} width={200} height={20} overflow={'visible'} >
                <div style={{width:'100%', textAlign: 'center'}}>
                    <XpTranslated className='xpChartYLineLabel' trKey={props.trKey} trParams={props.trParams} spanStyle={{color: props.color}}/>
                </div>
            </foreignObject>
        </g>
    );
}

function CustomizedXAxisTick(props) {
    const currentLanguage = useSelector(selectActiveLanguage);

    if (!props.chartData[props.payload.value]) return null;
    if (!props.ticks || !props.ticks[props.payload.value]?.label) return null;
    return (
        <g transform={`translate(${props.x},${props.y})`}>
            <text x={0} y={-5} dy={16} textAnchor="middle" fill="#666" fontSize={12}>
                {formatDateField(props.chartData[props.payload.value].momentDate, currentLanguage, 'L')}
            </text>
        </g>
    );
}

function CustomizedYAxisTick(props) {
    const decDenLangState = useSelector(selectDecDenLangState);
    if (props.payload?.value === undefined) return null;
    return (
        <g transform={`translate(${props.x},${props.y})`}>
            <text x={-2} y={4} dy={0} textAnchor="end" fill="#666" fontSize={12}>
                {formatAmount(props.payload.value, decDenLangState)}
            </text>
        </g>
    );
}

function lineDataIdentical(chartDataPoint, lastDataPoint, lineConfigs) {
    for (const lineConfig of lineConfigs) {
        if (chartDataPoint[lineConfig.lineInstanceId] === undefined) continue;
        if (lastDataPoint[lineConfig.lineInstanceId] === undefined) {
            // This is the first line with a data point counting from last date. This value should be used in next comparison.
            lastDataPoint[lineConfig.lineInstanceId] = chartDataPoint[lineConfig.lineInstanceId];
            continue;
        }
        if (chartDataPoint[lineConfig.lineInstanceId] !== lastDataPoint[lineConfig.lineInstanceId]) return false;
    }
    return true;
}

function calculateChartData(lineConfigs, previousDefaultFromLine) {
    const chartData = {};
    let minDate;
    let maxDate;

    // This loop iterates over all data series and create an object with one property per value date with data points for all series that have a data point for the date.
    for (const lineConfig of lineConfigs) {
        if (!lineConfig.data?.length || !lineConfig.dateField || !lineConfig.dataKey) continue;

        const visibleFromEod = lineConfig.visibleFrom?.endOf('day');
        const visibleToEod   = lineConfig.visibleTo?.endOf('day');

        for (const dataPoint of lineConfig.data) {
            if (dataPoint.disabledDateTime) {
                continue;
            }
            const valueDate = dataPoint[lineConfig.dateField];
            if (!valueDate) {
                continue;
            }

            if (!chartData[valueDate]) {
                const thisMomentDate = moment(valueDate).endOf('day');
                if (!minDate) minDate = thisMomentDate;
                if (!maxDate) maxDate = thisMomentDate
                chartData[valueDate] = {momentDate: thisMomentDate};

                if (minDate.isAfter( thisMomentDate)) minDate = thisMomentDate.clone();
                if (maxDate.isBefore(thisMomentDate)) maxDate = thisMomentDate.clone();
            }

            if (visibleFromEod && chartData[valueDate].momentDate.isBefore(visibleFromEod)) continue;
            if (visibleToEod   && chartData[valueDate].momentDate.isAfter( visibleToEod  )) continue;

            chartData[valueDate][lineConfig.lineInstanceId] = dataPoint[lineConfig.dataKey];
        }
    }
    if (!minDate || !maxDate) return [];

    // This loop iterates the available dates from last data to identify completely stale trailing data points.
    const reversedDatesArray = Object.keys(chartData).sort().reverse();
    let lastDataPoint = {...chartData[reversedDatesArray[0]]};
    for (const [index, date] of reversedDatesArray.entries()) {
        if (index === 0) continue; // First check will be on index 1.

        if (!lineDataIdentical(chartData[date], lastDataPoint, lineConfigs)) {
            // No need to check further. Exit loop.
            break;
        }
        // Since this data point is identical to previous data point => delete previous data point. Also set maxDate to this data point date
        delete chartData[reversedDatesArray[index-1]];
        maxDate = chartData[date].momentDate.clone();
    }

    const todayDate = moment().endOf('day');
    const todayDateStr = todayDate.format("YYYY-MM-DD");
    if (!chartData[todayDateStr]) {
        if (minDate.isAfter( todayDate)) minDate = todayDate.clone();
        if (maxDate.isBefore(todayDate)) maxDate = todayDate.clone();
        chartData[todayDateStr] = {momentDate: todayDate.clone()}
    }

    // Add two days to see last change clearly
    if (maxDate.isAfter(todayDate)) {
        maxDate = maxDate.add(2, 'days');
        chartData[maxDate.format('YYYY-MM-DD')] = {momentDate: maxDate.clone()}
    }

    // This loop creates one data record for all visible lines for each calendar day.
    let returnArray = [];
    let currentDate = minDate.clone();
    while (!currentDate.isAfter(maxDate)) {
        const thisDate = currentDate.format("YYYY-MM-DD")

        const newItem = {
            xIndex: returnArray.length,
            momentDate: currentDate.clone(),
            dateString: thisDate,
        }
        const lastDataPoint = returnArray.length ? returnArray[returnArray.length-1] : undefined;
        for (const lineConfig of lineConfigs) {
            if (lineConfig.visibleFrom && currentDate.isBefore(lineConfig.visibleFrom)) continue;
            if (lineConfig.visibleTo   && currentDate.isAfter( lineConfig.visibleTo  )) continue;

            let thisPointValue = undefined;

            // If the graph has data at this date, use it!
            if (chartData[thisDate] && (chartData[thisDate][lineConfig.lineInstanceId] !== undefined)) {
                thisPointValue = chartData[thisDate][lineConfig.lineInstanceId];
            }
            else if (!lastDataPoint) { // First data point. No previous data to use.
                continue;
            }
            // If the same line had data last index, use it!
            else if (lastDataPoint[lineConfig.lineInstanceId] !== undefined) {
                thisPointValue = lastDataPoint[lineConfig.lineInstanceId];
            }
            // If previousDefaultFromLine defined, use data from that line. Used to get future data to be drawn from today.
            else if (previousDefaultFromLine) {
                thisPointValue = lastDataPoint[previousDefaultFromLine];
            }
            newItem[lineConfig.lineInstanceId] = thisPointValue;
        }

        // Create loop to calculate delta values for stacked chart data.
        for (const lineConfig of lineConfigs) {
            newItem[lineConfig.lineInstanceId + '-absolute'] = newItem[lineConfig.lineInstanceId];
            if (!lineConfig.deltaKey) {
                continue;
            }
            if (newItem[lineConfig.deltaKey] === undefined) {
                continue;
            }
            newItem[lineConfig.lineInstanceId + '-delta'] = newItem[lineConfig.lineInstanceId] - newItem[lineConfig.deltaKey];
            if (newItem[lineConfig.lineInstanceId + '-delta'] === 0) {
                newItem[lineConfig.lineInstanceId + '-delta'] = undefined;
            }
        }

        returnArray.push(newItem);
        currentDate.add(1, 'day')
    }
    return returnArray;
}

function datesAreEqual(moment1, moment2) {
    if (moment1.date()  !== moment2.date() ) return false;
    if (moment1.month() !== moment2.month()) return false;
    if (moment1.year()  !== moment2.year() ) return false;
    return true;
}

function renderToolTip(props, decDenLangState, lineConfig) {
    const {payload} = props;
    if (!payload?.length || !payload[0]?.payload) return null;
    const sortedLineConfigs = payload.sort((a, b) => {
        const aVal = a.payload[a.name + '-absolute'];
        const bVal = b.payload[b.name + '-absolute'];
        if (aVal < bVal) return 1;
        if (aVal > bVal) return -1;
        return 0;
    });
    return (
        <div className="xpChartToolTipContainer">
            <div style={{width: '100%', textAlign: 'center'}}>
                <XpDateTime isoString={payload[0].payload.momentDate} format='L'/>
            </div>
            <table style={{margin: '0'}}>
                <tbody>
                {sortedLineConfigs.map((valueObject, index) => {
                    const thisLineConfig = lineConfig.find(item => item.name === valueObject.name);
                    if (!thisLineConfig) {
                        return null;
                    }
                    let numValue = formatAmount(valueObject.value, decDenLangState);
                    return (
                        <tr key={valueObject.name + '-' + valueObject.dataKey + '-' + valueObject.payload.xIndex} style={{border: 'none'}}>
                            <td style={{border: 'none'}}>
                                <div style={{position: 'relative', width: '30px', height: '24px'}}>
                                    <div className='xpChartActiveDot' style={{background: thisLineConfig.stroke}}/>
                                    <div className={'xpChartActiveDotInner'}/>
                                </div>
                            </td>
                            <td style={{border: 'none'}}>
                                <div><XpTranslated trKey={thisLineConfig.toolTipTrKey}/></div>
                            </td>
                            <td style={{textAlign: 'right', border: 'none'}}>
                                <div style={{paddingLeft: '5px'}} className="chart-toolTip chartTooltipNumber">{numValue}</div>
                            </td>
                            {thisLineConfig.toolTipInfoFn &&
                                <td style={thisLineConfig.toolTipInfoStyle}><span style={{paddingLeft: '5px'}} className="chart-toolTip chartTooltipNumber">{thisLineConfig.toolTipInfoFn(payload)}</span></td>}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
}

export function getYMinMax(chartData, lineConfigs) {
    let min = 0
    let max = 0
    for (const dataPoint of chartData) {
        for (const lineConfig of lineConfigs) {
            const checkVal = dataPoint[lineConfig.dataKey];
            if (!isNumber(checkVal)) continue;

            if (checkVal < min) min = checkVal;
            if (checkVal > max) max = checkVal;
        }
    }
    if (min < 0) min = roundToRelevantLevel(Math.abs(min)) * -1;
    if (max > 0) max = roundToRelevantLevel(max);

    return {min: min, max: max};
}

function roundToRelevantLevel(amt) {
    if (amt < 1) return 0; // Code below cannot handle negative exponents, and no need for it either (bugfix 4706)...
    let n = amt.toExponential();
    let m = n.toString().split("+");
    m[0] = m[0].substring(0, m[0].length - 1);
    let initBase = parseFloat(m[0]);
    let x = Math.floor(initBase);
    let remainder = initBase % x;
    let base = x + (remainder >= 0.5 ? 1 : 0.5);
    let expoValue = parseInt(m[1], 10);
    let myExp = Math.pow(10, expoValue);
    return base * myExp;
}

function roundToRelevantYAxisTick(amt) {
    if (amt < 1) return 0;
    let n = amt.toExponential();
    let m = n.toString().split("+");
    m[0] = m[0].substring(0, m[0].length - 1);
    let base = parseFloat(m[0]);
    let expoValue = parseInt(m[1], 10);
    let myExp = Math.pow(10, expoValue);

    return ((base > 5) ? 10 : 5) * myExp
}
