import React, { Component } from 'react'
import {connect} from "react-redux";
import UserMessageRow from "gui-common/userMessages/UserMessageRow"
import 'gui-common/userMessages/userMessages.css';
import {selectAppReadyState} from "gui-common/api/apiSelectors";


class UserMessagesDisplay extends Component {

    renderMessages(messages) {
        if (messages.length === 0) return null;
        if (!this.props.appReady) return null;
        else {
            return messages.map((message) => {
                if (!message.displayPanel) return null;
                return (
                    <UserMessageRow
                        message={message}
                        key={message.id}
                    />
                )}
            );
        }
    }

    extractDisplayedMessages (allMessages) {
        let outList = [];

        for (let message of allMessages) {
//            if (message.requireConfirmation && !message.confirmedDateTime) {
            if (message.displayPanel) {
                outList.push(message);
            }
        }
        return outList;
    }

    render() {
        let displayList = this.extractDisplayedMessages(this.props.userMessages);
        return (
            <div className={"userMessagesContainer" + (this.props.noMenu ? " userMessagesContainerNoMenu" : "") }>
                {this.renderMessages(displayList)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userMessages: state.userMessagesState,
        appReady: selectAppReadyState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMessagesDisplay);


