import {setEnvParameter} from "gui-common/app/appEnvReducer";

export function getAppSpecificEnvConfig () {
    if (!window.oxygen_env || !window.oxygen_env) return {};

    let retParams = {};

    setEnvParameter(retParams,'CURRENT_API_VERSION'          , 'currentApiVersion'         ,  "XP_V30");
    setEnvParameter(retParams,'ENABLE_LEDGER_ACCOUNT_RESET'  , 'enableLedgerAccountReset'  ,  false);
    setEnvParameter(retParams,'TRANSFER_SUPPORTED'           , 'transferSupported'         ,  false);
    setEnvParameter(retParams,'AVAILABLE_ACCOUNT_TYPES'      , 'availableAccountTypes'     ,  [{id: "PHYSICAL", default: true}, {id: "VIRTUAL"}]);
    setEnvParameter(retParams,'AVAILABLE_INTEGRATION_TYPES'  , 'availableIntegrationTypes' ,  [{id: "MANUAL"}, {id: "PSD2", default: true}, {id: "NATIVE"}]);
    setEnvParameter(retParams,'AVAILABLE_CREDIT_TYPES'       , 'availableCreditTypes'      ,  [{id: "NO_CREDIT", default: true}, {id: "CREDIT"}, {id: "UNLIMITED_CREDIT"}]);
    setEnvParameter(retParams,'REPORT_SET_CONFIG'            , 'reportSetConfig'           , {
        ExecutedOrders  : {hide: false, useMockData: false, reportPrefix: 'xpool: '},
    });
    setEnvParameter(retParams,'CLIENT_FORM_CONFIG'           , 'clientFormConfig'          , {
        disableDefaultAccountSettings: true,
        fieldsConfig: {timeZone: {hide: false, isRequired: true}}
    });
    setEnvParameter(retParams,'LEGAL_ENTITY_FORM_CONFIG'     , 'legalEntityFormConfig'     , {
        fieldsConfig: {
            legalPersonType : {hide: true},
            lei             : {hide: true}
        }
    });
    setEnvParameter(retParams,'LEGAL_ENTITY_UNIT_FORM_CONFIG', 'legalEntityUnitFormConfig' , {
        fieldsConfig: {
            fxEnabled       : {hide: false}
        }
    });
    setEnvParameter(retParams,'FX_REJECT_CONFIG'             , 'fxRejectConfig'            , {
        fieldsConfig: {
            retry     : {use: true},
            errorText : {use: false},
        }
    });
    setEnvParameter(retParams,'REQUEST_BALANCE_TIMEOUT'      , 'requestBalanceTimeout'     , 1000);
    setEnvParameter(retParams,'USE_CASH_LADDER'              , 'useCashLadder'             , false);
    setEnvParameter(retParams,'XP_GRID_CONFIG'               , 'xpGridConfig'              , {
        extraGridHeight: 0,
        defaultMaxRows: 10,
        maxRowsOnOrmModel: {
            'Client'            : 10,
            'LegalEntity'       : 5,
            'LegalEntityUnit'   : 5,
            'BankAccount'       : 10,
            'Order'             : 20,
        }
    });
    setEnvParameter(retParams,'CLIENT_FILE_CONFIG'           , 'clientFileConfig'          , {
        fileSpecification: {
            fileExtension: ".csv",
            separator: ';',
            commentLinePrefix: '#',
            encoding: 'UTF-8',
            levelDefinitions: [
                {
                    dataProp: 'clients',
                    columnDefinitions: [
                        {heading: 'ClientName' , field: 'name'           , type: 'string', mandatory: true},
                        {heading: 'OrgNr'      , field: 'clientRefId'    , type: 'string', mandatory: true},
                        {heading: 'OpUnit'     , field: 'operatingUnitId', type: 'string', mandatory: true, lookUpToId: 'opUnits'},
                        {heading: 'GetBalances', field: 'getBalancesTime', type: 'string', mandatory: true},
                        {heading: 'Execute'    , field: 'executeTime'    , type: 'string', mandatory: true},
                        {heading: 'StopTrying' , field: 'stopTryingTime' , type: 'string', mandatory: true},
                    ],
                    enrichItem: item => ({
                        runConfiguration: {
                            dailyRun: [{isActive: true, getBalancesTime: item.getBalancesTime, executeTime: item.executeTime, stopTryingTime: item.stopTryingTime, runOnMondays: true, runOnTuesdays: true, runOnWednesdays: true, runOnThursdays: true, runOnFridays: true, }],
                            scheduling: [{"isActive": true}]
                        },
                        useLegalEntities: false,
                        timeZone: "Europe/Stockholm",
                        cashPools: [{
                            "name": "default",
                            "description": "",
                            "includedLegalEntities": null
                        }]
                    }),
                },
                {
                    dataProp: 'legalEntities',
                    columnDefinitions: [
                        {heading: 'LEName'     , field: 'name'           , type: 'string', mandatory: true},
                        {heading: 'Country'    , field: 'countryId'      , type: 'string', mandatory: true, lookUpToId: 'countries'},
                        {heading: 'ClientName' , field: 'clientName'     , type: 'string', mandatory: true},
                    ],
                    enrichItem: item => ({
                        useLegalEntityUnits: false,
                        legalPersonType: "CORPORATE",
                    }),
                },
                {
                    dataProp: 'legalEntityUnits',
                    columnDefinitions: [
                        {heading: 'LEUName'    , field: 'name'           , type: 'string', mandatory: true},
                        {heading: 'ShortName'  , field: 'fxShortName'    , type: 'string', mandatory: true},
                        {heading: 'FXProvider' , field: 'fxProviderId'   , type: 'string', mandatory: true, lookUpToId: 'fxProviders'},
                        {heading: 'LEName'     , field: 'leName'         , type: 'string', mandatory: true},
                    ],
                    enrichItem: item => ({
                        fxEnabled: true,
                    }),
                },
                {
                    dataProp: 'topAccounts',
                    columnDefinitions: [
                        {heading: 'TopAccountName'    , field: 'name'          , type: 'string', mandatory: true},
                        {heading: 'Currency'          , field: 'currencyId'    , type: 'string', mandatory: true, lookUpToId: 'currencies'},
                        {heading: 'ClearingNumber'    , field: 'clearingNumber', type: 'string', mandatory: true},
                        {heading: 'AccountNumber'     , field: 'accountNumber' , type: 'string', mandatory: true},
                        {heading: 'AccountInstitution', field: 'institutionId' , type: 'string', mandatory: true, lookUpToId: 'bankAccountInstitutions'},
                        {heading: 'LEUName'           , field: 'leuName'       , type: 'string', mandatory: true},
                    ],
                    enrichItem: item => ({
                        creditType: "UNLIMITED_CREDIT",
                        accountType: "PHYSICAL",
                    }),
                },
                {
                    dataProp: 'subAccounts',
                    columnDefinitions: [
                        {heading: 'SubAccountName'    , field: 'name'          , type: 'string', mandatory: true},
                        {heading: 'Currency'          , field: 'currencyId'    , type: 'string', mandatory: true, lookUpToId: 'currencies'},
                        {heading: 'ClearingNumber'    , field: 'clearingNumber', type: 'string', mandatory: true},
                        {heading: 'AccountNumber'     , field: 'accountNumber' , type: 'string', mandatory: true},
                        {heading: 'AccountInstitution', field: 'institutionId' , type: 'string', mandatory: true, lookUpToId: 'bankAccountInstitutions'},
                        {heading: 'SweepThreshold'    , field: 'sweepThreshold', type: 'number', mandatory: true},
                        {heading: 'SweepTarget'       , field: 'sweepTarget'   , type: 'number', mandatory: true},
                        {heading: 'TopUpThreshold'    , field: 'topUpThreshold', type: 'number', mandatory: true},
                        {heading: 'TopUpTarget'       , field: 'topUpTarget'   , type: 'number', mandatory: true},
                        {heading: 'ParentAccountName' , field: 'parentAccountName', type: 'string', mandatory: true},
                    ],
                    enrichItem: item => ({
                        runConfiguration: {
                            sweepAndTopUp: [{isActive: true, sweepThreshold: item.sweepThreshold, sweepTarget: item.sweepTarget, topUpThreshold: item.topUpThreshold, topUpTarget: item.topUpTarget, currencyId: item.currencyId, useSweep: true, useTopUp: true, useSwapForSweep: false, useSwapForTopUp: false}],
                        },
                        creditType: "NO_CREDIT",
                        accountType: "PHYSICAL",
                    }),
                }
            ],
            dataTree: [{
                dataProp: 'clients',
                property: 'clients',
                idProp: 'name',
                parentIdProp: null,
                children: [
                    {
                        dataProp: 'legalEntities',
                        property: 'legalEntities',
                        idProp: 'name',
                        parentIdProp: 'clientName',
                        children: [
                            {
                                dataProp: 'legalEntityUnits',
                                property: 'legalEntityUnits',
                                idProp: 'name',
                                parentIdProp: 'leName',
                                children: [
                                    {
                                        dataProp: 'topAccounts',
                                        property: 'bankAccounts',
                                        idProp: 'name',
                                        parentIdProp: 'leuName',
                                        children: [
                                            {
                                                dataProp: 'subAccounts',
                                                property: 'bankAccounts',
                                                idProp: 'name',
                                                parentIdProp: 'parentAccountName',
                                                children: null,
                                            }]
                                    }]
                            }]
                    }]
            }],
        },
    });
    setEnvParameter(retParams,'ONBOARDING_WIZARD_FILE_CONFIG', 'onboardingWizardFileConfig', {
        fileSpecification: {
            fileExtension: ".csv",
            separator: ';',
            commentLinePrefix: '#',
            encoding: 'UTF-8',
            levelDefinitions: [
                {
                    dataProp: 'clients',
                    columnDefinitions: [
                        {heading: 'Client Name'         , field: 'name'           , type: 'string' , mandatory: true, minLength: 2, maxLength: 50},
                        {heading: 'Client Description'  , field: 'description'    , type: 'string' , mandatory: false, maxLength: 100},
                        {heading: 'Country Code'        , field: 'countryId'      , type: 'string' , mandatory: true, lookUpToId: 'countries'},
                        {heading: 'Client ID'           , field: 'clientRefId'    , type: 'string' , mandatory: true, exactLength: 12},
                        {heading: 'Account Manager'     , field: 'accountManager' , type: 'string' , mandatory: false},
                        {heading: 'Operating Unit'      , field: 'operatingUnitId', type: 'string' , mandatory: true, lookUpToId: 'operatingUnits'},
                        {heading: 'Fx Short Name'       , field: 'fxShortName'    , type: 'string' , mandatory: true, minLength: 2, maxLength: 20},
                        {heading: 'FX Provider'         , field: 'fxProviderId'   , type: 'string' , mandatory: true, lookUpToId: 'fxProviders'},
                        {heading: 'Get Balances Time'   , field: 'getBalancesTime', type: 'string' , mandatory: false},
                        {heading: 'Execute Time'        , field: 'executeTime'    , type: 'string' , mandatory: false},
                        {heading: 'Stop Trying Time'    , field: 'stopTryingTime' , type: 'string' , mandatory: false},
                        {heading: 'Run Type'            , field: 'clientRunType'  , type: 'string' , mandatory: true, lookUpToId: 'runTypes'},
                        {heading: 'Run on Mondays'      , field: 'runOnMondays'   , type: 'boolean', mandatory: false},
                        {heading: 'Run on Tuesdays'     , field: 'runOnTuesdays'  , type: 'boolean', mandatory: false},
                        {heading: 'Run on Wednesdays'   , field: 'runOnWednesdays', type: 'boolean', mandatory: false},
                        {heading: 'Run on Thursdays'    , field: 'runOnThursdays' , type: 'boolean', mandatory: false},
                        {heading: 'Run on Fridays'      , field: 'runOnFridays'   , type: 'boolean', mandatory: false},
                        {heading: 'Base Currency'       , field: 'topCurrencyId'  , type: 'string' , mandatory: true, lookUpToId: 'currencies'},
                        {heading: 'Day Type' , field: 'dayType'                   , type: 'string' , mandatory: false, lookUpToId: 'dayTypes',
                            errorFunctions: {
                                noDayTypeForMonthlyRun: (client) => {
                                    if (client.runType !== 'MONTHLY') return false;
                                    if (client.dayType) return false;
                                    return {
                                    };
                                }
                            }
                        },
                        {heading: 'Run Day' , field: 'runDay'           , type: 'string', mandatory: false, lookUpToId: 'runDays',
                            errorFunctions: {
                                noRunDayForMonthlyRun: (client) => {
                                    if (client.runType !== 'MONTHLY') return false;
                                    if (client.dayType !== 'DAY') return false;
                                    if (client.runDay) return false;
                                    return {};
                                }
                            }
                        },
                        {heading: 'Seek Strategy' , field: 'seekStrategy'           , type: 'string', mandatory: false, lookUpToId: 'seekStrategies',
                            errorFunctions: {
                                noSeekStrategyForMonthlyRun: (client) => {
                                    if (client.runType !== 'MONTHLY') return false;
                                    if (client.dayType !== 'DAY') return false;
                                    if (client.seekStrategy) return false;
                                    return {};
                                }
                            }
                        },
                    ],
                },
                {
                    dataProp: 'bankAccounts',
                    columnDefinitions: [
                        {heading: 'Currency Code'   , field: 'currencyId'    , type: 'string', mandatory: true, lookUpToId: 'currencies'},
                        {heading: 'Account Name'    , field: 'name'          , type: 'string', mandatory: true},
                        {heading: 'Clearing Number' , field: 'clearingNumber', type: 'string', mandatory: true,
                            errorFunctions: {
                                incorrectClearingNumberLength: (account) => {
                                    if (account.clearingNumber.length === 5) return false;
                                    return {
                                        account: account.clearingNumber + '-' + account.accountNumber,
                                        length: account.clearingNumber.length,
                                        checkValue: 5,
                                    };
                                }
                            }
                        },
                        {heading: 'Account Number'     , field: 'accountNumber' , type: 'string', mandatory: true,
                            errorFunctions: {
                                incorrectAccountNumberLength: (account) => {
                                    if (account.accountNumber.length === 10) return false;
                                    return {
                                        account: account.clearingNumber + '-' + account.accountNumber,
                                        length: account.accountNumber.length,
                                        checkValue: 10,
                                    };
                                }
                            }
                        },
                        {heading: 'Account Institution', field: 'institutionId' , type: 'string', mandatory: true, lookUpToId: 'institutions'},
                        {heading: 'Activate Sweep'      , field: 'useSweep', type: 'boolean', mandatory: false},
                        {heading: 'Activate Top Up'     , field: 'useTopUp', type: 'boolean', mandatory: false},
                        {heading: 'Use Swap For Sweep'  , field: 'useSwapForSweep', type: 'boolean', mandatory: false},
                        {heading: 'Use Swap For Top Up' , field: 'useSwapForTopUp', type: 'boolean', mandatory: false},
                        {heading: 'Sweep Threshold'    , field: 'sweepThreshold', type: 'number', mandatory: false},
                        {heading: 'Sweep Target'       , field: 'sweepTarget'   , type: 'number', mandatory: false},
                        {heading: 'Top Up Threshold'    , field: 'topUpThreshold', type: 'number', mandatory: false},
                        {heading: 'Top Up Target'       , field: 'topUpTarget'   , type: 'number', mandatory: false},
                        {heading: 'Get Balances Time'   , field: 'getBalancesTime', type: 'string' , mandatory: false},
                        {heading: 'Execute Time'        , field: 'executeTime'    , type: 'string' , mandatory: false},
                        {heading: 'Stop Trying Time'    , field: 'stopTryingTime' , type: 'string' , mandatory: false},
                        {heading: 'Minimum Trade Size'  , field: 'minimumTradeSize', type: 'number', mandatory: false},
                        {heading: 'Maximum Trade Size'  , field: 'maximumTradeSize', type: 'number', mandatory: false},
                        {heading: 'Use Split'           , field: 'useSplit', type: 'boolean', mandatory: false,
                            errorFunctions: {
                                splitDefinedWithoutMaximumTradeSize: (account) => {
                                    if (account.maximumTradeSize) return false;
                                    if (account.useSplit) return {account: account.clearingNumber + '-' + account.accountNumber};
                                    return false;
                                },
                                maximumTradeSizeLowerThan1: (account) => {
                                    if ((account.maximumTradeSize === undefined) || (account.maximumTradeSize === "")) return false;
                                    if (account.maximumTradeSize < 1) return {account: account.clearingNumber + '-' + account.accountNumber};
                                    return false;
                                },
                            }
                        },
                        {heading: 'Split Percent'     , field: 'splitPercent', type: 'string', mandatory: false,
                            errorFunctions: {
                                incorrectSplitPercentValue: (account) => {
                                    if (!account.useSplit) return false;
                                    if ((Number(account.splitPercent) <= 0) || (Number(account.splitPercent) >= 100)) return {account: account.clearingNumber + '-' + account.accountNumber};
                                    return false;
                                },
                            }
                        }
                    ],
                    enrichItemFn: account => {
                        account.currencyName = account.currencyId_source;
                        account.accountType  = "PHYSICAL";
                        account. useFor      =  'ALL';
                        account.overrideRunTimes = (!!account.getBalancesTime || !!account.executeTime || !!account.stopTryingTime);
                        account.useMaxMinSplit   = (!!account.maximumTradeSize || !!account.minimumTradeSize || !!account.useSplit || !!account.splitPercent);
                    },
                }
            ],
            dataTree: [{
                dataProp: 'clients',
                property: 'clients',
                errorFunctions: {
                    noBaseCurrencyAccount: client => {
                        const accountCurrencies = client.currencies?.length ? client.currencies.map(item => item.currencyId) : undefined;
                        if (!accountCurrencies || !accountCurrencies.includes(client.topCurrencyId)) {
                            return {topCurrencyId: client.topCurrencyId_source}
                        }
                        return false;
                    }
                },
                children: [
                    {
                        dataProp: 'bankAccounts',
                        property: 'currencies',
                        children: null,
                    }]
            }],
        },
    });
    setEnvParameter(retParams,'AUDIT_CONFIG'                 , 'auditConfig'               , {
        activateObjectAuditLog: true,
        auditActionTypes: {
            BalanceRequestAudit: {
                actionDataTransformer   : actionData => !actionData?.relatedObjects ? null : ({
                    clientName    : actionData.relatedObjects.shallowClient.name,
                    accountNumber : actionData.relatedObjects.bankAccount.clearingNumber + '-' + actionData.relatedObjects.bankAccount.accountNumber,
                    accountId     : actionData.relatedObjects.bankAccount.id,
                }),
            },
            OrderExecutionForClientAudit: {
                actionDataTransformer   : actionData => !actionData?.relatedObjects ? null : ({
                    clientName    : actionData.relatedObjects.client.name,
                    orders        : actionData.relatedObjects.orders.map(order => ({orderId: order.id, nominalAmount: order.nominalAmount, currencyPair: order.currencyPair.key, orderType: order.orderType, side: order.side})),
                }),
            },
            AddNoRunDateAudit: {
                actionDataTransformer   : actionData => !actionData?.parameter ? null : ({
                    noRunDate    : actionData.parameter.noRunDate,
                }),
            },
            DeleteNoRunDateAudit: {
                actionDataTransformer   : actionData => !actionData?.parameter ? null : ({
                    noRunDate    : actionData.parameter.noRunDate,
                }),
            },
            RequestCancelledClientRunStateAudit: {
                actionDataTransformer   : actionData => !actionData?.relatedObjects ? null : ({
                    clientName    : actionData.relatedObjects.client.name
                }),
            },
            RequestInitialClientRunStateAudit: {
                actionDataTransformer   : actionData => !actionData?.relatedObjects ? null : ({
                    clientName    : actionData.relatedObjects.client.name
                }),
            },
            RequestCancelledBarRunStateAudit: {
                actionDataTransformer   : actionData => !actionData?.relatedObjects ? null : ({
                    clientName    : actionData.relatedObjects.client.name,
                    accountNumber : actionData.relatedObjects.instructingAccount.clearingNumber + '-' + actionData.relatedObjects.instructingAccount.accountNumber,
                    accountId     : actionData.relatedObjects.instructingAccount.id,
                }),
            },
            RequestInitialBarRunStateAudit: {
                actionDataTransformer   : actionData => !actionData?.relatedObjects ? null : ({
                    clientName    : actionData.relatedObjects.client.name,
                    accountNumber : actionData.relatedObjects.instructingAccount.clearingNumber + '-' + actionData.relatedObjects.instructingAccount.accountNumber,
                    accountId     : actionData.relatedObjects.instructingAccount.id,
                }),
            },
            RequestRestartBarAudit: {
                actionDataTransformer   : actionData => !actionData?.relatedObjects ? null : ({
                    clientName    : actionData.relatedObjects.client.name,
                    accountNumber : actionData.relatedObjects.instructingAccount.clearingNumber + '-' + actionData.relatedObjects.instructingAccount.accountNumber,
                    accountId     : actionData.relatedObjects.instructingAccount.id,
                }),
            },
            OrderActionRequestAudit: {
                actionDataTransformer   : actionData => !actionData?.relatedObjects ? null : ({
                    action        : actionData.parameter.action,
                    clientName    : actionData.relatedObjects.client.name,
                    orderId       : actionData.relatedObjects.order.id,
                    nominalAmount : actionData.relatedObjects.order.nominalAmount,
                    currencyPair  : actionData.relatedObjects.order.currencyPair.key,
                    orderType     : actionData.relatedObjects.order.orderType,
                    side          : actionData.relatedObjects.order.side
                }),
            }
        }
    });
    setEnvParameter(retParams,'MAX_MANUAL_BALANCE'           , 'maxManualBalance'          , 100000000000000);
    setEnvParameter(retParams,'RUN_CONFIGURATION_CONFIG'     , 'runConfigurationConfig'    , {
        dailyRun: {
            maxElements: 1,
        },
        monthlyRun: {
            maxElements: 1,
        },
        maxMinSplit: {
            maxElements: 2,
        },
        holdOnRate: {
            maxElements: 2,
            maxDecimals: 4,
        },
        sweepAndTopUp: {
            useSweepHorizon: false,
        },
        scheduling: {
        },
        hedgeExposure: {
            isDisabled: true,
            maxElements: 1,
        },
    });
    setEnvParameter(retParams,'BALANCE_MOVEMENTS_CONFIG'     , 'balanceMovementsConfig'    , {
        allowManualRegistration: false,
        maxBalanceMovementAmount: 1000,
    });
    setEnvParameter(retParams,'CLIENT_GUI_CONFIG'            , 'clientGuiConfig'           , {
        showMenu: false,
    });
    setEnvParameter(retParams,'BALANCE_SIM_URL'              , 'balanceSimBaseUrl'         , "");
    setEnvParameter(retParams,'USE_MUI_FIELD_STYLE'          , 'useMuiFieldStyle'          , false);
    return retParams;
}
