import {useSelectorInstance} from "gui-common/functions/hooks";
import {
    getXpFormDependentFieldsSelector,
    getXpFormFieldErrorsSelector,
    getXpFormFieldStateSelector,
    getXpFormRevisionSelector,
    getXpFormRootSelector,
    getXpFormStateSelector,
    getXpFormUseStateSelector,
} from "gui-common/xpForm/core/xpFormSelectors";
import {useContext, useEffect, useMemo, useState} from "react";
import {xpFormAddField, xpFormSetFieldErrors} from "gui-common/xpForm/core/xpFormReducer";
import {useDispatch} from "react-redux";
import {XP_FORM_NEW, XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {XpFormContext} from "gui-common/xpForm/core/XpForm";

export function useXpFormContext() {
    return  useContext(XpFormContext);
}
export function useXpFormState(formModel) {
    const formState  = useSelectorInstance(getXpFormStateSelector   , {model: formModel})
    return formState;
}
export function useXpFormUseState(formModel) {
    const formUseState  = useSelectorInstance(getXpFormUseStateSelector   , {model: formModel})
    return formUseState;
}

export function useXpFormModel(props, formContext) {
    const fieldModel = useMemo(
        () => {
            if (props.fieldModel) return props.fieldModel;
            if (formContext?.formModel) return formContext.formModel + '.' + props.field;
            return "";
        },
        [props.fieldModel, props.field, formContext]
    );
    return fieldModel;
}

export function useXpFormRootFormData(formModel) {
    const formRoot      = useSelectorInstance(getXpFormRootSelector    , {model: formModel});
    const formRevision  = useSelectorInstance(getXpFormRevisionSelector, {model: formModel});

    const [lastRevision   , setLastRevision   ] = useState(0);

    useEffect(
        () => {
            setLastRevision(formRevision);
        },
        [formRevision],
    );

    if (formRevision !== lastRevision) return {...formRoot}
    return formRoot;
}

export function useXpFormFields(props) {
    const formContext = useContext(XpFormContext);
    const [memFields, setMemFields] = useState({});

    const dependentFieldValues = useSelectorInstance(getXpFormDependentFieldsSelector, {
        formModel           : props.formModel,
        rootFormModel       : props.rootFormModel,
        formUseState        : props.formUseState,
        rootCurrentData     : props.rootCurrentData,
        currentData         : props.currentData,
        ...formContext,
        dependentFields     : props.dependentFields,
        dependentFieldModels: props.dependentFieldModels,
        currentState        : memFields
    })

    return useMemo(
        () => {
            setMemFields(dependentFieldValues);
            return dependentFieldValues;
        },
        [dependentFieldValues]
    );
}
export function useXpFormField(fieldModel, props) {
    const formContext = useContext(XpFormContext);

    const fullFieldModel = useMemo(
        () => {
            if (!formContext) return fieldModel;
            const fieldModelArray = fieldModel.split('.');
            const formModelArray  = formContext.formModel.split('.');
            if (fieldModelArray[0] === formModelArray[0]) {
                return fieldModel
            }
            return formContext.formModel + '.' + fieldModel;
        },
        [formContext, fieldModel]
    );
    const fields = useXpFormFields({
        dependentFieldModels: {field: fullFieldModel},
        ...props,
    })
    return useMemo(
        () => {
            return fields.field;
        },
        [fields]
    );
}
export function useXpFormFieldErrors(errors, fieldModel, formContext, fieldState, dependentFields, dependentData) {
    const fieldErrors     = useSelectorInstance(getXpFormFieldErrorsSelector, {errorFunctions: errors, fieldState: fieldState, dependentFields: dependentFields, dependentData: dependentData})
    const dispatch        = useDispatch();
    useEffect(
        () => {
            if (!fieldState || fieldState.addField) return; // This is no real redux state, only a placeholder to trigger field metadata creation in useXpFormFieldState
            if (!fieldErrors && !fieldState.errors) return;
            dispatch(xpFormSetFieldErrors(fieldModel, fieldErrors))
        },
        [fieldErrors],
    );
}

export function useXpFormFieldState(props, fieldModel, formContext, defaultValue) {
    const fieldState = useSelectorInstance(getXpFormFieldStateSelector, {model: fieldModel, addIfMissing: true})
    const dispatch   = useDispatch();
    useEffect(
        () => {
            if (formContext.formUseState === XP_FORM_VIEW) return;
            if (!fieldState || fieldState.addField) dispatch(xpFormAddField(fieldModel, (defaultValue !== undefined) ? defaultValue : ''));
        },
        [fieldState, formContext?.formUseState],
    );
    return useMemo(()=>{
        return fieldState;
    }, [fieldState])
}

