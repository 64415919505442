import {createSelector as createOrmSelector} from "redux-orm";
import {createSelector} from "reselect";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {createTranslatedFieldData, translateBoolFields} from "gui-common/xpGrid/xpGridFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

export const usersToAdminSelector = createOrmSelector(
    orm,
    dbStateSelector,
    state => state.webSessionState.userSystemId,
    (session, loggedInUserId) => {
        let returnArray = [];
        session.User.all().exclude({id: loggedInUserId}).toModelArray().forEach(user => {
            const obj = user.ref;
            returnArray.push(Object.assign({}, obj, {
                fullName: user.firstName + " " + user.lastName,
            }));
        });
//        console.log("Users: ", returnArray);
        return returnArray;
    }
);


const translate        = (state, props) => selectTranslateFunction(state);
const usersToAdmin     = state          => usersToAdminSelector(state);
export const getUsersToAdminDataSelector = () => createSelector(
    [usersToAdmin, translate],
    (usersToAdmin, translate) => {
        // console.log("Selector getLegalEntitiesListDataSelector running ");
        let returnArray       = [];
        for (let user of usersToAdmin) {
            const enrichedUser = {...user};

            translateBoolFields(enrichedUser, translate);
            enrichedUser.countryName = user.country ? user.country.country : "";

            createTranslatedFieldData(enrichedUser, 'state', translate, 'rightsUserList.userStates.' + enrichedUser.state);
            if (user.profilesStr) createTranslatedFieldData(enrichedUser, 'profilesStr', translate, null);
            else                  createTranslatedFieldData(enrichedUser, 'profilesStr', translate, 'userPreferencesForm.noProfilesAssigned');

            returnArray.push(enrichedUser);
        }
        // console.log("Selected getLegalEntitiesListDataSelector:", returnArray);
        return returnArray;
    }
);
