import {appSpecificDashboardConfig} from "appConfig/dashboard/appDashboardConfig"
import IntegrationStatusContainer from "gui-common/integrationStatus/IntegrationStatusContainer";
import UserMessagesList from "gui-common/userMessages/UserMessagesList";
import FxPriceList from "gui-common/fxPrice/FxPriceList";
import MarketSimulator from "gui-common/simulators/testMarket/MarketSimulator";
import FixLogList from "gui-common/fixLog/FixLogList";
import {userCan} from "../functions/userRights";

export const dashboardConfig = {
    'IntegrationStatusContainer': {
        component           : IntegrationStatusContainer,
        authorizedFunction  : null,
        langPath            : 'integrationStatus.dashboardComponent.',
        icon                : 'traffic',
        keyName             : 'traffic',
        defaultSize         : {w: 3, h: 15}
    },
    'UserMessagesList': {
        component           : UserMessagesList,
        authorizedFunction  : null,
        langPath            : 'userMessages.dashboardComponent.',
        icon                : 'announcement',
        keyName             : 'userMessages',
    },
    'FxPriceList': {
        component           : FxPriceList,
        authorizedFunction  : null,
        langPath            : 'fxPrices.dashboardComponent.',
        icon                : 'euro_symbol',
        keyName             : 'fxPrices',
        defaultSize         : {w: 3, h: 10}
    },
    'FixLogList': {
        component           : FixLogList,
        authorizedFunction  : (user) => userCan('Read', 'ShowFixLog', user),
        langPath            : 'fixLog.fixLogList.dashboardComponent.',
        icon                : 'question_answer',
        keyName             : 'fixLog',
        defaultSize         : {w: 6, h: 10}
    },
    'MarketSimulator': {
        component           : MarketSimulator,
        authorizedFunction  : () => (window.oxygen_env.DEV_MODE === true),
        langPath            : 'marketSimulator.dashboardComponent.',
        icon                : 'currency_exchange',
        keyName             : 'marketSimulator',
        defaultSize         : {w: 7, h: 12}
    },

    ...appSpecificDashboardConfig,
};
