import React, {useMemo} from "react";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {getCurrencyDashboardErpConfigAdminFormConfig} from "xpool-gui-common/appConfig/xpAdminFormConfig";

export default function CurrencyDashboardErpSettingsForm(props) {
    const xpAdminFormConfig = useMemo(
        () => {
            return getCurrencyDashboardErpConfigAdminFormConfig();
        },
        []
    );

    if (!props.client?.legalEntities || !props.client.legalEntities[0]) return null;
    return (
        <XpAdminForm
            entityId              = {props.client.legalEntities[0].id}
            xpAdminFormInstanceId = 'erpSettingsForm'
            xpAdminFormConfig     = {xpAdminFormConfig}
            ormModel              = 'LegalEntity'
            suppressAccordion     = {true}
            hideDeleteButton      = {true}
        />
    )
}
