import React from 'react';
import {connect} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {fxPricesToDisplaySelector} from "gui-common/fxPrice/fxPriceSelectors";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import {formatFxPricesGridData, prepareFxPricesColumns} from "gui-common/fxPrice/fxPriceFunctions";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


class FxPriceList extends React.Component {

    onGridCellClicked(params)  {return true;}
    onGridRowClicked(params)   {return true;}
    onGridObjectSelected(data) {return true;}
    gridContextMenuItems(params) {
        let menuItems = [];
        // let menuItem = {};
        return menuItems;
    }

    render() {
        const hiddenState = this.props.hiddenState ? this.props.hiddenState : {
            currencyPair: false,
            rate:         false,
            indicator:    false,
            timeT:        true,
        };

        const fxPricesDataToRender = this.props.fxPricesData ? this.props.fxPricesData : this.props.fxPricesToDisplay;
        const dataToRender = cloneDeep(fxPricesDataToRender); // Get separate copy.
        let gridData = formatFxPricesGridData(dataToRender, this.props.translate, this.props.currentLanguage);
        let preparedColumns = prepareFxPricesColumns(this.props.translate, hiddenState);

        return (
            <XpGrid
                gridCellClicked={(params) => this.onGridCellClicked(params)}
                gridRowClicked={(params) => this.onGridRowClicked(params)}
                gridObjectSelected={(data) => this.onGridObjectSelected(data)}
                gridContextMenuItems={(params) => this.gridContextMenuItems(params)}

                gridId={"ordersGrid" + this.props.instanceId}
                instanceId={this.props.instanceId}

                columnDefs={preparedColumns}
                rowData={gridData}
                callingContext={this}

                treeData={false}
                ormModel='FxPrice'

                overlayNoRowsTemplate={this.props.translate('fxPrices.noPricesToShow')}

                fillAvailableSpace={this.props.fillAvailableSpace}
            />
        );
    };
}
const mapStateToProps = (state, props) => {
    return {
        translate:          selectTranslateFunction(state),
        currentLanguage:    selectActiveLanguage(state),
        fxPricesToDisplay:  fxPricesToDisplaySelector(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

const ConnectedFxPriceList = connect(mapStateToProps, mapDispatchToProps)(FxPriceList);

ConnectedFxPriceList.propTypes = {
    instanceId:           PropTypes.string.isRequired,
    fxPricesData:         PropTypes.array,
    filterCurrencyPairs:  PropTypes.array,
    hiddenState:          PropTypes.object,
};

ConnectedFxPriceList.defaultProps = {
    filterCurrencyPairs:  undefined,
    fxPricesData:         undefined,
    hiddenState:          undefined,
};

export default ConnectedFxPriceList;
