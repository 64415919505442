import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import removeIcon from "gui-common/resources/removeIcon.png";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {negativeNumberInputParser, rateInputParser} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {getClientPriceFromApi, overrideClientPriceToApi} from "gui-common/simulators/testMarket/marketSimulatorApi";
import {useCurrencyPair} from "gui-common/orm/ormSelectors";
import {xpFormChangeField, xpFormLoadForm} from "gui-common/xpForm/core/xpFormReducer";
import moment from "moment";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {isArray, isObject} from "lodash";
import XpTooltip from "gui-common/components/XpTooltip";

function RenderField (props) {
    const decDenLangState = useSelector(selectDecDenLangState);
    return (
        <div style={{position: 'relative'}} >
            <XpFormTextInput
                alignRight noTemplate
                isRequired={props.isRequired}
                defaultValue={props.defaultValue}
                inLineLayout={!props.notInline}
                inLineDivider={props.split ? props.split : 40}
                // label={props.label + (props.clientPrice.clientPrice ? props.clientPrice.clientPrice[props.field] : "")}
                label={props.label}
                field         = {props.field}
                updateOn      = "blur"
                parser={props.noParser ? undefined : value => negativeNumberInputParser(value, decDenLangState)}
            />
            {((props.currentValue !== null) && (props.currentValue !== undefined)) &&
            <div style={{position: 'absolute', top: (props.notInline ? '53px' : '20px'), left:' 0px', fontSize: '12px', color: 'var(--xpool-primary-color-x-strong)'}}>{
                props.fixedDecimals ? props.currentValue.toFixed(props.fixedDecimals) : props.currentValue
            }</div>}
        </div>
    )
}


function MarketSimulatorConfigItem (props) {

    const dispatch        = useDispatch();
    // const configState     = useSelector(state => marketSimulatorConfigSelector(state, props.currencyPair, tenor));
    // const [localState, setLocalState] = useState(configState ? configState  : defaultSimulatorConfigItem);

    const currencyPair = useCurrencyPair(props.currencyPairId);
    const [clientPrice, setClientPrice] = useState({sessionId: props.sessionId, currencyPair: currencyPair, tenor: props.tenor, fixAccount: props.fixAccount});
    const [fetchingPrice, setFetchingPrice] = useState(false);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [fixAccountOverrides, setFixAccountOverrides] = useState(undefined);

    const formModel = useMemo(
        () => {
            return "marketSimulator" + '.' + props.sessionId.replaceAll('.', '-') + (props.fixAccount ? ('.' + props.fixAccount) : "") + '.' + currencyPair.key + '.' + props.tenor;
        },
        []
    );
    const formData   = useXpFormField(formModel);

    useEffect(
        () => {
            if (!formData?.lastUpdate || !lastUpdate || moment.utc(formData?.lastUpdate).isAfter(lastUpdate)) {
                fetchCurrentPrice()
            }
        },
        [formData?.lastUpdate],
    );


    function checkPriceDataAndSetFormField(clientPriceData, formSubData, FormSubModel) {
        if (!clientPriceData || !formSubData) {
            return;
        }
        for (const key in clientPriceData) {
            if (clientPriceData[key] === null) {
                continue;
            }
            if (formSubData[key] !== clientPriceData[key]) {
                dispatch(xpFormChangeField(FormSubModel + '.' + key, clientPriceData[key]));
            }
        }
    }
    useEffect(
        () => {
            if (!formData) {
                return;
            }
            checkPriceDataAndSetFormField(clientPrice?.clientPriceOverride                       , formData                         , formModel);
            checkPriceDataAndSetFormField(clientPrice?.functionOverride?.quote                   , formData.quote                   , formModel + '.quote');
            checkPriceDataAndSetFormField(clientPrice?.functionOverride?.newOrderSingle          , formData.newOrderSingle          , formModel + '.newOrderSingle');
            checkPriceDataAndSetFormField(clientPrice?.functionOverride?.priceStreamConfiguration, formData.priceStreamConfiguration, formModel + '.priceStreamConfiguration');
        },
        [clientPrice],
    );

    function  fetchCurrentPrice() {
        setFetchingPrice(true);
        dispatch(getClientPriceFromApi({sessionId: props.sessionId, currencyPair: currencyPair, tenor: props.tenor, fixAccount: props.fixAccount}))
            .then(resp => {
                setFetchingPrice(false);
                if (!resp?.response?.length) {
                    return;
                }
                let clientPrice = resp.response.find(item => !item.fixAccount);
                if (props.fixAccount) {
                    const fixAccountClientPrice = resp.response.find(item => item.fixAccount === props.fixAccount);
                    if (fixAccountClientPrice) {
                        clientPrice = fixAccountClientPrice;
                    }
                }
                if (resp.response.length > 1) {
                    const fixAccountOverrides = resp.response.reduce((overrides, price) => {
                        if (price.fixAccount) {
                            overrides.push(price);
                        }
                        return overrides;
                    }, []);
                    setFixAccountOverrides(fixAccountOverrides);
                }
                else {
                    setFixAccountOverrides(undefined);
                }

                if (!clientPrice) {
                    console.error("No client price for config ", props);
                    return;
                }

                setClientPrice(clientPrice);
                const now = moment().utc().milliseconds(0);
                const nowStr = now.toISOString();
                dispatch(xpFormChangeField(formModel + '.lastUpdate', nowStr));
                setLastUpdate(now);
            });
    }

    function getFunctionOverride(functionFormData) {
        if (!functionFormData?.function && !functionFormData?.spreadType) return null;
        return {
            function       : functionFormData.function        ? functionFormData.function        : null,
            spreadType     : functionFormData.spreadType      ? functionFormData.spreadType      : null,
            responseDelay  : functionFormData.responseDelay   ? functionFormData.responseDelay   : null,
            errorCode      : functionFormData.errorCode       ? functionFormData.errorCode       : null,
            errorMessage   : functionFormData.errorMessage    ? functionFormData.errorMessage    : null,
            spreadInterval : functionFormData.spreadInterval  ? functionFormData.spreadInterval  : null,
            updateInterval : functionFormData.updateInterval  ? functionFormData.updateInterval  : null,
        }
    }

    function  overrideCurrentPrice() {
        setFetchingPrice(true);
        dispatch(overrideClientPriceToApi(
            {
                sessionId: props.sessionId,
                currencyPair: currencyPair,
                tenor: props.tenor,
                fixAccount: props.fixAccount,
                clientPriceOverride: {
                    askClientPrice        : formData?.askClientPrice     ? formData?.askClientPrice : null,
                    bidClientPrice        : formData?.bidClientPrice     ? formData?.bidClientPrice : null,
                    askIBForwardPoints    : formData?.askIBForwardPoints ? formData?.askIBForwardPoints : null,
                    bidIBForwardPoints    : formData?.bidIBForwardPoints ? formData?.bidIBForwardPoints : null,
                    askClientSpotPrice    : formData?.askClientSpotPrice ? formData?.askClientSpotPrice : null,
                    bidClientSpotPrice    : formData?.bidClientSpotPrice ? formData?.bidClientSpotPrice : null,
                    settlementDate        : formData?.settlementDate     ? formData?.settlementDate : null,
                    fixAccount            : clientPrice.fixAccount       ? clientPrice.fixAccount : "",
                },
                functionOverride: {
                    quote                   : getFunctionOverride(formData?.quote),
                    newOrderSingle          : getFunctionOverride(formData?.newOrderSingle),
                    priceStreamConfiguration: getFunctionOverride(formData?.priceStreamConfiguration),
                }
            }
        )).then(resp => {
            if (formData?.quote?.function === "") {
                dispatch(xpFormChangeField(formModel + '.quote', {}));
            }
            if (formData?.newOrderSingle?.function === "") {
                dispatch(xpFormChangeField(formModel + '.newOrderSingle', {}));
            }
            if (formData?.priceStreamConfiguration?.spreadType === "") {
                dispatch(xpFormChangeField(formModel + '.priceStreamConfiguration', {}));
            }
            setFetchingPrice(false);
            fetchCurrentPrice();
        });
    }
    function  clearOverride() {
        setFetchingPrice(true);
        dispatch(overrideClientPriceToApi(
            {
                sessionId: props.sessionId,
                currencyPair: currencyPair,
                tenor: props.tenor,
                fixAccount: props.fixAccount,
                clientPriceOverride: null,
                functionOverride: {
                    quote         : null,
                    newOrderSingle: null,
                    priceStreamConfiguration: null,
                },
            }
        )).then(resp => {
            setFetchingPrice(false);
            dispatch(xpFormLoadForm(formModel, {}, XP_FORM_EDIT));
            fetchCurrentPrice();
        });
    }
    function formIsDifferentFromPrice(form, price) {
        if (!form) {
            return false;
        }
        for (const field in form) {
            if (field.includes('$') || (field === 'lastUpdate') || isObject(form[field] || isArray(form[field]))) {
                continue;
            }
            if (!price || !price[field]) {
                if (form[field]) {
                    return true;
                }
                else {
                    continue;
                }
            }
            if (String(form[field]) !== String(price[field])) {
                return true;
            }
        }
        return false;
    }
    const overrideChanged = useMemo(
        () => {
            if (!formData || !clientPrice) {
                return false;
            }
            if (formIsDifferentFromPrice(formData               , clientPrice.clientPriceOverride)) {
                return true;
            }
            if (formIsDifferentFromPrice(formData.quote         , clientPrice.functionOverride?.quote)) {
                return true;
            }
            if (formIsDifferentFromPrice(formData.newOrderSingle, clientPrice.functionOverride?.newOrderSingle)) {
                return true;
            }
            if (formIsDifferentFromPrice(formData.priceStreamConfiguration, clientPrice.functionOverride?.priceStreamConfiguration)) {
                return true;
            }
            return false;
        },
        [formData, clientPrice]
    );

    return (
        <div className={'marketSimulatorConfigItem'}>
            <div style={{position: 'absolute', top   : '2px', left:' 4px', fontSize: '11px'}}>{props.sessionId}</div>
            {props.fixAccount && <div style={{position: 'absolute', bottom: '2px', left:' 4px', fontSize: '11px'}}>{'Fix account: ' + props.fixAccount}</div>}

            <XpForm
                formModel={formModel}
                onSubmit={(data) => {
                }}
                initialUseState={XP_FORM_EDIT}
                // currentData={clientPrice?.clientPrice}
            >
                <div style={{display: 'flex'}}>
                    <div style={{width: '170px', minWidth: '170px'}}>
                        <XpTooltip bypass={!fixAccountOverrides?.length} title={
                            (fixAccountOverrides?.length && <div>
                                {'Fix accounts with overrides:'}
                                {/*{fixAccountOverrides?.length ? "Fix accounts with overrides:\n" + fixAccountOverrides.reduce((str, pr) => str + pr.fixAccount + '\n', "") : ""}*/}
                                {fixAccountOverrides.map(item => (
                                    <div key={item.fixAccount}>
                                        {'\n' + item.fixAccount}
                                        <WaitingXpoolButton
                                            className       = "xPoolButtonInTable"
                                            label           = "Add config"
                                            onClickCallback = {() => {
                                                if (props.addConfigFn) {
                                                    props.addConfigFn(item.fixAccount)
                                                }
                                            }}
                                        />
                                    </div>
                                    )
                                )}
                            </div>)
                        }>
                            <h3>{currencyPair.key + ':' + props.tenor + (fixAccountOverrides?.length ? "*" : "")}</h3>
                        </XpTooltip>
                        <img
                            style={{height: '18px', marginTop: "-4px", float: 'none'}}
                            src={removeIcon} alt="Remove"
                            className="closeDashboardComponentButton"
                            onClick={() => props.removeMe()}
                        />
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            label           = "Fetch"
                            onClickCallback = {() => fetchCurrentPrice()}
                            waiting={fetchingPrice}
                        />
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            label           = "Override"
                            onClickCallback = {() => overrideCurrentPrice()}
                            disabled = {!overrideChanged}
                            waiting  = {fetchingPrice}
                        />
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            label           = "Clear all"
                            onClickCallback = {() => clearOverride()}
                            waiting  = {fetchingPrice}
                        />
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', width: 'calc(100% - 170px)', overflow: 'auto'}}>
                        <div style={{borderRadius: '3px', padding: '3px', background: '#e2f9ff', marginRight: '10px'}}>

                            <div style={{display: 'flex', marginLeft: '0px'}}>
                                <div style={{width: '110px'}}>
                                    <RenderField label={"Bid: "   } field='bidClientPrice'         fixedDecimals={6} currentValue={clientPrice.clientPrice?.bidClientPrice} />
                                </div>
                                <div style={{width: '110px', marginLeft: '10px'}}>
                                    <RenderField label={"Ask: "   } field='askClientPrice'         fixedDecimals={6} currentValue={clientPrice.clientPrice?.askClientPrice} />
                                </div>
                            </div>
                            <div style={{display: 'flex', marginLeft: '0px'}}>
                                <div style={{width: '110px'}}>
                                    <RenderField label={"BidFwp: "} field='bidIBForwardPoints' fixedDecimals={2} currentValue={clientPrice.clientPrice?.bidIBForwardPoints}/>
                                </div>
                                <div style={{width: '110px', marginLeft: '10px'}}>
                                    <RenderField label={"AskFwp: "} field='askIBForwardPoints' fixedDecimals={2} currentValue={clientPrice.clientPrice?.askIBForwardPoints}/>
                                </div>
                            </div>
                            <div style={{display: 'flex', marginLeft: '0px'}}>
                                <div style={{width: '110px'}}>
                                    <RenderField label={"BidSp: "   } field='bidClientSpotPrice'         fixedDecimals={6} currentValue={clientPrice.clientPrice?.bidClientSpotPrice} />
                                </div>
                                <div style={{width: '110px', marginLeft: '10px'}}>
                                    <RenderField label={"AskSp: "   } field='askClientSpotPrice'         fixedDecimals={6} currentValue={clientPrice.clientPrice?.askClientSpotPrice} />
                                </div>
                            </div>
                            <div style={{width: '225px', marginLeft: '5px'}}>
                                <RenderField label={"Settlement: "} field='settlementDate' currentValue={clientPrice.clientPrice?.settlementDate} noParser/>
                            </div>
                        </div>

                        <div style={{borderRadius: '3px', display: 'flex', padding: '3px', background: '#ffe7e0', marginRight: '10px'}}>
                            <div style={{width: '220px', marginLeft: '0px'}}>
                                <XpFormSelectorInput
                                    noLabel
                                    template={'No quote function'}
                                    field="quote.function"
                                    selectList={['REJECT', 'IGNORE', 'DELAY'].map(item => ({id: item, name: 'Quote ' + item}))}
                                />

                                {['REJECT', 'DELAY'].includes(formData?.quote?.function) &&
                                <div style={{marginTop: '10px'}}>
                                    <RenderField label={"Response delay (ms):"} field='quote.responseDelay' split={30} currentValue={clientPrice.functionOverride?.quote?.responseDelay}/>
                                </div>}
                                {(formData?.quote?.function === 'REJECT') &&
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '70px'}}>
                                        <RenderField label={"Code: "} field='quote.errorCode'    split={45}  currentValue={clientPrice.functionOverride?.quote?.errorCode} isRequired defaultValue={99}/>
                                    </div>
                                    <div style={{width: '150px', marginLeft: '10px'}}>
                                        <RenderField label={"Msg: "}  field='quote.errorMessage' split={75}  currentValue={clientPrice.functionOverride?.quote?.errorMessage} noParser/>
                                    </div>
                                </div>}
                            </div>
                        </div>

                        <div style={{borderRadius: '3px', display: 'flex', padding: '3px', background: '#fff5e0', marginRight: '10px'}}>
                            <div style={{width: '220px', marginLeft: '0px'}}>
                                <XpFormSelectorInput
                                    noLabel
                                    template={'No order function'}
                                    field="newOrderSingle.function"
                                    selectList={['REJECT', 'IGNORE', 'DELAY', 'CANCEL_REJECT', 'REJECT_CANCEL'].map(item => ({id: item, name: 'Order ' + item}))}
                                />
                                {['REJECT', 'DELAY', 'CANCEL_REJECT', 'REJECT_CANCEL'].includes(formData?.newOrderSingle?.function) &&
                                <div style={{marginTop: '10px'}}>
                                    <RenderField label={"Response delay (ms):"} field='newOrderSingle.responseDelay' split={30} currentValue={clientPrice.functionOverride?.newOrderSingle?.responseDelay}/>
                                </div>}
                                {(['REJECT', 'CANCEL_REJECT', 'REJECT_CANCEL'].includes(formData?.newOrderSingle?.function)) &&
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: '70px'}}>
                                            <RenderField label={"Code: "} field='newOrderSingle.errorCode'    split={45} currentValue={clientPrice.functionOverride?.newOrderSingle?.errorCode} isRequired defaultValue={99}/>
                                        </div>
                                        <div style={{width: '150px', marginLeft: '10px'}}>
                                            <RenderField label={"Msg: "}  field='newOrderSingle.errorMessage' split={75} currentValue={clientPrice.functionOverride?.newOrderSingle?.errorMessage} noParser/>
                                        </div>
                                    </div>}
                            </div>
                        </div>

                        {props.fixAccount &&
                        <div style={{borderRadius: '3px', display: 'flex', padding: '3px', background: '#e0ffe8'}}>
                            <div style={{width: '200px', marginLeft: '0px'}}>
                                <XpFormSelectorInput
                                    noLabel
                                    template={'No price stream'}
                                    field="priceStreamConfiguration.spreadType"
                                    selectList={['FIXED', 'RANDOM'].map(item => ({id: item, name: 'Stream ' + item}))}
                                />
                                {formData?.priceStreamConfiguration?.spreadType &&
                                <div style={{marginTop: '10px'}}>
                                    <RenderField label={"Spread (bps): "} field='priceStreamConfiguration.spreadInterval' split={30} currentValue={clientPrice.functionOverride?.priceStreamConfiguration?.spreadInterval}/>
                                </div>}
                                {formData?.priceStreamConfiguration?.spreadType &&
                                <div style={{marginTop: '10px'}}>
                                    <RenderField label={"Interval (s): "} field='priceStreamConfiguration.updateInterval' split={30} currentValue={clientPrice.functionOverride?.priceStreamConfiguration?.updateInterval} isRequired defaultValue={1}/>
                                </div>}
                            </div>
                        </div>}
                    </div>
                </div>
            </XpForm>
        </div>
    )
}
MarketSimulatorConfigItem.propTypes = {
    currencyPairId: PropTypes.number.isRequired,
    tenor       : PropTypes.string.isRequired,
    sessionId   : PropTypes.string.isRequired,
    removable   : PropTypes.bool,
    addConfigFn : PropTypes.func,
};
export default MarketSimulatorConfigItem
