import React from 'react'
import PropTypes from 'prop-types';
import {accountNumberParser} from "gui-common/xpForm/formComponentsFunctions";
import {xpFormUserCanEditBase} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";

function AccountStaticPhysical(props) {

    const formContext   = useXpFormContext();
    const formDataAccountType = useXpFormField('accountType')

    function fieldIsDisabled() {
        if (props.enforceUseCanEdit) return false;
        return !xpFormUserCanEditBase(formContext, props.ormModel, props.isSubForm);
    }

    return (
        <div className={props.className}>
            {(formDataAccountType === "PHYSICAL") &&
            <div>
                <XpFormTextInput
                    inLineLayout
                    inLineDivider={50}
                    field= "clearingNumber"
                    disabled={fieldIsDisabled()}
                    parser={accountNumberParser}
                    isRequired={true}
                    checkNoSpaces={true}
                    checkOnlySpaces={false}
                    exactLength={5}
                />

                <XpFormTextInput
                    inLineLayout
                    inLineDivider={50}
                    field= "accountNumber"
                    disabled={fieldIsDisabled()}
                    parser={accountNumberParser}
                    isRequired={true}
                    checkNoSpaces={true}
                    checkOnlySpaces={false}
                    exactLength={10}
                />
            </div>}
        </div>
    );
}

AccountStaticPhysical.propTypes = {
    className:        PropTypes.string,
};

export default AccountStaticPhysical;

