import {ReportConfigSelectClient} from "gui-common/report/reportComponents/ReportConfigSelectClient";
import {
    enrichOrdersReportData,
    ordersReportMockData,
    OrdersTurnoverAllClients, OrdersTurnoverForOneClient,
    OrdersTurnoverPerClient,
    OrdersTurnoverPerClientCompact
} from "xpool-gui-common/features/order/orderReport";

export const dataSetTypes = {
    'ExecutedOrders': {
        reportTypes: {
            'TurnoverAllClients': {
                configComponent: undefined,
                reportComponent: OrdersTurnoverAllClients,
                defaultSettings: undefined,
            },
            'TurnoverPerClient': {
                configComponent: undefined,
                reportComponent: OrdersTurnoverPerClient,
                defaultSettings: undefined,
            },
            'TurnoverPerClientCompact': {
                configComponent: undefined,
                reportComponent: OrdersTurnoverPerClientCompact,
                defaultSettings: undefined,
            },
            'TurnoverPerCurrencyAndMonth': {
                configComponent: ReportConfigSelectClient,
                reportComponent: OrdersTurnoverForOneClient,
                defaultSettings: {
                    showClients: true,
                    showMonths: false,
                    sumClient: true,
                    sumMonth: true,
                    sumYear: true,
                    showClientId: "",
                    showClientName: ""
                }
            },
        },
        // reportFields            : ordersReportFields,
        dateSortField           : 'modifiedDateTime',
        dataEnrichmentFunction  : enrichOrdersReportData,
        mockData                : ordersReportMockData,
        hideReportCurrency      : false,
    },
};

