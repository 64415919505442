import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {
    formatAmount
} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";

function XpFormattedAmount (props) {

    const decDenLangState = useSelector(selectDecDenLangState);

    const currencyPrefix = useMemo(
        () => {
            if (!props.currencyPrefix) return "";
            return props.currencyPrefix + "\u00a0";
        },
        [props.currencyPrefix]
    );

    const currencySuffix = useMemo(
        () => {
            if (!props.currencySuffix) return "";
            return "\u00a0" + props.currencySuffix;
        },
        [props.currencySuffix]
    );

    return (
        <span className={props.className} style={{textAlign: props.alignRight ? 'right' : undefined, ...props.style}}>
                {currencyPrefix + formatAmount(props.amount, decDenLangState) + currencySuffix}
        </span>
    )
}
XpFormattedAmount.propTypes = {
    amount:         PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alignRight:     PropTypes.bool,
    currencyPrefix: PropTypes.string,
    currencySuffix: PropTypes.string,
    className:      PropTypes.string,
    style:          PropTypes.object,
};
export default XpFormattedAmount
