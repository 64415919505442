import React, {useEffect, useState} from 'react'
import Stepper from '@material-ui/core/Stepper/index';
import Step from '@material-ui/core/Step/index';
import StepLabel from '@material-ui/core/StepLabel/index';
import {useDispatch} from "react-redux";
import 'gui-common/wizard/wizard.css';
import {setJumpStep, setHiddenSteps} from 'gui-common/wizard/wizardReducer';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getWizardStateSelector} from "gui-common/wizard/wizardSelectors";
import {isEqual} from "lodash";
import {useXpFormRootFormData} from "gui-common/xpForm/core/xpFormHooks";

function maxStep(completedArray){
    if(Array.isArray(completedArray) && completedArray.length >  0) {
        return completedArray.reduce(function (a, b) {
            return Math.max(a, b);
        });
    } else {
        return 0;
    }
}
function lastStep(steps, completedArray, stepNo) {
    return stepNo === steps.length - 1 && completedArray.length === steps.length - 1;
}
function activeStep(hidden, step){
    let outStep = step;
    for(let hideStep of hidden) {
        if(hideStep <= step){
            outStep--;
        }
    }
    return outStep;
}
function createSteps(wizardName, myWizard, jumpFunction) {
    let mySteps = myWizard.stepInfo.map(x => x.heading);
    return mySteps.map(function(element, index) {
            if(myWizard.hidden.includes(index)) {return null;}
            let jumpEnabled = (myWizard.initiatedSteps.includes(index) || maxStep(myWizard.completedSteps) >= index || lastStep(mySteps, myWizard.completedSteps, index)) && index !== myWizard.wizardStep;
            let stepStyles = {cursor : 'auto'};
            if (jumpEnabled) stepStyles.cursor = 'pointer';

            const stepIsInvalid = myWizard.invalidSteps.includes(index);

            return (
                <Step
                    key={element}
                    completed={myWizard.completedSteps.includes(index)}
                >
                    <StepLabel
                        onClick={jumpEnabled ? (() => jumpFunction(wizardName, index)) : null}
                        style={stepStyles}
                        error={stepIsInvalid}
                    >
                        <XpTranslated
                            trKey={wizardName + '.' + element}
                        />
                    </StepLabel>
                </Step>
            );
        }
    )
}

function WizardPhaser(props) {
    const thisWizardState = useSelectorInstance(getWizardStateSelector, props);
    const wizardForm      = useXpFormRootFormData(props.wizardFormName);
    const dispatch        = useDispatch();
    const [hiddenStepsState, setHiddenStepsState] = useState([]);

    useEffect(
        () => {
            if (!wizardForm || !thisWizardState?.stepInfo?.length) return;

            let currentHiddenSteps = [];
            thisWizardState.stepInfo.forEach((step, index) => {
                if (step.hideStepFn && step.hideStepFn(wizardForm)) currentHiddenSteps.push(index);
            })
            if (isEqual(currentHiddenSteps, hiddenStepsState)) return;

            setHiddenStepsState(currentHiddenSteps)
            dispatch(setHiddenSteps({wizardName : props.wizardName, hidden: currentHiddenSteps}));
        },
        [wizardForm, thisWizardState]
    );

    function jumpStep(wizardName, stepNo) {
        const stepItem = {wizardName : wizardName, stepNo : stepNo};
        dispatch(setJumpStep(stepItem));
    }

    if(!thisWizardState || !thisWizardState?.stepInfo) return (<div> </div>)
    return (
        <div>
            <div className="wizardPhaser" xp-test-id="wizardPhaser">
                <Stepper
                    activeStep={activeStep(hiddenStepsState, thisWizardState.wizardStep)}
                    alternativeLabel={true}
                >
                    {createSteps(props.wizardName, thisWizardState, jumpStep)}
                </Stepper>
            </div>
        </div>
    )
}

export default WizardPhaser;

