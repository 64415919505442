import PropTypes from "prop-types";
import React, {useMemo, useEffect} from "react";
import {
    useBaseCurrency,
    useCurrencyData,
} from "xpool-gui-common/features/currencyDashboard/currencyDashboardFunctions";
import XpPieChart from "gui-common/components/XpPieChart";
import XpCurrencyFlag from "gui-common/components/XpCurrencyFlag";
import {formatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useSelector} from "react-redux";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";

function CurrencyDashboardExposurePieChart (props) {
    const currencyData = useCurrencyData();
    const decDenLangState = useSelector(selectDecDenLangState);
    const baseCurrency = useBaseCurrency()

    const exposureData = useMemo(
        () => {
            if (!currencyData?.length) {
                return;
            }
            return currencyData.filter(item => item.currency !== baseCurrency.currency);
        },
        [currencyData]
    );

    if (!exposureData?.length) return null;

    return (
        <div>
            <XpPieChart
                // renderDebug
                data={exposureData}
                size={props.size}
                margin={props.margin}
                innerRadius={0.65}
                padAngle={0.8}
                skipLabelAtAngle={20}
                id = {e => e.currency}
                value = {e => e.percentExposure}
                startAngle={240}
                endAngle={480}
                getCenterContent = {params => (<CenterDisplay/>)}
                getLabelContent = {params => {
                    return (
                        <h3 style={{margin: '0', padding: '0', color: 'white', fontSize: '14px'}}>{params.datum.data.currency}</h3>
                        // <XpCurrencyFlag currency={params.datum.data.currency} draggable width={30} height={30}/>
                    )
                }}
                getTooltipContent={params => {

                    const balanceInCurrency = formatAmount(params.data.balanceInCurrency, decDenLangState);
                    const balanceInBase     = formatAmount(params.data.balanceInBase    , decDenLangState);
                    const percentExposure   = params.data.percentExposure.toFixed(1);

                    return (
                        <div className="currencyDashboardChartTooltip">
                            <h3 style={{textAlign: 'center', marginTop: '5px'}}>{params.data.currency}</h3>
                            <div style={{width: '100%', opacity: 0.1, marginBottom: '-80px', marginTop: '-20px', display: 'flex', justifyContent: 'center'}}>
                                <XpCurrencyFlag currency={params.data.currency} draggable width={100} height={100}/>
                            </div>
                            <table style={{margin: '0'}}><tbody>
                            <tr>
                                <td style={{border: 'none'}}><label><span><XpTranslated trKey={'currencyDashboard.currencies.balance.label'} trParams={{}}/></span></label></td>
                                <td style={{textAlign: 'right', border: 'none'}}><span style={{paddingLeft: '5px'}} className="chart-toolTip chartTooltipNumber">{balanceInCurrency}</span></td>
                            </tr>
                            <tr>
                                <td style={{border: 'none'}}><label><span><XpTranslated trKey={'currencyDashboard.currencies.balanceInBase.label'} trParams={{baseCurrency: baseCurrency.currency}}/></span></label></td>
                                <td style={{textAlign: 'right', border: 'none'}}><span style={{paddingLeft: '5px'}} className="chart-toolTip chartTooltipNumber">{balanceInBase}</span></td>
                            </tr>
                            <tr>
                                <td style={{border: 'none'}}><label><span><XpTranslated trKey={'currencyDashboard.currencies.exposure.label'} trParams={{}}/></span></label></td>
                                <td style={{textAlign: 'right', border: 'none'}}><span style={{paddingLeft: '5px'}} className="chart-toolTip chartTooltipNumber">{percentExposure + '%'}</span></td>
                            </tr>
                            </tbody></table>
                        </div>
                    )
                }}
                getColors={e => 'var(--xpool-secondary-color-mid)'}
            />
        </div>
    )
}
CurrencyDashboardExposurePieChart.propTypes = {
    size: PropTypes.number.isRequired,
    margin: PropTypes.object,
};
export default CurrencyDashboardExposurePieChart



function CenterDisplay(props) {
    const currencyData = useCurrencyData()
    const baseCurrency = useBaseCurrency()

    const totalExposure = useMemo(
        () => {
            if (!currencyData?.length) {
                return;
            }
            return currencyData.reduce((totalExposure, currency) => {
                if (currency.currency === baseCurrency.currency) {
                    return totalExposure;
                }
                return totalExposure + currency.percentExposure;
            }, 0);
        },
        [currencyData]
    );

    return (
        <div>
            <label style={{margin: '0', padding: '0', textAlign: 'center', width: '100%'}}><XpTranslated trKey={'currencyDashboard.header.totalExposure'} /></label>
            <h3 style={{margin: '0', padding: '0', textAlign: 'center'}}>{totalExposure.toFixed(1)}%</h3>
        </div>
    )
}
