import React, {Component} from "react";
import {calculateDisplayString} from "gui-common/functions/functions";
import {connect} from "react-redux";
import {selectActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

class XpGridDateTimeRenderer extends Component {
    constructor(props) {
        super(props);
        const format = props.colDef.cellRendererParams.xpDateTimeFormat;
        this.state = {
            value: props.value,
            format: format,
            // displayString: calculateDisplayString(props.value, format, props.currentLanguage),
        };
    }
    refresh(params) {
        if(params.value !== this.state.value) {
            this.setState({
                    ...this.state,
                    value: params.value,
                    // displayString: calculateDisplayString(params.value, this.state.format, this.props.currentLanguage)
                });
        }
        return true;
    }

    render () {
        if (!this.state.value || !this.state.format) return null;

        const displayString = calculateDisplayString(this.state.value, this.state.format, this.props.currentLanguage);
        return (
            <span>
                {displayString}
            </span>
        );
    }
}
const mapStateToProps = (state, props) => {
    return {
        currentLanguage:        selectActiveLanguage(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(XpGridDateTimeRenderer);
