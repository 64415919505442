import React from 'react'
import 'features/onboardingWizard/onboardingWizard.css';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";


function ObWizardReviewAndSaveForm(props) {
    return (
      <div className="submitRow">
          <h3><XpTranslated trKey={props.wizardName + '.finalStepInfo'}/></h3>
      </div>
    );
}
export default ObWizardReviewAndSaveForm;
