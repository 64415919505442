import React, {useEffect, useRef} from "react";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {bankHolidayAdminFormConfig} from "gui-common/xpAdminForm/xpAdminFormConfig";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import {useXpFormUseState} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";


function BankHolidayModal(props) {

    const childRef = useRef(); // Needed to get setNew and setEdit function from XpAdminFormButtons component.

/*
    const formDataSelector = useMemo(() => {return getXpAdminFormDataSelector()}, []);
    const formData = useSelector(state => formDataSelector(state, {selectInstance: 'bankHolidayForm', selectModel: 'BankHoliday', selectId: props.bankHoliday ? props.bankHoliday.id : undefined}));
*/
    // const dispatch  = useDispatch();
    const formUseState = useXpFormUseState('xpAdminForm.bankHolidayForm.BankHoliday.' + (props.bankHoliday?.id ? props.bankHoliday.id : 'new'));

    useEffect(
        () => {
            setTimeout(() => {
                if (!childRef.current) {
                    console.error("No childRef.current in BankHolidayModal! Cannot create new or edit.", props);
                    // cancelAction();
                    return;
                }
                childRef.current.setEventListener();
            }, 10);
        },
        [],
    );
    useEffect(
        () => {
            // if (!childRef.current && formUseState) return; // XpAdminForm not rendered yet.
            if (formUseState === XP_FORM_VIEW) props.popModalWindow({ status: MODAL_TYPE_CONFIRM });
        },
        [formUseState],
    );

    // const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalGenerateUserMessage"}};

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Edit text dialog"
                    onRequestClose={() => props.popModalWindow({ status: MODAL_TYPE_CONFIRM })}
                    shouldCloseOnEsc={true}
                    {...modalStandardParams}
                >
                    <div xp-test-id="bankHolidayModal">
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={props.bankHoliday ? 'bankHolidayForm.editHeader' : 'bankHolidayForm.newHeader'}/></h3>
                        <hr/>
                        <p className="valueTextDark">
                            <XpTranslated trKey={props.bankHoliday ? 'bankHolidayForm.editDescription' : 'bankHolidayForm.newDescription'}/>
                        </p>
                        <hr/>

                        <XpAdminForm
                            ref                   = {childRef}
                            entityId              = {props.bankHoliday ? props.bankHoliday.id : undefined}
                            xpAdminFormInstanceId = 'bankHolidayForm'
                            xpAdminFormConfig     = {bankHolidayAdminFormConfig}
                            ormModel              = 'BankHoliday'
                            suppressAccordion     = {true}
                            hideDeleteButton      = {true}
                            hideEditButton        = {true}
                        />
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
export default BankHolidayModal;
