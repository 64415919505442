import {createReducer} from 'gui-common/functions/reducer.js'

export const SAVED_USER_STATE_ADD     = "SAVED_USER_STATE_ADD";
export const SAVED_USER_STATE_REMOVE  = "SAVED_USER_STATE_REMOVE";
export const SAVED_USER_STATE_RESET   = "SAVED_USER_STATE_RESET";
export const SAVED_USER_STATE_RELOAD  = "SAVED_USER_STATE_RELOAD";

// Action creators ********************************************************
// ************************************************************************
export function addSavedUserState(userState) {
    return {type: SAVED_USER_STATE_ADD, payload: userState}
}
export function removeSavedUserState(userState) {
    return {type: SAVED_USER_STATE_REMOVE, payload: userState}
}
export function resetSavedUserState() {
    return {type: SAVED_USER_STATE_RESET}
}
export function reloadSavedUserState(savedState) {
    return {type: SAVED_USER_STATE_RELOAD, payload: savedState}
}

// Reducer functions ********************************************************
// ************************************************************************

const savedUserStatesInitialState = {savedSettings: []};

function addState(state, payload) {
    const itemPosition = state.savedSettings.findIndex(item => item.name === payload.name);
    let newState = {...state, savedSettings: [...state.savedSettings]};
    if (itemPosition !== -1) {
        console.warn("Saved user state already exists in addState, overwrite existing with new.", payload);
        newState.savedSettings.splice(itemPosition, 1);
    }
    newState.savedSettings.push(payload);
    return newState;
}

function removeState(state, payload) {
    const itemPosition = state.savedSettings.findIndex(item => item.name === payload.name);
    if (itemPosition === -1) {
        console.warn("Saved user state does not exist in addState", payload);
        return state;
    }
    let newState = {...state, savedSettings: [...state.savedSettings]};
    newState.savedSettings.splice(itemPosition, 1);
    return newState;
}

function reset(state, payload) {
    return savedUserStatesInitialState;
}
function reload(state, payload) {
    if (payload) return payload;
    return state;
}

export default createReducer(savedUserStatesInitialState, {
    [SAVED_USER_STATE_ADD]    : addState,
    [SAVED_USER_STATE_REMOVE] : removeState,
    [SAVED_USER_STATE_RESET]  : reset,
    [SAVED_USER_STATE_RELOAD] : reload,
});
