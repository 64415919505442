import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {selectMyXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import {getSpecificBankAccountSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";

export const selectedAccountIdInCashPool = createSelector(
    [
        state => selectMyXpGridState(state, {instanceId: 'cashPoolAccountList'}),
    ],
    (cashPoolAccountListState) => {
        return cashPoolAccountListState?.selectedId;
    }
)
export function useSelectedCashPoolAccount() {
    const selectedAccountId    = useSelector(selectedAccountIdInCashPool);
    return useSelectorInstance(getSpecificBankAccountSelector, {selectId: selectedAccountId});
}


