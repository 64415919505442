import {useDispatch, useSelector} from "react-redux";
import {selectAppEnvProperty, selectIsDevMode} from "gui-common/app/appEnvSelectors";
import {
    activeBankAccountInstitutionsSelector,
    activeCountriesSelector,
    activeCurrenciesSelector,
    activeFxProvidersSelector,
    operatingUnitsSelector
} from "gui-common/orm/ormSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {postOrmItemToApi} from "gui-common/orm/ormApi";
import FileReaderButton from "gui-common/components/FileReaderButton";
import React from "react";

function ClientFileLoad(props) {
    const clientFileConfig  = useSelector(state => selectAppEnvProperty(state, 'clientFileConfig'));
    const ormCountries      = useSelector(activeCountriesSelector);
    const ormCurrencies     = useSelector(activeCurrenciesSelector);
    const ormOpUnits        = useSelector(operatingUnitsSelector);
    const ormFxProviders    = useSelector(activeFxProvidersSelector);
    const ormInstitutions   = useSelector(activeBankAccountInstitutionsSelector);

    const devMode           = useSelector(selectIsDevMode);
    const dispatch          = useDispatch();

    function fileContentReady(params) {
        // console.log("Output: ", params);
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {headerText: "Create clients?", messageText: params.dataTree.clients.length + " clients will be created from data"}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    for (const client of params.dataTree.clients) {
                        dispatch(postOrmItemToApi('Client', client))
                    }
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }

    if (!devMode) return null;
    return (
        <FileReaderButton
            instanceId      = 'clientFileLoadFileButton'
            fileSpec        = {clientFileConfig.fileSpecification}
            label           = 'Dev: Load clients file'
            inLineLayout    = {true}
            onSuccess       = {(params) => {fileContentReady(params)}}
            onFail          = {(params) => {}}
            onStartBrowsing = {() => {}}
            onStartValidation = {() => {}}
            fieldLookUpToIds = {{
                countries               : ormCountries.map( item => ({...item, name: item.country })),
                currencies              : ormCurrencies.map(item => ({...item, name: item.currency})),
                opUnits                 : ormOpUnits,
                fxProviders             : ormFxProviders,
                bankAccountInstitutions : ormInstitutions,
            }}
            debugLog
        />
    )
}
export default ClientFileLoad
