import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import removeIcon from 'gui-common/resources/removeIcon.png'
import XpTooltip from "gui-common/components/XpTooltip";
import {
    addRemovedActiveDataRights,
    compareEntityName,
    extractInstanceRights
} from "gui-common/adminUserRights/adminUserRightsFunctions";
import {useDispatch, useSelector} from "react-redux";
import {
    userRightsAdminRemoveInstance,
    userRightsAdminSelectInstance, userRightsAdminSetInstanceRight
} from "gui-common/adminUserRights/adminUserRightsReducer";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getModifiedEntityDataRightSelector} from "gui-common/adminUserRights/adminUserRightsSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


export function RemoveEntityDataRightButton(props) {
    const dispatch  = useDispatch();
    return (
        <div className="tableField">
            <XpTooltip trKey='adminUserRights.removeInstanceTooltip'>
                <div>
                    <img
                        style={{height: '18px', marginTop: "2px" }}
                        src={removeIcon}
                        alt="Remove"
                        className="closeDashboardComponentButton"
                        onClick={() => dispatch(userRightsAdminRemoveInstance(props.dataRightsCollection, props.entityDataRight))}
                    />
                    {props.displayLabel && <label className="inlineValueLabel"><XpTranslated trKey={'adminUserRights.removeInstanceLabel'}/></label>}
                </div>
            </XpTooltip>
        </div>
    )
}


function InstanceRightsLine (props) {

    const dispatch  = useDispatch();

    const modifiedEntityDataRightSelector = useMemo(() => getModifiedEntityDataRightSelector(), []);
    const modifiedDataRightsCollection  = useSelector(state => modifiedEntityDataRightSelector(state, {selectId: props.entityDataRight.id}));

    if (!props.entityDataRight) return null;

    const dataRightToRender = modifiedDataRightsCollection ? modifiedDataRightsCollection : props.entityDataRight;

    if (!dataRightToRender.instanceRights) return null;

    let renderClass = "clickableRow";
    if (props.selectedEntityDataRight && (props.selectedEntityDataRight.id === props.entityDataRight.id)) {
        renderClass = "activeClickableRow";
    }
    if (props.isAdded)   renderClass += " instanceUserRightsCardAdded";
    if (props.isRemoved) renderClass = "instanceUserRightsCardRemoved";

    const instanceRightAvailable = (right) => {
        if (dataRightToRender.instanceRights[right] !== undefined) return true;
        if (props.activeEntityDataRight) return true;
        return (props.activeEntityDataRight.instanceRights[right] !== undefined);
    };
    const instanceRightChanged = (right) => {
        if (!props.activeEntityDataRight) return false;
        return (dataRightToRender.instanceRights[right] !== props.activeEntityDataRight.instanceRights[right]);
    };

    return (
        <tr
            key = {dataRightToRender.id}
            className={renderClass}
        >
            <td style={{border: 'none'}}>
                {!props.isRemoved &&
                <div className="tableField">
                    <RemoveEntityDataRightButton
                        dataRightsCollection = {props.dataRightsCollection}
                        entityDataRight      = {dataRightToRender}
                    />
                </div>}
            </td>
            <td style={{paddingLeft: '5px', width: '200px'}}
                className=""
                onClick={() => dispatch(userRightsAdminSelectInstance(dataRightToRender))}
            >
                <div>{dataRightToRender.entity.name}</div>
            </td>
            {props.instanceRights.map(right => {
                const handleChange = (event) => {
                    dispatch(userRightsAdminSetInstanceRight(props.dataRightsCollection.user, dataRightToRender, right, event.target.checked));
                };
                const isAllowed              = (dataRightToRender.instanceRights[right] === true);

                let cellClassName = "";
                if (!instanceRightAvailable(right)) cellClassName = "disabledCell";
                if ( instanceRightChanged(right)  ) cellClassName = "changedCell";

                return (
                    <td className={cellClassName} key={right}>
                        <div className="checkboxTdColumn">
                            {(instanceRightAvailable(right)) && <input
                                type="checkbox"
                                checked={isAllowed}
                                onChange={handleChange}
                                disabled ={props.readOnly || props.isRemoved}
                            />}
                        </div>
                    </td>
                )
            })}
        </tr>
    );
}

function InstanceRights (props) {
    const translate = useSelector(selectTranslateFunction);

    const instanceRights = useMemo(() => extractInstanceRights(props.assignedEntityDataRights, props.activeAssignedEntityDataRights),
        [props.assignedEntityDataRights, props.activeAssignedEntityDataRights]
    );

    const entityDataRights = useMemo(() => addRemovedActiveDataRights(props.assignedEntityDataRights, props.activeAssignedEntityDataRights),
        [props.assignedEntityDataRights, props.activeAssignedEntityDataRights]
    );

    if (!entityDataRights) return null;

    const sortedEntityList = entityDataRights.sort(compareEntityName);

    return (
        <div>
            <table style={{}}>
                <tbody>
                <tr className="HeaderRow" key="header">
                    <th style={{width: '20px'}}    > </th>
                    <th style={{width: '200px'}}   ><XpTranslated trKey={'general.prototypeNamesLarge.' + props.entityPrototypeName} /></th>
                    {instanceRights.map(right => {return (
                        <th key = {right} style={{width: '50px'}}>{right}</th>
                    )})}
                </tr>
                {(sortedEntityList.length > 0) ?
                    sortedEntityList.map(entityDataRight => {
                        const activeEntity   = props.activeAssignedEntityDataRights ? props.activeAssignedEntityDataRights.find(item => item.entity.id === entityDataRight.entity.id) : undefined;
                        const assignedEntity = props.assignedEntityDataRights       ? props.assignedEntityDataRights.find(item => item.entity.id === entityDataRight.entity.id) : undefined;

                        return (
                            <InstanceRightsLine
                                dataRightsCollection    = {props.dataRightsCollection}
                                selectedEntityDataRight = {props.selectedEntityDataRight}
                                entityDataRight         = {entityDataRight}
                                activeEntityDataRight   = {activeEntity}
                                isAdded                 = {!activeEntity}
                                isRemoved               = {!assignedEntity}
                                instanceRights          = {instanceRights}
                                key                     = {entityDataRight.id}
                            />
                        )
                    })
                    :
                    <tr className="">
                        <td style={{border: 'none'}}> </td>
                        <td style={{paddingLeft: '5px' }}>
                            <div>{translate('adminUserRights.noInstancesAdded', {entityType: translate('general.prototypeNamesSmallPlural.' + props.entityPrototypeName)})}</div>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    );
}

InstanceRights.propTypes = {
    dataRightsCollection     : PropTypes.object.isRequired,
    assignedEntityDataRights : PropTypes.array.isRequired,
    entityPrototypeName      : PropTypes.string.isRequired,
    selectedEntityDataRight  : PropTypes.object,
    readOnly                 : PropTypes.bool
};

export default InstanceRights;

