import React, {useMemo, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {createPortal} from "react-dom";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

const credentials = btoa('H067jERE9y1J' + ':' +'sqb9cxYjMJ');

const vismaUrl   = 'https://identity-sandbox.test.vismaonline.com/connect/authorize?client_id=nordicfinancialcomponentsab&redirect_uri=https://localhost:44300/callback&scope=ea:api%20offline_access%20ea:sales&state=Fishes&response_type=code&prompt=login&acr_values=service:44643EB1-3F76-4C1C-A672-402AE8085934+forceselectcompany:true'
// const fortnoxUrl = 'https://apps.fortnox.se/oauth-v1/auth?client_id=H067jERE9y1J&redirect_uri=https://dev.xpool.se/erp-connect-callback/fortnox&scope=supplierinvoice%20invoice%20bookkeeping&state=Fishes&access_type=offline&response_type=code'
const fortnoxUrl = 'https://apps.fortnox.se/oauth-v1/auth?client_id=H067jERE9y1J&https://dev.xpool.se/erp-connect-callback/fortnox&scope=supplierinvoice%20invoice%20bookkeeping%20payment%20customer%20supplier&state=Fishes&access_type=offline&response_type=code'

function EprPoc (props) {

    const translate = useSelector(selectTranslateFunction);
    const dispatch  = useDispatch();

    const [variable,   setVariable]   = useState(null);

    const authorizationCode = useXpFormField('erpPocForm.authCode');

    useEffect(
        () => {},
        [],
    );

    const memoVar = useMemo(
        () => {
            return "";
        },
        []
    );

    function windowPop(url, width, height) {
        let leftPosition, topPosition;
        //Allow for borders.
        leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
        //Allow for title and status bars.
        topPosition = (window.screen.height / 2) - ((height / 2) + 50);
        //Open the window.

        const winFeatures = "status=no,height=" + height + ",width=" + width + ",resizable=yes,left=" + leftPosition + ",top=" + topPosition +
            ",screenX=" + leftPosition + ",screenY=" + topPosition +
            ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no"

        const externalPopup = window.open(url, "Window2", "status=no,height=" + height + ",width=" + width + ",resizable=yes,left=" + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY=" + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");

/*
        const timer = setInterval(() => {
            if (!externalPopup) {
                timer && clearInterval(timer);
                return;
            }
            const currentUrl = externalPopup.location.href;
            if (!currentUrl) {
                return;
            }
            const searchParams = new URL(currentUrl).searchParams;
            const code = searchParams.get('code');
            if (code) {
                externalPopup.close();
                console.log(`The popup URL has URL code param = ${code}`);
/!*
                YourApi.endpoint(code).then(() => {
                    // change UI to show after the code was stored
                })
                    .catch(() => {
                        // API error
                    })
                    .finally(() => {
                        // clear timer at the end
                        // setExternalPopup(null);
                        // timer && clearInterval(timer);
                    })
*!/
                timer && clearInterval(timer);
            }
        }, 500)
*/

        // setTimeout(()=>{popUp.close()}, 5000);


    }

    return (
        <div>
            <h2>ERP Poc</h2>
            <XpForm
                formModel="erpPocForm"
                initialUseState={XP_FORM_EDIT}
            >
                <XpFormTextInput field='authCode' label='Authorization code'/>
            </XpForm>
            <WaitingXpoolButton onClickCallback={() => requestToken(authorizationCode)} label='Request token' />
            <WaitingXpoolButton onClickCallback={() => windowPop(fortnoxUrl, 700, 600)} label='Open popup' />
        </div>
    )
}
EprPoc.propTypes = {
};
export default EprPoc


function formatBody(data) {
    let formBody = [];
    for (let property in data) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    return formBody.join("&");
}

function requestToken(authorizationCode) {

    const parameters = {
        credentials : 'include',
        // withCredentials: true,
        method      : 'POST',
        // mode        : 'no-cors',
        headers     : {
            'Authorization': 'Basic ' + credentials,
            // 'Authorization': 'Basic FISHES',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'code=9b19b684-bf27-446d-833d-b16c00fce64c&grant_type=authorization_code&redirect_uri=https%3A%2F%2Fdev.xpool.se%2Ferp-connect-callback%2Ffortnox',
/*
        body: formatBody({
            grant_type: "authorization_code",
            code: authorizationCode,
            redirect_uri: 'https://dev.xpool.se/erp-connect-callback/fortnox'
        })
*/
    }
    fetch('https://apps.fortnox.se/oauth-v1/token', parameters)
        .then(resp => {

            console.log("Token request respons: ", resp);

            // const fish = resp.json();

            // return Promise.all([resp, resp.json()]);
        })
/*
        .then(resp => {
            if ((resp[0].status === 200) || (resp[0].status === 201)) { // Handle success
                if (!resp[1]) {
                    console.error("Request token returned no payload!", resp);
                    return;
                }
                console.log("Request token returned payload: ", resp);

            }
            else {
                console.error("Request token returned other than 200!", resp);
            }
        })
*/
        .catch(resp => {
            console.error("Request token failed with catch. Resp: ", resp);
        })
}
