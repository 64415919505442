import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {useSelectorRef} from "gui-common/functions/hooks";
import {getBalanceMovementsSelector} from "xpool-gui-common/features/balanceMovement/balanceMovementSelectors";
import {prepareBalanceMovementsColumns} from "xpool-gui-common/features/balanceMovement/balanceMovementFunctions";
import {pushModalWindow} from "redux-promising-modals";
import {HEDGE_BALANCE_MOVEMENT_DIALOG} from "appConfig/appModals";
import {reverseBalanceMovementToApi} from "xpool-gui-common/features/balanceMovement/balanceMovementApi";
import {CONFIRMATION_DIALOG, CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {pushDelayedModal} from "gui-common/functions/thunks";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


const hiddenState = {
    id              : true,
    bankAccountId   : true,
    typeT           : false,
    amount          : false,
    valueDate       : false,
    comment         : false,
    stateT          : false,
    sourceT         : false,
    createdDateTime : true,
    createdByUser   : true,
};

export function hedgeIsAllowed(movement, client) {
    if (movement.state  !== 'BOOKED') return false;
    if (["REVERSE_PAYABLE", "REVERSE_RECEIVABLE"].includes(movement.type)) return false;
    return true;
}
export function reverseIsAllowed(movement, client) {
    if (movement.state  !== 'BOOKED') return false;
    if (movement.source !== 'MANUAL') return false;
    if (["REVERSE_PAYABLE", "REVERSE_RECEIVABLE"].includes(movement.type)) return false;
    return true;
}

export function balanceMovementReverseAction(item, dispatch) {
    dispatch(pushDelayedModal(CONFIRMATION_WITH_COMMENT_DIALOG, {
        commentIsRequired: true,
        modalKey: "balanceMovementsList.gridContextMenuItems.reverse." + item.type,
        modalParams: {amount: item.amount, valueDate: item.valueDate}
    }))
        .then((result) => {
            if (result.status === MODAL_TYPE_CONFIRM) {
                dispatch(reverseBalanceMovementToApi(item, result.comment));
            }
            if (result.status === MODAL_TYPE_CANCEL) {}
        });

}

// BalanceMovementsList.detailIcon = 'swap_horizontal_circle';
// BalanceMovementsList.detailIconToolTipKey = 'orderList.detailIconToolTipKey';
function BalanceMovementsList (props) {

    const ormActiveClient = useSelector(shallowActiveClientSelector);
    const dispatch        = useDispatch();
    const translateRef    = useSelectorRef(selectTranslateFunction); // Needed to transport updated translate hook to callback scope.

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((data  ) => {
            if (props.itemSelectedCallback) props.itemSelectedCallback(data);
        }, []),
        gridContextMenuItems    : useCallback(
            (params) => {
                const data = (params && params.node) ? params.node.data : null;

                let menuItems = [];

                if (reverseIsAllowed(params.node.data, ormActiveClient)) {
                    menuItems.push({
                        action: () => balanceMovementReverseAction(params.node.data, dispatch),
                        name: translateRef.current('balanceMovementsList.gridContextMenuItems.reverse.' + params.node.data.type + '.menuItem'),
                    });
                }

                if (hedgeIsAllowed(params.node.data, ormActiveClient)) {
                    menuItems.push({
                        action: function () {
                            dispatch(pushDelayedModal(HEDGE_BALANCE_MOVEMENT_DIALOG, {movement: params.node.data}))
                                .then(({status}) => {
                                    if (status === MODAL_TYPE_CONFIRM) {}
                                    if (status === MODAL_TYPE_CANCEL) {}
                                });
                        },
                        name: translateRef.current('balanceMovementsList.gridContextMenuItems.hedgeTransaction'),
                    });
                }

                return menuItems;
            }, [translateRef?.current, ormActiveClient]),
    }

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translateRef?.current('balanceMovementsList.noBalanceMovementsToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? false : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                getRowDataSelector      : getBalanceMovementsSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'BalanceMovement',
                rowClassRules           : {
                    'xp-grid-onHold-order-row'   : params => ["SUSPENDED", "CANCELLED"].includes(params.data.state),
                }
            };
        },
        [translateRef?.current, props.rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareBalanceMovementsColumns(translateRef.current, props.hiddenState ? props.hiddenState : hiddenState);
        },
        [translateRef?.current]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={"balanceMovementsList" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}
export default BalanceMovementsList
