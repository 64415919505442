export const FORM_NUMBER_FIELD_ADD    = 'FORM_NUMBER_FIELD_ADD';
export const FORM_NUMBER_FIELD_REMOVE = 'FORM_NUMBER_FIELD_REMOVE';

export function formNumberFieldAdd(model) {
    return {type: FORM_NUMBER_FIELD_ADD, payload: {model: model}}
}
export function formNumberFieldRemove(model) {
    return {type: FORM_NUMBER_FIELD_REMOVE, payload: model}
}

export function formNumberFieldsReducer(state = {}, action)
{
    let newState;
    switch (action.type) {
        case FORM_NUMBER_FIELD_ADD:
            if (!action.payload?.model) return state;
            newState = {...state};
            newState[action.payload.model] = true;
            return newState;
        case FORM_NUMBER_FIELD_REMOVE:
            if (!action.payload) return state;
            if (!state[action.payload.model]) return state;
            newState = {...state};
            delete newState[action.payload.model];
            return newState;
        default:
            return state;
    }
}
