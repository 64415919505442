import React from 'react'
import PropTypes from 'prop-types';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

function DomainRights (props) {

    const noRightsAllowed = (prototypeRights, prototypeRightsBeforeChange) => {
        let anyAllowed = false;
        for (let right in prototypeRights) {
            if (prototypeRightsBeforeChange && (prototypeRightsBeforeChange[right] === true)) anyAllowed = true;
            if (prototypeRights[right]             === true) anyAllowed = true;
        }
        return !anyAllowed;
    };

    if (!props.instanceDomainRights) return (
        <div>
            <p className="valueText"><XpTranslated trKey='adminUserRights.noDomainRightsAvailable'/></p>
        </div>
    );

    return (
        <div style={{display: "inline-block", verticalAlign: "top"}}>
            <table style={{}}>
                <tbody>
                <tr className="HeaderRow" key="header">
                    <th style={{width: '150px'}}>
                        <XpTranslated trKey='adminUserRights.domainHeader'/>
                    </th>
                    {props.domainRights.map(right => {return (
                        <th key = {right} style={{width: '50px'}}>{right}</th>
                    )})}
                </tr>
                {Object.keys(props.instanceDomainRights).map(domain => {
                    const prototypeRights             = props.instanceDomainRights[domain].entityPrototypeRights;
                    const prototypeRightsBeforeChange = props.instanceDomainRightsBeforeChange ? props.instanceDomainRightsBeforeChange[domain].entityPrototypeRights : undefined;
                    if (noRightsAllowed(prototypeRights, prototypeRightsBeforeChange) && props.readOnly) return null;

                    const rowClassName = "clickableRow" + (prototypeRightsBeforeChange ? "" : " instanceUserRightsCardAdded");
                    return (
                        <tr
                            key = {domain}
                            className={rowClassName}
                        >
                            <td style={{paddingLeft: '5px', width: '100px'}} className="">
                                <div><XpTranslated trKey={'general.prototypeNamesLarge.'+domain}/></div>
                            </td>
                            {props.domainRights.map(right => {
                                const isAllowed = (prototypeRights[right] === true);
                                const domainRightAvailable = ((prototypeRights[right] !== undefined) && !(props.readOnly && !isAllowed));
                                const domainRightChanged   = prototypeRightsBeforeChange && (prototypeRights[right] !== prototypeRightsBeforeChange[right]);
                                let cellClassName = "";
                                if (!domainRightAvailable) cellClassName = "disabledCell";
                                if ( domainRightChanged)   cellClassName = "changedCell";
                                return (
                                    <td className={cellClassName} key={right}>
                                        <div className="checkboxTdColumn">
                                            {(domainRightAvailable) && <input
                                                type="checkbox"
                                                checked={isAllowed}
                                                onChange={event => props.setDomainRight(domain, right, event.target.checked)}
                                                disabled ={props.readOnly}
                                            />}
                                        </div>
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
}

DomainRights.propTypes = {
    instanceDomainRights: PropTypes.object.isRequired,
    setDomainRight:       PropTypes.func.isRequired,
    domainRights:         PropTypes.array.isRequired,
    readOnly            : PropTypes.bool
};

DomainRights.defaultProps = {
};

export default DomainRights;

