import {createReducer} from 'gui-common/functions/reducer'
import cloneDeep from "lodash/cloneDeep";
import {USER_LOGOUT} from "gui-common/api/webSessionReducer";
import {DASHBOARD_REMOVE_COMPONENT} from "gui-common/dashboard/dashboardReducer"

export const XP_GRID_CREATE_GRID          = "XP_GRID_CREATE_GRID";
export const XP_GRID_REMOVE_GRID          = "XP_GRID_REMOVE_GRID";
export const XP_GRID_SET_STATE            = "XP_GRID_SET_STATE";
export const XP_GRID_SET_EXPANDED_STATE   = "XP_GRID_SET_EXPANDED_STATE";
export const XP_GRID_SET_COL_DEF          = "XP_GRID_SET_COL_DEF";
export const XP_GRID_SET_FIT_TO_PAGE      = "XP_GRID_SET_FIT_TO_PAGE";
export const XP_GRID_SET_SELECTED         = "XP_GRID_SET_SELECTED";
export const XP_GRID_SET_VISIBLE_SELECTED = "XP_GRID_SET_VISIBLE_SELECTED";
export const XP_GRID_SET_SHOW_STATE       = "XP_GRID_SET_SHOW_STATE";
export const XP_GRID_RELOAD_STATE         = "XP_GRID_RELOAD_STATE";
export const XP_GRID_RESET_STATE          = "XP_GRID_RESET_STATE";
export const XP_GRID_RESET_GRID_STATE     = "XP_GRID_RESET_GRID_STATE";
export const XP_GRID_SET_DETAIL_COMPONENT = "XP_GRID_SET_DETAIL_COMPONENT";
export const XP_GRID_SET_GRID_CONFIG      = "XP_GRID_SET_GRID_CONFIG";

// Action creators ********************************************************
// ************************************************************************
export function xpGridCreateGrid(instanceId, selectedId, showState) {
    return {
        type : XP_GRID_CREATE_GRID,
        payload: {instanceId: instanceId, selectedId: selectedId, showState: showState}
    };
}
export function xpGridRemoveGrid(instanceId) {
    return {
        type : XP_GRID_REMOVE_GRID,
        payload: {instanceId: instanceId}
    };
}
export function xpGridSetState(instanceId, colState, filterState) {
    return {
        type: XP_GRID_SET_STATE,
        payload: {instanceId: instanceId, colState: colState, filterState: filterState}
    };
}
export function xpGridSetColDef(instanceId, colId, colDef) {
    return {
        type: XP_GRID_SET_COL_DEF,
        payload: {instanceId: instanceId, colId: colId, colDef: colDef}
    };
}
export function xpGridSetExpandedState(instanceId, expState) {
    return {
        type: XP_GRID_SET_EXPANDED_STATE,
        payload: {instanceId: instanceId, expState: expState}
    };
}
export function xpGridSetFitToPage(instanceId, fitToPage) {
    return {
        type: XP_GRID_SET_FIT_TO_PAGE,
        payload: {instanceId: instanceId, fitToPage: fitToPage, }
    };
}
export function xpGridSetSelected(instanceId, itemId) {
    return {
        type: XP_GRID_SET_SELECTED,
        payload: {instanceId: instanceId, itemId: itemId}
    };
}
export function xpGridSetVisibleSelected(instanceId, isVisible) {
    return {
        type: XP_GRID_SET_VISIBLE_SELECTED,
        payload: {instanceId: instanceId, isVisible: isVisible}
    };
}
export function xpGridSetShowState(instanceId, showState) {
    return {
        type: XP_GRID_SET_SHOW_STATE,
        payload: {instanceId: instanceId, show: showState}
    };
}
export function xpGridsReloadState(newState) {
    return {
        type : XP_GRID_RELOAD_STATE,
        payload: newState
    };
}
export function xpGridsResetState() {
    return {
        type : XP_GRID_RESET_STATE,
        payload: undefined
    };
}
export function xpGridResetGridState(instanceId) {
    return {
        type: XP_GRID_RESET_GRID_STATE,
        payload: {instanceId: instanceId}
    };
}
export function xpGridSetDetailComponent(instanceId, detailType) {
    return {
        type: XP_GRID_SET_DETAIL_COMPONENT,
        payload: {instanceId: instanceId, detailType: detailType}
    };
}
export function xpGridSetGridConfig(instanceId, gridConfig) {
    return {
        type: XP_GRID_SET_GRID_CONFIG,
        payload: {instanceId: instanceId, gridConfig: gridConfig}
    };
}


// Reducer functions ********************************************************
// ************************************************************************
function findGridInstance(state, instanceId, caller) {
    let gridInstance = state.xpGrids.find(item => item.instanceId === instanceId);
    if (!gridInstance) {
        // Not really a warning since actions can be dispatched before the grids are initialized and ormForms may not have grids mounted.
//        console.warn("No grid instance found in " + caller + " in xpGridsReducer. Requested instanceId: " + instanceId + ". Ignoring action.");
        return false;
    }
    return gridInstance;
}
function newStateWithReplacedItem(state, newItem) {
    let newState = cloneDeep(state);
    newState.xpGrids = state.xpGrids.filter(item => item.instanceId !== newItem.instanceId);
    newState.xpGrids.push(newItem);
    return newState;
}


const xpGridsInitialState = {
    xpGrids: [],
};
const getNewGridState = (payload) => ({
    instanceId        : payload.instanceId,
    show              :      (payload.showState === undefined) ? true : payload.showState,
    fitToPage         : true,
    selectedId        : payload.selectedId,
    selectedIdVisible : true,
    colState          : undefined,
    filterState       : undefined,
    expState          : undefined,
    colDef            : undefined,
    height            : undefined,
    detailType        : undefined,
    gridConfig        : undefined,
});
function createGrid (state, payload) {
    if (!payload.instanceId) {
        console.error("Tried to create XpGrid state without instanceId: ", payload);
        return state;
    }
    let newState = cloneDeep(state);
    newState.xpGrids = [...state.xpGrids, getNewGridState(payload)];
    return newState;
}
function removeGrid (state, payload) {
    let newState = cloneDeep(state);
    newState.xpGrids = state.xpGrids.filter(item => !item.instanceId.includes(payload.instanceId));
    return newState;
}
function removeDashboardComponent (state, payload) {
    let newState = cloneDeep(state);
    let instanceId = 'dashboard-' + payload;
    newState.xpGrids = state.xpGrids.filter(item => item.instanceId !== instanceId);
    return newState;
}
function setGridState (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "setColState");
    if (!gridInstance) return state;
    let newInstance = cloneDeep(gridInstance);

    newInstance.colState    = (payload.colState    === undefined) ? undefined : payload.colState;
    newInstance.filterState = (payload.filterState === undefined) ? undefined : cloneDeep(payload.filterState);

    return newStateWithReplacedItem(state, newInstance);
}
function setGridExpandedState (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "setExpState");
    if (!gridInstance) return state;
    let newInstance = cloneDeep(gridInstance);

    newInstance.expState    = (payload.expState    === undefined) ? undefined : payload.expState;

    return newStateWithReplacedItem(state, newInstance);
}
function setColDef (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "setColDef");
    if (!gridInstance) return state;
    let newInstance = cloneDeep(gridInstance);

    if (!newInstance.colDef) newInstance.colDef = {};
    if (!newInstance.colDef[payload.colId]) newInstance.colDef[payload.colId] = {};
    newInstance.colDef[payload.colId] = {...newInstance.colDef[payload.colId], ...payload.colDef};

    return newStateWithReplacedItem(state, newInstance);
}
function setFitToPage (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "setFitToPage");
    if (!gridInstance) return state;
    if (gridInstance.fitToPage === payload.fitToPage) return state; // no update needed.

    let newInstance = cloneDeep(gridInstance);
    newInstance.fitToPage = payload.fitToPage;
    return newStateWithReplacedItem(state, newInstance);
}
function setSelected (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "setSelected");
    if (!gridInstance) return state;
    // if (gridInstance.selectedId === payload.itemId) return state; // no update needed. MT20200929 removed. selectedIdVisible must be set even in some cases when these two are equal.

    let newInstance = cloneDeep(gridInstance);
    newInstance.selectedId        = payload.itemId;
    newInstance.selectedIdVisible = (payload.itemId) ? true : undefined;
    return newStateWithReplacedItem(state, newInstance);
}
function setVisibleSelected (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "setVisibleSelected");
    if (!gridInstance) return state;
    if (gridInstance.selectedIdVisible === payload.isVisible) return state; // no update needed.

    let newInstance = cloneDeep(gridInstance);
    newInstance.selectedIdVisible = payload.isVisible;
    return newStateWithReplacedItem(state, newInstance);
}
function setShowState (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "setShowState");
    if (!gridInstance) return state;
    if (gridInstance.show === payload.show) return state; // no update needed.

    let newInstance = cloneDeep(gridInstance);
    newInstance.show = payload.show;
    return newStateWithReplacedItem(state, newInstance);
}
function resetGrids (state, payload) {
    return xpGridsInitialState;
}
function reloadGridsState (state, payload) {
    return payload;
}
function resetGridState (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "resetGridState");
    if (!gridInstance) return state;
    let newInstance = getNewGridState(payload);
    return newStateWithReplacedItem(state, newInstance);
}
function setDetail (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "setDetail");
    if (!gridInstance) return state;
    if (gridInstance.detailType === payload.detailType) return state; // no update needed.

    let newInstance = cloneDeep(gridInstance);
    newInstance.detailType = payload.detailType;
    return newStateWithReplacedItem(state, newInstance);
}
function setConfig (state, payload) {
    let gridInstance = findGridInstance(state, payload.instanceId, "setConfig");
    if (!gridInstance) return state;

    let newInstance = cloneDeep(gridInstance);
    newInstance.gridConfig = {...newInstance.gridConfig, ...payload.gridConfig};
    return newStateWithReplacedItem(state, newInstance);
}

export default createReducer(xpGridsInitialState, {
    [XP_GRID_CREATE_GRID]          : createGrid,
    [XP_GRID_REMOVE_GRID]          : removeGrid,
    [DASHBOARD_REMOVE_COMPONENT]   : removeDashboardComponent,
    [XP_GRID_SET_STATE]            : setGridState,
    [XP_GRID_SET_EXPANDED_STATE]   : setGridExpandedState,
    [XP_GRID_SET_COL_DEF]          : setColDef,
    [XP_GRID_SET_FIT_TO_PAGE]      : setFitToPage,
    [XP_GRID_SET_SELECTED]         : setSelected,
    [XP_GRID_SET_VISIBLE_SELECTED] : setVisibleSelected,
    [XP_GRID_SET_SHOW_STATE]       : setShowState,
    [USER_LOGOUT]                  : resetGrids,
    [XP_GRID_RELOAD_STATE]         : reloadGridsState,
    [XP_GRID_RESET_STATE]          : resetGrids,
    [XP_GRID_RESET_GRID_STATE]     : resetGridState,
    [XP_GRID_SET_DETAIL_COMPONENT] : setDetail,
    [XP_GRID_SET_GRID_CONFIG]      : setConfig,
});
