import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {requestReloadBalanceFromApi, requestReloadBalancesFromApi} from "./requestReloadBalanceApi"
import './requestReloadBalance.css';
import {addUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getOrmItemsSelector} from "gui-common/orm/ormSelectors";
import {getOrmModelLoading} from "gui-common/orm/ormLoadingSelectors";
import {CONFIRMATION_DIALOG, INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import {ormEntityUpdateFailedAction, ormEntityUpdateStarted} from "gui-common/orm/ormReducer";


function RequestReloadBalanceButton (props) {

    const ormActiveClient    = useSelector(shallowActiveClientSelector);
    const dispatch           = useDispatch();
    const requestBalanceTimeout = useAppEnvProperty('requestBalanceTimeout')

    const [timerHandle,   setTimerHandle]   = useState(undefined);

    const [requestMap,   setRequestMap]   = useState({});
    const requestMapRef = useRef(requestMap);
    requestMapRef.current = requestMap;

    const ledgerAccounts     = useSelectorInstance(getOrmItemsSelector, {itemIdArray: Object.keys(requestMap), ormModel: 'LedgerAccount'})
    const ledgerAccountsRef = useRef(ledgerAccounts);
    ledgerAccountsRef.current = ledgerAccounts;

    const loadingState       = useSelectorInstance(getOrmModelLoading , {ormModel: 'LedgerAccount'})
    const loadingStateRef = useRef(loadingState);
    loadingStateRef.current = loadingState;


    const waitingForBalances = useMemo(
        () => {
            if (!loadingState || !requestMap) return false;
            for (const ledgerAccountId in requestMap) {
                if (loadingState[ledgerAccountId] && loadingState[ledgerAccountId].loadingUpdate) return true;
            }
            return false;
        },
        [loadingState, requestMap]
    );

    function checkErrorsAndPushModal() {
        let errorArray = [];
        for (const ledgerAccount of ledgerAccountsRef.current) {
            let statusCode = 'OK';
            let trParams = {}
            if (loadingStateRef.current[ledgerAccount.id] && loadingStateRef.current[ledgerAccount.id].loadingUpdate) {
                statusCode = 'TIMEOUT';
            }
            else if (ledgerAccount.requestStatus && (ledgerAccount.requestStatus !== 'OK')) {
                statusCode = ledgerAccount.requestStatus;
                trParams.errorCode = ledgerAccount.errorCode;
                trParams.errorDescription = ledgerAccount.errorDescription;
            }
            if (statusCode !== 'OK') {
                console.log("Failed to get balance on bankAccount id ", ledgerAccount.id, statusCode, ledgerAccount.errorDescription);
                dispatch(ormEntityUpdateFailedAction('LedgerAccount', {id: ledgerAccount.id}));
                const account = requestMapRef.current[ledgerAccount.id] ? requestMapRef.current[ledgerAccount.id].account : {name: 'N/A', id: 'N/A'};
                errorArray.push(
                    <div key={ledgerAccount.id}>
                        {account.name + " (id " + account.id + ") : "}
                        <XpTranslated trKey={'reloadAccountBalances.requestStatusCodes.' + statusCode} trParams={trParams}/>
                    </div>
                )
            }
        }
        if (errorArray.length) {
            const message = (
                <div>
                    <div><XpTranslated trKey={'reloadAccountBalances.failedRequestModal.message'}/></div>
                    {errorArray}
                </div>
            );
            dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'reloadAccountBalances.failedRequestModal', messageText: message, className: 'xpoolModalFileReaderErrorMessage'}));
        }
    }

    useEffect(
        () => {
            if (waitingForBalances) return;
            if (timerHandle) clearTimeout(timerHandle);
            checkErrorsAndPushModal();
            setRequestMap({});
        },
        [waitingForBalances],
    );

    function wakeOnTimout() {
        dispatch(addUserMessageThunk("warning", "userMessages.warning.requestBalancesFailed", {}, false, ormActiveClient));

        checkErrorsAndPushModal();

        setRequestMap({});
    }

    function onButtonClicked() {
        if (!props.accountList) return;
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'reloadAccountBalances.confirmReloadModal'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    const reqMap = {};
                    dispatch(requestReloadBalancesFromApi(props.accountList));
                    props.accountList.forEach(item => {
                        reqMap[item.ledgerBalanceLaId] = {ledgerAccountId: item.ledgerBalanceLaId, account: item};
                    });
                    setRequestMap(reqMap);

                    setTimerHandle(setTimeout(() => wakeOnTimout(), requestBalanceTimeout));
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }
    return (
        <WaitingXpoolButton
            labelKey        = {props.labelKey ? props.labelKey : 'reloadAccountBalances.reloadButtonLabel.ready'}
            onClickCallback = {() => onButtonClicked()}
            disabled        = {props.disabled}
            waiting         = {waitingForBalances}
            toolTipKey      = {waitingForBalances ? 'reloadAccountBalances.reloadButtonTooltip.loading' : 'reloadAccountBalances.reloadButtonTooltip.ready'}
        />
    );
}

RequestReloadBalanceButton.propTypes = {
    accountList:    PropTypes.array.isRequired,
    className:      PropTypes.string,
    disabled:       PropTypes.bool,
    labelKey:       PropTypes.string,
};
RequestReloadBalanceButton.defaultProps = {
    className:      "",
    disabled:       false,
};
export default RequestReloadBalanceButton;
