import {createSelector} from "reselect";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getTimeValueFromDateString} from "gui-common/functions/functions";


export const cashLadderTransactionsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector bankAccountsLadderSelector running ");
        let returnArray = []

/*
        let cashedAgreements  = {};

        const listRates = session.ListRate.orderBy('id', 'desc').toRefArray();
        for (let listRate of listRates) {
            // These lookups consumes a lot of rendering time. With 321 orders in the system, calculation of the list went from 46ms to 9 ms without the three lines below.
            const agreement       = getOrAddCashedObject(listRate.agreementId  , 'Agreement'  , cashedAgreements  , session);

            returnArray.push({
                ...listRate,
                currencyPair         : agreement ? agreement.currencyPair : undefined,
                legalEntityUnitId    : agreement ? agreement.legalEntityUnitId : undefined,
                currencyPairStr      : agreement ? getCurrencyPairString(agreement.currencyPair) : "",
                gracePeriodT         : listRate.gracePeriod + 's',
                listRatePeriodT      : listRate.listRatePeriod ? listRate.listRatePeriod.period         : "",
                dispatchTradePeriodT : listRate.dispatchTradePeriod ? listRate.dispatchTradePeriod.period : "",
            });
        }
*/
        // console.log("Selected listRatesSelector:", returnArray);
        return returnArray;
    }
);


const selectedProjectionDates = (state, props) => props ? props.selectedProjectionDates : undefined;
const selectedProjection      = (state, props) => props ? props.selectedProjection : undefined;
const filterFunction    = (state, props) => props ? props.filterFunction : undefined;
export const cashLadderTransactionsListDataSelector = () => createSelector(
    [selectedProjectionDates, selectedProjection, filterFunction],
    (selectedProjectionDates, selectedProjection, filterFunction) => {
        // console.log("Selector bankAccountsCashLadderListDataSelector running ");

        if (!selectedProjectionDates || !selectedProjectionDates.length || !selectedProjection) return [];

        let returnArray       = [];
        for (let trs of trsArray) {
            if (trs.inProjections.indexOf(selectedProjection) === -1) continue;
            if (filterFunction && !filterFunction(trs)) continue;
            const newRow = {
                id: trs.id,
                type: trs.type,
                valueDate: trs.valueDate,
            }
            const valueDateNumber = getTimeValueFromDateString(trs.valueDate);

            let largestDateValue = 0;
            for (let date of selectedProjectionDates) {
                const dateNumber = getTimeValueFromDateString(date);
                if (dateNumber > largestDateValue) largestDateValue = dateNumber; // Save latest date value

                if (dateNumber < valueDateNumber) continue  // No data before valueDate

                newRow[date] = trs.amount;
            }
            if (valueDateNumber <= largestDateValue) returnArray.push(newRow);
        }
        // console.log("Selected bankAccountsCashLadderListDataSelector:", returnArray);
        return returnArray;
    }
);

const trsArray = [
    {
        id: 1,
        type: 'SoD',
        valueDate: '2020-11-13',
        accountId: 13,
        inProjections: ['sod', 'trs', 'post'],
        amount: 123000,
    }, {
        id: 2,
        type: 'Transfer',
        valueDate: '2020-11-17',
        accountId: 13,
        inProjections: ['sod', 'trs', 'post'],
        amount: 50000,
    }, {
        id: 3,
        type: 'Trade',
        valueDate: '2020-11-18',
        accountId: 13,
        inProjections: ['trs', 'post'],
        amount: -30000,
    }, {
        id: 4,
        type: 'Trade',
        valueDate: '2020-11-24',
        accountId: 13,
        inProjections: ['trs', 'post'],
        amount: 120000,
    }, {
        id: 5,
        type: 'Rebal',
        valueDate: '2020-11-24',
        accountId: 13,
        inProjections: ['post'],
        amount: -113000,
    }, {
        id: 6,
        type: 'SoD',
        valueDate: '2020-11-13',
        accountId: 14,
        inProjections: ['sod', 'trs', 'post'],
        amount: 38000,
    }, {
        id: 7,
        type: 'Transfer',
        valueDate: '2020-11-16',
        accountId: 14,
        inProjections: ['sod', 'trs', 'post'],
        amount: 40000,
    }, {
        id: 8,
        type: 'Transfer',
        valueDate: '2020-11-24',
        accountId: 14,
        inProjections: ['sod', 'trs', 'post'],
        amount: -30000,
    }, {
        id: 9,
        type: 'Transfer',
        valueDate: '2020-11-18',
        accountId: 14,
        inProjections: ['trs', 'post'],
        amount: 130000,
    }, {
        id: 10,
        type: 'Transfer',
        valueDate: '2020-12-03',
        accountId: 14,
        inProjections: ['trs', 'post'],
        amount: -40000,
    }, {
        id: 11,
        type: 'Rebal',
        valueDate: '2020-11-18',
        accountId: 14,
        inProjections: ['post'],
        amount: -108000,
    }
]
