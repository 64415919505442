import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {
    createOpenObjectAuditMenuItem,
    formatAuditEntriesGridData,
    prepareAuditEntriesGridColumns
} from "gui-common/audit/auditFunctions";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {userSelector} from "gui-common/orm/ormSelectors";
import {selectActiveLanguage, useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {auditConfig} from "gui-common/audit/auditConstants";

export function AuditEntriesList ({itemSelectedCallback, instanceId, inputData, setHeightCallback, fillAvailableSpace, rootModel, hiddenState, suppressObjectAudit}) {

    const currentLanguage = useSelector(selectActiveLanguage);
    const dispatch = useDispatch();
    const auditEnvConfig = useAppEnvProperty( 'auditConfig');
    const user                   = useSelector(userSelector);

    const translate     = useXpTranslateFunction();
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const rootConfig = auditConfig[rootModel];

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!itemSelectedCallback) return;
                itemSelectedCallback((data === undefined) ? undefined : data.id);
            },
            [itemSelectedCallback]),
        rowClassRules   : {},
        gridContextMenuItems    : useCallback(
            (params) => {
                if (suppressObjectAudit) {
                    return [];
                }
                const auditMenuItem = createOpenObjectAuditMenuItem(auditEnvConfig, params.node.data.model, {id: params.node.data.modifiedObjectId, name: params.node.data.modifiedObjectName}, dispatch, translate, user);
                if (auditMenuItem) {
                    return [auditMenuItem];
                }
                return []

            }, [translate]),

    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : instanceId,
                columnDefs              : prepareAuditEntriesGridColumns(translate, hiddenState, rootModel),
                overlayNoRowsTemplate   : translate('auditEntriesList.noAuditEntriesToShow'),
                fillAvailableSpace      : (fillAvailableSpace === undefined) ? true : fillAvailableSpace,
                groupDefaultExpanded    : 0,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'AuditEntry',
            };
        },
        [translate, rootModel]
    );

    const rowData = useMemo(
        () => {
            if (!inputData) return [];
            return formatAuditEntriesGridData(inputData, currentLanguage, translate, rootConfig);
        },
        [inputData, currentLanguage, translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={"auditEntryGrid-" + instanceId}
                {...gridCallbacks}
                {...gridOptions}
                rowData={rowData}
                callingContext={this}
                setHeightCallback={setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}
