import React from "react";
import {useDispatch} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG, modalStandardParams} from "gui-common/modals/modalConstants";
import LabelValueDisplay from "gui-common/components/LabelValueDisplay";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import {xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {hedgeBalanceMovementToApi} from "xpool-gui-common/features/balanceMovement/balanceMovementApi";
import FormattedAmount from "gui-common/components/FormattedAmount";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {getSpecificBankAccountSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";

const formName = "hedgeBalanceMovementForm"
export function HedgeBalanceMovementModal(props) {
    const dispatch       = useDispatch();
    const bankAccount = useSelectorInstance(getSpecificBankAccountSelector, {selectId: props.movement.bankAccountId});
    const formData       = useXpFormFields({dependentFieldModels: {
            hedgePercent : formName + '.hedgePercent',
            comment  : formName + '.comment',
        }})

    function closeModal(){
        props.popModalWindow({ status: MODAL_TYPE_CANCEL });
        dispatch(xpFormRemoveForm(formName));
    }

    function cancelAction() {
        if (!formData.hedgePercent && !formData.comment) {
            closeModal();
            return;
        }
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'general.modalConfirmCancelEditNew'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) closeModal();
            });
    }
    function handleSubmit(form) {
        dispatch(hedgeBalanceMovementToApi(props.movement, form.hedgePercent, form.comment));
        props.popModalWindow({ status: MODAL_TYPE_CONFIRM });
        dispatch(xpFormRemoveForm(formName));
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Hedge balance movement dialog"
                    onRequestClose={cancelAction}
                    shouldCloseOnEsc={true}
                    {...modalStandardParams}
                >

                    <div xp-test-id="registerBalanceMovementDialog">
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={'hedgeBalanceMovementForm.header.' + props.movement.type}/></h3>
                        <LabelValueDisplay
                            label={<XpTranslated trKey={'hedgeBalanceMovementForm.valueDate.label'}/>}
                            value={props.movement.valueDate}
                            labelTab={30}
                        />
                        <LabelValueDisplay
                            label={<XpTranslated trKey={'hedgeBalanceMovementForm.amount.label'}/>}
                            value={<FormattedAmount
                                amount={props.movement.amount}
                                currencyPrefix={bankAccount?.currency?.currency}
                            />}
                            labelTab={30}
                        />
                        <hr/>

                        <XpForm
                            formModel={formName}
                            onSubmit={handleSubmit}
                            initialUseState={XP_FORM_NEW}
                            initialFormData={{hedgePercent: "", comment: ""}}
                        >
                            <XpFormAmountInput
                                isRequired autoFocus
                                inLineDivider={40}
                                field     = "hedgePercent"
                                maxAmount = {100}
                                minAmount = {0}
                            />
                            <XpFormTextAreaInput
                                field="comment"
                                maxLength={200}
                            />
                        </XpForm>

                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                labelKey        = "general.buttons.save"
                                onClickCallback = {() => dispatch(xpFormSubmitForm(formName))}
                                disabled={formData.hedgePercent === ""}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                labelKey        = 'general.buttons.cancel'
                                onClickCallback = {cancelAction}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
