import React, { Component } from 'react'
import {connect} from "react-redux";
import 'gui-common/integrationStatus/integrationStatus.css';
import IntegrationStatusItem from './IntegrationStatusItem'
import {activeProvidersSelector} from "gui-common/orm/ormSelectors"

class IntegrationStatusContainer extends Component {

    renderProviders(providers) {
        if (providers.length === 0) return null;
        else {
            return providers.map(item =>
                <IntegrationStatusItem
                    provider={item}
                    key={item.id}
                    instanceId={this.props.instanceId + "-" + item.id}
                />
            );
        }
    }

    render() {
//        moment.appLocale(this.props.currentLanguage);
        return (
            <div className="integrationStatusContainer" ref='integrationStatusContainer'>
                {this.renderProviders(this.props.providers)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        providers: activeProvidersSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationStatusContainer);


