import React, {useContext, useEffect} from "react";
import RunTimesTable from "xpool-gui-common/appConfig/runConfiguration/RunTimesTable";
import {checkConflictWithOtherElements} from "gui-common/runConfiguration/runConfigurationFunctions";
import {setTranslatedError} from "gui-common/functions/functions";
import {createSelectItems} from "gui-common/functions/hooks";
import {useDispatch} from "react-redux";
import {xpFormIsReadOnly} from "gui-common/xpForm/core/xpFormFunctions";
import {useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {XpFormContext} from "gui-common/xpForm/core/XpForm";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import RunTypeActivationForRun from "xpool-gui-common/appConfig/runConfiguration/RunTypeActivationForRun";


const runDays = [...Array(31).keys()].map((item, index) => {return {id: index + 1, name: String(index + 1)}});

function MonthlyRunForm (props) {
    const formContext    = useContext(XpFormContext);
    const runTypes       = createSelectItems(['RUN', 'NO_RUN']            , '.runTypes.'      , formContext.formTemplate);
    const dayTypes       = createSelectItems(['FIRST', 'LAST', 'DAY']     , '.dayTypes.'      , formContext.formTemplate);
    const seekStrategies = createSelectItems(['EARLIER', 'LATER', 'EXACT'], '.seekStrategies.', formContext.formTemplate);

    const formData       = useXpFormFields({dependentFields: {
            runType     : 'runType',
            dayType     : 'dayType',
            seekStrategy: 'seekStrategy'
        }})

    const dispatch = useDispatch();

    function fieldIsDisabled() {return props.disabled}

    const noRunDayTypeOverlap = () => (val, checkConfig, myself) => {
        if (!myself) return false;
        if (!checkConfig.isActive) return false;

        if ((myself.runType !== 'NO_RUN') || (checkConfig.runType !== 'NO_RUN')) return false;
        if ((myself.dayType === 'DAY'   ) || (checkConfig.dayType === 'DAY'   )) return false;

        return (myself.dayType === checkConfig.dayType);
    }

    const noRunRunDayOverlap = () => (val, checkConfig, myself) => {
        if (!myself) return false;
        if (!checkConfig.isActive) return false;

        if ((myself.runType !== 'NO_RUN') || (checkConfig.runType !== 'NO_RUN')) return false;
        if ((myself.dayType !== 'DAY'   ) || (checkConfig.dayType !== 'DAY'   )) return false;

        return (Number(myself.runDay) === Number(checkConfig.runDay));
    }

    useEffect(
        () => {
            if (!formData) return;

            if (xpFormIsReadOnly(formContext)) return;
            if (formData.dayType === 'DAY') return;
            if (formData.dayType === 'FIRST') {
                if (formData.seekStrategy === 'LATER') return;
                dispatch(xpFormChangeField(formContext.formModel + ".seekStrategy", 'LATER'));
            }
            if (formData.dayType === 'LAST') {
                if (formData.seekStrategy === 'EARLIER') return;
                dispatch(xpFormChangeField(formContext.formModel + ".seekStrategy", 'EARLIER'));
            }
        },
        [formData, formContext],
    );

    const showDaySpecificFields = formData.dayType === 'DAY';
    const showRunTimes          = formData.runType === 'RUN';

    return (
        <div>
            <RunTypeActivationForRun {...props}/>
            <div>
                <div className='inlineFormField' style={{width:'40%'}}>
                    <XpFormSelectorInput
                        noTemplate inLineLayout
                        inLineDivider   = {60}
                        field           = "runType"
                        selectList      = {runTypes}
                        itemTextField   = "name"
                        disabled        = {fieldIsDisabled()}
                    />
                </div>
                <div className='inlineFormField' style={{width:'60%'}}>
                    <XpFormSelectorInput
                        noTemplate inLineLayout
                        inLineDivider   = {50}
                        field           = "dayType"
                        selectList      = {dayTypes}
                        itemTextField   = "name"
                        disabled        = {fieldIsDisabled()}
                        errors={{
                            noRunDayTypeOverlap: val => checkConflictWithOtherElements(val, noRunDayTypeOverlap(), props),
                        }}
                        errorMessages={{
                            noRunDayTypeOverlap: setTranslatedError(formContext.formTemplate + '.errors.noRunDayTypeOverlap'),
                        }}
                    />
                </div>
            </div>
            {showDaySpecificFields &&
            <div>
                <div className='inlineFormField' style={{width:'40%'}}>
                    <XpFormSelectorInput
                        noTemplate inLineLayout
                        inLineDivider   = {60}
                        field           = "runDay"
                        selectList      = {runDays}
                        itemTextField   = "name"
                        disabled        = {fieldIsDisabled()}
                        errors={{
                            noRunRunDayOverlap: val => checkConflictWithOtherElements(val, noRunRunDayOverlap(), props),
                        }}
                        errorMessages={{
                            noRunRunDayOverlap : setTranslatedError(formContext.formTemplate + '.errors.noRunRunDayOverlap'),
                        }}
                    />
                </div>
                <div className='inlineFormField' style={{width:'60%'}}>
                    <XpFormSelectorInput
                        noTemplate inLineLayout
                        inLineDivider   = {50}
                        field           = "seekStrategy"
                        selectList      = {seekStrategies}
                        itemTextField   = "name"
                        disabled        = {fieldIsDisabled()}
                    />
                </div>
            </div>}
            {showRunTimes &&
            <RunTimesTable {...props} disabled={fieldIsDisabled()}/>}
        </div>
    )
}
MonthlyRunForm.propTypes = {
};
export default MonthlyRunForm

