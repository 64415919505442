export const orderStates = [
    "SUGGESTED",
    "TO_MARKET",
    "IN_MARKET",
    "PENDING" ,
    "EXECUTED",
    "IN_BALANCE",
    "REJECTED",
    "FAILED",
    "CANCELLED",
    "RETRY",
    "RETRY-EXECUTION_FAILURE",
    "RETRY-QUOTATION_FAILURE",
    "ON_HOLD",
    "ON_HOLD-HOLD_ON_RATE",
    "ON_HOLD-INVALID_BALANCE",
    "ON_HOLD-ABOVE_SPLIT_SIZE",
    "ON_HOLD-ABOVE_MAX_SIZE",
    "ON_HOLD-BELOW_MIN_SIZE",
    "ON_HOLD-EXECUTION_FAILURE",
    "ON_HOLD-QUOTATION_FAILURE",
    "ON_HOLD-OUTSIDE_LADDER",
    "ON_HOLD-PASSED_SWEEP_HORIZON"
]

export const orderBuySell = [
    "BUY",
    "SELL"
]


export const orderTypes = window.oxygen_env.TRANSFER_SUPPORTED ? [
    "TODAY",
    "FX_SWAP",
] : [
    "TODAY",
    "FX_SWAP",
    "TRANSFER"
]
