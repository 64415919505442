import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import ReportFilterForm from "gui-common/report/ReportFilterForm"
import spinnerGif from "gui-common/resources/spinner.gif";
import ReportDataList from 'gui-common/report/ReportDataList';
import 'gui-common/report/report.css'
import {
    enrichAndSortReportData,
} from "gui-common/report/reportFunctions";
import {dataSetTypes} from "appConfig/report/appReportConstants";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import PropTypes from "prop-types";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

export const reportDataListInstanceId = "reportDataList";

function ReportPage (props) {

    const translate         = useXpTranslateFunction();
    const reportState       = useSelector(state => state.reportState);
    const formDataDataSetType     = useXpFormField('reportFilterForm.dataSetType');


    const enrichedAndSortedReportData = useMemo(() => {
            if (!formDataDataSetType) return undefined;
            if (!reportState.reportData || !Array.isArray(reportState.reportData)) return undefined;
            return enrichAndSortReportData(reportState.reportData, dataSetTypes[reportState.loadedDataSetType]);
        },
        [reportState?.reportData, reportState?.loadedDataSetType]
    );
    return (
        <div style={{height: "100%"}}>
            {!props.noHeader &&
            <h2><XpTranslated trKey='report.header'/></h2>}
            {!props.noHeader &&
            <hr/>}
            <ReportFilterForm
                enrichedReportData= {enrichedAndSortedReportData}
            />

            {reportState.reportDataLoading
                ?
                <div xp-test-id="reportDataListSpinner">
                    <p className="valueText">{translate("report.loading")} </p>
                    <img className="spinner" src={spinnerGif} alt="waiting" />
                </div>
                :
                ((enrichedAndSortedReportData?.length === 0)
                        ?
                        <h3><XpTranslated trKey='report.noReportDataToShow'/></h3>
                        :
                        <div style={{height: "calc(100vh - 200px)"}}>
                            <ReportDataList
                                reportData           = {enrichedAndSortedReportData}
                                loadedDataSetType    = {reportState.loadedDataSetType}
                                instanceId           = {reportDataListInstanceId + "-" + formDataDataSetType}
                                dataSetType          = {formDataDataSetType}
                                dataSetConfig        = {dataSetTypes[formDataDataSetType]}
                            />
                        </div>
                )
            }
        </div>
    );
}
ReportPage.propTypes = {
    noHeader: PropTypes.bool,
}
export default ReportPage;
