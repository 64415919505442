import React, {forwardRef, useCallback, useMemo} from 'react'
import {useDispatch} from "react-redux";
import 'react-datepicker/dist/react-datepicker.css';
import XpAdminFormContent from "gui-common/xpAdminForm/XpAdminFormContent";
import XpAdminFormSubEntities from "gui-common/xpAdminForm/XpAdminFormSubEntities";
import {
    childEntityInParentForm,
    getFilteredRunConfigurations,
    getXpAdminFormModel
} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import {postOrmItemToApi, putOrmItemToApi} from "gui-common/orm/ormApi";
import XpAdminFormButtons from "gui-common/xpAdminForm/XpAdminFormButtons";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {
    useXpFormFields,
    useXpFormState,
    useXpFormUseState
} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_EDIT, XP_FORM_NEW, XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import {xpFormApiClear, xpFormApiSubmit} from "gui-common/xpForm/core/xpFormReducer";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {getOrmItemLoadingSelector} from "gui-common/orm/ormLoadingSelectors";
import ComponentLoading from "gui-common/components/ComponentLoading";

const XpAdminForm = forwardRef((props, ref) => {


    const xpAdminFormConfig = useMemo(
        () => {
            if (props.xpAdminFormConfig) return props.xpAdminFormConfig
            return props.getXpAdminFormConfig()
        },
        []
    );

    const useMuiFieldStyle = useAppEnvProperty('useMuiFieldStyle');

    const newEntityFormModel      =                  getXpAdminFormModel(props.xpAdminFormInstanceId, props.ormModel);
    const selectedEntityFormModel = props.entityId ? getXpAdminFormModel(props.xpAdminFormInstanceId, props.ormModel, {id: props.entityId}) : undefined;

    const newEntityFormState      = useXpFormState(newEntityFormModel);
    const selectedEntityFormState = useXpFormState(selectedEntityFormModel);

    const formModel    = selectedEntityFormState ? selectedEntityFormModel : newEntityFormState ? newEntityFormModel : selectedEntityFormModel;
    const formUseState = useXpFormUseState(formModel);

    const formTemplate        = useMemo(() => {return xpAdminFormConfig ? xpAdminFormConfig[props.ormModel].formTemplate : ""}, []);

    const selectedCurrentData = useSelectorInstance(xpAdminFormConfig ? xpAdminFormConfig[props.ormModel].getCurrentDataSelector : undefined, {selectId: props.entityId})

    const itemLoading   = useSelectorInstance(getOrmItemLoadingSelector, {ormModel: props.ormModel, itemId: props.entityId});

    const currentData = useMemo(
        () => {
            if (formUseState === XP_FORM_NEW) return undefined;
            return props.currentData ? props.currentData : selectedCurrentData;
        },
        [props.currentData, selectedCurrentData, formUseState]
    );

    const entityNameFromCurrent = currentData?.name;

    const propsToFormContext = useMemo(
        () => {
            return {
                xpAdminFormInstanceId: props.xpAdminFormInstanceId,
                xpAdminFormConfig    : xpAdminFormConfig,
                ormModel             : props.ormModel,
                rootAuditProps       : props.rootAuditProps,
            };
        },
        []
    );

    const dependentFieldModels = useMemo(
        () => {
            if (!xpAdminFormConfig) return {};
            function fillArrayFromLevel(fields, thisLevelOrmModel, formModel) {
                const thisModelFormConfig = xpAdminFormConfig[thisLevelOrmModel];
                if (!thisModelFormConfig?.childEntities) return;

                thisModelFormConfig.childEntities.forEach(childProps => {
                    const baseFormModel = formModel ? (formModel + '.') : "";
                    if (!childProps.arrayProperty || !childProps.ormModel || !childProps.inParentFormProperty) return;
                    const fieldModel = baseFormModel + childProps.inParentFormProperty;
                    fields[fieldModel] = fieldModel;
                    fillArrayFromLevel(fields, childProps.ormModel, baseFormModel + childProps.arrayProperty + '.0');
                })
            }
            let fields = {};
            fillArrayFromLevel(fields, props.ormModel, formModel);
            return fields;
        },
        [formModel, props.ormModel]
    );

    const dependentFields = useXpFormFields({
        dependentFieldModels : dependentFieldModels,
        rootCurrentData      : currentData,
        formUseState         : formUseState,
        formModel            : formModel,
        rootFormModel        : formModel,
    });

    const dispatch        = useDispatch();


    function getEntityToSubmit(entity, ormModel, rootEntity) {
        const returnEntity = {...entity};
        if (entity.runConfiguration) returnEntity.runConfiguration = getFilteredRunConfigurations(entity.runConfiguration);

        const thisModelFormConfig = xpAdminFormConfig[ormModel];

        if (!thisModelFormConfig?.childEntities?.length) return returnEntity;

        thisModelFormConfig.childEntities.forEach(childProps => {
            if (!childProps.arrayProperty || !childProps.ormModel) return;

            let inParentForm = childEntityInParentForm(childProps, XP_FORM_VIEW, undefined, formModel, entity);

            if (!inParentForm) {
                if (entity[childProps.arrayProperty]) delete returnEntity[childProps.arrayProperty];
                return;
            }

            if (!entity[childProps.arrayProperty] || !entity[childProps.arrayProperty][0]) return returnEntity // No child entries to submit!

            let childEntity = getEntityToSubmit(entity[childProps.arrayProperty][0], childProps.ormModel, rootEntity);
            if (!childEntity) return;
            childEntity.name = rootEntity.name + xpAdminFormConfig[childProps.ormModel].subEntitySuffix;

            returnEntity[childProps.arrayProperty] = [childEntity];
        })

        return returnEntity;
    }

    const handleSubmit = useCallback( (entity) => {
        if (![XP_FORM_EDIT, XP_FORM_NEW].includes(formUseState)) return;

        const entityToSubmit = getEntityToSubmit(entity, props.ormModel, entity);

        let functionToDispatch = null;
        if (formUseState === XP_FORM_NEW ) functionToDispatch = postOrmItemToApi;
        if (formUseState === XP_FORM_EDIT) functionToDispatch = putOrmItemToApi;
        if (!functionToDispatch) {
            console.error("Incorrect formState in submit function in XpAdminForm: ", formUseState);
            return;
        }
        dispatch(xpFormApiSubmit(formModel));

        dispatch(functionToDispatch(props.ormModel, entityToSubmit))
            .then(res => {
                // dispatch(actions.reset(formModel));
                dispatch(xpFormApiClear(formModel));
                console.log("Successful Submit: " + formModel + ': ', entityToSubmit);
            })
            .catch(res => {
                // dispatch(actions.reset(formModel));
                dispatch(xpFormApiClear(formModel));
                console.log("Failed Submit: " + formModel + ': ', entityToSubmit);
                if (xpAdminFormConfig[props.ormModel].onApiCallFailedThunk) {
                    dispatch(xpAdminFormConfig[props.ormModel].onApiCallFailedThunk(entityToSubmit, res, formUseState, formModel));
                }
            })
    }, [formUseState, formModel]);

    function displayEntityFormHeader () {
        if (![XP_FORM_EDIT,XP_FORM_NEW].includes(formUseState)) return null;
        if (props.suppressAccordion) return null;

        let superEntityName = props.parentItemData ? props.parentItemData.name : "";
        const name  = (formUseState === XP_FORM_NEW) ? superEntityName : entityNameFromCurrent;
        return <h3 className={'formHeaderEditNew'}><XpTranslated trKey={formTemplate + ((formUseState === XP_FORM_NEW) ? ".newEntity" : ".editEntity")}/>{name}</h3>;
    }

    if (!xpAdminFormConfig) {
        console.error("XpAdminForm called without xpAdminFormConfig", props);
        return null;
    }
    if (!formModel) return null;
    if ((formUseState === XP_FORM_VIEW) && !currentData) return null;


    const cssClassDisabled = (currentData?.isDeleted)        ? " adminEntityCardDisabled" : "";
    const cssClassEdit     = (formUseState === XP_FORM_EDIT) ? " adminEntityCardEdit" : "";
    let   cssClassNew      = (formUseState === XP_FORM_NEW ) ? " adminEntityCardNew"  : "";
    if (props.auditMode && currentData?.isNew) cssClassNew = " adminEntityCardNew";

    const adminCardCssClass = (useMuiFieldStyle ? "xpAdminFormEntityCardNewStyling" : "xpAdminFormEntityCard ") + (props.parentItemData ? "xpAdminFormEntityCardIndent" : "") + cssClassEdit + cssClassNew + cssClassDisabled;

/*
    if (itemLoading) {
        return (<ComponentLoading loadingTrKey={formTemplate + '.loading'}/>);
    }
*/

    return (
        <div xp-test-id="xpAdminForm">
            <div className={adminCardCssClass} xp-test-id={"ormFormEntityCard-"+props.ormModel} >
                <XpAccordion
                    header={entityNameFromCurrent}
                    instanceId={props.xpAdminFormInstanceId + '-' + props.ormModel}
                    bypass={(formUseState !== XP_FORM_VIEW) || props.suppressAccordion}
                >
                    {displayEntityFormHeader()}
                    <XpForm
                        // className="adminFormContainer"
                        formModel        = {formModel}
                        onSubmit         = {handleSubmit}
                        currentData      = {currentData}
                        beforeChangeData = {props.beforeChangeData}
                        formTemplate     = {formTemplate}
                        consumerProps    = {propsToFormContext}
                        auditMode        = {props.auditMode}
                    >
                        <XpAdminFormContent
                            {...props}
                            xpAdminFormConfig={xpAdminFormConfig}
                            dependentFields = {dependentFields}
                            formModel       = {formModel}
                            currentData     = {currentData}
                            formUseState    = {formUseState}
                        />
                    </XpForm>

                    {!props.auditMode &&
                    <XpAdminFormButtons
                        {...props}
                        xpAdminFormConfig={xpAdminFormConfig}
                        formUseState = {formUseState}
                        formModel    = {formModel}
                        currentData  = {currentData}
                        onCancelCallback = {props.onCancelCallback}
                        formTemplate={formTemplate}
                        itemLoading  = {itemLoading}
                        ref={ref}
                    />}

                </XpAccordion>
                <XpAdminFormSubEntities
                    {...props}
                    xpAdminFormConfig={xpAdminFormConfig}
                    dependentFields = {dependentFields}
                    currentData     = {currentData}
                    formUseState    = {formUseState}
                    formModel       = {formModel}
                    // formTemplate  = {formTemplate}
                />
            </div>
        </div>
    );
})
export default XpAdminForm;



