import {createSelector} from "reselect";

const webSessionState = state => state.webSessionState;
export const selectWebSessionAwt = createSelector(
    [webSessionState],
    (webSessionState) => {
        if (!webSessionState) return null;
        if (!webSessionState.awtList) return null;
        if (!webSessionState.awtList.length) return null;
        return webSessionState.awtList[0];
    }
);
export const selectWebSessionRefId = createSelector(
    [webSessionState],
    (webSessionState) => {
        if (!webSessionState) return null;
        return webSessionState.userSystemId;
    }
);
const webSocketStatus = state => state.webSocketState ? state.webSocketState.socketStatus : undefined;
export const selectWebSocketStatus = createSelector(
    [webSocketStatus],
    (webSocketStatus) => {
        return webSocketStatus;
    }
);
