import React, { Component } from 'react'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import UserTopicsConfigTable from "gui-common/userSettings/topics/UserTopicsConfigTable"
import {userTopicActionToApi} from "gui-common/userSettings/topics/userTopicAdminApi"
import {topicsSelector} from "gui-common/orm/ormSelectors"
import {ormItemLoading} from "gui-common/orm/ormLoadingSelectors"
import spinnerGif from "gui-common/resources/spinner.gif";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {userCan} from "gui-common/functions/userRights";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


class UserTopicsAdmin extends Component {


    constructor(props) {
        super(props);
        this.state = {
            topicToAdd: "",
        };
    }

    getAddableTopics() {
        let addableTopics = [];
        this.props.topics.forEach(topic => {
            if (this.props.user.topics && this.props.user.topics.find(item => item === topic.topic)) return;
            if (userCan('Read', 'UmTopic' + topic.topic ,this.props.user)) addableTopics.push(topic.topic);
        });
//        console.log('addable', addableTopics);
        return addableTopics;
    }

    deleteTopicCallback(topic) {
        if (!topic) return;
        this.props.userTopicActionToApi("delete", this.props.user.id, topic);
    }

    addTopic(topic) {
        if (!topic) return;
        const arrayIndex = this.props.user.topics ? this.props.user.topics.findIndex(item => item === topic) : -1;
        if (arrayIndex !== -1) return; // Topic already on user.
        this.props.userTopicActionToApi("assign", this.props.user.id, topic);
/*
        this.setState({
            topicToAdd: topic,
        });
*/
    }

    render() {
        if (!this.props.user)   return null;
        if (!this.props.topics) return null;

        const topicList = this.getAddableTopics();

        return (
            <div xp-test-id='xpUserTopicsAdmin'>
                <h3 className="userPrefsCardHeader">
                    <XpTranslated trKey={'userTopicAdmin.header'}/>
                </h3>
                <label>
                    <XpTranslated trKey={'userTopicAdmin.label'}/>
                </label>
                {this.props.userLoading
                    ?
                    <div xp-test-id="userTopicAdminSpinner">
                        <div>
                            <p className="valueText">
                                <XpTranslated trKey={'userTopicAdmin.loading'}/>
                            </p>
                            <img className="spinner" src={spinnerGif} alt="waiting" />
                        </div>
                    </div>
                    :
                    <div>
                        <UserTopicsConfigTable
                            deleteTopicCallback = {(topic) => this.deleteTopicCallback(topic)}
                            translate           = {this.props.translate}
                            user                = {this.props.user}
                        />
                        {!this.props.disabled &&
                        <div>
                            <select
                                className="inlineField"
                                value={this.state.topicToAdd}
                                onChange={
                                    (e) => this.setState({topicToAdd: e.target.value})
                                }
                            >
                                <option value="">
                                    {this.props.translate("userTopicAdmin.topicToAdd.template")}
                                </option>
                                {topicList.map((item, index) => (
                                    <option
                                        value={item}
                                        key={index}
                                    >
                                        {this.props.translate("userTopicAdmin.topicToAdd.topics." + item)}
                                    </option>
                                ))}
                            </select>

                            <button
                                style={{marginLeft:"7px"}}
                                type="button"
                                className="xPoolButton"
                                onClick={() => this.addTopic(this.state.topicToAdd)}
                                disabled={(this.state.topicToAdd === "")}
                                xp-test-id="userTopicAdminAddButton"
                            >
                                <XpTranslated trKey='userTopicAdmin.addButton'/>
                            </button>
                        </div>}
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        translate : selectTranslateFunction(state),
        topics    : topicsSelector(state),
        userLoading : ormItemLoading(state, {itemId: props.user.id, ormModel: 'User'})
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userTopicActionToApi:         (action, userId, topic) => dispatch(userTopicActionToApi(action, userId, topic)),
    };
};

const ConnectedUserTopicsAdmin = connect(mapStateToProps, mapDispatchToProps)(UserTopicsAdmin);

ConnectedUserTopicsAdmin.propTypes = {
    className:        PropTypes.string,
    user:             PropTypes.object.isRequired,
    disabled:         PropTypes.bool
};

ConnectedUserTopicsAdmin.defaultProps = {
    disabled   : false,
};

export default ConnectedUserTopicsAdmin;

