import {ReportConfigDropdown} from "gui-common/report/reportComponents/reportComponents";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import PropTypes from "prop-types";
import React from "react";

function getClientItems(reportData) {
    let returnArray = [];
    for (let item of reportData) {
        if (!returnArray.find(client => client.id === item.clientId)) returnArray.push({id: item.clientId, name: item.clientName});
    }
    return returnArray;
}

export function ReportConfigSelectClient ({reportData, setReportConfig, reportConfig, translate, langPath}) {
    if (!setReportConfig || !reportConfig || !translate) return null;
    /*
        const displayCheckbox = (parameter) => {
            return (
                <ReportConfigCheckbox
                    parameter       = {parameter}
                    reportConfig    = {reportConfig}
                    setReportConfig = {setReportConfig}
                    label           = {translate(langPath + '.settings.' + parameter + '.label')}
                />
            )};
    */

    return (
        <div>
            {/*
            {displayCheckbox('showMonths'   )}
            {displayCheckbox('showClients'  )}
*/}
            <ReportConfigDropdown
                parameter       = {'showClientId'}
                nameParameter   = {'showClientName'}
                reportConfig    = {reportConfig}
                setReportConfig = {setReportConfig}
                label           = {<XpTranslated trKey={langPath + '.settings.showClient.label'}/>}
                template        = {translate(langPath + '.settings.showClient.template')}
                selectItems     = {getClientItems(reportData)}
            />
        </div>
    )
}
ReportConfigSelectClient.propTypes = {
    setReportConfig : PropTypes.func.isRequired,
    onRenderCallback: PropTypes.func,
    reportConfig    : PropTypes.object.isRequired,
    translate       : PropTypes.object.isRequired,
    langPath        : PropTypes.string.isRequired
};

