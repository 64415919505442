export const CURRENCY_PAIR_CONFIG_CHANGED = 'CURRENCY_PAIR_CONFIG_CHANGED';
export const CURRENCY_PAIR_CONFIG_RESET   = 'CURRENCY_PAIR_CONFIG_RESET';

// Action creators ********************************************************
// ************************************************************************
export function currencyPairConfigSetChanged(newConfig) {
    return {type: CURRENCY_PAIR_CONFIG_CHANGED, payload: newConfig};
}
export function currencyPairConfigReset() {
    return {type: CURRENCY_PAIR_CONFIG_RESET,};
}
// Reducer functions ********************************************************
// ************************************************************************

function setChanged(state, payload) {
    if (!payload) return state;
    const newChangedItems = {...state.changedItems};
    newChangedItems[payload.id] = payload;

    return {...state, changedItems: newChangedItems};
}
function reset(state, payload) {
    return defaultConfig;
}
// Reducer action handlers map ************************************************
// ************************************************************************
const actionHandler = {
    [CURRENCY_PAIR_CONFIG_CHANGED]       : setChanged,
    [CURRENCY_PAIR_CONFIG_RESET]         : reset,
};
const defaultConfig = {
    changedItems : {},
    loading      : false,
};

const getInitialState = () => (defaultConfig);

export function currencyPairConfigReducer(state = getInitialState(), action) {
    const handler = actionHandler[action.type];
    return handler ? handler(state, action.payload) : state;
}
