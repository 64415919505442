import React, {useMemo, useRef} from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import {newXpAdminFormTemplateSelector} from "gui-common/xpAdminForm/xpAdminFormSelectors";
import {getXpAdminFormListInstanceId} from "gui-common/xpAdminForm/XpAdminFormEntityList";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {xpFormUserCanEditBase, formDiffersFromCurrent} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import {editBaseUsed} from "appConfig/appConfig";
import {useXpFormContext, useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {getXpFormFieldValueSelector, getXpFormRootSelector} from "gui-common/xpForm/core/xpFormSelectors";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormLoadForm, xpFormRemoveForm} from "gui-common/xpForm/core/xpFormReducer";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";


function useNumberOfChildEntities(props) {
    const dependentFields = useXpFormFields({dependentFields: props.childEntityProps ? {childEntities: props.childEntityProps.arrayProperty} : undefined});

    const numberOfChildEntities = dependentFields?.childEntities?.length;
    return useMemo(
        () => {
            return numberOfChildEntities;
        },
        [numberOfChildEntities]
    );
}

function ChildEntitySelector(props)  {

    const propsRef = useRef(props); // Needed to transport updated hook to callback scope.
    propsRef.current = props;

    const numberOfChildEntities = useNumberOfChildEntities(props)

    function fieldIsDisabled() {
        if (numberOfChildEntities > 1) return true;
        if (!editBaseUsed()) return false;
        return !xpFormUserCanEditBase(props.formContext, props.ormModel, props.isSubForm);
    }

    function getRootEntityWithFirstChild(rootFormData, firstChild, isUsed) {

        function returnNextLevel(thisLevelFormData, propsArray) {
            if (!propsArray.length) return {
                ...thisLevelFormData,
                [props.childEntityProps.inParentFormProperty] : isUsed,
                [props.childEntityProps.arrayProperty]        : [firstChild],
            }
            const nextLevelData = returnNextLevel(thisLevelFormData[propsArray[0]], propsArray.slice(1));
            if (Array.isArray(thisLevelFormData)) {
                thisLevelFormData[propsArray[0]] = nextLevelData;
                return thisLevelFormData;
            }
            return {...thisLevelFormData, [propsArray[0]]: nextLevelData}
        }

        const formModelPropsArray = props.formContext.formModel.split('.').slice(4);
        const newRootEntity = returnNextLevel(rootFormData, formModelPropsArray);

        return newRootEntity;
    }

    function useChildEntitiesChanged (model, newValue, oldValue, changeValue) {
        return (dispatch, getState) => {
            changeValue(newValue);

            const currentProps = propsRef.current;
            // const currentChildEntities = getXpFormPropertyValueSelector()(getState(), {model: currentProps.formContext.formModel + '.' + currentProps.childEntityProps.arrayProperty});
            const currentChildEntities = getXpFormFieldValueSelector()(getState(), {model: currentProps.formContext.formModel + '.' + currentProps.childEntityProps.arrayProperty});

            const childFormModel = (currentChildEntities && currentChildEntities[0]) ? 'xpAdminForm.' + currentProps.xpAdminFormInstanceId + '.' + currentProps.childEntityProps.ormModel + '.' + currentChildEntities[0].id : undefined;
            const currentChildFormData = (childFormModel) ? getXpFormRootSelector()(getState(), {model: childFormModel}) : undefined;

            console.log("Callback useChildEntitiesChanged: ", currentProps.childEntityProps, newValue);


            if (currentProps.formContext.formUseState === XP_FORM_NEW) {
                // No sub form load when in new state. Normal rendering mechanics will handle this case.
                return;
            }

            const thisFormData  = getXpFormRootSelector()(getState(), {model: currentProps.formContext.formModel})
            const rootFormData  = getXpFormRootSelector()(getState(), {model: currentProps.formContext.rootFormModel})
            let childDataToLoad = newXpAdminFormTemplateSelector(getState(), {...currentProps, parentItemData: thisFormData, ormModel: currentProps.childEntityProps.ormModel});

            if (!newValue) { // Not used
                // If there is no child entity in array, a new template must be injected. Otherwise the form content of the child model must be injected.
                if (currentChildEntities?.length) {
                    if (!currentChildFormData || (currentChildEntities[0].id !== currentChildFormData.id)) {
                        dispatch(xpFormRemoveForm(childFormModel));
                        return; // No need to load form. Rendering mechanics will handle this case.
                    }
                    childDataToLoad = currentChildFormData;
                } else if (currentChildFormData && !currentChildFormData.id) { // This should not hapen. Maybe in NEW case, but that is not handled here...
                    childDataToLoad = currentChildFormData;
                }
                const objectToLoad = {
                    ...getRootEntityWithFirstChild(rootFormData, childDataToLoad, false),
                }
                dispatch(xpFormLoadForm(currentProps.formContext.rootFormModel, objectToLoad, currentProps.formContext.formUseState));
                dispatch(xpFormRemoveForm(childFormModel));
                return;
            }

            // From not used to used:
            // We need to set the root model form of the child entity to the same form state and with the same data as the first child entity. And select the entity in the child list.

            if (!currentChildEntities?.length) {
                console.error("No child entities in useChildEntitiesChanged: ", currentProps.childEntityProps);
                return;
            }

            const childCurrentData = props.xpAdminFormConfig[currentProps.childEntityProps.ormModel].getCurrentDataSelector()(getState(), {selectId: currentChildEntities[0].id});
            childDataToLoad = (currentChildFormData && (currentChildEntities[0].id === currentChildFormData.id)) ? currentChildFormData : currentChildEntities[0];

            const decDenLangState = selectDecDenLangState(getState());
            const childDataIsChanged = formDiffersFromCurrent(childCurrentData,childDataToLoad, decDenLangState);

            if (childDataIsChanged || (currentProps.formContext.formUseState === XP_FORM_NEW)) {
                dispatch(xpFormLoadForm(childFormModel, childDataToLoad, currentProps.formContext.formUseState));
            }

            const childListInstanceId = getXpAdminFormListInstanceId(currentProps.xpAdminFormInstanceId, currentProps.childEntityProps.ormModel);
            dispatch(xpGridSetSelected(childListInstanceId, childDataToLoad.id));
        }
    }

    if (!props.childEntityProps) return null;

    return (
        <div className={props.className}>
            <XpFormSwitchInput
                field            = {props.childEntityProps.inParentFormProperty}
                disabled         = {fieldIsDisabled()}
                onChangeThunk    = {useChildEntitiesChanged}
            />
        </div>
    );
}


export default function XpAdminFormUseChildEntitySelectors(props)  {
    const formContext = useXpFormContext();
    if (!props.xpAdminFormConfig[props.ormModel].childEntities || !formContext) return null;

    return (
        <div>
            {props.xpAdminFormConfig[props.ormModel].childEntities.map(childEntityProps => (
                <ChildEntitySelector
                    {...props}
                    key              = {childEntityProps.ormModel}
                    childEntityProps = {childEntityProps}
                    formContext      = {formContext}
                />
            ))}
        </div>
    );
}



