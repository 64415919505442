import {XP_FORM_EDIT, XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";

export function xpFormGetFieldValueFromData(data, nestedModel) {
    if (nestedModel === undefined) return undefined;
    if (!data) return undefined;
    if (nestedModel === "") return data;
    let currentDataPointer = data;
    const nestedModelArray = nestedModel.split('.');
    for (let field of nestedModelArray) {
        if (currentDataPointer === undefined) return undefined;
        if (currentDataPointer === null     ) return undefined;
        currentDataPointer = currentDataPointer[field];
    }
    return currentDataPointer;
}
export function xpFormIsReadOnly(formContext) {
    return ![XP_FORM_EDIT, XP_FORM_NEW].includes(formContext?.formUseState);
}
export function getItemRenderProps(translate, item, props, trParams) {
    const formContext = props.formContext ? props.formContext : props.formcontext; // Ugly, but one way to solve stupid warning in console. Long story...

    const formTemplate = props.formTemplate ? props.formTemplate : formContext.formTemplate;
    const trKey = item.trKey ? item.trKey : formTemplate + "." + props.field + (item.id ? (".values." + item.id) : ".template");
    let translatedText = trKey ? translate(trKey) : undefined;
    if (!translatedText || translatedText.includes("No text defined for")) {
        const labelString = props.itemTextField ? item[props.itemTextField] : item.name ? item.name : 'id: ' + item.id;
        return {
            id: item.id,
            value: (labelString !== undefined) ? labelString : "N/A",
            disabled: (labelString !== undefined) ? item.disabled : true,
            renderTooltip: item.renderTooltip,
            tooltipTrKey : item.tooltipTrKey,
        }
    }
    return {
        id: item.id,
        trKey: trKey,
        trParams: trParams,
        value: translate(trKey, trParams),
        disabled: (item.id === '') ? false : item.disabled,
        renderTooltip: item.renderTooltip,
        tooltipTrKey : item.tooltipTrKey,
    }
}

export function xpFormContainsErrors(object) {
    for (let key in object) {
        if (key[0] === '$') {
            if (object[key] && object[key].errors) return true;
        }
        else {
            if (typeof object[key] !== 'object') continue;
            if (xpFormContainsErrors(object[key])) return true;
        }
    }
    return false;
}
