import React, {useContext, useMemo} from "react";
import {useSelector} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {rateInputParser, stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {activeCurrencyPairsSelector} from "gui-common/orm/ormSelectors";
import {useCurrencyPairFromBar} from "xpool-gui-common/appConfig/runConfiguration/runConfigurationFunctions";
import {checkConflictWithOtherElements} from "gui-common/runConfiguration/runConfigurationFunctions";
import {useSelectorInstance, createSelectItems} from "gui-common/functions/hooks";
import "xpool-gui-common/appConfig/runConfiguration/holdOnRate.css"
import {getCurrentFxPriceSelector} from "gui-common/fxPrice/fxPriceSelectors";
import XpTooltip from "gui-common/components/XpTooltip";
import {XpFormContext} from "gui-common/xpForm/core/XpForm";
import {useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";

function HoldOnRateForm (props) {

    const decDenLangState   = useSelector(selectDecDenLangState);
    const currencyPairs     = useSelector(activeCurrencyPairsSelector);
    const formContext    = useContext(XpFormContext);
    const formData       = useXpFormFields({dependentFields: {
            holdAboveRate: 'holdAboveRate',
            holdBelowRate: 'holdBelowRate',
            currencyPairId: 'currencyPairId'
        }})
    const currentFxRate     = useSelectorInstance(getCurrentFxPriceSelector,  {selectCurrencyPairId: formData?.currencyPairId});
    const runConfigurationConfig = useAppEnvProperty('runConfigurationConfig');

    useCurrencyPairFromBar(props);

    function useForOverlap(val, checkConfig) {
        if (!checkConfig.isActive) return false;
        if (checkConfig.useFor === 'ALL') return true;
        if (val === 'ALL') return true;
        if (val === checkConfig.useFor) return true
        return false;
    }
    const useForTypes = createSelectItems(['ALL', 'SWEEP', 'TOP_UP'], '.useForTypes.', formContext.formTemplate);

    function str2num (val) {
        return stringNumberToFloat(val, decDenLangState);
    }

    function renderRateField(field, crossField) {
        return (
            <XpFormTextInput
                alignRight noTemplate inLineLayout
                inLineDivider={45}
                disabled = {props.disabled}
                field         = {field}
                updateOn      = "blur"
                parser={value => rateInputParser(value, runConfigurationConfig?.holdOnRate?.maxDecimals ? runConfigurationConfig?.holdOnRate?.maxDecimals : 4)}
                errors={{
                    noLevelDefined    : (val) => !/\d/.test(val) && !/\d/.test(formData[crossField]),
                    lowerThanZero     : (val) => /\d/.test(val) && str2num(val) <= 0,
                    levelsEqual       : (val) => /\d/.test(val) && /\d/.test(formData[crossField]) && (str2num(val) === str2num(formData[crossField])),
                }}
            />
        )
    }
    const illustrationCss = useMemo(
        () => {
            if (!/\d/.test(formData.holdAboveRate) && !/\d/.test(formData.holdBelowRate)     ) return undefined;
            if (/\d/.test( formData.holdAboveRate) && (str2num(formData.holdAboveRate) <= 0) ) return undefined;
            if (/\d/.test( formData.holdBelowRate) && (str2num(formData.holdBelowRate) <= 0) ) return undefined;
            if (str2num(   formData.holdAboveRate) ===  str2num(formData.holdBelowRate)      ) return undefined;

            if (!/\d/.test(formData.holdAboveRate)) return {low: 'holdOnRateHold'   , mid: 'holdOnRateRelease', high: 'holdOnRateRelease'}
            if (!/\d/.test(formData.holdBelowRate)) return {low: 'holdOnRateRelease', mid: 'holdOnRateRelease', high: 'holdOnRateHold'}

            if (str2num(formData.holdAboveRate) < str2num(formData.holdBelowRate))  return {low: 'holdOnRateRelease', mid: 'holdOnRateHold', high: 'holdOnRateRelease'};

            return {low: 'holdOnRateHold'   , mid: 'holdOnRateRelease', high: 'holdOnRateHold'}
        },
        [formData]
    );
    const illustrationCssWithActiveSegment = useMemo(
        () => {
            if (!illustrationCss) return undefined;
            if (!currentFxRate) return illustrationCss;

            // First check overlapping case
            if (/\d/.test(formData.holdAboveRate) && /\d/.test(formData.holdBelowRate) && (str2num(formData.holdAboveRate) < str2num(formData.holdBelowRate))) {
                if ((currentFxRate.fxRate > str2num(formData.holdAboveRate)) && (currentFxRate.fxRate < str2num(formData.holdBelowRate))) return {
                    ...illustrationCss,
                    mid: illustrationCss.mid + " holdOnRateActive",
                }
                else if (currentFxRate.fxRate >= str2num(formData.holdBelowRate)) return {
                    ...illustrationCss,
                    high: illustrationCss.high + " holdOnRateActive",
                }
                else return {
                        ...illustrationCss,
                        low: illustrationCss.low + " holdOnRateActive",
                    }
            }
            if (!/\d/.test(formData.holdAboveRate)) {
                if (currentFxRate.fxRate < Number(formData.holdBelowRate)) return {
                    ...illustrationCss,
                    low: illustrationCss.low + " holdOnRateActive",
                }
                else return {
                    ...illustrationCss,
                    mid : illustrationCss.mid  + " holdOnRateActive",
                    high: illustrationCss.high + " holdOnRateActive",
                }
            }
            if (!/\d/.test(formData.holdBelowRate)) {
                if (currentFxRate.fxRate > Number(formData.holdAboveRate)) return {
                    ...illustrationCss,
                    high: illustrationCss.high + " holdOnRateActive",
                }
                else return {
                    ...illustrationCss,
                    mid : illustrationCss.mid  + " holdOnRateActive",
                    low : illustrationCss.low  + " holdOnRateActive",
                }
            }
            // Both fields are set and there is no overlap
            if ((currentFxRate.fxRate <= str2num(formData.holdAboveRate)) && (currentFxRate.fxRate >= str2num(formData.holdBelowRate))) return {
                ...illustrationCss,
                mid: illustrationCss.mid + " holdOnRateActive",
            }
            else if (currentFxRate.fxRate < str2num(formData.holdBelowRate)) return {
                ...illustrationCss,
                low: illustrationCss.low + " holdOnRateActive",
            }
            else return {
                    ...illustrationCss,
                    high: illustrationCss.high + " holdOnRateActive",
                }

        },
        [illustrationCss, currentFxRate, formData]
    );

    const rateInSegment = useMemo(
        () => {
            if (!currentFxRate) return 'none';
            if (!/\d/.test(formData.holdAboveRate) && !/\d/.test(formData.holdBelowRate)) return 'none';

            // First check overlapping case
            if (/\d/.test(formData.holdAboveRate) && /\d/.test(formData.holdBelowRate) && (str2num(formData.holdAboveRate) < str2num(formData.holdBelowRate))) {
                if ((currentFxRate.fxRate > str2num(formData.holdAboveRate)) && (currentFxRate.fxRate < str2num(formData.holdBelowRate))) return 'mid';
                if (currentFxRate.fxRate >= str2num(formData.holdBelowRate)) return 'high';
                return 'low';
            }

            if (!/\d/.test(formData.holdAboveRate)) {
                if (currentFxRate.fxRate < Number(formData.holdBelowRate)) return 'low';
                return 'mid';
            }
            if (!/\d/.test(formData.holdBelowRate)) {
                if (currentFxRate.fxRate > Number(formData.holdAboveRate)) return 'high';
                return 'mid';
            }
            // Both fields are set and there is no overlap
            if ((currentFxRate.fxRate <= str2num(formData.holdAboveRate)) && (currentFxRate.fxRate >= str2num(formData.holdBelowRate))) return 'mid';
            if (currentFxRate.fxRate < str2num(formData.holdBelowRate)) return 'low';
            return 'high'
        },
        [formData, currentFxRate]
    );


    function rateDiv() {
        if (!currentFxRate) return null;
        return (
            <div className='holdOnRateRateDisplay'>{currentFxRate.fxRate}</div>
        )
    }
    function renderRate(segment) {
        if ((rateInSegment === 'none') && (segment === 'mid')) return rateDiv();
        if (segment !== rateInSegment) return " ";
        return rateDiv();
    }

    return (
        <div>
            <div>
                <XpFormSelectorInput
                    noTemplate inLineLayout
                    inLineDivider={60}
                    disabled = {props.disabled}
                    field            = "useFor"
                    selectList       = {useForTypes}
                    itemTextField    = "name"
                    errors={{
                        useForOverlap: val => checkConflictWithOtherElements(val, useForOverlap, props),
                    }}
/*
                    errorMessages={{
                        useForOverlap: setTranslatedError(formContext.formTemplate + '.useFor.errors.useForOverlap'),
                    }}
*/
                />
                {(props.ormModel !== 'BankAccount') &&
                <XpFormSelectorInput
                    isRequired inLineLayout
                    disabled = {props.disabled}
                    field         = "currencyPairId"
                    selectList    = {currencyPairs}
                    itemTextField = "key"
                />}
            </div>
            <div className='tableInForm'>
                <table style={{width: '100%', margin: '0'}}>
                    <tbody>
                    <tr key="headerTop">
                        <th style={{width: '24%' }} className="blankHeader" > </th>
                        <th style={{width: '25%' }} className="blankHeader" > </th>
                        <th style={{width: '2%'  }} className="blankHeader" > </th>
                        <th style={{width: '24%' }} className="blankHeader" > </th>
                        <th style={{width: '25%' }} className="blankHeader" > </th>
                    </tr>
                    <tr key="header">
                        <td className="blankHeader" colSpan="2"> {renderRateField('holdBelowRate', 'holdAboveRate')}</td>
                        <td className="blankHeader"> </td>
                        <td className="blankHeader" colSpan="2"> {renderRateField('holdAboveRate', 'holdBelowRate')}</td>
                    </tr>

                    {!formContext.auditMode &&
                    <XpTooltip
                        trKey={'runConfiguration.holdOnRate.illustrationTooltip'}
                        trParams={currentFxRate ? {fxRate: currentFxRate.fxRate, currencyPair: currentFxRate.currencyPair.key} : {}}
                        bypass = {currentFxRate ? undefined : true}
                    >
                        <tr key="illustration">
                            <td className="blankDetail"><label><XpTranslated trKey={formContext.formTemplate + '.illustration.label'}/></label></td>

                            <td className="holdOnRate"             ><div className={"holdOnRateLow " +  (illustrationCssWithActiveSegment ? illustrationCssWithActiveSegment.low  : "holdOnRateInvalid")}>{renderRate('low' )}</div></td>
                            <td className="holdOnRate" colSpan="2" ><div className={                    (illustrationCssWithActiveSegment ? illustrationCssWithActiveSegment.mid  : "holdOnRateInvalid")}>{renderRate('mid' )}</div></td>
                            <td className="holdOnRate"             ><div className={"holdOnRateHigh " + (illustrationCssWithActiveSegment ? illustrationCssWithActiveSegment.high : "holdOnRateInvalid")}>{renderRate('high')}</div></td>
                        </tr>
                    </XpTooltip>}

                    </tbody>
                </table>
            </div>
        </div>
    )
}
HoldOnRateForm.propTypes = {
};
export default HoldOnRateForm

