import React from "react";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";

function RunTypeActivationForRun (props) {

    const runConfigurationConfig = useAppEnvProperty( 'runConfigurationConfig');

    if (runConfigurationConfig?.hedgeExposure?.isDisabled) return null;
    return (
        <div style={{marginBottom: '7px'}}>
            <div className='inlineFormField' style={{width:'50%'}}>
                <XpFormSwitchInput
                    noMargin
                    field        = "runLiquidity"
                    disabled    = {props.disabled}
                />
            </div>
            <div className='inlineFormField' style={{width:'50%'}}>
                <XpFormSwitchInput
                    noMargin
                    field        = "runHedge"
                    disabled    = {props.disabled}
                />
            </div>
        </div>
    )
}
export default RunTypeActivationForRun
