import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import {bankHolidaysTodaySelector, noRunDateTodaySelector} from "gui-common/bankHolidays/bankHolidaysSelectors";
import XpTooltip from "gui-common/components/XpTooltip";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import 'gui-common/bankHolidays/bankHolidays.css'

function BankHolidayIndicator (props) {

    const today         = useMemo(() => moment().format('YYYY-MM-DD'), []);
    const todayHolidays  = useSelector(state => bankHolidaysTodaySelector(state, {selectDate: today}));
    const noRunDateToday = useSelector(state => noRunDateTodaySelector   (state, {selectDate: today}));

    const backgroundCss = useMemo(
        () => {
            if (noRunDateToday) return "bankHolidayIndicatorNoRunDate";
            if (todayHolidays && todayHolidays.length) return "bankHolidayIndicatorHolidays";
            return "bankHolidayIndicatorNoHolidays";
        },
        [noRunDateToday, todayHolidays]
    );

    const displayText = useMemo(
        () => {
            if (noRunDateToday) return "NRD";
            if (todayHolidays && todayHolidays.length) return (todayHolidays.length === 1) ? todayHolidays[0].currencyName : todayHolidays.length + ' ...';
            return "";
        },
        [noRunDateToday, todayHolidays]
    );

    const showTooltip = useMemo(
        () => {
            if (noRunDateToday) return <XpTranslated trKey={'bankHolidaysIndicator.noRunDateActive'}/>;
            if (!todayHolidays || !todayHolidays.length) return null;
            return (
                <div>
                    <XpTranslated trKey={'bankHolidaysIndicator.bankHolidaysToday'}/>
                    {todayHolidays.map((item, index) =>
                            <div key={index}>
                                {item.currencyName}: {item.description}
                            </div>
                            )}
                </div>
            )
        },
        [noRunDateToday, todayHolidays]
    );

    return (
        <XpTooltip title={showTooltip}>
            <div className={"bankHolidayIndicatorBase " + backgroundCss}>
                {displayText}
            </div>
        </XpTooltip>
    )
}
BankHolidayIndicator.propTypes = {
};
export default BankHolidayIndicator



