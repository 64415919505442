import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {xpCommonOnActiveClientThunk} from "xpool-gui-common/appFunctions";

export function appOnActiveClientThunk(client) {
    return (dispatch, getState) => {
        dispatch(xpCommonOnActiveClientThunk(client));
        dispatch(xpGridSetSelected('cashPoolAccountList', undefined))
    }
}
export function appSpecificOnReadyThunk() {
    return (dispatch, getState) => {
    }
}
