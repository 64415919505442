import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    shallowActiveProvidersSelector,
} from "gui-common/orm/ormSelectors";
import XpFormFieldGroup from "gui-common/xpForm/XpFormFieldGroup";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {xpFormUserCanEditBase} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";


function LegalEntityUnitBaseStatic(props) {

    const ormProviders         = useSelector(shallowActiveProvidersSelector);
    const dispatch             = useDispatch();
    const formContext          = useXpFormContext();
    const formDataFxEnabled    = useXpFormField('fxEnabled');
    const formDataFxProviderId = useXpFormField('fxProviderId');
    const formConfig = useAppEnvProperty('legalEntityUnitFormConfig');

    const fxProviders = useMemo(
        () => {
            return ormProviders.filter(item => item.providerType === 'FX');
        },
        [ormProviders]
    );
    useEffect(
        () => {
            if (formContext?.formUseState === XP_FORM_VIEW) return;
            if ((formDataFxEnabled === true) && (formDataFxProviderId === "")) dispatch(xpFormChangeField(formContext.formModel + '.fxProviderId', fxProviders[0].id));
        },
        [formDataFxEnabled, formDataFxProviderId, formContext?.formUseState],
    );


    function fieldIsDisabled() {
        if (props.enforceUseCanEdit) return false;
        return !xpFormUserCanEditBase(formContext, props.ormModel, props.isSubForm);
    }

    return (
        <div className={props.className}>
            <XpFormFieldGroup
                bypass={props.bypassFieldGroup || formConfig?.fieldsConfig?.fxEnabled?.hide}
                isExpanded={(formDataFxEnabled === true)}
                disableAnimations = {formContext?.formUseState === XP_FORM_VIEW}
                controlComponent={
                    <XpFormSwitchInput
                        noMargin
                        field= "fxEnabled"
                        disabled={fieldIsDisabled()}
                    />
                }
            >
                <XpFormSelectorInput
                    field="fxProviderId"
                    selectList={fxProviders}
                    itemTextField="name"
                    disabled={fieldIsDisabled()}
                    inLineLayout noTemplate
                    isRequired
                    defaultValue={fxProviders?.length ? fxProviders[0].id : null}
                />
                <XpFormTextInput
                    field= "fxShortName"
                    disabled={fieldIsDisabled()}
                    checkNoSpaces={true}
                    checkOnlySpaces={false}
                    minLength={2}
                    maxLength={20}
                    inLineLayout
                    isRequired
                />
            </XpFormFieldGroup>
        </div>
    )
}

export default LegalEntityUnitBaseStatic;

