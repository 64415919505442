import React from "react";
import {useDispatch} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {xpTranslatedSetEditTextMode} from "gui-common/appLocale/xpTranslated/xpTranslatedReducer";
import {useXpTranslatedEditTextMode} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";


export function EditTextModeSetting () {
    const editTextMode = useXpTranslatedEditTextMode();
    const dispatch     = useDispatch();

    return (
        <div xp-test-id='xpEditTextModeSetting'>
            <h3 className="userPrefsCardHeader">
                <XpTranslated trKey={'appLangSetEditMode.header'}/>
            </h3>

            <div className='field'>
                <FormControlLabel
                    control={
                        <Switch
                            checked ={editTextMode}
                            onChange={value => dispatch(xpTranslatedSetEditTextMode(!editTextMode))}
                            color="primary"
                            // size="small"
                        />
                    }
                    label={(<label className="labelCheckbox">
                        <XpTranslated trKey={'appLangSetEditMode.activateEditTestMode'}/>
                    </label>)}
                />
           </div>
        </div>
    )
}
