import cloneDeep from "lodash/cloneDeep";

export const SET_INITIAL_STEPS = 'SET_INITIAL_STEPS';
export const MARK_STEP_AS_COMPLETE = 'MARK_STEP_AS_COMPLETE';
export const MARK_STEP_AS_INCOMPLETE = 'MARK_STEP_AS_INCOMPLETE';
export const SET_STEP_VALIDITY       = 'SET_STEP_VALIDITY';
export const SET_NEXT_STEP = 'SET_NEXT_STEP';
export const RESET_WIZARD = 'RESET_WIZARD';
export const COMPLETED_WIZARD = 'COMPLETED_WIZARD';
export const SUBMITTED_WIZARD = 'SUBMITTED_WIZARD';
export const FAILED_SUBMIT = 'FAILED_SUBMIT';
export const SET_FILENAME = 'SET_FILENAME';
export const LOAD_FILE_CONTENT = 'LOAD_FILE_CONTENT';
export const SET_JUMP_STEP = 'SET_JUMP_STEP';
export const SWITCH_WIZARD = 'SWITCH_WIZARD';
export const SET_HIDDEN_STEPS = 'SET_HIDDEN_STEPS';
export const STORE_WIZARD = 'STORE_WIZARD';
export const POP_WIZARD = 'POP_WIZARD';

export function setInitialSteps(action) {
    return {type: SET_INITIAL_STEPS, payload: action};
}
export function markStepAsComplete(action) {
    return {type: MARK_STEP_AS_COMPLETE, payload: action};
}
export function markStepAsIncomplete(action) {
    return {type: MARK_STEP_AS_INCOMPLETE, payload: action};
}
export function setStepValidity(action) {
    return {type: SET_STEP_VALIDITY, payload: action};
}
export function setNextStep(action) {
    return{type: SET_NEXT_STEP, payload: action};
}
export function resetWizard(action){
    return {type: RESET_WIZARD, payload: action};
}
export function completedWizard(action) {
    return {type: COMPLETED_WIZARD, payload: action};
}
export function failedSubmit(action) {
    return {type: FAILED_SUBMIT, payload: action};
}
export function loadFileContent(action) {
    return {type: LOAD_FILE_CONTENT, payload: action};
}
export function setJumpStep(action) {
    return {type: SET_JUMP_STEP, payload: action};
}
export function setFilename(fileName) {
    return {type: SET_FILENAME, payload: fileName}
}
export function submittedWizard(wizard) {
    return {type: SUBMITTED_WIZARD, payload: wizard};
}
export function switchWizard(action) {
    return {type: SWITCH_WIZARD, payload: action};
}
export function setHiddenSteps(action) {
    return {type: SET_HIDDEN_STEPS, payload: action};
}
export function storeWizard(action){
    return {type: STORE_WIZARD, payload: action};
}
export function popWizard(action) {
    return {type: POP_WIZARD, payload: action};
}

const initialWizard = {
    wizardId: 0,
    wizardStep: 0,
    wizardName: "",
    stepInfo: [],
    completedSteps: [],
    initiatedSteps: [],
    invalidSteps  : [],
    failedSubmit: false,
    completed: false,
    fileContent: "",
    fileName: "",
    submitted: false,
    createObject: false,
    ormModel: "",
    wizardFormName: "",
    backupWizards: [],
    hidden:[],
};
const getInitialState = () => ([]);
function getInitialHidden(stepInfo) {
    let hidden = [];
    for (let stepIndex in stepInfo){
        if(stepInfo[stepIndex].initialHidden) hidden.push(parseInt(stepIndex));
    }
    return hidden;
}
export function wizardReducer(state = getInitialState(), action) {
    let newState;
    switch (action.type) {
        case SET_INITIAL_STEPS:
            newState = cloneDeep(state);
            for (let wizard of newState) {
                if(wizard.wizardName === action.payload.wizardName) {
                    wizard.stepInfo       = action.payload.stepInfo;
                    wizard.createObject   = action.payload.createObject;
                    wizard.ormModel       = action.payload.ormModel;
                    wizard.wizardFormName = action.payload.wizardFormName;
                    return newState;
                }
            }
            return [
                ...newState,
                {
                    ...initialWizard,
                    wizardName: action.payload.wizardName,
                    stepInfo: action.payload.stepInfo,
                    createObject: action.payload.createObject,
                    ormModel: action.payload.ormModel,
                    wizardFormName : action.payload.wizardFormName,
                    hidden         : getInitialHidden(action.payload.stepInfo),
                }
            ];
        case MARK_STEP_AS_COMPLETE:
            newState = cloneDeep(state);
            for(let wizard of newState) {
                if(wizard.wizardName === action.payload.wizardName) {
                    if(!wizard.completedSteps.includes(action.payload.stepNo)){
                        wizard.completedSteps.push(action.payload.stepNo);
                        return newState;
                    }
                }
            }
            return state;
        case MARK_STEP_AS_INCOMPLETE :
            newState = cloneDeep(state);
            let found = newState.findIndex(function(element){
                return element.wizardName === action.payload.wizardName;
            });
            if(found !== -1) {
                let newCompletedSteps = newState[found].completedSteps.filter(val => val !== action.payload.stepNo);
                newState[found].completedSteps = newCompletedSteps;
                return newState;
            }
            return state;

        case SET_STEP_VALIDITY :
            newState = cloneDeep(state);
            let wizardInstanceInValidity = newState.find(element => element.wizardName === action.payload.wizardName);
            if (!wizardInstanceInValidity)           return state;
            if (action.payload.stepNo === undefined) return state;

            if (action.payload.stepIsValid) {
                if (!wizardInstanceInValidity.invalidSteps.includes(action.payload.stepNo)) return state;
                wizardInstanceInValidity.invalidSteps = wizardInstanceInValidity.invalidSteps.filter(item => item !== action.payload.stepNo);
            }
            else {
                if (wizardInstanceInValidity.invalidSteps.includes(action.payload.stepNo)) return state;
                wizardInstanceInValidity.invalidSteps.push(action.payload.stepNo);
            }
            return newState;

        case SET_NEXT_STEP:
            newState = cloneDeep(state);
            for (let wizard of newState) {
                if(wizard.wizardName === action.payload.wizardName) {
                    wizard.wizardStep = action.payload.step < 0 ? (wizard.wizardStep > 0 ? wizard.wizardStep + action.payload.step : wizard.wizardStep) : (wizard.wizardStep < wizard.stepInfo.length -1 ? wizard.wizardStep + action.payload.step : wizard.wizardStep);
                    while (wizard.hidden.includes(wizard.wizardStep)){
                        wizard.wizardStep = wizard.wizardStep + (action.payload.step > 0 ? 1 : -1);
                    }
                    let newInitiatedSteps = wizard.initiatedSteps.filter(x => !wizard.completedSteps.includes(x));
                    if(action.payload.step > 0) {
                        newInitiatedSteps.push(wizard.wizardStep);
                        wizard.initiatedSteps = newInitiatedSteps;
                    }
                    return newState;
                }
            }
            return state;
        case SET_JUMP_STEP:
            newState = cloneDeep(state);
            for (let wizard of newState) {
                if (wizard.wizardName === action.payload.wizardName) {
                    wizard.wizardStep = action.payload.stepNo;
                    return newState;
                }
            }
            return state;
        case RESET_WIZARD:
            newState = cloneDeep(state);
            for(let key in newState) {
                if(newState[key].wizardName === action.payload.wizardName) {
                    let oldSteps = [...newState[key].stepInfo];
                    let oldCreateObject = newState[key].createObject;
                    let oldOrmModel = newState[key].ormModel;
                    let oldWizardFormName = newState[key].wizardFormName;
                    newState[key] = {
                        ...initialWizard,
                        wizardName      : action.payload.wizardName,
                        stepInfo        : oldSteps,
                        createObject    : oldCreateObject,
                        ormModel        : oldOrmModel,
                        wizardFormName  : oldWizardFormName,
                        hidden          : getInitialHidden(oldSteps)
                    };
                    return newState;
                }
            }
            return state;
        case COMPLETED_WIZARD:
            newState = cloneDeep(state);
            for(let wizard of newState) {
                if(wizard.wizardName === action.payload.wizardName) {
                    wizard.completed = true;
                    return newState;
                }
            }
            return state;
        case SUBMITTED_WIZARD:
            newState = cloneDeep(state);
            for(let wizard of newState) {
                if(wizard.wizardName === action.payload.wizardName){
                    wizard.submitted = true;
                    return newState;
                }
            }
            return state;
        case FAILED_SUBMIT:
            newState = cloneDeep(state);
            for(let wizard of newState) {
                if(wizard.wizardName === action.payload.wizardName) {
                    wizard.failedSubmit = true;
                    wizard.submitted = false;
                    return newState;
                }
            }
            return state;
        case SET_FILENAME:
            newState = cloneDeep(state);
            for(let wizard of newState) {
                if (wizard.wizardName === action.payload.wizardName) {
                    wizard.fileName = action.payload.fileName;
                    return newState;
                }
            }
            return state;
        case LOAD_FILE_CONTENT:
            newState = cloneDeep(state);
            for(let wizard of newState) {
                if(wizard.wizardName === action.payload.wizardName) {
                    wizard.fileContent = action.payload.content;
                    return newState;
                }
            }
            return state;
        case SWITCH_WIZARD:
            newState = cloneDeep(state);
            for(let wizard of newState) {
                if(wizard.wizardName === action.payload.wizardName){

                }
            }
            return state;
        case SET_HIDDEN_STEPS:
            newState = cloneDeep(state);
            for(let wizard of newState) {
                if(wizard.wizardName === action.payload.wizardName){
                    wizard.hidden = action.payload.hidden;
                    for(let hiddenStep of wizard.hidden){
                        const hiddenIndex = wizard.completedSteps.findIndex(x => x === hiddenStep);
                        if(hiddenIndex !== -1) {
                            wizard.completedSteps.splice(hiddenIndex, 1);
                        }
                    }
                    return newState
                }
            }
            return state;
        case STORE_WIZARD:
            newState = cloneDeep(state);
            for(let wizardIndex in newState) {
                if (newState[wizardIndex].wizardName === action.payload.wizardName) {
                    const saveWizard = cloneDeep(newState[wizardIndex]);
                    saveWizard.backupWizards = [];
                    saveWizard.fileContent = "";
                    let newWizard = {...initialWizard, wizardName: newState[wizardIndex].wizardName, stepInfo: cloneDeep(newState[wizardIndex].stepInfo), wizardFormName: newState[wizardIndex].wizardFormName};
                    if(action.payload.hidden) {newWizard.hidden = action.payload.hidden;}
                    newWizard.backupWizards.push({wizardState: saveWizard, formState: cloneDeep(action.payload.formState)});
                    newState.splice(wizardIndex, 1, newWizard);
                    return newState;
                }
            }
            return state;
        case POP_WIZARD:
            newState = cloneDeep(state);
            for (let wizard of newState){
                if (wizard.wizardName === action.payload.wizardName) {
                    const storedWizards = cloneDeep(wizard.backupWizards);
                    wizard = storedWizards[action.payload.wizardIndex];
                    wizard.backupWizards = storedWizards
                    return newState;
                }
            }
            return state;
        default:
            return state;
    }
}
