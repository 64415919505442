import React, {Component} from "react";
import {connect} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

class XpGridFormInputEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldTemplate: props.colDef.cellRendererParams.fieldTemplate,
            fieldModel: props.colDef.cellRendererParams.getFieldModel(props.data),
            value: props.value,
            rowData: props.data,
            enforceEditFrame    : props.colDef.cellRendererParams.enforceEditFrame,
            xpFormComponentProps: props.colDef.cellRendererParams.xpFormComponentProps,
            xpFormComponent: props.colDef.cellRendererParams.xpFormComponent,
        }
        this.inputContainerRef = React.createRef();
    }

    afterGuiAttached() {
        let container = this.inputContainerRef.current;
        if (container) {
            const input = container.querySelector('input');
            if (input) {
                input.focus();
                input.select();
            }
        }
    }
    getValue() {
        return this.state.value;
    }
    isPopup() {
        return false;
    }

    refresh(params) {
        let changedState = {rowData: params.data};
        this.setState(changedState);
        return true;
    }

    render() {
        const errorMessages = {};
        for (let key in this.props.colDef.cellRendererParams.errors) {
            errorMessages[key] = <XpTranslated trKey={this.props.colDef.cellRendererParams.getErrorTrKey(key)}/>
        }

        return (
            <div ref={this.inputContainerRef} className={this.state.enforceEditFrame ? "xpGridEnforceEditBackground" : ""}>
                <this.state.xpFormComponent
                    noLabel autoFocus
                    field={this.state.fieldModel}
                    fieldTemplate={this.state.fieldTemplate}
                    {...this.state.xpFormComponentProps}
                    defaultValue={this.state.value}
                    onChangeCallback={value => this.setState({value: value})}
                    errors       ={this.props.colDef.cellRendererParams.errors}
                    errorMessages={errorMessages}
                    dependentData={this.props.data}
                />
            </div>
        );
    }
}
const mapStateToProps = (state, props) => {return {};};
const mapDispatchToProps = (dispatch) => {return {};};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(XpGridFormInputEditor);
