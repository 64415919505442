import {useCallback, useEffect, useMemo, useState} from "react";
import XpGrid from "../xpGrid/XpGrid3";
import {useDispatch, useSelector} from "react-redux";
import {selectTranslateFunction} from "../appLocale/xpTranslated/xpTranslatedSelectors";
import {prepareFixLogColumns} from "./fixLogFunctions";
import {
    fixLogContainsMessagesSelector,
    fixLogListDataSelector,
    getFilteredFixLogListDataSelector
} from "./fxLogSelectors";
import {getOrmItemsFromApi} from "../orm/ormApi";
import {useSelectorInstance} from "../functions/hooks";
import {getOrmModelLoading} from "../orm/ormLoadingSelectors";
import {XP_FORM_EDIT} from "../xpForm/core/xpFormConstants";
import XpFormTextInput from "../xpForm/core/XpFormTextInput";
import XpForm from "../xpForm/core/XpForm";
import {useXpFormField} from "../xpForm/core/xpFormHooks";
import {dashboardSetComponentsState} from "gui-common/dashboard/dashboardReducer";
import {xpFormChangeField, xpFormRemoveForm} from "gui-common/xpForm/core/xpFormReducer";
import moment from "moment/moment";

export default function FixLogList(props) {

    const fixLogForm = 'fixLogForm-' + props.instanceId;
    const fixMessagesExist = useSelector(fixLogContainsMessagesSelector);
    const fixMessagesLoading = useSelectorInstance(getOrmModelLoading, {ormModel: 'FixMessage'})

    const dispatch = useDispatch();
    const translate     = useSelector(selectTranslateFunction);
    const formRegexpString = useXpFormField(fixLogForm + '.regexpSearch')

    const [currentSearchStr, setCurrentSearchStr] = useState(props.componentData.state?.regexpString);

    useEffect(() => {
        if (fixMessagesExist) {
            return;
        }
        if (fixMessagesLoading) {
            return;
        }
        dispatch(getOrmItemsFromApi("FixMessage"));
    }, [fixMessagesExist, fixMessagesLoading])

    useEffect(() => {
        try {
            new RegExp(formRegexpString);
            setCurrentSearchStr(formRegexpString);
            dispatch(dashboardSetComponentsState(
                props.componentData.key,
                {regexpString: formRegexpString}
            ));
        } catch(e) {
        }
    }, [formRegexpString])

    useEffect(
        () => {
            if (props.componentData.state?.regexpString) {
                return
            }
            dispatch(xpFormChangeField(fixLogForm + '.regexpSearch', props.componentData.state?.regexpString))
            return () => {
                dispatch(xpFormRemoveForm(fixLogForm + '.regexpSearch'));
            }
        },
        [],
    );

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((data  ) => {}, []),
        gridContextMenuItems    : useCallback((params) => {}, []),
    }

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('ordersList.noOrdersToShow'), // todo add lang
                fillAvailableSpace      : true,
                groupDefaultExpanded    : 0,
                getRowDataSelector      : getFilteredFixLogListDataSelector,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'FixMessage',
                rowClassRules           : {
                    'xp-grid-rejected-order-row' : params =>  (['Reject', 'QuoteRequestReject'].includes(params.data.messageType)),
                    'xp-grid-onHold-order-row'   : params =>  (['QuoteCancel', 'OrderCancelRequest'].includes(params.data.messageType))
                }
            };
        },
        [translate]
    );



    const columnDefs = useMemo(() => {
        let hiddenState = props.hiddenState ? props.hiddenState : {
            id:                     true,
            logTime:                false,
            sequenceNumber:         false,
            guiDirection:           false,
            messageType:            false,
            guiMessageCat:          false,
            account:                false,
            message:                false
        }
        return prepareFixLogColumns(translate, hiddenState);
    }, [translate]);

    return (
        <div style={{height: "100%"}}>

            <XpForm
                formModel={fixLogForm}
                initialUseState={XP_FORM_EDIT}
                initialFormData={{
                    regexpSearch: props.componentData.state?.regexpString ? props.componentData.state?.regexpString : "",
                }}
            >
                <div style={{height: '20px', paddingLeft: '10px'}}>
                    <div style={{width: '300px', marginRight: '5px'}}>
                        <XpFormTextInput
                            field={'regexpSearch'}
                            noTemplate
                            label={'Search regex:'}
                            inLineDivider={65}
                            inLineLayout
                        />
                    </div>
                </div>
            </XpForm>
            <hr/>
            <div style={{height: "calc(100% - 20px)"}}>
                <XpGrid
                    gridId={"fixLog" + props.instanceId}
                    {...gridCallbacks}
                    {...gridOptions}
                    rowDataSelectorProps={{regexpSearchString: currentSearchStr}}
                    columnDefs={columnDefs}
                    callingContext={this}
                />
            </div>

        </div>

    )

}
