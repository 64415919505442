import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {prepareClientsGridColumns} from "xpool-gui-common/features/client/clientFunctions";
import {getClientListDataSelector} from "xpool-gui-common/features/client/clientSelectors";
import XpAdminFormEntityGridTemplate from "gui-common/xpAdminForm/XpAdminFormEntityGridTemplate";
import {userCanRunPool} from "gui-common/functions/userRights";
import {push} from "connected-react-router";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {setActiveClientThunk} from "gui-common/functions/thunks";
import {requestRunStateToApi} from "gui-common/runState/runStateApi";
import {useSelectorInstanceRef} from "gui-common/functions/hooks";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import ErpStatusCellRenderer from "features/client/ErpStatusCellRenderer";
import {shallowActiveClientsSelector} from "features/client/clientSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {getOrmItemFromApi} from "gui-common/orm/ormApi";
import moment from "moment";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

export default function ClientsList (props) {
    const ormClients      = useSelector(shallowActiveClientsSelector);

    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const erpConfig = useAppEnvProperty('erpIntegrationConfig');


    const requestStateRef = useSelectorInstanceRef(getRequestingStateOnModelSelector, {model: 'RunState'});

    const dispatch      = useDispatch();

    const columnDefs = useMemo(
        () => {
            let hiddenState = props.hiddenState ? props.hiddenState : {
                name:               false,
                id:                 true,
                description:        true,
                accountManager:     true,
                clientRefId:        true,
                operatingUnitT:     true,
                timeZone:           true,
                activeOrderCount:   false,
                erpStatus:          false,
                cashPoolsRunStateT:  false,
                runPoolProcessNextRunTime: false,
                createdDateTime:     false,
                createdByUser:      true,
                changedDateTime:     false,
                changedByUser:      true,
            };
            if (!erpConfig.erpIntegrationEnabled) {
                hiddenState.erpStatus = undefined;
            }
            return prepareClientsGridColumns(translate, hiddenState);
        },
        [translate, props.hiddenState]
    );

    const contextMenuItems =  useCallback(
        (params) => {
            if (!params || !params.node || !params.node.data) return [];
            let menuItems = [];

            if (userCanRunPool(params.node.data)) {

                const latestToCancel = params.node.data?.cashPoolsRunStateParams?.latestToCancel;
                const latestToResume = params.node.data?.cashPoolsRunStateParams?.latestToResume;

                const resumeDisabled = (params.node.data?.cashPoolsRunState !== 'CANCELLED') || (latestToResume && moment().isAfter(moment(latestToResume)));
                const cancelDisabled = ['WAIT_FOR_EXECUTION', 'WAIT_FOR_BALANCE', 'CANCELLED', 'FAILED', 'NOT_SCHEDULED', 'NO_RUN_DAY', 'AFTER_STOP_TRYING', 'COMPLETED'].includes(params.node.data?.cashPoolsRunState) || (latestToCancel && moment().isAfter(moment(latestToCancel)));
                menuItems.push({
                    name: translateRef.current('clientsList.gridContextMenuItems.resumeAutoRun'),
                    action: function() {
                        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'runPoolButton.resumeAutoRun.confirmModal'}))
                            .then(({status}) => {
                                if (status === MODAL_TYPE_CONFIRM) {
                                    dispatch(requestRunStateToApi(params.node.data.cashPoolsRunStateObject, 'INITIAL', ['FAILED', 'COMPLETED', 'INITIAL', 'WAIT_FOR_BALANCE', 'BEFORE_EXECUTION', 'WAIT_FOR_EXECUTION']));
                                }
                            });
                    },
                    disabled: resumeDisabled
                });

                menuItems.push({
                    name: translateRef.current('clientsList.gridContextMenuItems.cancelAutoRun'),
                    action: function() {
                        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'runPoolButton.cancelAutoRun.confirmModal'}))
                            .then(({status}) => {
                                if (status === MODAL_TYPE_CONFIRM) {
                                    dispatch(requestRunStateToApi(params.node.data.cashPoolsRunStateObject, 'CANCELLED', ['FAILED', 'COMPLETED']));
                                }
                            });
                    },
                    disabled: cancelDisabled
                });
            }
            menuItems.push({
                name: translateRef.current('clientsList.gridContextMenuItems.activateClient'),
                action: function () {
                    dispatch(setActiveClientThunk(params.node.data));
                    dispatch(push("/cashPool"));
                },
                disabled: props.suppressRowClickSelection,
            });
            return menuItems;
        }, [translate, requestStateRef.current, ormClients]);


    return (
        <XpAdminFormEntityGridTemplate
            columnDefs={columnDefs}
            // rowData={rowData}
            trKeyBase='clientsList'
            ormModel='Client'
            contextMenuItems={contextMenuItems}
            {...props}
            getRowDataSelector   = {getClientListDataSelector}
            rowDataSelectorProps = {props.rowDataSelectorProps}
            frameworkComponents= {{
                xpErpStatusRenderer: ErpStatusCellRenderer,
            }}
            itemSelectedCallback={props.disableLoadClientOnSelect ? undefined: client => {
                if (!client) return;
                if (client.clientBankAccounts?.length) return; // Full client already loaded.
                dispatch(getOrmItemFromApi('Client', client.id))
                    .catch(err => {
                        console.error("Get Client failed in setActiveClient: " + err);
                    });
            }}
        />
    )
}
