import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import MarketSimulatorConfigItem from "gui-common/simulators/testMarket/MarketSimulatorConfigItem";
import {
    fixAccountsSelector,
    marketSimulatorActiveConfigurationsSelector,
    simulatorAvailableCurrencyPairsSelector, simulatorAvailableTenorsSelector
} from "appConfig/simulators/simulatorsSelectors";
import "gui-common/simulators/simulator.css";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import {activeFxProvidersSelector} from "gui-common/orm/ormSelectors";
import {dashboardSetComponentsState} from "gui-common/dashboard/dashboardReducer";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormRemoveForm} from "gui-common/xpForm/core/xpFormReducer";
import {getFixAccountsFromApi} from "gui-common/simulators/testMarket/marketSimulatorApi";

function MarketSimulator (props) {

    const marketSimulatorForm = 'marketSimulatorForm-' + props.instanceId;
    const activeClientConfigurations = useSelector(marketSimulatorActiveConfigurationsSelector);
    const availableCurrencyPairs = useSelector(simulatorAvailableCurrencyPairsSelector);
    const availableTenors        = useSelector(simulatorAvailableTenorsSelector);
    const simConfig              = useSelector(state => selectAppEnvProperty(state, 'marketSimConfig'));
    const ormFxProviders         = useSelector(activeFxProvidersSelector);
    const fixAccounts            = useSelector(fixAccountsSelector);

    const currencyPairIdToAdd   = useXpFormField(marketSimulatorForm + '.currencyPairIdToAdd')
    const tenorToAdd            = useXpFormField(marketSimulatorForm + '.tenorToAdd')
    const sessionIdToAdd        = useXpFormField(marketSimulatorForm + '.sessionIdToAdd')
    const fixAccountToAdd       = useXpFormField(marketSimulatorForm + '.fixAccountToAdd')

    const [configFixAccounts, setConfigFixAccounts] = useState([]);


    const dispatch  = useDispatch();

    useEffect(
        () => {
            if (props.componentData.state?.priceItems) {
                return
            }
            dispatch(dashboardSetComponentsState(
                props.componentData.key,
                {priceItems: activeClientConfigurations}
            ));
            return () => {
                dispatch(xpFormRemoveForm(marketSimulatorForm));
            }
        },
        [],
    );

    useEffect(
        () => {
            dispatch(getFixAccountsFromApi()).then(resp => {
                const accounts = Object.keys(resp.response).map(item => ({id: item, name: item}));
                setConfigFixAccounts(accounts);
            })
        },
        [],
    );

    const tenorsAvailableToAdd = useMemo(
        () => {
            return availableTenors?.length ? availableTenors.map(item => ({id:item , name: item})) : [];
        },
        [availableTenors]
    );
    const currencyPairsAvailableToAdd = useMemo(
        () => {
            return availableCurrencyPairs?.length ? availableCurrencyPairs.map(item => ({id: item.id, name: item.key})) : [];
        },
        [availableCurrencyPairs]
    );
    const sessionsAvailableToAdd = useMemo(
        () => {
            return ormFxProviders?.length ? ormFxProviders.map(item => ({id: item.sessionID, name: item.sessionID})) : [];
        },
        [ormFxProviders]
    );
    const fixAccountsAvailableToAdd = useMemo(
        () => {
            let returnArray = fixAccounts?.length ? fixAccounts.map(item => ({id: item, name: item})) : [];
            if (configFixAccounts?.length) {
                returnArray = returnArray.concat(configFixAccounts);
            }
            return returnArray;
        },
        [fixAccounts, configFixAccounts]
    );

    function configIsSame(item, config) {
        for (let key in item) {
            if (item[key] !== config[key]) {
                return false;
            }
        }
        for (let key in config) {
            if (item[key] !== config[key]) {
                return false;
            }
        }
        return true;
    }
    function getConfigKey(item) {
        let returnKey = ""
        for (let key in item) {
            returnKey += item[key];
        }
        return returnKey;
    }

    if (!props.componentData?.state?.priceItems) {
        return null;
    }

    return (
        <div>
            <XpForm
                formModel={marketSimulatorForm}
                initialUseState={XP_FORM_EDIT}
            >
                <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                    <div style={{width: '300px', marginRight: '5px'}}>
                        <XpFormSelectorInput
                            noTemplate
                            label={'FIX session'}
                            field="sessionIdToAdd"
                            selectList={sessionsAvailableToAdd}
                            defaultValue={sessionsAvailableToAdd[0].id}
                        />
                    </div>
                    <div style={{width: '110px', marginRight: '5px'}}>
                        <XpFormSelectorInput
                            noTemplate
                            label={'Currency pair'}
                            field="currencyPairIdToAdd"
                            selectList={currencyPairsAvailableToAdd}
                            defaultValue={currencyPairsAvailableToAdd[0].id}
                        />
                    </div>
                    <div style={{width: '70px', marginRight: '5px'}}>
                        <XpFormSelectorInput
                            noTemplate
                            label={'Tenor'}
                            field="tenorToAdd"
                            selectList={tenorsAvailableToAdd}
                            defaultValue={tenorsAvailableToAdd[0].id}
                        />
                    </div>
                    <div style={{width: '200px', marginRight: '5px'}}>
                        <XpFormSelectorInput
                            template={'All fix accounts'}
                            label={'FIX account'}
                            field="fixAccountToAdd"
                            selectList={fixAccountsAvailableToAdd}
                        />
                    </div>
                    <div style={{marginTop: '17px'}}>
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            onClickCallback = {() => {
                                dispatch(dashboardSetComponentsState(
                                    props.componentData.key,
                                    {priceItems: [...props.componentData.state.priceItems, {currencyPairId: currencyPairIdToAdd, tenor: tenorToAdd, sessionId: sessionIdToAdd, fixAccount: fixAccountToAdd}]}
                                ));
                            }}
                            label    = 'Add configuration'
                            disabled = {undefined !== props.componentData.state.priceItems.find(item => configIsSame(item, {currencyPairId: currencyPairIdToAdd, tenor: tenorToAdd, sessionId: sessionIdToAdd, fixAccount: fixAccountToAdd}))}
                        />
                    </div>

                </div>
            </XpForm>
            <hr/>
            {props.componentData?.state?.priceItems.map(config => { return (
                <MarketSimulatorConfigItem
                    key            = {getConfigKey(config)}
                    sessionId      = {config.sessionId}
                    currencyPairId = {config.currencyPairId}
                    fixAccount     = {config.fixAccount}
                    tenor          = {config.tenor}
                    addConfigFn    = {fixAccount => {
                        if (props.componentData.state.priceItems.find(item => configIsSame(item, {currencyPairId: config.currencyPairId, tenor: config.tenor, sessionId: config.sessionId, fixAccount: fixAccount}))) {
                            return;
                        }
                        dispatch(dashboardSetComponentsState(
                            props.componentData.key,
                            {priceItems: [...props.componentData.state.priceItems, {currencyPairId: config.currencyPairId, tenor: config.tenor, sessionId: config.sessionId, fixAccount: fixAccount}]}
                        ));
                    }}
                    removeMe       = {() => {
                        dispatch(dashboardSetComponentsState(
                            props.componentData.key,
                            {priceItems: props.componentData.state.priceItems.filter(item => !configIsSame(item, config))}
                        ));
                    }}
                />)})
            }
        </div>
    )
}
MarketSimulator.propTypes = {
};
export default MarketSimulator
