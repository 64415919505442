import React, {useEffect} from 'react'
import 'gui-common/wizard/wizard.css';
import PropTypes from 'prop-types';
import WizardPhaser from 'gui-common/wizard/WizardPhaser';
import WizardContent from 'gui-common/wizard/WizardContent';
import WizardSummary from 'gui-common/wizard/WizardSummary';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {setInitialSteps} from "gui-common/wizard/wizardReducer";
import {wizardConfig} from "appConfig/wizardConfig";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {
    getWizardDefaultParametersSelector,
    getWizardLookupValuesSelector,
    getWizardValidationParamsSelector
} from "gui-common/wizard/wizardSelectors";
import {useDispatch} from "react-redux";

function Wizard(props){

    const defaultParameters = useSelectorInstance(getWizardDefaultParametersSelector, {wizardName: props.wizardName})
    const lookupValues      = useSelectorInstance(getWizardLookupValuesSelector     , {wizardName: props.wizardName})
    const validationParams  = useSelectorInstance(getWizardValidationParamsSelector , {wizardName: props.wizardName})
    const wizardFormName    = wizardConfig[props.wizardName].formName;
    const wizardSubFormName = wizardConfig[props.wizardName].subFormName;
    const stepInfo          = wizardConfig[props.wizardName].stepInfo;
    const dispatch          = useDispatch();


    useEffect(
        () => {
            // Was in componentWillMount. This may work
            dispatch(setInitialSteps({
                wizardName: props.wizardName,
                stepInfo: stepInfo,
                createObject: true,
                ormModel: wizardConfig[props.wizardName].ormModel,
                wizardFormName: wizardFormName
            }));
        },
        [],
    );

    return(
        <div className="wizard">
            <div className="wizardReset">
                &nbsp;
            </div>
            <div className={"wizardTop"}>
                <h2><XpTranslated trKey={props.wizardName + '.wizardName'}/></h2>
            </div>
            <div className="wizardDivider">
                <hr/>
            </div>
            <div className="wizardStep">
                <WizardPhaser
                    wizardName={props.wizardName}
                    wizardFormName={wizardFormName}
                />
            </div>
            <div className="wizardContent">
                <WizardContent
                    wizardName={props.wizardName}
                    stepInfo={stepInfo}
                    wizardFormName={wizardFormName}
                    wizardSubFormName={wizardSubFormName}
                    defaultParameters={defaultParameters}
                    lookupValues={lookupValues}
                    validationParams={validationParams}
                />
            </div>
            <div className="wizardSummary">
                <WizardSummary
                    wizardName={props.wizardName}
                    wizardFormName={wizardFormName}
                    lookupValues={lookupValues}
                    wizardSubFormName={wizardSubFormName}
                />
            </div>
        </div>
    );
}

Wizard.propTypes = {
    wizardName : PropTypes.string.isRequired,
}
export default Wizard
