// Main CSS import sequence for gui-common. The order is important. Later imports overrides earlier.
// The CSS loading in the gui-common appStartup will be the first loaded. All later imports in the app
// or in other gui-startup libraries will override the css loads below.
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css'
import 'gui-common/css/globalCssVariables.css'
import 'gui-common/css/generalElements.css'
import 'gui-common/css/generalForms.css'
import 'gui-common/css/generalTables.css'
import 'gui-common/css/filterForms.css'
import 'gui-common/css/xpMaterialUiClasses.css'
import 'gui-common/appLocale/xpTranslated/appLocale.css'
import 'gui-common/xpForm/core/xpForm.css'
import "gui-common/css/xpChart.css"

import {Provider} from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import Favicon from "react-favicon";
import {ConnectedRouter, push} from "connected-react-router";
import moment from "moment";

import {setStyling} from "gui-common/app/commonAppFunctions";
import {setActiveLanguageThunk} from "gui-common/appLocale/appLocaleFunctions";
import {loadDataProcess, loadProcessFailedThunk} from "gui-common/api/loginProcess";
import {checkAndUpdateUserStateOnTimer} from "gui-common/userSettings/userState/userStateThunks"
import configureStore from "gui-common/app/store";
import rootReducer from "gui-common/app/rootReducer";
import IeNotSupported from "gui-common/components/IeNotSupported";
import RedirectToLoginPage from "gui-common/components/RedirectToLoginPage";
import nfcIcon from "gui-common/resources/nfc_icon.ico";
import {getAppSpecificLanguage} from "appConfig/appConfig";
import {getFirstAwtBatchFromApi} from "gui-common/api/webSessionApiFunctions";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {xpTranslatedInit, xpTranslatedLoadTranslations} from "gui-common/appLocale/xpTranslated/xpTranslatedReducer";


export function commonStartupThunk(dataLoadFunction, props) {
    return (dispatch, getState) => {

// MT: The whole state coockie manager is not working with rotating keys and the current reload infrastructure.
/*
        if (window.performance) {
            if (performance.navigation.type === 1) {
                setReloadDetected(true);
            } else {
                setReloadDetected(false);
            }
        }
*/
        window.onpopstate = function (event) {
            console.log("location: " + document.location + ", state: " + JSON.stringify(event.state));
        };

        moment.locale('en', {
            calendar : {
                lastDay : '[Yesterday at] HH:mm:ss',
                sameDay : 'HH:mm:ss',
                nextDay : '[Tomorrow at] HH:mm:ss',
                lastWeek : 'YYYY-MM-DD, HH:mm:ss',
                nextWeek : 'YYYY-MM-DD, HH:mm:ss',
                sameElse : 'YYYY-MM-DD, HH:mm:ss'
            },
            week: {
                dow: 1, // First day of week is Monday
            }
        });
        moment.locale('sv', {
            calendar : {
                lastDay : '[Igår kl] HH:mm:ss',
                sameDay : 'HH:mm:ss',
                nextDay : '[Imorgon kl] HH:mm:ss',
                lastWeek : 'YYYY-MM-DD, HH:mm:ss',
                nextWeek : 'YYYY-MM-DD, HH:mm:ss',
                sameElse : 'YYYY-MM-DD, HH:mm:ss'
            },
            week: {
                dow: 1, // First day of week is Monday
            }
        });

        const languages =     getState().appEnvState.availableLanguages;
        // dispatch(initialize(languages,  { defaultLanguage: 'en' }));
        dispatch(xpTranslatedInit(languages, { defaultLanguage: 'en' }))

        if (!props?.skipLoadCommonLang) {
            const guiCommonLanguage = {
                // Common features lang files in gui-common.
                ...require('gui-common/app/appLang.json'),
                ...require('gui-common/appLocale/xpTranslated/xpTranslatedLang.json'),
                ...require('gui-common/api/userLoginLang.json'),
                ...require('gui-common/fxPrice/fxPriceLang.json'),
                ...require('gui-common/fixLog/fixLogLang.json'),
                ...require('gui-common/userMessages/userMessagesLang.json'),
                ...require('gui-common/integrationStatus/integrationStatusLang.json'),
                ...require('gui-common/userSettings/userSettingsLang.json'),
                ...require('gui-common/dashboard/dashboardLang.json'),
                ...require('gui-common/audit/auditLang.json'),
                ...require('gui-common/userSettings/topics/userTopicAdminLang.json'),
                ...require('gui-common/adminUserRights/adminUserRightsLang'),
                ...require('gui-common/noRunDate/noRunDateLang'),
                ...require('gui-common/userSettings/sharedSettings/savedUserStatesLang.json'),
                ...require('gui-common/report/reportLang.json'),
                ...require('gui-common/wizard/wizardLang'),
                ...require('gui-common/fxRejectConfig/fxRejectConfigLang'),
                ...require('gui-common/simulators/testMarket/marketSimulatorLang.json'),
                ...require('gui-common/currencyPairConfig/currencyPairConfigLang'),
                ...require('gui-common/bankHolidays/bankHolidaysLang'),
                ...require('gui-common/components/fileReaderLang'),
                ...require('gui-common/runConfiguration/runConfigurationLang.json'),
                ...require('gui-common/erpIntegration/erpIntegrationLang.json'),
            };
            dispatch(xpTranslatedLoadTranslations(guiCommonLanguage));
            // dispatch(addTranslation(guiCommonLanguage));
        }

        const appSpecificLanguage = getAppSpecificLanguage();
        dispatch(xpTranslatedLoadTranslations(appSpecificLanguage));
        // dispatch(addTranslation(appSpecificLanguage));


/*
        const missingTranslations = getMissingLanguage(getState().locale.translations, languages);
        dispatch(addTranslation(missingTranslations));
*/
        dispatch(setActiveLanguageThunk('en'));

        const styleArray = selectAppEnvProperty(getState(), 'guiStyling');
        if (styleArray && styleArray.length > 0) {
            let defaultStyling = styleArray.find(item => item.default === true);
            if (!defaultStyling) defaultStyling = styleArray[0];
            dispatch(setStyling(defaultStyling));
        }

        if ((process.env.NODE_ENV !== "production") && (module.hot) && getState().loginProcessState.userLoggedIn) {
            return;
        } // No need to reload data when hot reloading.

        dispatch(getFirstAwtBatchFromApi())
            .then(result => {
                dispatch(push("/loadingData"));
                dispatch(loadDataProcess(getState().appConfigState.appStartPath, dataLoadFunction));
                setTimeout(() => {dispatch(checkAndUpdateUserStateOnTimer())}, 1000);
            })
            .catch(err => {
                console.error("Could not get first batch of AWT tokens at sign-in: " + err);
                dispatch(loadProcessFailedThunk());
            });
    }
}

export function getUrlLoginParams() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    return {
        key         : (url && url.searchParams) ? url.searchParams.get("key")         : null,
        sessionId   : (url && url.searchParams) ? url.searchParams.get("sessionId")   : null,
        referenceId : (url && url.searchParams) ? url.searchParams.get("referenceId") : null,
    }
}

export function renderAppFromIndexJs(props) {
    let rootElement = document.getElementById('root');

    const appStore = configureStore(undefined, rootReducer);

    const renderPage = (content) => {
        ReactDOM.render(
            <div>
                <Favicon url={nfcIcon}/>
                {content}
            </div>,
            rootElement
        )
    };

    if (!window.oxygen_env) {
        renderPage(
            <div>
                <h2> Installation error!</h2>
                <h2> No client-env-config.js file found in root directory. </h2>
            </div>
        )
        return;
    }
    if (!window.oxyGenClientConfiguration) {
        renderPage(
            <div>
                <h2> Installation error!</h2>
                <h2> No client-config.js file found in root directory. Cannot start without client configuration. </h2>
            </div>
        )
        return;
    }
    if (!window.oxyGenConfigurationOverride) console.warn("WARNING: No client-config-override.js loaded.");


    console.log("Navigator = ", navigator.appVersion);
    console.log("Client configuration: ", window.oxygen_env);
    console.log("Build number:", window.oxygen_env.BUILD_NUMBER);
    console.log("Branch name :", window.oxygen_env.BRANCH_NAME);
    console.log("Commit:", window.oxygen_env.COMMIT_HASH);


    const appVersion = navigator.appVersion;
    const isIE = appVersion.indexOf("Trident") !== -1;

    if (isIE) { // First verify that browser is not IE
        renderPage(<IeNotSupported/>);
        return;
    }
    // OK, we are not running IE.

    // Check login credentials and redirect if not available.
    const rerouteUrl         = window.oxygen_env.AUTHENTICATION_URL;
    const noBackendMode      = window.oxygen_env.NO_BACKEND_MODE;
    const loginParamsFromUrl = getUrlLoginParams();

    if (!noBackendMode && (!loginParamsFromUrl.key || !loginParamsFromUrl.referenceId || !loginParamsFromUrl.sessionId)) { // Check if application is started without a valid session, if so, redirect and show redirect page
        window.location.assign(rerouteUrl); // Use replace if "back" should not be possible... and change to login URL
        renderPage(<RedirectToLoginPage />);
        return;
    }

    // expose store when run in Cypress
    if (window.Cypress) window.xpStore = appStore;

    let render = () => {
        // Dynamically import our main App component, and render it
        const App = props?.appComponent ? props.appComponent : require("gui-common/app/App").default;
        const hist = require("gui-common/app/store.js").history;

        renderPage(
            <Provider store={appStore}>
                <ConnectedRouter history={hist}>
                    <div style={{minHeight: "90%"}}>
                        <Favicon url={nfcIcon}/>
                        <App/>
                    </div>
                </ConnectedRouter>
            </Provider>
        );
    }

    render();
}
