import cloneDeep from "lodash/cloneDeep";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";

export const XP_FORM_ADD_FORM         = 'XP_FORM_ADD_FORM';
export const XP_FORM_LOAD             = 'XP_FORM_LOAD';
export const XP_FORM_REMOVE           = 'XP_FORM_REMOVE';
export const XP_FORM_SUBMIT           = 'XP_FORM_SUBMIT';
export const XP_FORM_API_SUBMIT       = 'XP_FORM_API_SUBMIT';
export const XP_FORM_API_CLEAR        = 'XP_FORM_API_CLEAR';
export const XP_FORM_CLEAR_SUBMIT     = 'XP_FORM_CLEAR_SUBMIT';
export const XP_FORM_ADD_FIELD        = 'XP_FORM_ADD_FIELD';
export const XP_FORM_CHANGE_FIELD     = 'XP_FORM_CHANGE_FIELD';
export const XP_FORM_FIELD_TOUCHED    = 'XP_FORM_FIELD_TOUCHED';
export const XP_FORM_REMOVE_FIELD     = 'XP_FORM_REMOVE_FIELD';
export const XP_FORM_SET_FIELD_ERRORS = 'XP_FORM_SET_FIELD_ERRORS';
export const XP_FORM_ADD_ELEMENT      = 'XP_FORM_ADD_ELEMENT';
export const XP_FORM_REMOVE_ELEMENT   = 'XP_FORM_REMOVE_ELEMENT';

// Action creators ********************************************************
// ************************************************************************
export function xpFormAddForm(model, formUseState) {
    return {type: XP_FORM_ADD_FORM  , payload: {model: model, formUseState: formUseState}};
}
export function xpFormLoadForm(model, data, formUseState) {
    return {type: XP_FORM_LOAD  , payload: {model: model, data: data, formUseState: formUseState}};
}
export function xpFormRemoveForm(model) {
    return {type: XP_FORM_REMOVE  , payload: {model: model}};
}
export function xpFormSubmitForm(model) {
    return {type: XP_FORM_SUBMIT  , payload: {model: model}};
}
export function xpFormApiSubmit(model) {
    return {type: XP_FORM_API_SUBMIT  , payload: {model: model}};
}
export function xpFormApiClear(model) {
    return {type: XP_FORM_API_CLEAR  , payload: {model: model}};
}
export function xpFormClearSubmit(model) {
    return {type: XP_FORM_CLEAR_SUBMIT, payload: {model: model}};
}
export function xpFormAddField(model, value) {
    return {type: XP_FORM_ADD_FIELD  , payload: {model: model, value: value}};
}
export function xpFormChangeField(model, value, errors) {
    return {type: XP_FORM_CHANGE_FIELD, payload: {model: model, value: value, errors: errors}};
}
export function xpFormSetFieldErrors(model, errors) {
    return {type: XP_FORM_SET_FIELD_ERRORS  , payload: {model: model, errors: errors}};
}
export function xpFormSetFieldTouched(model) {
    return {type: XP_FORM_FIELD_TOUCHED  , payload: {model: model}};
}
export function xpFormRemoveField(model) {
    return {type: XP_FORM_REMOVE_FIELD  , payload: {model: model}};
}
export function xpFormAddElement(model, element) {
    return {type: XP_FORM_ADD_ELEMENT  , payload: {model: model, element: element}};
}
export function xpFormRemoveElement(model, index) {
    return {type: XP_FORM_REMOVE_ELEMENT  , payload: {model: model, index: index}};
}

// Reducer functions ********************************************************
// ************************************************************************

function getStatePointerAndField(state, model, addModelToState) {
    let ret = {field: undefined, statePointer: state, formDepth: 0, formStatePointer: undefined};

    const modelArray = model.split('.');
    if (modelArray.length < 1) return ret; // Must be at least a form name.

    ret.field = modelArray[0];
    for (let i = 1; i < (modelArray.length); i++) {
        if (!ret.statePointer[ret.field]) {
            if (addModelToState) ret.statePointer[ret.field] = {};
            else {
                // console.error("Cannot find field in form state. ", state, model);
                ret.statePointer = undefined;
                return ret;
            }
        }
        ret.formDepth++;
        ret.statePointer = ret.statePointer[ret.field];
        if (ret.statePointer['$_xpFormData']) ret.formStatePointer = ret.statePointer['$_xpFormData']
        ret.field = modelArray[i];
    }
    return ret;
}
function getFormInitialState(payload) {
    return {
        formUseState : payload.formUseState ? payload.formUseState : XP_FORM_EDIT,
        formModel: payload.model,
        revisionNumber: 0,
    }
}
function addForm(state, payload) {
    if (!payload || !payload.model) return state;
    if (payload.model.length < 1) return state; // Must be at least a form name, eg "myForm".

    const {statePointer, field} = getStatePointerAndField(state, payload.model, true);
    if (!statePointer) return state;

    if (!statePointer[field]) statePointer[field] = {};
    if (statePointer[field]['$_xpFormData']) {
        console.warn("Adding form model, but $form already exists: ", state, payload);
        return state;
    }
    statePointer[field]['$_xpFormData'] = getFormInitialState(payload);
    return {...state};
}
function loadForm(state, payload) {
    if (!payload || !payload.model) return state;
    const {statePointer, field} = getStatePointerAndField(state, payload.model, true);
    if (!statePointer) return state;

    statePointer[field] = payload.data ? cloneDeep(payload.data) : {};
    statePointer[field]['$_xpFormData'] = getFormInitialState(payload);

    console.log("Loaded XpForm: ", payload.model, payload.data, payload.formUseState);
    return {...state};
}
function removeForm(state, payload) {
    if (!payload || !payload.model) return state;
    const {statePointer, field} = getStatePointerAndField(state, payload.model, false);
    if (!statePointer) return state;
    if (!statePointer[field]) return state;
/*
    if (!statePointer[field]) {
        console.error("Cannot find form in form state (removeForm). ", state, payload);
        return state;
    }
*/
    delete statePointer[field];

    console.log("Removed XpForm: ", payload.model);
    return {...state};
}
function setFormMetadata(state, payload, propName, value) {
    if (!payload || !payload.model) return state;

    const {statePointer, field} = getStatePointerAndField(state, payload.model, false);
    if (!statePointer) return state;
    if (!statePointer[field]) {
        console.log("Could not find form to set metadata", payload, state);
        return state;
    }
    if (!statePointer[field]['$_xpFormData']) {
        console.error("No metadata for form, cannot set metadata", payload, state);
        return state;
    }
    statePointer[field]['$_xpFormData'] = {...statePointer[field]['$_xpFormData'], [propName]: value};

    return {...state};
}
function submitForm(state, payload) {
    return setFormMetadata(state, payload, 'submitRequested', true);
}
function clearSubmitForm(state, payload) {
    return setFormMetadata(state, payload, 'submitRequested', false);
}
function apiSubmit(state, payload) {
    return setFormMetadata(state, payload, 'submittedToApi', true);
}
function apiClear(state, payload) {
    return setFormMetadata(state, payload, 'submittedToApi', false);
}

function addField(state, payload, isRemove) {
    if (!payload || !payload.model) return state;

    const {statePointer, field, formStatePointer} = getStatePointerAndField(state, payload.model, !isRemove);
    if (statePointer === undefined) return state;

    if (isRemove) {
        if (statePointer['$' + field]) delete statePointer['$' + field];
        return {...state};
    }
    if ((statePointer[field] === undefined) || (statePointer[field] === null))  {
        statePointer[field] = payload.value;
    }
    if (!statePointer['$' + field]) statePointer['$' + field] = {fieldValue: statePointer[field], fieldModel: payload.model};

    if (formStatePointer) formStatePointer.revisionNumber++;
    return {...state};
}
function removeField(state, payload) {
    return addField(state, payload, true);
}

function changeField(state, payload, operation) {
    if (!payload || !payload.model) return state;

    const {statePointer, field, formStatePointer} = getStatePointerAndField(state, payload.model, false);
    if (statePointer === undefined) return state;
    if (!statePointer) {
        console.error("Cannot find field in form state in changeField. ", state, payload);
        return state;
    }

    if      (operation === 'setToughed') {
        if (!statePointer['$' + field]) {
            // Field is nor created yet. Cannot set touched.
            return state;
        }
        statePointer['$' + field] = {...statePointer['$' + field], isTouched: true};
    }
    else if (operation === 'setErrors') {
        if (!statePointer['$' + field] && !payload.errors) return state; // Do not create a fieldState with undefined errors when there is no fieldState.
        statePointer['$' + field] = {...statePointer['$' + field], errors: payload.errors};
    }
    else if (operation === 'addElement') {
        statePointer[field] = statePointer[field] ? [...statePointer[field], cloneDeep(payload.element)] : [cloneDeep(payload.element)];
    }
    else if (operation === 'removeElement') {
        if (statePointer[field] && statePointer[field].length) {
            statePointer[field].splice(payload.index, 1);
            const newArray = statePointer[field];
            statePointer[field] = [...newArray];
        }
    }
    else {
        statePointer[field] = payload.value;
        if (statePointer['$' + field]) statePointer['$' + field] = {...statePointer['$' + field], fieldValue: payload.value};
    }
    if (formStatePointer) {
        formStatePointer.revisionNumber++;
        formStatePointer.isTouched = true;
    }

    return {...state};
}
function setFieldTouched(state, payload) {return changeField(state, payload, 'setToughed'   )}
function setFieldErrors(state, payload)  {return changeField(state, payload, 'setErrors'    )}
function addElement(state, payload)      {return changeField(state, payload, 'addElement'   )}
function removeElement(state, payload)   {return changeField(state, payload, 'removeElement')}

// Reducer action handlers map ************************************************
// ************************************************************************
const actionHandler = {
    [XP_FORM_ADD_FORM]         : addForm,
    [XP_FORM_LOAD]             : loadForm,
    [XP_FORM_REMOVE]           : removeForm,
    [XP_FORM_SUBMIT]           : submitForm,
    [XP_FORM_API_SUBMIT]       : apiSubmit,
    [XP_FORM_API_CLEAR]        : apiClear,
    [XP_FORM_CLEAR_SUBMIT]     : clearSubmitForm,
    [XP_FORM_ADD_FIELD]        : addField,
    [XP_FORM_CHANGE_FIELD]     : changeField,
    [XP_FORM_FIELD_TOUCHED]    : setFieldTouched,
    [XP_FORM_REMOVE_FIELD]     : removeField,
    [XP_FORM_SET_FIELD_ERRORS] : setFieldErrors,
    [XP_FORM_ADD_ELEMENT]      : addElement,
    [XP_FORM_REMOVE_ELEMENT]   : removeElement,
};


const getInitialState = () => ({});

export function xpFormReducer(state = getInitialState(), action) {
    const handler = actionHandler[action.type];
    return handler ? handler(state, action.payload) : state;
}
