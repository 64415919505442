import React, { Component } from 'react'
import IntegrationStatusLight from "gui-common/integrationStatus/IntegrationStatusLight"
import connect from "react-redux/es/connect/connect";
import {integrationStatusToggleEvents} from "gui-common/integrationStatus/integrationStatusReducer"
import SystemEventList from "gui-common/integrationStatus/SystemEventList"
import sizeMe from 'react-sizeme'
import PropTypes from "prop-types";
import {makeIntegrationStatusItemStateSelector} from "gui-common/integrationStatus/integrationStatusSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

class IntegrationStatusItem extends Component {

    render () {
//        console.log("Rendering integration status item", this.props.instanceId);

        if (!this.props.myState) return null;
        const provider = this.props.provider;
        if (!provider ) return null;
        const lastEvent = (provider.events.length > 0) ? provider.events[0] : null;

        const sizeBase       = this.props.size.width * 0.037;
        const headFontSize   = Math.max(13, sizeBase * 1.2) + 'px';
        const buttonFontSize = Math.max(11, sizeBase * 0.7) + 'px';
        const buttonHeight   = Math.max(20, sizeBase * 1.4) + 'px';
        const buttonWidth    = Math.max(90, sizeBase * 6  ) + 'px';

        const providerStatus = (lastEvent) ? lastEvent.systemEventStatusType : "";

        return (
            <div className="integrationStatusItem">
                <div className='itemHead'>
                    <IntegrationStatusLight active = {(providerStatus === "RUNNING")                                                                   } lastEvent={lastEvent}/>
                    <IntegrationStatusLight active = {(providerStatus === "WARNING") || (providerStatus === "ERROR") || (providerStatus === "STARTING")} lastEvent={lastEvent}/>
                    <IntegrationStatusLight active = {(providerStatus === "DOWN"   )                                                                   } lastEvent={lastEvent}/>

                    <div className="itemText" style={{fontSize: headFontSize}}>
                        {provider.name}
                    </div>
                    <button className="showEventButton xPoolButton" style={{fontSize: buttonFontSize, height: buttonHeight, width: buttonWidth, padding: 0}} onClick={() => this.props.integrationStatusToggleEvents(this.props.instanceId)}>
                        <XpTranslated trKey={'integrationStatus.showEventsButton' + (this.props.myState.showEventList ? '.hide' : '.show')}/>
                    </button>
                </div>
                {this.props.myState.showEventList &&
                <SystemEventList
                    events = {provider.events}
                    instanceId={this.props.instanceId}
                />}
            </div>
        )
    }
}

const makeMapStateToProps = () => {
    const integrationStatusItemStateSelector = makeIntegrationStatusItemStateSelector();
    const mapStateToProps = (state, props) => {
        return {
            myState: integrationStatusItemStateSelector(state, props),
            translate: selectTranslateFunction(state),
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        integrationStatusToggleEvents: (id) => dispatch(integrationStatusToggleEvents(id)),
    };
};

const ConnectedIntegrationStatusItem = connect(makeMapStateToProps, mapDispatchToProps)(sizeMe()(IntegrationStatusItem));

ConnectedIntegrationStatusItem.propTypes = {
    provider:   PropTypes.object.isRequired,
    instanceId: PropTypes.string.isRequired,
};

ConnectedIntegrationStatusItem.defaultProps = {
};

export default ConnectedIntegrationStatusItem;
