import {isString} from "gui-common/functions/functions";

export function modalLogMessage(logHeader, props) {
    let logString = "Not able to convert to log string";

    if (props.messageText && isString(props.messageText)) {
        logString = props.messageText;
    }
    else if (props.modalKey && props.translate) {
        logString = props.translate(props.modalKey+'.message', props.modalParams);
    }
    console.log(logHeader + '\n' + logString);
}
