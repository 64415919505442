import {isMomentObject} from "gui-common/xpGrid/xpGridFunctions";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";

function getSelectedDate(formDate) {
    if (!formDate) return undefined;
    const momentDate = isMomentObject(formDate) ? formDate : moment(formDate);
    return momentDate.isValid() ? momentDate.toDate() : undefined;
}

function XpDatePicker(props) {
    return (
        <DatePicker
            {...props}
            {...{
                dateFormat  : props.dateFormat ? props.dateFormat : 'yyyy-MM-dd',
                maxDate     : new Date(props.maxDate),
                minDate     : new Date(props.minDate),
                showMonthYearDropdown   : props.showMonthYearDropdown && props.maxDate && props.minDate,
                showYearDropdown        : props.showYearDropdown && props.maxDate && props.minDate,
                popperPlacement         : props.popperPlacement,
                popperModifiers         : props.popperModifiers,
                value: undefined,
            }}
        />
    )
}

function XpFormDateInput (props) {
    return (
        <XpFormLabeledInput
            {...props}

            CompToRender={XpDatePicker}
            getValueFromEvent={event => event}
            isDatePickerField

            controlProps={{
                maxDate               : props.maxDate,
                minDate               : props.minDate,
                dateFormat            : props.dateFormat,
                showMonthYearDropdown : props.showMonthYearDropdown,
                showYearDropdown      : props.showYearDropdown,
                popperPlacement       : props.popperPlacement,
                popperModifiers       : props.popperModifiers,
            }}
            valueFormatter = {value => value ? moment(value).format('YYYY-MM-DD') : ""}
            dynamicControlProps={inProps => ({
                selected    : getSelectedDate(inProps.renderValue),
            })}
            errors = {useMemo(()=> ({
                ...props.errors,
                isRequired: props.isRequired ? val => !val || !moment(val).isValid() : undefined,
            }), [props.errors])}
        />
    )
}
XpFormDateInput.propTypes    = {
    ...xpFormBasePropTypes,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
    showMonthYearDropdown: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    popperPlacement: PropTypes.string,
    popperModifiers: PropTypes.object,
    placeholderText: PropTypes.string,
};
export default XpFormDateInput;
