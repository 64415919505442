import {createSelector} from "reselect";

const numberFormatState    = state => state.numberFormatState;

export const selectNumberDecimalState = createSelector(
    [numberFormatState],
    (numberFormatState) => {
        if (!numberFormatState) return 1;
        return numberFormatState.amountDecimals
    }
);
export const selectNumberDenominatorState = createSelector(
    [numberFormatState],
    (numberFormatState) => {
        if (!numberFormatState) return 1;
        return numberFormatState.amountDenominator
    }
);
export const selectNumberFormatter = createSelector(
    [numberFormatState],
    (numberFormatState) => {
        if (!numberFormatState) return undefined;
        return numberFormatState.formatter;
    }
);

const xpTrState = state => state.xpTranslatedState;
export const selectDecDenLangState = createSelector(
    [numberFormatState, xpTrState],
    (numberFormatState, xpTrState) => {
        return {
            amountDecimals    : numberFormatState ? numberFormatState.amountDecimals    : 1,
            amountDenominator : numberFormatState ? numberFormatState.amountDenominator : 1,
            activeLanguage    : xpTrState         ? xpTrState.activeLanguage         : 'en',
        }
    }
);

