import React from 'react'
import PropTypes from 'prop-types';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {DisplayOperatingUser} from "gui-common/components/DisplayOperatingUser";
import {XpDateTime} from "gui-common/components/XpDateTime";

export function ShowDateInForms ({trKey, date, user}) {
    if (!date) return null;
    return (
        <label>
            <XpTranslated trKey={trKey}/>
            <XpDateTime isoString={date} format='lll'/>
            {user && <DisplayOperatingUser userIdString={user}/>}
        </label>
    );
}

ShowDateInForms.propTypes = {
    trKey  : PropTypes.string.isRequired,
    date   : PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    user   : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
