/* -----------------------------------------------------------------------------------------------------------------
* Functions to get shallow clients from BE including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
import {apiBaseUrlMap} from "appConfig/api/apiConfig";
import {ormEntitiesGetFailed, ormEntitiesGetStarted, ormEntitiesReceived} from "gui-common/orm/ormReducer";
import {genericFetch} from "gui-common/api/apiCommon";
import {ormItemArrayTransformer} from "gui-common/orm/ormApi";

export function getShallowClientsFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get shallow clients ";
            let requestUrl = apiBaseUrlMap.shallowClients + "?";

            dispatch(ormEntitiesGetStarted('Client'));
            dispatch(genericFetch(
                requestUrl,
                logText,
                getResponseHandler,
                getFailHandler,
                getFailHandler,
                'GET',
                undefined, // no body in http request needed for GET.
                'Client'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getResponseHandler(resp) {
    return (dispatch, getState) => {
        let transformedResponse = ormItemArrayTransformer('Client', resp, {getState: getState});
        dispatch(ormEntitiesReceived('Client', transformedResponse));
    }
}
function getFailHandler(resp) {
    return (dispatch, getState) => {
        dispatch(ormEntitiesGetFailed('Client'));
    }
}
