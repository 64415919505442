export function replaceParametersInText(text, params) {
    if (!params) return text;
    const parameterStartIndex = text.indexOf('${');
    if (parameterStartIndex === -1) return text;
    const parameterEndIndex = text.indexOf('}');
    const parameterName = text.substring(parameterStartIndex + 2, parameterEndIndex);

    if (params[parameterName] === undefined) return text.substring(0, parameterEndIndex + 1) + replaceParametersInText(text.substring(parameterEndIndex + 1), params);

    return text.substring(0, parameterStartIndex) + params[parameterName] + replaceParametersInText(text.substring(parameterEndIndex + 1), params);
}

export function systemTextTransformer(item) {

    return {
        id: item.id ? item.id : null,
        createdDateTime: item.createdDateTime ? item.createdDateTime : null,
        isNewKey: item.newKey ? item.newKey : false,
//        oldText           : item.isNewKey           ? ""                    : getTranslationForKeyAndLangCode(state, item.textKey, item.languageCode),
        languageCode: item.languageCode ? item.languageCode : null,
        textKey: item.textKey ? item.textKey : "",
        text: item.text ? item.text : "",
        userId: item.userId ? item.userId : null,
        comment: item.comment ? item.comment : "",
    };
}

export function createSystemTextBody(item) {
    return {
        newKey: item.isNewKey ? item.isNewKey : false,
        languageCode: item.languageCode ? item.languageCode : 'en',
        textKey: item.textKey ? item.textKey : "",
        text: item.text ? item.text : "",
        comment: item.comment ? item.comment : "",
    };
}


/*
export function xpTranslatedEnrichTranslation(codeText, trParams) {
    let returnText = "";

    let remainingCodeText = codeText;
    let startIndex = 0;
    let replaceIndexStart = remainingCodeText.indexOf('${');
    while (replaceIndexStart !== -1) {
        returnText = returnText.concat(remainingCodeText.substring(startIndex, replaceIndexStart));
        remainingCodeText = remainingCodeText.substring(replaceIndexStart + 2);

        const replaceIndexStop = remainingCodeText.indexOf('}');
        let replacementText = "";
        if (replaceIndexStop  === -1) {
            console.error("Incorrect replacement code in translation: ", codeText);
            replacementText = "ERROR"
            return codeText;
        }

        const varName = remainingCodeText.substring(0, replaceIndexStop);
        replacementText = (!trParams || !trParams[varName]) ? "[\'" + varName + "\' not provided]" : trParams[varName];
        returnText = returnText.concat(replacementText);

        remainingCodeText = remainingCodeText.substring(replaceIndexStop + 1);
        replaceIndexStart = remainingCodeText.indexOf('${');
    }
    return returnText.concat(remainingCodeText);
}
*/
