import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import PropTypes from "prop-types";
import React from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {useSelector} from "react-redux";
import {getItemRenderProps} from "gui-common/xpForm/core/xpFormFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function XpRadioGroup(props) {
    const translate = useSelector(selectTranslateFunction);
    return (
        <RadioGroup
            value={props.value}
            onChange={props.onChange}
            row
        >
            {props.radioItems.map((item, index) => {
                if (item.disabled) return null;
                const itemRenderProps = getItemRenderProps(translate, item, props);
                return (
                    <FormControlLabel
                        value={(typeof item.id === 'number') ? String(item.id) : item.id}
                        key={index}
                        control={<Radio size="small"/>}
                        label = {itemRenderProps.trKey ? <XpTranslated trKey={itemRenderProps.trKey} trParams={itemRenderProps.trParams} /> : itemRenderProps.value}
                        disabled={item.disabled}
                    />
                    )}
            )}
        </RadioGroup>
    )
}

function XpFormRadioInput (props) {
    const translate = useSelector(selectTranslateFunction);
    return (
        <XpFormLabeledInput
            {...props}

            CompToRender={XpRadioGroup}
            getValueFromEvent={event => event.target.value}
            isRadioField
            setTouchedOnChange

            controlProps={{
                radioItems: props.radioItems,
                itemTextField: props.itemTextField
            }}

            beforeChangeFunction={(before, after) => {
                let afterChangeValue = (!after) ? "" : after;
                return (String(before) === String(afterChangeValue)) ? null : before;
            }}
            valueRenderer={(value, props) => {
                if (value === null || value === undefined) return null;
                const displayItem = props.radioItems.find(item => String(item.id) === String(value));
                const itemRenderProps = getItemRenderProps(translate,  displayItem ? displayItem : {id: value}, props);
                return itemRenderProps.trKey ? <XpTranslated trKey={itemRenderProps.trKey} trParams={itemRenderProps.trParams} /> : itemRenderProps.value
            }}
        />
    )
}

XpFormRadioInput.propTypes = {
    ...xpFormBasePropTypes,
    radioItems: PropTypes.array.isRequired,
    itemTextField: PropTypes.string
};
export default XpFormRadioInput

