import {
    xpColCreate,
    xpColHeadCreate
} from "gui-common/xpGrid/xpGridFunctions";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {rateInputParser} from "gui-common/numberFormat/numberFormatFunctions";

export function getCurrencyPairConfigListColumns(translate, listData, userCanUpdate, currencyPairEvnConfig, decDenLangState) {
    let outArray = [];
    let subArray = [];

    if (!listData || !listData.length) return [];

    xpColCreate(translate, subArray, true ,'id'                   , 100, 50 , 'currencyPairConfigList.id', {cellStyle: {textAlign: "right"}, sort: 'desc'});
    xpColCreate(translate, subArray, false,'currencyPairStr'      , 200, 100, 'agreementsList.currencyPairStr');
    xpColCreate(translate, subArray, true ,'configType'           , 100, 50 , 'currencyPairConfigList.type');
    xpColHeadCreate(translate, outArray, subArray,  'currencyPairConfigList.configInfo');


    const configObject = listData[0].configObject;
    for (let levelOneKey of Object.keys(configObject).sort()) {
        const level1Config = configObject[levelOneKey];
        for (let levelTwoKey of Object.keys(level1Config).sort()) {
            const objectPath = levelOneKey + '.' + levelTwoKey;
            const fieldName  = levelOneKey + '_' + levelTwoKey;
            const customErrors = (currencyPairEvnConfig && currencyPairEvnConfig.customValidators) ? currencyPairEvnConfig.customValidators[fieldName] : {}
            const customErrorMessages = {};
            if (currencyPairEvnConfig && currencyPairEvnConfig.customErrorMessages) {
                for (let key in currencyPairEvnConfig.customErrorMessages[fieldName]) {
                    customErrorMessages[key] = currencyPairEvnConfig.customErrorMessages[fieldName][key]
                }
            }
            xpColCreate(translate, subArray, false, fieldName,     100 , 60, 'currencyPairConfigParameters.'+levelTwoKey+'.label', {
                cellEditor: 'xpGridFormInputEditor',
                cellRenderer: 'xpGridFormInputRenderer',
                cellRendererParams: {
                    getFieldModel: object => object.id + '.configObject.' + objectPath,
                    objectPath: objectPath,
                    xpFormComponent: XpFormTextInput,
                    xpFormComponentProps: {
                        parser: value => rateInputParser(value),
                        alignRight: true,
                    },
                    errors : {
                        isRequired: (val) => (!val || val.length === 0),
                        maxLength : (val, data) => val && (val.length > 10),
                        ...customErrors,
                    },
                    getErrorTrKey: error => 'currencyPairConfigParameters.' + levelTwoKey + '.errors.' + error,
                },
                editable: (userCanUpdate === true),
                cellStyle: params => {
                    return {
                        textAlign: "right",
                    }
                },
            }, {
                xpClassName: 'xpHeaderAlignRight',
                fallBackText: levelTwoKey
            });

        }
        xpColHeadCreate(translate, outArray, subArray,  'currencyPairConfigParameters.'+levelOneKey, undefined, 'xpGroupHeaderAlignRight', undefined, {fallBackText: levelOneKey});
    }
    xpColCreate(translate, subArray, false, 'changedDateTime', 120, 50, 'general.changedDateTimeInGrid', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'lll'}});
    xpColCreate(translate, subArray, false, 'changedByUser',   120, 50, 'general.changedByUserInGrid', {cellRenderer: 'xpGridUserRenderer'});
    xpColHeadCreate(translate, outArray, subArray, 'general.changedDateTimeInGridGroup');

    return outArray;
}


export function currencyPairConfigTransformer(item) {
    return {
        name: item.name ? item.name : item.currencyPair?.key, // + '-' + item.configType,
        ...item,
    };
}

export function createCurrencyPairConfigBody(item) {
    return {
        id           : item.id           ? item.id           : null,
        configObject : item.configObject ? item.configObject : null,
        currencyPair : item.currencyPair ? item.currencyPair : null,
        configType   : item.configType   ? item.configType   : null,
    };
}
