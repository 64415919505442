import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";

/* -----------------------------------------------------------------------------------------------------------------
* Functions for logging out user including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function logoutUserFromApi(rerouteUrl) {
    return (dispatch, getState) => {
        const redirectUrl = rerouteUrl ? rerouteUrl : selectAppEnvProperty(getState(), 'logoutUrl');
        window.location.replace(redirectUrl);
    }
}
