import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {createSelector} from "reselect";
import {getOrAddCashedModelObject, getOrAddCashedObject, getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {createTranslatedFieldData, enrichWithTreeData, translateBoolFields,} from "gui-common/xpGrid/xpGridFunctions";
import {getAccountTypeText, getDefaultObject} from "gui-common/functions/functions";
import {activeBankAccountInstitutionsSelector, activeCurrenciesSelector,} from "gui-common/orm/ormSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {activeClientSelector, buildClientObject} from "xpool-gui-common/features/client/clientSelectors";
import {
    addParentRunConfigurationElements,
    createMergedRunConfigurationElements
} from "gui-common/runConfiguration/runConfigurationFunctions";
import {runConfigurationConfig} from "appConfig/runConfiguration/runConfigurationConstants";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {selectedOrderInCashPoolOrderList} from "xpool-gui-common/features/order/orderSelectors";
import {workflowPrototypeToWorkFlowMap} from "appConfig/runState/runStateConstants";
import {entityIsActive, getLastChange} from "gui-common/audit/auditFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


/********* BankAccount selectors *****************************************************************/


const getBarAndRunStateInfo = (bars) => {
    if (!bars || !bars.length) return {};

    let returnObject = {
        barId            : bars[0].id,
    }

    const runStates = bars[0].runStates.filter(item => {
        const workflow = workflowPrototypeToWorkFlowMap[item.workflow.workflowPrototype];
        return (workflow === 'BarWorkflow');
    }).orderBy('changedDateTime', 'desc').toRefArray();
    if (!runStates || !runStates.length) return returnObject;

    return {
        ...returnObject,
        barRunStateId    : runStates[0].id,
        barRunStateObject: runStates[0],
        barRunState      : runStates[0].runState,
        barRunStateParams: runStates[0].runStateParams,
        barRunStateReason: runStates[0].runStateParams?.reason,
    };
};

const getBalanceRunState = (account) => {
    const runStates = account.runStates.orderBy('changedDateTime', 'desc').toRefArray();
    if (!runStates || !runStates.length) return {};
    return {
        balanceRunStateId    : runStates[0].id,
        balanceRunStateObject: runStates[0],
        balanceRunState      : runStates[0].runState,
        balanceRunStateParams: runStates[0].runStateParams,
    };
};

function getCashPoolInfo(clientModel) {
    const cashPools = clientModel.cashPools.toRefArray();
    return {
        cashPoolId       : (cashPools?.length) ? cashPools[0].id : undefined,
        cashPoolState    : (cashPools?.length) ? cashPools[0].cashPoolState : undefined,
    }
}

export function buildBankAccountObject(deepObject, bankAccount, legalEntityUnit, legalEntity, clientModel) {
    const bars = bankAccount.bankAccountRelations.filter(entityIsActive).orderBy('id', 'desc');
    const returnObject = {
        ...bankAccount.ref,
        client: clientModel.ref,
        legalEntity: legalEntity,
        legalEntityId: legalEntity.id,
        legalEntityUnit: legalEntityUnit,
        parentAccount: deepObject && bankAccount.parentAccountId ? bankAccount.parentAccountId.ref : {},
        childAccounts: deepObject && bankAccount.childAccounts.filter(entityIsActive).toModelArray().map(childAccount => childAccount.ref),
        bankAccountRelations: bars.toRefArray(),
        ...getCashPoolInfo(clientModel),
        ...getBarAndRunStateInfo(bars.toModelArray()),
        ...getBalanceRunState(bankAccount),
    };

    return returnObject;
}
export const buildBankAccountFromId = (session, bankAccountId) => {
    if (!bankAccountId) return undefined;

    const bankAccount = session.BankAccount.idExists(bankAccountId) ? session.BankAccount.withId(bankAccountId) : undefined;
    if (!bankAccount) return undefined;

    const legalEntityUnit = session.LegalEntityUnit.idExists(bankAccount.ref.legalEntityUnitId) ? session.LegalEntityUnit.withId(bankAccount.ref.legalEntityUnitId).ref : {};
    const legalEntity = session.LegalEntity.idExists(legalEntityUnit.legalEntityId) ? session.LegalEntity.withId(legalEntityUnit.legalEntityId).ref : {};
    const client = session.Client.idExists(legalEntity.clientId) ? session.Client.withId(legalEntity.clientId) : {};

    const returnObject = buildBankAccountObject( true, bankAccount, legalEntityUnit, legalEntity, client);

    return returnObject;
};
export const bankAccountSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props.selectId,
    (session, bankAccountId) => {

        const returnObject = buildBankAccountFromId(session, bankAccountId);

//        console.log("Selected bank account:", returnObject);
        return returnObject;
    }
);

export const shallowBankAccountSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props.selectId,
    (session, bankAccountId) => {
        return getOrmModelObjectById(session, 'BankAccount', bankAccountId);
    }
);

export const bankAccountsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector reFixAgreementsSelector running ");
        let returnArray = [];
        let cashedLeus    = {};
        let cashedLes     = {};
        let cashedClients = {};
        let cashedAccounts = {};

        const bankAccounts = session.BankAccount.filter(entityIsActive).orderBy('id', 'desc').toModelArray();
        for (let bankAccount of bankAccounts) {
            // Now, build the bankAccount
            const bankAccountRef = bankAccount.ref;

            const parentAccount   =                   getOrAddCashedObject(bankAccountRef.parentAccountId   , 'BankAccount'      , cashedAccounts, session);
            const legalEntityUnit =                   getOrAddCashedObject(bankAccountRef.legalEntityUnitId , 'LegalEntityUnit'  , cashedLeus    , session);
            const legalEntity     = legalEntityUnit ? getOrAddCashedObject(legalEntityUnit.legalEntityId    , 'LegalEntity'      , cashedLes     , session) : undefined;
            const clientModel     = legalEntity     ? getOrAddCashedModelObject(legalEntity.clientId        , 'Client'           , cashedClients , session) : undefined;

            if (!clientModel) {
                console.warn("Could not find client in bankAccountsSelector", bankAccountRef);
                continue;
            }
            const bars = bankAccount.bankAccountRelations.filter(entityIsActive).orderBy('id', 'desc');

            // const runStates       = bankAccount.runState.all().toRefArray();

            returnArray.push({
                ...bankAccountRef,
                client: clientModel.ref,
                clientId: clientModel.ref.id,
                legalEntity: legalEntity,
                legalEntityId: legalEntity.id,
                legalEntityUnit: legalEntityUnit,
                parentAccount: parentAccount ? parentAccount : {},
                ...getCashPoolInfo(clientModel),
                ...getBarAndRunStateInfo(bars.toModelArray()),
                ...getBalanceRunState(bankAccount),
                ...getLastChange('BankAccount', bankAccount),
            });
        }
        // console.log("bankAccountsSelector done :", returnArray);
        return returnArray;
    }
);

export function enrichBankAccount(session, account) {
    const bankAccountModel = session.BankAccount.idExists(account.id) ? session.BankAccount.withId(account.id) : undefined;
    if (!bankAccountModel) return undefined;

    const clientModel = session.Client.idExists(account.clientId) ? session.Client.withId(account.clientId) : undefined;
    const bars = bankAccountModel.bankAccountRelations.filter(entityIsActive).orderBy('id', 'desc');
    return {
        ...account,
        childAccounts: bankAccountModel.childAccounts.filter(entityIsActive).toRefArray(),
        runConfiguration: createMergedRunConfigurationElements(bankAccountModel, 'BankAccount'),
        bankAccountRelations: bars.toRefArray(),
        clientCashPools: clientModel ? clientModel.cashPools.toRefArray() : [],
    };
}


export const getSpecificBankAccountSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    bankAccountsSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, bankAccounts, accountId) => {
        if (!accountId) return undefined;
        if (!bankAccounts || !bankAccounts.length) return undefined;

        const account = bankAccounts.find(item => item.id === accountId);
        if (!account) return undefined;

        return enrichBankAccount(session, account);
    }
);

export const getBankAccountFromLedgerAccountIdSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props.selectId,
    (session, selectId) => {

        const ledgerAccount = getOrmModelObjectById(session, 'LedgerAccount', selectId)
        if (!ledgerAccount) return null;

        const bankAccount = getOrmModelObjectById(session, 'BankAccount', ledgerAccount.ownerId)
        if (!bankAccount) return null;

//        console.log("Selected bank account:", returnObject);
        return bankAccount.ref;
    }
);

export const getBankAccountAggregatesSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, accountId) => {
        if (!accountId) return undefined;

        const aggregates = getOrmModelObjectById(session, 'BankAccountAggregates', accountId);
        if (!aggregates) return undefined;

        return aggregates.ref;
    }
);


const requestStateSelector = getRequestingStateOnModelSelector();
const requestState         = state          => requestStateSelector(state, {model: 'RunState'});
const translate            = (state, props) => selectTranslateFunction(state);
const allActiveAccounts    = (state, props) => props.inputData ? props.inputData : bankAccountsSelector(state);
const surpressTree         = (state, props) => props ? props.surpressTree   : false;
const filterFunction       = (state, props) => props ? props.filterFunction : undefined;
const selectedOrder        = (state, props) => selectedOrderInCashPoolOrderList(state);
export const getAccountListDataSelector = () => createSelector(
    [allActiveAccounts, translate, requestState, surpressTree, filterFunction, selectedOrder],
    (allActiveAccounts, translate, requestState, surpressTree, filterFunction, selectedOrder) => {
        // console.log("Selector getAccountListDataSelector running ");

        const filteredAccounts   = !filterFunction ? allActiveAccounts : allActiveAccounts.filter(filterFunction);

        enrichWithTreeData(filteredAccounts, 'parentAccountId', 'name');

        let returnArray       = [];
        for (let account of filteredAccounts) {
            const enrichedAccount = {...account};

            translateBoolFields(enrichedAccount, translate);

            if (!account.parentAccountId) createTranslatedFieldData(enrichedAccount, 'creditType', translate, ("accountForm.creditType.values." + account.creditType));

            if (selectedOrder && [selectedOrder.instructingAccountId, selectedOrder.counterpartyAccountId].includes(account.id)) enrichedAccount.isSelectedByOrder = true;
            enrichedAccount.paddedName           = account.name + (enrichedAccount.isSelectedByOrder ? ' ' : '');

            if (account.parentAccountId) {
                let runStateTrKey = "accountRunState.";
                enrichedAccount.barRunState = !account.barRunState ? 'NONE' : account.barRunState;
                runStateTrKey += enrichedAccount.barRunState;
                if (account.barRunStateParams && account.barRunStateParams.reason) runStateTrKey += "_" + account.barRunStateParams.reason;

                if (['NO_RUN_DAY', 'CANCELLED'].includes(account.barRunState) && !account.barRunStateParams?.nextRunDateTime) {
                    runStateTrKey = "accountRunState.NO_RUN_THIS_MONTH";
                }
                enrichedAccount.barNextRunTime = account.barRunStateParams?.nextRunDateTime;
                createTranslatedFieldData(enrichedAccount, 'barRunState', translate, runStateTrKey, account.barRunStateParams, requestState && account.barRunStateObject && requestState[account.barRunStateObject.id]);
            }

            enrichedAccount.businessUnitName = account.legalEntityUnit ? account.legalEntityUnit.name : "";
            enrichedAccount.legalOwnerName   = account.legalEntity     ? account.legalEntity.name     : "";
            enrichedAccount.institutionName  = account.institution     ? account.institution.name     : "";
            enrichedAccount.accountTypeText  = account.accountType     ? getAccountTypeText(account.accountType, translate) : "";
            enrichedAccount.availableCredit  =(account.creditType === "CREDIT") ? account.availableCredit : undefined;

            enrichedAccount.currencyT        = account.currency ? account.currency.currency : "";

            returnArray.push(enrichedAccount);
        }
        // console.log("Selected getAccountListDataSelector:", returnArray);
        return returnArray;
    }
);

/********* New bank account template selector *****************************************************************/

const ormCurrencies             = state => activeCurrenciesSelector(state);
const ormInstitutions           = state => activeBankAccountInstitutionsSelector(state);
const availableAccountTypes     = state => selectAppEnvProperty(state, 'availableAccountTypes');
const availableIntegrationTypes = state => selectAppEnvProperty(state, 'availableIntegrationTypes');
const availableCreditTypes      = state => selectAppEnvProperty(state, 'availableCreditTypes');
const activeClient              = state => activeClientSelector(state);
const parentItemData            = (state, props) => props ? props.parentItemData : undefined;
export const newBankAccountFormTemplateSelector = ormCreateSelector(
    orm,
    dbStateSelector, ormCurrencies, ormInstitutions, availableAccountTypes, availableIntegrationTypes, availableCreditTypes, activeClient, parentItemData,
    (session       , ormCurrencies, ormInstitutions, availableAccountTypes, availableIntegrationTypes, availableCreditTypes, activeClient, parentItemData) => {

        const client = parentItemData ?
            (parentItemData.client ? buildClientObject(getOrmModelObjectById(session, 'Client', parentItemData.client.id), false) : undefined)
            : activeClient;
        if (!client || !client.id) {
            // console.error("No client in newBankAccountFormTemplateSelector", parentItemData, activeClient);
            return undefined;
        }

        let legalEntityId = undefined;
        let legalEntityUnitId = parentItemData ? parentItemData.id : "";

        if (!parentItemData) {
            const defaultLegalEntity = (client.legalEntities && client.legalEntities[0]) ? client.legalEntities[0] : undefined;
            legalEntityId = defaultLegalEntity ? defaultLegalEntity.id : "";

            const defaultLegalEntityUnit = (defaultLegalEntity && defaultLegalEntity.legalEntityUnits && defaultLegalEntity.legalEntityUnits[0]) ? defaultLegalEntity.legalEntityUnits[0] : undefined;
            legalEntityUnitId = defaultLegalEntityUnit ? defaultLegalEntityUnit.id : "";
        }
        let parentRunConfigurations = {};
        const parentModelObject = getOrmModelObjectById(session, 'LegalEntityUnit', legalEntityUnitId);
        addParentRunConfigurationElements(parentModelObject, 'LegalEntityUnit', parentRunConfigurations)

        if (!parentRunConfigurations.sweepAndTopUp) parentRunConfigurations.sweepAndTopUp = [];
        parentRunConfigurations.sweepAndTopUp.push(runConfigurationConfig.sweepAndTopUp.newTemplate);

        return {
            clientId:                   client.id,
            clientCashPools:            client.cashPools,
            creditType:                 getDefaultObject(availableCreditTypes).id,
            currencyId:                 getDefaultObject(ormCurrencies).id,
            legalEntityId:              legalEntityId,
            legalEntityUnitId:          legalEntityUnitId,
            institutionId:              getDefaultObject(ormInstitutions).id,
            accountType:                getDefaultObject(availableAccountTypes).id,
            integrationType:            getDefaultObject(availableIntegrationTypes).id,
            runConfiguration:           parentRunConfigurations,
            balanceListLoading:         false,
            id:                         "",
            name:                       "",
            description:                "",
            accountNumber:              "",
            clearingNumber:             "",
            iban:                       "",
            bic:                        "",
            currency:                   "",
            balanceTime:                "",
            parentAccountId:            "",
            availableCredit:            "",
            createdDateTime:            null,
            createdByUser:              "",
            changedDateTime:            null,
            changedByUser:              "",
        }
    }
);
