import React, {useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import RunPoolButton from "features/cashPool/RunPoolButtons"
import OrdersList from "xpool-gui-common/features/order/OrdersList"
import AccountingOrdersList from "xpool-gui-common/features/order/AccountingOrdersList"
import AccountDetails from 'features/cashPool/AccountDetails';
import AccountsList from "features/bankAccount/AccountsList";
import XpAccordion from "gui-common/xpAccordion/XpAccordion"
import {getOrmItemLoadingSelector} from "gui-common/orm/ormLoadingSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {cashPoolOrdersListDataSelector} from "xpool-gui-common/features/order/orderSelectors";
import {useSelectorInstance} from "gui-common/functions/hooks";
import BalanceMovementsList from "xpool-gui-common/features/balanceMovement/BalanceMovementsList";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useSelectedCashPoolAccount} from "features/cashPool/cashPoolSelectors";
import {useLedgerAccountWithBalances} from "gui-common/ledger/ledgerHooks";
import LoadingDataSpinner from "gui-common/components/LoadingDataSpinner";

const accountGridHiddenState = {
    currencyT:           false,
    integrationType:     true,
    accountType:         false,
    legalOwnerName:      false,
    businessUnitName:    true,
    description:         true,
    institutionName:     true,
    clearingNumber:      true,
    accountNumber:       true,
    bic:                 true,
    iban:                true,
    barRunStateT        : false,
    barNextRunTime      : false,
    ledgerBalance       : false,
    ledgerBalanceTime   : true,
    pendingBalance      : false,
    postPoolingBalance  : false,
    aggregatedBalance:   false,
    aggregatedPostPoolingBalance: false,
    creditTypeT:      false,
    availableCredit:     false,

    id:                  false,
    createdDateTime:     true,
    createdByUser:       true,
    changedDateTime:     true,
    changedByUser:       true,
};

function AccountSpecificHeader (props) {

    if (!props.selectedAccount) return (<XpTranslated trKey= {props.trBase + '.noSelectedAccount'}/>);
    return (
        <div>
            <XpTranslated trKey={props.trBase + '.selectedAccount'}/>
            {props.selectedAccount.name}
            <span className="clickToExpand">
            {' (' + props.selectedAccount.clearingNumber + '-' + props.selectedAccount.accountNumber + ')'}
            </span>
        </div>
    )
}


function CashPool (props) {

    const activeClient    = useSelector(shallowActiveClientSelector);
    const selectedAccount = useSelectedCashPoolAccount();
    const clientLoading   = useSelectorInstance(getOrmItemLoadingSelector, {ormModel: 'Client', itemId: activeClient ? activeClient.id : undefined});
    // const translate = useSelector(selectTranslateFunction);
    // const dispatch  = useDispatch();
    const erpConfig = useAppEnvProperty('erpIntegrationConfig');
    // const [requestedAccounts, setRequestedAccounts] = useState({});
    const [currentClientId, setCurrentClientId] = useState();

    // These hooks include loading of balances if not yet loaded. Ledger account objects are not used.
    useLedgerAccountWithBalances(selectedAccount?.ledgerBalanceLaId);
    useLedgerAccountWithBalances(selectedAccount?.pendingBalanceLaId);
    useLedgerAccountWithBalances(selectedAccount?.suggestedBalanceLaId);
    useLedgerAccountWithBalances(selectedAccount?.postPoolingBalanceLaId);

    const operatingUnitText = useMemo(
        () => {
            if (!activeClient || !activeClient.operatingUnit) return null;
            return "(" + activeClient.operatingUnit.name + ": " + activeClient.operatingUnit.phoneNumber + ", " + activeClient.operatingUnit.email + ")";
        }, [activeClient]
    );

/*
    useEffect(
        () => {
            console.log("selectedAccountInCashPool", selectedAccount?.id);
            if (!selectedAccount || requestedAccounts[selectedAccount.id]) {
                return;
            }
            setRequestedAccounts({...requestedAccounts, [selectedAccount.id]: true});

            dispatch(getBalancesOnLedgerAccountFromApi(selectedAccount.ledgerBalanceLaId));
            dispatch(getBalancesOnLedgerAccountFromApi(selectedAccount.pendingBalanceLaId));
            dispatch(getBalancesOnLedgerAccountFromApi(selectedAccount.suggestedBalanceLaId));
            dispatch(getBalancesOnLedgerAccountFromApi(selectedAccount.postPoolingBalanceLaId));
        },
        [selectedAccount],
    );
*/

    const loadingNewClient = useMemo(
        () => {
            if (!clientLoading) {
                return false;
            }
            if (activeClient?.id === currentClientId) {
                return false;
            }
            setCurrentClientId(activeClient?.id);
            return true;
        },
        [clientLoading, activeClient]
    );


    // function selectedAccountInCashPool(account) {
    // }

    if (!activeClient) return (
        <div><h2><XpTranslated trKey='cashPool.headerNoClient'/></h2></div>
    )
    return (
        <div>
            <h2><XpTranslated trKey={'cashPool.header'} trParams={{name: activeClient.name}}/></h2>
            <p style={{textAlign: "center"}}>{operatingUnitText}</p>
            <hr/>

            {loadingNewClient ?
                <LoadingDataSpinner trKey='currencyDashboard.header.clientDataIsLoading'/>
                :
                <div>
                    <RunPoolButton/>
                    <div style={{marginBottom: 5}} xp-test-id="cashPoolAccountsGrid">
                        <AccountsList
                            // itemSelectedCallback={(account) => selectedAccountInCashPool(account)}
                            instanceId="cashPoolAccountList"
                            defaultSelectedId={undefined}
                            hiddenState={accountGridHiddenState}
                            rowDataSelectorProps={{
                                filterFunction: (item) => (item.clientId === activeClient.id),
                            }}
                            fillAvailableSpace={false}
                        />
                    </div>

                    <div>
                        <XpAccordion
                            headerKey='cashPool.suggestedOrdersHeader'
                            headerClassName="textGenerationsHeader"
                            instanceId="orderList"
                            defaultShowState={false}
                        >
                            <OrdersList
                                instanceId="cashPoolOrderList"
                                selectedAccountId={selectedAccount ? selectedAccount.id : undefined}
                                rowDataSelector = {cashPoolOrdersListDataSelector}
                            />
                        </XpAccordion>

                        {erpConfig.erpIntegrationEnabled &&
                        <XpAccordion
                            header={<AccountSpecificHeader selectedAccount={selectedAccount} trBase='cashPool.balanceMovements' />}
                            disabled={!selectedAccount}
                            headerClassName="textGenerationsHeader"
                            instanceId="balanceMovements"
                            defaultShowState={false}
                        >
                            <BalanceMovementsList
                                instanceId="cashPoolBalanceMovementsList"
                                rowDataSelectorProps={{bankAccount: selectedAccount}}
                            />
                        </XpAccordion>}

                        <XpAccordion
                            header={<AccountSpecificHeader selectedAccount={selectedAccount} trBase='cashPool.accountingOrders' />}
                            disabled={!selectedAccount}
                            headerClassName="textGenerationsHeader"
                            instanceId="accountingOrder"
                            defaultShowState={false}
                        >
                            <AccountingOrdersList
                                instanceId="cashPoolAccountingOrderList"
                            />
                        </XpAccordion>

                        <XpAccordion
                            header={<AccountSpecificHeader selectedAccount={selectedAccount} trBase='cashPool.poolParametersConfig' />}
                            disabled={!selectedAccount}
                            headerClassName="textGenerationsHeader"
                            instanceId="poolParameters"
                            defaultShowState={false}
                        >
                            <AccountDetails/>
                        </XpAccordion>
                    </div>
                </div>
            }
        </div>
    );
}
export default withRouter(CashPool);
