import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk'
import { modalsMiddleware } from 'redux-promising-modals';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// const dashboardInitialState = {};

export const history = createBrowserHistory();

const enhancers = [];
const middleware = [
    thunkMiddleware,
    modalsMiddleware,
    routerMiddleware(history)
];

const composedEnhancers = composeWithDevTools(
    applyMiddleware(...middleware),
    ...enhancers
);

export default function configureStore(preloadedState, rootReducer) {

    const store = createStore(
        rootReducer(history),
        preloadedState,
        composedEnhancers
    );

/*
    if(process.env.NODE_ENV !== "production") {
        if(module.hot) {
            module.hot.accept("gui-common/app/rootReducer", () =>{
                store.replaceReducer(rootReducer(history))
            });
        }
    }
*/

    return store;
}

// const appStore = createStore(rootReducer, dashboardInitialState, composedEnhancers);
// export default appStore



