import {createSelector} from "reselect";

/********* User preferences and settings selectors *****************************************************************/

const userPreferencesState = state => state.userPreferencesState;
export const selectUserPreferencesActiveClientId = createSelector(
    [userPreferencesState],
    (userPreferencesState) => {
        if (!userPreferencesState) return undefined;
        return userPreferencesState.activeClientId;
    }
);

const property = (state, property) => property;
export const getUserPreferencesPropertySelector = () => createSelector(
    [userPreferencesState, property],
    (userPreferencesState, property) => {
        if (!userPreferencesState) return undefined;
        if (!property) return undefined;
        return userPreferencesState[property];
    }
);
