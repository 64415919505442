import React, { Component } from 'react'
import {connect} from "react-redux";
import moment from 'moment';
// import Draggable from 'react-draggable';
import DashboardIcon from "gui-common/dashboard/DashboardIcon";
import {userSelector} from "gui-common/orm/ormSelectors";
import {dashboardConfig} from "./dashboardConfig";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


class DashboardPicker extends Component {

    iconDiv = function(iconName, componentName, keyName) {
        if (dashboardConfig[componentName].authorizedFunction && !dashboardConfig[componentName].authorizedFunction(this.props.user)) return null;
        return (
             <DashboardIcon key={keyName} iconName={iconName} componentName={componentName} myKey={keyName}/>
        )
    };

    render() {
        moment.locale(this.props.currentLanguage);
        return (
            <div className="dashboardPicker">
                    {this.props.dashboardState.pickerIcons.map((item) => this.iconDiv(item.icon, item.componentName, item.keyName))}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user:                   userSelector(state),
        dashboardState:         state.dashboardState,
        translate:          selectTranslateFunction(state),
        currentLanguage:    selectActiveLanguage(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPicker);
