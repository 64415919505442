import React, {useEffect, useMemo, useState} from "react";
import Slider from '@mui/material/Slider';
import PropTypes from "prop-types";
import {Tooltip} from "@mui/material";

export default function XpSlider (props) {

    const [activeSliderValue, setActiveSliderValue] = useState();
    const [changeStarted, setChangeStarted] = useState(false);

    const useMinDefault = useMemo(
        () => {
            if (!props.defaultValue || (props.defaultValue[0] === undefined)) {
                return props.minValue;
            }
            if (props.defaultValue[0] < props.minValue) {
                return props.minValue;
            }
            if (props.defaultValue[0] >= props.maxValue) {
                return props.minValue;
            }
            return props.defaultValue[0];
        },
        [props.defaultValue, props.minValue, props.maxValue]
    );

    const useMaxDefault = useMemo(
        () => {
            if (!props.defaultValue || (props.defaultValue[1] === undefined)) {
                return props.maxValue;
            }
            if (props.defaultValue[1] > props.maxValue) {
                return props.maxValue;
            }
            if (props.defaultValue[1] <= props.minValue) {
                return props.maxValue;
            }
            return props.defaultValue[1];
        },
        [props.defaultValue, props.minValue, props.maxValue]
    );

/*
    function mouseDownAtValue(newValue) {
        const compareMin = (activeSliderValue && activeSliderValue[props.dataSetId]) ? activeSliderValue[props.dataSetId][0] : useMinDefault;
        const compareMax = (activeSliderValue && activeSliderValue[props.dataSetId]) ? activeSliderValue[props.dataSetId][1] : useMaxDefault;

        return ((newValue[0] === compareMin) && (newValue[1] === compareMax))
    }
*/

    // if (activeSliderValue === undefined) return null;
    return (
        <div className={'xpSliderContainer' + (props.containerClass ? (' ' + props.containerClass) : '')}>
            <Slider
                size={'small'}
                // defaultValue={props.defaultValue ? props.defaultValue : [props.minValue, props.maxValue]}
                step={1}
                min={props.minValue}
                max={props.maxValue}
                value={(activeSliderValue && activeSliderValue[props.dataSetId]) ? activeSliderValue[props.dataSetId] : [useMinDefault, useMaxDefault]}
                valueLabelFormat={props.getLabelFn}
                valueLabelDisplay="auto"
                // ValueLabelComponent={ValueLabelComponent}
                onChangeCommitted={(event, value) => {
                    if (!changeStarted) {
                        return;
                    }
                    setActiveSliderValue({...activeSliderValue, [props.dataSetId]: value});
                    setChangeStarted(false);
                    if (props.onCommittedChangeCallback) {
                        props.onCommittedChangeCallback(value);
                    }
                }}
                 onChange={
                     (event, value) => {
                         // console.log("onChange: ", event.type, value)
/*;
This code is to prevent changing zoom when clicking on the slider path, as is the default of the Slider component.
                         if ((event.type === 'mousedown') && mouseDownAtValue(value)) {
                             setChangeStarted(true);
                             console.log("Change started");
                             return;
                         }
                         if (!changeStarted) {
                             console.log("Change not started");
                             return;
                         }
*/                         setActiveSliderValue({...activeSliderValue, [props.dataSetId]: value});
                         if (props.onChangeCallback) {
                             props.onChangeCallback(value);
                         }
                     }
                 } // Every mouse move triggers the function
            />
        </div>
    )
}

/*
function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}
*/

XpSlider.propTypes = {
    dataSetId        : PropTypes.string.isRequired,
    onCommittedChangeCallback : PropTypes.func,
    onChangeCallback : PropTypes.func,
    getLabelFn       : PropTypes.func,
    minValue         : PropTypes.number.isRequired,
    maxValue         : PropTypes.number.isRequired,
    defaultValue     : PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    containerClass   : PropTypes.string,
};
