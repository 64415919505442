import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import connect from "react-redux/es/connect/connect";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {modalLogMessage} from "../modalFunctions";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


class ModalInformation extends Component {

    static propTypes = {
        modalKey:       PropTypes.string,
        modalParams:    PropTypes.object,
        headerText:     PropTypes.string,
        messageText:    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        confirmText:    PropTypes.string,
        resultTypes:    PropTypes.object.isRequired,
        popModalWindow: PropTypes.func.isRequired
    };

    confirmAction(message) {
        const { resultTypes: { MODAL_TYPE_CONFIRM }, popModalWindow } = this.props;
        modalLogMessage('User confirmed info message:', this.props);
        popModalWindow({ status: MODAL_TYPE_CONFIRM});
    }

    render() {

        const showConfirm = this.props.confirmText ? this.props.confirmText : <XpTranslated trKey={this.props.modalKey ? (this.props.modalKey+'.confirm') : 'general.buttons.confirm'} fallbackKey='general.buttons.confirm' trParams={this.props.modalParams}/>;
        const showHeader  = this.props.headerText  ? this.props.headerText  : <XpTranslated trKey={this.props.modalKey ? (this.props.modalKey+'.header' ) : ''} trParams={this.props.modalParams}/>;
        const showMessage = this.props.messageText ? this.props.messageText : <XpTranslated trKey={this.props.modalKey ? (this.props.modalKey+'.message') : ''} trParams={this.props.modalParams}/>;

        const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal " + this.props.className}};

        return (
            <div>
                <FocusLock>
                    <ReactModal
                        contentLabel="Information modal"
                        // style={customStyles}
                        onRequestClose={(props) => this.confirmAction(showMessage)}
                        shouldCloseOnEsc={false}
                        {...modalParams}
                    >
                        <div xp-test-id="xpModalInformation">
                            <h3 className ="xpoolModalHeader">{showHeader}</h3>
                            <hr/>
                            <span className="valueTextDark">{showMessage}</span>
                            <hr/>
                            <div className="xpoolModalButtonsCenter">
                                <WaitingXpoolButton
                                    xpTestId        = {"xpModalButtonConfirm"}
                                    label           = {showConfirm}
                                    onClickCallback = {() => this.confirmAction(showMessage)}
                                />
                            </div>
                        </div>
                    </ReactModal>
                </FocusLock>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translate:          selectTranslateFunction(state), // needed in modalLogMessage call.
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalInformation);
