import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {TranslationsList} from "gui-common/appLocale/xpTranslated/TranslationsList"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectIsDevMode} from "gui-common/app/appEnvSelectors";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {pushModalWindow} from "redux-promising-modals";
import {SHOW_NEW_TEXTS_DIALOG} from "gui-common/modals/modalConstants";


class EditTranslationsPage extends React.Component {
    render() {
        return (
            <div style={{height: "100%"}}>
                <h2>
                    <XpTranslated trKey={'appLangTranslationsList.pageHeader'}/>
                    {this.props.isDevMode &&
                    <WaitingXpoolButton
                        onClickCallback = {() => this.props.pushShowTextsModal('new')}
                        labelKey        = 'appLangTextGenerationsList.showNewTranslationsButton'
                        floatRight
                    />}
                    {this.props.isDevMode &&
                    <WaitingXpoolButton
                        onClickCallback = {() => this.props.pushShowTextsModal('edited')}
                        labelKey        = 'appLangTextGenerationsList.showEditedTranslationsButton'
                        floatRight
                    />}
                </h2>
                <hr></hr>
                <div style={{height: "calc(100vh - 150px)"}}>
                    <TranslationsList instanceId="editTranslationsPageGrid"/>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        isDevMode: selectIsDevMode(state),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushShowTextsModal: (type) => dispatch(pushModalWindow(SHOW_NEW_TEXTS_DIALOG, {type: type})),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTranslationsPage));
