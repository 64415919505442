import React, {Component} from "react";
import {connect} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getFormInGridErrorFunctions} from "gui-common/xpGrid/xpGridFunctions";
import {XpFormContext} from "gui-common/xpForm/core/XpForm";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import XpTooltip from "gui-common/components/XpTooltip";


class XpGridFormInputRenderer extends Component {
    static contextType = XpFormContext;

    constructor(props) {
        super(props);

        this.state = {
            value               : props.value,
            rowData             : props.data,
            fieldTemplate       : props.colDef.cellRendererParams.fieldTemplate,
            fieldModel          : props.colDef.cellRendererParams.getFieldModel(props.data),
            xpFormComponentProps: props.colDef.cellRendererParams.xpFormComponentProps,
            xpFormComponent     : props.colDef.cellRendererParams.xpFormComponent,
            errorFunctions      : getFormInGridErrorFunctions(props.colDef.cellRendererParams.errors, props.data)
        }
    }
    componentDidMount() {
        this.setState({formContext: this.context});
    }

    refresh(params) {
        let changedState = {rowData: params.data};
        if (params.value !== this.state.value) {
            changedState.value = params.value;
            // this.props.setFormValue(this.state.formContext.formModel + '.' + this.state.fieldModel, params.value);
        }
        this.setState(changedState);
        return true;
    }

    render() {
        const errorMessages = {};
        for (let key in this.props.colDef.cellRendererParams.errors) {
            errorMessages[key] = <XpTranslated trKey={this.props.colDef.cellRendererParams.getErrorTrKey(key)}/>
        }
        return (
            <div ref="xpGridFormInputEditor" style={{height: 'var(--xpool-line-height)', overflow: 'hidden'}}>
                <this.state.xpFormComponent
                    noLabel readOnly inCellError
                    field={this.state.fieldModel}
                    fieldTemplate={this.state.fieldTemplate}
                    {...this.state.xpFormComponentProps}
                    defaultValue={this.state.value}
                    errors = {this.props.colDef.cellRendererParams.errors}
                    errorMessages={errorMessages}
                    dependentData={this.state.rowData}
                />
            </div>
        );

    }
}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFormValue    : (model, value)  => dispatch(xpFormChangeField(model, value)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(XpGridFormInputRenderer);
