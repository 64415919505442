import {userRightsAdminAddInstance} from "gui-common/adminUserRights/adminUserRightsReducer";
import {putDataRightsCollectionFromApi} from "gui-common/adminUserRights/adminUserRightsApi";

export function extractInstanceRights(entityDataRights, activeEntityDataRights) {
    if (!entityDataRights && !activeEntityDataRights) return [];

    let entityDataRightsArray = [];
    if (entityDataRights) entityDataRightsArray = entityDataRightsArray.concat(entityDataRights);
    if (activeEntityDataRights) entityDataRightsArray = entityDataRightsArray.concat(activeEntityDataRights);

    let instanceRights = {};
    for (let assignedItem of entityDataRightsArray) {
        if (!assignedItem.instanceRights) continue;
        for (let key in assignedItem.instanceRights) {
            if (!instanceRights[key]) instanceRights[key] = true;
        }
    }
    return Object.keys(instanceRights).sort();
}


/*function getDomainRights(domainRights, rightsArray, readOnly) {
    if (!domainRights) return rightsArray;
    for (let prototype in domainRights) {
        const domain = domainRights[prototype];
        if (!domain || !domain.entityPrototypeRights) continue;
        for (let key in domain.entityPrototypeRights) {
            if (readOnly && (domain.entityPrototypeRights[key] === false)) continue;
            if (!rightsArray.find(item => item === key)) rightsArray.push(key);
        }
    }
    return rightsArray;
}*/


function addDomains(array, domains, readOnly) {
    for (let prototype in domains) {
        const domain = domains[prototype];
        if (!domain || !domain.entityPrototypeRights) continue;
        for (let key in domain.entityPrototypeRights) {
            if (readOnly && (domain.entityPrototypeRights[key] === false)) continue;
            if (!array.find(item => item === key)) array.push(key);
        }
    }
}

export function extractDomainRights(domainRights, readOnly, domainRightsBeforeChange) {
    if (!domainRights) return [];
    let rightsArray = [];
    addDomains(rightsArray, domainRights, readOnly);
    if (domainRightsBeforeChange) {
        addDomains(rightsArray, domainRightsBeforeChange, readOnly);
    }
    return rightsArray.sort();
}

/*
export function extractSystemRights(systemRights, readOnly) {
    if (!systemRights) return [];

    let rightsArray = [];
    rightsArray = getDomainRights(systemRights, rightsArray, readOnly);

//        console.log("Selecting systemRights ", rightsArray);
    return rightsArray.sort();
}
*/

export function assignEntityDataRight(dataRightsCollection, entityDataRight, dispatch) {
    if (entityDataRight.id) { // entityDataRight is identified from BE, i.e. already assigned at some point in time.
        dispatch(userRightsAdminAddInstance(dataRightsCollection, entityDataRight));
        return;
    }
    dispatch(userRightsAdminAddInstance(dataRightsCollection, entityDataRight));
/*
    const updatedDataRightsCollection = {...dataRightsCollection};
    updatedDataRightsCollection.assigned   = [...dataRightsCollection.assigned  , entityDataRight];
    updatedDataRightsCollection.assignable = [...dataRightsCollection.assignable];

    const indexOfInstanceToAdd = updatedDataRightsCollection.assignable.findIndex(item => item.entity.id === entityDataRight.entity.id)
    updatedDataRightsCollection.assignable.splice(indexOfInstanceToAdd,1);
    dispatch(putDataRightsCollectionFromApi(updatedDataRightsCollection));
*/
    dispatch(putDataRightsCollectionFromApi(dataRightsCollection));
}

export function addRemovedActiveDataRights(assignedEntityDataRights, activeAssignedEntityDataRights) {
    if (!assignedEntityDataRights && !activeAssignedEntityDataRights) return undefined;
    let returnArray = [...assignedEntityDataRights];

    if (!activeAssignedEntityDataRights) return returnArray;

    activeAssignedEntityDataRights.forEach(activeItem => {
        if (!returnArray.find(assignedItem => assignedItem.entity.id === activeItem.entity.id)) returnArray.push(activeItem);
    });
    return returnArray;
}

export function compareEntityName( a, b ) {
    if ( a.entity.name < b.entity.name ) return -1;
    if ( a.entity.name > b.entity.name ) return 1;
    return 0;
}
