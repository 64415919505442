import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavDropdown} from "react-bootstrap";
import {push} from "connected-react-router";

import XpTooltip from "gui-common/components/XpTooltip";
import {setActiveClientThunk} from 'gui-common/functions/thunks';
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import MaterialIcon from "material-icons-react";
import {shallowActiveClientSelector, shallowActiveClientsSelector} from "features/client/clientSelectors";
import {entityIsDisabled} from "gui-common/audit/auditFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


function XpSearchableClientSelector () {
    const appReady     = useSelector(selectAppReadyState);
    const translate    = useSelector(selectTranslateFunction);
    const activeClient = useSelector(state => shallowActiveClientSelector(state));
    const ormClients   = useSelector(state => shallowActiveClientsSelector(state));
    const appStartPath = useSelector(state => state.appConfigState.appStartPath);
    const [searchString, setSearchString] = useState("");
    const dispatch     = useDispatch();

    useEffect(
        () => {
        },
        [],
    );

    if (!appReady) return null;

    function truncateClientName(name) {
        if (name.length < 20) return name;
        return name.substring(0, 18) + "...";
    }
    function setActiveClient(client) {
        setSearchString("");
        dispatch(setActiveClientThunk(client));
        // MT: This is needed to not remain in screen that is not allowed for the new client
        dispatch(push(appStartPath));
    }
    function renderClient(client, index) {
        if (entityIsDisabled(client)) return "";
        return (
            <NavDropdown.Item key={client.id} onClick={() => setActiveClient(client)}>
                <span style={(activeClient && (client.name === activeClient.name)) ? {fontWeight: '800', fontSize: '16px'} : {}}>
                    {client.name}
                </span>
            </NavDropdown.Item>
        );
    }

    const showList = ormClients.filter(client => client.name.toLowerCase().includes(searchString.toLowerCase()));

    return (
        <NavDropdown
            onToggle={isOpen => {
                setTimeout(()=>{
                    const inputField = document.getElementById("xpActiveClientSearchField");
                    if (inputField) inputField.focus();
                }, 10);
            }}
            title={
                <XpTooltip
                    title={activeClient ? activeClient.name : undefined}
                    trKey={activeClient ? undefined         : 'topMenu.noActiveClient'}
                >
                    <span>
                        {activeClient ? truncateClientName(activeClient.name) : translate('topMenu.noActiveClient')}
                    </span>
                </XpTooltip>
            }
            id="client-nav-dropdown"
            xp-test-id='xpActiveClientSelector'
        >
                <div style={{margin: "5px 0 5px 0"}} className={'clientSelectorSearchField'}>
                    <div style={{display: "inline-block", verticalAlign: 'top', margin: "0 5px 0 -5px"}}>
                        <MaterialIcon icon='search' size="small"/>
                    </div>
                    <input
                        id="xpActiveClientSearchField"
                        className="searchField"
                        autoFocus = {true}
                        type="text"
                        value={searchString}
                        onChange={event => setSearchString(event.currentTarget.value)}
                    />
                </div>
            {showList.length ?
                <div className={'clientSelectorMenuList'}>
                    {showList.map((client, index) => renderClient(client, index))}
                </div>
                :
                <NavDropdown.Item disabled key={1}>
                    {translate('topMenu.noClientsAvailable')}
                </NavDropdown.Item>
            }
        </NavDropdown>
    )
}
export default XpSearchableClientSelector


