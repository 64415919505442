import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import {topicsSelector, userSelector} from "gui-common/orm/ormSelectors"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {generateUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import {userMessageTypes} from "gui-common/userMessages/userMessageTypeEnum";
import {MODAL_TYPE_CANCEL} from "../modals/modalResultTypes";
import {userCan} from "gui-common/functions/userRights";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function GenerateUserMessage({popModalWindow, confirmText, cancelText, translationKey}) {

    const translate = useXpTranslateFunction();
    const topics    = useSelector(state => topicsSelector(state));
    const thisUser  = useSelector(userSelector);

    const dispatch  = useDispatch();

    const [messageToSend, setMessage] = useState("");
    const [topicToSendOn, setTopic  ] = useState("");
    const [typeToSend   , setType   ] = useState(userMessageTypes.INFO);

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalGenerateUserMessage"}};

    function listTopics() {
        let outTopics = [];
        topics.forEach(topic => {
            if (userCan('Create', 'UmTopic' + topic.topic ,thisUser)) outTopics.push(topic.topic);
        });
        return outTopics;
    }

    function sendUserMessage() {
        console.log('um:', messageToSend);
        console.log('topic:', topicToSendOn);
        dispatch(generateUserMessageThunk(typeToSend, messageToSend, topicToSendOn));
        setMessage("");
        setTopic("");
        setType(userMessageTypes.INFO);
        popModalWindow({ status: MODAL_TYPE_CANCEL });
    }

    function cancelAction() {
        popModalWindow({ status: MODAL_TYPE_CANCEL });
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Edit text dialog"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalParams}
                >
                    <div xp-test-id="generateUserMessageModal">
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={'topMenu.generateUserMessage.menuItem'}/></h3>
                        <hr/>
                        <span><XpTranslated trKey={'topMenu.generateUserMessage.description'}/></span>
                        <hr/>
                        <div>
                            <div className="xpoolModalGenerateUserMessageRow">
                                <label className="inlineLabel">
                                    <XpTranslated trKey={'userMessages.addUserMessage.messageText'}/>
                                </label>
                                <textarea
                                    autoFocus={true}
                                    value={messageToSend}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </div>
                            <div className="xpoolModalGenerateUserMessageRow">
                                <label className="inlineLabel">
                                    <XpTranslated trKey={'userMessages.addUserMessage.selectTopic'}/>
                                </label>
                                <select
                                    className="xpoolModalGenerateUserMessageInlineSelect"
                                    value={topicToSendOn}
                                    onChange={(e) => setTopic(e.target.value)}
                                >
                                    <option value="">
                                        {translate("userTopicAdmin.topicToAdd.template")}
                                    </option>
                                    {listTopics().map((item, index) => (
                                        <option
                                            value={item}
                                            key={index}
                                        >
                                            {translate("userTopicAdmin.topicToAdd.topics." + item)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="xpoolModalGenerateUserMessageRow">
                                <label className="inlineLabel">
                                    <XpTranslated trKey={'userMessages.addUserMessage.selectType'}/>
                                </label>
                                <select
                                    className="xpoolModalGenerateUserMessageInlineSelect"
                                    value={typeToSend}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    {Object.values(userMessageTypes).map((item, index) => (
                                        <option
                                            value = {item}
                                            key   = {index}
                                        >
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="xpoolModalButtons">
                                <WaitingXpoolButton
                                    xpTestId        = {"xpModalButtonConfirm"}
                                    labelKey        ='userMessages.addUserMessage.buttonConfirm'
                                    onClickCallback = {() => sendUserMessage()}
                                    disabled        = {messageToSend === "" || topicToSendOn === ""}
                                />
                                <WaitingXpoolButton
                                    xpTestId        = {"xpModalButtonCancel"}
                                    labelKey        = 'general.buttons.cancel'
                                    onClickCallback = {() => cancelAction()}
                                />
                            </div>
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
export default GenerateUserMessage;
