export const clientRunStates = [
    "NOT_SCHEDULED",
    "NO_RUN_DAY",
    "INITIAL",
    "CANCELLED",
    "WAIT_FOR_BALANCE",
    "WAIT_FOR_RETRY_BALANCE",
    "BEFORE_EXECUTION",
    "WAIT_FOR_EXECUTION",
    "WAIT_FOR_RETRY_EXECUTION",
    "COMPLETED",
    "NONE",
    "FAILED",
    "FAILED_ERROR",
    "FAILED_BALANCE",
    "FAILED_EXECUTION",
    "FAILED_REBALANCER_DOWN",
    "FAILED_CLOSED"
]
