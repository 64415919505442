import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {xpAccordionShow} from "gui-common/xpAccordion/xpAccordionReducer";
import {connect} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import "./xpAccordion.css"
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

const showExpanded = (showState, forceExpanded, disabled, defaultShowState) => {
    if (forceExpanded) return true;
    if (disabled) return false;
    if ((showState === undefined) || (showState === null)) {
        return (defaultShowState !== undefined) ? defaultShowState : true;
    }
    return showState;
};

class XpAccordion extends React.Component {

    onAccordionClick(event, expanded) {
        this.props.xpAccordionShow(this.props.instanceId, expanded);
    };

    render() {
        const enabledClassName  = this.props.headerClassName ? this.props.headerClassName : "xpAccordionHeader";
        const headerClassName = enabledClassName + (this.props.disabled ? " xpAccordionDisabledHeader" : "");

        const showHeader = this.props.header ? this.props.header : <XpTranslated trKey={this.props.headerKey} trParams={this.props.headerParams} suppressEditTranslation={this.props.suppressEditTranslation}/>;

        if (this.props.bypass) {
            return (<div>{this.props.children}</div>)
        }
        else {
            const expanded=showExpanded(this.props.showState, this.props.forceExpanded, this.props.disabled, this.props.defaultShowState);
            return (
                <Accordion expanded={expanded} disabled={this.props.disabled} className={this.props.classNamePanel ? this.props.classNamePanel : ""}
                                onChange={(event, expanded) => this.onAccordionClick(event, expanded)}>
                    <AccordionSummary
                        // expandIcon={this.props.noExpandIcon ? null : <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} className={this.props.classNameIcon ? this.props.classNameIcon : ""} />}

                        expandIcon={this.props.noExpandIcon ? null : <ArrowForwardIosSharpIcon sx={{ fontSize: '0.8rem' }} />}

                        // expandIcon={this.props.noExpandIcon ? null : <ExpandMoreIcon className={this.props.classNameIcon ? this.props.classNameIcon : ""}/>}

                        className={this.props.classNameSummary ? this.props.classNameSummary : ""}
                    >
                        <h3 className={headerClassName}>
                            {showHeader}
                        </h3>
                        {(!expanded && !this.props.disabled) && <span className="clickToExpand"><XpTranslated trKey='general.clickToExpand' suppressEditTranslation={this.props.suppressEditTranslation}/></span>}
                    </AccordionSummary>
                    <AccordionDetails className={this.props.classNameDetail ? this.props.classNameDetail : ""}>
                        {expanded && <div style={{width: "100%"}}>{this.props.children}</div>}
                    </AccordionDetails>
                </Accordion>
            )
        }
    }
}

const mapStateToProps = (state, props) => {
    return {
        showState: state.xpAccordionState ? state.xpAccordionState[props.instanceId] : undefined,
        translate: selectTranslateFunction(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        xpAccordionShow: (name, show)    => dispatch(xpAccordionShow(name, show)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(XpAccordion);
