import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {formatAmountForInput, stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {xpFormFieldStateSelector} from "gui-common/xpForm/core/xpFormSelectors";

function reformatStringNumber(amount, stateInfo) {
    let floatAmount = stringNumberToFloat(amount, {amountDenominator: stateInfo.oldDen, amountDecimals: stateInfo.oldDec, activeLanguage: stateInfo.oldLan});
    let outString = formatAmountForInput(floatAmount, {amountDenominator: stateInfo.newDen, amountDecimals: stateInfo.newDec, activeLanguage: stateInfo.newLan});
    return outString;
}
export const reformatNumberFieldsOnChangedRules = (formattingRules) => {
    return (dispatch, getState) => {
        let formNumberFields = getState().formNumberFieldsState;
        for (let model in formNumberFields) {
            const current = xpFormFieldStateSelector(getState(), {model: model});
            if (!current) continue;
            const currentString = current.fieldValue;
            if (currentString === "") continue;
            const newValue = reformatStringNumber(currentString, formattingRules);
            dispatch(xpFormChangeField(model, newValue))
        }
    };
};
