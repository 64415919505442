import {ormApiBaseUrlMap} from "appConfig/api/apiConfig";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {
    ormEntitiesGetFailed,
    ormEntitiesGetStarted,
    ormEntitiesReceived, ormEntityUpdated,
    ormEntityUpdateFailed,
    ormEntityUpdateStarted
} from "gui-common/orm/ormReducer";
import {genericFetch} from "gui-common/api/apiCommon";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {ormItemArrayTransformer} from "gui-common/orm/ormApi";

export function registerLedgerBalanceToApi(ledgerAccountId, balance, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Register ledger balance " + balance + " on ledger bankAccount " + ledgerAccountId;
            let requestUrl = ormApiBaseUrlMap.LedgerAccount + "/" + ledgerAccountId + "/register-balance?";
            const decDenLangState = selectDecDenLangState(getState());
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => { return (dispatch, getState) =>
                    dispatch(ormEntityUpdateStarted('LedgerAccount', {id: ledgerAccountId}));
                },
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'PUT',
                {
                    balance: stringNumberToFloat(balance, decDenLangState),
                    comment: comment,
                }
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function getBalancesOnLedgerAccountFromApi(ledgerAccountId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Get balances from ledger bankAccount " + ledgerAccountId;
            let requestUrl = ormApiBaseUrlMap.LedgerAccount + "/" + ledgerAccountId + "/balances?";
            dispatch(ormEntityUpdated('LedgerAccount', {id: ledgerAccountId, baseLoadRequested: true}))
            dispatch(ormEntityUpdateStarted('LedgerAccount', {id: ledgerAccountId}));
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => { return (dispatch, getState) => {
                    let transformedResponse = ormItemArrayTransformer('LedgerAccountBalance', resp, {});
                    dispatch(ormEntitiesReceived('LedgerAccountBalance', transformedResponse));
                    dispatch(ormEntityUpdated('LedgerAccount', {id: ledgerAccountId, baseLoadRequested: false, baseLoadCompleted: true}))
                }},
                loadBalancesFailHandler(ledgerAccountId),
                loadBalancesFailHandler(ledgerAccountId),
                'GET',
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function loadBalancesFailHandler(ledgerAccountId) {
    return (resp) => { return (dispatch, getState) => {
        dispatch(ormEntityUpdated('LedgerAccount', {id: ledgerAccountId, baseLoadRequested: false}))
        dispatch(ormEntitiesGetFailed('LedgerAccountBalance'))
    }}
}

export function disableLedgerAccountBalanceToApi(ledgerAccountId, balanceId, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Disable balance id " + balanceId + " on ledger bankAccount " + ledgerAccountId;
            let requestUrl = ormApiBaseUrlMap.LedgerAccount + "/" + ledgerAccountId + "/balances/" + balanceId + "?";

            dispatch(ormEntityUpdateStarted('LedgerAccountBalance', {id: balanceId}));
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => { return (dispatch, getState) => {
                    let transformedResponse = ormItemArrayTransformer('LedgerAccountBalance', [resp], {});
                    dispatch(ormEntitiesReceived('LedgerAccountBalance', transformedResponse));
                }},
                (resp) => { return (dispatch, getState) => dispatch(ormEntityUpdateFailed('LedgerAccountBalance', {id: balanceId}))},
                (resp) => { return (dispatch, getState) => dispatch(ormEntityUpdateFailed('LedgerAccountBalance', {id: balanceId}))},
                'DELETE',
                {
                    comment: comment,
                }
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
