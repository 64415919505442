import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {
    activeBankAccountInstitutionsSelector,
    activeCurrenciesSelector,
} from "gui-common/orm/ormSelectors";
import {clientLegalEntitiesSelector} from "xpool-gui-common/features/client/clientSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {shallowActiveLegalEntityUnitsSelector} from "xpool-gui-common/features/legalEntityUnit/legalEntityUnitSelectors";
import {xpFormUserCanEditBase} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import {useXpFormContext, useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormRadioInput from "gui-common/xpForm/core/XpFormRadioInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {useDisableAccountPropertyOnActiveOrders} from "features/bankAccount/bankAccountSelectors";


function AccountBaseStatic (props) {
    const formContext               = useXpFormContext();
    const ormCurrencies             = useSelector(activeCurrenciesSelector);
    const ormInstitutions           = useSelector(activeBankAccountInstitutionsSelector);
    const activeLegalEntities       = useSelector(clientLegalEntitiesSelector);
    const activeLegalEntityUnits    = useSelector(shallowActiveLegalEntityUnitsSelector);
    const availableAccountTypes     = useSelector(state => selectAppEnvProperty(state, 'availableAccountTypes'));
    const availableIntegrationTypes = useSelector(state => selectAppEnvProperty(state, 'availableIntegrationTypes'));
    const disableOnOpenOrders       = useDisableAccountPropertyOnActiveOrders(formContext?.currentData?.clientId, formContext);
    const formData                  = useXpFormFields({dependentFields: {
            legalEntityId: 'legalEntityId',
            legalEntityUnitId: 'legalEntityUnitId',
            accountType: 'accountType',
            integrationType: 'integrationType',
        }})

    const dispatch = useDispatch();

    const legalEntities    = props.parentItemData ? [] : activeLegalEntities;
    const legalEntityUnits = props.parentItemData ? [] : activeLegalEntityUnits;

    function fieldIsDisabled() {
        return !xpFormUserCanEditBase(formContext, props.ormModel, props.isSubForm);
    }

    const legalEntitiesToSelect = useMemo(
        () => {
            const returnArray = [];
            legalEntities.forEach(leItem => {
                if (legalEntityUnits.find(leuItem => leuItem.legalEntityId === leItem.id)) returnArray.push(leItem);
            });
            return returnArray;
        },
        [legalEntities, legalEntityUnits]
        // [(props.entityData && props.entityData.form) ? props.entityData.form.legalEntityId : null, props.formState, legalEntityUnits]
    );
    const legalEntityUnitsToSelect = useMemo(
        () => {
            if (!formData?.legalEntityId) return [];
            if (formContext.formUseState === XP_FORM_VIEW) return legalEntityUnits;
            const returnArray = [];

            legalEntityUnits.forEach(item => {
                returnArray.push({
                    ...item,
                    disabled: (item.legalEntityId !== Number(formData.legalEntityId))
                })
            });
            return returnArray;
        },
        [formData?.legalEntityId, formContext?.formUseState, legalEntityUnits]
    );
    const legalEntityUnitsUnderLegalEntity = useMemo(
        () => {
            if (!formData?.legalEntityId) return [];
            return legalEntityUnits.filter(item => item.legalEntityId === Number(formData.legalEntityId));
        },
        [formData?.legalEntityId, formContext?.formUseState, legalEntityUnits]
    );

    useEffect(
        () => {
            // Auto set the first available legal entity unit, or "".
            // Only relevant if there are legalEntities and legalEntityUnits from the parent data which is the case in the bankAccount admin screen where client is the parent.
            // In the normal tree admin structure, parent is LEU and the legalEntities and legalEntityUnits arrays will be empty
            if (!legalEntityUnitsUnderLegalEntity || !legalEntityUnitsUnderLegalEntity.length) return;

            if (!formData?.legalEntityId) return;

            if (legalEntityUnitsUnderLegalEntity.find(item => item.id === formData.legalEntityUnitId)) return; // Selected LEU is under selected LE.

            const legalEntityUnitId = (legalEntityUnitsUnderLegalEntity && legalEntityUnitsUnderLegalEntity[0]) ? String(legalEntityUnitsUnderLegalEntity[0].id) : "";

            if (formData.legalEntityUnitId === legalEntityUnitId) return;
            dispatch(xpFormChangeField(formContext.formModel + ".legalEntityUnitId", legalEntityUnitId));
        },
        [formData, legalEntityUnitsUnderLegalEntity],
    );

    const defaultProps = {
        disabled     : fieldIsDisabled()
    }

    return (
        <div className={props.className}>
            <XpFormSelectorInput
                {...defaultProps}
                inLineLayout noTemplate
                field="currencyId"
                selectList={ormCurrencies}
                itemTextField="currency"
                isRequired={true}
                disabled      = {disableOnOpenOrders}
                labelKey      = {disableOnOpenOrders ? "accountForm.currencyId.labelBlockOnOrder" : undefined}
                toolTipTrKey  = {disableOnOpenOrders ? "accountForm.currencyId.toolTipBlockOnOrders" : undefined}
            />

            {(availableAccountTypes.length > 1) &&
            <XpFormRadioInput
                {...defaultProps}
                field="accountType"
                radioItems={availableAccountTypes}
            />}

            {(formData?.accountType === "PHYSICAL") &&
            <div>
                {(legalEntitiesToSelect && legalEntitiesToSelect.length > 1) &&
                <XpFormSelectorInput
                    {...defaultProps}
                    inLineLayout noTemplate
                    field="legalEntityId"
                    selectList={legalEntitiesToSelect}
                    itemTextField="name"
                    isRequired
                />}

                {(legalEntityUnitsUnderLegalEntity && legalEntityUnitsUnderLegalEntity.length > 1) &&
                <XpFormSelectorInput
                    {...defaultProps}
                    inLineLayout noTemplate
                    field="legalEntityUnitId"
                    selectList={legalEntityUnitsToSelect}
                    itemTextField="name"
                    isRequired
                />}

                <XpFormSelectorInput
                    {...defaultProps}
                    inLineLayout noTemplate
                    field="institutionId"
                    selectList={ormInstitutions}
                    itemTextField="name"
                    isRequired
                    onChangeCallback={() => dispatch(xpFormChangeField(formContext.formModel + '.parentAccountId', undefined))}
                />

                {(availableIntegrationTypes.length > 1) &&
                <XpFormRadioInput
                    {...defaultProps}
                    field="integrationType"
                    radioItems={availableIntegrationTypes}
                />}

                {(formData?.integrationType === "PSD2") &&
                <div>
                    <XpFormTextInput
                        {...defaultProps}
                        field= "iban"
                        checkNoSpaces={true}
                        checkOnlySpaces={false}
                        maxLength={34}
                        minLength={16}
                        isRequired
                    />
                    <XpFormTextInput
                        {...defaultProps}
                        field= "bic"
                        checkNoSpaces={true}
                        checkOnlySpaces={false}
                        exactLength={8}
                        isRequired
                    />
                </div>}
            </div>}
        </div>
    );
}

AccountBaseStatic.propTypes = {
    className:        PropTypes.string,
    superEntity:      PropTypes.object,
    clientData:       PropTypes.object,
    useStaticObject:  PropTypes.bool,
};

AccountBaseStatic.defaultProps = {
    className  : undefined,
    superEntity: undefined,
};

export default AccountBaseStatic;

