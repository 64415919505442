import {
    xpColCreate,
    xpColShortDateColCreate
} from "gui-common/xpGrid/xpGridFunctions";


export function getCashLadderTransactionsColumns(translate, selectedProjectionDates) {
    let subArray = [];
    let hiddenState = {};

    hiddenState.id = true;
    hiddenState.type = false;
    // hiddenState.currencyStr = false;

    xpColCreate(translate, subArray, hiddenState, 'id'           , 80 , 50 , 'transactionsList.systemId', {cellStyle: {textAlign: "right"}, sort: 'asc', lockVisible: true});
    xpColCreate(translate, subArray, hiddenState, 'type'         , 205, 100, 'transactionsList.type', {suppressSizeToFit: true});
    // xpColCreate(translate, subArray, hiddenState, 'currencyStr'  , 50 , 50 , 'transactionsList.currency');

    for (let date of selectedProjectionDates) {
        xpColShortDateColCreate(subArray, 80, date, undefined, {cellStyle: function(params) {
                return (params.data.valueDate !== params.colDef.field) ? {color: '#B2B2B2', textAlign: 'right', fontStyle: 'italic'} : {textAlign: 'right'};
            }});
    }
    return subArray;
}
