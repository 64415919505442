import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import XpTooltip        from "gui-common/components/XpTooltip";
import {userSelector}   from "gui-common/orm/ormSelectors";
import userIcon         from 'gui-common/resources/userIcon.png'
import userIconAtt      from 'gui-common/resources/userIconAtt.png'
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {renderUserProfiles} from "gui-common/user/userFunctions";

function XpUserIcon () {
    const userMessages   = useSelector(state => state.userMessagesState);
    const user           = useSelector(userSelector);

    const unconfirmedMessages = useMemo(
        () => {
            if (!userMessages) return [];
            if (!userMessages.length) return[];
            return userMessages.filter(item => (item.requireConfirmation && !item.confirmedDateTime));
        },
        [userMessages]
    );

    function getToolTip() {
        if (!user) return "";
        return (
            <span>
                <XpTranslated trKey='userPreferencesForm.userId.label'/>{" " + user.userId + "\n"}
                <XpTranslated trKey='userPreferencesForm.userName'    />{" " + user.firstName + " " + user.lastName + "\n"}
                <XpTranslated trKey='userPreferencesForm.topics'      />{"\n"}
                {user.topics && user.topics.map(topic => <span key={topic}><XpTranslated trKey={'userTopicAdmin.topicToAdd.topics.' + topic}/>{"\n"}</span>)}
                <XpTranslated trKey='userPreferencesForm.profiles'    />
                {"\n"}
                {renderUserProfiles(user)}
                {(unconfirmedMessages.length === 0) && <XpTranslated trKey='topMenu.noUnconfirmedMessages' />}
                {(unconfirmedMessages.length === 1) && <XpTranslated trKey='topMenu.unconfirmedMessage'    />}
                {(unconfirmedMessages.length >   1) && <XpTranslated trKey='topMenu.unconfirmedMessages' trParams={{umCount: unconfirmedMessages.length}}/>}
            </span>
        )
    }

    const icon = (unconfirmedMessages.length) ? userIconAtt : userIcon;

    return (
        <XpTooltip title={getToolTip()} placement="left-end">
            <img src={icon} alt="user" className="userImage"/>
        </XpTooltip>
    )
}
export default XpUserIcon



