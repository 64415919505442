import {genericFetch} from "gui-common/api/apiCommon";
import {addUserMessageThunk} from 'gui-common/userMessages/userMessageThunks'
import {warnAndResolve} from "gui-common/functions/functions";
import {apiBaseUrlMap} from "appConfig/api/apiConfig";
import {selectUserPreferencesActiveClientId} from "gui-common/userSettings/userSettingsSelectors";


/* -----------------------------------------------------------------------------------------------------------------
* Functions for execute suggested orders including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function executeSuggestedOrders() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let clientId = selectUserPreferencesActiveClientId(getState());
            if (!clientId) {
                warnAndResolve("Execute suggested orders called with no client ID", resolve);
                return;
            }
            let customText = "Execute suggested orders for client id " + clientId + " ";
            let requestUrl = apiBaseUrlMap.executeOrders + "/" + clientId + "?";

            dispatch(genericFetch(
                requestUrl,
                customText,
                executeSuggestedOrdersResponseHandler,
                executeSuggestedOrdersFailHandler,
                executeSuggestedOrdersFailHandler,
                'POST'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function executeSuggestedOrdersResponseHandler(resp) {
    return (dispatch, getState) => {
        dispatch(addUserMessageThunk("info", "userMessages.info.suggestedOrdersExecuted", {client: resp.name}, false, resp));
    }
}
function executeSuggestedOrdersFailHandler(resp) {
    return (dispatch, getState) => {
        // TBD to handle failure to execute orders...
    }
}

