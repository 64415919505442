import React from "react";
import {useDispatch} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG, modalStandardParams} from "gui-common/modals/modalConstants";
import LabelValueDisplay from "gui-common/components/LabelValueDisplay";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import {xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import moment from "moment";
import {registerBalanceMovementToApi} from "xpool-gui-common/features/balanceMovement/balanceMovementApi";
import XpFormDateInput from "gui-common/xpForm/core/XpFormDateInput";

const formName = "registerBalanceMovementForm"
export function RegisterBalanceMovementModal(props) {
    const dispatch       = useDispatch();
    const balanceMovementsConfig = useAppEnvProperty('balanceMovementsConfig')
    const formData       = useXpFormFields({dependentFieldModels: {
            movement : formName + '.movement',
            comment  : formName + '.comment',
        }})

    function closeModal(){
        props.popModalWindow({ status: MODAL_TYPE_CANCEL });
        dispatch(xpFormRemoveForm(formName));
    }

    function cancelAction() {
        if (!formData.movement && !formData.comment) {
            closeModal();
            return;
        }
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'general.modalConfirmCancelEditNew'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) closeModal();
            });
    }
    function handleSubmit(form) {
        dispatch(registerBalanceMovementToApi(props.account, form, props.type));
        props.popModalWindow({ status: MODAL_TYPE_CONFIRM });
        dispatch(xpFormRemoveForm(formName));
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Register balance movement dialog"
                    onRequestClose={cancelAction}
                    shouldCloseOnEsc={true}
                    {...modalStandardParams}
                >

                    <div xp-test-id="registerBalanceMovementDialog">
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={'registerBalanceMovementForm.header.' + props.type}/></h3>
                        <LabelValueDisplay
                            label={<XpTranslated trKey={'registerBalanceMovementForm.accountName.label'}/>}
                            value={props.account.name}
                            labelTab={30}
                        />
                        <LabelValueDisplay
                            label={<XpTranslated trKey={'registerBalanceMovementForm.accountCurrency.label'}/>}
                            value={props.account.currency.currency}
                            labelTab={30}
                        />
                        <hr/>

                        <XpForm
                            formModel={formName}
                            onSubmit={handleSubmit}
                            initialUseState={XP_FORM_NEW}
                            initialFormData={{valueDate: props.date ? props.date : moment().format('YYYY-MM-DD'), movement: "", comment: ""}}
                        >
                            <XpFormAmountInput
                                isRequired autoFocus
                                inLineDivider={40}
                                field     = "amount"
                                maxAmount = {balanceMovementsConfig?.maxBalanceMovementAmount}
                                minAmount = {-balanceMovementsConfig?.maxBalanceMovementAmount}
                            />
                            <XpFormDateInput
                                isRequired noTemplate alignRight inLineLayout
                                inLineDivider={30}
                                field         = "valueDate"
                                // minDate       = {moment().format('YYYY-MM-DD')}
                                // showYearDropdown = {true}
                                popperPlacement='left'
                            />
                            <XpFormTextAreaInput
                                field="comment"
                                maxLength={200}
                            />
                        </XpForm>

                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                labelKey        = "general.buttons.save"
                                onClickCallback = {() => dispatch(xpFormSubmitForm(formName))}
                                disabled={formData.amount === ""}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                labelKey        = 'general.buttons.cancel'
                                onClickCallback = {cancelAction}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
