import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import XpTooltip from "gui-common/components/XpTooltip";

import "./erpIntegration.css"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {
    requestCancelErpConnectionFromApi,
    requestErpConnectionFromApi, requestErpPayablesFromApi, requestErpReceivablesFromApi,
    requestRevokeErpConnectionFromApi
} from "gui-common/erpIntegration/erpIntegrationApi";
import {useDispatch} from "react-redux";
import XpContextMenu from "gui-common/contextMenu/XpContextMenu";
import spinnerGif from "gui-common/resources/spinner.gif";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getErpIntegrationSelector} from "gui-common/erpIntegration/erpIntegrationSelectors";


function EprConnectStatusButton (props) {

    const erpIntegration = useSelectorInstance(getErpIntegrationSelector, {selectId: props.erpIntegrationId});
    const [erpLoginWindow, setErpLoginWindow]               = useState(undefined);
    const [erpStatusAtConnecting, setErpStatusAtConnecting] = useState(undefined);
    const dispatch = useDispatch();
    const erpConfig = useAppEnvProperty('erpIntegrationConfig');

    const backgroundCss = useMemo(
        () => {
            if (!erpIntegration?.erpEnabled) return 'erpIntegrationNotConfigured';
            switch (erpIntegration?.status) {
                case 'CONNECTED'    : return 'erpIntegrationReady';
                case 'CONNECTING'   : return 'erpIntegrationWarning';
                case 'EXPIRED'      :
                case 'REVOKED'      :
                case 'TIMEOUT'      :
                case 'FAILED'       : return 'erpIntegrationError';
                case 'INITIAL'      : return 'erpIntegrationNotConnected';
                default:
            }
            return 'erpIntegrationNotConnected';
        },
        [erpIntegration?.status]
    );

    useEffect(
        () => {
            if (!erpLoginWindow) return;
            if (erpIntegration?.status === erpStatusAtConnecting) return;

            if (erpIntegration?.status !== 'CONNECTING') {
                setTimeout(() => {
                    erpLoginWindow.close();
                },  erpConfig?.closePopUpTimeout ? erpConfig?.closePopUpTimeout : 2000);
                setErpLoginWindow(undefined);
            }
        },
        [erpLoginWindow, erpIntegration?.status],
    );

    const contextMenuItems = useMemo(
        () => {
            const returnArray = [];
            if (erpIntegration?.status === 'CONNECTED') {
                returnArray.push ({
                    trKey: 'erpIntegration.contextMenuFunctions.revoke.label',
                    trParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName},
                    action: () => dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'erpIntegration.contextMenuFunctions.revoke', modalParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName}}))
                        .then((result) => {
                            if (result.status === MODAL_TYPE_CONFIRM) dispatch(requestRevokeErpConnectionFromApi(erpIntegration));
                        }),
                })
                returnArray.push ({
                    trKey: 'erpIntegration.contextMenuFunctions.requestPayables.label',
                    trParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName},
                    action: () => dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'erpIntegration.contextMenuFunctions.requestPayables', modalParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName}}))
                        .then((result) => {
                            if (result.status === MODAL_TYPE_CONFIRM) dispatch(requestErpPayablesFromApi(erpIntegration));
                        }),
                })
                returnArray.push ({
                    trKey: 'erpIntegration.contextMenuFunctions.requestReceivables.label',
                    trParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName},
                    action: () => dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'erpIntegration.contextMenuFunctions.requestReceivables', modalParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName}}))
                        .then((result) => {
                            if (result.status === MODAL_TYPE_CONFIRM) dispatch(requestErpReceivablesFromApi(erpIntegration));
                        }),
                })
            }
            else if (erpIntegration?.status === 'CONNECTING') returnArray.push ({
                trKey: 'erpIntegration.contextMenuFunctions.cancel.label',
                trParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName},
                action: () => dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'erpIntegration.contextMenuFunctions.cancel', modalParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName}}))
                    .then((result) => {
                        if (result.status === MODAL_TYPE_CONFIRM) dispatch(requestCancelErpConnectionFromApi(erpIntegration));
                    }),
            })
            else returnArray.push ({
                trKey: 'erpIntegration.contextMenuFunctions.connect.label',
                trParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName},
                    action: () => dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'erpIntegration.contextMenuFunctions.connect', modalParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName}}))
                        .then((result) => {
                            if (result.status === MODAL_TYPE_CONFIRM) {
                                dispatch(requestErpConnectionFromApi(erpIntegration))
                                .then(resp => {
                                    setErpStatusAtConnecting(erpIntegration.status);
                                    setErpLoginWindow(windowPop(resp.response.redirectUri, 'erp-connect-' + erpIntegration.id,  800, 700));
                                })
                            }
                        }),
            })
            return returnArray;
        },
        [erpIntegration?.status, erpIntegration?.erpSystem]
    );

    const tooltipTrKey = useMemo(
        () => {
            if (erpIntegration?.erpEnabled) {
                return 'erpIntegration.erpConnectionStatusTooltip.' + erpIntegration?.status;
            }
            return 'erpIntegration.erpConnectionStatusTooltip.NO_ERP';
        },
        [erpIntegration?.erpEnabled, erpIntegration?.status]
    );

    const textTrKey = useMemo(
        () => {
            if (erpIntegration?.erpEnabled) {
                return 'erpIntegration.erpConnectionStatus.' + erpIntegration?.status;
            }
            return 'erpIntegration.erpConnectionStatus.NO_ERP';
        },
        [erpIntegration?.erpEnabled, erpIntegration?.status]
    );


    if (!erpIntegration) return null; // When a new LE is created.
    if (props.noRenderWhenDisabled && !erpIntegration?.erpEnabled) return null;

    return (
            <XpContextMenu
                bypass    = {!erpIntegration?.erpEnabled}
                menuItems = {contextMenuItems}
            >
                <XpTooltip placement="top" trKey={tooltipTrKey} trParams={{boName: props.businessObjectName, erpSystemName: erpIntegration.erpSystem?.name}}>
                    <div className={"erpIntegrationIndicatorBase " + backgroundCss} style={props.removeBorder ? {border: 'none'} : {}}>
                        {(erpIntegration?.status === 'CONNECTING') &&
                        <img className="smallTableSpinner" src={spinnerGif} alt="waiting" style={{margin: '-5px', marginTop: '-7px'}}/>}
                        <XpTranslated trKey={textTrKey} />
                    </div>
                </XpTooltip>
            </XpContextMenu>
    )
}
EprConnectStatusButton.propTypes = {
    erpIntegrationId: PropTypes.number,
    businessObjectName: PropTypes.string,
};
export default EprConnectStatusButton

function windowPop(url, name, width, height) {
    const leftPosition = (window.screen.width / 2) - ((width / 2) + 10); //Allow for borders.
    const topPosition  = (window.screen.height / 2) - ((height / 2) + 50); //Allow for title and status bars.

    return window.open(url, name,
        "status=no,height=" + height + ",width=" + width + ",resizable=yes" +
        ",left=" + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY=" + topPosition +
        ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
}

/*
const erpUrls = {
    ['Visma']  : 'https://identity-sandbox.test.vismaonline.com/connect/authorize?client_id=nordicfinancialcomponentsab&redirect_uri=https://dev.xpool.se/erp-connect-callback/visma-spcs&scope=ea:api%20offline_access%20ea:sales%20ea:purchase%20ea:accounting&state=Fishes&response_type=code&prompt=login&acr_values=service:44643EB1-3F76-4C1C-A672-402AE8085934+forceselectcompany:true',
    ['Fortnox']: 'https://apps.fortnox.se/oauth-v1/auth?client_id=H067jERE9y1J&redirect_uri=https://dev.xpool.se/erp-connect-callback/fortnox&scope=scope=supplierinvoice%20invoice%20bookkeeping%20payment%20customer%20supplier&state=Fishes&access_type=offline&response_type=code'
}
*/
