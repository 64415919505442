import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    useActiveClientLE0Accounts,
} from "xpool-gui-common/features/currencyDashboard/currencyDashboardFunctions";
import {activeClientSelector} from "xpool-gui-common/features/client/clientSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpLanguageSelector from "gui-common/menuComponents/XpLanguageSelector";
import XpNumberFormatMenu from "gui-common/menuComponents/XpNumberFormatMenu";
import {NavDropdown} from "react-bootstrap";
import XpUserIcon from "gui-common/menuComponents/XpUserIcon";
import {LinkContainer} from "react-router-bootstrap";
import XpTooltip from "gui-common/components/XpTooltip";
import {confirmAllUserMessages} from "gui-common/userMessages/userMessageThunks";
import {openUserMessagesSelector} from "gui-common/userMessages/userMessagesSelectors";
import XpSearchableClientSelector from "gui-common/menuComponents/XpSearchableClientSelector";
import CurrencyDashboardAccounts from "xpool-gui-common/features/currencyDashboard/CurrencyDashboardAccounts";
import DevMenu from "gui-common/app/DevMenu";
import CurrencyDashboardBalancePieChart from "xpool-gui-common/features/currencyDashboard/CurrencyDashboardBalancePieChart";
import CurrencyDashboardExposurePieChart from "xpool-gui-common/features/currencyDashboard/CurrencyDashboardExposurePieChart";
import EprConnectStatusButton from "gui-common/erpIntegration/ErpConnectStatusButton";
import {XpHomeCurrencySelector} from "gui-common/menuComponents/XpHomeCurrencySelector";
import {useActiveClientIsLoading} from "xpool-gui-common/features/client/clientHooks";
import LoadingDataSpinner from "gui-common/components/LoadingDataSpinner";

const meterSize = 230;
function CurrencyDashboardHeader (props) {

    const activeClient = useSelector(activeClientSelector);
    const activeClientIsLoading = useActiveClientIsLoading();
    // const baseAccount = useBaseAccount();
    const accounts = useActiveClientLE0Accounts();
    // const baseAccountLedgerAccount = useSelectorInstance(getLedgerAccountSelector, {selectId: baseAccount?.ledgerBalanceLaId})
    // const totalLiquidity= useTotalLiquidity();
    // const devMode = useSelector(selectIsDevMode);

    // const [nameAndKeyValuesAreaState, setNameAndKeyValuesAreaState]   = useState(undefined);

/*
    const nameAndKeyValuesRef = useResizeObserver(box => {
        setNameAndKeyValuesAreaState({height: box.height, width: box.width})
    })

    function getTotalLiquidity() {
        if ((totalLiquidity === undefined) || (baseAccountLedgerAccount === undefined)) {
            return (<XpTranslated trKey='currencyDashboard.header.noTotalBalanceAvailable' />)
        }
        return (
            <div>
                <h5>
                    <XpTooltip bypass={!devMode} title={(<DevMenuWidget/>)} >
                        <span>
                            <XpTranslated trKey='currencyDashboard.header.totalCash' trParams={{currency: baseAccount.currency.currency}}/>
                        </span>
                    </XpTooltip>
                    <h3 style={{display: 'inline-block'}}>
                        <XpFormattedAmount amount={totalLiquidity}/>
                    </h3>
                </h5>
                <h5>
                    <XpTranslated trKey='currencyDashboard.header.totalExposure'/>
                    <XpFormattedAmount amount={100 * (totalLiquidity - baseAccountLedgerAccount.balance) / totalLiquidity} style={{color: 'var(--xpool-header-text-color)'}}/>
                    <span style={{color: 'var(--xpool-header-text-color)'}}>%</span>
                </h5>
            </div>
        )
    }

    const accountWidgetsWidthStr = nameAndKeyValuesAreaState ? 'calc(100% - ' + (nameAndKeyValuesAreaState.width + 200) + 'px)' : '70%';
*/
    const availableCurrencies = useMemo(
        () => {
            if (!accounts?.length) {
                return undefined;
            }
            return accounts.map(acc => acc.currency);
        },
        [accounts]
    );


    if (!activeClient && !activeClientIsLoading) return (
        <h2 style={{textAlign: 'center'}}><XpTranslated trKey={"currencyDashboard.header.noClient"}/></h2>
    )

    return (
        <div>
            <div className='currencyDashboardTitle'>
                <img src={'/static/images/bank_logo_1.png'} alt={"bank logo"} style={{height: '42px', position: 'absolute', top: '10px'}}/>
                <div style={{position: 'absolute', top: '-5px', right: '-5px', display: 'flex', alignItems: 'center'}}>
                    {(activeClient?.legalEntities && activeClient?.legalEntities[0]) &&
                    <EprConnectStatusButton
                        erpIntegrationId={activeClient?.legalEntities[0].erpIntegrationId}
                        businessObjectName={activeClient?.legalEntities[0].name}
                    />}
                    {availableCurrencies &&
                    <XpHomeCurrencySelector forceDisplay availableCurrencies={availableCurrencies}/>}
                    <XpNumberFormatMenu/>
                    <XpLanguageSelector/>
                </div>

                <h2><XpTranslated trKey={'currencyDashboard.header.header'}/></h2>
            </div>
            {activeClientIsLoading ? <LoadingDataSpinner trKey='currencyDashboard.header.clientDataIsLoading'/> :
            <div className='currencyDashboardHeader'>
                <div style={{display: 'flex', width: '100%', marginBottom: '-30px'}}>
                    <CurrencyDashboardBalancePieChart size={meterSize}/>

                    {/*
                <div style={{width: '20%', minWidth: '250px', maxWidth: '400px'}} ref={nameAndKeyValuesRef}>
                    {getTotalLiquidity()}
                </div>
*/}
                    {/*
                <div style={{width: accountWidgetsWidthStr}}>
                    <CurrencyDashboardAccounts/>
                </div>
*/}
                    <div style={{width: 'calc(100% - ' + (2 * meterSize) + 'px)', paddingTop: '30px'}}>
                        <CurrencyDashboardAccounts/>
                    </div>
                    <CurrencyDashboardExposurePieChart size={meterSize}/>
                    {/*<CurrencyDashboardButtons/>*/}
                </div>
            </div>}
        </div>
    )
}
export default CurrencyDashboardHeader
// <h4 style={{width: '30%', textAlign: 'right'}}>{activeClient.name}</h4>

function DevMenuWidget(props) {
    const openUserMessages       = useSelector(openUserMessagesSelector);
    const confirmAllUserMessagesDisabled = openUserMessages ? (openUserMessages.length) === 0 : true;

    const dispatch = useDispatch();
    return (
        <div style={{display: 'flex', width: '400px', background: 'white'}}>
            <div style={{marginRight: '-10px'}}>
                <DevMenu/>
            </div>
            <div style={{marginRight: '-10px'}}>
                <XpSearchableClientSelector/>
            </div>
            <div style={{marginRight: '-14px'}}>
                <NavDropdown
                    title={<XpUserIcon/>}
                    id="user-nav-dropdown"
                    alignRight
                >
                    <LinkContainer to='/userSettings'>
                        <NavDropdown.Item><XpTranslated trKey={'topMenu.userSettings'}/></NavDropdown.Item></LinkContainer>

                    <XpTooltip trKey={confirmAllUserMessagesDisabled ? 'topMenu.confirmAllUserMessages.tooltipDisabled' : 'topMenu.confirmAllUserMessages.tooltipActive'}>
                        <div>
                            <NavDropdown.Item
                                key = 'confirmAllUserMessages'
                                onClick={confirmAllUserMessagesDisabled ? undefined : () => dispatch(confirmAllUserMessages())}
                                disabled={confirmAllUserMessagesDisabled}
                            >
                                <XpTranslated trKey={'topMenu.confirmAllUserMessages.menuItem'}/>
                            </NavDropdown.Item>
                        </div>
                    </XpTooltip>
                </NavDropdown>
            </div>
            <div style={{marginRight: '-18px'}}>
                <XpLanguageSelector/>
            </div>
            <div style={{marginRight: '-10px'}}>
                <XpNumberFormatMenu/>
            </div>
        </div>
    )
}

//         <div style={{textAlign: 'right', justifyContent: 'flex-end', display: 'flex', marginTop: '-10px', minWidth: props.rightColumnMinWidth}}>
