import React from "react";

export function SetViewCheckbox ({viewState, setState, label}) {
    return (
        <div
            style={{display: "inline-block", verticalAlign: "top"}}
            onClick = {
                () => setState(!viewState)
            }
        >
            <div className="checkboxTdColumn" style={{display: "inline-block"}}>
                <input
                    type="checkbox"
                    checked={viewState}
                    readOnly
                    // onChange={event => setState(event.target.checked)}
                />
            </div>
            <label className="labelCheckbox">
                {label}
            </label>
        </div>
    )
}
