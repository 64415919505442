import {
    createFlatAuditFields,
    translateDateFields,
    xpColCreate
} from "gui-common/xpGrid/xpGridFunctions";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {accountNumberParser} from "gui-common/xpForm/formComponentsFunctions";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";

export function formatFxRejectConfigGridData(dataArray, currentLanguage, translate) {
    for (let item of dataArray) {
        translateDateFields(item, 'lll', currentLanguage);
    }

    return dataArray;
}

function getFieldModel(object, field) {
    return (object.id ? object.id : object.newId) + field;
}

export function prepareFxRejectConfigGridColumns(translate, hiddenState, userCanUpdate) {
    let subArray = [];

    subArray.push({
        colId: 'dragHandle',
        field: 'matchOrderIndex',
        hide: false,
        minWidth: 30,
        maxWidth: 30,
        headerName: "",
        rowDrag: userCanUpdate && true,
        suppressMenu: true,
        lockPosition: true,
        lockVisible: true,
    })
    xpColCreate(translate, subArray, hiddenState, 'matchOrderIndex',    50 , 50, 'fxRejectConfig.list.matchOrderIndex.label', {
        lockPosition: true,
        cellEditor: 'xpGridFormInputEditor',
        cellRenderer: 'xpGridFormInputRenderer',
        cellRendererParams: {
            getFieldModel: object => getFieldModel(object, '.matchOrderIndex'),
            xpFormComponent: XpFormTextInput,
            xpFormComponentProps: {
                alignRight: true,
            }
        },
        editable: false,
    });
    xpColCreate(translate, subArray, hiddenState, 'autoGeneratedT',     50 , 50, 'fxRejectConfig.list.autoGenerated.label', {
        cellRenderer: 'xpTranslatedRenderer'
    });
    xpColCreate(translate, subArray, hiddenState, 'rejectCode',         50 , 50, 'fxRejectConfig.list.rejectCode.label', {
        cellEditor: 'xpGridFormInputEditor',
        cellRenderer: 'xpGridFormInputRenderer',
        cellRendererParams: {
            fieldTemplate: "fxRejectConfig.form.rejectCode",
            getFieldModel: object => getFieldModel(object, '.rejectCode'),
            xpFormComponent: XpFormTextInput,
            xpFormComponentProps: {
                parser: value => accountNumberParser(value),
                isRequired: true,
                alignRight: true,
                maxLength: 3,
            }
        },
        editable: (userCanUpdate === true),
    });
    xpColCreate(translate, subArray, hiddenState, 'matchText',          300, 50, 'fxRejectConfig.list.matchText.label', {
        cellEditor: 'xpGridFormInputEditor',
        cellRenderer: 'xpGridFormInputRenderer',
        cellRendererParams: {
            fieldTemplate: "fxRejectConfig.form.matchText",
            getFieldModel: object => getFieldModel(object, '.matchText'),
            xpFormComponent: XpFormTextInput,
            xpFormComponentProps: {
                maxLength: 200,
            }
        },
        editable: (userCanUpdate === true),
    });
    xpColCreate(translate, subArray, hiddenState, 'retryExecution',     120 , 50, 'fxRejectConfig.list.retryExecution.label', {
        // cellRenderer: 'checkboxRenderer',
        // cellEditor: 'checkboxEditor',
        cellEditor: 'xpGridFormInputEditor',
        cellRenderer: 'xpGridFormInputRenderer',
        maxWidth: 150,
        cellRendererParams: {
            getFieldModel: object => getFieldModel(object, '.retryExecution'),
            xpFormComponent: XpFormSwitchInput,
            enforceEditFrame: true,
            xpFormComponentProps: {
            }
        },
        editable: (userCanUpdate === true)
    });
    xpColCreate(translate, subArray, hiddenState, 'errorResponseText',  200, 50, 'fxRejectConfig.list.errorResponseText.label', {
        cellEditor: 'xpGridFormInputEditor',
        cellRenderer: 'xpGridFormInputRenderer',
        cellRendererParams: {
            getFieldModel: object => getFieldModel(object, '.errorResponseText'),
            xpFormComponent: XpFormTextInput,
            xpFormComponentProps: {
                maxLength: 100,
            }
        },
        editable: (userCanUpdate === true)
    });
    xpColCreate(translate, subArray, hiddenState, 'originalReasonText', 200, 50, 'fxRejectConfig.list.originalReasonText.label');
    subArray = [...subArray, ...createFlatAuditFields(hiddenState, translate)];

    return subArray;
}

function transformFxRejectConfigItem(item) {
    item.id = item.id ? item.id : null;
    item.matchText = item.matchText ? item.matchText : null;
    item.autoGenerated = item.autoGenerated ? item.autoGenerated : false;
    item.originalReasonText = item.originalReasonText ? item.originalReasonText : "";
    item.matchOrderIndex = item.matchOrderIndex ? item.matchOrderIndex : null;
    item.retryExecution = item.retryExecution ? item.retryExecution : false;
    item.errorResponseText = item.errorResponseText ? item.errorResponseText : "";
    item.name = item.name ? item.name : item.rejectType + '-' + item.rejectCode + '-' + item.matchText;
    return item;
}

export function fxRejectConfigTransformer(configObject) {
    // if (!configObject.rejectType) return null;
    if (configObject.configArray) {
        return {
            ...configObject,
            configArray: configObject.configArray.map(item => transformFxRejectConfigItem(item))
        };
    }
    else {
        return (transformFxRejectConfigItem(configObject))
    }
}

export function createFxRejectConfigBody(configObject) {
    if (!configObject.rejectType) return null;
    const returnObject = {
        rejectType: configObject.rejectType,
        configArray: configObject.configArray.map(item => {
            return {
                id: (item.id && (item.id > 0)) ? item.id : undefined,
                rejectType: configObject.rejectType,
                rejectCode: item.rejectCode,
                matchText: item.matchText,
                autoGenerated: item.autoGenerated,
                originalReasonText: item.originalReasonText,
                matchOrderIndex: item.matchOrderIndex,
                retryExecution: item.retryExecution,
                errorResponseText: item.errorResponseText,
            }
        })
    };
    return returnObject;
}
