import React from "react";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import RunTimesTable, {
} from "xpool-gui-common/appConfig/runConfiguration/RunTimesTable";
import {useXpFormContext} from "gui-common/xpForm/core/xpFormHooks";
import XpFormCheckboxInput from "gui-common/xpForm/core/XpFormCheckboxInput";
import RunTypeActivationForRun from "xpool-gui-common/appConfig/runConfiguration/RunTypeActivationForRun";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";

function DailyRunForm (props) {
    const formContext = useXpFormContext()

    function fieldIsDisabled() {return props.disabled}
    return (
        <div>
            <RunTypeActivationForRun {...props}/>
            <div style={{marginBottom: '-5px'}}><label><XpTranslated trKey={formContext.formTemplate +'.runPoolOnWeekdays'}/></label></div>
            <div style={formContext.useMuiFieldStyle ? {marginBottom: '3px'} : undefined}>
                {['runOnMondays','runOnTuesdays','runOnWednesdays','runOnThursdays','runOnFridays'].map((field, index) => (
                    <div style={{display: 'inline-block'}} key = {index}>
                        <XpFormCheckboxInput
                            // placeBeforeTextUnderLabel
                            field        = {field}
                            disabled     = {fieldIsDisabled()}
                        />
                    </div>
                ))}
            </div>

            {/*<label><XpTranslated trKey={'clientForm.runTimes'}/></label>*/}
            <RunTimesTable {...props} disabled={fieldIsDisabled()}/>

        </div>
    )
}
DailyRunForm.propTypes = {
};
export default DailyRunForm
