import React from 'react'

function IeNotSupported() {
    return (
        <div>
            <h2 style={{textAlign: 'center'}}>
                Internet Explorer is not supported by xpool.
            </h2>
            <p> </p>
            <h2 style={{textAlign: 'center'}}>
                Xpool supports Chrome, Firefox and Edge.
            </h2>
        </div>
    )
}
export default IeNotSupported;


