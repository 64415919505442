import React, {useState} from 'react';
import ReactModal from "react-modal";
import PropTypes from 'prop-types';
import FocusLock from "react-focus-lock";
import {useDispatch, useSelector} from "react-redux";
import {sharedSettingsSelector, userSelector} from "gui-common/orm/ormSelectors";
import {checkAndConfirmDuplicate} from "gui-common/userSettings/sharedSettings/savedUserStateFunctions";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {modalStandardParams} from "gui-common/modals/modalConstants";


function ModalSaveSettings ({resultTypes: { MODAL_TYPE_CONFIRM }, resultTypes: { MODAL_TYPE_CANCEL }, popModalWindow, showSaveOptions, defaultSaveOption})  {

    const [settingName, setSettingName ] = useState("");
    const [saveShared,  setSaveShared  ] = useState((defaultSaveOption === true));
    const sharedUserStates               = useSelector(sharedSettingsSelector);
    const savedUserStates                = useSelector(state => state.savedUserStatesState.savedSettings);
    const user                           = useSelector(state => userSelector(state));
    const dispatch = useDispatch();

    function confirmAction() {
        const checkArray      = saveShared ? sharedUserStates : savedUserStates;
        checkAndConfirmDuplicate(checkArray, settingName, dispatch, user, saveShared)
            .then(result => {
                if (result.decision === "cancel") return; // Do not pop modal window
                popModalWindow({ status: MODAL_TYPE_CONFIRM, settingName: settingName, saveShared: saveShared, decision: result.decision, duplicate: result.duplicate});
            });
    }

    function cancelAction() {
        popModalWindow({ status: MODAL_TYPE_CANCEL });
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Save settings dialog"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalStandardParams}
                >
                    <div xp-test-id="xpModalSaveSettings">
                        <h3 className ="xpoolModalHeader">
                            <XpTranslated trKey='savedUserStates.saveSettingsModal.header'/>
                        </h3>
                        <hr/>
                        <p className="valueTextDark">
                            <XpTranslated trKey={showSaveOptions ? 'savedUserStates.saveSettingsModal.messageWithOption' : 'savedUserStates.saveSettingsModal.message'}/>
                        </p>
                        <hr/>
                        <div>
                            <label className="inlineLabel">
                                <XpTranslated trKey='savedUserStates.saveSettingsModal.nameLabel'/>
                            </label>
                            <input autoFocus className="inlineField" type="text" value={settingName} onChange={event => setSettingName(event.currentTarget.value)} />
                        </div>
                        {(showSaveOptions) &&
                        <div>
                            <input
                                type="checkbox"
                                checked={saveShared}
                                onChange={e => setSaveShared(e.target.checked)}
                            />
                            <label className="labelCheckbox">
                                <XpTranslated trKey='savedUserStates.saveLocalCheckbox'/>
                            </label>
                        </div>}

                        <div className="xpoolModalButtons" >
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonConfirm"}
                                labelKey        = {saveShared ? 'savedUserStates.saveSettingsModal.saveSharedButton' : 'savedUserStates.saveSettingsModal.saveLocalButton'}
                                onClickCallback = {() => confirmAction()}
                                disabled        = {(settingName === "")}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                labelKey        = {'general.buttons.cancel'}
                                onClickCallback = {() => cancelAction()}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}


ModalSaveSettings.propTypes = {
    headerText        : PropTypes.string.isRequired,
    messageText       : PropTypes.string.isRequired,
    cancelText        : PropTypes.string,
    confirmText       : PropTypes.string,
    showSaveOptions   : PropTypes.bool,
    defaultSaveOption : PropTypes.bool,
    resultTypes       : PropTypes.object,
    popModalWindow    : PropTypes.func.isRequired,
};

export default ModalSaveSettings
