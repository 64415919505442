import React, {Component} from "react";
import {connect} from "react-redux";
import {Portal} from 'react-portal';
import ContextMenu from "gui-common/contextMenu/ContextMenu";

import IntegrationStatusItemMenu from "gui-common/integrationStatus/IntegrationStatusItemMenu";
import StandardContextMenu from "gui-common/contextMenu/StandardContextMenu";

const selectContextMenu = state => state.contextMenuState;

const menuTypes = {
    IntegrationStatusItemMenu,
    StandardContextMenu
};


export function contextMenuManagerMapState(state) {
    return {
        contextMenu : selectContextMenu(state)
    };
}


export class ContextMenuManager extends Component {

    render() {
        const {contextMenu} = this.props;
        const {show, location, type, menuArgs = {}} = contextMenu;

        let menu = null;

        if(show) {
            let MenuComponent = menuTypes[type];

            if(MenuComponent) {
                menu = (
                    <Portal isOpened={true}>
                        <ContextMenu location={location} >
                            <MenuComponent {...menuArgs} />
                        </ContextMenu>
                    </Portal>
                )
            }
        }

        return menu;
    }
}

export default connect(contextMenuManagerMapState)(ContextMenuManager);
