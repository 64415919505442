import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Resizable} from "re-resizable";

function XpResizable ({isDisabled, className, classNameActive, classNameDisabled, classNameManaged, defaultHeight, children, setHeight, setHeightCallback, setManaged, hideDragHandle}) {

    const [heightState, setHeightState] = useState(defaultHeight);
    const [isManaged, setIsManaged]     = useState((setManaged !== undefined) ? setManaged : false);

    useEffect(
        () => {
            if (!setHeight || !setHeightCallback) return;

            if (!isManaged && (setHeight !== heightState)) {
                setHeightState(setHeight);
                // setHeightCallback(setHeight);
            }

            if ((setManaged !== undefined) && (isManaged !== setManaged)) setIsManaged(setManaged);
        },
        [setHeight, isManaged, setManaged],
    );

    let extraProps = {};
    if (setHeight && setHeightCallback) {
        extraProps.size={ width: '100%', height: !isManaged ? setHeight : heightState };
        extraProps.onResizeStop = (e, direction, ref, d) => {
            const newHeight = heightState + d.height;
            if (setManaged === undefined) setIsManaged(true);
            setHeightState(newHeight);
            setHeightCallback(newHeight);
        };
    }

    function getHandleClassName() {
        let base = "xpHorizontalHandle";
        if (isManaged)  return classNameManaged  ? classNameManaged  : base + " xpResizableHandleManaged " ;
        if (isDisabled) return classNameDisabled ? classNameDisabled : base + " xpResizableHandleDisabled ";
        return classNameActive ? classNameActive : base + " xpResizableHandle ";
    }

    return (
            <Resizable
                className={className}
                {...extraProps}
                defaultSize={{
                    width :'100%',
                    height: defaultHeight,
                }}
                enable={{ top:false, right:false, bottom:!isDisabled, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                handleStyles={{bottom: {
                        position: 'absolute',
                        display:  hideDragHandle ? 'none' : undefined,
                        userSelect: 'none',
                        height: '12px',
                        width: '100%',
                        // top: '0px',
                        // bottom: '-6px',
                        cursor: 'row-resize'
                    }}}
                handleClasses={{bottom: getHandleClassName()}}
            >
                <div style={{paddingBottom: '6px', height: '100%'}}>
                    {children}
                </div>
            </Resizable>
    )
}
XpResizable.propTypes = {
    defaultHeight     : PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    classNameActive   : PropTypes.string,
    classNameDisabled : PropTypes.string,
    setSize           : PropTypes.number,
    isDisabled        : PropTypes.bool,
};
export default XpResizable



