import React, {Component} from "react";

export class CheckboxRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }
    refresh(params) {
        if(params.value !== this.state.value) {
            this.setState({
                value: params.value
            })
        }
        return true;
    }
    render () {
        return (
            <div>
                <div style={{opacity: 0, background: 'white', position: 'absolute', top: '0', right: '0', bottom: '0', left: '0', height: '30px', width: '100%'}}/>
                <input type="checkbox" defaultChecked={this.state.value} disabled/>
            </div>
        )
    }
}
