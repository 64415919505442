import {getDefaultObject} from "gui-common/functions/functions";

export function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export function populateDefaults(formString, propObject, setFunction){
    Object.entries(propObject).map(obj => setFunction(formString + '.' + obj[0], obj[1]));
}

export function findAndSetDefaultId(fieldArray) {
    const defaultItem = getDefaultObject(fieldArray);
    return defaultItem ? String(defaultItem.id) : "";
}
