import React, {Component} from "react";

export class CheckboxEditor extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            value: props.value,
            gridApi: props.api,
        }
    }
    afterGuiAttached() {
        // get ref from React component
        const eInput = this.refs.checkboxInput;
        eInput.focus();
    }
    getValue() {
        return this.state.value;
    }
    isPopup() {
        return false;
    }
    onChange() {
        this.setState({
            value: !this.state.value
        },
//            () => this.props.api.stopEditing()
        );
    }
    render() {
        return (
            <input ref="checkboxInput" type="checkbox" onChange={this.onChange} checked={this.state.value}/>
        );
    }
}
