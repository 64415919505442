import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import React from "react";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {userLoginStates} from "gui-common/user/userConstants";

export function prepareRightsUserGridColumns(translate, hiddenState) {
    let subArray = [];
    xpColCreate(translate, subArray, hiddenState, 'id', 60, 30, 'rightsUserList.headers.id');
    xpColCreate(translate, subArray, hiddenState, 'countryCode', 60, 30, 'rightsUserList.headers.countryCode');
    xpColCreate(translate, subArray, hiddenState, 'firstName', 120, 60, 'rightsUserList.headers.firstName');
    xpColCreate(translate, subArray, hiddenState, 'lastName', 120, 60, 'rightsUserList.headers.lastName');
    xpColCreate(translate, subArray, hiddenState, 'profilesStrT', 120, 60, 'rightsUserList.headers.profiles', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'stateT', 80, 50, 'rightsUserList.headers.state', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: userLoginStates.map(item => 'rightsUserList.userStates.' + item)
        },
        enableRowGroup: true, cellStyle: function (params) {
            if (!params.data) return;
            let outStyle = {background: 'none'};
            if (params.data.state === "Active") outStyle = {background: '#C7EFCA'};
            return outStyle;
        }
    });
    xpColCreate(translate, subArray, hiddenState, 'socialSecurityNumber', 120, 60, 'rightsUserList.headers.socialSecurityNumber');
    return subArray;
}

export function renderUserProfiles(user) {
    if (!user || !user.profiles || !user.profiles.length) return <XpTranslated trKey='userPreferencesForm.noProfilesAssigned'/>
    return user.profiles.map(item => <div key={item}>{item}</div>)
}

export function buildUserProfilesString(user, translate) {
    if (!user || !user.profiles || !user.profiles.length) return translate('userPreferencesForm.noProfilesAssigned');

    let returnString = ""
    for (let profile of user.profiles) returnString += "\n" + profile;
    return returnString;
}
