import {createSelector} from "reselect";
import {useSelectorInstance} from "gui-common/functions/hooks";

export const getXpTabStateSelector = () => createSelector(
    [
        state => state.xpTabsState,
        (state, props) => props.instanceId
    ],
    (xpTabsState, instanceId) => {
        if (!xpTabsState) return undefined;
        if (!instanceId) return undefined;
        return xpTabsState[instanceId];
    }
);

export function useXpTabSelectedIndex(instanceId) {
    return useSelectorInstance(getXpTabStateSelector, {instanceId: instanceId});
}
