import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {
    requestReloadBalanceFromApi,
    requestResetLedgerAccountsFromApi
} from "features/requestReloadBalance/requestReloadBalanceApi";
import {
    userCanDelete,
    userCanRegisterBalance,
    userCanRunPool,
    userCanUpdate
} from "gui-common/functions/userRights";
import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector, useStore} from "react-redux";
import {pushModalWindow} from "redux-promising-modals";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {selectIsDevMode, useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {workflowConfig} from "appConfig/runState/runStateConstants";
import {requestRunStateToApi} from "gui-common/runState/runStateApi";
import {useSelectorInstanceRef} from "gui-common/functions/hooks";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {CONFIRMATION_DIALOG, INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {REGISTER_BALANCE_DIALOG, REGISTER_BALANCE_MOVEMENT_DIALOG} from "appConfig/appModals";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {pushDelayedModal} from "gui-common/functions/thunks";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import {
    bankAccountHasActiveSweepOrTopUpRunConfiguration,
    prepareAccountGridColumns
} from "xpool-gui-common/features/bankAccount/bankAccountFunctions";
import {getAccountListDataSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import moment from "moment";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {getClientOrdersSelector} from "xpool-gui-common/features/order/orderSelectors";

const accountsHiddenState =  {
    id                  : true,
    name                : false,
    currencyT           : false,
    integrationType     : true,
    accountType         : false,
    legalOwnerName      : false,
    businessUnitName    : true,
    description         : true,
    institutionName     : true,
    clearingNumber      : true,
    accountNumber       : true,
    bic                 : true,
    iban                : true,
    barRunStateT        : false,
    barNextRunTime      : false,
    ledgerBalance       : false,
    ledgerBalanceTime   : true,
    pendingBalance      : false,
    postPoolingBalance  : false,
    aggregatedBalance   : true,
    aggregatedPostPoolingBalance: true,
    creditTypeT         : false,
    availableCredit     : false,
    createdDateTime     : true,
    createdByUser       : true,
    changedDateTime     : true,
    changedByUser       : true,
};


// AccountsList.detailIcon = 'assignment';
// AccountsList.detailIconToolTipKey = 'accountsList.detailIconToolTipKey';
export default function AccountsList (props) {

    const ormActiveClient = useSelector(shallowActiveClientSelector);
    const requestStateRef = useSelectorInstanceRef(getRequestingStateOnModelSelector, {model: 'RunState'});

    const balanceMovementsConfig = useAppEnvProperty('balanceMovementsConfig');

    const enableLedgerAccountReset = useAppEnvProperty('enableLedgerAccountReset');

    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const devMode   = useSelector(selectIsDevMode);
    const dispatch  = useDispatch();
    const store     = useStore();

    const gridCallbacks = {
        gridRowClicked          : useCallback(
            (params) => {
                if (props.suppressRowClickSelection) {
                    dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'accountsList.cantSelectWhenInEditOrNew'}));
                }
            }, [props.suppressRowClickSelection]),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!props.itemSelectedCallback) return;
                props.itemSelectedCallback(data);
            },
            [props.itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];
                let menuItems = [];

                if ((props.instanceId !== "cashPoolAccountList") && params.node && userCanUpdate(params.node.data)) menuItems.push({
                    name: translateRef.current('accountsList.gridContextMenuItems.editItem'),
                    action: function () {
                        if (this.context.callingProps.xpAdminFormSetEditCallback) this.context.callingProps.xpAdminFormSetEditCallback();
                    },
                    context: {callingProps: props, params: params, dispatch: dispatch},
                    disabled: params.node ? props.suppressRowClickSelection : true,
                })

                if ((props.instanceId !== "cashPoolAccountList") && params.node && userCanDelete(params.node.data)) menuItems.push({
                    name: translateRef.current('accountsList.gridContextMenuItems.disableItem'),
                    action: function () {
                        if (this.context.callingProps.xpAdminFormDisableCallback) this.context.callingProps.xpAdminFormDisableCallback();
                    },
                    context: {callingProps: props, params: params, dispatch: dispatch},
                    disabled: params.node ? props.suppressRowClickSelection : true,
                });

                if ((props.instanceId === "cashPoolAccountList") && params.node && userCanRegisterBalance(params.node.data)) menuItems.push({
                    name: translateRef.current('accountsList.gridContextMenuItems.registerBalance'),
                    action: function () {
                        // Timeout required to get focus to land in dialogue. Propable competition between context menu item close from grid.
                        setTimeout(() => dispatch(pushModalWindow(REGISTER_BALANCE_DIALOG, {account: this.context.params.node.data, displayState: XP_FORM_NEW})), 50);
                    },
                    context: {callingProps: props, params: params, dispatch: dispatch},
                });

                if (enableLedgerAccountReset && (props.instanceId === "cashPoolAccountList") && params.node && userCanRegisterBalance(params.node.data)) menuItems.push({
                    name: translateRef.current('accountsList.gridContextMenuItems.resetBalances.menuItem'),
                    action: function () {
                        if (bankAccountHasActiveSweepOrTopUpRunConfiguration(params.node.data)) {
                            dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'accountsList.gridContextMenuItems.resetBalances.activeSweepOrTopUpModal'}));
                            return;
                        }
                        const activeOrders = getClientOrdersSelector()(store.getState(), {clientId: ormActiveClient?.id}).filter(order => order.state !== "IN_BALANCE");
                        if (activeOrders?.length) {
                            dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'accountsList.gridContextMenuItems.resetBalances.activeOrdersNotAllowedModal'}));
                            return;
                        }
                        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'accountsList.gridContextMenuItems.resetBalances.confirmModal', modalParams: {accountName: params.node.data.name}}))
                            .then(({status}) => {
                                if (status === MODAL_TYPE_CONFIRM) {
                                    dispatch(requestResetLedgerAccountsFromApi(params.node.data));
                                }
                            });
                    },
                    context: {callingProps: props, params: params, dispatch: dispatch},
                });

                if (devMode) menuItems.push({
                    name: translateRef.current('accountsList.gridContextMenuItems.requestBalance'),
                    action: function () {
                        const node = this.context.params.node;
                        this.context.dispatch(requestReloadBalanceFromApi(node.data));
                    },
                    context: {callingProps: props, params: params, dispatch: dispatch},
                    disabled: (ormActiveClient && workflowConfig.ClientWorkflow.isRunning(ormActiveClient.cashPoolsRunState)),
                });





                if (userCanRunPool(ormActiveClient)) {

                    const latestToCancel = params.node.data?.barRunStateParams?.latestToCancel;
                    const latestToResume = params.node.data?.barRunStateParams?.latestToResume;
                    const restartAllowed = ["FAILED", "COMPLETED"].includes(params.node.data?.barRunState);

                    menuItems.push({
                        name: translateRef.current('accountsList.gridContextMenuItems.resumeAutoRun'),
                        action: function() {
                            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'accountsList.resumeAutoRun.confirmModal', modalParams: {accountName: params.node.data.name}}))
                                .then(({status}) => {
                                    if (status === MODAL_TYPE_CONFIRM) {
                                        dispatch(requestRunStateToApi(params.node.data.barRunStateObject, 'INITIAL', ['INITIAL', 'BEFORE_EXECUTION']));
                                    }
                                });
                        },
                        disabled: (params.node.data?.barRunState !== "CANCELLED") || (latestToResume && moment().isAfter(moment(latestToResume)))
                    });

                    menuItems.push({
                        name: translateRef.current('accountsList.gridContextMenuItems.cancelAutoRun'),
                        action: function() {
                            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'accountsList.cancelAutoRun.confirmModal', modalParams: {accountName: params.node.data.name}}))
                                .then(({status}) => {
                                    if (status === MODAL_TYPE_CONFIRM) {
                                        dispatch(requestRunStateToApi(params.node.data.barRunStateObject, 'CANCELLED', undefined));
                                    }
                                });
                        },
                        disabled: !['INITIAL', 'BEFORE_EXECUTION', 'WAIT_FOR_RETRY'].includes(params.node.data?.barRunState) || (latestToCancel && moment().isAfter(moment(latestToCancel)))
                    });

                    menuItems.push({
                        name: translateRef.current('accountsList.gridContextMenuItems.restartAutoRun'),
                        action: function() {
                            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'accountsList.restartAutoRun.confirmModal', modalParams: {accountName: params.node.data.name}}))
                                .then(({status}) => {
                                    if (status === MODAL_TYPE_CONFIRM) {
                                        dispatch(requestRunStateToApi(params.node.data.barRunStateObject, 'RESTART', ['INITIAL', 'BEFORE_EXECUTION']));
                                    }
                                });
                        },
                        disabled: !(restartAllowed && (latestToResume && moment().isBefore(moment(latestToResume))))
                    });
                }

                if ((props.instanceId === "cashPoolAccountList") && params.node && balanceMovementsConfig?.allowManualRegistration) menuItems.push({
                    name: translateRef.current('accountsList.gridContextMenuItems.registerBalanceMovement.PAYABLE'),
                    action: function () {
                        dispatch(pushDelayedModal(REGISTER_BALANCE_MOVEMENT_DIALOG, {account: this.context.params.node.data, type: 'PAYABLE'}));
                    },
                    context: {callingProps: props, params: params, dispatch: dispatch},
                });

                if ((props.instanceId === "cashPoolAccountList") && params.node && balanceMovementsConfig?.allowManualRegistration) menuItems.push({
                    name: translateRef.current('accountsList.gridContextMenuItems.registerBalanceMovement.RECEIVABLE'),
                    action: function () {
                        dispatch(pushDelayedModal(REGISTER_BALANCE_MOVEMENT_DIALOG, {account: this.context.params.node.data, type: 'RECEIVABLE'}));
                    },
                    context: {callingProps: props, params: params, dispatch: dispatch},
                });

                return menuItems;
            }, [translate, ormActiveClient, requestStateRef.current]),
        isRowMaster             : useCallback((params) => {return false}, []),
        rowClassRules: {},
    };

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('accountsList.noAccountsToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? true : props.fillAvailableSpace,
                groupDefaultExpanded    : -1,
                treeData                : !props.surpressTree,
                masterDetail            : true,
                ormModel                : 'BankAccount',
                getRowDataSelector      : getAccountListDataSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                autoGroupColumnDef      : {
                    headerName: translate('accountsList.name'),
                    headerComponentParams: {trKey: 'accountsList.name'},
                    width: 250,
                    minWidth: 100,
                    cellRendererParams: { suppressCount: true },
                    suppressCellFlash: true,
                    cellStyle: function(params) {
                        if (params.data.isSelectedByOrder) {
                            return {background: 'var(--xpool-grid-selected-order-background-color)'}
                        }
                        else {
                            return {background: 'none'}
                        }
                    },
                    valueGetter: (params) => {
                        return params.data.paddedName;
                    }
                },
            };
        },
        [translate, props.fillAvailableSpace, props.rowDataSelectorProps]
    );

    const colDefs = useMemo(
        () => {
            let hiddenStateToUse = props.hiddenState ? props.hiddenState : accountsHiddenState;
            if (!props.surpressTree) hiddenStateToUse.name = undefined;
            return prepareAccountGridColumns(translate, hiddenStateToUse);
        },
        [translate, props.hiddenState, props.surpressTree]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={props.gridId ? props.gridId : "accountsGrid" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                // rowData={rowData}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
                suppressRowClickSelection={props.suppressRowClickSelection} // This must be a direct propagation of prop since the memoized grid options will no be updated by this prop.
            >
            </XpGrid>
        </div>
    );
}
