import React from 'react'
import Wizard from 'gui-common/wizard/Wizard';
import ClientFileLoad from "features/onboardingWizard/ClientFileLoad";

function OnboardingWizardPage(props) {

    return (
        <div xp-test-id="onBoardingWizardMainDiv">
            <Wizard wizardName = 'onboardingWizard'/>
            <ClientFileLoad/>
        </div>
    )
}
export default OnboardingWizardPage
