import {ormEntityCreated, ormEntityDeleted, ormEntityRefreshed, ormEntityUpdated} from "gui-common/orm/ormReducer";

export const ormFunctionMap = {
    'se.nordicfc.common.message.functions.Create'  : {batchPossible: true, ormEventType: 'create', functionToDispatch: (itemType, item) => ormEntityCreated(itemType, item)},
    'se.nordicfc.common.message.functions.Update'  : {batchPossible: true, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, item)},
    'se.nordicfc.common.message.functions.Refresh' : {batchPossible: true, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityRefreshed(itemType, item)},
    'se.nordicfc.common.message.functions.Delete'  : {batchPossible: true, ormEventType: 'delete', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, item)},
};
export const ormFunctionMapDeleted = {
    'se.nordicfc.common.message.functions.Create'  : {batchPossible: true, ormEventType: 'create', functionToDispatch: (itemType, item) => ormEntityCreated(itemType, item)},
    'se.nordicfc.common.message.functions.Update'  : {batchPossible: true, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, item)},
    'se.nordicfc.common.message.functions.Refresh' : {batchPossible: true, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityRefreshed(itemType, item)},
    'se.nordicfc.common.message.functions.Delete'  : {batchPossible: true, ormEventType: 'delete', functionToDispatch: (itemType, item) => ormEntityDeleted(itemType, item)},
};
