import PropTypes from "prop-types";
import React from "react";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {
    timeEarlierOrSameThanParameter,
    timeEarlierThanParameter, timeLaterOrSameThanParameter,
    timeLaterThanParameter
} from "gui-common/xpForm/formComponentsValidators";
import XpFormTimeInput from "gui-common/xpForm/core/XpFormTimeInput";
import {checkConflictWithOtherElements} from "gui-common/runConfiguration/runConfigurationFunctions";
import {useXpFormContext} from "gui-common/xpForm/core/xpFormHooks";


function monthlyRunAndDifferentDays(val, checkConfig, myself, type) {
    if (type !== 'monthlyRun') return false;
    if (!myself) return false;

    if (checkConfig.runType === 'NO_RUN') return true; // Do not check run times in NO_RUN
    if (myself.runType      === 'NO_RUN') return true; // Do not check run times in NO_RUN
    if (checkConfig.dayType !== myself.dayType) return true;
    if ((checkConfig.dayType === 'DAY') && (Number(checkConfig.runDay) !== Number(myself.runDay)) ) return true;
    return false;
}

function dailyRunAndDifferentDays(val, checkConfig, changedField, myself, type) {
    if (type !== 'dailyRun') return false;
    if (!myself) return false;

    for (let dayField of ['runOnMondays','runOnTuesdays','runOnWednesdays','runOnThursdays','runOnFridays']) {
        if (!checkConfig[dayField]) continue;
        if (myself[dayField]) return false; // Otherwise, check the form
    }
    return true;
}

export const periodIncludesOtherPeriod = (val, checkConfig, myself, type) => {
    if (!checkConfig.isActive) return false;
    if (monthlyRunAndDifferentDays(val, checkConfig, myself, type)) return false;
    if (dailyRunAndDifferentDays(  val, checkConfig, myself, type)) return false;

    if (timeEarlierOrSameThanParameter(myself.getBalancesTime, checkConfig.getBalancesTime) &&
        timeLaterOrSameThanParameter(  myself.stopTryingTime , checkConfig.stopTryingTime)) return true;

    return false;
}

export const timeWithinOtherPeriod = (val, checkConfig, myself, type) => {
    if (!checkConfig.isActive) return false;
    if (monthlyRunAndDifferentDays(val, checkConfig, myself, type)) return false;
    if (dailyRunAndDifferentDays(  val, checkConfig, myself, type)) return false;

    if (timeLaterThanParameter(val, checkConfig.getBalancesTime) && timeEarlierThanParameter(val, checkConfig.stopTryingTime)) return true;
}

function RunTimesTable (props) {
    const formContext   = useXpFormContext();

    function renderGetBalances(extraStyling){
        return (
            <XpFormTimeInput
                {...extraStyling}
                isRequired
                field="getBalancesTime"
                formTemplate={'runConfiguration.runTimesTable'}
                disabled={props.disabled}
                dependentFields={{
                    executeTime: 'executeTime',
                }}
                errors={{
                    laterThanExecute         : (val, fields) => timeLaterThanParameter(val, fields.executeTime),
                    timeInOtherPeriod        : (val) => checkConflictWithOtherElements(val, timeWithinOtherPeriod, props),
                    periodIncludesOtherPeriod: (val) => checkConflictWithOtherElements(val, periodIncludesOtherPeriod, props)
                }}
            />
        )
    }
    function renderExecute(extraStyling) {
        return (
            <XpFormTimeInput
                {...extraStyling}
                isRequired
                field="executeTime"
                formTemplate={'runConfiguration.runTimesTable'}
                disabled={props.disabled}
                dependentFields={{
                    getBalancesTime: 'getBalancesTime',
                    stopTryingTime : 'stopTryingTime',
                }}
                errors={{
                    earlierThanGetBalance : (val, fields) => timeEarlierThanParameter(val, fields.getBalancesTime),
                    laterThanStopTrying   : (val, fields) => timeLaterThanParameter  (val, fields.stopTryingTime),
                }}
            />
        )
    }
    function renderStopTrying(extraStyling) {
        return (
            <XpFormTimeInput
                {...extraStyling}
                isRequired
                field="stopTryingTime"
                formTemplate={'runConfiguration.runTimesTable'}
                disabled={props.disabled}
                dependentFields={{
                    executeTime: 'executeTime',
                }}
                errors={{
                    earlierThanExecution     : (val, fields) => timeEarlierOrSameThanParameter(val, fields.executeTime),
                    timeInOtherPeriod        : (val) => checkConflictWithOtherElements(val, timeWithinOtherPeriod, props),
                    periodIncludesOtherPeriod: (val) => checkConflictWithOtherElements(val, periodIncludesOtherPeriod, props)
                }}
            />
        )
    }

    if (formContext.useMuiFieldStyle) {
        return (
            <div className={'xpFormMultipleFieldsLine'}>
                <div style={{width: 'calc(33.3% - 6px)', marginRight: '10px'}}>{renderGetBalances()}</div>
                <div style={{width: 'calc(33.4% - 7px)', marginRight: '10px'}}>{renderExecute()}</div>
                <div style={{width: 'calc(33.3% - 7px)'                     }}>{renderStopTrying()}</div>
            </div>
        )
    }

    // Old styling in table view.
    const extraStyling = {noLabel: true, inTable: true}
    return (
        <div className='tableInForm'>
            <table style={{width: '100%'}}>
                <tbody>
                    <tr className="HeaderRow" key="header2">
                        <th style={{width: '33.33%'}} className="timeColumn"><label><XpTranslated trKey={'runConfiguration.runTimesTable.getBalancesTime.label'   }/></label></th>
                        <th style={{width: '33.33%'}} className="timeColumn"><label><XpTranslated trKey={'runConfiguration.runTimesTable.executeTime.label'       }/></label></th>
                        <th style={{width: '34.34%'}} className="timeColumn"><label><XpTranslated trKey={'runConfiguration.runTimesTable.stopTryingTime.label'    }/></label></th>
                    </tr>
                    <tr>
                        <td className="timeColumn">{renderGetBalances(extraStyling)}</td>
                        <td className="timeColumn">{renderExecute(extraStyling)}</td>
                        <td className="timeColumn">{renderStopTrying(extraStyling)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
RunTimesTable.propTypes = {
    className   : PropTypes.string,
};
export default RunTimesTable



