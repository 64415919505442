import PropTypes from "prop-types";
import React, {useMemo, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpFormStaticField from "gui-common/xpForm/core/XpFormStaticField";
import {XpDateTime} from "gui-common/components/XpDateTime";
import BankHolidaysDataList from "gui-common/bankHolidays/BankHolidaysDataList";
import {bankHolidayAdminFormConfig} from "gui-common/xpAdminForm/xpAdminFormConfig";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getXpGridStateSelector} from "gui-common/xpGrid/xpGridSelectors";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


export const bankHolidaysHiddenState = {
    id                  : true,
    name                : true,
    currencyName        : false,
    date                : false,
    description         : false,
    comment             : true,
    createdDateTime     : true,
    createdByUser       : true,
    changedDateTime     : true,
    changedByUser       : true,
    disabledDateTime    : true,
    disabledByUser      : true,
};

function AuditBulkBankHolidays (props) {

    const translate = useSelector(selectTranslateFunction);
    const dispatch  = useDispatch();
    const listState = useSelectorInstance(getXpGridStateSelector, {instanceId: 'bankHolidaysBulkAuditList'});

    const [variable,   setVariable]   = useState(null);

    useEffect(
        () => {},
        [],
    );

    const [operationType, listToDisplay] = useMemo(
        () => {

            if (props.auditEntry?.after?.bankHolidays?.length && props.auditEntry?.before?.bankHolidays?.length) {
                return ["Update", []];
            }
            if (props.auditEntry?.before?.bankHolidays?.length) {
                return ["Delete", props.auditEntry.before.bankHolidays];
            }
            if (props.auditEntry?.after?.bankHolidays?.length) {
                return ["Create", props.auditEntry.after.bankHolidays];
            }
            return "Error"
        },
        [props.auditEntry]
    );

    const selectedItem = useMemo(
        () => {
            if (!listState || !listToDisplay) return undefined;
            const selectedItem = listToDisplay.find(item => item.id === listState.selectedId);
            if (!selectedItem) return undefined;
            return {
                ...selectedItem,
                isDeleted: (operationType === "Delete"),
                isNew: (operationType === "Create"),
            }
        },
        [listState, listToDisplay]
    );



    return (
        <div>
            <div className='xpAdminFormEntityCard'>
                <div className="adminFormContainer">
                    <div className="adminColumn" style={{width: '330px'}}>
                        <XpFormStaticField inLineLayout labelTrKey='userPreferencesForm.userName'                                 value={props.auditEntry.user.firstName + ' ' + props.auditEntry.user.lastName}/>
                        <XpFormStaticField inLineLayout labelTrKey='userPreferencesForm.userId.label'                             value={props.auditEntry.user.userId}/>
                        <XpFormStaticField inLineLayout labelTrKey='bankHolidays.bulkAudit.operationType.header'                  value={<XpTranslated trKey={'bankHolidays.bulkAudit.operationType.' + operationType}/>}/>
                        <XpFormStaticField inLineLayout labelTrKey='bankHolidays.bulkAudit.operationSource.header'                value={<XpTranslated trKey={'bankHolidays.bulkAudit.operationSource.' + props.auditEntry.modifiedObject.reason}/>}/>
                        <XpFormStaticField inLineLayout labelTrKey='bankHolidays.bulkAudit.itemsIncluded'                         value={props.auditEntry.modifiedObject.numberOfItems}/>
                        <XpFormStaticField inLineLayout labelTrKey='auditUserActionTypes.actionDisplayHeader.requestedDateTime'   value={<XpDateTime isoString={props.auditEntry.auditDateTime} format='lll'/>}/>
                    </div>
                    <div style={{width: 'calc(100% - 720px)', minWidth: '300px', marginRight: '30px'}}>
                        <label><XpTranslated trKey={'bankHolidays.bulkAudit.itemsIncluded'} /></label>
                        <BankHolidaysDataList
                            instanceId            = {'bankHolidaysBulkAuditList'}
                            auditMode             = {true}
                            fillAvailableSpace    = {false}
                            bankHolidaysHiddenState = {bankHolidaysHiddenState}
                            rowDataSelectorProps  = {{
                                inputData     : listToDisplay, // Only defined when in audit mode
                            }}

                        />
                    </div>
                    {selectedItem &&
                    <div className="adminColumn" style={{width: '330px'}}>
                        <label><XpTranslated trKey={'bankHolidays.bulkAudit.selectedItem'} /></label>
                        <XpAdminForm
                            entityId              = {selectedItem.id}
                            currentData           = {selectedItem}
                            xpAdminFormInstanceId = 'bankHolidayForm'
                            xpAdminFormConfig     = {bankHolidayAdminFormConfig}
                            ormModel              = 'BankHoliday'
                            suppressAccordion     = {true}
                            hideDeleteButton      = {true}
                            auditMode             = {true}
                        />
                    </div>}

                </div>
            </div>
        </div>
    )
}
AuditBulkBankHolidays.propTypes = {
    beforeChangeData: PropTypes.object.isRequired,
    currentData     : PropTypes.object.isRequired,
    auditEntry      : PropTypes.object.isRequired
};
export default AuditBulkBankHolidays



