import React, {Component} from 'react';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

export default class XpGridGroupHeader extends Component {

    componentDidMount() {
    }
    getReactContainerStyle() {
        return {
            width: '100%'
        };
    }

    refresh(params) {
        return true;
    }

    getHeaderContent() {
        if (!this.props.trKey) return (
            <span className={"ag-header-cell-text "}>
                {this.props.displayName}
            </span>
        );
        return (
            <XpTranslated trKey={this.props.trKey} trParams={this.props.trParams} fallBackText={this.props.fallBackText}/>
        );
    }

    render() {

        return (
            <div className="ag-cell-label-container" style={{height: '100%'}}>
                <div
                    className={"ag-header-cell-label " + (this.props.xpClassName ? this.props.xpClassName : "")}
                >
                    {this.getHeaderContent()}
                </div>
            </div>
        );
    }
}
