import React, {useState, useMemo, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton"
import {userRightsAdminRemoveInstance} from "gui-common/adminUserRights/adminUserRightsReducer";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {ormItemLoading} from "gui-common/orm/ormLoadingSelectors";
import {
    addRemovedActiveDataRights,
    assignEntityDataRight,
    compareEntityName
} from "gui-common/adminUserRights/adminUserRightsFunctions";
import XpTooltip from "gui-common/components/XpTooltip";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function EntityList (props) {

    const entityDataRights = useMemo(() =>
        {
            return props.suppressChangeDisplay ? props.entityList : addRemovedActiveDataRights(props.entityList, props.activeEntityList);
        },
        [props.entityList, props.activeEntityList]
    );

    const onLineClick = (instance) => {
        if (props.readOnly) return;
        if (props.selectedInstance && (instance.entity.id === props.selectedInstance.entity.id)) props.selectLine(null);
        else props.selectLine(instance)
    };

    const sortedEntityList = entityDataRights.sort(compareEntityName);

    const getRowTooltipKey = (isAdded, isRemoved) => {
        if (props.suppressChangeDisplay) return undefined;
        if (isAdded)   return 'adminUserRights.entityDataRightsChange.' + (props.readOnly ? 'addedAudit' : 'added');
        if (isRemoved) return 'adminUserRights.entityDataRightsChange.' + (props.readOnly ? 'removedAudit' : 'removed');
        return "adminUserRights.entityDataRightsChange.active";
    };

    return (
        <div className={"adminUserRightsEntityList"} xp-test-id={'xpOperatingUnitList'}>
            <h3>{props.header}</h3>

            <table style={{width: '100%'}}>
                <tbody>
                <tr className="HeaderRow" key="header">
                    <th style={{width: '40%'}}    ><XpTranslated trKey='general.fieldNames.name'       /></th>
                    <th style={{width: '60%'}}    ><XpTranslated trKey='general.fieldNames.description'/></th>
                </tr>
                {(sortedEntityList.length > 0) ?
                    sortedEntityList.map(instance => {
                        const activeEntity   = props.activeEntityList ? props.activeEntityList.find(item => item.entity.id === instance.entity.id) : undefined;
                        const assignedEntity = props.entityList       ? props.entityList.find(      item => item.entity.id === instance.entity.id) : undefined;

                        const isAdded   = !activeEntity;
                        const isRemoved = !assignedEntity;

                        let renderClass = props.readOnly ? "" : "clickableRow";
                        if (props.selectedInstance && (props.selectedInstance.entity.id === instance.entity.id)) {
                            renderClass = "activeClickableRow";
                        }
                        if (!props.suppressChangeDisplay && isAdded)   renderClass += " instanceUserRightsCardAdded";
                        if (!props.suppressChangeDisplay && isRemoved) renderClass  = "instanceUserRightsCardRemoved";

                        return (
                            <XpTooltip
                                trKey={getRowTooltipKey(isAdded, isRemoved)}
                                trParams={{name: instance.entity.name}}
                                key={instance.entity.id}
                            >
                                <tr
                                    className={renderClass}
                                    onClick={isRemoved ? undefined : () => onLineClick(instance)}
                                >
                                    <td style={{paddingLeft: '5px' }}><div>{instance.entity.name}</div></td>
                                    <td style={{paddingLeft: '5px' }}><div>{instance.entity.description}</div></td>
                                </tr>
                            </XpTooltip>
                        )
                    })
                    :
                    <tr className="">
                        <td style={{paddingLeft: '5px' }} colSpan={3}>
                            <div>{props.nonItemsMessage}</div>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    )
}

function SimpleUserRightsAssignment (props) {
    const dispatch  = useDispatch();
    const translate = useSelector(selectTranslateFunction);

    const dataRightsCollectionLoading = useSelector(state => ormItemLoading(state, {itemId: props.dataRightsCollection.id, ormModel: 'DataRightsCollection'}));

    const getFilteredList = (list) => {
        if (!list) return undefined;
        return list.filter(item => item.entityPrototypeName === props.entityPrototypeName);
    }

    const assignedEntityDataRights         = useMemo(() => getFilteredList(props.dataRightsCollection.assigned  ), [props.dataRightsCollection.assigned  , props.entityPrototypeName]);
    const assignableEntityDataRights       = useMemo(() => getFilteredList(props.dataRightsCollection.assignable), [props.dataRightsCollection.assignable, props.entityPrototypeName]);

    const activeAssignedEntityDataRights   = useMemo(() => props.activeDataRightsCollection ? getFilteredList(props.activeDataRightsCollection.assigned  ) : undefined, [props.activeDataRightsCollection.assigned  , props.entityPrototypeName]);
    const activeAssignableEntityDataRights = useMemo(() => props.activeDataRightsCollection ? getFilteredList(props.activeDataRightsCollection.assignable) : undefined, [props.activeDataRightsCollection.assignable, props.entityPrototypeName]);

    const [selectedAssigned,   setSelectedAssigned]   = useState(null);
    const [selectedAvailable,  setSelectedAvailable]  = useState(null);
    useEffect(
        () => {
            if (selectedAssigned && !assignedEntityDataRights.find(item => item.entity.id === selectedAssigned.entity.id)) setSelectedAssigned(null);
        },
        [selectedAssigned, assignedEntityDataRights],
    );
    useEffect(
        () => {
            if (selectedAvailable && !assignableEntityDataRights.find(item => item.entity.id === selectedAvailable.entity.id)) setSelectedAvailable(null);
        },
        [selectedAvailable, assignableEntityDataRights],
    );

    if (!props.dataRightsCollection || !props.entityPrototypeName) return null;

    return (
        <div className={"adminUserRightsContainer"}>
            <EntityList
                header={
                    <span>
                        <XpTranslated trKey='adminUserRights.assignedEntities'/>
                        <XpTranslated trKey={'general.prototypeNamesSmallPlural.' + props.entityPrototypeName} fallBackText={props.entityPrototypeName}/>
                    </span>
                }
                nonItemsMessage = {<XpTranslated trKey='adminUserRights.noEntitiesAssigned' trParams={{entityType: translate('general.prototypeNamesSmallPlural.' + props.entityPrototypeName)}}/>}
                selectLine       = {setSelectedAssigned}
                selectedInstance = {selectedAssigned}
                entityList       = {assignedEntityDataRights}
                activeEntityList = {activeAssignedEntityDataRights}
                readOnly         = {props.readOnly}
            />
            {!props.auditMode &&
            <div className={"adminUserRightsAssignButtons"} style={{visibility: props.readOnly ? 'hidden' : 'visible'}}>
                <WaitingXpoolButton
                    labelKey        = 'adminUserRights.assignButton'
                    onClickCallback = {() => assignEntityDataRight(props.dataRightsCollection, selectedAvailable, dispatch)}
                    disabled        = {selectedAvailable === null}
                    waiting         = {dataRightsCollectionLoading}
                    toolTipKey      = {(dataRightsCollectionLoading && selectedAvailable) ? 'adminUserRights.waitingForUserRight' : undefined}
                    toolTipParams   = {(dataRightsCollectionLoading && selectedAvailable) ? {name: selectedAvailable.entity.name}: undefined}
                />
                <WaitingXpoolButton
                    labelKey        = 'adminUserRights.removeButton'
                    onClickCallback = {() => dispatch(userRightsAdminRemoveInstance(props.dataRightsCollection, selectedAssigned))}
                    disabled        = {selectedAssigned  === null}
                    waiting         = {dataRightsCollectionLoading}
                />
            </div>}
            {!props.auditMode &&
            <EntityList
                header={
                    <span>
                        <XpTranslated trKey='adminUserRights.availableEntities'/>
                        <XpTranslated trKey={'general.prototypeNamesSmallPlural.' + props.entityPrototypeName} fallBackText={props.entityPrototypeName}/>
                    </span>
                }
                nonItemsMessage  = {<XpTranslated trKey='adminUserRights.noEntitiesAssigned' trParams={{entityType: translate('general.prototypeNamesSmallPlural.' + props.entityPrototypeName)}}/>}
                selectLine       = {setSelectedAvailable}
                selectedInstance = {selectedAvailable}
                entityList       = {assignableEntityDataRights}
                activeEntityList = {activeAssignableEntityDataRights}
                readOnly         = {props.readOnly}
                suppressChangeDisplay = {true}
            />}
        </div>
    )
}
export default SimpleUserRightsAssignment
