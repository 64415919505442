import {xpColCreate} from "../xpGrid/xpGridFunctions";

// id:                     true,
//     logTime:                false,
//     sequenceNumber:         false,
//     fixMessageDirection:    false,
//     messageType:            false,
//     account:                false,
//     message:                false

export function prepareFixLogColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id'                   , 100, 50, 'fixLog.fixLogList.headers.id');
    xpColCreate(translate, subArray, hiddenState, 'logTime'              , 200, 50, 'fixLog.fixLogList.headers.logTime');
    xpColCreate(translate, subArray, hiddenState, 'sequenceNumber'       , 100, 50, 'fixLog.fixLogList.headers.sequenceNumber');
    xpColCreate(translate, subArray, hiddenState, 'guiDirection'         , 100, 50, 'fixLog.fixLogList.headers.guiDirection');
    xpColCreate(translate, subArray, hiddenState, 'messageType'          , 200, 50, 'fixLog.fixLogList.headers.messageType', {
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState, 'account'              , 150, 100, 'fixLog.fixLogList.headers.account');
    xpColCreate(translate, subArray, hiddenState, 'guiMessageCat'              , 150, 100, 'fixLog.fixLogList.headers.guiMessageCat');
    xpColCreate(translate, subArray, hiddenState, 'message'              , 400, 100, 'fixLog.fixLogList.headers.message', {tooltipField: 'toolTip'});
    return subArray;
}
