import React, {useCallback, useMemo, useRef} from 'react';
import {useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'

import {getCashLadderTransactionsColumns} from "features/transaction/transactionFunctions";
import {cashLadderTransactionsListDataSelector} from "features/transaction/transactionSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

TransactionsList.detailIcon = 'linear_scale';
TransactionsList.detailIconToolTipKey = 'accountCashLadderList.detailIconToolTipKey';
export function TransactionsList ({itemSelectedCallback, instanceId, setHeightCallback, fillAvailableSpace, gridId, rowDataSelectorProps, selectedProjectionDates, selectedProjection}) {

    const translate      = useSelector(selectTranslateFunction);
    const translateRef   = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!itemSelectedCallback) return;
                itemSelectedCallback((data === undefined) ? undefined : data.id);
            },
            [itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            (params) => {
                let menuItems = [];
                return menuItems;

            }, [translate]),
        isRowMaster     : useCallback((params) => {return true;}, []),
        rowClassRules   : {},
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : instanceId,
                overlayNoRowsTemplate   : translate('transactionsList.noItemsToShow'),
                fillAvailableSpace      : (fillAvailableSpace === undefined) ? true : fillAvailableSpace,
                groupDefaultExpanded    : 0,
                getRowDataSelector      : cashLadderTransactionsListDataSelector,
                rowDataSelectorProps    : rowDataSelectorProps,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'Transaction',
                applyColumnDefOrder     : true,
                xpDetailRendererProps   : {},
            };
        },
        [translate, fillAvailableSpace, rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return getCashLadderTransactionsColumns(translate, rowDataSelectorProps.selectedProjectionDates);
        },
        [translate, rowDataSelectorProps.selectedProjectionDates]
    );
/*
    console.log("Trs: selectedProjection: ", rowDataSelectorProps.selectedProjection);
    console.log("Trs: selectedDates: ", rowDataSelectorProps.selectedProjectionDates);
*/

    return (
        <XpGrid
            gridId={gridId ? gridId : "CashLadderTransactions-" + instanceId}
            {...gridCallbacks}
            {...gridOptions}
            columnDefs={colDefs}
            callingContext={this}
            setHeightCallback={setHeightCallback}
        />
    );
}
