import {
    getSpecificBankHolidaySelector,
    newBankHolidayFormTemplateSelector
} from "gui-common/bankHolidays/bankHolidaysSelectors";
import {BankHolidayForm} from "gui-common/bankHolidays/BankHolidayForm";

export const bankHolidayAdminFormConfig = {
    BankHoliday: {
        formTemplate: 'bankHolidayForm',
        getCurrentDataSelector: getSpecificBankHolidaySelector,
        newFormTemplateSelector: newBankHolidayFormTemplateSelector,
        checkBeforeDisableFunction: () => true,
        componentMatrix:[
            [BankHolidayForm]
        ],
        childEntities: undefined,
    }
}
