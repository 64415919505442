import EditFileDialog from "gui-common/modals/modalComponents/EditFileDialog";
import RemoveFileDialog from "gui-common/modals/modalComponents/RemoveFileDialog";
import ModalConfirmation from "gui-common/modals/modalComponents/ModalConfirmation";
import ModalConfirmationWithComment from "gui-common/modals/modalComponents/ModalConfirmationWithComment";
import ModalInformation from "gui-common/modals/modalComponents/ModalInformation";
import ModalSaveSettings from "gui-common/modals/modalComponents/ModalSaveSettings";
import ModalViewReport from "gui-common/report/ModalViewReport";
import ModalSetTranslation from "gui-common/appLocale/xpTranslated/ModalSetTranslation";
import {ModalShowNewTranslations} from "gui-common/appLocale/xpTranslated/ModalShowNewTranslations";
import GenerateUserMessage from "gui-common/userMessages/GenerateUserMessage";
import ModalGetTestParameters from "gui-common/modals/modalComponents/ModalGetTestParameters";
import BankHolidayModal from "gui-common/bankHolidays/BankHolidayModal";
import {appModalsMap} from "appConfig/appModals";
import ShowAuditHistoryModal from "gui-common/audit/ShowAuditHistoryModal";

export const REMOVE_FILE_DIALOG                 = 'REMOVE_FILE_DIALOG';
export const CONFIRMATION_DIALOG                = 'CONFIRMATION_DIALOG';
export const CONFIRMATION_WITH_COMMENT_DIALOG   = 'CONFIRMATION_WITH_COMMENT_DIALOG';
export const INFORMATION_DIALOG                 = 'INFORMATION_DIALOG';
export const SAVE_SETTINGS_DIALOG               = 'SAVE_SETTINGS_DIALOG';
export const VIEW_REPORT_DIALOG                 = 'VIEW_REPORT_DIALOG';
export const SET_TRANSLATION_DIALOG             = 'SET_TRANSLATION_DIALOG';
export const SHOW_NEW_TEXTS_DIALOG              = 'SHOW_NEW_TEXTS_DIALOG';
export const CREATE_UM_DIALOG                   = 'CREATE_UM_DIALOG';
export const BANK_HOLIDAY_DIALOG                = 'BANK_HOLIDAY_DIALOG';
export const GET_TEST_PARAMETERS_DIALOG         = 'GET_TEST_PARAMETERS_DIALOG';
export const EDIT_FILE_DIALOG                   = 'EDIT_FILE_DIALOG';
export const SHOW_AUDIT_HISTORY_DIALOG          = 'SHOW_AUDIT_HISTORY_DIALOG';

export const modalsMap = new Map([
    [EDIT_FILE_DIALOG                   , EditFileDialog],
    [REMOVE_FILE_DIALOG                 , RemoveFileDialog],
    [CONFIRMATION_DIALOG                , ModalConfirmation],
    [CONFIRMATION_WITH_COMMENT_DIALOG   , ModalConfirmationWithComment],
    [INFORMATION_DIALOG                 , ModalInformation],
    [SAVE_SETTINGS_DIALOG               , ModalSaveSettings],
    [VIEW_REPORT_DIALOG                 , ModalViewReport],
    [SET_TRANSLATION_DIALOG             , ModalSetTranslation],
    [SHOW_NEW_TEXTS_DIALOG              , ModalShowNewTranslations],
    [CREATE_UM_DIALOG                   , GenerateUserMessage],
    [GET_TEST_PARAMETERS_DIALOG         , ModalGetTestParameters],
    [BANK_HOLIDAY_DIALOG                , BankHolidayModal],
    [SHOW_AUDIT_HISTORY_DIALOG          , ShowAuditHistoryModal],
    ...appModalsMap,
]);
export const modalStandardParams = {
    isOpen: true,
    className: {
        base: "xpoolModal",
        afterOpen: "xpoolModalAfterOpen",
        beforeClose: "xpoolModalBeforeClose"
    },
    overlayClassName: {
        base: "xpoolModalOverlay",
        afterOpen: "xpoolModalOverlayAfterOpen",
        beforeClose: "xpoolModalOverlayBeforeClose"
    },
    closeTimeoutMS: 200,
    shouldCloseOnOverlayClick: false,
    ariaHideApp: false,
};
