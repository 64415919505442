import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
    overlay: {
        zIndex: 1040,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
};

export default class EditFileDialog extends Component {
    static propTypes = {
        initialFileName: PropTypes.string,
        resultTypes: PropTypes.object,
        popModalWindow: PropTypes.func
    };

    static defaultProps = {
        initialFileName: ''
    };

    state = {
        newFileName: this.props.initialFileName
    };


    render() {
        const { newFileName } = this.state;

        return (
            <Modal
                isOpen={true}
                contentLabel="Edit"
                style={customStyles}
                onRequestClose={(props) => this.handleCancelBtnClick(props)}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
            >
                <div>
                    <label>
                        <span>Enter new filename:</span>
                        <input type="text" value={newFileName} onChange={event => this.handleFileNameChange(event)} />
                    </label>
                    <button type="button" onClick={() => this.handleConfirmBtnClick()}>Confirm</button>
                    <button type="button" onClick={() => this.handleCancelBtnClick()}>Cancel</button>
                </div>
            </Modal>
        );
    }

    handleFileNameChange(event) {
        this.setState({
            newFileName: event.currentTarget.value
        });
    }

    handleConfirmBtnClick() {
        const { resultTypes: { MODAL_TYPE_CONFIRM }, popModalWindow } = this.props;
        const { newFileName } = this.state;

        // Close the modal with confirm status and provide new file name
        popModalWindow({ status: MODAL_TYPE_CONFIRM, newFileName });
    }

    handleCancelBtnClick() {
        const { resultTypes: { MODAL_TYPE_CANCEL }, popModalWindow } = this.props;

        // Just closing the modal
        popModalWindow({ status: MODAL_TYPE_CANCEL });
    }
}