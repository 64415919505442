import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";

function XpSwitch(props) {
    return (
        <FormControlLabel
            disabled={props.disabled}
            control={
                <Switch
                    checked ={!!props.value}
                    onChange={props.readOnly ? undefined : props.onChange}
                    color="primary"
                    // size="small"
                />
            }
            label={props.label}
        />
    )
}

function XpFormSwitchInput (props) {
    return (
        <XpFormLabeledInput
            // inLineLayout

            {...props}

            CompToRender={XpSwitch}
            getValueFromEvent={event => event.target.checked}
            isSwitchField
            setTouchedOnChange

            beforeChangeFunction={(before, after) => (before === after) ? null : before}
            valueRenderer={(value, props) => (value === null) ? null : (<XpTranslated trKey={value ? 'general.switchValues.checked' : 'general.switchValues.unchecked'} />)}
        />
    )
}

XpFormSwitchInput.propTypes    = xpFormBasePropTypes;
export default XpFormSwitchInput
