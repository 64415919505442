import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {disableBalance, prepareAccountBalanceGridColumns,} from "features/bankAccountBalance/accountBalanceFunctions";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {getLedgerAccountBalancesSelector} from "gui-common/ledger/ledgerSelectors";
import {userCanDisableBalance, userCanRegisterBalance} from "gui-common/functions/userRights";
import {pushModalWindow} from "redux-promising-modals";
import {REGISTER_BALANCE_DIALOG} from "appConfig/appModals";
import {XP_FORM_NEW, XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

const accountBalancesHiddenState = {
    id:               true,
    accountName:      true,
    valueDate:        false,
    // typeT:            false,
    balance:          false,
    createdDateTime:  false,
    createdByUser:    true,
    createdComment:   true,
    disabledDateTime: true,
    disabledByUser:   true,
    disabledComment:  true,
};

function AccountBalanceList (props) {
    const dispatch      = useDispatch();
    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;
    const activeLanguage = useSelector(selectActiveLanguage);

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {if (props.itemSelectedCallback) props.itemSelectedCallback(data)},
            [props.itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];
                let menuItems = [];
/*
                if (userCanRegisterBalance(props.account)) menuItems.push({
                    name: translateRef.current('accountsList.gridContextMenuItems.registerBalance'),
                    action: () => {
                        setTimeout(() => dispatch(pushModalWindow(REGISTER_BALANCE_DIALOG, {account: props.account, displayState: XP_FORM_NEW})), 10);
                    },
                });
*/
                menuItems.push({
                    name: translateRef.current('accountBalanceList.gridContextMenuItems.viewBalance'),
                    action: () => {
                        setTimeout(() => dispatch(pushModalWindow(REGISTER_BALANCE_DIALOG, {account: props.account, displayState: XP_FORM_VIEW, balance: params.node.data})), 10);
                    },
                });
                if (userCanDisableBalance(props.account)) menuItems.push({
                    name: translateRef.current('accountBalanceList.gridContextMenuItems.disableBalance'),
                    action: () => {
                        disableBalance(props.account, params.node.data, activeLanguage, dispatch);
                    },
                    disabled: params.node.data.disabledDateTime || params.node.data.isCurrent
                });
                return menuItems;
            }, []),
        rowClassRules   : {
            'xp-grid-disabled-row' : (params) =>  !!params.data.disabledDateTime,
        },
    }

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('accountBalanceList.noAvailableBalance'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? false : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                getRowDataSelector      : getLedgerAccountBalancesSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'LedgerAccountBalance',
            };
        },
        [translate, props.rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareAccountBalanceGridColumns(translate, accountBalancesHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={"accountBalanceList" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}
export default AccountBalanceList
