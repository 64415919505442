import React, {useMemo} from 'react'
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import 'moment/locale/fr';
import 'moment/locale/sv';

import {confirmAllUserMessages} from "gui-common/userMessages/userMessageThunks";
import {
    userSelector,
    usersSelector,
    topicsSelector,
    getOperatingUnitsWithFullClientCreateSelector,
} from "gui-common/orm/ormSelectors";
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import XpTooltip from "gui-common/components/XpTooltip";
import {openUserMessagesSelector} from 'gui-common/userMessages/userMessagesSelectors'
import XpUserIcon from "gui-common/menuComponents/XpUserIcon";
import XpLanguageSelector from "gui-common/menuComponents/XpLanguageSelector";
import XpNumberFormatMenu from "gui-common/menuComponents/XpNumberFormatMenu";
import XpSearchableClientSelector from "gui-common/menuComponents/XpSearchableClientSelector";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {pushModalWindow} from "redux-promising-modals";
import {
    getMainMenuLeftItems,
    getMainMenuSettingsItems, getMenuTitle,
    useAppMenuClientSelector
} from "appConfig/appMenuConfig";
import {XpHomeCurrencySelector} from "gui-common/menuComponents/XpHomeCurrencySelector";
import {selectIsDevMode} from "gui-common/app/appEnvSelectors";
import {logoutUserFromApi} from "gui-common/api/userLogoutAPI";
import {userCan} from "gui-common/functions/userRights";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {CREATE_UM_DIALOG} from "gui-common/modals/modalConstants";
import {selectUserPreferencesActiveClientId} from "gui-common/userSettings/userSettingsSelectors";
import DevMenu from "gui-common/app/DevMenu";

export default function MainMenu() {

    const devMode                = useSelector(selectIsDevMode);
    const appReady               = useSelector(selectAppReadyState);
    const user                   = useSelector(userSelector);
    const users                  = useSelector(usersSelector);
    const openUserMessages       = useSelector(openUserMessagesSelector);
    const activeClientId         = useSelector(selectUserPreferencesActiveClientId);
    const topics                 = useSelector(topicsSelector);
    const appEnvState            = useSelector(state => state.appEnvState);

    const operatingUnitsWithFullClientCreate = useSelectorInstance(getOperatingUnitsWithFullClientCreateSelector, {nestedPropToCheck: 'nestedRightRequiredForWizard'});


    const userCanCreateUserMessage = useMemo(() => {
        for (let topic of topics) if (userCan('Create', 'UmTopic' + topic.topic , user)) return true;
        return false;
    }, [topics, user]);

    const dispatch = useDispatch();

    function handleLogout() {
        dispatch(logoutUserFromApi());
    }

    // const showAuditMenu          = (auditRootModels.length > 0);
    const showAuditMenu          = userCan('Read', 'Audit', user) || userCan('Read', 'SuperAudit', user);
    const showClientCreateWizard = (operatingUnitsWithFullClientCreate.length > 0);
    const confirmAllUserMessagesDisabled = openUserMessages ? (openUserMessages.length) === 0 : true;
    const propsToFunction = {users: users, user: user, showClientCreateWizard: showClientCreateWizard, showAuditMenu: showAuditMenu, devMode: devMode, ormActiveClientId: activeClientId};

    // console.log("Rendering MainMenu");

    return (
        <div xp-test-id='xpMainMenuContainer'>

            <Navbar bg="dark" variant="dark" collapseOnSelect fixed='top' expand="md">
                {getMenuTitle()}
                <Navbar.Toggle/>

                <Navbar.Collapse>
                    {/* Main menu items to the left *************************************************************************/}
                    {(appReady === true) &&
                    getMainMenuLeftItems(activeClientId, appEnvState)}

                    {/* Settings menu group to the right *********************************************************************/}
                    <Nav>
                        {/* Client selection dropdown *************************************************************************/}
                        {useAppMenuClientSelector && <XpSearchableClientSelector/>}

                        {/* System and client settings ***************************************************************/}
                        {(appReady === true) &&
                        getMainMenuSettingsItems(propsToFunction)}

                        {/* Dev menu with menu items ot showed only in dev mode ****************************************************************/}
                        <DevMenu/>

                        {/* User related settings and test menu items  *********************************************************/}
                        {(appReady === true) &&
                        <NavDropdown
                            title={<XpUserIcon/>}
                            id="user-nav-dropdown"
                            alignRight
                        >
                            <LinkContainer to='/userSettings'>
                                <NavDropdown.Item><XpTranslated trKey={'topMenu.userSettings'}/></NavDropdown.Item></LinkContainer>

                            <LinkContainer to='/myUserRights'>
                                <NavDropdown.Item><XpTranslated trKey={'topMenu.myUserRights'}/></NavDropdown.Item></LinkContainer>

                            <NavDropdown.Divider/>
                            <LinkContainer to='/userMessages'>
                                <NavDropdown.Item><XpTranslated trKey={'topMenu.userMessages'}/></NavDropdown.Item></LinkContainer>
                            <XpTooltip trKey={confirmAllUserMessagesDisabled ? 'topMenu.confirmAllUserMessages.tooltipDisabled' : 'topMenu.confirmAllUserMessages.tooltipActive'}>
                                <div>
                                    <NavDropdown.Item
                                        key = 'confirmAllUserMessages'
                                        onClick={confirmAllUserMessagesDisabled ? undefined : () => dispatch(confirmAllUserMessages())}
                                        disabled={confirmAllUserMessagesDisabled}
                                    >
                                        <XpTranslated trKey={'topMenu.confirmAllUserMessages.menuItem'}/>
                                    </NavDropdown.Item>
                                </div>
                            </XpTooltip>
                            {userCanCreateUserMessage &&
                            <XpTooltip trKey={'topMenu.generateUserMessage.tooltip'}>
                                <div>
                                    <NavDropdown.Item
                                        key = 'generateUserMessage'
                                        onClick={() => dispatch(pushModalWindow(CREATE_UM_DIALOG))}
                                        disabled={false}
                                        xp-test-id="generateUserMessageItem"
                                    >
                                        <XpTranslated trKey={'topMenu.generateUserMessage.menuItem'}/>
                                    </NavDropdown.Item>
                                </div>
                            </XpTooltip>}
                            <NavDropdown.Divider/>
                            <LinkContainer to='/login' onClick={() => handleLogout()}>
                                <NavDropdown.Item><XpTranslated trKey={'topMenu.logout'}/></NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>}
                        {/* Decimals and denominator menu *********************************************************************/}
                        <XpNumberFormatMenu/>
                        {/* Home currency selector ********************************************************************/}
                        <XpHomeCurrencySelector/>
                        {/* Language menu and refresh button ********************************************************************/}
                        <XpLanguageSelector/>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}
