import React from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {activeCurrenciesSelector} from "gui-common/orm/ormSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormDateInput from "gui-common/xpForm/core/XpFormDateInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";

export function BankHolidayForm (props) {

    const ormCurrencies     = useSelector(activeCurrenciesSelector);
    const bankHolidayConfig = useSelector(state => selectAppEnvProperty(state, 'bankHolidayConfig'));


    const fieldIsDisabled = () => {
        return false;
    };

    return (
        <div className={props.className}>
            <XpFormSelectorInput
                isRequired noTemplate alignRight inLineLayout
                inLineDivider={30}
                field         = "currencyId"
                disabled      = {fieldIsDisabled()}
                selectList    = {ormCurrencies}
                itemTextField = "currency"
                defaultValue={ormCurrencies[0].id}
            />
            <XpFormDateInput
                isRequired noTemplate alignRight inLineLayout
                inLineDivider={30}
                field         = "date"
                disabled      = {fieldIsDisabled()}
                minDate       = {bankHolidayConfig.minYear + '-01-01'}
                maxDate       = {bankHolidayConfig.maxYear + '-12-31'}
                showYearDropdown = {true}
                popperPlacement='left'
/*
                popperModifiers={{
                    offset: {
                        enabled: true,
                        offset: "40px, 0px"
                    }
                }}
*/
            />
            <XpFormTextInput
                field         = "description"
                disabled      = {fieldIsDisabled()}
                isRequired
                maxLength     = {50}
            />
            <XpFormTextAreaInput
                field         = "comment"
                disabled      = {fieldIsDisabled()}
                maxLength     = {200}
            />
        </div>
    );
}
BankHolidayForm.propTypes = {
    className:        PropTypes.string,
};
