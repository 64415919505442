import {useSelector} from "react-redux";
import React, {useMemo} from "react";
import {getExtendedClientRunStateInfo} from "xpool-gui-common/features/client/clientFunctions";
import XpTooltip from "gui-common/components/XpTooltip";
import spinnerGif from "gui-common/resources/spinner.gif";
import AccountsRunStatus from "features/cashPool/AccountsRunStatus"
import XpTimeCountdown from "gui-common/components/XpTimeCountdown"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {activeClientBankAccountsSelector} from "xpool-gui-common/features/client/clientSelectors";
import {workflowConfig} from "appConfig/runState/runStateConstants";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function CashPoolRunText (props) {
    const translate = useSelector(selectTranslateFunction);
    const client    = useSelector(shallowActiveClientSelector);
    const accounts  = useSelector(activeClientBankAccountsSelector);
    const currentLanguage = useSelector(selectActiveLanguage);

    const clientRunState = useMemo(
        () => {
            if (!client) return null;
            // if (!accounts && client.clientRunState) return client.clientRunState.runStateInfo;
            return (getExtendedClientRunStateInfo(client, accounts, currentLanguage));
        },
        [client, accounts]
    );

    const messageAccounts = useMemo(
        () => {
            if (!clientRunState) return null;
            let returnStr = "";
            for (let i = 0; i < clientRunState.messageAccounts.length; i++) {
                const displayAccount = clientRunState.messageAccounts[i];
                returnStr = returnStr + (displayAccount ? displayAccount.name : "XXX");
                if (i === (clientRunState.messageAccounts.length-2)) returnStr = returnStr + " " + translate('general.and') + " ";
                if (i  <  (clientRunState.messageAccounts.length-2)) returnStr = returnStr + ", ";
            }
            return returnStr;
        },
        [clientRunState]
    );

    const isRunning = useMemo(
        () => {
            return workflowConfig.ClientWorkflow.isRunning(client.cashPoolsRunState);
        },
        [client.cashPoolsRunState]
    );



    if (!clientRunState) return null;
    return (
        <div style={{flexGrow:'1', marginBottom: '0px', marginTop: 'auto'}}>
            <XpTooltip
                // Todo: Find out if client runs on clock...
                title={client.cashPoolsRunState ? <AccountsRunStatus/> : undefined}
                trKey={client.cashPoolsRunState ? undefined : 'runPoolButton.runPoolStateMessage.manual'}
                placement="bottom-start"
            >
                <span className="valueTextDark" style={{paddingRight: 10}}>
                    {isRunning && <img className="textSpinner" src={spinnerGif} alt="waiting"/>}
                    <XpTranslated trKey={clientRunState.messageKey} trParams={{messageTime: clientRunState.messageTime, messageAccounts: messageAccounts}}/>
                    {' '}
                    {clientRunState?.showCounter && <XpTimeCountdown targetTime={clientRunState.rawMessageTime} className='cashPoolCountdown' outputType='digital'/>}
                </span>
            </XpTooltip>


        </div>
    )
}
export default CashPoolRunText
