import React from 'react'

function RedirectToLoginPage () {
    return (
        <div>
            <h2 style={{textAlign: 'center'}}>
                Redirecting to login page.
            </h2>
            <p> </p>
            <h2 style={{textAlign: 'center'}}>
                If not login page loads, try to reload page.
            </h2>
        </div>
    )
}
export default RedirectToLoginPage;


