import {genericFetch}            from "gui-common/api/apiCommon";
import {apiBaseUrlMap}           from "appConfig/api/apiConfig";
import {webSessionPushAwt}       from "gui-common/api/webSessionReducer";

/* -----------------------------------------------------------------------------------------------------------------
* Functions to get user messages from BE including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getFirstAwtBatchFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get first AWT batch";
            let requestUrl = apiBaseUrlMap.signIn + "?";

            dispatch(genericFetch(
                requestUrl,
                logText,
                getResponseHandler,
                getFailHandler,
                getFailHandler,
                'POST',
                undefined, // no body in http request needed for GET.
                undefined
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getResponseHandler(resp) {
    return (dispatch, getState) => {
        dispatch(webSessionPushAwt(resp));
    }
}
function getFailHandler(resp) {
    return (dispatch, getState) => {
    }
}
