import React from 'react';
import PropTypes from "prop-types";
import {contextMenuClickThunk} from "gui-common/contextMenu/contextMenuFunctions";
import {useDispatch} from "react-redux";

function XpContextMenu(props) {
    const dispatch     = useDispatch();

    if (props.bypass) return props.children;

    return (
        <span
            onContextMenu={(e) => dispatch(contextMenuClickThunk(e, false, "StandardContextMenu", {menuItems: props.menuItems, data: props.data}))}
        >
            {props.children}
        </span>
    );
}
XpContextMenu.propTypes = {
    menuItems : PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    data      : PropTypes.object,
    bypass    : PropTypes.bool
};
export default XpContextMenu;
