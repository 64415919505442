import {translateDateFields, xpColCreate} from "gui-common/xpGrid/xpGridFunctions";

export function formatSystemEventsGridData(dataArray, translate, currentLanguage) {
    for (let item of dataArray) {
        translateDateFields(item, 'YYYY-MM-DD, HH:mm:ss', currentLanguage);
    }
    return dataArray;
}

export function prepareSystemEventsGridColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id', 50, 20, 'systemEventList.id');
    xpColCreate(translate, subArray, hiddenState, 'timeT', 50, 30, 'systemEventList.time');
    xpColCreate(translate, subArray, hiddenState, 'systemEventStatusType', 50, 30, 'systemEventList.status', {
        enableRowGroup: true, cellStyle: function (params) {
            if (!params.data) return;

            let outStyle = {background: '#79CA7E', color: '#434343'}; // Default RUNNING
            if (params.data.systemEventStatusType === "STARTING") outStyle = {background: '#C7EFCA', color: '#434343'};
            else if (params.data.systemEventStatusType === "WARNING") outStyle = {
                background: '#E7D27F',
                color: '#434343'
            };
            else if (params.data.systemEventStatusType === "ERROR") outStyle = {
                background: '#E7B27F',
                color: '#434343'
            };
            else if (params.data.systemEventStatusType === "DOWN") outStyle = {background: '#CC8182', color: '#434343'};
            return outStyle;
        }
    });
    xpColCreate(translate, subArray, hiddenState, 'systemEventType', 50, 30, 'systemEventList.type', {autoHeight: true});
    xpColCreate(translate, subArray, hiddenState, 'description', 400, 50, 'systemEventList.description', {autoHeight: true, tooltipField: 'description'});
    return subArray;
}
