export const CONTEXT_MENU_SHOW = "CONTEXT_MENU_SHOW";
export const CONTEXT_MENU_HIDE = "CONTEXT_MENU_HIDE";

export function showContextMenu(x, y, type, menuArgs) {
    return {
        type : CONTEXT_MENU_SHOW,
        payload : {
            location : {x, y},
            type,
            menuArgs
        }
    }
}
export function hideContextMenu() {
    return {
        type : CONTEXT_MENU_HIDE
    }
}


const contextMenuInitialState = {
    show : false,
    location : {
        x : null,
        y : null,
    },
    type : null,
    menuArgs : undefined,
};
export function contextMenuReducer(state = contextMenuInitialState, action)
{
    switch (action.type) {
        case CONTEXT_MENU_SHOW:
            return {
                ...state,
                show : true,
                ...action.payload
            };
        case CONTEXT_MENU_HIDE:
            return {
                ...contextMenuInitialState
            };
        default:
            return state;
    }
}