import React, {Component} from 'react';
import {isMomentObject} from "gui-common/xpGrid/xpGridFunctions";
import {formatDateField} from "gui-common/functions/functions";
import moment from "moment";
import {connect} from "react-redux";
import {selectActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function calculateShortDateDisplayString(value, format, currentLanguage) {
    if (!value) return "";
    const momentObject = isMomentObject(value) ? value : moment(value);
    return formatDateField(momentObject, currentLanguage, format);
}
class XpGridShortDateHeader extends Component {

    constructor(props) {
        super(props);
        const format = props.column.colDef.cellRendererParams.xpDateTimeFormat;
        this.state = {
            value: props.displayName,
            format: format,
        };
    }

    getReactContainerStyle() {
        return {
            width: '100%'
        };
    }

    componentDidMount() {
    }

    refresh(params) {
        this.setState( {
            value: params.displayName,
        });
        return true;
    }
    render() {

        const displayString = calculateShortDateDisplayString(this.state.value, this.state.format, this.props.currentLanguage);

        return (
            <div className={"ag-cell-label-container"} style={{height: '100%'}}>
                <div className="ag-header-cell-label" >
                    <span className="ag-header-cell-text" style={{textAlign: 'right', width: '100%'}}>
                        {displayString}
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        currentLanguage:        selectActiveLanguage(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(XpGridShortDateHeader);
