import {getBaseFormattingRules,} from "gui-common/numberFormat/numberFormatFunctions";
import {translateBoolFields, xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {reformatNumberFieldsOnChangedRules} from "gui-common/xpForm/formNumberFieldsFunctions";
import {xpTranslatedSetActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedReducer";

export function getTranslatedTextClassName(editTextMode, textGenerations) {
    if (!editTextMode) return "";
    if (!textGenerations || (textGenerations.length <= 1)) return "editableTextField";
    return "editableTextField editableTextFieldWithGenerations"
}


export function setActiveLanguageThunk(code) {
    return (dispatch, getState) => {

        const langCode = code ? code : 'en';

        // dispatch(setActiveLanguage(langCode));
        dispatch(xpTranslatedSetActiveLanguage(langCode));

/*
        const languages = getLanguages(state.locale);
        const translateFunction = getTranslate(state.locale);
        dispatch(setAppLanguage(langCode, languages, translateFunction));
*/

        let formattingRules = getBaseFormattingRules(getState().numberFormatState);
        formattingRules.newLan = langCode;
        dispatch(reformatNumberFieldsOnChangedRules(formattingRules));
    }
}

export function formatTranslationsGridData(translate, trObjects, languages) {

    let returnArray = [];
    let index = 1;

    const createItem = (item) => {
        item.id = index;
        translateBoolFields(item, translate);
        index++;
        return item;
    };

    function isEdited(trObject) {
        for (const lang of languages) {
            if (trObject[lang.code] && trObject[lang.code].length > 1) {
                return true;
            }
        }
        return false;
    }

    for (let key in trObjects) {
        let newItem = {
            trKey: key,
            generationsAvailable: isEdited(trObjects[key]),
            isNewKey: trObjects[key].isNewKey === true,
        }
        for (const lang of languages) {
            newItem[lang.code] = trObjects[key][lang.code] ? trObjects[key][lang.code][0].text : "";
        }
        returnArray.push(createItem(newItem));
    }

/*
    for (let key in textGenerations) {
        if (trObjects[key]) continue; // key already added in loop above

        let newItem = {
            trKey: key,
            generationsAvailable: true,
            isNewKey: true,
        }
        for (let lang of languages) {
            newItem[lang.code] = textGenerations[key][lang.code][0].text;
        }
        returnArray.push(createItem(newItem));
    }
*/

    return returnArray;
}

export function prepareTranslationsGridColumns(translate, hiddenState, languages) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'trKey', 300, 100, 'appLangTranslationsList.trKey', {autoHeight: true, tooltipField: 'trKey'});
    xpColCreate(translate, subArray, hiddenState, 'generationsAvailableT', 50, 50, 'appLangTranslationsList.generationsAvailable', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'isNewKeyT', 50, 50, 'appLangTranslationsList.isNewKey', {cellRenderer: 'xpTranslatedRenderer'});

    for (let lang of languages) {
        xpColCreate(translate, subArray, hiddenState, lang.code, 300, 100, null, {autoHeight: true, tooltipField: lang.code, headerName: lang.name});
    }

    return subArray;
}

/*
export function createMissingTextGeneration(trKey, trParams, fallBackText, translate, languages, textGenerations, dispatchSetAppTextGeneration) {
    if (!trKey) return;

    const text = translate(trKey);
    if (text.includes("Missing translation")) {
        if (textGenerations[trKey]) return;
        languages.forEach(lang => {
            let params = "";
            if (trParams) for (let key in trParams) params = params + " ${" + key + "}";
            const textItem = {
                textKey         : trKey,
                languageCode    : lang.code,
                text            : fallBackText ? fallBackText : "No text defined." + (trParams ? " Parameters:" + params : ""),
                isNewKey        : true,
            };
            dispatchSetAppTextGeneration([textItem]);
        });
    }
}*/

/*
export function getMissingLanguage(translations, languages) {

    const nrOfLanguages = languages.length;
    let translationsToAdd = {}

    for (let trKey in translations) {
        if (translations[trKey].length < nrOfLanguages) {
            for (let index = translations[trKey].length-1; index < nrOfLanguages; index++) {
                translationsToAdd[trKey] = translations[trKey];
                translationsToAdd[trKey].push(translations[trKey][0]);
            }
        }
    }
    return translationsToAdd
}
*/


