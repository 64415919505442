import React, {useMemo} from 'react'
import {useSelector} from "react-redux";
import {
    activeBankAccountInstitutionsSelector,
} from "gui-common/orm/ormSelectors";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import AccountStaticPhysical from "features/bankAccount/formComponents/AccountStaticPhysical";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";


export function useSortedAccountsArray() {
    const topCurrencyId  = useXpFormField('topCurrencyId');
    const formCurrencies = useXpFormField('currencies')

    return useMemo(
        () => {
            if (!formCurrencies?.length) return [];
            let subAccountsArray = [];
            let topAccount = undefined;
            for (const [index, account] of formCurrencies.entries()) {
                if (account.currencyId === topCurrencyId) topAccount = {...account, originalIndex: index};
                else subAccountsArray.push({...account, originalIndex: index});
            }
            if (!topAccount) {
                console.error("No top bankAccount found in useAccountsArray", formCurrencies, topCurrencyId);
                return [];
            }
            return [topAccount].concat(subAccountsArray);
        },
        []
    );
}

function AccountInfo(props) {
    const ormInstitutions   = useSelector(activeBankAccountInstitutionsSelector);
    return (
        <div>
            <h3 className='wizardFormCardHeader'><XpTranslated trKey='onboardingWizardForm.currencyAccountForm.accountHeader' trParams={{currency: props.account.currencyName}}/></h3>
            <div>
                <XpFormTextInput
                    field="name"
                    inLineLayout
                    inLineDivider={50}
                    isRequired
                    checkNoSpaces={false}
                    checkOnlySpaces={false}
                    minLength={2}
                    maxLength={30}
                    autoFocus={props.index === 0}
                />
            </div>
            <div>
                <XpFormSelectorInput
                    noTemplate
                    inLineLayout
                    inLineDivider={50}
                    field= "institutionId"
                    selectList    ={ormInstitutions}
                    itemTextField="name"
                    isRequired
                />
            </div>
            <AccountStaticPhysical enforceUseCanEdit/>

        </div>
    )
}

function ObWizardAccountSettingsForm(props) {
    const accountsArray = useSortedAccountsArray()

    return (
        <div className={"adminFormContainer"}>
            {accountsArray.map((account, index) => {
                return (
                    <div className="wizardFormCard" key={index}>
                        <XpFormFieldset
                            field={'currencies.' + account.originalIndex}
                            formTemplate="accountForm"
                        >
                            <AccountInfo account={account} index={index}/>
                        </XpFormFieldset>
                    </div>
                )
            })}
        </div>
    )
}
export default ObWizardAccountSettingsForm;
