import {createStandardAuditFields2, xpColCreate, xpColHeadCreate,} from "gui-common/xpGrid/xpGridFunctions";
import {workflowConfig} from "appConfig/runState/runStateConstants";
import moment from "moment";
import {getLedgerAccountSelector} from "gui-common/ledger/ledgerSelectors";
import {
    bankAccountSelector,
    getBankAccountAggregatesSelector,
    getSpecificBankAccountSelector
} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectedParentIsChildOrNotActive} from "gui-common/functions/functions";
import {ormEntityUpdated} from "gui-common/orm/ormReducer";
import {getDisableAccountStructureChangeSelector} from "features/bankAccount/bankAccountSelectors";

export function calculateAccountRunStateInfo(account) {
    if (!account || !account.barRunState) return null;

    let messageTime = (account.barRunStateParams) ? account.barRunStateParams.nextRunTime : "";
    if (['CANCELLED', 'FAILED', 'COMPLETED'].includes(account.barRunState)) {
        const workflowParams = (account.barRunStateObject && account.barRunStateObject.workflow) ? account.barRunStateObject.workflow.workflowParams : {};
        messageTime = workflowParams.getBalancesTime ? moment(workflowParams.getBalancesTime).format('HH:mm') : "";
    }

    const stateReason = (account.barRunStateParams && account.barRunStateParams.reason) ? "_" + account.barRunStateParams.reason : "";

    let returnObject = {
        stateKey      : account.barRunState ? "clientRunState."         + account.barRunState + stateReason : "clientRunState.NOT_SCHEDULED",
        messageKey    : account.barRunState ? "accountRunStateTooltip." + account.barRunState + stateReason : "accountRunStateTooltip.NOT_SCHEDULED",
        messageTime   : messageTime,
        stateColor    : account.barRunState ? workflowConfig.BarWorkflow.getStatusBackgroundColor(account) : 'var(--xpool-client-run-status-disabled-background-color)',
        isCancelled   : (account.barRunState === 'CANCELLED'),
        allDone       : (account.barRunState === 'COMPLETED'),
        beforeProcess : (account.barRunState === 'INITIAL'),
        isRunning     : workflowConfig.BarWorkflow.isRunning(account.barRunState),
        noRunToday    : !account.barRunState,
        noRunState    : !account.barRunState
    }
    return returnObject
}

/*
export const disableAccountCancelResume = (stateToRequest, account) => {
    if (!account || !account.barRunState) return true;
    if (stateToRequest === 'INITIAL'  ) {
        if  (account.barRunState !== 'CANCELLED') return true;
        if (moment().isAfter(moment(account.barRunStateObject.workflow.workflowParams.stopTryingTime))) return true;
        return false;
    }
    if (stateToRequest === 'CANCELLED') return !workflowConfig.BarWorkflow.allowCancel(account.barRunState);
    return true;
};
*/

/*
export const anyAccountIsCancelled = (accounts) => {
    for (let account of accounts) {
        if (!account.barRunState) continue;
        if (account.barRunState === 'CANCELLED') return true;
    }
    return false;
};
*/

/*
export const anyAccountCanBeCancelled = (accounts) => {
    for (let account of accounts) {
        if (!account.barRunState) continue;
        if (workflowConfig.BarWorkflow.allowCancel(account.barRunState)) return true;
    }
    return false;
};
*/

export function canBankAccountBeDisabled(account, store) {
    let returnObject = {
        canBeDisabled: true,
        modalKey: 'accountForm.modalConfirmDisableAccount',
        modalParams: {name: account.name}
    };
    const rejectOnActiveOrders = getDisableAccountStructureChangeSelector()(store.getState(), {clientId: account.clientId});
    if (rejectOnActiveOrders) {
        returnObject.modalKey = 'accountForm.modalActiveOrders';
        returnObject.canBeDisabled = false;
    }
    else if (account.childAccounts && account.childAccounts.length) {
        let childrenNames = "";
        for (let key in account.childAccounts) {
            childrenNames = childrenNames + "- " + account.childAccounts[key].name + "\n";
        }
        returnObject.modalKey = 'accountForm.modalAccountIsParent';
        returnObject.modalParams = {name: account.name, children: childrenNames}
        returnObject.canBeDisabled = false;
    }
    return returnObject;
}

export function accountAllowedAsParent(potentialParent, potentialChild, accountList, transferSupported) {
    if (potentialChild.id === potentialParent.id) return false;

    // Check if bankAccount can be used as parent without creating circular tree structure or inactive root bankAccount.
    if (selectedParentIsChildOrNotActive(accountList, potentialParent, "parentAccountId", potentialChild.id)) return false;

    if (Number(potentialChild.currencyId) !== potentialParent.currencyId) {
        if (Number(potentialChild.legalEntityUnitId) !== potentialParent.legalEntityUnitId) return false;
        if (Number(potentialChild.institutionId) !== potentialParent.institutionId) return false;
        if (potentialParent.legalEntityUnit.fxEnabled === false) return false;
        return true;
    }
    if (!transferSupported) return false;
    return true;
}
/*function setNoCalcStyle(field) {
    let outStyle = {textAlign: "right", color: 'var(--xpool-body-text-color)', fontWeight: 'var(--xpool-standard-font-weight)'};
    if (field === null) {
        outStyle = {...outStyle, fontStyle: "italic", color: '#AAAAAA', fontWeight: '200'};
    }
    return outStyle;
}*/

function enrichStyleWithBalanceBackground(ledgerAccount, bankAccount, style) {
    if (ledgerAccount?.requestStatus && (ledgerAccount.requestStatus !== "OK")) {
        return {...style, background: 'rgba(255,150,130,0.5)'}
    }
    if (bankAccount && bankAccount.balanceRunState) {
        if (bankAccount.balanceRunState === "RESTRUCTURING") return style;
        if (bankAccount.balanceRunState === 'COMPLETED') return {...style, background: 'rgba(178,255,183,0.5)'};
        if (bankAccount.balanceRunState === 'FAILED')    return {...style, background: 'rgba(255,150,130,0.5)'};
        if (['WAIT_FOR_BALANCE', 'WAIT_FOR_RETRY']
            .includes(bankAccount.balanceRunState))      return {...style, background: 'rgba(250,255,152,0.5)'};
    }
    return style;
}

function enrichStyleToCompareToPoolParams(ledgerAccount, bankAccount, style) {
    if (!ledgerAccount || (ledgerAccount.balance === null) || (ledgerAccount.balance === undefined)) {
        return {...style, fontStyle: "italic", color: '#AAAAAA', fontWeight: '200'};
    }
    if (!bankAccount || !bankAccount.parentAccountId || !bankAccount.runConfiguration || !bankAccount.runConfiguration.sweepAndTopUp || !bankAccount.runConfiguration.sweepAndTopUp[0]) return style;

    if (bankAccount.runConfiguration.sweepAndTopUp[0].useSweep && (ledgerAccount.balance > bankAccount.runConfiguration.sweepAndTopUp[0].sweepThreshold)) {
        return {...style, color: '#0000CC', fontWeight: '400'};
    } else if (bankAccount.runConfiguration.sweepAndTopUp[0].useTopUp  && (ledgerAccount.balance < bankAccount.runConfiguration.sweepAndTopUp[0].topUpThreshold)) {
        return {...style, color: '#CC0000', fontWeight: '400'};
    }
    return style;
}

function showCashPoolState(selectObject, rowObject, value) {
    if (!rowObject?.cashPoolState || (rowObject?.cashPoolState === 'IN_BALANCE')) return value;
    return <XpTranslated trKey={'accountsList.cashPoolState.' + rowObject.cashPoolState}/>;
}
function setWaitingSpinner(selectObject, rowObject, value) {
    if (!rowObject?.cashPoolState || (rowObject?.cashPoolState === 'IN_BALANCE')) return false;
    return true;
}

export function prepareAccountGridColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'name',            120, 50, 'accountsList.name');
    xpColCreate(translate, subArray, hiddenState, 'currencyT',       100, 30, 'accountsList.currency');
    xpColCreate(translate, subArray, hiddenState, 'integrationType', 130, 50, 'accountsList.integrationType');
    xpColCreate(translate, subArray, hiddenState, 'legalOwnerName',  200, 50, 'accountsList.legalOwnerName');
    xpColCreate(translate, subArray, hiddenState, 'businessUnitName',200, 50, 'accountsList.businessUnitName');
    xpColCreate(translate, subArray, hiddenState, 'description',     250, 50, 'accountsList.description');
    xpColCreate(translate, subArray, hiddenState, 'institutionName', 200, 50, 'accountsList.institutionName');
    xpColCreate(translate, subArray, hiddenState, 'clearingNumber',  100, 50, 'accountsList.clearingNumber');
    xpColCreate(translate, subArray, hiddenState, 'accountNumber',   150, 50, 'accountsList.accountNumber');
    xpColCreate(translate, subArray, hiddenState, 'bic',             110, 50, 'accountsList.bic');
    xpColCreate(translate, subArray, hiddenState, 'iban',            200, 50, 'accountsList.iban');
    xpColCreate(translate, subArray, hiddenState, 'barRunStateT',100, 50, 'accountsList.accountRunState', {
        cellRenderer: 'xpTranslatedRenderer',
        filter: false,
        cellStyle: function(params) {
            return {background: workflowConfig.BarWorkflow.getStatusBackgroundColor(params.data)}
        }});
    xpColCreate(translate, subArray, hiddenState,'barNextRunTime', 200, 100, 'clientsList.runPoolProcessNextRunTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState, 'id',              100, 50, 'accountsList.systemId');
    xpColHeadCreate(translate, outArray, subArray, 'accountsList.accountDetailsGroup');


    xpColCreate(translate, subArray, hiddenState, 'creditTypeT'     ,   100, 50, 'accountsList.creditType'     , {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: window.oxygen_env.AVAILABLE_CREDIT_TYPES.map(item => 'accountForm.creditType.values.' + item.id),
        },
    });
    xpColCreate(translate, subArray, hiddenState, 'availableCredit' ,   120, 50, 'accountsList.availableCredit', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColHeadCreate(translate, outArray, subArray, 'accountsList.accountCreditGroup');

    xpColCreate(translate, subArray, hiddenState, 'ledgerBalance',      120, 50, 'accountsList.ledgerBalance',     {
        cellRenderer: 'xpGridObjectPropertyRenderer',
        cellRendererParams: {
            getSelectObjectSelector: getLedgerAccountSelector,
            getRowObjectSelector: getSpecificBankAccountSelector,
            selectObjectOrmModel: 'LedgerAccount',
            selectObjectIdField: 'ledgerBalanceLaId',
            displayField: 'balance',
            getStyle: (selectObject, rowObject) => {
                let outStyle = {};
                outStyle = enrichStyleToCompareToPoolParams(selectObject, rowObject, outStyle);
                outStyle = enrichStyleWithBalanceBackground(selectObject, rowObject, outStyle);
                return outStyle;
            },
            getTooltipTrKey: (selectObject, rowObject) => {
                if (!selectObject?.requestStatus) return undefined;
                if (selectObject?.requestStatus == "OK") return undefined;
                return 'reloadAccountBalances.requestStatusCodes.' + selectObject?.requestStatus;
            },
            getTooltipTrParams: (selectObject, rowObject) => {
                if (!selectObject?.requestStatus) return undefined;
                if (selectObject?.requestStatus == "OK") return undefined;
                return {
                    errorCode: selectObject.errorCode,
                    errorDescription: selectObject.errorDescription
                }
            }
        },
        cellStyle: {textAlign: "right"}
    });
    xpColCreate(translate, subArray, hiddenState, 'ledgerBalanceTime',      120, 50, 'accountsList.balanceTime',     {
        cellRenderer: 'xpGridObjectPropertyRenderer',
        cellRendererParams: {
            getSelectObjectSelector: getLedgerAccountSelector,
            getRowObjectSelector: getSpecificBankAccountSelector,
            selectObjectOrmModel: 'LedgerAccount',
            selectObjectIdField: 'ledgerBalanceLaId',
            displayField: 'lastModified',
            xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss',
            getStyle: (selectObject, rowObject) => {
                let outStyle = {};
                outStyle = enrichStyleWithBalanceBackground(selectObject, rowObject, outStyle);
                return outStyle;
            },
        },
        cellStyle: {textAlign: "right"}
    });
    xpColCreate(translate, subArray, hiddenState, 'pendingBalance',      120, 50, 'accountsList.pendingBalance',     {
        cellRenderer: 'xpGridObjectPropertyRenderer',
        cellRendererParams: {
            getSelectObjectSelector: getLedgerAccountSelector,
            getRowObjectSelector: getSpecificBankAccountSelector,
            selectObjectOrmModel: 'LedgerAccount',
            selectObjectIdField: 'pendingBalanceLaId',
            displayField: 'balance',
            getStyle: (selectObject, rowObject) => {
                let outStyle = {};
                outStyle = enrichStyleToCompareToPoolParams(selectObject, rowObject, outStyle);
                return outStyle;
            },
            enrichRenderValue: showCashPoolState,
            setWaitingSpinner: setWaitingSpinner
        },
        cellStyle: {textAlign: "right"}
    });
    xpColCreate(translate, subArray, hiddenState, 'postPoolingBalance',      120, 50, 'accountsList.postPoolingBalance',     {
        cellRenderer: 'xpGridObjectPropertyRenderer',
        cellRendererParams: {
            getSelectObjectSelector: getLedgerAccountSelector,
            getRowObjectSelector: getSpecificBankAccountSelector,
            selectObjectOrmModel: 'LedgerAccount',
            selectObjectIdField: 'postPoolingBalanceLaId',
            displayField: 'balance',
            getStyle: (selectObject, rowObject) => {
                let outStyle = {};
                outStyle = enrichStyleToCompareToPoolParams(selectObject, rowObject, outStyle);
                return outStyle;
            },
            enrichRenderValue: showCashPoolState,
            setWaitingSpinner: setWaitingSpinner
        },
        cellStyle: {textAlign: "right"}
    });
    xpColHeadCreate(translate, outArray, subArray, 'accountsList.accountBalancesGroup');

    xpColCreate(translate, subArray, hiddenState, 'aggregatedBalance',      120, 50, 'accountsList.aggregatedBalance',     {
        cellRenderer: 'xpGridObjectPropertyRenderer',
        cellRendererParams: {
            getSelectObjectSelector: getBankAccountAggregatesSelector,
            getRowObjectSelector: getSpecificBankAccountSelector,
            selectObjectOrmModel: 'BankAccountAggregates',
            selectObjectIdField: 'id',
            displayField: 'ledgerAggregatedBalance',
        },
        cellStyle: {textAlign: "right"}
    });
    xpColCreate(translate, subArray, hiddenState, 'aggregatedPostPoolingBalance',      120, 50, 'accountsList.aggregatedPostPoolingBalance',     {
        cellRenderer: 'xpGridObjectPropertyRenderer',
        cellRendererParams: {
            getSelectObjectSelector: getBankAccountAggregatesSelector,
            getRowObjectSelector: getSpecificBankAccountSelector,
            selectObjectOrmModel: 'BankAccountAggregates',
            selectObjectIdField: 'id',
            displayField: 'postPoolingAggregatedBalance',
        },
        cellStyle: {textAlign: "right"}
    });
    xpColHeadCreate(translate, outArray, subArray, 'accountsList.aggregatedBalancesGroup');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}

export function handleBalanceRequestResponse(response) {
    return (dispatch, getState) => {
        const bankAccount = bankAccountSelector(getState(), {selectId: response.bankAccountId});
        if (!bankAccount) {
            console.warn("Could not find bank account from balance request response", response);
            return;
        }
        if (!response?.bankAccountBalanceResponse) {
            console.warn("No response infor in balance request response", response);
            return;
        }
        dispatch(ormEntityUpdated('LedgerAccount', {
            id              : bankAccount.ledgerBalanceLaId,
            requestStatus   : response.bankAccountBalanceResponse.requestStatus,
            errorCode       : response.bankAccountBalanceResponse.errorCode,
            errorDescription: response.bankAccountBalanceResponse.errorDescription,
        }));
    };
}

export function bankAccountHasActiveSweepOrTopUpRunConfiguration(bankAccount) {
    const sweepAndTopUpRunConfigurations = bankAccount?.runConfiguration?.sweepAndTopUp
    if (!bankAccount.parentAccountId) {
        return false;
    }
    if (!sweepAndTopUpRunConfigurations) {
        return false;
    }
    for (const rc of sweepAndTopUpRunConfigurations) {
        if (!rc.isActive) {
            continue;
        }
        if (rc.useSweep || rc.useTopUp) {
            return true;
        }
    }
    return false;
}
