import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {XpFormContext} from "gui-common/xpForm/core/XpForm";
import {useXpFormContext} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormGetFieldValueFromData} from "gui-common/xpForm/core/xpFormFunctions";


function XpFormFieldset (props) {
    const formContext       = useXpFormContext();

    const fieldModel        = useMemo(
        () => {
            if (props.fieldModel) return props.fieldModel;
            if (!props.field) return formContext?.formModel;
            if (formContext?.formModel) return formContext.formModel + '.' + props.field;
            return "";
        },
        [props.fieldModel, props.field, formContext]
    )

    const fieldSetPath = useMemo(
        () => {
            if (!fieldModel.includes(formContext.formModel)) {
                console.log("formModel not included in fieldModel in XpForm fieldset!", fieldModel, formContext);
                return "";
            }
            return fieldModel.substring(formContext.formModel.length+1);
        },
        []
    );

    const newContextData = useMemo(
        () => {
            return {
                ...formContext,
                currentData      : xpFormGetFieldValueFromData(formContext.currentData     , fieldSetPath),
                beforeChangeData : xpFormGetFieldValueFromData(formContext.beforeChangeData, fieldSetPath),
                formModel        : fieldModel,
                formTemplate     : props.formTemplate ? props.formTemplate : fieldModel,
                ...props.consumerProps,
            }
        },
        [formContext, props.formTemplate, props.formModel, props.consumerProps],
    );

    return (
        <div className={props.className}>
            <XpFormContext.Provider value={newContextData}>
                {props.children}
            </XpFormContext.Provider>
        </div>
    )
}
XpFormFieldset.propTypes = {
    field        : PropTypes.string, //oneOfPropsRequired(['field', 'fieldModel'], ['string', 'string']),
    fieldModel   : PropTypes.string, //oneOfPropsRequired(['field', 'fieldModel'], ['string', 'string']),
    formTemplate : PropTypes.string,
    consumerProps: PropTypes.object,
};
export default XpFormFieldset



