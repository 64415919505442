import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {requestOrderActionOnApi} from "xpool-gui-common/features/order/orderApi"
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {pushModalWindow} from "redux-promising-modals";
import {prepareOrdersColumns} from "xpool-gui-common/features/order/orderFunctions";
import {workflowConfig} from "appConfig/runState/runStateConstants";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {domainRightAllowed} from "gui-common/functions/userRights";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {allOrdersListDataSelector} from "xpool-gui-common/features/order/orderSelectors";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


const ordersHiddenState = {
    id                      : true,

    // clientId            : false,
    // clientName          : false,

    sourceAccountName       : false,
    sourceAmountCcy         : false,
    destinationAccountName  : false,
    destinationAmountCcy    : false,

    orderType               : false,
    adjustedSideT           : false,
    currencyPairStr         : true,
    externalReference       : true,
    settlementDate          : true,

    nominalAmountCcy        : false,
    counterAmountCcy        : false,
    displayRate             : false,

    displayStateT           : false,

    createdDateTime         : true,
    modifiedDateTime        : true,
    executionDateTime       : false,
};

const actionToDomainRightMap = {
    CANCEL      : "OrderCancel",
    SEND        : "OrderExecute",
    RELEASE_HOLD: "OrderRelease",
    ON_HOLD     : "OrderHold",
}

const orderStateToActionsMap = {
    SUGGESTED : ["ON_HOLD", "SEND"],
    TO_MARKET : ["CANCEL"],
    IN_MARKET : ["CANCEL"],
    PENDING   : [],
    EXECUTED  : [],
    IN_BALANCE: [],
    REJECTED  : [],
    FAILED    : [],
    CANCELLED : [],
    RETRY     : ["ON_HOLD", "SEND"],
    ON_HOLD   : ["RELEASE_HOLD"],
}

OrdersList.detailIcon = 'swap_horizontal_circle';
OrdersList.detailIconToolTipKey = 'orderList.detailIconToolTipKey';
function OrdersList (props) {

    const ormActiveClient = useSelector(shallowActiveClientSelector);
    const dispatch = useDispatch();
    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((data  ) => {
            if (props.itemSelectedCallback) props.itemSelectedCallback(data);
            if (props.instanceId === 'cashPoolOrderList')
                dispatch(xpGridSetSelected('cashPoolAccountingOrderList', data?.id));
        }, []),
        gridContextMenuItems    : useCallback(
            (params) => {
                let menuItems = [];
                const orderData = (params && params.node) ? params.node.data : null;
                if (!orderData) return menuItems;
                if (!orderData.actions) return menuItems;

                const availableActions = orderStateToActionsMap[params.node.data.state];
                if (!availableActions) return menuItems;

                // for (let action of orderData.actions) {
                for (let action of availableActions) {
                    if (!actionToDomainRightMap[action]) continue;
                    if (!domainRightAllowed('BankAccount', orderData.client, actionToDomainRightMap[action])) continue;
                    let menuItem = {
                        name: translateRef.current("orderActions." + action + ".menuItem"),
                        action: function () {
                            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: "orderActions." + action}))
                                .then(({status}) => {
                                    if (status === MODAL_TYPE_CONFIRM) {
                                        dispatch(requestOrderActionOnApi(orderData.id, action));
                                    }
                                    if (status === MODAL_TYPE_CANCEL) {}
                                });
                        },
                        disabled: (action === "send" && (ormActiveClient && workflowConfig.ClientWorkflow.isRunning(ormActiveClient.cashPoolsRunState))),
                    };
                    menuItems.push(menuItem);
                }
                return menuItems;
            }, [translate, ormActiveClient]),
    }

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('ordersList.noOrdersToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? false : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                rowDataSelector         : props.rowDataSelector ? props.rowDataSelector : allOrdersListDataSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'Order',
                rowClassRules           : {
                    'xp-grid-inBalance-order-row': params =>
                    {
                        return !params.data.isSelectedInCashPool && (params.data.execution.state === "IN_BALANCE")
                    },
                    'xp-grid-executed-order-row' : params => !params.data.isSelectedInCashPool && (params.data.execution.state === "EXECUTED"  ),
                    'xp-grid-rejected-order-row' : params => !params.data.isSelectedInCashPool && (params.data.execution.state === "REJECTED"  ),
                    'xp-grid-onHold-order-row'   : params => !params.data.isSelectedInCashPool && (params.data.execution.state === "ON_HOLD"   ),
                    'xp-grid-selected-order-row' : params =>  params.data.isSelectedInCashPool
                }
            };
        },
        [translate, props.rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareOrdersColumns(translate, props.hiddenState ? props.hiddenState : ordersHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={"ordersList" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}
export default OrdersList
