import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import removeIcon from "gui-common/resources/removeIcon.png";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {negativeNumberInputParser} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormChangeField, xpFormLoadForm} from "gui-common/xpForm/core/xpFormReducer";
import moment from "moment";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {isArray, isObject} from "lodash";
import {
    getTestBalanceFromApi,
    overrideTestBalanceToApi
} from "xpool-gui-common/features/balanceApiSimulator/balanceApiSimulatorApi";
import {bankAccountsSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";

function RenderField (props) {
    const decDenLangState = useSelector(selectDecDenLangState);
    return (
        <div style={{position: 'relative'}} >
            <XpFormTextInput
                alignRight noTemplate
                isRequired={props.isRequired}
                defaultValue={props.defaultValue}
                inLineLayout={!props.notInline}
                inLineDivider={props.split ? props.split : 40}
                label={props.label}
                field         = {props.field}
                updateOn      = "blur"
                parser={props.noParser ? undefined : value => negativeNumberInputParser(value, decDenLangState)}
            />
            {((props.currentValue !== null) && (props.currentValue !== undefined)) &&
            <div style={{position: 'absolute', top: (props.notInline ? '53px' : '20px'), left:' 0px', fontSize: '12px', color: 'var(--xpool-primary-color-x-strong)'}}>{
                props.fixedDecimals ? props.currentValue.toFixed(props.fixedDecimals) : props.currentValue
            }</div>}
        </div>
    )
}


function BalanceApiSimulatorConfigItem (props) {
    const allAccounts = useSelector(bankAccountsSelector);

    const dispatch        = useDispatch();

    const [accountBalance, setAccountBalance] = useState({bankAccountId: props.bankAccountId});
    const [fetchingBalance, setFetchingBalance] = useState(false);
    const [lastUpdate, setLastUpdate] = useState(null);

    const formModel = "balanceApiSimulator" + '.' + props.bankAccountId;
    const formData   = useXpFormField(formModel);

    const thisAccountName = useMemo(
        () => {
            const thisAccount = allAccounts.find(item => item.id === props.bankAccountId);
            return thisAccount ?
                thisAccount.currency.currency + ' (' + thisAccount.id + ') - ' + thisAccount.name
                : "Not found"
        },
        [props.bankAccountId, allAccounts]
    );

    const thisClientName = useMemo(
        () => {
            const thisAccount = allAccounts.find(item => item.id === props.bankAccountId);
            return thisAccount ? thisAccount.client.name : "Not found"
        },
        [props.bankAccountId, allAccounts]
    );

    useEffect(
        () => {
            if (!formData?.lastUpdate || !lastUpdate || moment.utc(formData?.lastUpdate).isAfter(lastUpdate)) {
                fetchCurrentBalance()
            }
        },
        [formData?.lastUpdate],
    );


    function checkBalanceDataAndSetFormField(balanceData, formSubData, FormSubModel) {
        if (!balanceData || !formSubData) {
            return;
        }
        for (const key in balanceData) {
            if (balanceData[key] === null) {
                continue;
            }
            if (formSubData[key] !== balanceData[key]) {
                dispatch(xpFormChangeField(FormSubModel + '.' + key, balanceData[key]));
            }
        }
    }
    useEffect(
        () => {
            if (!formData) {
                return;
            }
            checkBalanceDataAndSetFormField(accountBalance?.balanceOverride           , formData                 , formModel);
            checkBalanceDataAndSetFormField(accountBalance?.functionOverride?.balance , formData.balanceFunction , formModel + '.balanceFunction');
        },
        [accountBalance],
    );

    function  fetchCurrentBalance() {
        setFetchingBalance(true);
        dispatch(getTestBalanceFromApi(props.bankAccountId))
            .then(resp => {
                setFetchingBalance(false);
                if (!resp?.response) {
                    return;
                }
                setAccountBalance(resp.response);
                const now = moment().utc().milliseconds(0);
                const nowStr = now.toISOString();
                dispatch(xpFormChangeField(formModel + '.lastUpdate', nowStr));
                setLastUpdate(now);
            });
    }

    function getFunctionOverride(functionFormData) {
        if (!functionFormData?.function) return null;
        return {
            function       : functionFormData.function        ? functionFormData.function        : null,
            responseDelay  : functionFormData.responseDelay   ? functionFormData.responseDelay   : null,
            errorCode      : functionFormData.errorCode       ? functionFormData.errorCode       : null,
            errorMessage   : functionFormData.errorMessage    ? functionFormData.errorMessage    : null,
        }
    }

    function  overrideCurrentBalance() {
        setFetchingBalance(true);
        dispatch(overrideTestBalanceToApi(
            {
                bankAccountId: props.bankAccountId,
                balanceOverride: {
                    balance       : formData?.balance  ? formData?.balance : null,
                    bankAccountId : props.bankAccountId,
                },
                functionOverride: {
                    balance                   : getFunctionOverride(formData?.balanceFunction),
                }
            }
        )).then(resp => {
            if (formData?.balanceFunction?.function === "") {
                dispatch(xpFormChangeField(formModel + '.balanceFunction', {}));
            }
            setFetchingBalance(false);
            fetchCurrentBalance();
        });
    }
    function  clearOverride() {
        setFetchingBalance(true);
        dispatch(overrideTestBalanceToApi(
            {
                bankAccountId: props.bankAccountId,
                balanceOverride: null,
                functionOverride: {
                    balance : null,
                }
            }
        )).then(resp => {
            setFetchingBalance(false);
            dispatch(xpFormLoadForm(formModel, {}, XP_FORM_EDIT));
            fetchCurrentBalance();
        });
    }
    function formIsDifferentFromBalance(form, balance) {
        if (!form) {
            return false;
        }
        for (const field in form) {
            if (field.includes('$') || (field === 'lastUpdate') || isObject(form[field] || isArray(form[field]))) {
                continue;
            }
            if (!balance || !balance[field]) {
                if (form[field]) {
                    return true;
                }
                else {
                    continue;
                }
            }
            if (String(form[field]) !== String(balance[field])) {
                return true;
            }
        }
        return false;
    }
    const overrideChanged = useMemo(
        () => {
            if (!formData || !accountBalance) {
                return false;
            }
            if (formIsDifferentFromBalance(formData               , accountBalance.balanceOverride)) {
                return true;
            }
            if (formIsDifferentFromBalance(formData.balanceFunction         , accountBalance.functionOverride?.balance)) {
                return true;
            }
            return false;
        },
        [formData, accountBalance]
    );

    return (
        <div className={'marketSimulatorConfigItem'} key={props.key}>
            <div style={{position: 'absolute', top   : '2px', left:' 4px', fontSize: '11px'}}>{thisClientName}</div>

            <XpForm
                formModel={formModel}
                onSubmit={(data) => {
                }}
                initialUseState={XP_FORM_EDIT}
                // currentData={clientPrice?.clientPrice}
            >
                <div style={{display: 'flex'}}>
                    <div style={{width: '300px', minWidth: '300px'}}>
                        <h3>{thisAccountName}</h3>
                        <img
                            style={{height: '18px', marginTop: "-4px", float: 'none'}}
                            src={removeIcon} alt="Remove"
                            className="closeDashboardComponentButton"
                            onClick={() => props.removeMe()}
                        />
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            label           = "Fetch"
                            onClickCallback = {() => fetchCurrentBalance()}
                            waiting={fetchingBalance}
                        />
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            label           = "Override"
                            onClickCallback = {() => overrideCurrentBalance()}
                            disabled = {!overrideChanged}
                            waiting  = {fetchingBalance}
                        />
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            label           = "Clear all"
                            onClickCallback = {() => clearOverride()}
                            waiting  = {fetchingBalance}
                        />
                    </div>

                    <div style={{display: 'flex', flexWrap: 'wrap', width: 'calc(100% - 170px)', overflow: 'auto'}}>
                        <div style={{borderRadius: '3px', padding: '3px', background: '#e2f9ff', marginRight: '10px'}}>
                            <div style={{display: 'flex', marginLeft: '0px'}}>
                                <div style={{width: '110px'}}>
                                    <RenderField label={"Balance: "   } field='balance' currentValue={accountBalance?.currentBalance?.balance} />
                                </div>
                            </div>
                        </div>

                        <div style={{borderRadius: '3px', display: 'flex', padding: '3px', background: '#ffe7e0', marginRight: '10px'}}>
                            <div style={{width: '220px', marginLeft: '0px'}}>
                                <XpFormSelectorInput
                                    noLabel
                                    template={'No balance function'}
                                    field="balanceFunction.function"
                                    selectList={['FAIL', 'IGNORE', 'DELAY'].map(item => ({id: item, name: 'Request ' + item}))}
                                />

                                {['FAIL', 'DELAY'].includes(formData?.balanceFunction?.function) &&
                                <div style={{marginTop: '10px'}}>
                                    <RenderField label={"Response delay (ms):"} field='balanceFunction.responseDelay' split={30} currentValue={accountBalance.functionOverride?.balance?.responseDelay}/>
                                </div>}
                                {(formData?.balanceFunction?.function === 'FAIL') &&
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '70px'}}>
                                        <RenderField label={"Code: "} field='balanceFunction.errorCode'    split={45}  currentValue={accountBalance.functionOverride?.balance?.errorCode} isRequired defaultValue={99}/>
                                    </div>
                                    <div style={{width: '150px', marginLeft: '10px'}}>
                                        <RenderField label={"Msg: "}  field='balanceFunction.errorMessage' split={75}  currentValue={accountBalance.functionOverride?.balance?.errorMessage} noParser/>
                                    </div>
                                </div>}
                            </div>
                        </div>

                    </div>
                </div>
            </XpForm>
        </div>
    )
}
BalanceApiSimulatorConfigItem.propTypes = {
    bankAccountId: PropTypes.number.isRequired,
    removeMe : PropTypes.func,
};
export default BalanceApiSimulatorConfigItem
