import React, { Component } from 'react'
import {connect} from "react-redux";
import Draggable from 'react-draggable';
import {dashboardDropItem, dragging, dashboardDragItem, dashboardClickDropItem} from 'gui-common/dashboard/dashboardReducer';
import MaterialIcon/*, {colorPalette}*/ from 'material-icons-react';
import XpTooltip from "gui-common/components/XpTooltip";
import {dashboardConfig} from "./dashboardConfig";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


class DashboardIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {key: this.props.keyName + "_1", eX: 0, eY:0};
        this.defaultSize = dashboardConfig[this.props.componentName] ? dashboardConfig[this.props.componentName].defaultSize : undefined;
    }
    static counter(prevKey) {
        let newV = prevKey.split('_');
        let count = parseInt(newV[1], 10);
        return newV[0] + '_' + (count + 1);
    }
    beforeDrag = function(e) {
        this.setState({...this.state, eX: e.clientX, eY: e.clientY});
    };
    startDrag = function(e) {
        this.props.dragging(true);
    };
    stopDrag = function(e) {
        this.props.dragging(false);
        if (e.clientX === this.state.eX && e.clientY === this.state.eY)
            this.props.dashboardClickDropItem({x : e.screenX, y: e.screenY, componentName: this.props.componentName, sizeInfo: this.defaultSize});
        else
            this.props.dashboardDropItem({x : e.screenX, y: e.screenY, componentName: this.props.componentName, sizeInfo: this.defaultSize});

        this.setState({key : DashboardIcon.counter(this.state.key)});
    };
    whileDragging(x, y) {
        let inCanvas = false;
        if(this.props.dashboardState.sizeInfo.top && this.props.dashboardState.sizeInfo.left && this.props.dashboardState.sizeInfo.right) {
            if (x.clientY > this.props.dashboardState.sizeInfo.top && x.clientX > this.props.dashboardState.sizeInfo.left && x.clientX < this.props.dashboardState.sizeInfo.right) {
                inCanvas = true;
            }
        }
        if(inCanvas) {
            let xPosition = Math.floor(x.clientX / (this.props.dashboardState.sizeInfo.panelWidth / this.props.dashboardState.sizeInfo.cols));
            let yPosition = Math.floor((x.clientY - this.props.dashboardState.sizeInfo.top - this.props.dashboardState.yFactor )/ this.props.dashboardState.sizeInfo.rowHeight);
            yPosition = yPosition < 0 ? 0 : yPosition;
            //console.log('dragging x:', xPosition, 'dragging y:', yPosition);
            this.props.dashboardDragItem({
                clientX: xPosition,
                clientY: yPosition,
                iconName: this.props.iconName,
                sizeInfo: dashboardConfig[this.props.componentName] ? dashboardConfig[this.props.componentName].defaultSize : undefined,
            }); //Todo use icon id instead for performance improvement
        }
        //console.log('w dragging:', x.clientY);
        //console.log('y dragging:', y);

    }

    render() {
        // console.log('cname:', this.props.componentName);
        return(
            <Draggable
                axis="both"
                onStart={this.startDrag.bind(this)}
                onDrag={this.whileDragging.bind(this)}
                onStop={this.stopDrag.bind(this)}
                onMouseDown={this.beforeDrag.bind(this)}
                key={this.state.key}
            >
                <div className="dashboardIcon" xp-test-id='xpDashboardIcon'>
                    <XpTooltip trKey={dashboardConfig[this.props.componentName].langPath + "toolTip"}>
                        <div className="center-div">
                            <span className="icon-i"><MaterialIcon icon={this.props.iconName} size={18} invert/></span>
                        </div>
                    </XpTooltip>
                </div>
            </Draggable>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboardState:         state.dashboardState,
        translate:          selectTranslateFunction(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dashboardDropItem:  (position)      => dispatch(dashboardDropItem(position)),
        dragging:           (isDragging)    => dispatch(dragging(isDragging)),
        dashboardDragItem:  (dragKey)       => dispatch(dashboardDragItem(dragKey)),
        dashboardClickDropItem: (position)  => dispatch(dashboardClickDropItem(position)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardIcon);
