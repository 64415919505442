import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useXpFormFieldErrors} from "gui-common/xpForm/core/xpFormHooks";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


export function xpFormGenerateError(errorKey, errorData, index, props, translate) {

    if (props.errorMessages && props.errorMessages[errorKey]) return <span key = {index}>{props.errorMessages[errorKey]}</span>;

    const formTemplate = props.formTemplate ? props.formTemplate : props.formContext.formTemplate;
    let trKey    = (props.fieldTemplate ? props.fieldTemplate : formTemplate + "." + props.field) + ".errors." + errorKey;
    let trParams = (typeof errorData === 'object') ? errorData : {errorResult: errorData};
    if (props[errorKey]) trParams.checkValue = props[errorKey];
    if (props.errorProps && props.errorProps[errorKey]) {
        if (props.errorProps[errorKey].trKey)    trKey    = props.errorProps[errorKey].trKey;
        if (props.errorProps[errorKey].trParams) trParams = {...trParams, ...props.errorProps[errorKey].trParams};
    }

    const checkTranslation = translate(trKey);
    if (checkTranslation.includes("Missing translation")) trKey = "general.errors." + errorKey;

    return <span key = {index}><XpTranslated key = {index} trKey={trKey} trParams={trParams}/></span>
}

export function XpFormErrors(props) {
    useXpFormFieldErrors(props.errors, props.fieldModel, props.formContext, props.fieldState, props.dependentFields, props.dependentData);
    const noFormValidation = useAppEnvProperty('noFormValidation');

    const translate = useSelector(selectTranslateFunction);

    const errors = useMemo(
        () => {
            if (props.disableErrors) return;
            if (noFormValidation) return;
            if (props.formContext.useMuiFieldStyle) return;
            if (!props.fieldState) return;
            if (!props.fieldState.errors) return;
            if (!props.fieldState.isTouched) return;
            if (props.inCellError) return;

            return Object.keys(props.fieldState.errors).map((errorKey, index) => xpFormGenerateError(errorKey, props.fieldState.errors[errorKey], index, props, translate));
        },
        [translate, props.fieldState?.errors, props.fieldState?.isTouched, props.disableErrors, noFormValidation]
    );

    if (!errors) return null;
    return (
        <div className = "xpFormError xpFormErrorBelow">
            {errors}
        </div>
    )
}
