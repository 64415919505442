import {createSelector} from "reselect";

const integrationStatusState = state => state.integrationStatusState;
const thisIntegrationStatusInstanceId = (state, props) => props.instanceId;

export const integrationStatusItemStateSelector = createSelector(
    [integrationStatusState, thisIntegrationStatusInstanceId],
    (integrationStatusState, thisIntegrationStatusInstanceId) => {
        if (!thisIntegrationStatusInstanceId) return undefined;
        if (!integrationStatusState.items) return undefined;
        let returnState = integrationStatusState.items.find(item => item.id === thisIntegrationStatusInstanceId);
        if (!returnState) returnState = {id: thisIntegrationStatusInstanceId, showEventList: false}; // Initial state for the list
        return returnState;
    }
);

export const makeIntegrationStatusItemStateSelector = () => {
    return createSelector(
        [integrationStatusState, thisIntegrationStatusInstanceId],
        (integrationStatusState, thisIntegrationStatusInstanceId) => {

            //console.log("Selecting integration status state", thisIntegrationStatusInstanceId);

            if (!thisIntegrationStatusInstanceId) return undefined;
            if (!integrationStatusState.items) return undefined;
            let returnState = integrationStatusState.items.find(item => item.id === thisIntegrationStatusInstanceId);
            if (!returnState) returnState = {id: thisIntegrationStatusInstanceId, showEventList: false}; // Initial state for the list
            return returnState;
        }
    );
};