import React from 'react'
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useSortedAccountsArray} from "features/onboardingWizard/forms/ObWizardAccountSettingsForm";
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import RunConfigurationForm from "gui-common/runConfiguration/RunConfigurationForm";

function SweepAndTopUpAccountForm(props) {
    return (
            <div className="wizardFormCard">
                <h3 className='wizardFormCardHeader'><XpTranslated trKey='onboardingWizardForm.currencyAccountForm.accountHeader' trParams={{currency: props.account.currencyName}}/></h3>

                <XpFormFieldset
                    field={'currencies.' + props.account.originalIndex/* + '.runConfiguration.sweepAndTopUp.0'*/}
                    formTemplate="runConfiguration.sweepAndTopUp"
                >
                    <RunConfigurationForm
                        type     = {'sweepAndTopUp'}
                        ormModel = {'BankAccount'}
                        userCanManage={true}
                        enforceShowSwapCheckbox={true}
                        ownedByParent={true} // Must set this to make sweep and topUp for set currencyId from bank bankAccount (not available in wizard case)
                    />
                </XpFormFieldset>
        </div>
    )
}

function ObWizardSweepAndTopUpForm(props) {
    const accountsArray = useSortedAccountsArray()
    return (
            <div className={"adminFormContainer"}>
                {accountsArray.map((account, index) => {
                    if (index === 0) return null;//topCurrencyRow(bankAccount);
                    return (
                        <SweepAndTopUpAccountForm account={account} index={index} key={index}/>
                    )
                }
            )}
            </div>
    );
}
export default ObWizardSweepAndTopUpForm;

