import cloneDeep from "lodash.clonedeep";

export const INTEGRATION_STATUS_TOGGLE_EVENTS  = 'INTEGRATION_STATUS_TOGGLE_EVENTS';
export const INTEGRATION_STATUS_RELOAD_STATE   = "INTEGRATION_STATUS_RELOAD_STATE";
export const INTEGRATION_STATUS_RESET_STATE    = "INTEGRATION_STATUS_RESET_STATE";

export function integrationStatusToggleEvents(id) {
    return {type: INTEGRATION_STATUS_TOGGLE_EVENTS, payload: id};
}
export function integrationStatusReloadState(newState) {
    return {type: INTEGRATION_STATUS_RELOAD_STATE, payload: newState};
}
export function integrationStatusResetState() {
    return {type: INTEGRATION_STATUS_RESET_STATE, payload: undefined};
}

const initialState = {items: []};

export function integrationStatusReducer(state = initialState, action)
{
    switch (action.type) {
        case INTEGRATION_STATUS_TOGGLE_EVENTS:
            let newState = cloneDeep(state);
            let connectionInstance = newState.items.find(item => item.id === action.payload);
            if (!connectionInstance) {
                newState.items.push({id: action.payload, showEventList: true});
                return newState;
            }
            connectionInstance.showEventList = !connectionInstance.showEventList;
            return newState;
        case INTEGRATION_STATUS_RELOAD_STATE:
            return action.payload;
        case INTEGRATION_STATUS_RESET_STATE:
            return initialState;
        default:
            return state;
    }
}
