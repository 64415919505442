import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import './calculator.css'

function Calculator (props) {

    const [input,   setInput]   = useState("");
    const [result,   setResult]   = useState(0);
    const [showResult,   setShowResult  ] = useState(true);

    const [calcFunction,   setCalcFunction] = useState( undefined);

    const calcFunctions = {
        plus:  (input, result) => input + result,
        minus: (input, result) => result - input,
        multiply: (input, result) => input * result,
        divide: (input, result) => result / input,
    }

    function InputButton(props) {
        return (
            <button
                className='calcButton'
                onClick={() => {
                    setInput(input + props.number);
                    setShowResult(false);
                }}
            >
                {props.number}
            </button>
        )
    }
    function CalculateButton(props) {
        return (
            <button
                className='calcButton'
                onClick={() => {
                    if (calcFunction) {
                        setResult(calcFunctions[calcFunction](Number(input), result));
                    }
                    else if (!result) {
                        setResult(Number(input));
                    }
                    setCalcFunction(props.calcFunction);
                    setShowResult(true);
                    setInput("");
                }}
            >
                {props.showSign}
            </button>
        )
    }
    function EqualsButton(props) {
        return (
            <button
                className='calcButton'
                onClick={() => {
                    if (calcFunction) {
                        setResult(calcFunctions[calcFunction](Number(input), result));
                    }
                    else {
                        setResult(Number(input));
                    }
                    setShowResult(true);
                    setInput("");
                    setCalcFunction(undefined);
                }}
            >
                =
            </button>
        )
    }
    function ResetButton(props) {
        return (
            <button
                className='calcButton'
                onClick={() => {
                    setCalcFunction(undefined);
                    setResult(0);
                    setShowResult(true);
                    setInput("");
                }}
            >
                C
            </button>
        )
    }
    return (
        <div>
            <div>
                {showResult ? result : input}
            </div>
            <div>
                <InputButton number={1} /><InputButton number={2} /><InputButton number={3} />
                <CalculateButton
                    showSign='+'
                    calcFunction='plus'
                />
            </div>
            <div>
                <InputButton number={4} /><InputButton number={5} /><InputButton number={6} />
                <CalculateButton
                    showSign='-'
                    calcFunction='minus'
                />
            </div>
            <div>
                <InputButton number={7} /><InputButton number={8} /><InputButton number={9} />
                <CalculateButton
                    showSign='*'
                    calcFunction='multiply'
                />
            </div>
            <div>
                <ResetButton/><InputButton number={0} /><EqualsButton/>
                <CalculateButton
                    showSign='%'
                    calcFunction='divide'
                />
            </div>
        </div>
    )
}
Calculator.propTypes = {
};
export default withRouter(Calculator)


