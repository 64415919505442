import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {
    prepareLegalEntityUnitsGridColumns
} from "xpool-gui-common/features/legalEntityUnit/legalEntityUnitFunctions";
import XpAdminFormEntityGridTemplate from "gui-common/xpAdminForm/XpAdminFormEntityGridTemplate";
import {getLegalEntityUnitsListDataSelector} from "xpool-gui-common/features/legalEntityUnit/legalEntityUnitSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


export default function LegalEntityUnitsList (props) {

    const translate = useSelector(selectTranslateFunction);

    const columnDefs = useMemo(
        () => {
            const hiddenState = props.hiddenState ? props.hiddenState : {
                name:               false,
                description:        false,
                id:                 true,
                fxProviderName:     false,
                fxShortNameT:       false,
                createdDateTime:    true,
                createdByUser:      true,
                changedDateTime:    true,
                changedByUser:      true,
            };
            return prepareLegalEntityUnitsGridColumns(translate, hiddenState);
        },
        [translate, props.hiddenState]
    );
    return (
        <XpAdminFormEntityGridTemplate
            columnDefs={columnDefs}
            trKeyBase='legalEntityUnitsList'
            ormModel='LegalEntityUnit'
            {...props}
            getRowDataSelector   = {getLegalEntityUnitsListDataSelector}
            rowDataSelectorProps = {props.rowDataSelectorProps}
        />
    )
}
