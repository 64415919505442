import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {prepareLegalEntityGridColumns} from "xpool-gui-common/features/legalEntity/legalEntityFunctions";
import XpAdminFormEntityGridTemplate from "gui-common/xpAdminForm/XpAdminFormEntityGridTemplate";
import {getLegalEntitiesListDataSelector} from "xpool-gui-common/features/legalEntity/legalEntitySelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


export default function LegalEntitiesList (props) {
    const translate = useSelector(selectTranslateFunction);
    const columnDefs = useMemo(
        () => {
            const hiddenState = props.hiddenState ? props.hiddenState : {
                name:               false,
                description:        false,
                id:                 true,
                countryName:        false,
                // legalEntityType:    true,
                lei:                false,
                createdDateTime:    true,
                createdByUser:      true,
                changedDateTime:    true,
                changedByUser:      true,
            };
            return prepareLegalEntityGridColumns(translate, hiddenState);
        },
        [translate, props.hiddenState]
    );

// Skipped parent child feature for LE in oxyGen v2.
/*
    let autoGroupColumnDef = {
        headerName: this.props.translate('legalEntityForm.name.label'),
        headerComponentParams: {trKey: 'legalEntityForm.name.label'},
        width: 250,
        minWidth: 100,
        cellRendererParams: { suppressCount: true },
        valueGetter: (params) => {
            return params.data.name;
        }
    };
    treeData={true}
*/

    return (
        <XpAdminFormEntityGridTemplate
            columnDefs={columnDefs}
            trKeyBase='entitiesList'
            ormModel='LegalEntity'
            {...props}
            getRowDataSelector   = {getLegalEntitiesListDataSelector}
            rowDataSelectorProps = {props.rowDataSelectorProps}
        />
    )
}
