import React from "react";
import {withRouter} from "react-router-dom";
import FxRejectConfigType from "./fxRejectConfigType"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

function FxRejectConfigPage () {
    return (
        <div>
            <h2><XpTranslated trKey={'fxRejectConfig.header'}/></h2>
            <hr/>
            <FxRejectConfigType
                rejectType="Quote"
                formTemplate={"fxRejectConfig"}
            />
            <FxRejectConfigType
                rejectType="Order"
                formTemplate={"fxRejectConfig"}
            />
        </div>
    )
}
export default withRouter(FxRejectConfigPage)
