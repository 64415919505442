import {commonCreateBodyMap, commonTransformerMap} from "gui-common/api/commonTransformers";
import {xpoolCreateBodyMap , xpoolTransformerMap } from "xpool-gui-common/appConfig/api/apiTransformers";

export const apiTransformerMap = {
    ...commonTransformerMap,
    ...xpoolTransformerMap,
};
export const apiCreateBodyMap = {
    ...commonCreateBodyMap,
    ...xpoolCreateBodyMap,
};
