import React, {Component} from "react";
import {getEntityFromId} from "gui-common/functions/functions";
import {connect} from "react-redux";
import {usersSelector} from "gui-common/orm/ormSelectors";

class XpGridUserRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {value: props.value};
    }
    refresh(params) {
        if(params.value !== this.state.value) this.setState({value: params.value});
        return true;
    }

    render () {
        if (!this.state.value) return null;
        const user = (typeof this.state.value === 'object') ? this.state.value : getEntityFromId(Number(this.state.value), this.props.users);
        const userString = user ? (user.firstName + " " + user.lastName) : this.state.value;
        return (
            <span>{userString}</span>
        );
    }
}
const mapStateToProps = (state, props) => {
    return {
        users:              usersSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(XpGridUserRenderer);
