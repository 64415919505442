import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from "prop-types";
import React, {useCallback, useMemo} from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getItemRenderProps} from "gui-common/xpForm/core/xpFormFunctions";
import XpTooltip from "gui-common/components/XpTooltip";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


function XpSelect(props) {
    const translate = useXpTranslateFunction();
    const renderList = useMemo(
        () => {
            let returnArray = [];
            for (const item of props.selectList) {
                returnArray.push(getItemRenderProps(translate, item, props, item.trParams, true));
            }
            return returnArray;
        },
        [props, translate]
    );

    const currentValue = useMemo(
        () => {
            const value = renderList.find(item => String(item.id) === String(props.value));
            return value ? value : renderList[0];
        },
        [props.value, renderList]
    );


    return (
        <Autocomplete
            value={currentValue}
            onChange={(event, item, reason) => {
                if (reason === 'clear') {
                    props.onChange(props.value);
                } else {
                    return props.onChange(item ? item.id : "")
                }
            }}
            //Did not work as intended, ie, resetting to last valid option
            onClose={(event, reason) => {
                if (reason === 'escape') {
                    props.onChange(props.value);
                }
            }}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}

            autoHighlight
            // autoSelect // will set first match on blur. Not as I want it.
            disableClearable
            clearOnEscape
            renderInput={(params) => {
                const displayItem = renderList.find(item => String(item.id) === String(props.value));

                return (
                    <XpTooltip
                        title  = {displayItem?.renderTooltip ? displayItem.renderTooltip(displayItem) : undefined}
                        trKey  = {displayItem?.tooltipTrKey}
                        bypass = {!displayItem?.tooltipTrKey && !displayItem?.renderTooltip}
                    >
                       <TextField {...params} autoFocus={props.autoFocus} label={props.label} variant={'standard'} margin="normal"/>
                    </XpTooltip>
                )
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <RenderValue {...option}/>
                    </li>
                )
            }}
            getOptionDisabled={option => !!option.disabled}
            options={renderList}
            getOptionLabel={(option) => {
                return option.value;
            }}
        />
    )
}



function RenderValue(props) {
    return (
            <XpTooltip
                title  = {props.renderTooltip ? props.renderTooltip(props) : undefined}
                trKey  = {props.tooltipTrKey}
                bypass = {!props.tooltipTrKey && !props.renderTooltip}
            >
                {props.trKey ? <XpTranslated trKey={props.trKey} trParams={props.trParams}/> : <span>{props.value}</span>}
            </XpTooltip>
    )
}

function getSelectListWithTemplate(props) {
    return (props.noTemplate) ? [...props.selectList] : [
        {
            [props.itemTextField ? props.itemTextField : 'name']: props.template ? props.template : "No template", // itemTextField can be "id". Set id after.
            disabled: !props.template,
            id: "",
        }, ...props.selectList
    ]
}

function XpFormSelectorInput (props) {
    const translate = useXpTranslateFunction();
    return (
        <XpFormLabeledInput
            {...props}

            CompToRender={XpSelect}
            // getValueFromEvent={useCallback(event => event.target.value, [])}
            getValueFromEvent={useCallback(event => event, [])}
            isSelectField
            setTouchedOnChange
            // noTemplate={true}

            controlProps={useMemo(()=>({
                selectList: getSelectListWithTemplate(props),
                itemTextField: props.itemTextField
            }), [props.selectList])}

            beforeChangeFunction={useCallback((before, after) => {
                let afterChangeValue = (!after) ? "" : after;
                return (String(before) === String(afterChangeValue)) ? null : before;
            }, [])}
            valueRenderer={useCallback((value, props) => {
                if (!props.showTemplateInView && (value === null || value === undefined)) {
                    return null;
                }
                const displayItem = getSelectListWithTemplate(props).find(item => String(item.id) === String(value));
                // return !displayItem ? null : displayItem[props.itemTextField] ? displayItem[props.itemTextField] : displayItem?.id;
                const itemRenderProps = getItemRenderProps(translate,  displayItem ? displayItem : {id: value}, props);

                return <RenderValue {...itemRenderProps}/>
            }, [])}
        />
    )
}

XpFormSelectorInput.propTypes = {
    ...xpFormBasePropTypes,
    selectList: PropTypes.array.isRequired,
    itemTextField: PropTypes.string,
};
export default XpFormSelectorInput;
