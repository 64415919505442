import {createTranslatedFieldData, translateDateFields, xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {apiTransformerMap} from "appConfig/api/apiTransformers"
import {
    auditOperationTypes,
    auditPrototypeMap,
    auditModelsFromRootModel, auditOrmToPrototypeMap, auditConfig
} from "gui-common/audit/auditConstants";
import {pushModalWindow} from "redux-promising-modals";
import {SHOW_AUDIT_HISTORY_DIALOG} from "gui-common/modals/modalConstants";
import {nestedOrmEntities} from "gui-common/orm/ormModels";
import moment from "moment";
import {userCan} from "gui-common/functions/userRights";

export function formatAuditEntriesGridData(dataArray, currentLanguage, translate, rootConfig) {
    for (let item of dataArray) {
        translateDateFields(item, 'lll', currentLanguage);
        item.id = item.id ? item.id : "";
        item.rootObjectId = item.root ? item.root.id : "";
        item.modifiedObjectId = item.modifiedObject ? item.modifiedObject.id : "";

        item.rootObjectName = item.root ? item.root.name : "";
        createTranslatedFieldData(item, 'rootObjectName', translate, rootConfig?.rootProps?.getTrKey ? rootConfig?.rootProps?.getTrKey(item.rootObjectName) : undefined);

        createTranslatedFieldData(item, 'rootObjectType', translate, ('general.modelNamesLarge.' + item.rootModel));

        // These two are not relevant when rendering user actions.
        if (item.operationType) createTranslatedFieldData(item, 'operationType' , translate, ('auditEntriesList.operationType.' + item.operationType));
        if (item.model)         createTranslatedFieldData(item, 'model'         , translate, ('general.modelNamesLarge.' + item.model));

        item.modifiedObjectName = item.modifiedObject ? item.modifiedObject.name : "";
        item.userId = item.user ? item.user.id : "";
        item.userFullName = item.user ? item.user.firstName + " " + item.user.lastName : "";
        item.apiVersion = item.apiVersion ? item.apiVersion : "";
    }
    return dataArray;
}

export function prepareAuditEntriesGridColumns(translate, hiddenState, rootModel) {
    let subArray = [];
    xpColCreate(translate, subArray, hiddenState, 'id'                , 60 , 30, 'auditEntriesList.headers.id');
    xpColCreate(translate, subArray, hiddenState, 'auditDateTime'     , 100, 50, 'auditEntriesList.headers.auditDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState, 'rootObjectTypeT'   , 60 , 30, 'auditEntriesList.headers.rootObjectType', {
        cellRenderer: 'xpTranslatedRenderer',
        filter: false,
    });
    xpColCreate(translate, subArray, hiddenState, 'rootObjectId'      , 60 , 30, 'auditEntriesList.headers.rootObjectId.' + (rootModel ? rootModel : 'noRootModel'));

    // xpColCreate(translate, subArray, hiddenState, 'rootObjectName'    , 120, 50, 'auditEntriesList.headers.rootObjectName.' + (rootModel ? rootModel : 'noRootModel'));
    xpColCreate(translate, subArray, hiddenState, 'rootObjectNameT'   , 120, 50, 'auditEntriesList.headers.rootObjectName.' + (rootModel ? rootModel : 'noRootModel'), {
        cellRenderer: 'xpTranslatedRenderer',
    });

    xpColCreate(translate, subArray, hiddenState, 'operationTypeT'    , 80 , 40, 'auditEntriesList.headers.operationTypeT', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: auditOperationTypes.map(item => 'auditEntriesList.operationType.' + item)
        },
    });
    xpColCreate(translate, subArray, hiddenState, 'modelT'            , 80 , 40, 'auditEntriesList.headers.model', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: (rootModel && auditModelsFromRootModel[rootModel]) ? auditModelsFromRootModel[rootModel].map(item => 'general.modelNamesLarge.' + item) : [],
        },
    });
    xpColCreate(translate, subArray, hiddenState, 'modifiedObjectId'  , 60 , 30, (rootModel === 'AuditUserActionType') ? 'auditEntriesList.headers.notUsed' : 'auditEntriesList.headers.modifiedObjectId');
    xpColCreate(translate, subArray, hiddenState, 'modifiedObjectName', 120, 50, (rootModel === 'AuditUserActionType') ? 'auditEntriesList.headers.effectedClientName' : 'auditEntriesList.headers.modifiedObjectName');
    xpColCreate(translate, subArray, hiddenState, 'userId'            , 60 , 30, 'auditEntriesList.headers.userId');
    xpColCreate(translate, subArray, hiddenState, 'userFullName'      , 160, 50, 'auditEntriesList.headers.userFullName');
    xpColCreate(translate, subArray, hiddenState, 'apiVersion'        , 120, 50, 'auditEntriesList.headers.apiVersion');
    return subArray;
}
export function auditRootModelTransformer(item) {
    const config = auditPrototypeMap[item.prototype];
    if (!config) {
        console.error("Missing transformer prototype in auditRootModelTransformer", item.prototype);
        return item;
    }
    return {
        ...item,
        model : config.model,
        sortOrder: config.rootObjectSortOrder ? config.rootObjectSortOrder : item.sortOrder,
    }
}
export function auditActionTypeTransformer(item) {
    /*
        if (!auditActionTypePrototypeMap[item.prototype]) {
            console.error("Missing transformer prototype in auditActionTypeTransformer", item.prototype);
            return item;
        }
    */
    if (!item.prototype) {
        console.error("Missing transformer prototype in auditActionTypeTransformer", item);
        return null;
    }
    const prototypePathArray = item.prototype.split('.');
    const actionType = prototypePathArray[prototypePathArray.length-1]
    return {
        ...item,
        actionType : actionType,
        name       : actionType,
        // actionType : auditActionTypePrototypeMap[item.prototype].actionType,
        // name: auditActionTypePrototypeMap[item.prototype].actionType
    }
}
export function auditEntryTransformer(item, activeAuditActionTypesMap, auditConfig) {
    let returnObject = {};

    const rootModel = auditPrototypeMap[item.rootPrototype];
    if (!rootModel) {
        console.error("Missing transformer for root prototype in auditEntryTransformer", item.rootPrototype);
        return null;
    }

    if (rootModel.model === 'AuditUserActionType') {
        const actionType   = activeAuditActionTypesMap[item.prototype];
        const actionConfig = actionType ? auditConfig.auditActionTypes[actionType.actionType] : undefined;
        if (!actionType || !actionConfig) {
            console.error("Missing transformer prototype or config for user action in auditEntryTransformer", item);
            return null;
        }
        returnObject.root = {
            name: actionType.actionType,
            id: actionType.id,
        };
        returnObject.name = actionType.actionType;
        returnObject.actionType = actionType.actionType;
        if (item.modified) returnObject.actionData = actionConfig.actionDataTransformer ? actionConfig.actionDataTransformer(item.modified) : item.modified;
        returnObject.modifiedObject = item.modified;
    }
    else {
        const model      = auditPrototypeMap[item.prototype];
        if (!model) {
            console.error("Missing transformer prototype in auditEntryTransformer", item.prototype);
            return null;
        }
        returnObject.model          = model.model;

        if (item.root    ) returnObject.root           = apiTransformerMap[rootModel.model](item.root    , {addTenorBooleansToAgreement: true});
        if (item.before  ) returnObject.before         = apiTransformerMap[rootModel.model](item.before  , {addTenorBooleansToAgreement: true});
        if (item.after   ) returnObject.after          = apiTransformerMap[rootModel.model](item.after   , {addTenorBooleansToAgreement: true});
        if (item.modified) returnObject.modifiedObject = apiTransformerMap[model.model    ](item.modified, {addTenorBooleansToAgreement: true});
        if (item.operationType) returnObject.operationType  = item.operationType;
    }

    returnObject.id             = item.id;
    returnObject.rootModel      = rootModel.model;
    returnObject.user           = item.user     ? apiTransformerMap['User'](item.user) : null;

    if (item.apiVersion)    returnObject.apiVersion     = item.apiVersion;
    if (item.auditDate)     returnObject.auditDateTime  = item.auditDate;

    return returnObject;
}

export function createOpenObjectAuditMenuItem(auditConfig, ormModel, item, dispatch, translate, user) {

    if (!auditConfig.activateObjectAuditLog || !ormModel || !auditOrmToPrototypeMap[ormModel] || !item || !userCan('Read', 'Audit', user)) {
        return;
    }

    return {
        name: translate('showAuditInfo.gridMenu'),
        action: function () {
            this.context.dispatch(pushModalWindow(SHOW_AUDIT_HISTORY_DIALOG,{auditedObject : item, ormModel: ormModel}))
        },
        context: {item: item, dispatch: dispatch},
        disabled: false,
    }
}

export function entityIsDisabled(entity) {
    if (!entity) return false;
    return !entityIsActive(entity);
}
export function entityIsActive(entity) {
    if (!entity) return false;
    if (entity.auditEntries?.length) return (entity.auditEntries[0].operationType !== 'Delete');
    return !entity.disabledByUser;
}
export function getDisableByUser(entity) {
    if (entityIsActive(entity)) return undefined;
    if (entity.auditEntries?.length) return entity.auditEntries[0].user;
    return entity.disabledByUser;
}
export function getLastTouchUser(entity) {
    if (entity.auditEntries?.length) return entity.auditEntries[0].user;
    if (entity.disabledByUser) return entity.disabledByUser;
    if (entity.changedByUser) return entity.changedByUser;
    return entity.createdByUser;
}
export function getLastChangedAuditInfo(entity) {
    if (!entity) return {
        changedDateTime : null,
        changedByUser   : null,
    }
    return {
        changedDateTime : entity.auditEntries?.length ? entity.auditEntries[0].auditDate : entity.changedDateTime,
        changedByUser   : entity.auditEntries?.length ? entity.auditEntries[0].user      : entity.changedByUser,
    }
}

export function getLastChange(ormModel, modelObject) {

    let lastChangeObject = getLastChangedAuditInfo(modelObject.ref);

    if (!nestedOrmEntities[ormModel] || !nestedOrmEntities[ormModel].length) {
        return lastChangeObject;
    }
    for (const nestedConfig of nestedOrmEntities[ormModel]) {
        if (!nestedConfig.useSubEntityProp) { // no prop for this, i.e. never managed separately, i.e. integrated part of the object.
            continue;
        }
        if (modelObject.ref[nestedConfig.useSubEntityProp] === true) { // Sub modelObject managed separately. Should not be included in this filter request
            continue;
        }
        if (!modelObject[nestedConfig.property]) {
            continue;
        }
        if (!auditOrmToPrototypeMap[nestedConfig.model]) {
            continue;
        }
        const nestedObjects = modelObject[nestedConfig.property].toModelArray();
        if (nestedObjects?.length) {
            const nestedLastChanged = getLastChange(nestedConfig.model, nestedObjects[0]);
            // If current lastChangeObject is empty, use the one from the child entity.
            if (!lastChangeObject.changedDateTime || moment(nestedLastChanged.changedDateTime).isAfter(moment(lastChangeObject.changedDateTime))) {
                lastChangeObject = nestedLastChanged;
            }
        }
    }
    return lastChangeObject;
}

export function getHistoricAuditConfig(data, commonHistoricAuditData) {
    const retObject = {}
    if (commonHistoricAuditData) {
        data.forEach(item => item.apiVersions.forEach(apiVersion => retObject[apiVersion] = {
            excludeFields    : [...commonHistoricAuditData?.excludeField[apiVersion]    , ...item.excludeFields],
            skipParentLevels : [...commonHistoricAuditData?.skipParentLevels[apiVersion], ...item.skipParentLevels]
        }))
    }
    else {
        data.forEach(item => item.apiVersions.forEach(apiVersion => retObject[apiVersion] = {
            excludeFields    : item.excludeFields,
            skipParentLevels : item.skipParentLevels,
        }))
    }
    return retObject;
}
