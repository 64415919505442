import React from "react";
import {Route} from "react-router-dom";
import OnboardingWizardPage from "features/onboardingWizard/OnboardingWizardPage";
import CashPool from "features/cashPool/CashPool";
import xpoolLogo from "resources/xpoolLogo.jpeg";
import ActiveClientAccountsSettings from "features/bankAccount/ActiveClientAccountsSettings";
import Calculator from "features/calculator/Calculator";
import ErpPoc from "features/erpPoc/ErpPoc";
import ClientSettingsPage from "features/client/ClientSettingsPage";
import ClientsStatusList from "features/client/ClientsStatusList";
import {xpoolModelToPrototypesMap} from "xpool-gui-common/appConfig/appConfig";
import {
    currencyDashboardReloadState,
    currencyDashboardResetState
} from "xpool-gui-common/features/currencyDashboard/currencyDashboardReducer";
import "xpool-gui-common/appConfig/css/xpoolCssVariables.css"
import CurrencyDashboardPage from "xpool-gui-common/features/currencyDashboard/CurrencyDashboardPage";

export function getAppSpecificLanguage () {
    return {
        ...require('appConfig/appSpecificLang.json'),

        ...require('xpool-gui-common/appConfig/xpoolSpecificLang.json'),
        ...require('xpool-gui-common/features/legalEntity/legalEntityLang.json'),
        ...require('xpool-gui-common/features/legalEntityUnit/legalEntityUnitLang.json'),
        ...require('xpool-gui-common/features/bankAccountBalance/accountBalanceLang.json'),
        ...require('xpool-gui-common/features/order/orderLang.json'),
        ...require('xpool-gui-common/appConfig/runConfiguration/runConfigurationLang.json'),
        ...require('xpool-gui-common/features/balanceMovement/balanceMovementLang.json'),
        ...require('xpool-gui-common/features/bankAccount/bankAccountLang.json'),
        ...require('xpool-gui-common/features/currencyDashboard/currencyDashboardLang.json'),
        ...require('xpool-gui-common/features/balanceApiSimulator/balanceApiSimulatorLang.json'),

        ...require('features/bankAccount/accountLang.json'),
        ...require('features/client/clientLang.json'),
        ...require('features/cashPool/cashPoolLang.json'),
        ...require('features/onboardingWizard/onboardingWizardLang'),
        ...require('appConfig/report/appReportLang.json'),
        ...require('features/welcomeScreen/welcomeScreenLang.json'),
        ...require('features/transaction/transactionLang.json'),
        ...require('appConfig/appUserSettings/appUserSettingsLang.json'),
        ...require('appConfig/audit/appSpecificAuditLang.json'),
    }
}
export const  getAppRouterPaths = () => [
    <Route path="/onboardingWizard"     key="onboardingWizardRouterPath"    component={OnboardingWizardPage}/>,
    <Route path="/cashPool"             key="cashPoolRouterPath"            component={CashPool}/>,
    <Route path="/accounts"             key="accountsRouterPath"            component={ActiveClientAccountsSettings}/>,
    <Route path="/calculator"           key="calculatorPath"                component={Calculator}/>,
    <Route path="/erpPoc"               key="erpPocPath"                    component={ErpPoc}/>,
    <Route path="/clientSettings"       key="clientSettings"                component={ClientSettingsPage}/>,
    <Route path="/clientsStatusList"    key="clientsStatusList"             component={ClientsStatusList}/>,
    <Route path="/currencyDashboard"    key="currencyDashboard"             component={CurrencyDashboardPage}/>
];

export function getAppLogo(){
    return xpoolLogo;
}
export function getAppName(){
    return "xpool";
}
export function editBaseUsed() {
    return true;
}

export const modelToPrototypesMap = {
    ...xpoolModelToPrototypesMap,
};

export function getAppSpecificUserPreferencesInitialState() {
    return {
        showInBalanceOrdersDays : (window.oxygen_env.SHOW_IN_BALANCE_ORDERS_DAYS === undefined) ? 1 : window.oxygen_env.SHOW_IN_BALANCE_ORDERS_DAYS,
    }
}
export const getAppSpecificUserStates = () => ({
    "currencyDashboardState" : {reloadFunction: currencyDashboardReloadState, resetFunction: currencyDashboardResetState},
});
