import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {formatDateFieldRelativeNow, isMomentObject} from "gui-common/xpGrid/xpGridFunctions";
import {createMomentObjectFromUtcTimestamp, formatDateField} from "gui-common/functions/functions";
import {selectActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


export function XpDateTime ({isoString, format}) {

    const currentLanguage = useSelector(selectActiveLanguage);

    const momentObject  = useMemo( () =>  isMomentObject(isoString) ? isoString : createMomentObjectFromUtcTimestamp(isoString), [isoString]);

    const displayString = useMemo(
        () =>  (format === 'relative') ? formatDateFieldRelativeNow(momentObject, currentLanguage) : formatDateField(momentObject, currentLanguage, format),
        [momentObject, currentLanguage, format]
    );

    return (
        <span>
            {displayString}
        </span>
    )
}



