import React, {useMemo, useRef} from "react";
import {getXpGridStateSelector} from "gui-common/xpGrid/xpGridSelectors";
import {useDispatch} from "react-redux";
import {getOrmModelLoadingNewOrUpdatedSelector} from "gui-common/orm/ormLoadingSelectors";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getFormLabelKey, setFormButtonKey} from "gui-common/xpForm/formComponentsFunctions";
import {userCanCreate} from "gui-common/functions/userRights";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {xpAdminFormLoadNew} from "gui-common/xpAdminForm/xpAdminFormThunks";
import {useXpFormState} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_EDIT, XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {getXpAdminFormModel} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import {entityIsActive, entityIsDisabled} from "gui-common/audit/auditFunctions";

export function getXpAdminFormListInstanceId(xpAdminFormInstanceId, ormModel) {
    return xpAdminFormInstanceId + '-' + ormModel + '-formList'
}

export default function XpAdminFormEntityList (props) {

    const childRef = useRef(); // Needed to get setNew function from XpAdminFormButtons component.
    const propsRef = useRef(props); // Needed to transport updated hook to callback scope.
    propsRef.current = props;

    const parentItemData = props.parentItemData;

    const listInstanceId = getXpAdminFormListInstanceId(props.xpAdminFormInstanceId, props.ormModel);

    const listState         = useSelectorInstance(getXpGridStateSelector    , {instanceId: listInstanceId});
    // const childFormData     = useSelectorInstance(getXpAdminFormDataSelector, {selectInstance: props.xpAdminFormInstanceId, selectModel: props.ormModel, selectId: listState ? listState.selectedId : undefined});

    const childFormStateSelectedEntity = useXpFormState(getXpAdminFormModel(props.xpAdminFormInstanceId, props.ormModel));
    const childFormStateNewEntity      = useXpFormState(getXpAdminFormModel(props.xpAdminFormInstanceId, props.ormModel, {id: listState?.selectedId}));
    const childFormUseState = childFormStateSelectedEntity ? childFormStateSelectedEntity.formUseState : childFormStateNewEntity ? childFormStateNewEntity.formUseState : undefined;

    const childModelLoading = useSelectorInstance(getOrmModelLoadingNewOrUpdatedSelector , {ormModel: props.ormModel});

    const dispatch     = useDispatch();

    const filterFunction = useMemo(
        () => {
            if (props.filterFunction) return props.filterFunction;
            if (!props.rowDataFilterFunction) return undefined;
            return (item) => {
                if (props.noParentData) return true; // All items to be included.
                if (!parentItemData) return false; // Parent not found or removed. No list items included.
                return props.rowDataFilterFunction(item, parentItemData);
            }
        },
        [props.filterFunction, props.rowDataFilterFunction, parentItemData],
    );

    const selectedListItemIdInChildList = useMemo(
        () => {
            if (!listState || !listState.selectedId) return undefined;

            // Used when no parent data is available, but the selected ID should be checked against a list. Used for active clients bankAccount list in xpool.
            if (props.includedListItems) return props.includedListItems.find(item => item.id === listState.selectedId) ? listState.selectedId : undefined;

            if (props.noParentData) return listState.selectedId; // Always show selected item if list is top level.

            if (!parentItemData || !parentItemData[props.arrayProperty]) return undefined; // Parent item is removed.
            if (parentItemData[props.arrayProperty].find(item => item.id === listState.selectedId)) return listState.selectedId;
            return undefined;
        },
        [listState, parentItemData, props.includedListItems],
    );

    const auditListData = useMemo(
        () => {
            if (!props.auditMode) return undefined;
            if (!parentItemData || !parentItemData[props.arrayProperty] || !parentItemData[props.arrayProperty].length) return [];
            return parentItemData[props.arrayProperty].filter(entityIsActive);
        },
        [props.auditMode, parentItemData]
    );

    function setNew(){
        if (childRef.current && childRef.current.setNew) childRef.current.setNew();
        else dispatch(xpAdminFormLoadNew(props));
    }
    function setEdit(){
        if (childRef.current && childRef.current.setEdit) childRef.current.setEdit();
    }
    function disableItem(){
        if (childRef.current && childRef.current.disableItem) childRef.current.disableItem();
    }

    const childFormInEditOrNew = (childFormUseState && ([XP_FORM_EDIT, XP_FORM_NEW].includes(childFormUseState))) || (childModelLoading === true);
    const thisFormInEditOrNew = (props.formUseState && ([XP_FORM_EDIT, XP_FORM_NEW].includes(props.formUseState)));
    const subItemsHeaderCss = parentItemData ? "entitySubItemsHeader" : "entitySubItemsHeader entitySubItemsHeaderNoBackground";
    const userCanAddEntity = (props.userCanAddEntity !== undefined) ? props.userCanAddEntity : parentItemData ? userCanCreate(props.ormModel, parentItemData, 'nestedRightRequiredForAdmin') : false;

    return (
        <div className={parentItemData ? 'entitySubItemsContainerWithTopBorder' : ""}>
            <div className={subItemsHeaderCss}>
                {(userCanAddEntity && !props.auditMode) &&
                <WaitingXpoolButton
                    xpTestId        = {"ormFormNewButton-" + props.ormModel}
                    labelKey        = {setFormButtonKey(props, '.new')}
                    disabled        = {thisFormInEditOrNew ||childFormInEditOrNew || (!props.noParentData && (!parentItemData || entityIsDisabled(parentItemData)))}
                    onClickCallback = {setNew}
                    floatRight
                />}
                {(!props.auditMode) && <p className="entitySubItemsHeaderText">
                    {
                    parentItemData ?
                        <span><XpTranslated trKey={getFormLabelKey(props, ".gridHeaderWithSuperEntity")}/>{parentItemData.name + ":"}</span>
                        :
                        <XpTranslated trKey={getFormLabelKey(props, ".gridHeader")}/>
                }</p>}
            </div>
            <div className="entitySubItemsList" xp-test-id={listInstanceId}>
                <props.listComponent
                    rowDataSelectorProps={{
                        filterFunction: filterFunction,
                        inputData     : auditListData, // Only defined when in audit mode
                    }}
                    instanceId={listInstanceId}
                    suppressRowClickSelection={childFormInEditOrNew}
                    fillAvailableSpace={false}
                    xpAdminFormSetEditCallback={setEdit}
                    xpAdminFormDisableCallback={disableItem}
                    auditMode               = {props.auditMode}
                />
            </div>
            {(selectedListItemIdInChildList || (childFormUseState === XP_FORM_NEW)) &&
            <XpAdminForm
                ref={childRef}
                xpAdminFormInstanceId   = {props.xpAdminFormInstanceId}
                xpAdminFormConfig       = {props.xpAdminFormConfig}
                ormModel                = {props.ormModel}
                auditMode               = {props.auditMode}
                rootAuditProps          = {props.rootAuditProps}
                parentItemData          = {props.parentItemData}
                suppressSubEntities     = {props.suppressSubEntities}
                suppressAccordion       = {props.suppressAccordion}
                entityId                = {selectedListItemIdInChildList}
                currentData             = {(props.auditMode && parentItemData && parentItemData[props.arrayProperty] && listState) ? parentItemData[props.arrayProperty].find(item => item.id === listState.selectedId) : undefined}
                beforeChangeData        = {(props.auditMode && parentItemData && parentItemData[props.arrayProperty] && listState) ? parentItemData[props.arrayProperty].find(item => item.id === listState.selectedId) : undefined}
                userCanEditEntity       = {props.userCanEditEntity}
                userCanDeleteEntity     = {props.userCanDeleteEntity}
            />}
        </div>
    );
}



