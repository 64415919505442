export const XP_TABS_SET_SELECTED = 'XP_TABS_SET_SELECTED';
export const XP_TABS_RELOAD_STATE = 'XP_TABS_RELOAD_STATE';
export const XP_TABS_RESET_STATE  = 'XP_TABS_RESET_STATE';

export function xpTabsSetSelected(instanceId, selectedIndex) {
    return {type: XP_TABS_SET_SELECTED, payload: {instanceId: instanceId, selectedIndex: selectedIndex}}
}
export function xpTabsReloadState(newState) {
    return {type: XP_TABS_RELOAD_STATE, payload: newState}
}
export function xpTabsResetState() {
    return {type: XP_TABS_RESET_STATE}
}

export function xpTabsReducer(state = {}, action)
{
    switch (action.type) {
        case XP_TABS_SET_SELECTED:
            let newState = {...state};
            newState[action.payload.instanceId] = action.payload.selectedIndex;
            return newState;
        case XP_TABS_RELOAD_STATE:
            return action.payload;
        case XP_TABS_RESET_STATE:
            return {};
        default:
            return state;

    }
}
