import {
    createErpIntegrationBody,
    createRunConfigurationBody, erpIntegrationTransformer,
    transformRunConfiguration
} from "gui-common/api/commonTransformers";
import {orderTransformer} from "xpool-gui-common/features/order/orderFunctions";
import {
    getNestedIdProperty,
    setNestedIdProperty,
    setParentId,
    setParentIdPropertyInCreateBody
} from "gui-common/functions/functions";
import {ormItemArrayTransformer} from "gui-common/orm/ormApi";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {addLedgerAccountProps} from "gui-common/ledger/ledgerFunctions";
import {balanceMovementTransformer, createBalanceMovementBody} from "xpool-gui-common/features/balanceMovement/balanceMovementFunctions";

export const xpoolTransformerMap = {
    Client                  : clientResponseTransformer,
    CashPool                : cashPoolResponseTransformer,
    LegalEntity             : legalEntityResponseTransformer,
    LegalEntityUnit         : legalEntityUnitResponseTransformer,
    BankAccount             : accountResponseTransformer,
    BankAccountRelation     : bankAccountRelationTransformer,
    BankAccountAggregates   : bankAccountAggregatesTransformer,
    BalanceMovement         : balanceMovementTransformer,
    BankAccountInstitution  : bankAccountInstitutionResponseTransformer,
    Order                   : orderTransformer,
};
export const xpoolCreateBodyMap = {
    Client                  : createClientBody,
    CashPool                : createCashPoolBody,
    LegalEntity             : createLegalEntityBody,
    LegalEntityUnit         : createLegalEntityUnitBody,
    BankAccount             : createAccountBody,
    BankAccountRelation     : createBankAccountRelationBody,
    BalanceMovement         : createBalanceMovementBody,
    BankAccountInstitution  : undefined,
    Order                   : undefined,
};

function bankAccountInstitutionResponseTransformer(inst) {
    // setItemDateTimes(inst);
    if (inst.name        === null) inst.name = "";
    if (inst.description === null) inst.description = "";
    if (inst.country     === null) inst.country = "";
    return inst;
}

function clientResponseTransformer(client, params) {


    if (!client.id) client.name = 0;
    if (!client.name) client.name = "";
    if (!client.description) client.description = "";
    if (!client.shortName) client.shortName = "";
    if (!client.accountManager) client.accountManager = "";
    client.operatingUnitId = getNestedIdProperty(client, 'operatingUnit');
    if (!client.orgNumber) client.orgNumber = "";
    if (!client.clientRefId) client.clientRefId = "";

    if (!client.timeZone) client.timeZone = "";
    client.nettingRule = (client.nettingRule === "BankAccount");

    if (client.useLegalEntities === undefined) client.useLegalEntities = true;
    if (client.useLegalEntities === null) client.useLegalEntities = true;

    if (client.legalEntities) {
        client.legalEntities = ormItemArrayTransformer('LegalEntity', client.legalEntities, {
            ...params,
            parentId: client.id
        });
    }
    if (client.cashPools) {
        client.cashPools = ormItemArrayTransformer('CashPool', client.cashPools, {...params, parentId: client.id});
    }
    client.runConfiguration = transformRunConfiguration(client.runConfiguration, params);

/*
    client.orders = [
        {
            id :client.id +1,
            currencyPair: {
                id: 76232754938448,
                baseCurrency: {
                    id: 4543826,
                    currency: "EUR",
                    enabled: true,
                    sortOrder: 5
                },
                quoteCurrency: {
                    id: 4670032,
                    currency: "GBP",
                    enabled: true,
                    sortOrder: 5
                },
                key: "EUR/GBP",
                enabled: true
            },
            orderType: "TODAY",
            nominalAmount: 1000,
            side: "BUY",
            barId: 657,
            clientId: client.id,
            createdDateTime: "2021-03-20T12:42:34.968Z",
            modifiedDateTime: "2021-03-20T12:42:34.968Z",
            reference: {
                referenceRate: 0.12346,
                counterpartyAmount: 666
            },
            execution: {
                executionRate: 0.23445,
                executionRateFarLeg: 0.7658,
                counterpartyAmount: 777,
                externalReference: "Fishes are good",
                state: "SUGGESTED",
                reason: "",
                settlementDate: "2021-10-10",
                executionDateTime: "2021-03-20T12:42:34.968Z",
            }
        },
    ]
*/
    if (client.orders) {
        client.orders = ormItemArrayTransformer('Order', client.orders, {...params, parentId: client.id});
    }

    /*
        client.runStates = [runStateTransformer({
            id        : 123554 + client.id,
            runPeriod : {
                id   : 234,
                businessObjectPrototype  : 'Client',
                businessObjectId         : client.id,
            },
            workflow: {
                workflowPrototype: 'ClientWorkflow',
                workflowState    : 'ACTIVE'
            },
            runState        : 'WAIT_FOR_RETRY',
            runStateParams     : {
                nextRunTime : "2021-03-20T12:42:34.968Z",
                reason : 'BALANCE'
            },
            changedDateTime : "2021-03-20T12:42:34.968Z",
        })]
    */

    return client;
}

function createClientBody(client, decDenLangState, method) {

    let legalEntities = undefined;
    if (client.legalEntities) {
        legalEntities = client.legalEntities.map(item => createLegalEntityBody(item, decDenLangState, method));
    }
    let cashPools = undefined;
    if (client.cashPools) {
        cashPools = client.cashPools.map(item => createCashPoolBody(item, decDenLangState, method));
    }
    return {
        id: client.id,
        name: (client.name !== "") ? client.name : null,
        description: (client.description !== "") ? client.description : null,
        // shortName:           (client.shortName      !== "") ? client.shortName      : null,
        accountManager: (client.accountManager !== "") ? client.accountManager : null,
        operatingUnit: setNestedIdProperty(client, 'operatingUnitId'),
        orgNumber: (client.orgNumber !== "") ? client.orgNumber : null,
        clientRefId: (client.clientRefId !== "") ? client.clientRefId : null,
        nettingRule: client.nettingRule ? "BANK_ACCOUNT" : "NONE",

        timeZone: client.timeZone,
        useLegalEntities: client.useLegalEntities,
        legalEntities: legalEntities,

        cashPools: cashPools,
        runConfiguration: createRunConfigurationBody(client.runConfiguration, {
            decDenLangState: decDenLangState,
            method: method
        }),
    }
}

function cashPoolResponseTransformer(cashPool, params) {
    setParentId(cashPool, 'clientId', params ? params.parentId : null);
    return cashPool;
}

function createCashPoolBody(cashPool, decDenLangState, method) {
    return {
        id: cashPool.id,
        name: cashPool.name,
        description: cashPool.description,
        includedLegalEntities: null,
    }
}

function legalEntityResponseTransformer(legalEntity, params) {
    // setItemDateTimes(legalEntity);
    setParentId(legalEntity, 'clientId', params ? params.parentId : null);
    // if (legalEntity.legalEntityType === null)   legalEntity.legalEntityType = "Company";
    if (legalEntity.name === null) legalEntity.name = "";
    if (legalEntity.description === null) legalEntity.description = "";
    if (legalEntity.useLegalEntityUnits === null) legalEntity.useLegalEntityUnits = true;
    if (legalEntity.useLegalEntityUnits === undefined) legalEntity.useLegalEntityUnits = true;
    legalEntity.legalPersonType = (legalEntity.legalPersonType) ? legalEntity.legalPersonType : "";
    legalEntity.hasLEI = true;
    if (legalEntity.lei === null) {
        legalEntity.lei = "";
        legalEntity.hasLEI = false;
    }
    // Skipped parent child feature for LE in oxyGen v2.
    // if (legalEntity.parentEntityId === 0) legalEntity.parentEntityId = null;

    legalEntity.countryId = getNestedIdProperty(legalEntity, 'country');

    if (legalEntity.legalEntityUnits) {
        legalEntity.legalEntityUnits = ormItemArrayTransformer('LegalEntityUnit', legalEntity.legalEntityUnits, {
            ...params,
            parentId: legalEntity.id
        });
    }
    legalEntity.runConfiguration = transformRunConfiguration(legalEntity.runConfiguration, params);

    if (legalEntity.erpIntegration) {
        legalEntity.erpIntegrationId = legalEntity.erpIntegration.id;
        legalEntity.erpIntegration = erpIntegrationTransformer(legalEntity.erpIntegration);
    }

    return legalEntity;
}

function createLegalEntityBody(legalEntity, decDenLangState, method) {
    let legalEntityUnits = undefined;
    if (legalEntity.legalEntityUnits) {
        legalEntityUnits = legalEntity.legalEntityUnits.map(item => createLegalEntityUnitBody(item, decDenLangState, method));
    }
    let erpIntegration = undefined;
    if (legalEntity.erpIntegration) {
        erpIntegration = createErpIntegrationBody(legalEntity.erpIntegration);
    }
    return {
        ...setParentIdPropertyInCreateBody(legalEntity, 'clientId', method),
        id: legalEntity.id,
        clientId: legalEntity.clientId,
        name: legalEntity.name,
        description: legalEntity.description,
        country: setNestedIdProperty(legalEntity, 'countryId'),
        lei: legalEntity.hasLEI ? legalEntity.lei : null,
        legalPersonType: legalEntity.legalPersonType ? legalEntity.legalPersonType : null,
        // parentEntityId:      legalEntity.parentEntityId ? Number(legalEntity.parentEntityId) : null, // Skipped parent child feature for LE in oxyGen v2.
        useLegalEntityUnits: legalEntity.useLegalEntityUnits,
        legalEntityUnits: legalEntityUnits,
        runConfiguration: createRunConfigurationBody(legalEntity.runConfiguration, {
            decDenLangState: decDenLangState,
            method: method
        }),
        erpIntegration: erpIntegration,
    }
}

function legalEntityUnitResponseTransformer(legalEntityUnit, params) {
    // setItemDateTimes(legalEntityUnit);
    setParentId(legalEntityUnit, 'legalEntityId', params ? params.parentId : null);
    if (legalEntityUnit.name === null) legalEntityUnit.name = "";
    if (legalEntityUnit.description === null) legalEntityUnit.description = "";
    if (!legalEntityUnit.fxEnabled) legalEntityUnit.fxEnabled = false;
    if (legalEntityUnit.fxShortName === null) legalEntityUnit.fxShortName = "";

    legalEntityUnit.fxProviderId = getNestedIdProperty(legalEntityUnit, 'fxProvider');
//    if (!legalEntityUnit.fxProviderId)              legalEntityUnit.fxProviderId = null;

    if (legalEntityUnit.bankAccounts) {
        legalEntityUnit.bankAccounts = ormItemArrayTransformer('BankAccount', legalEntityUnit.bankAccounts, {
            ...params,
            parentId: legalEntityUnit.id
        });
    }
    legalEntityUnit.runConfiguration = transformRunConfiguration(legalEntityUnit.runConfiguration, params);

    return legalEntityUnit;
}

function createLegalEntityUnitBody(legalEntityUnit, decDenLangState, method) {
    let bankAccounts = undefined;
    if (legalEntityUnit.bankAccounts && (method === 'POST')) {
        bankAccounts = legalEntityUnit.bankAccounts.map(item => createAccountBody(item, decDenLangState, method, false));
    }
    return {
        ...setParentIdPropertyInCreateBody(legalEntityUnit, 'legalEntityId', method),
        id: legalEntityUnit.id,
        legalEntityId: legalEntityUnit.legalEntityId,
        name: legalEntityUnit.name,
        description: legalEntityUnit.description,
        fxEnabled: legalEntityUnit.fxEnabled,
        fxShortName: legalEntityUnit.fxEnabled ? legalEntityUnit.fxShortName : null,
        fxProvider: legalEntityUnit.fxEnabled ? setNestedIdProperty(legalEntityUnit, 'fxProviderId') : null,
//        fxProviderId:   (legalEntityUnit.fxEnabled && legalEntityUnit.fxProviderId)? Number(legalEntityUnit.fxProviderId) : null,
        bankAccounts: bankAccounts,
        runConfiguration: createRunConfigurationBody(legalEntityUnit.runConfiguration, {
            decDenLangState: decDenLangState,
            method: method
        }),
    }
}

function accountResponseTransformer(account, params) {
    setParentId(account, 'legalEntityUnitId', params ? params.parentId : null);

    if (account.accountNumber === null) account.accountNumber = "";
    if (account.clearingNumber === null) account.clearingNumber = "";
    if (account.name === null) account.name = "";
    if (account.description === null) account.description = "";
    if (account.bic === null) account.bic = "";
    if (account.iban === null) account.iban = "";
    if (!account.parentAccountId) account.parentAccountId = "";

    if (account.creditType === null) account.creditType = "NO_CREDIT";
    if (account.availableCredit === null) account.availableCredit = 0;

    if (account.accountType     === null) account.accountType     = "PHYSICAL";
    if (account.integrationType === null) account.integrationType = "NATIVE";

    account.institutionId = getNestedIdProperty(account, 'bankAccountInstitution');
    account.currencyId = getNestedIdProperty(account, 'currency');

    if (account.bankAccountRelations) {
        account.bankAccountRelations = ormItemArrayTransformer('BankAccountRelation', account.bankAccountRelations, {
            ...params,
            parentId: account.id,
            parentCurrency: account.currency,
        });
    }

    if (account.erpTransactions) {
        account.balanceMovements = ormItemArrayTransformer('BalanceMovement', account.erpTransactions, {
            ...params,
            parentId: account.id,
            parentCurrency: account.currency,
        });
    }

    if (account.aggregatedBalances) {
        account.aggregatedBalances = bankAccountAggregatesTransformer(account.aggregatedBalances, params);
    }

    account.erpTransactions = undefined; // We do not want to keep the erpTransactions array in memory after renaming to balanceMovements.

    account.runConfiguration = transformRunConfiguration(account.runConfiguration, params);

    addLedgerAccountProps(account, 'ledgerBalance'     , {...params, parentId: account.id});
    addLedgerAccountProps(account, 'pendingBalance'    , {...params, parentId: account.id});
    addLedgerAccountProps(account, 'suggestedBalance'  , {...params, parentId: account.id});
    addLedgerAccountProps(account, 'postPoolingBalance', {...params, parentId: account.id});

    return account;
}

function bankAccountAggregatesTransformer(aggregates, params) {
    return {
        id: aggregates.bankAccountId,
        ...aggregates,
    };
}


// Function implementing simple parent relation in a BAR property.
// wizardCase is when the client and bankAccount structure is created in one transaction. Parent accounts are defined including child accounts in the childAccounts property.
// In the wizard case there will be no selected client cash pools on the bankAccount, and no parentAccountId.
function setBankAccountRelations(account, isChildAccount) {
    let returnObject = {
        name: "Simple parent relation",
        description: "",
    }
    if (!isChildAccount) {
        if (!account.parentAccountId || !account.clientCashPools || !account.clientCashPools.length) return [];
        if (account.bankAccountRelations && account.bankAccountRelations.length && (account.bankAccountRelations[0].counterpartyAccountId === Number(account.parentAccountId))) return [account.bankAccountRelations[0]];
        returnObject.cashPoolId = account.clientCashPools[0].id;
        returnObject.counterpartyAccountId = Number(account.parentAccountId);
    }
    return [returnObject]
}

function createAccountBody(account, decDenLangState, method, state, isChildAccount) {

    const bankAccountRelations = setBankAccountRelations(account, isChildAccount).map(item => createBankAccountRelationBody(item, decDenLangState, method));

    if (account.runConfiguration && account.runConfiguration.sweepAndTopUp && account.runConfiguration.sweepAndTopUp[0]) {
        if (!account.runConfiguration.sweepAndTopUp[0].currencyId) account.runConfiguration.sweepAndTopUp[0].currencyId = account.currencyId;
    }

    return {
        ...setParentIdPropertyInCreateBody(account, 'legalEntityUnitId', method),
        id: account.id,
        legalEntityUnitId: account.legalEntityUnitId ? Number(account.legalEntityUnitId) : null,
        accountType: account.accountType,
        integrationType: account.integrationType,
        bankAccountInstitution: setNestedIdProperty(account, 'institutionId'),
        accountNumber: account.accountNumber,
        clearingNumber: account.clearingNumber,
        currency: setNestedIdProperty(account, 'currencyId'),
        name: account.name,
        description: account.description,
        bic: account.bic ? account.bic : null,
        iban: account.iban ? account.iban : null,
        parentAccountId: (!isChildAccount && account.parentAccountId) ? Number(account.parentAccountId) : null, // We should not set parentAccountId if in a child structure, the parent will be given by the structure.

        creditType: (!account.parentAccountId && account.creditType) ? account.creditType : "NO_CREDIT",
        availableCredit: stringNumberToFloat(account.availableCredit, decDenLangState),

        childAccounts: (account.hasOwnProperty('bankAccounts') && (method === 'POST')) ? account.bankAccounts.map(item => createAccountBody(item, decDenLangState, method, state, true)) : undefined,
        bankAccountRelations: bankAccountRelations,
        runConfiguration: createRunConfigurationBody(account.runConfiguration, {decDenLangState: decDenLangState, method: method})
    }
}

function bankAccountRelationTransformer(bar, params) {
    // setParentIdIfDefined(bar, 'accountId'      , params ? params.parentId       : null);
    // setParentIdIfDefined(bar, 'accountCurrency', params ? params.parentCurrency : null);
    bar.runConfiguration = transformRunConfiguration(bar.runConfiguration, params);
    bar.executionConfiguration = transformRunConfiguration(bar.executionConfiguration, params);

    return bar;
}


function createBankAccountRelationBody(bar, decDenLangState, method) {
    return {
        id: bar.id,
        name: bar.name,
        description: bar.description,
        cashPoolId: bar.cashPoolId,
        counterpartyAccountId: bar.counterpartyAccountId,
        instructingAccountId: bar.instructingAccountId,
        instructingAccountCurrency: bar.instructingAccountCurrency,
        runConfiguration: createRunConfigurationBody(bar.runConfiguration, {
            decDenLangState: decDenLangState,
            method: method
        }),
    }
}
