import React, {Component} from "react";
import {connect} from "react-redux";
import AbsolutePosition from "gui-common/contextMenu/AbsolutePosition";
import {hideContextMenu} from "gui-common/contextMenu/contextMenuReducer";

const actions = {hideContextMenu};

export class ContextMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offSetX: 0,
            offSetY: 0,
        };
    }

    componentDidMount() {
        let menuDiv = document.getElementById("contextMenuDiv");
        if ((this.refs["contextMenuDiv"] !== undefined) && (this.refs["contextMenuDiv"] !== null) && menuDiv) {

            let containerDiv = this.refs["contextMenuDiv"].getBoundingClientRect();
            let newOffSetX = 0;
            let newOffSetY = 0;
            if (this.props.location.x + menuDiv.clientWidth > containerDiv.width) {
                newOffSetX = 0 - menuDiv.clientWidth - 4;
            }
            if ((this.props.location.y + menuDiv.clientHeight > containerDiv.y) && (this.props.location.y > menuDiv.clientHeight + 55 )) {
                newOffSetY = 0 - menuDiv.clientHeight - 4;
            }
            if (newOffSetX || newOffSetY) {
                this.setState({
                    offSetX: newOffSetX,
                    offSetY: newOffSetY,
                });
            }
        }
        // console.log("Adding event listeners");
        document.addEventListener('click', this.handleClickOutside, true);
        document.addEventListener('contextmenu', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        // console.log("Removing event listeners");
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('contextmenu', this.handleClickOutside, true);
    }

    componentDidUpdate(){

    }

    handleClickOutside = (e) => {
        // Handling of 'false' right click event in Firefox
        if ((e.type === 'click') && (e.button === 2) && (e.which === 3)) return;
        if (!this.node || !this.node.contains(e.target) ) {
            this.props.hideContextMenu();
            // console.log("hide context menu");
        }
    };

    render() {
        const {location} = this.props;

        return (
            <div ref="contextMenuDiv">
                <AbsolutePosition
                    left={location.x + 2 + this.state.offSetX}
                    top={location.y + this.state.offSetY}
                    className="contextMenu"
                    nodeRef={node => this.node = node}
                >
                    {this.props.children}
                </AbsolutePosition>
            </div>
        )
    }
}

export default connect(null, actions)(ContextMenu);
