import ClientBaseStatic from "features/client/formComponents/ClientBaseStatic";
import LegalEntityBaseStatic from "xpool-gui-common/features/legalEntity/formComponents/LegalEntityBaseStatic";
import LegalEntityUnitBaseStatic from "xpool-gui-common/features/legalEntityUnit/formComponents/LegalEntityUnitBaseStatic";
import LegalEntitiesList from "xpool-gui-common/features/legalEntity/LegalEntitiesList";
import LegalEntityUnitsList from "xpool-gui-common/features/legalEntityUnit/LegalEntityUnitsList";
import AccountBaseStatic from "features/bankAccount/formComponents/AccountBaseStatic";
import AccountsList from "features/bankAccount/AccountsList";
import EntityBaseStaticFormComponent from "gui-common/xpAdminForm/EntityBaseStaticFormComponent";
import AccountCurrentBalance from "features/bankAccount/formComponents/AccountCurrentBalance";
import AccountStaticPhysical from "features/bankAccount/formComponents/AccountStaticPhysical";
import AccountParentAccountSelector from "features/bankAccount/formComponents/AccountParentAccountSelector";
import {
    getSpecificClientSelector,
    getSpecificShallowClientSelector,
    newClientFormTemplateSelector
} from "xpool-gui-common/features/client/clientSelectors";
import {
    getSpecificLegalEntityUnitSelector,
    newLegalEntityUnitFormTemplateSelector
} from "xpool-gui-common/features/legalEntityUnit/legalEntityUnitSelectors";
import {
    getSpecificLegalEntitySelector,
    newLegalEntityFormTemplateSelector
} from "xpool-gui-common/features/legalEntity/legalEntitySelectors";
import XpAdminFormUseChildEntitySelectors from "gui-common/xpAdminForm/XpAdminFormUseChildEntitySelectors";
import {canClientBeDisabled} from "xpool-gui-common/features/client/clientFunctions";
import {canLegalEntityBeDisabled} from "xpool-gui-common/features/legalEntity/legalEntityFunctions";
import {canLegalEntityUnitBeDisabled} from "xpool-gui-common/features/legalEntityUnit/legalEntityUnitFunctions";
import RunConfigurationsList from "gui-common/runConfiguration/RunConfigurationsList";
import React from "react";
import ErpIntegrationForm from "gui-common/erpIntegration/ErpIntegrationForm";
import {canBankAccountBeDisabled} from "xpool-gui-common/features/bankAccount/bankAccountFunctions";
import {
    getSpecificBankAccountSelector,
    newBankAccountFormTemplateSelector
} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {requestErpReceivablesFromApi} from "gui-common/erpIntegration/erpIntegrationApi";
import {XP_FORM_EDIT, XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {postOrmItemToApi, putOrmItemToApi} from "gui-common/orm/ormApi";
import {xpFormApiClear, xpFormApiSubmit} from "gui-common/xpForm/core/xpFormReducer";

const MonthlyRunConfigurations    = (props) => <RunConfigurationsList type={'monthlyRun'   } {...props}/>
const DailyRunConfigurations      = (props) => <RunConfigurationsList type={'dailyRun'     } {...props}/>
const SweepAndTopUpConfigurations = (props) => <RunConfigurationsList type={'sweepAndTopUp'} {...props}/>
const MaxMinSplitConfigurations   = (props) => <RunConfigurationsList type={'maxMinSplit'  } {...props}/>
const HoldOnRateConfigurations    = (props) => <RunConfigurationsList type={'holdOnRate'   } {...props}/>
const SchedulingConfigurations    = (props) => <RunConfigurationsList type={'scheduling'   } {...props}/>
const HedgeExposureConfigurations = (props) => <RunConfigurationsList type={'hedgeExposure'} {...props}/>

export const getClientAdminFormConfig = () => ({
    Client: {
        formTemplate: 'clientForm',
        getCurrentDataSelector: getSpecificClientSelector,
        newFormTemplateSelector: newClientFormTemplateSelector,
        checkBeforeDisableFunction: canClientBeDisabled,
        subEntitySuffix: ' (CL)',
        componentMatrix: [
            [EntityBaseStaticFormComponent, XpAdminFormUseChildEntitySelectors],
            [ClientBaseStatic],
            [SchedulingConfigurations, DailyRunConfigurations, MonthlyRunConfigurations, HedgeExposureConfigurations]
        ],
        childEntities: [
            {
                ormModel        : 'LegalEntity',
                inParentFormProperty  : 'useLegalEntities',
                arrayProperty   : 'legalEntities',
                listComponent   : LegalEntitiesList,
                rowDataFilterFunction  : (item, parentItemData) => {
                    if (!item.clientId) return false;
                    return (item.clientId === parentItemData.id);
                },
            },
        ],
    },
    LegalEntity: {
        formTemplate: 'legalEntityForm',
        getCurrentDataSelector: getSpecificLegalEntitySelector,
        newFormTemplateSelector: newLegalEntityFormTemplateSelector,
        checkBeforeDisableFunction: canLegalEntityBeDisabled,
        subEntitySuffix: ' (LE)',
        componentMatrix: [
            [EntityBaseStaticFormComponent, XpAdminFormUseChildEntitySelectors],
            [LegalEntityBaseStatic, ErpIntegrationForm],
        ],
        childEntities: [
            {
                ormModel        : 'LegalEntityUnit',
                inParentFormProperty  : 'useLegalEntityUnits',
                arrayProperty   : 'legalEntityUnits',
                listComponent   : LegalEntityUnitsList,
                rowDataFilterFunction  : (item, parentItemData) => {
                    if (!item.legalEntityId) return false;
                    return (item.legalEntityId === parentItemData.id);
                },
            },
        ],
    },
    LegalEntityUnit: {
        formTemplate: 'legalEntityUnitForm',
        getCurrentDataSelector: getSpecificLegalEntityUnitSelector,
        newFormTemplateSelector: newLegalEntityUnitFormTemplateSelector,
        checkBeforeDisableFunction: canLegalEntityUnitBeDisabled,
        subEntitySuffix: ' (BU)',
        componentMatrix: [
            [EntityBaseStaticFormComponent],
            [LegalEntityUnitBaseStatic],
        ],
        childEntities: [
            {
                ormModel        : 'BankAccount',
                inParentFormProperty  : undefined,
                arrayProperty   : 'bankAccounts',
                listComponent   : AccountsList,
                rowDataFilterFunction  : (item, parentItemData) => {
                    if (!item.legalEntityUnitId) return false;
                    return (item.legalEntityUnitId === parentItemData.id);
                },
            },
        ],
    },
    BankAccount: {
        formTemplate: 'accountForm',
        getCurrentDataSelector: getSpecificBankAccountSelector,
        newFormTemplateSelector: newBankAccountFormTemplateSelector,
        checkBeforeDisableFunction: canBankAccountBeDisabled,
        componentMatrix: [
            [EntityBaseStaticFormComponent, AccountBaseStatic, AccountStaticPhysical, AccountCurrentBalance],
            [AccountParentAccountSelector, SweepAndTopUpConfigurations, HedgeExposureConfigurations, MaxMinSplitConfigurations],
            [DailyRunConfigurations, MonthlyRunConfigurations, HoldOnRateConfigurations]
        ],
        childEntities: undefined,
        onApiCallFailedThunk: (bankAccount, apiResponse, formUseState, formModel) => (dispatch, getState) => {
            if (apiResponse.apiResponse && apiResponse.apiResponse[1] && apiResponse.apiResponse[1].response?.code === "DuplicateBankAccountWithinClientException") {
                let functionToDispatch = null;
                if (formUseState === XP_FORM_NEW ) functionToDispatch = postOrmItemToApi;
                if (formUseState === XP_FORM_EDIT) functionToDispatch = putOrmItemToApi;
                dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'accountForm.modalConfirmDuplicateAccount', modalParams: {accountNumber: bankAccount.accountNumber, clearingNumber: bankAccount.clearingNumber}}))
                    .then((result) => {
                        if (result.status === MODAL_TYPE_CONFIRM) {
                            dispatch(xpFormApiSubmit(formModel));
                            dispatch(functionToDispatch('BankAccount', bankAccount, url => url.substring(0, url.length - 1) + "/allow-duplicate?"))
                                .finally(res => {
                                    dispatch(xpFormApiClear(formModel));
                                })
                        }
                    })
            }
        }
    }
})


export const getCashPoolClientParametersFormConfig = () => ({
    Client: {
        formTemplate: 'clientForm',
        getCurrentDataSelector: getSpecificShallowClientSelector,
        newFormTemplateSelector: newClientFormTemplateSelector,
        checkBeforeDisableFunction: canClientBeDisabled,
        subEntitySuffix: ' (CL)',
        componentMatrix: [
            [SchedulingConfigurations, HedgeExposureConfigurations],
            [DailyRunConfigurations, MonthlyRunConfigurations]
        ],
        childEntities: undefined,
    },
})

export const getCashPoolAccountParametersConfig = () => ({
    BankAccount: {
        formTemplate: 'cashPoolAccountForm',
        getCurrentDataSelector: getSpecificBankAccountSelector,
        newFormTemplateSelector: newBankAccountFormTemplateSelector,
        checkBeforeDisableFunction: canBankAccountBeDisabled,
        componentMatrix: [
            [SweepAndTopUpConfigurations, HedgeExposureConfigurations, MaxMinSplitConfigurations],
            [DailyRunConfigurations, MonthlyRunConfigurations, HoldOnRateConfigurations]
        ],
        childEntities: undefined,
    }
})
