import React from "react";
import {withRouter} from "react-router";
import {useDispatch} from "react-redux";
import {xpFormLoadForm, xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import XpFormCheckboxInput from "gui-common/xpForm/core/XpFormCheckboxInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpFormRadioInput from "gui-common/xpForm/core/XpFormRadioInput";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormTimeInput from "gui-common/xpForm/core/XpFormTimeInput";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import XpFormDateInput from "gui-common/xpForm/core/XpFormDateInput";
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import XpFormToggleInput from "gui-common/xpForm/core/XpFormToggleInput";

function TestPage (props) {
    const dispatch = useDispatch();

    return (
        <div>
            <span>Test page</span>

            <XpForm
                formModel='testForm'
                currentData={{name: 'Fish client', countryId: 2, isActive: true, isSelected: false, fishId:2}}
                initialUseState={XP_FORM_EDIT}
                onSubmit={data => {
                    console.log("Submit success!", data);
                }}
            >
                <div style={{background: 'ghostwhite', width: '300px'}}>
                    <XpFormTextInput
                        field='name'
                        label='Name'
                        // maxLength={5}
                        noTemplate
                        dependentFields={{
                            nameMaxLength: 'nameMaxLength',
                        }}
                        errors={{
                            longerThanNum:     (val, fields) =>
                                (val?.length && fields?.nameMaxLength?.length && (val.length > Number(fields.nameMaxLength))) ? {length: val.length, nameMaxLength: Number(fields.nameMaxLength)} : false,
                        }}
                    />
                    <XpFormToggleInput
                        inLineLayout placeBeforeTextUnderLabel
                        field    = 'toggleTest'
                        label    = 'TOGGLE'
                    />

                    <XpFormRadioInput
                        field='countryId'
                        // label='Country'
                        itemTextField='name'
                        radioItems={[{id:1, name:"Sweden"},{id:2, name:"Finland"},{id:3, name:"Russia"}]}
                        dependentPropsFn={fields => ({label: fields.textAreaText ? fields.textAreaText : "Country"})}
                        dependentFields={{
                            textAreaText: 'textAreaTest',
                            appId: 'clientRefId',
                        }}
                    />
                    <XpFormSelectorInput
                        field='fishId'
                        label='fishId'
                        itemTextField='name'
                        selectList={[{id:1, name: 'Fish1'},{id:2, name: 'Fish2'},{id:3, name:'Fish3'}]}
                        noTemplate
                    />

                    <XpFormTimeInput
                        field='clientTime'
                        label='Time input'
                        noTemplate
                    />

                    <XpFormAmountInput
                        field='nameMaxLength'
                        label='Name max length'
                    />
                    <XpFormSwitchInput
                        field='isActive'
                        label='Test switch'
                    />
                    <XpFormCheckboxInput
                        field='isSelected'
                        label='Test checkbox'
                    />
                    <XpFormTextAreaInput
                        field='textAreaTest'
                        label='Text area'
                        maxLength={50}
                    />
                    <XpFormDateInput
                        field='dateTest'
                        label='Date input'
                    />

                    <XpFormFieldset
                        // formModel='testForm.runTimes'
                        field='runTimes.0'
                    >
                        <XpFormTimeInput
                            noLabel inTable
                            field="getBalancesTime"
                            // dependentFields={{ executeTime: 'executeTime'}}
                            // errors         ={{ timeLater  : (val, fields) => timeLaterThanParameter(val, fields.executeTime)}}
                            // errorMessages  ={{ timeLater  : setTranslatedError('runConfiguration.runTimesTable.getBalancesTime.errors.laterThanExecute')}}
                        />
{/*                        <XpFormTimeInput
                            noLabel
                            field="executeTime"
                            dependentFields={{
                                getBalancesTime: 'getBalancesTime',
                                stopTryingTime : 'stopTryingTime',
                            }}
                            errors={{
                                timeEarlier : (val, fields) => timeEarlierThanParameter(val, fields.getBalancesTime),
                                timeLater   : (val, fields) => timeLaterThanParameter  (val, fields.stopTryingTime),
                            }}
                            errorMessages={{
                                timeEarlier : setTranslatedError('runConfiguration.runTimesTable.executeTime.errors.earlierThanGetBalance'),
                                timeLater   : setTranslatedError('runConfiguration.runTimesTable.executeTime.errors.laterThanStopTrying'),
                            }}
                        />
                        <XpFormTimeInput
                            noLabel
                            field="stopTryingTime"
                            dependentFields={{
                                executeTime: 'executeTime',
                            }}
                            errors={{
                                timeEarlier              : (val, fields) => timeEarlierOrSameThanParameter(val, fields.executeTime),
                            }}
                            errorMessages={{
                                timeEarlier              : setTranslatedError('runConfiguration.runTimesTable.stopTryingTime.errors.earlierThanExecution'),
                            }}
                        />*/}
                    </XpFormFieldset>
                    <XpFormFieldset
                        // formModel='testForm.runTimes'
                        field='runTimes.1'
                    >
                        <XpFormTimeInput
                            noLabel inTable
                            field="getBalancesTime"
                            // errors         ={{ timeLater  : (val, fields) => timeLaterThanParameter(val, fields.executeTime)}}
                            // errorMessages  ={{ timeLater  : setTranslatedError('runConfiguration.runTimesTable.getBalancesTime.errors.laterThanExecute')}}
                        />
                    </XpFormFieldset>
                </div>
            </XpForm>

            <button onClick={()=>dispatch(xpFormLoadForm(  "testForm", {name: 'Fish client', runTimes: [{getBalancesTime: '12:34'},{getBalancesTime: '13:34'}]}, XP_FORM_EDIT))}>Load active client</button>
            <button onClick={()=>dispatch(xpFormRemoveForm("testForm"))}                            >Cancel</button>
            <button onClick={()=>dispatch(xpFormSubmitForm("testForm"))}                            >Submit</button>

{/*
            <XpForm
                formModel='brax.form'
            >
                <div>
                    <XpFormFieldController
                        field='name'
                        label='brax.form: name'
                        component={TextField}
                        dependentFields={{
                            hopp: 'hopp',
                        }}
                        errors={{
                            longerThanHopp:     (val, fields) =>
                                (val && val.length && fields?.hopp && (val.length > fields.hopp.length)) ? {length: val.length} : false,
                        }}
                    />
                </div>
                <div>
                    <XpFormFieldController
                        field='hopp'
                        label='brax.form: hopp'
                        component={TextField}
                    />
                </div>
            </XpForm>
*/}

        </div>
    )
}
export default withRouter(TestPage);



