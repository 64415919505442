import {xpColCreate, xpColHeadCreate, xpColShortDateColCreate} from "gui-common/xpGrid/xpGridFunctions";


export function getCashLadderColumns(translate, selectedProjectionDates) {
    if (!selectedProjectionDates || !selectedProjectionDates.length) return [];

    let subArray = [];
    let outArray = [];
    let hiddenState = {};

    hiddenState.id          = true;
    hiddenState.name        = false;
    hiddenState.currencyStr = false;
    xpColCreate(translate, subArray, hiddenState, 'name'         , 150, 100 , 'accountsList.name', {cellRenderer: 'agGroupCellRenderer', cellStyle: {textAlign: "right"}, sort: 'desc', lockVisible: true, lockPosition: true, suppressSizeToFit: true});
    xpColCreate(translate, subArray, hiddenState, 'id'           , 100, 50, 'accountsList.systemId');
    xpColCreate(translate, subArray, hiddenState, 'currencyStr'  , 90 , 50, 'accountsList.currency', {suppressSizeToFit: true});
    xpColHeadCreate(translate, outArray, subArray, 'accountsList.accountDetailsGroup');

    for (let date of selectedProjectionDates) {
        xpColShortDateColCreate(subArray, 80, date, undefined, {cellStyle: {textAlign: "right"}});
    }
    xpColHeadCreate(translate, outArray, subArray, 'accountCashLadderList.cashProjectionGroup');
    return outArray;
}

