import {selectWebSessionAwt} from "./webSessionSelectors";
import {webSessionPopAwt} from "./webSessionReducer";


export function getWebSessionAwt(dispatch, state) {
    const key = selectWebSessionAwt(state);
    if (!key) return null;
    dispatch(webSessionPopAwt());
    return key;
}
