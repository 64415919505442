import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {activeCurrencyPairsSelector} from "gui-common/orm/ormSelectors";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getSpecificBankAccountSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import {useXpFormContext, useXpFormField, useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";

export function useCurrencyFromBankAccount(props) {
    const dispatch = useDispatch();
    const formContext = useXpFormContext();
    const accountCurrencyId  = useXpFormField(props.baseEntityFormModel + '.currencyId')
    const formDataCurrencyId = useXpFormField('currencyId');

    useEffect(
        () => {
            if (props.ownedByParent) return;
            if (props.ormModel !== 'BankAccount') return;
            if ((formContext.formUseState !== XP_FORM_VIEW) && (formDataCurrencyId !== accountCurrencyId))
                dispatch(xpFormChangeField(formContext.formModel + ".currencyId", accountCurrencyId));
        },
        [accountCurrencyId, formDataCurrencyId, formContext],
    );
}

export function useCurrencyPairFromBar(props) {
    const currencyPairs = useSelector(activeCurrencyPairsSelector);
    const dispatch      = useDispatch();
    const formContext   = useXpFormContext();
    const entityData    = useXpFormFields({dependentFieldModels: {
            parentAccountId: props.baseEntityFormModel + '.parentAccountId',
            currencyId     : props.baseEntityFormModel + '.currencyId',
        }})

    const parentAccount = useSelectorInstance(getSpecificBankAccountSelector, {selectId: entityData?.parentAccountId});
    const formDataCurrencyPairId = useXpFormField('currencyPairId');

    useEffect(
        () => {
            if (props.ownedByParent) return;
            if (props.ormModel !== 'BankAccount') return;
            if (!parentAccount) return;

            const currencyPair = currencyPairs.find(cp => {
                if ((cp.baseCurrencyId === parentAccount.currencyId) && (cp.quoteCurrencyId === entityData?.currencyId       )) return true;
                if ((cp.baseCurrencyId === entityData?.currencyId       ) && (cp.quoteCurrencyId === parentAccount.currencyId)) return true;
                return false;
            })

            if (!currencyPair) return;

            if ((formContext.formUseState !== XP_FORM_VIEW) && (formDataCurrencyPairId !== currencyPair.id))
                dispatch(xpFormChangeField(formContext.formModel + ".currencyPairId", currencyPair.id));
        },
        [formContext, formDataCurrencyPairId, parentAccount],
    );
}
