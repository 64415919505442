import React from 'react'
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {selectOrmItemsLoading} from "gui-common/orm/ormLoadingSelectors";


import spinnerGif from 'gui-common/resources/spinner.gif';
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function DataLoading() {
    const loadingText = useSelector(state => state.loginProcessState.loadingText);
    const translate       = useXpTranslateFunction();
    const ormItemsLoading = useSelector(selectOrmItemsLoading);
    if (!selectOrmItemsLoading || !selectOrmItemsLoading.length)

    return (
        <div>
            <div style={{paddingTop: 0, marginTop: "-10px"}} xp-test-id='xpDataLoaderSpinner'>
                <img className="loadingSpinner" src={spinnerGif} alt="waiting" />
                {loadingText}
            </div>
        {ormItemsLoading.map(model =>
            <div key={model} style={{paddingTop: 0, marginTop: "-10px"}} xp-test-id='xpDataLoaderSpinner'>
                <img className="loadingSpinner" src={spinnerGif} alt="waiting" />
                {translate('general.loadingData') + translate('general.modelNamesLargePlural.'+model) + "..."}
            </div>
        )}
        </div>
    );
}

export default withRouter(DataLoading);

/*
<div style={{paddingTop: 0, marginTop: "-10px"}} xp-test-id='xpDataLoaderSpinner'>
    <img className="loadingSpinner" src={spinnerGif} alt="waiting" />
    {loadingText}
</div>
*/
