import React, {useMemo} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {activeBankAccountInstitutionsSelector, activeCurrenciesSelector} from "gui-common/orm/ormSelectors";
import 'features/onboardingWizard/onboardingWizard.css';
import removeIcon from "gui-common/resources/removeIcon.png";
import {getDefaultObject, setTranslatedError} from "gui-common/functions/functions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormAddElement, xpFormChangeField, xpFormRemoveElement} from "gui-common/xpForm/core/xpFormReducer";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function ObWizardCurrenciesForm(props) {
    const formContext = useXpFormContext()
    const ormCurrencies   = useSelector(activeCurrenciesSelector);
    const ormInstitutions = useSelector(activeBankAccountInstitutionsSelector);
    const formCurrencies  = useXpFormField('currencies')
    const dispatch = useDispatch();
    const translate         = useSelector(selectTranslateFunction);

    const accountDefaults = useMemo(
        () => {
            const defaultInstitution = getDefaultObject(ormInstitutions);
            return {
                institutionId: defaultInstitution?.id,
                useSweep: true,
                useTopUp: true,
                useSwapForSweep: false,
                useSwapForTopUp: false,
                useFor: 'ALL',
                accountType: 'PHYSICAL'
            };
        },
        []
    );
    const remainingCurrencies = useMemo(
        () => {
            if (!formCurrencies?.length) return ormCurrencies;
            let retArray = [];
            for (const currency of ormCurrencies) {
                if (!formCurrencies.find(formCcy => currency.id === Number(formCcy.currencyId))) retArray.push(currency);
            }
            return retArray;
        },
        [formCurrencies]
    );


    function addCurrency(value) {
        const selectedCurrency = ormCurrencies.find(ccy => ccy.id === Number(value));
        if (!selectedCurrency) return;

        dispatch(xpFormChangeField(formContext.formModel + '.currency', ""));
        setTimeout(() => dispatch(xpFormAddElement(formContext.formModel + '.currencies',
            {
                ...accountDefaults,
                currencyId: selectedCurrency.id,
                currencyName: selectedCurrency.currency
            })), 10);
    }

    function currencyList() {
        if(!formCurrencies?.length)return null;

        return (
            <div className="currencyList">
                <div className="currencyRow">
                    <div className="selectedCurrencies">
                        <p className="valueText"><XpTranslated trKey={props.wizardName + '.selectedCurrencies'}/></p>
                    </div>
                </div>
                {formCurrencies.map((element, index) => {
                    return (
                        <div key={index} className="currencyRow">
                            <div className="removeCurrencies">
                                <img style={{height: '18px', marginTop: "2px", float: "left"}} src={removeIcon} alt="Remove"
                                     className="closeDashboardComponentButton"
                                     onClick={() => dispatch(xpFormRemoveElement(formContext.formModel + '.currencies', index))}
                                />
                            </div>
                            <div className="selectedCurrencies">
                                {element.currencyName}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }


    return (
        <div  className="formCard">
            <div className={"adminFormContainer"}>
                <div className={"adminColumn admin2Column"}>
                    <div className="currencySelect">
                        <XpFormSelectorInput
                            field="currency"
                            formTemplate="onboardingWizardForm"
                            selectList={remainingCurrencies}
                            itemTextField="currency"
                            template={translate(props.wizardName + '.currency.template')}
                            dependentFields={{
                                currencyArray: 'currencies',
                            }}
                            errors = {{
                                noCurrencySelected: (val, fields) => !fields.currencyArray?.length,
                            }}
                            errorMessages = {{
                                noCurrencySelected: setTranslatedError('onboardingWizardForm.currency.errors.noCurrencySelected'),
                            }}
                            onChangeCallback={addCurrency}
                            autoFocus={true}
                        />
                    </div>
                </div>
                <div className={"adminColumn admin2Column"}>
                    {currencyList()}
                </div>
            </div>
        </div>
    )
}

export default ObWizardCurrenciesForm;
