import React, { Component } from 'react'
import {connect} from "react-redux";
import {Route, withRouter} from "react-router-dom";
import {xpMaterialTheme, xpMaterialThemeUseMuiStyle} from "gui-common/css/xpMaterialUiStyling";
import { ThemeProvider } from '@mui/material/styles';
import 'moment/locale/fr';
import 'moment/locale/sv';
import {loadApplicationData} from "appConfig/api/apiConfig";
import {commonStartupThunk} from "gui-common/app/appStartup";
import {push} from "connected-react-router";
import ModalManager from 'gui-common/modals/ModalManager.jsx';
import UserMessagesDisplay from "gui-common/userMessages/UserMessagesDisplay";
import ContextMenuManager from "gui-common/contextMenu/ContextMenuManager";
import MainMenu from 'gui-common/app/MainMenu'
import WelcomeScreen from "features/welcomeScreen/WelcomeScreen";
import DashboardContainer from "gui-common/dashboard/DashboardContainer";
import UserMessagesPage from "gui-common/userMessages/UserMessagesPage";
import GenerateUserMessage from "gui-common/userMessages/GenerateUserMessage";
import UserPreferencesForm from "gui-common/userSettings/UserPreferences";
import DataLoading from "gui-common/components/DataLoading";
import FailedDataLoad from "gui-common/components/FailedDataLoad";
import AuthenticationFailed from "gui-common/components/AuthenticationFailed";
import AuditViewPage from "gui-common/audit/AuditViewPage";
import ReportPage from "gui-common/report/ReportPage";
import AdminUserRights from "gui-common/adminUserRights/AdminUserRights";
import ViewMyUserRights from "gui-common/adminUserRights/ViewMyUserRights";
import AdminNoRunDate from "gui-common/noRunDate/AdminNoRunDate";
import FxRejectConfigPage from "gui-common/fxRejectConfig/FxRejectConfigPage";
import EditTranslationsPage from "gui-common/appLocale/xpTranslated/EditTranslationsPage";
import {getAppRouterPaths} from "appConfig/appConfig";
import CurrencyPairConfigPage from "gui-common/currencyPairConfig/CurrencyPairConfigPage";
import BankHolidaysPage from "gui-common/bankHolidays/BankHolidaysPage";
import TestPage from "gui-common/app/TestPage";
import DevInfo from "gui-common/app/DevInfo";

class App extends Component {

    constructor(props) {
        super(props);

        if ((process.env.NODE_ENV !== "production") && (module.hot) && this.props.userLoggedIn) {
            // this.props.push("/welcome"); // Cannot hot reload to any ag-grid page. Crashes :-(
            return;
        } // No need to reload data when hot reloading.

        this.props.commonStartUp(loadApplicationData);
    }

    componentDidMount(){}

    render() {
/* // MT: StateCookie manager is not working with rotating keys and current data load process.
        if(!this.props.appState.cookiesLoaded) {
            return (<div><StateCookieManager/></div>);
        }
*/
        // console.log("Rendering App");
        return (
            <ThemeProvider theme={window.oxygen_env.USE_MUI_FIELD_STYLE ? xpMaterialThemeUseMuiStyle : xpMaterialTheme}>
                <UserMessagesDisplay/>
                <DevInfo/>
                {/*<FocusLock className="oxyGenApp"> MT20210516: Removed to allow materialUI components work properly. Might result in problems...*/}
                    <div className="body oxyGenApp" id="oxyGenApp">
{/* MT: The whole state coockie manager is not working with rotating keys and the current reload infrastructure.
                        <StateCookieManager/>
*/}
                        <ModalManager />
                        <ContextMenuManager />
                        <MainMenu/>
                        <div className="content" id="appContentContainer">
                            <Route path="/welcome"              component={WelcomeScreen}/>
                            <Route path="/dashboard"            component={DashboardContainer}/>
                            <Route path="/userMessages"         component={UserMessagesPage}/>
                            <Route path="/userSettings"         component={UserPreferencesForm}/>
                            <Route path="/loadingData"          component={DataLoading}/>
                            <Route path="/failedDataLoad"       component={FailedDataLoad}/>
                            <Route path="/authenticationFailed" component={AuthenticationFailed}/>
                            <Route path="/auditView"            component={AuditViewPage}/>
                            <Route path="/report"               component={ReportPage}/>
                            <Route path="/adminUserRights"      component={AdminUserRights}/>
                            <Route path="/myUserRights"         component={ViewMyUserRights}/>
                            <Route path="/adminNoRunDates"      component={AdminNoRunDate}/>
                            <Route path="/fxRejectConfig"       component={FxRejectConfigPage}/>
                            <Route path="/currencyPairConfig"   component={CurrencyPairConfigPage}/>
                            <Route path="/editTranslations"     component={EditTranslationsPage}/>
                            <Route path="/generateUserMessage"  component={GenerateUserMessage}/>
                            <Route path="/bankHolidays"         component={BankHolidaysPage}/>
                            <Route path="/testPage"             component={TestPage}/>
                            {getAppRouterPaths().map(item => item)}
                        </div>
                    </div>
                {/*</FocusLock>*/}
            </ThemeProvider>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        commonStartUp:        (loadApplicationData) => dispatch(commonStartupThunk(loadApplicationData)),
        push:                 (loadPath)           => dispatch(push(loadPath)),
    }
};

const mapStateToProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
