import PropTypes from "prop-types";
import React from "react";

function LabelValueDisplay (props) {
    return (
        <div>
            <label style={{margin: 0, lineHeight: '25px', display: props.notInLine ? undefined : 'inline-block', width: props.labelTab ? (props.labelTab + '%') : undefined}}>
                {props.label}
            </label>
            <span className="valueText" style={{display: props.notInLine ? undefined : 'inline-block', width: props.labelTab ? ((100-props.labelTab) + '%') : undefined, textAlign: props.alignRight ? 'right' : undefined}}>{props.value}</span>
        </div>
    )
}
LabelValueDisplay.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alignRight: PropTypes.bool,
    notInLine: PropTypes.bool
};
export default LabelValueDisplay



