import {nonAuthorizedFetch} from "gui-common/api/apiCommon";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {marketSimulatorBaseUrlMap} from "gui-common/simulators/testMarket/marketSimulatorConstants";


function invalidClientPrice(clientPrice) {
    return (!clientPrice || !clientPrice.currencyPair || !clientPrice.sessionId || !clientPrice.tenor);
}
function getRequestUrl(getState, functionName, clientPrice) {
    let returnUrl = selectAppEnvProperty(getState(), 'marketSimBaseUrl');
    returnUrl += marketSimulatorBaseUrlMap[functionName] + "?";
    returnUrl += "sessionId="       + clientPrice.sessionId;
    returnUrl += "&currencyPair="   + clientPrice.currencyPair.key;
    returnUrl += "&tenor="          + clientPrice.tenor;
    if (clientPrice.fixAccount) {
        returnUrl += "&fixAccount=" + clientPrice.fixAccount;
    }
    return returnUrl;
}

export function getClientPriceFromApi(clientPrice) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (invalidClientPrice(clientPrice)) return;


            let logText = "Get price for " + clientPrice.currencyPair.key;
            let requestUrl =  getRequestUrl(getState, 'clientPrice', clientPrice);

            dispatch(nonAuthorizedFetch(requestUrl, logText,
                (resp) => {
                    return (dispatch, getState) => {
                        // dispatch(marketSimulatorConfigUpdate(clientPrice));
                    }},
                emptyFailHandler,
                emptyFailHandler,
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)});

        })
    }
}

export function overrideClientPriceToApi(clientPrice) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (invalidClientPrice(clientPrice)) return;


            let logText = "Set price override for " + clientPrice.currencyPair.key;
            let requestUrl =  getRequestUrl(getState, 'clientPriceOverride', clientPrice);

            dispatch(nonAuthorizedFetch(requestUrl, logText,
                (resp) => {
                    return (dispatch, getState) => {
                        // dispatch(marketSimulatorConfigUpdate(clientPrice));
                    }},
                emptyFailHandler,
                emptyFailHandler,
                'PUT',
                {
                    clientPriceOverride: clientPrice.clientPriceOverride,
                    functionOverride: clientPrice.functionOverride
                },
            )).then(result => {resolve(result)}).catch(err => {reject(err)});

        })
    }
}

export function getFixAccountsFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get fix accounts";
            const requestUrl = selectAppEnvProperty(getState(), 'marketSimBaseUrl') + marketSimulatorBaseUrlMap.fixAccounts;


            dispatch(nonAuthorizedFetch(requestUrl, logText,
                (resp) => {
                    return (dispatch, getState) => {
                        // dispatch(marketSimulatorConfigUpdate(clientPrice));
                    }},
                emptyFailHandler,
                emptyFailHandler,
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)});

        })
    }
}


/*
public class TestMarketClientPrice {
    ClientPrice clientPrice; // alltid påverkad av overrides
    ClientPriceOverride clientPriceOverride;
    String sessionId;
    CurrencyPair currencyPair;
    Tenor tenor;
    String fixAccount;

}

http://dev.xpool.se:10300/test-market/v1/client-prices?sessionId=FIX.4.4:FIX_SIMULATOR-%3ENFC_TEST&currencyPair=EUR/SEK&tenor=TD

http://localhost:10300/test-market/v1/client-prices?sessionId=FIX.4.4:FIX_SIMULATOR-%3ENFC_TEST&currencyPair=EUR/SEK&tenor=TD
http://localhost:10300/test-market/v1/client-prices?sessionId=sdfsdf                           &currencyPair=EUR/SEK&tenor=Spot


http://dev.xpool.se:10300/test-market/v1/client-prices?sessionId=FIX.4.4:FIX_SIMULATOR-%3ENFC_TEST&currencyPair=EUR/SEK&tenor=TD
http://   localhost:10300/test-market/v1/client-prices?sessionId=sdfsdf                           &currencyPair=EUR/SEK&tenor=Spot&fixAccount=Fish

public class ClientPrice {

    private CurrencyPair currencyPair;
    private Tenor tenor;
    @JsonInclude(JsonInclude.Include.NON_NULL)
    private String fixAccount;
    private PriceSource source; // OpenExchange, Override
    private BigDecimal askPrice;
    private BigDecimal bidPrice;
    private BigDecimal askForwardPoints;
    private BigDecimal bidForwardPoints;
    private BigDecimal askSpotPrice;
    private BigDecimal bidSpotPrice;
    private LocalDate settlementDate;
}
*/

/*

export function overrideClientPriceToApi(clientPrice) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!clientPrice) return;
            if (!clientPrice.currencyPair) return;
            if (!clientPrice.bid || !config.ask) return; // Must set both bid and ask.
            if (!clientPrice.bidFwp || !clientPrice.askFwp) return; // Must set both bid and ask.
            const simBaseUrl = selectAppEnvProperty(getState(), 'marketSimBaseUrl');

            let logText = "Set price override for " + clientPrice.currencyPair;
            let requestUrl = marketSimulatorBaseUrlMap.clientPriceOverride + "?";
            requestUrl = requestUrl + "currencyPair="  + config.currencyPair;
            requestUrl = requestUrl + "&bid="          + config.bid         ;
            requestUrl = requestUrl + "&bidFwdPoints=" + config.bidFwp      ;
            requestUrl = requestUrl + "&ask="          + config.ask         ;
            requestUrl = requestUrl + "&askFwdPoints=" + config.askFwp      ;



            dispatch(marketSimulatorConfigUpdate(config));
            dispatch(genericFetch(
                requestUrl,
                logText,
//                setFunctionResponseHandler({currencyPair: config.currencyPair, bid: config.bid, ask: config.ask}),
                setFunctionResponseHandler(config),
                setFunctionFailHandler,
                setFunctionFailHandler,
                'POST',
                undefined, // no body in http request.
                undefined,
                simBaseUrl,
                true
            )).then(result => {resolve(result)}).catch(err => {reject(err)});
        })
    }
}


export function setMarketSimulatorFunctionToApi(config) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!config) return;
            if (!config.currencyPair) return;
            if (!config.responseFunction) return;
            const simBaseUrl = selectAppEnvProperty(getState(), 'marketSimBaseUrl');


            let logText = "Set currency pair simulator function for " + config.currencyPair;
            let requestUrl = simulatorsBaseUrlMap.marketResponse + "?";
            requestUrl = requestUrl + "currencyPair="  + config.currencyPair;
            requestUrl = requestUrl + "&function="     + config.responseFunction;
            requestUrl = requestUrl + "&rejectCodeOpt=" + (config.rejectCode ? config.rejectCode : "00");
            requestUrl = requestUrl + "&rejectMsgOpt="  + (config.rejectMessage ? config.rejectMessage : "None");

            dispatch(marketSimulatorConfigUpdate(config));
            dispatch(genericFetch(
                requestUrl,
                logText,
                setFunctionResponseHandler({currencyPair: config.currencyPair, responseFunction: config.responseFunction, rejectCode: config.rejectCode, rejectMessage: config.rejectCode}),
                setFunctionFailHandler,
                setFunctionFailHandler,
                'POST',
                undefined, // no body in http request.
                undefined,
                simBaseUrl,
                true
            )).then(result => {resolve(result)}).catch(err => {reject(err)});
        })
    }
}

function setFunctionResponseHandler(config) {
    return (resp) => {
        return (dispatch, getState) => {
//            dispatch(marketSimulatorConfigUpdate(config));
        }
    }
}
function setFunctionFailHandler(resp) {
    return (dispatch, getState) => {
        console.error("Failed to make simulator API call");
    }
}
*/

function emptyFailHandler(resp) {
    return (dispatch, getState) => {
    }
}
