
module.exports = {
    clients: [
        {
            id                  : 13,
            name                : "Xp test client 1",
            description         : "Test description 1",
            clientRefId         : "123456789013",
            useLegalEntities    : false,
            timeZone            : "Europe/Stockholm",
            operatingUnit       : {
                default: true,
                description: "Kontoret i Stockholm",
                email: "stockholm@xpool.se",
                enabled: true,
                id: 1,
                name: "Stockholm",
                phoneNumber: "08-471112"
            },
            accountManager      : "Kalle Säl",
            orgNumber           : "471112-1312",
            nettingRule         : "NONE",
            createdDateTime     : "2019-12-20T12:42:34.968Z",
            createdByUser       : "3",
            changedDateTime     : "2020-03-20T12:42:34.968Z",
            changedByUser       : "4",
            disabledDateTime    : null,
            disabledByUser      : null,
            legalEntities       : [
                {
                    id                  : 342,
                    name                : "Xp test LE 1",
                    description         : "Test legal entity",
                    country           : {
                        country: "US",
                        enabled: true,
                        id: 21843,
                        sortOrder: 1,
                    },
                    lei                 : "SEhhhj78986898asd",
                    useLegalEntityUnits : false,
                    legalPersonType     : "CORPORATE",
                    parentEntityId      : null,
                    createdDateTime     : "2020-01-20T12:42:34.968Z",
                    createdByUser       : "4",
                    changedDateTime     : "2020-02-20T12:42:34.968Z",
                    changedByUser       : "2",
                    disabledDateTime    : null,
                    disabledByUser      : null,
                    legalEntityUnits: [
                        {
                            id                          : 567,
                            name                        : "Xp test LEU 1",
                            description                 : "Test business unit",
                            fxEnabled                   : true,
                            fxProvider                  : {
                                countryId: 21317,
                                description: "Swedbank in Sweden",
                                enabled: true,
                                id: 8,
                                name: "SWB FX SE",
                                providerType: "FX",
                                sessionID: "FIX.4.4:SWB_RESTFX->APHELION",
                            },
                            fxShortName                 : "XPTST1",
                            createdDateTime             : "2020-01-20T12:42:34.968Z",
                            createdByUser               : "4",
                            changedDateTime             : "2020-02-20T12:42:34.968Z",
                            changedByUser               : "2",
                            disabledDateTime            : null,
                            disabledByUser              : null,
                            bankAccounts                : [
                                {
                                    id: 1,
                                    name: "Xp test bankAccount EUR",
                                    description: "Test bankAccount",
                                    accountNumber: "4711121314",
                                    clearingNumber: "67832",
                                    bic: "",
                                    iban: "",
                                    ledgerAccount: "1234",
                                    currency: {
                                        currency: "EUR",
                                        enabled: true,
                                        id: 4543826,
                                        sortOrder: 7
                                    },
                                    institution: {
                                        id: 34,
                                        name: "Test BAI",
                                        description:"BAI desc.",
                                        country           : {
                                            country: "US",
                                            enabled: true,
                                            id: 21843,
                                            sortOrder: 1,
                                        },
                                    },
                                    parentAccountId: null,
                                    creditType: "UNLIMITED_CREDIT",
                                    availableCredit: null,
                                    bankAccountBalance: {
                                        balanceTime: "2020-02-23 12:13:14",
                                        pendingBalance: 123,
                                        ledgerBalance: 125,
                                        postPoolingBalance: 200,
                                        aggregatedBalance: 450,
                                        aggregatedPostPoolingBalance: 560,
                                    },
                                    createdDateTime: "2020-02-23 12:13:14",
                                    modifiedDateTime: "2020-02-23 22:13:14",
                                    disabledDateTime: null,
                                    createdByUser: 2,
                                    changedByUser: 2,
                                    disabledByUser: null,
                                }, {
                                    id: 2,
                                    name: "Xp test bankAccount USD",
                                    description: "Test bankAccount",
                                    accountNumber: "4711121314",
                                    clearingNumber: "67832",
                                    bic: "",
                                    iban: "",
                                    ledgerAccount: "1234",
                                    currency: {
                                        currency: "USD",
                                        enabled: true,
                                        id: 5591876,
                                        sortOrder: 6,
                                    },
                                    institution: {
                                        id: 34,
                                        name: "Test BAI",
                                        description:"BAI desc.",
                                        country           : {
                                            country: "US",
                                            enabled: true,
                                            id: 21843,
                                            sortOrder: 1,
                                        },
                                    },
                                    parentAccountId: 1,
                                    creditType: "UNLIMITED_CREDIT",
                                    availableCredit: null,
                                    bankAccountBalance: {
                                        balanceTime: "2020-02-23 12:13:14",
                                        pendingBalance: 123,
                                        ledgerBalance: 125,
                                        postPoolingBalance: 200,
                                        aggregatedBalance: 450,
                                        aggregatedPostPoolingBalance: 560,
                                    },
                                    stopSwappingAfter: null,
                                    createdDateTime: "2020-02-23 12:13:14",
                                    modifiedDateTime: "2020-02-23 22:13:14",
                                    disabledDateTime: null,
                                    createdByUser: 2,
                                    changedByUser: 2,
                                    disabledByUser: null,
                                }
                            ]
                        },
                    ],
                },
            ],
        }
    ],
    orders: [
        {
            id                  : 1,
            clientId            : 13,
            baseCurrency        : {
                currency        : "USD",
                enabled         : true,
                id              : 5591876,
                sortOrder       : 7,
            },
            currencyPair: {
                enabled: true,
                id: 19515584862700876,
                key: "EUR/SEK",
                baseCurrency: {
                    currency: "EUR",
                    enabled: true,
                    id: 4543826,
                    sortOrder: 7
                },
                quoteCurrency: {
                    currency: "SEK",
                    enabled: true,
                    id: 5457227,
                    sortOrder: 14
                }
            },
            nominalAmount       : 340,
            sourceAccountId     : 1,
            destinationAccountId: 1,
            sourceAmount        : 200,
            destinationAmount   : 400,
            orderType           : "Today",
            orderStatus         : "Suggested",
            orderHold           : "Active",
            actions             : ['on-hold', 'send'],
            externalReference   : "Test order ref",
            fxPriceId           : 1011003,
            fxRateExecution     : 4.711,
            fxRateFarLegExecution: 4.712,
            sourceValueDate     : "2020-04-20",
            destinationValueDate: "2020-04-10",
            createdDateTime     : "2019-12-20T12:42:34.968Z",
            modifiedDateTime    : "2019-12-20T14:22:34.968Z",
        }
    ],
    operatingUnits: [
        {
            default: true,
            description: "Kontoret i Stockholm",
            email: "stockholm@xpool.se",
            enabled: true,
            id: 1,
            name: "Stockholm",
            phoneNumber: "08-471112"
        }, {
            default: false,
            description: "Kontoret i Göteborg",
            email: "gothenburg@xpool.se",
            enabled: true,
            id: 2,
            name: "Göteborg",
            phoneNumber: "031-471113"
        }, {
            default: false,
            description: "Kontoret i Malmö",
            email: "malmo@xpool.se",
            enabled: true,
            id: 3,
            name: "Malmö",
            phoneNumber: "040-471114",
        }
    ],
    currencies: [
        {
            currency: "EUR",
            enabled: true,
            id: 4543826,
            sortOrder: 3,
        }, {
            currency: "GBP",
            enabled: true,
            id: 4670032,
            sortOrder: 4,
        }, {
            currency: "USD",
            enabled: true,
            id: 5591876,
            sortOrder: 7,
        }, {
            currency: "SEK",
            enabled: true,
            id: 5457227,
            sortOrder: 8,
        }
    ],
    currencyPairs: [
        {
            enabled: true,
            id: 19515584862700876,
            key: "EUR/SEK",
            baseCurrency: {
                currency: "EUR",
                enabled: true,
                id: 4543826,
                sortOrder: 7
            },
            quoteCurrency: {
                currency: "SEK",
                enabled: true,
                id: 5457227,
                sortOrder: 14
            },
            validAgreementTypes: ['MarketOrderAgreement'],
        }, {
            enabled: true,
            id: 24016925337273676,
            key: "EUR/USD",
            baseCurrency: {
                currency: "EUR",
                enabled: true,
                id: 4543826,
                sortOrder: 7
            },
            quoteCurrency: {
                currency: "USD",
                enabled: true,
                id: 5591876,
                sortOrder: 7,
            },
            validAgreementTypes: ['ListRateAgreement'],
        }, {
            enabled: true,
            id: 19515584862375756,
            key: "USD/SEK",
            baseCurrency: {
                currency: "USD",
                enabled: true,
                id: 5591876,
                sortOrder: 7,
            },
            quoteCurrency: {
                currency: "SEK",
                enabled: true,
                id: 5457227,
                sortOrder: 14
            },
            validAgreementTypes: ['MarketOrderAllAgreement', 'ListRateAgreement'],
        }
    ],
    countries: [
        {
            country: "SE",
            enabled: true,
            id: 21317,
            sortOrder: 0,
        }, {
            country: "US",
            enabled: true,
            id: 21843,
            sortOrder: 1,
        }, {
            country: "FI",
            enabled: true,
            id: 17993,
            sortOrder: 2,
        }
    ],
    tenors: [
        {
            id: 1,
            name: "TD",
            validAgreementTypes: ['ListRateAgreement', 'MarketOrderAgreement'],
        }, {
            id: 2,
            name: "TM",
            validAgreementTypes: ['ListRateAgreement', 'MarketOrderAgreement'],
        }, {
            id: 3,
            name: "SP",
            validAgreementTypes: ['ListRateAgreement', 'MarketOrderAllAgreement'],
        }, {
            id: 4,
            name: "SN",
            validAgreementTypes: ['ListRateAgreement', 'MarketOrderAllAgreement'],
        }
    ],
    topics: [
        "Admin",
        "Support",
        "Order",
        "BankAccount",
        "Client",
        "Info"
    ],
    providers: [
        {
            countryId: 21317,
            description: "Refix Market Order",
            enabled: true,
            id: 13,
            name: "Refix Market Order",
            providerType: "SYSTEM",
            sessionID: "NoSession"
        }, {
            countryId: 21317,
            description: "FxPrice Service",
            enabled: true,
            id: 16,
            name: "FxPrice Service",
            providerType: "SYSTEM",
            sessionID: "NoSession"
        }, {
            countryId: 21317,
            description: "Refix API",
            enabled: true,
            id: 17,
            name: "Refix API",
            providerType: "SYSTEM",
            sessionID: "NoSession"
        }, {
            countryId: 21317,
            description: "Swedbank in Sweden",
            enabled: true,
            id: 8,
            name: "SWB FX SE",
            providerType: "FX",
            sessionID: "FIX.4.4:SWB_RESTFX->APHELION"
        }
    ],
    erpSystems: [
        {
            id:            1,
            name:          "Visma SPCS",
            description:   "",
            erpPrototype:  "",
            erpSpecifics:  null,
        }, {
            id:            2,
            name:          "Fortnox",
            description:   "",
            erpPrototype:  "",
            erpSpecifics:  null,
        }
    ],
    fxPrices: [
        {
            askConversionRate: 3.678342,
            askRate: 3.678342,
            bidConversionRate: 3.663658,
            bidRate: 3.663658,
            channel: null,
            currencyPairId: 24016925336094020,
            fromCurrencyId: 5591876,
            id: 1011002,
            indicator: "Sell",
            midConversionRate: 3.671,
            midRate: 3.671,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 7.3647,
            askRate: 7.3647,
            bidConversionRate: 7.3353,
            bidRate: 7.3353,
            channel: null,
            currencyPairId: 18930584546856780,
            fromCurrencyId: 4407620,
            id: 1011003,
            indicator: "Sell",
            midConversionRate: 7.35,
            midRate: 7.35,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 9.6192,
            askRate: 9.6192,
            bidConversionRate: 9.5808,
            bidRate: 9.5808,
            channel: null,
            currencyPairId: 20057635504540484,
            fromCurrencyId: 4670032,
            id: 1011004,
            indicator: "Sell",
            midConversionRate: 9.6,
            midRate: 9.6,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 6.94386,
            askRate: 6.94386,
            bidConversionRate: 6.91614,
            bidRate: 6.91614,
            channel: null,
            currencyPairId: 23440781244319052,
            fromCurrencyId: 5457732,
            id: 1011005,
            indicator: "Sell",
            midConversionRate: 6.93,
            midRate: 6.93,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.97194,
            askRate: 0.97194,
            bidConversionRate: 0.96806,
            bidRate: 0.96806,
            channel: null,
            currencyPairId: 24016925336225864,
            fromCurrencyId: 5591876,
            id: 1011006,
            indicator: "Sell",
            midConversionRate: 0.97,
            midRate: 0.97,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 2.39478,
            askRate: 2.39478,
            bidConversionRate: 2.38522,
            bidRate: 2.38522,
            channel: null,
            currencyPairId: 18389624825136452,
            fromCurrencyId: 4281668,
            id: 1011007,
            indicator: "Sell",
            midConversionRate: 2.39,
            midRate: 2.39,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 7.68534,
            askRate: 7.68534,
            bidConversionRate: 7.65466,
            bidRate: 7.65466,
            channel: null,
            currencyPairId: 19515584861654616,
            fromCurrencyId: 4543826,
            id: 1011008,
            indicator: "Sell",
            midConversionRate: 7.67,
            midRate: 7.67,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 1.0521,
            askRate: 1.0521,
            bidConversionRate: 1.0479,
            bidRate: 1.0479,
            channel: null,
            currencyPairId: 19515584861653064,
            fromCurrencyId: 4543826,
            id: 1011009,
            indicator: "Sell",
            midConversionRate: 1.05,
            midRate: 1.05,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 1.24248,
            askRate: 1.24248,
            bidConversionRate: 1.23752,
            bidRate: 1.23752,
            channel: null,
            currencyPairId: 20057635505394500,
            fromCurrencyId: 4670032,
            id: 1011010,
            indicator: "Sell",
            midConversionRate: 1.24,
            midRate: 1.24,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5591876,
        },
        {
            askConversionRate: 5.50098,
            askRate: 5.50098,
            bidConversionRate: 5.47902,
            bidRate: 5.47902,
            channel: null,
            currencyPairId: 23440781243599684,
            fromCurrencyId: 5457732,
            id: 1011011,
            indicator: "Sell",
            midConversionRate: 5.49,
            midRate: 5.49,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 0.43086,
            askRate: 0.43086,
            bidConversionRate: 0.42914,
            bidRate: 0.42914,
            channel: null,
            currencyPairId: 21770666031075148,
            fromCurrencyId: 5068878,
            id: 1011012,
            indicator: "Sell",
            midConversionRate: 0.43,
            midRate: 0.43,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 4.22844,
            askRate: 4.22844,
            bidConversionRate: 4.21156,
            bidRate: 4.21156,
            channel: null,
            currencyPairId: 22054297080712012,
            fromCurrencyId: 5134916,
            id: 1011013,
            indicator: "Sell",
            midConversionRate: 4.22,
            midRate: 4.22,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 6.69336,
            askRate: 6.69336,
            bidConversionRate: 6.66664,
            bidRate: 6.66664,
            channel: null,
            currencyPairId: 18389624825990988,
            fromCurrencyId: 4281668,
            id: 1011014,
            indicator: "Sell",
            midConversionRate: 6.68,
            midRate: 6.68,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 8.78754,
            askRate: 8.78754,
            bidConversionRate: 8.75246,
            bidRate: 8.75246,
            channel: null,
            currencyPairId: 20057635504213590,
            fromCurrencyId: 4670032,
            id: 1011015,
            indicator: "Sell",
            midConversionRate: 8.77,
            midRate: 8.77,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 7.7655,
            askRate: 7.7655,
            bidConversionRate: 7.7345,
            bidRate: 7.7345,
            channel: null,
            currencyPairId: 24016925336554308,
            fromCurrencyId: 5591876,
            id: 1011016,
            indicator: "Sell",
            midConversionRate: 7.75,
            midRate: 7.75,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 4.44888,
            askRate: 4.44888,
            bidConversionRate: 4.43112,
            bidRate: 4.43112,
            channel: null,
            currencyPairId: 21770666030813270,
            fromCurrencyId: 5068878,
            id: 1011017,
            indicator: "Sell",
            midConversionRate: 4.44,
            midRate: 4.44,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 0.59118,
            askRate: 0.59118,
            bidConversionRate: 0.58882,
            bidRate: 0.58882,
            channel: null,
            currencyPairId: 22054297080645704,
            fromCurrencyId: 5134916,
            id: 1011018,
            indicator: "Sell",
            midConversionRate: 0.59,
            midRate: 0.59,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 16.46286,
            askRate: 16.46286,
            bidConversionRate: 16.39714,
            bidRate: 16.39714,
            channel: null,
            currencyPairId: 18389624825272908,
            fromCurrencyId: 4281668,
            id: 1011019,
            indicator: "Sell",
            midConversionRate: 16.43,
            midRate: 16.43,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 1.66332,
            askRate: 1.66332,
            bidConversionRate: 1.65668,
            bidRate: 1.65668,
            channel: null,
            currencyPairId: 19515584861525316,
            fromCurrencyId: 4543826,
            id: 1011020,
            indicator: "Sell",
            midConversionRate: 1.66,
            midRate: 1.66,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4281668,
        },
        {
            askConversionRate: 1.77354,
            askRate: 1.77354,
            bidConversionRate: 1.76646,
            bidRate: 1.76646,
            channel: null,
            currencyPairId: 19515584862378564,
            fromCurrencyId: 4543826,
            id: 1011021,
            indicator: "Sell",
            midConversionRate: 1.77,
            midRate: 1.77,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5134916,
        },
        {
            askConversionRate: 4.49898,
            askRate: 4.49898,
            bidConversionRate: 4.48102,
            bidRate: 4.48102,
            channel: null,
            currencyPairId: 18389624825334604,
            fromCurrencyId: 4281668,
            id: 1011022,
            indicator: "Sell",
            midConversionRate: 4.49,
            midRate: 4.49,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.6513,
            askRate: 0.6513,
            bidConversionRate: 0.6487,
            bidRate: 0.6487,
            channel: null,
            currencyPairId: 18389624826450756,
            fromCurrencyId: 4281668,
            id: 1011023,
            indicator: "Sell",
            midConversionRate: 0.65,
            midRate: 0.65,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5591876,
        },
        {
            askConversionRate: 0.03507,
            askRate: 0.03507,
            bidConversionRate: 0.03493,
            bidRate: 0.03493,
            channel: null,
            currencyPairId: 20917492251706692,
            fromCurrencyId: 4870233,
            id: 1011024,
            indicator: "Sell",
            midConversionRate: 0.035,
            midRate: 0.035,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 7.29456,
            askRate: 7.29456,
            bidConversionRate: 7.26544,
            bidRate: 7.26544,
            channel: null,
            currencyPairId: 18938289717464664,
            fromCurrencyId: 4409414,
            id: 1011025,
            indicator: "Sell",
            midConversionRate: 7.28,
            midRate: 7.28,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 3.2565,
            askRate: 3.2565,
            bidConversionRate: 3.2435,
            bidRate: 3.2435,
            channel: null,
            currencyPairId: 20348954545969740,
            fromCurrencyId: 4737860,
            id: 1011026,
            indicator: "Sell",
            midConversionRate: 3.25,
            midRate: 3.25,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 1.2024,
            askRate: 1.2024,
            bidConversionRate: 1.1976,
            bidRate: 1.1976,
            channel: null,
            currencyPairId: 20057635504212040,
            fromCurrencyId: 4670032,
            id: 1011027,
            indicator: "Sell",
            midConversionRate: 1.2,
            midRate: 1.2,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 17.20434,
            askRate: 17.20434,
            bidConversionRate: 17.13566,
            bidRate: 17.13566,
            channel: null,
            currencyPairId: 18930584546793550,
            fromCurrencyId: 4407620,
            id: 1011028,
            indicator: "Sell",
            midConversionRate: 17.17,
            midRate: 17.17,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 1.73346,
            askRate: 1.73346,
            bidConversionRate: 1.72654,
            bidRate: 1.72654,
            channel: null,
            currencyPairId: 20057635504210244,
            fromCurrencyId: 4670032,
            id: 1011029,
            indicator: "Sell",
            midConversionRate: 1.73,
            midRate: 1.73,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 17.8857,
            askRate: 17.8857,
            bidConversionRate: 17.8143,
            bidRate: 17.8143,
            channel: null,
            currencyPairId: 23440781243275852,
            fromCurrencyId: 5457732,
            id: 1011030,
            indicator: "Sell",
            midConversionRate: 17.85,
            midRate: 17.85,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 106.53264,
            askRate: 106.53264,
            bidConversionRate: 106.10736,
            bidRate: 106.10736,
            channel: null,
            currencyPairId: 24016925336686680,
            fromCurrencyId: 5591876,
            id: 1011031,
            indicator: "Sell",
            midConversionRate: 106.32,
            midRate: 106.32,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 24.67926,
            askRate: 24.67926,
            bidConversionRate: 24.58074,
            bidRate: 24.58074,
            channel: null,
            currencyPairId: 18938289718122576,
            fromCurrencyId: 4409414,
            id: 1011032,
            indicator: "Sell",
            midConversionRate: 24.63,
            midRate: 24.63,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 5.0601,
            askRate: 5.0601,
            bidConversionRate: 5.0399,
            bidRate: 5.0399,
            channel: null,
            currencyPairId: 18389624825596740,
            fromCurrencyId: 4281668,
            id: 1011033,
            indicator: "Sell",
            midConversionRate: 5.05,
            midRate: 5.05,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 0.41082,
            askRate: 0.41082,
            bidConversionRate: 0.40918,
            bidRate: 0.40918,
            channel: null,
            currencyPairId: 18958102402322252,
            fromCurrencyId: 4414027,
            id: 1011034,
            indicator: "Sell",
            midConversionRate: 0.41,
            midRate: 0.41,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.63126,
            askRate: 0.63126,
            bidConversionRate: 0.62874,
            bidRate: 0.62874,
            channel: null,
            currencyPairId: 18389624825268296,
            fromCurrencyId: 4281668,
            id: 1011035,
            indicator: "Sell",
            midConversionRate: 0.63,
            midRate: 0.63,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 15.47088,
            askRate: 15.47088,
            bidConversionRate: 15.40912,
            bidRate: 15.40912,
            channel: null,
            currencyPairId: 22054297080650316,
            fromCurrencyId: 5134916,
            id: 1011037,
            indicator: "Sell",
            midConversionRate: 15.44,
            midRate: 15.44,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 4.7595,
            askRate: 4.7595,
            bidConversionRate: 4.7405,
            bidRate: 4.7405,
            channel: null,
            currencyPairId: 22054297080974148,
            fromCurrencyId: 5134916,
            id: 1011038,
            indicator: "Sell",
            midConversionRate: 4.75,
            midRate: 4.75,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 8.4168,
            askRate: 8.4168,
            bidConversionRate: 8.3832,
            bidRate: 8.3832,
            channel: null,
            currencyPairId: 19515584861981508,
            fromCurrencyId: 4543826,
            id: 1011039,
            indicator: "Sell",
            midConversionRate: 8.4,
            midRate: 8.4,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 0.87174,
            askRate: 0.87174,
            bidConversionRate: 0.86826,
            bidRate: 0.86826,
            channel: null,
            currencyPairId: 22054297081694020,
            fromCurrencyId: 5134916,
            id: 1011040,
            indicator: "Sell",
            midConversionRate: 0.87,
            midRate: 0.87,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 4.87974,
            askRate: 4.87974,
            bidConversionRate: 4.86026,
            bidRate: 4.86026,
            channel: null,
            currencyPairId: 23440781243337548,
            fromCurrencyId: 5457732,
            id: 1011041,
            indicator: "Sell",
            midConversionRate: 4.87,
            midRate: 4.87,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 6.28254,
            askRate: 6.28254,
            bidConversionRate: 6.25746,
            bidRate: 6.25746,
            channel: null,
            currencyPairId: 22054297081368396,
            fromCurrencyId: 5134916,
            id: 1011042,
            indicator: "Sell",
            midConversionRate: 6.27,
            midRate: 6.27,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 6.90378,
            askRate: 6.90378,
            bidConversionRate: 6.87622,
            bidRate: 6.87622,
            channel: null,
            currencyPairId: 24016925336292172,
            fromCurrencyId: 5591876,
            id: 1011043,
            indicator: "Sell",
            midConversionRate: 6.89,
            midRate: 6.89,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 26.0019,
            askRate: 26.0019,
            bidConversionRate: 25.8981,
            bidRate: 25.8981,
            channel: null,
            currencyPairId: 18938289717467724,
            fromCurrencyId: 4409414,
            id: 1011044,
            indicator: "Sell",
            midConversionRate: 25.95,
            midRate: 25.95,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 27.36462,
            askRate: 27.36462,
            bidConversionRate: 27.25538,
            bidRate: 27.25538,
            channel: null,
            currencyPairId: 19515584861657676,
            fromCurrencyId: 4543826,
            id: 1011045,
            indicator: "Sell",
            midConversionRate: 27.31,
            midRate: 27.31,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 1.87374,
            askRate: 1.87374,
            bidConversionRate: 1.86626,
            bidRate: 1.86626,
            channel: null,
            currencyPairId: 18372032639290188,
            fromCurrencyId: 4277572,
            id: 1011046,
            indicator: "Sell",
            midConversionRate: 1.87,
            midRate: 1.87,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 9.80958,
            askRate: 9.80958,
            bidConversionRate: 9.77042,
            bidRate: 9.77042,
            channel: null,
            currencyPairId: 24016925337273676,
            fromCurrencyId: 5591876,
            id: 1011047,
            indicator: "Sell",
            midConversionRate: 9.79,
            midRate: 9.79,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 2.79558,
            askRate: 2.79558,
            bidConversionRate: 2.78442,
            bidRate: 2.78442,
            channel: null,
            currencyPairId: 18372032639946572,
            fromCurrencyId: 4277572,
            id: 1011048,
            indicator: "Sell",
            midConversionRate: 2.79,
            midRate: 2.79,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.61122,
            askRate: 0.61122,
            bidConversionRate: 0.60878,
            bidRate: 0.60878,
            channel: null,
            currencyPairId: 22054297081828164,
            fromCurrencyId: 5134916,
            id: 1011049,
            indicator: "Sell",
            midConversionRate: 0.61,
            midRate: 0.61,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5591876,
        },
        {
            askConversionRate: 1.53306,
            askRate: 1.53306,
            bidConversionRate: 1.52694,
            bidRate: 1.52694,
            channel: null,
            currencyPairId: 19515584862701380,
            fromCurrencyId: 4543826,
            id: 1011050,
            indicator: "Sell",
            midConversionRate: 1.53,
            midRate: 1.53,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 2.2545,
            askRate: 2.2545,
            bidConversionRate: 2.2455,
            bidRate: 2.2455,
            channel: null,
            currencyPairId: 22054297080513860,
            fromCurrencyId: 5134916,
            id: 1011051,
            indicator: "Sell",
            midConversionRate: 2.25,
            midRate: 2.25,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.91182,
            askRate: 0.91182,
            bidConversionRate: 0.90818,
            bidRate: 0.90818,
            channel: null,
            currencyPairId: 20348954545966680,
            fromCurrencyId: 4737860,
            id: 1011052,
            indicator: "Sell",
            midConversionRate: 0.91,
            midRate: 0.91,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 5.57112,
            askRate: 5.57112,
            bidConversionRate: 5.54888,
            bidRate: 5.54888,
            channel: null,
            currencyPairId: 18930584546462532,
            fromCurrencyId: 4407620,
            id: 1011053,
            indicator: "Sell",
            midConversionRate: 5.56,
            midRate: 5.56,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 0.97194,
            askRate: 0.97194,
            bidConversionRate: 0.96806,
            bidRate: 0.96806,
            channel: null,
            currencyPairId: 18944968391674700,
            fromCurrencyId: 4410969,
            id: 1011054,
            indicator: "Sell",
            midConversionRate: 0.97,
            midRate: 0.97,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 4.5591,
            askRate: 4.5591,
            bidConversionRate: 4.5409,
            bidRate: 4.5409,
            channel: null,
            currencyPairId: 20057635504080196,
            fromCurrencyId: 4670032,
            id: 1011055,
            indicator: "Sell",
            midConversionRate: 4.55,
            midRate: 4.55,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 24.00792,
            askRate: 24.00792,
            bidConversionRate: 23.91208,
            bidRate: 23.91208,
            channel: null,
            currencyPairId: 24016925336885330,
            fromCurrencyId: 5591876,
            id: 1011056,
            indicator: "Sell",
            midConversionRate: 23.96,
            midRate: 23.96,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 2.6052,
            askRate: 2.6052,
            bidConversionRate: 2.5948,
            bidRate: 2.5948,
            channel: null,
            currencyPairId: 23440781243139396,
            fromCurrencyId: 5457732,
            id: 1011057,
            indicator: "Sell",
            midConversionRate: 2.6,
            midRate: 2.6,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 2.67534,
            askRate: 2.67534,
            bidConversionRate: 2.66466,
            bidRate: 2.66466,
            channel: null,
            currencyPairId: 18372032640271692,
            fromCurrencyId: 4277572,
            id: 1011058,
            indicator: "Sell",
            midConversionRate: 2.67,
            midRate: 2.67,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.27054,
            askRate: 0.27054,
            bidConversionRate: 0.26946,
            bidRate: 0.26946,
            channel: null,
            currencyPairId: 18958102401665868,
            fromCurrencyId: 4414027,
            id: 1011059,
            indicator: "Sell",
            midConversionRate: 0.27,
            midRate: 0.27,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 3.38676,
            askRate: 3.38676,
            bidConversionRate: 3.37324,
            bidRate: 3.37324,
            channel: null,
            currencyPairId: 18944968392267856,
            fromCurrencyId: 4410969,
            id: 1011060,
            indicator: "Sell",
            midConversionRate: 3.38,
            midRate: 3.38,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 26.01192,
            askRate: 26.01192,
            bidConversionRate: 25.90808,
            bidRate: 25.90808,
            channel: null,
            currencyPairId: 19515584862312530,
            fromCurrencyId: 4543826,
            id: 1011061,
            indicator: "Sell",
            midConversionRate: 25.96,
            midRate: 25.96,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 10.63122,
            askRate: 10.63122,
            bidConversionRate: 10.58878,
            bidRate: 10.58878,
            channel: null,
            currencyPairId: 19515584862700876,
            fromCurrencyId: 4543826,
            id: 1011062,
            indicator: "Sell",
            midConversionRate: 10.61,
            midRate: 10.61,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 3.09618,
            askRate: 3.09618,
            bidConversionRate: 3.08382,
            bidRate: 3.08382,
            channel: null,
            currencyPairId: 20348954546624590,
            fromCurrencyId: 4737860,
            id: 1011063,
            indicator: "Sell",
            midConversionRate: 3.09,
            midRate: 3.09,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 7.03404,
            askRate: 7.03404,
            bidConversionRate: 7.00596,
            bidRate: 7.00596,
            channel: null,
            currencyPairId: 18930584547181900,
            fromCurrencyId: 4407620,
            id: 1011064,
            indicator: "Sell",
            midConversionRate: 7.02,
            midRate: 7.02,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 132.02352,
            askRate: 132.02352,
            bidConversionRate: 131.49648,
            bidRate: 131.49648,
            channel: null,
            currencyPairId: 20057635504672856,
            fromCurrencyId: 4670032,
            id: 1011065,
            indicator: "Sell",
            midConversionRate: 131.76,
            midRate: 131.76,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 65.21016,
            askRate: 65.21016,
            bidConversionRate: 64.94984,
            bidRate: 64.94984,
            channel: null,
            currencyPairId: 22054297081106520,
            fromCurrencyId: 5134916,
            id: 1011066,
            indicator: "Sell",
            midConversionRate: 65.08,
            midRate: 65.08,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 29.73936,
            askRate: 29.73936,
            bidConversionRate: 29.62064,
            bidRate: 29.62064,
            channel: null,
            currencyPairId: 20057635504871504,
            fromCurrencyId: 4670032,
            id: 1011067,
            indicator: "Sell",
            midConversionRate: 29.68,
            midRate: 29.68,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 1.4529,
            askRate: 1.4529,
            bidConversionRate: 1.4471,
            bidRate: 1.4471,
            channel: null,
            currencyPairId: 18938289718511428,
            fromCurrencyId: 4409414,
            id: 1011068,
            indicator: "Sell",
            midConversionRate: 1.45,
            midRate: 1.45,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 11.1222,
            askRate: 11.1222,
            bidConversionRate: 11.0778,
            bidRate: 11.0778,
            channel: null,
            currencyPairId: 19515584862375756,
            fromCurrencyId: 4543826,
            id: 1011069,
            indicator: "Sell",
            midConversionRate: 11.1,
            midRate: 11.1,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 2.02404,
            askRate: 2.02404,
            bidConversionRate: 2.01596,
            bidRate: 2.01596,
            channel: null,
            currencyPairId: 20057635504937540,
            fromCurrencyId: 4670032,
            id: 1011070,
            indicator: "Sell",
            midConversionRate: 2.02,
            midRate: 2.02,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5134916,
        },
        {
            askConversionRate: 5.09016,
            askRate: 5.09016,
            bidConversionRate: 5.06984,
            bidRate: 5.06984,
            channel: null,
            currencyPairId: 18930584546135640,
            fromCurrencyId: 4407620,
            id: 1011071,
            indicator: "Sell",
            midConversionRate: 5.08,
            midRate: 5.08,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 4.94988,
            askRate: 4.94988,
            bidConversionRate: 4.93012,
            bidRate: 4.93012,
            channel: null,
            currencyPairId: 18930584546200396,
            fromCurrencyId: 4407620,
            id: 1011072,
            indicator: "Sell",
            midConversionRate: 4.94,
            midRate: 4.94,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 10.09014,
            askRate: 10.09014,
            bidConversionRate: 10.04986,
            bidRate: 10.04986,
            channel: null,
            currencyPairId: 18938289718510924,
            fromCurrencyId: 4409414,
            id: 1011073,
            indicator: "Sell",
            midConversionRate: 10.07,
            midRate: 10.07,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 3.78756,
            askRate: 3.78756,
            bidConversionRate: 3.77244,
            bidRate: 3.77244,
            channel: null,
            currencyPairId: 18938289717331268,
            fromCurrencyId: 4409414,
            id: 1011074,
            indicator: "Sell",
            midConversionRate: 3.78,
            midRate: 3.78,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 6.00198,
            askRate: 6.00198,
            bidConversionRate: 5.97802,
            bidRate: 5.97802,
            channel: null,
            currencyPairId: 22054297081693516,
            fromCurrencyId: 5134916,
            id: 1011075,
            indicator: "Sell",
            midConversionRate: 5.99,
            midRate: 5.99,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 5.02002,
            askRate: 5.02002,
            bidConversionRate: 4.99998,
            bidRate: 4.99998,
            channel: null,
            currencyPairId: 23440781243272790,
            fromCurrencyId: 5457732,
            id: 1011076,
            indicator: "Sell",
            midConversionRate: 5.01,
            midRate: 5.01,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 1.08216,
            askRate: 1.08216,
            bidConversionRate: 1.07784,
            bidRate: 1.07784,
            channel: null,
            currencyPairId: 19515584862835524,
            fromCurrencyId: 4543826,
            id: 1011077,
            indicator: "Sell",
            midConversionRate: 1.08,
            midRate: 1.08,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5591876,
        },
        {
            askConversionRate: 0.89178,
            askRate: 0.89178,
            bidConversionRate: 0.88822,
            bidRate: 0.88822,
            channel: null,
            currencyPairId: 20348954546031436,
            fromCurrencyId: 4737860,
            id: 1011078,
            indicator: "Sell",
            midConversionRate: 0.89,
            midRate: 0.89,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 76.4025,
            askRate: 76.4025,
            bidConversionRate: 76.0975,
            bidRate: 76.0975,
            channel: null,
            currencyPairId: 18930584546594904,
            fromCurrencyId: 4407620,
            id: 1011079,
            indicator: "Sell",
            midConversionRate: 76.25,
            midRate: 76.25,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 4.33866,
            askRate: 4.33866,
            bidConversionRate: 4.32134,
            bidRate: 4.32134,
            channel: null,
            currencyPairId: 22054297080647256,
            fromCurrencyId: 5134916,
            id: 1011080,
            indicator: "Sell",
            midConversionRate: 4.33,
            midRate: 4.33,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 1.06212,
            askRate: 1.06212,
            bidConversionRate: 1.05788,
            bidRate: 1.05788,
            channel: null,
            currencyPairId: 18389624825993796,
            fromCurrencyId: 4281668,
            id: 1011081,
            indicator: "Sell",
            midConversionRate: 1.06,
            midRate: 1.06,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5134916,
        },
        {
            askConversionRate: 12.15426,
            askRate: 12.15426,
            bidConversionRate: 12.10574,
            bidRate: 12.10574,
            channel: null,
            currencyPairId: 20057635505259852,
            fromCurrencyId: 4670032,
            id: 1011082,
            indicator: "Sell",
            midConversionRate: 12.13,
            midRate: 12.13,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 1.51302,
            askRate: 1.51302,
            bidConversionRate: 1.50698,
            bidRate: 1.50698,
            channel: null,
            currencyPairId: 19515584861651268,
            fromCurrencyId: 4543826,
            id: 1011083,
            indicator: "Sell",
            midConversionRate: 1.51,
            midRate: 1.51,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 6.53304,
            askRate: 6.53304,
            bidConversionRate: 6.50696,
            bidRate: 6.50696,
            channel: null,
            currencyPairId: 18372032639883344,
            fromCurrencyId: 4277572,
            id: 1011084,
            indicator: "Sell",
            midConversionRate: 6.52,
            midRate: 6.52,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.87174,
            askRate: 0.87174,
            bidConversionRate: 0.86826,
            bidRate: 0.86826,
            channel: null,
            currencyPairId: 19515584861913680,
            fromCurrencyId: 4543826,
            id: 1011085,
            indicator: "Sell",
            midConversionRate: 0.87,
            midRate: 0.87,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4670032,
        },
        {
            askConversionRate: 7.99596,
            askRate: 7.99596,
            bidConversionRate: 7.96404,
            bidRate: 7.96404,
            channel: null,
            currencyPairId: 18938289717791556,
            fromCurrencyId: 4409414,
            id: 1011086,
            indicator: "Sell",
            midConversionRate: 7.98,
            midRate: 7.98,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 10.56108,
            askRate: 10.56108,
            bidConversionRate: 10.51892,
            bidRate: 10.51892,
            channel: null,
            currencyPairId: 18938289718185804,
            fromCurrencyId: 4409414,
            id: 1011087,
            indicator: "Sell",
            midConversionRate: 10.54,
            midRate: 10.54,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 2.63526,
            askRate: 2.63526,
            bidConversionRate: 2.62474,
            bidRate: 2.62474,
            channel: null,
            currencyPairId: 18930584546002244,
            fromCurrencyId: 4407620,
            id: 1011088,
            indicator: "Sell",
            midConversionRate: 2.63,
            midRate: 2.63,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 4.61922,
            askRate: 4.61922,
            bidConversionRate: 4.60078,
            bidRate: 4.60078,
            channel: null,
            currencyPairId: 18389624825269850,
            fromCurrencyId: 4281668,
            id: 1011089,
            indicator: "Sell",
            midConversionRate: 4.61,
            midRate: 4.61,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 1.7535,
            askRate: 1.7535,
            bidConversionRate: 1.7465,
            bidRate: 1.7465,
            channel: null,
            currencyPairId: 20057635505260356,
            fromCurrencyId: 4670032,
            id: 1011090,
            indicator: "Sell",
            midConversionRate: 1.75,
            midRate: 1.75,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 10.26048,
            askRate: 10.26048,
            bidConversionRate: 10.21952,
            bidRate: 10.21952,
            channel: null,
            currencyPairId: 24016925336948556,
            fromCurrencyId: 5591876,
            id: 1011091,
            indicator: "Sell",
            midConversionRate: 10.24,
            midRate: 10.24,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 14.69934,
            askRate: 14.69934,
            bidConversionRate: 14.64066,
            bidRate: 14.64066,
            channel: null,
            currencyPairId: 22054297081305170,
            fromCurrencyId: 5134916,
            id: 1011092,
            indicator: "Sell",
            midConversionRate: 14.67,
            midRate: 14.67,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 1.32264,
            askRate: 1.32264,
            bidConversionRate: 1.31736,
            bidRate: 1.31736,
            channel: null,
            currencyPairId: 20348954546687820,
            fromCurrencyId: 4737860,
            id: 1011093,
            indicator: "Sell",
            midConversionRate: 1.32,
            midRate: 1.32,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 3.98796,
            askRate: 3.98796,
            bidConversionRate: 3.97204,
            bidRate: 3.97204,
            channel: null,
            currencyPairId: 19515584861521220,
            fromCurrencyId: 4543826,
            id: 1011094,
            indicator: "Sell",
            midConversionRate: 3.98,
            midRate: 3.98,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.067134,
            askRate: 0.067134,
            bidConversionRate: 0.066866,
            bidRate: 0.066866,
            channel: null,
            currencyPairId: 20917492251840090,
            fromCurrencyId: 4870233,
            id: 1011095,
            indicator: "Sell",
            midConversionRate: 0.067,
            midRate: 0.067,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 3.56712,
            askRate: 3.56712,
            bidConversionRate: 3.55288,
            bidRate: 3.55288,
            channel: null,
            currencyPairId: 18944968391613004,
            fromCurrencyId: 4410969,
            id: 1011096,
            indicator: "Sell",
            midConversionRate: 3.56,
            midRate: 3.56,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 1.0521,
            askRate: 1.0521,
            bidConversionRate: 1.0479,
            bidRate: 1.0479,
            channel: null,
            currencyPairId: 21770666030357068,
            fromCurrencyId: 5068878,
            id: 1011097,
            indicator: "Sell",
            midConversionRate: 1.05,
            midRate: 1.05,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 69.47868,
            askRate: 69.47868,
            bidConversionRate: 69.20132,
            bidRate: 69.20132,
            channel: null,
            currencyPairId: 18389624825729110,
            fromCurrencyId: 4281668,
            id: 1011098,
            indicator: "Sell",
            midConversionRate: 69.34,
            midRate: 69.34,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 1.42284,
            askRate: 1.42284,
            bidConversionRate: 1.41716,
            bidRate: 1.41716,
            channel: null,
            currencyPairId: 19223084704941388,
            fromCurrencyId: 4475723,
            id: 1011099,
            indicator: "Sell",
            midConversionRate: 1.42,
            midRate: 1.42,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 31.29246,
            askRate: 31.29246,
            bidConversionRate: 31.16754,
            bidRate: 31.16754,
            channel: null,
            currencyPairId: 20057635504216652,
            fromCurrencyId: 4670032,
            id: 1011100,
            indicator: "Sell",
            midConversionRate: 31.23,
            midRate: 31.23,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 1.01202,
            askRate: 1.01202,
            bidConversionRate: 1.00798,
            bidRate: 1.00798,
            channel: null,
            currencyPairId: 18930584547182404,
            fromCurrencyId: 4407620,
            id: 1011101,
            indicator: "Sell",
            midConversionRate: 1.01,
            midRate: 1.01,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 1.38276,
            askRate: 1.38276,
            bidConversionRate: 1.37724,
            bidRate: 1.37724,
            channel: null,
            currencyPairId: 18944968392656204,
            fromCurrencyId: 4410969,
            id: 1011102,
            indicator: "Sell",
            midConversionRate: 1.38,
            midRate: 1.38,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 115.5306,
            askRate: 115.5306,
            bidConversionRate: 115.0694,
            bidRate: 115.0694,
            channel: null,
            currencyPairId: 19515584862113880,
            fromCurrencyId: 4543826,
            id: 1011103,
            indicator: "Sell",
            midConversionRate: 115.3,
            midRate: 115.3,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 1.26252,
            askRate: 1.26252,
            bidConversionRate: 1.25748,
            bidRate: 1.25748,
            channel: null,
            currencyPairId: 20348954547012940,
            fromCurrencyId: 4737860,
            id: 1011104,
            indicator: "Sell",
            midConversionRate: 1.26,
            midRate: 1.26,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 25.2504,
            askRate: 25.2504,
            bidConversionRate: 25.1496,
            bidRate: 25.1496,
            channel: null,
            currencyPairId: 24016925336230476,
            fromCurrencyId: 5591876,
            id: 1011105,
            indicator: "Sell",
            midConversionRate: 25.2,
            midRate: 25.2,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 18.10614,
            askRate: 18.10614,
            bidConversionRate: 18.03386,
            bidRate: 18.03386,
            channel: null,
            currencyPairId: 18930584546138700,
            fromCurrencyId: 4407620,
            id: 1011106,
            indicator: "Sell",
            midConversionRate: 18.07,
            midRate: 18.07,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 8.54706,
            askRate: 8.54706,
            bidConversionRate: 8.51294,
            bidRate: 8.51294,
            channel: null,
            currencyPairId: 20057635504278348,
            fromCurrencyId: 4670032,
            id: 1011107,
            indicator: "Sell",
            midConversionRate: 8.53,
            midRate: 8.53,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 12.71538,
            askRate: 12.71538,
            bidConversionRate: 12.66462,
            bidRate: 12.66462,
            channel: null,
            currencyPairId: 20057635504934732,
            fromCurrencyId: 4670032,
            id: 1011108,
            indicator: "Sell",
            midConversionRate: 12.69,
            midRate: 12.69,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.013026,
            askRate: 0.013026,
            bidConversionRate: 0.012974,
            bidRate: 0.012974,
            channel: null,
            currencyPairId: 20917492252886852,
            fromCurrencyId: 4870233,
            id: 1011109,
            indicator: "Sell",
            midConversionRate: 0.013,
            midRate: 0.013,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 0.47094,
            askRate: 0.47094,
            bidConversionRate: 0.46906,
            bidRate: 0.46906,
            channel: null,
            currencyPairId: 20348954545833284,
            fromCurrencyId: 4737860,
            id: 1011110,
            indicator: "Sell",
            midConversionRate: 0.47,
            midRate: 0.47,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.67134,
            askRate: 0.67134,
            bidConversionRate: 0.66866,
            bidRate: 0.66866,
            channel: null,
            currencyPairId: 22042232517577548,
            fromCurrencyId: 5132107,
            id: 1011111,
            indicator: "Sell",
            midConversionRate: 0.67,
            midRate: 0.67,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.8517,
            askRate: 0.8517,
            bidConversionRate: 0.8483,
            bidRate: 0.8483,
            channel: null,
            currencyPairId: 22054297080643908,
            fromCurrencyId: 5134916,
            id: 1011112,
            indicator: "Sell",
            midConversionRate: 0.85,
            midRate: 0.85,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 16.9839,
            askRate: 16.9839,
            bidConversionRate: 16.9161,
            bidRate: 16.9161,
            channel: null,
            currencyPairId: 23440781243930704,
            fromCurrencyId: 5457732,
            id: 1011113,
            indicator: "Sell",
            midConversionRate: 16.95,
            midRate: 16.95,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.92184,
            askRate: 0.92184,
            bidConversionRate: 0.91816,
            bidRate: 0.91816,
            channel: null,
            currencyPairId: 18389624826316612,
            fromCurrencyId: 4281668,
            id: 1011114,
            indicator: "Sell",
            midConversionRate: 0.92,
            midRate: 0.92,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 7.25448,
            askRate: 7.25448,
            bidConversionRate: 7.22552,
            bidRate: 7.22552,
            channel: null,
            currencyPairId: 23440781243993932,
            fromCurrencyId: 5457732,
            id: 1011115,
            indicator: "Sell",
            midConversionRate: 7.24,
            midRate: 7.24,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 1.39278,
            askRate: 1.39278,
            bidConversionRate: 1.38722,
            bidRate: 1.38722,
            channel: null,
            currencyPairId: 24016925336224068,
            fromCurrencyId: 5591876,
            id: 1011116,
            indicator: "Sell",
            midConversionRate: 1.39,
            midRate: 1.39,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 0.092184,
            askRate: 0.092184,
            bidConversionRate: 0.091816,
            bidRate: 0.091816,
            channel: null,
            currencyPairId: 20917492252886348,
            fromCurrencyId: 4870233,
            id: 1011117,
            indicator: "Sell",
            midConversionRate: 0.092,
            midRate: 0.092,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 1.41282,
            askRate: 1.41282,
            bidConversionRate: 1.40718,
            bidRate: 1.40718,
            channel: null,
            currencyPairId: 24016925337274180,
            fromCurrencyId: 5591876,
            id: 1011118,
            indicator: "Sell",
            midConversionRate: 1.41,
            midRate: 1.41,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 7.47492,
            askRate: 7.47492,
            bidConversionRate: 7.44508,
            bidRate: 7.44508,
            channel: null,
            currencyPairId: 19515584861719372,
            fromCurrencyId: 4543826,
            id: 1011119,
            indicator: "Sell",
            midConversionRate: 7.46,
            midRate: 7.46,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.06513,
            askRate: 0.06513,
            bidConversionRate: 0.06487,
            bidRate: 0.06487,
            channel: null,
            currencyPairId: 20917492251904844,
            fromCurrencyId: 4870233,
            id: 1011120,
            indicator: "Sell",
            midConversionRate: 0.065,
            midRate: 0.065,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.39078,
            askRate: 0.39078,
            bidConversionRate: 0.38922,
            bidRate: 0.38922,
            channel: null,
            currencyPairId: 18958102402647372,
            fromCurrencyId: 4414027,
            id: 1011121,
            indicator: "Sell",
            midConversionRate: 0.39,
            midRate: 0.39,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 1.9038,
            askRate: 1.9038,
            bidConversionRate: 1.8962,
            bidRate: 1.8962,
            channel: null,
            currencyPairId: 20057635504084292,
            fromCurrencyId: 4670032,
            id: 1011122,
            indicator: "Sell",
            midConversionRate: 1.9,
            midRate: 1.9,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4281668,
        },
        {
            askConversionRate: 1.4529,
            askRate: 1.4529,
            bidConversionRate: 1.4471,
            bidRate: 1.4471,
            channel: null,
            currencyPairId: 18944968392331084,
            fromCurrencyId: 4410969,
            id: 1011123,
            indicator: "Sell",
            midConversionRate: 1.45,
            midRate: 1.45,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 1.93386,
            askRate: 1.93386,
            bidConversionRate: 1.92614,
            bidRate: 1.92614,
            channel: null,
            currencyPairId: 18372032639225430,
            fromCurrencyId: 4277572,
            id: 1011124,
            indicator: "Sell",
            midConversionRate: 1.93,
            midRate: 1.93,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.24048,
            askRate: 0.24048,
            bidConversionRate: 0.23952,
            bidRate: 0.23952,
            channel: null,
            currencyPairId: 20917492251843148,
            fromCurrencyId: 4870233,
            id: 1011125,
            indicator: "Sell",
            midConversionRate: 0.24,
            midRate: 0.24,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 7.10418,
            askRate: 7.10418,
            bidConversionRate: 7.07582,
            bidRate: 7.07582,
            channel: null,
            currencyPairId: 18938289717529420,
            fromCurrencyId: 4409414,
            id: 1011126,
            indicator: "Sell",
            midConversionRate: 7.09,
            midRate: 7.09,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.096192,
            askRate: 0.096192,
            bidConversionRate: 0.095808,
            bidRate: 0.095808,
            channel: null,
            currencyPairId: 20917492252561228,
            fromCurrencyId: 4870233,
            id: 1011127,
            indicator: "Sell",
            midConversionRate: 0.096,
            midRate: 0.096,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 6.39276,
            askRate: 6.39276,
            bidConversionRate: 6.36724,
            bidRate: 6.36724,
            channel: null,
            currencyPairId: 18389624826316108,
            fromCurrencyId: 4281668,
            id: 1011129,
            indicator: "Sell",
            midConversionRate: 6.38,
            midRate: 6.38,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.96192,
            askRate: 0.96192,
            bidConversionRate: 0.95808,
            bidRate: 0.95808,
            channel: null,
            currencyPairId: 22042232518559052,
            fromCurrencyId: 5132107,
            id: 1011130,
            indicator: "Sell",
            midConversionRate: 0.96,
            midRate: 0.96,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.7014,
            askRate: 0.7014,
            bidConversionRate: 0.6986,
            bidRate: 0.6986,
            channel: null,
            currencyPairId: 18930584546134090,
            fromCurrencyId: 4407620,
            id: 1011131,
            indicator: "Sell",
            midConversionRate: 0.7,
            midRate: 0.7,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 109.72902,
            askRate: 109.72902,
            bidConversionRate: 109.29098,
            bidRate: 109.29098,
            channel: null,
            currencyPairId: 18938289717923930,
            fromCurrencyId: 4409414,
            id: 1011132,
            indicator: "Sell",
            midConversionRate: 109.51,
            midRate: 109.51,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 0.073146,
            askRate: 0.073146,
            bidConversionRate: 0.072854,
            bidRate: 0.072854,
            channel: null,
            currencyPairId: 20917492252166980,
            fromCurrencyId: 4870233,
            id: 1011133,
            indicator: "Sell",
            midConversionRate: 0.073,
            midRate: 0.073,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 6.87372,
            askRate: 6.87372,
            bidConversionRate: 6.84628,
            bidRate: 6.84628,
            channel: null,
            currencyPairId: 18372032639228492,
            fromCurrencyId: 4277572,
            id: 1011134,
            indicator: "Sell",
            midConversionRate: 6.86,
            midRate: 6.86,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 7.09416,
            askRate: 7.09416,
            bidConversionRate: 7.06584,
            bidRate: 7.06584,
            channel: null,
            currencyPairId: 24016925336227416,
            fromCurrencyId: 5591876,
            id: 1011135,
            indicator: "Sell",
            midConversionRate: 7.08,
            midRate: 7.08,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.91182,
            askRate: 0.91182,
            bidConversionRate: 0.90818,
            bidRate: 0.90818,
            channel: null,
            currencyPairId: 18389624825266500,
            fromCurrencyId: 4281668,
            id: 1011136,
            indicator: "Sell",
            midConversionRate: 0.91,
            midRate: 0.91,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 0.29058,
            askRate: 0.29058,
            bidConversionRate: 0.28942,
            bidRate: 0.28942,
            channel: null,
            currencyPairId: 21770666030418764,
            fromCurrencyId: 5068878,
            id: 1011137,
            indicator: "Sell",
            midConversionRate: 0.29,
            midRate: 0.29,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.271862,
            askRate: 3.678342,
            bidConversionRate: 0.272951,
            bidRate: 3.663658,
            channel: null,
            currencyPairId: 24016925336094020,
            fromCurrencyId: 5591876,
            id: 1011002,
            indicator: "Buy",
            midConversionRate: 0.272405,
            midRate: 3.671,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.135783,
            askRate: 7.3647,
            bidConversionRate: 0.136327,
            bidRate: 7.3353,
            channel: null,
            currencyPairId: 18930584546856780,
            fromCurrencyId: 4407620,
            id: 1011003,
            indicator: "Buy",
            midConversionRate: 0.136054,
            midRate: 7.35,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.103959,
            askRate: 9.6192,
            bidConversionRate: 0.104375,
            bidRate: 9.5808,
            channel: null,
            currencyPairId: 20057635504540484,
            fromCurrencyId: 4670032,
            id: 1011004,
            indicator: "Buy",
            midConversionRate: 0.104167,
            midRate: 9.6,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 0.144012,
            askRate: 6.94386,
            bidConversionRate: 0.144589,
            bidRate: 6.91614,
            channel: null,
            currencyPairId: 23440781244319052,
            fromCurrencyId: 5457732,
            id: 1011005,
            indicator: "Buy",
            midConversionRate: 0.144300,
            midRate: 6.93,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 1.028870,
            askRate: 0.97194,
            bidConversionRate: 1.032994,
            bidRate: 0.96806,
            channel: null,
            currencyPairId: 24016925336225864,
            fromCurrencyId: 5591876,
            id: 1011006,
            indicator: "Buy",
            midConversionRate: 1.030928,
            midRate: 0.97,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 0.417575,
            askRate: 2.39478,
            bidConversionRate: 0.419249,
            bidRate: 2.38522,
            channel: null,
            currencyPairId: 18389624825136452,
            fromCurrencyId: 4281668,
            id: 1011007,
            indicator: "Buy",
            midConversionRate: 0.418410,
            midRate: 2.39,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.130118,
            askRate: 7.68534,
            bidConversionRate: 0.130639,
            bidRate: 7.65466,
            channel: null,
            currencyPairId: 19515584861654616,
            fromCurrencyId: 4543826,
            id: 1011008,
            indicator: "Buy",
            midConversionRate: 0.130378,
            midRate: 7.67,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.950480,
            askRate: 1.0521,
            bidConversionRate: 0.954290,
            bidRate: 1.0479,
            channel: null,
            currencyPairId: 19515584861653064,
            fromCurrencyId: 4543826,
            id: 1011009,
            indicator: "Buy",
            midConversionRate: 0.952381,
            midRate: 1.05,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 0.804842,
            askRate: 1.24248,
            bidConversionRate: 0.808068,
            bidRate: 1.23752,
            channel: null,
            currencyPairId: 20057635505394500,
            fromCurrencyId: 4670032,
            id: 1011010,
            indicator: "Buy",
            midConversionRate: 0.806452,
            midRate: 1.24,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5591876,
        },
        {
            askConversionRate: 0.181786,
            askRate: 5.50098,
            bidConversionRate: 0.182514,
            bidRate: 5.47902,
            channel: null,
            currencyPairId: 23440781243599684,
            fromCurrencyId: 5457732,
            id: 1011011,
            indicator: "Buy",
            midConversionRate: 0.182149,
            midRate: 5.49,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 2.320940,
            askRate: 0.43086,
            bidConversionRate: 2.330242,
            bidRate: 0.42914,
            channel: null,
            currencyPairId: 21770666031075148,
            fromCurrencyId: 5068878,
            id: 1011012,
            indicator: "Buy",
            midConversionRate: 2.325581,
            midRate: 0.43,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.236494,
            askRate: 4.22844,
            bidConversionRate: 0.237442,
            bidRate: 4.21156,
            channel: null,
            currencyPairId: 22054297080712012,
            fromCurrencyId: 5134916,
            id: 1011013,
            indicator: "Buy",
            midConversionRate: 0.236967,
            midRate: 4.22,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.149402,
            askRate: 6.69336,
            bidConversionRate: 0.150001,
            bidRate: 6.66664,
            channel: null,
            currencyPairId: 18389624825990988,
            fromCurrencyId: 4281668,
            id: 1011014,
            indicator: "Buy",
            midConversionRate: 0.149701,
            midRate: 6.68,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.113797,
            askRate: 8.78754,
            bidConversionRate: 0.114254,
            bidRate: 8.75246,
            channel: null,
            currencyPairId: 20057635504213590,
            fromCurrencyId: 4670032,
            id: 1011015,
            indicator: "Buy",
            midConversionRate: 0.114025,
            midRate: 8.77,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.128775,
            askRate: 7.7655,
            bidConversionRate: 0.129291,
            bidRate: 7.7345,
            channel: null,
            currencyPairId: 24016925336554308,
            fromCurrencyId: 5591876,
            id: 1011016,
            indicator: "Buy",
            midConversionRate: 0.129032,
            midRate: 7.75,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 0.224776,
            askRate: 4.44888,
            bidConversionRate: 0.225677,
            bidRate: 4.43112,
            channel: null,
            currencyPairId: 21770666030813270,
            fromCurrencyId: 5068878,
            id: 1011017,
            indicator: "Buy",
            midConversionRate: 0.225225,
            midRate: 4.44,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 1.691532,
            askRate: 0.59118,
            bidConversionRate: 1.698312,
            bidRate: 0.58882,
            channel: null,
            currencyPairId: 22054297080645704,
            fromCurrencyId: 5134916,
            id: 1011018,
            indicator: "Buy",
            midConversionRate: 1.694915,
            midRate: 0.59,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 0.060743,
            askRate: 16.46286,
            bidConversionRate: 0.060986,
            bidRate: 16.39714,
            channel: null,
            currencyPairId: 18389624825272908,
            fromCurrencyId: 4281668,
            id: 1011019,
            indicator: "Buy",
            midConversionRate: 0.060864,
            midRate: 16.43,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.601207,
            askRate: 1.66332,
            bidConversionRate: 0.603617,
            bidRate: 1.65668,
            channel: null,
            currencyPairId: 19515584861525316,
            fromCurrencyId: 4543826,
            id: 1011020,
            indicator: "Buy",
            midConversionRate: 0.602410,
            midRate: 1.66,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4281668,
        },
        {
            askConversionRate: 0.563844,
            askRate: 1.77354,
            bidConversionRate: 0.566104,
            bidRate: 1.76646,
            channel: null,
            currencyPairId: 19515584862378564,
            fromCurrencyId: 4543826,
            id: 1011021,
            indicator: "Buy",
            midConversionRate: 0.564972,
            midRate: 1.77,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5134916,
        },
        {
            askConversionRate: 0.222273,
            askRate: 4.49898,
            bidConversionRate: 0.223163,
            bidRate: 4.48102,
            channel: null,
            currencyPairId: 18389624825334604,
            fromCurrencyId: 4281668,
            id: 1011022,
            indicator: "Buy",
            midConversionRate: 0.222717,
            midRate: 4.49,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 1.535391,
            askRate: 0.6513,
            bidConversionRate: 1.541545,
            bidRate: 0.6487,
            channel: null,
            currencyPairId: 18389624826450756,
            fromCurrencyId: 4281668,
            id: 1011023,
            indicator: "Buy",
            midConversionRate: 1.538462,
            midRate: 0.65,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5591876,
        },
        {
            askConversionRate: 28.514400,
            askRate: 0.03507,
            bidConversionRate: 28.628686,
            bidRate: 0.03493,
            channel: null,
            currencyPairId: 20917492251706692,
            fromCurrencyId: 4870233,
            id: 1011024,
            indicator: "Buy",
            midConversionRate: 28.571429,
            midRate: 0.035,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.137088,
            askRate: 7.29456,
            bidConversionRate: 0.137638,
            bidRate: 7.26544,
            channel: null,
            currencyPairId: 18938289717464664,
            fromCurrencyId: 4409414,
            id: 1011025,
            indicator: "Buy",
            midConversionRate: 0.137363,
            midRate: 7.28,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.307078,
            askRate: 3.2565,
            bidConversionRate: 0.308309,
            bidRate: 3.2435,
            channel: null,
            currencyPairId: 20348954545969740,
            fromCurrencyId: 4737860,
            id: 1011026,
            indicator: "Buy",
            midConversionRate: 0.307692,
            midRate: 3.25,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.831670,
            askRate: 1.2024,
            bidConversionRate: 0.835003,
            bidRate: 1.1976,
            channel: null,
            currencyPairId: 20057635504212040,
            fromCurrencyId: 4670032,
            id: 1011027,
            indicator: "Buy",
            midConversionRate: 0.833333,
            midRate: 1.2,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 0.058125,
            askRate: 17.20434,
            bidConversionRate: 0.058358,
            bidRate: 17.13566,
            channel: null,
            currencyPairId: 18930584546793550,
            fromCurrencyId: 4407620,
            id: 1011028,
            indicator: "Buy",
            midConversionRate: 0.058241,
            midRate: 17.17,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.576881,
            askRate: 1.73346,
            bidConversionRate: 0.579193,
            bidRate: 1.72654,
            channel: null,
            currencyPairId: 20057635504210244,
            fromCurrencyId: 4670032,
            id: 1011029,
            indicator: "Buy",
            midConversionRate: 0.578035,
            midRate: 1.73,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 0.055911,
            askRate: 17.8857,
            bidConversionRate: 0.056135,
            bidRate: 17.8143,
            channel: null,
            currencyPairId: 23440781243275852,
            fromCurrencyId: 5457732,
            id: 1011030,
            indicator: "Buy",
            midConversionRate: 0.056022,
            midRate: 17.85,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.009387,
            askRate: 106.53264,
            bidConversionRate: 0.009424,
            bidRate: 106.10736,
            channel: null,
            currencyPairId: 24016925336686680,
            fromCurrencyId: 5591876,
            id: 1011031,
            indicator: "Buy",
            midConversionRate: 0.009406,
            midRate: 106.32,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 0.040520,
            askRate: 24.67926,
            bidConversionRate: 0.040682,
            bidRate: 24.58074,
            channel: null,
            currencyPairId: 18938289718122576,
            fromCurrencyId: 4409414,
            id: 1011032,
            indicator: "Buy",
            midConversionRate: 0.040601,
            midRate: 24.63,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.197625,
            askRate: 5.0601,
            bidConversionRate: 0.198417,
            bidRate: 5.0399,
            channel: null,
            currencyPairId: 18389624825596740,
            fromCurrencyId: 4281668,
            id: 1011033,
            indicator: "Buy",
            midConversionRate: 0.198020,
            midRate: 5.05,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 2.434156,
            askRate: 0.41082,
            bidConversionRate: 2.443912,
            bidRate: 0.40918,
            channel: null,
            currencyPairId: 18958102402322252,
            fromCurrencyId: 4414027,
            id: 1011034,
            indicator: "Buy",
            midConversionRate: 2.439024,
            midRate: 0.41,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 1.584133,
            askRate: 0.63126,
            bidConversionRate: 1.590483,
            bidRate: 0.62874,
            channel: null,
            currencyPairId: 18389624825268296,
            fromCurrencyId: 4281668,
            id: 1011035,
            indicator: "Buy",
            midConversionRate: 1.587302,
            midRate: 0.63,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 0.064638,
            askRate: 15.47088,
            bidConversionRate: 0.064897,
            bidRate: 15.40912,
            channel: null,
            currencyPairId: 22054297080650316,
            fromCurrencyId: 5134916,
            id: 1011037,
            indicator: "Buy",
            midConversionRate: 0.064767,
            midRate: 15.44,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.210106,
            askRate: 4.7595,
            bidConversionRate: 0.210948,
            bidRate: 4.7405,
            channel: null,
            currencyPairId: 22054297080974148,
            fromCurrencyId: 5134916,
            id: 1011038,
            indicator: "Buy",
            midConversionRate: 0.210526,
            midRate: 4.75,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 0.118810,
            askRate: 8.4168,
            bidConversionRate: 0.119286,
            bidRate: 8.3832,
            channel: null,
            currencyPairId: 19515584861981508,
            fromCurrencyId: 4543826,
            id: 1011039,
            indicator: "Buy",
            midConversionRate: 0.119048,
            midRate: 8.4,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 1.147131,
            askRate: 0.87174,
            bidConversionRate: 1.151729,
            bidRate: 0.86826,
            channel: null,
            currencyPairId: 22054297081694020,
            fromCurrencyId: 5134916,
            id: 1011040,
            indicator: "Buy",
            midConversionRate: 1.149425,
            midRate: 0.87,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 0.204929,
            askRate: 4.87974,
            bidConversionRate: 0.205750,
            bidRate: 4.86026,
            channel: null,
            currencyPairId: 23440781243337548,
            fromCurrencyId: 5457732,
            id: 1011041,
            indicator: "Buy",
            midConversionRate: 0.205339,
            midRate: 4.87,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.159171,
            askRate: 6.28254,
            bidConversionRate: 0.159809,
            bidRate: 6.25746,
            channel: null,
            currencyPairId: 22054297081368396,
            fromCurrencyId: 5134916,
            id: 1011042,
            indicator: "Buy",
            midConversionRate: 0.159490,
            midRate: 6.27,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.144848,
            askRate: 6.90378,
            bidConversionRate: 0.145429,
            bidRate: 6.87622,
            channel: null,
            currencyPairId: 24016925336292172,
            fromCurrencyId: 5591876,
            id: 1011043,
            indicator: "Buy",
            midConversionRate: 0.145138,
            midRate: 6.89,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.038459,
            askRate: 26.0019,
            bidConversionRate: 0.038613,
            bidRate: 25.8981,
            channel: null,
            currencyPairId: 18938289717467724,
            fromCurrencyId: 4409414,
            id: 1011044,
            indicator: "Buy",
            midConversionRate: 0.038536,
            midRate: 25.95,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.036544,
            askRate: 27.36462,
            bidConversionRate: 0.036690,
            bidRate: 27.25538,
            channel: null,
            currencyPairId: 19515584861657676,
            fromCurrencyId: 4543826,
            id: 1011045,
            indicator: "Buy",
            midConversionRate: 0.036617,
            midRate: 27.31,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.533692,
            askRate: 1.87374,
            bidConversionRate: 0.535831,
            bidRate: 1.86626,
            channel: null,
            currencyPairId: 18372032639290188,
            fromCurrencyId: 4277572,
            id: 1011046,
            indicator: "Buy",
            midConversionRate: 0.534759,
            midRate: 1.87,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.101941,
            askRate: 9.80958,
            bidConversionRate: 0.102350,
            bidRate: 9.77042,
            channel: null,
            currencyPairId: 24016925337273676,
            fromCurrencyId: 5591876,
            id: 1011047,
            indicator: "Buy",
            midConversionRate: 0.102145,
            midRate: 9.79,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.357708,
            askRate: 2.79558,
            bidConversionRate: 0.359141,
            bidRate: 2.78442,
            channel: null,
            currencyPairId: 18372032639946572,
            fromCurrencyId: 4277572,
            id: 1011048,
            indicator: "Buy",
            midConversionRate: 0.358423,
            midRate: 2.79,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 1.636072,
            askRate: 0.61122,
            bidConversionRate: 1.642630,
            bidRate: 0.60878,
            channel: null,
            currencyPairId: 22054297081828164,
            fromCurrencyId: 5134916,
            id: 1011049,
            indicator: "Buy",
            midConversionRate: 1.639344,
            midRate: 0.61,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5591876,
        },
        {
            askConversionRate: 0.652290,
            askRate: 1.53306,
            bidConversionRate: 0.654905,
            bidRate: 1.52694,
            channel: null,
            currencyPairId: 19515584862701380,
            fromCurrencyId: 4543826,
            id: 1011050,
            indicator: "Buy",
            midConversionRate: 0.653595,
            midRate: 1.53,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 0.443557,
            askRate: 2.2545,
            bidConversionRate: 0.445335,
            bidRate: 2.2455,
            channel: null,
            currencyPairId: 22054297080513860,
            fromCurrencyId: 5134916,
            id: 1011051,
            indicator: "Buy",
            midConversionRate: 0.444444,
            midRate: 2.25,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 1.096708,
            askRate: 0.91182,
            bidConversionRate: 1.101103,
            bidRate: 0.90818,
            channel: null,
            currencyPairId: 20348954545966680,
            fromCurrencyId: 4737860,
            id: 1011052,
            indicator: "Buy",
            midConversionRate: 1.098901,
            midRate: 0.91,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.179497,
            askRate: 5.57112,
            bidConversionRate: 0.180217,
            bidRate: 5.54888,
            channel: null,
            currencyPairId: 18930584546462532,
            fromCurrencyId: 4407620,
            id: 1011053,
            indicator: "Buy",
            midConversionRate: 0.179856,
            midRate: 5.56,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 1.028870,
            askRate: 0.97194,
            bidConversionRate: 1.032994,
            bidRate: 0.96806,
            channel: null,
            currencyPairId: 18944968391674700,
            fromCurrencyId: 4410969,
            id: 1011054,
            indicator: "Buy",
            midConversionRate: 1.030928,
            midRate: 0.97,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.219342,
            askRate: 4.5591,
            bidConversionRate: 0.220221,
            bidRate: 4.5409,
            channel: null,
            currencyPairId: 20057635504080196,
            fromCurrencyId: 4670032,
            id: 1011055,
            indicator: "Buy",
            midConversionRate: 0.219780,
            midRate: 4.55,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.041653,
            askRate: 24.00792,
            bidConversionRate: 0.041820,
            bidRate: 23.91208,
            channel: null,
            currencyPairId: 24016925336885330,
            fromCurrencyId: 5591876,
            id: 1011056,
            indicator: "Buy",
            midConversionRate: 0.041736,
            midRate: 23.96,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.383848,
            askRate: 2.6052,
            bidConversionRate: 0.385386,
            bidRate: 2.5948,
            channel: null,
            currencyPairId: 23440781243139396,
            fromCurrencyId: 5457732,
            id: 1011057,
            indicator: "Buy",
            midConversionRate: 0.384615,
            midRate: 2.6,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.373784,
            askRate: 2.67534,
            bidConversionRate: 0.375282,
            bidRate: 2.66466,
            channel: null,
            currencyPairId: 18372032640271692,
            fromCurrencyId: 4277572,
            id: 1011058,
            indicator: "Buy",
            midConversionRate: 0.374532,
            midRate: 2.67,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 3.696311,
            askRate: 0.27054,
            bidConversionRate: 3.711126,
            bidRate: 0.26946,
            channel: null,
            currencyPairId: 18958102401665868,
            fromCurrencyId: 4414027,
            id: 1011059,
            indicator: "Buy",
            midConversionRate: 3.703704,
            midRate: 0.27,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.295267,
            askRate: 3.38676,
            bidConversionRate: 0.296451,
            bidRate: 3.37324,
            channel: null,
            currencyPairId: 18944968392267856,
            fromCurrencyId: 4410969,
            id: 1011060,
            indicator: "Buy",
            midConversionRate: 0.295858,
            midRate: 3.38,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.038444,
            askRate: 26.01192,
            bidConversionRate: 0.038598,
            bidRate: 25.90808,
            channel: null,
            currencyPairId: 19515584862312530,
            fromCurrencyId: 4543826,
            id: 1011061,
            indicator: "Buy",
            midConversionRate: 0.038521,
            midRate: 25.96,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.094063,
            askRate: 10.63122,
            bidConversionRate: 0.094440,
            bidRate: 10.58878,
            channel: null,
            currencyPairId: 19515584862700876,
            fromCurrencyId: 4543826,
            id: 1011062,
            indicator: "Buy",
            midConversionRate: 0.094251,
            midRate: 10.61,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.322979,
            askRate: 3.09618,
            bidConversionRate: 0.324273,
            bidRate: 3.08382,
            channel: null,
            currencyPairId: 20348954546624590,
            fromCurrencyId: 4737860,
            id: 1011063,
            indicator: "Buy",
            midConversionRate: 0.323625,
            midRate: 3.09,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.142166,
            askRate: 7.03404,
            bidConversionRate: 0.142736,
            bidRate: 7.00596,
            channel: null,
            currencyPairId: 18930584547181900,
            fromCurrencyId: 4407620,
            id: 1011064,
            indicator: "Buy",
            midConversionRate: 0.142450,
            midRate: 7.02,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.007574,
            askRate: 132.02352,
            bidConversionRate: 0.007605,
            bidRate: 131.49648,
            channel: null,
            currencyPairId: 20057635504672856,
            fromCurrencyId: 4670032,
            id: 1011065,
            indicator: "Buy",
            midConversionRate: 0.007590,
            midRate: 131.76,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 0.015335,
            askRate: 65.21016,
            bidConversionRate: 0.015396,
            bidRate: 64.94984,
            channel: null,
            currencyPairId: 22054297081106520,
            fromCurrencyId: 5134916,
            id: 1011066,
            indicator: "Buy",
            midConversionRate: 0.015366,
            midRate: 65.08,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 0.033625,
            askRate: 29.73936,
            bidConversionRate: 0.033760,
            bidRate: 29.62064,
            channel: null,
            currencyPairId: 20057635504871504,
            fromCurrencyId: 4670032,
            id: 1011067,
            indicator: "Buy",
            midConversionRate: 0.033693,
            midRate: 29.68,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.688279,
            askRate: 1.4529,
            bidConversionRate: 0.691037,
            bidRate: 1.4471,
            channel: null,
            currencyPairId: 18938289718511428,
            fromCurrencyId: 4409414,
            id: 1011068,
            indicator: "Buy",
            midConversionRate: 0.689655,
            midRate: 1.45,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 0.089910,
            askRate: 11.1222,
            bidConversionRate: 0.090271,
            bidRate: 11.0778,
            channel: null,
            currencyPairId: 19515584862375756,
            fromCurrencyId: 4543826,
            id: 1011069,
            indicator: "Buy",
            midConversionRate: 0.090090,
            midRate: 11.1,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.494061,
            askRate: 2.02404,
            bidConversionRate: 0.496042,
            bidRate: 2.01596,
            channel: null,
            currencyPairId: 20057635504937540,
            fromCurrencyId: 4670032,
            id: 1011070,
            indicator: "Buy",
            midConversionRate: 0.495050,
            midRate: 2.02,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5134916,
        },
        {
            askConversionRate: 0.196457,
            askRate: 5.09016,
            bidConversionRate: 0.197245,
            bidRate: 5.06984,
            channel: null,
            currencyPairId: 18930584546135640,
            fromCurrencyId: 4407620,
            id: 1011071,
            indicator: "Buy",
            midConversionRate: 0.196850,
            midRate: 5.08,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.202025,
            askRate: 4.94988,
            bidConversionRate: 0.202835,
            bidRate: 4.93012,
            channel: null,
            currencyPairId: 18930584546200396,
            fromCurrencyId: 4407620,
            id: 1011072,
            indicator: "Buy",
            midConversionRate: 0.202429,
            midRate: 4.94,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.099107,
            askRate: 10.09014,
            bidConversionRate: 0.099504,
            bidRate: 10.04986,
            channel: null,
            currencyPairId: 18938289718510924,
            fromCurrencyId: 4409414,
            id: 1011073,
            indicator: "Buy",
            midConversionRate: 0.099305,
            midRate: 10.07,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.264022,
            askRate: 3.78756,
            bidConversionRate: 0.265080,
            bidRate: 3.77244,
            channel: null,
            currencyPairId: 18938289717331268,
            fromCurrencyId: 4409414,
            id: 1011074,
            indicator: "Buy",
            midConversionRate: 0.264550,
            midRate: 3.78,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.166612,
            askRate: 6.00198,
            bidConversionRate: 0.167279,
            bidRate: 5.97802,
            channel: null,
            currencyPairId: 22054297081693516,
            fromCurrencyId: 5134916,
            id: 1011075,
            indicator: "Buy",
            midConversionRate: 0.166945,
            midRate: 5.99,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.199202,
            askRate: 5.02002,
            bidConversionRate: 0.200001,
            bidRate: 4.99998,
            channel: null,
            currencyPairId: 23440781243272790,
            fromCurrencyId: 5457732,
            id: 1011076,
            indicator: "Buy",
            midConversionRate: 0.199601,
            midRate: 5.01,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.924078,
            askRate: 1.08216,
            bidConversionRate: 0.927781,
            bidRate: 1.07784,
            channel: null,
            currencyPairId: 19515584862835524,
            fromCurrencyId: 4543826,
            id: 1011077,
            indicator: "Buy",
            midConversionRate: 0.925926,
            midRate: 1.08,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5591876,
        },
        {
            askConversionRate: 1.121353,
            askRate: 0.89178,
            bidConversionRate: 1.125847,
            bidRate: 0.88822,
            channel: null,
            currencyPairId: 20348954546031436,
            fromCurrencyId: 4737860,
            id: 1011078,
            indicator: "Buy",
            midConversionRate: 1.123596,
            midRate: 0.89,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.013089,
            askRate: 76.4025,
            bidConversionRate: 0.013141,
            bidRate: 76.0975,
            channel: null,
            currencyPairId: 18930584546594904,
            fromCurrencyId: 4407620,
            id: 1011079,
            indicator: "Buy",
            midConversionRate: 0.013115,
            midRate: 76.25,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 0.230486,
            askRate: 4.33866,
            bidConversionRate: 0.231410,
            bidRate: 4.32134,
            channel: null,
            currencyPairId: 22054297080647256,
            fromCurrencyId: 5134916,
            id: 1011080,
            indicator: "Buy",
            midConversionRate: 0.230947,
            midRate: 4.33,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.941513,
            askRate: 1.06212,
            bidConversionRate: 0.945287,
            bidRate: 1.05788,
            channel: null,
            currencyPairId: 18389624825993796,
            fromCurrencyId: 4281668,
            id: 1011081,
            indicator: "Buy",
            midConversionRate: 0.943396,
            midRate: 1.06,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5134916,
        },
        {
            askConversionRate: 0.082276,
            askRate: 12.15426,
            bidConversionRate: 0.082605,
            bidRate: 12.10574,
            channel: null,
            currencyPairId: 20057635505259852,
            fromCurrencyId: 4670032,
            id: 1011082,
            indicator: "Buy",
            midConversionRate: 0.082440,
            midRate: 12.13,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.660930,
            askRate: 1.51302,
            bidConversionRate: 0.663579,
            bidRate: 1.50698,
            channel: null,
            currencyPairId: 19515584861651268,
            fromCurrencyId: 4543826,
            id: 1011083,
            indicator: "Buy",
            midConversionRate: 0.662252,
            midRate: 1.51,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 0.153068,
            askRate: 6.53304,
            bidConversionRate: 0.153682,
            bidRate: 6.50696,
            channel: null,
            currencyPairId: 18372032639883344,
            fromCurrencyId: 4277572,
            id: 1011084,
            indicator: "Buy",
            midConversionRate: 0.153374,
            midRate: 6.52,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 1.147131,
            askRate: 0.87174,
            bidConversionRate: 1.151729,
            bidRate: 0.86826,
            channel: null,
            currencyPairId: 19515584861913680,
            fromCurrencyId: 4543826,
            id: 1011085,
            indicator: "Buy",
            midConversionRate: 1.149425,
            midRate: 0.87,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4670032,
        },
        {
            askConversionRate: 0.125063,
            askRate: 7.99596,
            bidConversionRate: 0.125564,
            bidRate: 7.96404,
            channel: null,
            currencyPairId: 18938289717791556,
            fromCurrencyId: 4409414,
            id: 1011086,
            indicator: "Buy",
            midConversionRate: 0.125313,
            midRate: 7.98,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 0.094687,
            askRate: 10.56108,
            bidConversionRate: 0.095067,
            bidRate: 10.51892,
            channel: null,
            currencyPairId: 18938289718185804,
            fromCurrencyId: 4409414,
            id: 1011087,
            indicator: "Buy",
            midConversionRate: 0.094877,
            midRate: 10.54,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.379469,
            askRate: 2.63526,
            bidConversionRate: 0.380990,
            bidRate: 2.62474,
            channel: null,
            currencyPairId: 18930584546002244,
            fromCurrencyId: 4407620,
            id: 1011088,
            indicator: "Buy",
            midConversionRate: 0.380228,
            midRate: 2.63,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 0.216487,
            askRate: 4.61922,
            bidConversionRate: 0.217354,
            bidRate: 4.60078,
            channel: null,
            currencyPairId: 18389624825269850,
            fromCurrencyId: 4281668,
            id: 1011089,
            indicator: "Buy",
            midConversionRate: 0.216920,
            midRate: 4.61,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.570288,
            askRate: 1.7535,
            bidConversionRate: 0.572574,
            bidRate: 1.7465,
            channel: null,
            currencyPairId: 20057635505260356,
            fromCurrencyId: 4670032,
            id: 1011090,
            indicator: "Buy",
            midConversionRate: 0.571429,
            midRate: 1.75,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 0.097461,
            askRate: 10.26048,
            bidConversionRate: 0.097852,
            bidRate: 10.21952,
            channel: null,
            currencyPairId: 24016925336948556,
            fromCurrencyId: 5591876,
            id: 1011091,
            indicator: "Buy",
            midConversionRate: 0.097656,
            midRate: 10.24,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.068030,
            askRate: 14.69934,
            bidConversionRate: 0.068303,
            bidRate: 14.64066,
            channel: null,
            currencyPairId: 22054297081305170,
            fromCurrencyId: 5134916,
            id: 1011092,
            indicator: "Buy",
            midConversionRate: 0.068166,
            midRate: 14.67,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 0.756064,
            askRate: 1.32264,
            bidConversionRate: 0.759094,
            bidRate: 1.31736,
            channel: null,
            currencyPairId: 20348954546687820,
            fromCurrencyId: 4737860,
            id: 1011093,
            indicator: "Buy",
            midConversionRate: 0.757576,
            midRate: 1.32,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.250755,
            askRate: 3.98796,
            bidConversionRate: 0.251760,
            bidRate: 3.97204,
            channel: null,
            currencyPairId: 19515584861521220,
            fromCurrencyId: 4543826,
            id: 1011094,
            indicator: "Buy",
            midConversionRate: 0.251256,
            midRate: 3.98,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 14.895582,
            askRate: 0.067134,
            bidConversionRate: 14.955284,
            bidRate: 0.066866,
            channel: null,
            currencyPairId: 20917492251840090,
            fromCurrencyId: 4870233,
            id: 1011095,
            indicator: "Buy",
            midConversionRate: 14.925373,
            midRate: 0.067,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 0.280338,
            askRate: 3.56712,
            bidConversionRate: 0.281462,
            bidRate: 3.55288,
            channel: null,
            currencyPairId: 18944968391613004,
            fromCurrencyId: 4410969,
            id: 1011096,
            indicator: "Buy",
            midConversionRate: 0.280899,
            midRate: 3.56,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.950480,
            askRate: 1.0521,
            bidConversionRate: 0.954290,
            bidRate: 1.0479,
            channel: null,
            currencyPairId: 21770666030357068,
            fromCurrencyId: 5068878,
            id: 1011097,
            indicator: "Buy",
            midConversionRate: 0.952381,
            midRate: 1.05,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.014393,
            askRate: 69.47868,
            bidConversionRate: 0.014451,
            bidRate: 69.20132,
            channel: null,
            currencyPairId: 18389624825729110,
            fromCurrencyId: 4281668,
            id: 1011098,
            indicator: "Buy",
            midConversionRate: 0.014422,
            midRate: 69.34,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 0.702820,
            askRate: 1.42284,
            bidConversionRate: 0.705637,
            bidRate: 1.41716,
            channel: null,
            currencyPairId: 19223084704941388,
            fromCurrencyId: 4475723,
            id: 1011099,
            indicator: "Buy",
            midConversionRate: 0.704225,
            midRate: 1.42,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.031957,
            askRate: 31.29246,
            bidConversionRate: 0.032085,
            bidRate: 31.16754,
            channel: null,
            currencyPairId: 20057635504216652,
            fromCurrencyId: 4670032,
            id: 1011100,
            indicator: "Buy",
            midConversionRate: 0.032020,
            midRate: 31.23,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.988123,
            askRate: 1.01202,
            bidConversionRate: 0.992083,
            bidRate: 1.00798,
            channel: null,
            currencyPairId: 18930584547182404,
            fromCurrencyId: 4407620,
            id: 1011101,
            indicator: "Buy",
            midConversionRate: 0.990099,
            midRate: 1.01,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 0.723191,
            askRate: 1.38276,
            bidConversionRate: 0.726090,
            bidRate: 1.37724,
            channel: null,
            currencyPairId: 18944968392656204,
            fromCurrencyId: 4410969,
            id: 1011102,
            indicator: "Buy",
            midConversionRate: 0.724638,
            midRate: 1.38,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.008656,
            askRate: 115.5306,
            bidConversionRate: 0.008690,
            bidRate: 115.0694,
            channel: null,
            currencyPairId: 19515584862113880,
            fromCurrencyId: 4543826,
            id: 1011103,
            indicator: "Buy",
            midConversionRate: 0.008673,
            midRate: 115.3,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 0.792067,
            askRate: 1.26252,
            bidConversionRate: 0.795241,
            bidRate: 1.25748,
            channel: null,
            currencyPairId: 20348954547012940,
            fromCurrencyId: 4737860,
            id: 1011104,
            indicator: "Buy",
            midConversionRate: 0.793651,
            midRate: 1.26,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.039603,
            askRate: 25.2504,
            bidConversionRate: 0.039762,
            bidRate: 25.1496,
            channel: null,
            currencyPairId: 24016925336230476,
            fromCurrencyId: 5591876,
            id: 1011105,
            indicator: "Buy",
            midConversionRate: 0.039683,
            midRate: 25.2,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.055230,
            askRate: 18.10614,
            bidConversionRate: 0.055451,
            bidRate: 18.03386,
            channel: null,
            currencyPairId: 18930584546138700,
            fromCurrencyId: 4407620,
            id: 1011106,
            indicator: "Buy",
            midConversionRate: 0.055340,
            midRate: 18.07,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.116999,
            askRate: 8.54706,
            bidConversionRate: 0.117468,
            bidRate: 8.51294,
            channel: null,
            currencyPairId: 20057635504278348,
            fromCurrencyId: 4670032,
            id: 1011107,
            indicator: "Buy",
            midConversionRate: 0.117233,
            midRate: 8.53,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 0.078645,
            askRate: 12.71538,
            bidConversionRate: 0.078960,
            bidRate: 12.66462,
            channel: null,
            currencyPairId: 20057635504934732,
            fromCurrencyId: 4670032,
            id: 1011108,
            indicator: "Buy",
            midConversionRate: 0.078802,
            midRate: 12.69,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 76.769538,
            askRate: 0.013026,
            bidConversionRate: 77.077231,
            bidRate: 0.012974,
            channel: null,
            currencyPairId: 20917492252886852,
            fromCurrencyId: 4870233,
            id: 1011109,
            indicator: "Buy",
            midConversionRate: 76.923077,
            midRate: 0.013,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 2.123413,
            askRate: 0.47094,
            bidConversionRate: 2.131923,
            bidRate: 0.46906,
            channel: null,
            currencyPairId: 20348954545833284,
            fromCurrencyId: 4737860,
            id: 1011110,
            indicator: "Buy",
            midConversionRate: 2.127660,
            midRate: 0.47,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4277572,
        },
        {
            askConversionRate: 1.489558,
            askRate: 0.67134,
            bidConversionRate: 1.495528,
            bidRate: 0.66866,
            channel: null,
            currencyPairId: 22042232517577548,
            fromCurrencyId: 5132107,
            id: 1011111,
            indicator: "Buy",
            midConversionRate: 1.492537,
            midRate: 0.67,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 1.174122,
            askRate: 0.8517,
            bidConversionRate: 1.178828,
            bidRate: 0.8483,
            channel: null,
            currencyPairId: 22054297080643908,
            fromCurrencyId: 5134916,
            id: 1011112,
            indicator: "Buy",
            midConversionRate: 1.176471,
            midRate: 0.85,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 0.058879,
            askRate: 16.9839,
            bidConversionRate: 0.059115,
            bidRate: 16.9161,
            channel: null,
            currencyPairId: 23440781243930704,
            fromCurrencyId: 5457732,
            id: 1011113,
            indicator: "Buy",
            midConversionRate: 0.058997,
            midRate: 16.95,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5068878,
        },
        {
            askConversionRate: 1.084787,
            askRate: 0.92184,
            bidConversionRate: 1.089135,
            bidRate: 0.91816,
            channel: null,
            currencyPairId: 18389624826316612,
            fromCurrencyId: 4281668,
            id: 1011114,
            indicator: "Buy",
            midConversionRate: 1.086957,
            midRate: 0.92,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 0.137846,
            askRate: 7.25448,
            bidConversionRate: 0.138398,
            bidRate: 7.22552,
            channel: null,
            currencyPairId: 23440781243993932,
            fromCurrencyId: 5457732,
            id: 1011115,
            indicator: "Buy",
            midConversionRate: 0.138122,
            midRate: 7.24,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.717988,
            askRate: 1.39278,
            bidConversionRate: 0.720866,
            bidRate: 1.38722,
            channel: null,
            currencyPairId: 24016925336224068,
            fromCurrencyId: 5591876,
            id: 1011116,
            indicator: "Buy",
            midConversionRate: 0.719424,
            midRate: 1.39,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 10.847869,
            askRate: 0.092184,
            bidConversionRate: 10.891348,
            bidRate: 0.091816,
            channel: null,
            currencyPairId: 20917492252886348,
            fromCurrencyId: 4870233,
            id: 1011117,
            indicator: "Buy",
            midConversionRate: 10.869565,
            midRate: 0.092,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.707804,
            askRate: 1.41282,
            bidConversionRate: 0.710641,
            bidRate: 1.40718,
            channel: null,
            currencyPairId: 24016925337274180,
            fromCurrencyId: 5591876,
            id: 1011118,
            indicator: "Buy",
            midConversionRate: 0.709220,
            midRate: 1.41,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457732,
        },
        {
            askConversionRate: 0.133781,
            askRate: 7.47492,
            bidConversionRate: 0.134317,
            bidRate: 7.44508,
            channel: null,
            currencyPairId: 19515584861719372,
            fromCurrencyId: 4543826,
            id: 1011119,
            indicator: "Buy",
            midConversionRate: 0.134048,
            midRate: 7.46,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 15.353908,
            askRate: 0.06513,
            bidConversionRate: 15.415446,
            bidRate: 0.06487,
            channel: null,
            currencyPairId: 20917492251904844,
            fromCurrencyId: 4870233,
            id: 1011120,
            indicator: "Buy",
            midConversionRate: 15.384615,
            midRate: 0.065,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 2.558985,
            askRate: 0.39078,
            bidConversionRate: 2.569241,
            bidRate: 0.38922,
            channel: null,
            currencyPairId: 18958102402647372,
            fromCurrencyId: 4414027,
            id: 1011121,
            indicator: "Buy",
            midConversionRate: 2.564103,
            midRate: 0.39,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 0.525265,
            askRate: 1.9038,
            bidConversionRate: 0.527371,
            bidRate: 1.8962,
            channel: null,
            currencyPairId: 20057635504084292,
            fromCurrencyId: 4670032,
            id: 1011122,
            indicator: "Buy",
            midConversionRate: 0.526316,
            midRate: 1.9,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4281668,
        },
        {
            askConversionRate: 0.688279,
            askRate: 1.4529,
            bidConversionRate: 0.691037,
            bidRate: 1.4471,
            channel: null,
            currencyPairId: 18944968392331084,
            fromCurrencyId: 4410969,
            id: 1011123,
            indicator: "Buy",
            midConversionRate: 0.689655,
            midRate: 1.45,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.517101,
            askRate: 1.93386,
            bidConversionRate: 0.519173,
            bidRate: 1.92614,
            channel: null,
            currencyPairId: 18372032639225430,
            fromCurrencyId: 4277572,
            id: 1011124,
            indicator: "Buy",
            midConversionRate: 0.518135,
            midRate: 1.93,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 4.158350,
            askRate: 0.24048,
            bidConversionRate: 4.175017,
            bidRate: 0.23952,
            channel: null,
            currencyPairId: 20917492251843148,
            fromCurrencyId: 4870233,
            id: 1011125,
            indicator: "Buy",
            midConversionRate: 4.166667,
            midRate: 0.24,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.140762,
            askRate: 7.10418,
            bidConversionRate: 0.141326,
            bidRate: 7.07582,
            channel: null,
            currencyPairId: 18938289717529420,
            fromCurrencyId: 4409414,
            id: 1011126,
            indicator: "Buy",
            midConversionRate: 0.141044,
            midRate: 7.09,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        },
        {
            askConversionRate: 10.395875,
            askRate: 0.096192,
            bidConversionRate: 10.437542,
            bidRate: 0.095808,
            channel: null,
            currencyPairId: 20917492252561228,
            fromCurrencyId: 4870233,
            id: 1011127,
            indicator: "Buy",
            midConversionRate: 10.416667,
            midRate: 0.096,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5132107,
        },
        {
            askConversionRate: 0.156427,
            askRate: 6.39276,
            bidConversionRate: 0.157054,
            bidRate: 6.36724,
            channel: null,
            currencyPairId: 18389624826316108,
            fromCurrencyId: 4281668,
            id: 1011129,
            indicator: "Buy",
            midConversionRate: 0.156740,
            midRate: 6.38,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 1.039587,
            askRate: 0.96192,
            bidConversionRate: 1.043754,
            bidRate: 0.95808,
            channel: null,
            currencyPairId: 22042232518559052,
            fromCurrencyId: 5132107,
            id: 1011130,
            indicator: "Buy",
            midConversionRate: 1.041667,
            midRate: 0.96,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 5457227,
        },
        {
            askConversionRate: 1.425720,
            askRate: 0.7014,
            bidConversionRate: 1.431434,
            bidRate: 0.6986,
            channel: null,
            currencyPairId: 18930584546134090,
            fromCurrencyId: 4407620,
            id: 1011131,
            indicator: "Buy",
            midConversionRate: 1.428571,
            midRate: 0.7,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4409414,
        },
        {
            askConversionRate: 0.009113,
            askRate: 109.72902,
            bidConversionRate: 0.009150,
            bidRate: 109.29098,
            channel: null,
            currencyPairId: 18938289717923930,
            fromCurrencyId: 4409414,
            id: 1011132,
            indicator: "Buy",
            midConversionRate: 0.009132,
            midRate: 109.51,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4870233,
        },
        {
            askConversionRate: 13.671288,
            askRate: 0.073146,
            bidConversionRate: 13.726082,
            bidRate: 0.072854,
            channel: null,
            currencyPairId: 20917492252166980,
            fromCurrencyId: 4870233,
            id: 1011133,
            indicator: "Buy",
            midConversionRate: 13.698630,
            midRate: 0.073,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4737860,
        },
        {
            askConversionRate: 0.145482,
            askRate: 6.87372,
            bidConversionRate: 0.146065,
            bidRate: 6.84628,
            channel: null,
            currencyPairId: 18372032639228492,
            fromCurrencyId: 4277572,
            id: 1011134,
            indicator: "Buy",
            midConversionRate: 0.145773,
            midRate: 6.86,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4414027,
        },
        {
            askConversionRate: 0.140961,
            askRate: 7.09416,
            bidConversionRate: 0.141526,
            bidRate: 7.06584,
            channel: null,
            currencyPairId: 24016925336227416,
            fromCurrencyId: 5591876,
            id: 1011135,
            indicator: "Buy",
            midConversionRate: 0.141243,
            midRate: 7.08,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4410969,
        },
        {
            askConversionRate: 1.096708,
            askRate: 0.91182,
            bidConversionRate: 1.101103,
            bidRate: 0.90818,
            channel: null,
            currencyPairId: 18389624825266500,
            fromCurrencyId: 4281668,
            id: 1011136,
            indicator: "Buy",
            midConversionRate: 1.098901,
            midRate: 0.91,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4407620,
        },
        {
            askConversionRate: 3.441393,
            askRate: 0.29058,
            bidConversionRate: 3.455186,
            bidRate: 0.28942,
            channel: null,
            currencyPairId: 21770666030418764,
            fromCurrencyId: 5068878,
            id: 1011137,
            indicator: "Buy",
            midConversionRate: 3.448276,
            midRate: 0.29,
            source: "LuddesFX",
            time: "2020-05-11T14:15:55.000+0000",
            toCurrencyId: 4475723,
        }
    ],
    institutions: [
        {
            id: 34,
            name: "Test BAI",
            description: "BAI desc.",
            enabled: true,
            country: {
                country: "US",
                enabled: true,
                id: 21843,
                sortOrder: 1,
            }
        }, {
            id: 35,
            name: "Test BAI2",
            description: "BAI desc.",
            enabled: true,
            country: {
                country: "US",
                enabled: true,
                id: 21843,
                sortOrder: 1,
            },
        }
    ],
    noRunDates: [
        {
            id  : 23,
            date: "2020-06-23"
        }, {
            id  : 24,
            date: "2020-06-28"
        }
    ],
    auditRootModels: [
        {
            /*
                        id             : 1,
                        prototype      : 'se.nordicfc.refix.common.model.audit.CurrencyPairConfig',
                        apiVersion     : '4711',
                        enabled        : true,
                        default        : false,
                    }, {
            */
            id             : 2,
            prototype      : 'se.nordicfc.xpool.common.model.audit.Client',
            apiVersion     : '4711',
            enabled        : true,
            default        : true,
        }
    ],
    dataRightsCollections:[
        {
            "id": 4711,
            "basedOnDataRightsCollectionId": 123,
            "state": 'PENDING',
            "user": {
                "userId": 9,
                "firstName": "Test User Name",
                "lastName": "Test User Last Name",
                "socialSecurityNumber": "TestSecNum",
                "countryCode": "SD",
            },
            "assigned": [
                {
                    "id": 412,
                    "parentDataRightsCollectionId": 4711,
                    "childDataRightsCollection": {
                        "id": 4743,
                        "basedOnDataRightsCollectionId": 114,
                        "state": 'ACTIVE',
                        "user": {
                            "userId": 9,
                            "firstName": "Test User Name",
                            "lastName": "Test User Last Name",
                            "socialSecurityNumber": "TestSecNum",
                            "countryCode": "SD"
                        },
                        "assigned": [],
                        "assignable": [],
                        "parentEntityDataRightId": 412,
                        "disabledByUser": null,
                        "disabledDateTime": null,
                        "changedByUser": null,
                        "changedDateTime": null
                    },
                    "domainRights": {
                        "LegalEntityUnitIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": false
                            }
                        },
                        "BankAccountIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": false
                            }
                        },
                        "OperatingUnitIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": true,
                                "Delete": false,
                                "Update": false,
                                "Create": true
                            }
                        },
                        "BankAccountBalanceIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Create": false
                            }
                        },
                        "ClientIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": false,
                                "Update": true,
                                "Create": true
                            }
                        },
                        "LegalEntityIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": true
                            }
                        },
                        "OrderIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Delete": true,
                                "OnHold": false,
                                "Execute": false,
                                "Update": false
                            }
                        }
                    },
                    "instanceRights": {
                        "Read": true,
                        "Update": false,
                        "Fish": true
                    },
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.OperatingUnitIntegrationTestEntity",
                    "entity": {
                        "id": 3,
                        "name": 'Hej entity 3',
                        "description": 'fish description'
                    }
                },
                {
                    "id": 413,
                    "parentDataRightsCollectionId": 4711,
                    "childDataRightsCollection": {
                        "id": 4744,
                        "basedOnDataRightsCollectionId": 119,
                        "state": 'ACTIVE',
                        "user": {
                            "userId": 9,
                            "firstName": "Test User Name",
                            "lastName": "Test User Last Name",
                            "socialSecurityNumber": "TestSecNum",
                            "countryCode": "SD"
                        },
                        "assigned": [],
                        "assignable": [
                            {
                                "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.CLIENT",
                                "entity": {
                                    "id": 14,
                                    "name": 'Hej entity 14',
                                    "description": 'fish description'
                                }
                            }
                        ],
                        "parentEntityDataRightId": 412,
                        "disabledByUser": null,
                        "disabledDateTime": null,
                        "changedByUser": null,
                        "changedDateTime": null
                    },
                    "domainRights": {
                        "LegalEntityUnitIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": false
                            }
                        },
                        "BankAccountIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": false
                            }
                        },
                        "OperatingUnitIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": true,
                                "Delete": false,
                                "Update": false,
                                "Create": true
                            }
                        },
                        "BankAccountBalanceIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Create": false
                            }
                        },
                        "ClientIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": false,
                                "Update": true,
                                "Create": true
                            }
                        },
                        "LegalEntityIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": true
                            }
                        },
                        "OrderIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Delete": true,
                                "OnHold": false,
                                "Execute": false,
                                "Update": false
                            }
                        }
                    },
                    "instanceRights": {
                        "Read": true,
                        "Update": false,
                        "Fish": true
                    },
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.OperatingUnitIntegrationTestEntity",
                    "entity": {
                        "id": 4,
                        "name": 'Hej entity 4',
                        "description": 'fish description'
                    }
                }

            ],
            "assignable": [
                {
                    "parentDataRightsCollectionId": 4711,
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.OperatingUnitIntegrationTestEntity",
                    "entity": {
                        "id": 67,
                        "name": 'Test 67',
                        "description": 'adsasd'
                    }
                }, {
                    "parentDataRightsCollectionId": 4711,
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.OperatingUnitIntegrationTestEntity",
                    "entity": {
                        "id": 68,
                        "name": 'Test 68',
                        "description": 'dsflkjds slkj jj'
                    }
                }, {
                    "parentDataRightsCollectionId": 4711,
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.Brax",
                    "entity": {
                        "id": 38,
                        "name": 'Brax 38',
                        "description": 'fish 234'
                    }
                }, {
                    "parentDataRightsCollectionId": 4711,
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.Brax",
                    "entity": {
                        "id": 39,
                        "name": 'Brax 39',
                        "description": 'fish'
                    }
                }
            ],
            "parentEntityDataRightId": null,
            "disabledByUser": null,
            "disabledDateTime": null,
            "changedByUser": null,
            "changedDateTime": null
        },
        {
            "id": 4712,
            "basedOnDataRightsCollectionId": 123,
            "state": 'ACTIVE',
            "user": {
                "userId": 9,
                "firstName": "Test User Name",
                "lastName": "Test User Last Name",
                "socialSecurityNumber": "TestSecNum",
                "countryCode": "SD",
            },
            "assigned": [
                {
                    "id": 512,
                    "parentDataRightsCollectionId": 4712,
                    "childDataRightsCollection": {
                        "id": 4743,
                        "basedOnDataRightsCollectionId": 114,
                        "state": 'ACTIVE',
                        "user": {
                            "userId": 9,
                            "firstName": "Test User Name",
                            "lastName": "Test User Last Name",
                            "socialSecurityNumber": "TestSecNum",
                            "countryCode": "SD"
                        },
                        "assigned": [],
                        "assignable": [],
                        "parentEntityDataRightId": 412,
                        "disabledByUser": null,
                        "disabledDateTime": null,
                        "changedByUser": null,
                        "changedDateTime": null
                    },
                    "domainRights": {
                        "LegalEntityUnitIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": false
                            }
                        },
                        "BankAccountIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": false
                            }
                        },
                        "OperatingUnitIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": true,
                                "Delete": false,
                                "Update": false,
                                "Create": true
                            }
                        },
                        "BankAccountBalanceIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Create": false
                            }
                        },
                        "ClientIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": false,
                                "Update": true,
                                "Create": true
                            }
                        },
                        "LegalEntityIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": true
                            }
                        },
                        "OrderIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Delete": true,
                                "OnHold": false,
                                "Execute": false,
                                "Update": false
                            }
                        }
                    },
                    "instanceRights": {
                        "Read": true,
                        "Update": false,
                        "Fish": false
                    },
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.OperatingUnitIntegrationTestEntity",
                    "entity": {
                        "id": 3,
                        "name": 'Hej entity 3',
                        "description": 'fish description'
                    }
                },
                {
                    "id": 513,
                    "parentDataRightsCollectionId": 4712,
                    "childDataRightsCollection": {
                        "id": 4744,
                        "basedOnDataRightsCollectionId": 119,
                        "state": 'ACTIVE',
                        "user": {
                            "userId": 9,
                            "firstName": "Test User Name",
                            "lastName": "Test User Last Name",
                            "socialSecurityNumber": "TestSecNum",
                            "countryCode": "SD"
                        },
                        "assigned": [],
                        "assignable": [
                            {
                                "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.CLIENT",
                                "entity": {
                                    "id": 14,
                                    "name": 'Hej entity 14',
                                    "description": 'fish description'
                                }
                            }
                        ],
                        "parentEntityDataRightId": 412,
                        "disabledByUser": null,
                        "disabledDateTime": null,
                        "changedByUser": null,
                        "changedDateTime": null
                    },
                    "domainRights": {
                        "LegalEntityUnitIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": false
                            }
                        },
                        "BankAccountIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": false
                            }
                        },
                        "OperatingUnitIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": true,
                                "Delete": false,
                                "Update": false,
                                "Create": true
                            }
                        },
                        "BankAccountBalanceIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Create": false
                            }
                        },
                        "ClientIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": false,
                                "Update": true,
                                "Create": true
                            }
                        },
                        "LegalEntityIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Read": false,
                                "Delete": true,
                                "Update": false,
                                "Create": true
                            }
                        },
                        "OrderIntegrationTestEntity": {
                            "id": null,
                            "entityPrototypeRights": {
                                "Delete": true,
                                "OnHold": false,
                                "Execute": false,
                                "Update": false
                            }
                        }
                    },
                    "instanceRights": {
                        "Read": true,
                        "Update": false,
                        "Fish": true
                    },
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.OperatingUnitIntegrationTestEntity",
                    "entity": {
                        "id": 4,
                        "name": 'Hej entity 4',
                        "description": 'fish description'
                    }
                }

            ],
            "assignable": [
                {
                    "parentDataRightsCollectionId": 4711,
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.OperatingUnitIntegrationTestEntity",
                    "entity": {
                        "id": 67,
                        "name": 'Test 67',
                        "description": 'adsasd'
                    }
                }, {
                    "parentDataRightsCollectionId": 4711,
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.OperatingUnitIntegrationTestEntity",
                    "entity": {
                        "id": 68,
                        "name": 'Test 68',
                        "description": 'dsflkjds slkj jj'
                    }
                }, {
                    "parentDataRightsCollectionId": 4711,
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.Brax",
                    "entity": {
                        "id": 38,
                        "name": 'Brax 38',
                        "description": 'fish 234'
                    }
                }, {
                    "parentDataRightsCollectionId": 4711,
                    "entityPrototypeName": "se.nordicfc.common.authorisation.integration.sample.sub.Brax",
                    "entity": {
                        "id": 39,
                        "name": 'Brax 39',
                        "description": 'fish'
                    }
                }
            ],
            "parentEntityDataRightId": null,
            "disabledByUser": null,
            "disabledDateTime": null,
            "changedByUser": null,
            "changedDateTime": null
        }
    ],
    userSystemRights:[
        {
            "id": 4719,
            "user": {
                "userId": 9,
                "firstName": "Test User Name",
                "lastName": "Test User Last Name",
                "socialSecurityNumber": "TestSecNum",
                "countryCode": "SD",
            },
            "systemRights": {
                "NoRunDates": {
                    "id": null,
                    "systemPrototypeRights": {
                        "Read": false,
                        "Delete": true,
                        "Testa": false,
                        "Braxa": true
                    }
                },
                "BraxDates": {
                    "id": null,
                    "systemPrototypeRights": {
                        "Read": false,
                        "Delete": false,
                        "Update": true,
                        "Buffa": true
                    }
                },
                "BeaverFish": {
                    "id": null,
                    "systemPrototypeRights": {
                        "Read": false,
                        "Puffa": true,
                        "Update": false,
                        "Create": true
                    }
                }
            },
            "changedByUser": null,
            "changedDateTime": null
        }
    ]
};
