export const CURRENCY_DASHBOARD_SET_SELECTED_ACCOUNT = 'CURRENCY_DASHBOARD_SET_SELECTED_ACCOUNT';
export const CURRENCY_DASHBOARD_RELOAD_STATE         = "CURRENCY_DASHBOARD_RELOAD_STATE";
export const CURRENCY_DASHBOARD_RESET_STATE          = "CURRENCY_DASHBOARD_RESET_STATE";

// Action creators ********************************************************
// ************************************************************************
export function currencyDashboardSetSelectedAccount(account) {
    return {
        type: CURRENCY_DASHBOARD_SET_SELECTED_ACCOUNT,
        payload: {account: account}
    };
}

export function currencyDashboardReloadState(newState) {
    return {
        type : CURRENCY_DASHBOARD_RELOAD_STATE,
        payload: newState
    };
}
export function currencyDashboardResetState() {
    return {
        type : CURRENCY_DASHBOARD_RESET_STATE,
        payload: undefined
    };
}

// Reducer functions ********************************************************
// ************************************************************************

function setSelectedAccount(state, payload) {
    if (!payload?.account?.id) return state;
    if (state.selectedAccountId === payload.account.id) return {...state, selectedAccountId: undefined}
    return {...state, selectedAccountId: payload.account.id};
}

function reloadState (state, payload) {
    return payload;
}
function resetState (state, payload) {
    return getInitialState();
}

// Reducer action handlers map ************************************************
// ************************************************************************
const actionHandler = {
    [CURRENCY_DASHBOARD_SET_SELECTED_ACCOUNT] : setSelectedAccount,
    [CURRENCY_DASHBOARD_RELOAD_STATE]         : reloadState,
    [CURRENCY_DASHBOARD_RESET_STATE]          : resetState,
};

const getInitialState = () => ({
    selectedAccountId   : undefined,
});

export function currencyDashboardReducer(state = getInitialState(), action) {
    const handler = actionHandler[action.type];
    return handler ? handler(state, action.payload) : state;
}
