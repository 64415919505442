import React, {useMemo} from 'react'
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {executeSuggestedOrders} from 'features/cashPool/executeOrdersAPI.js'
import {
    userSelector
} from "gui-common/orm/ormSelectors";
import RequestReloadBalancesButton from "features/requestReloadBalance/RequestReloadBalanceButton"
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton"
import XpWarningBanner from "gui-common/components/XpWarningBanner";
import CashPoolRunText from "features/cashPool/CashPoolRunText"
import {userCanRunPool, userCanRefreshBalances, userRightRestrictedBelowEntity} from "gui-common/functions/userRights";
import {activeClientBankAccountsSelector} from "xpool-gui-common/features/client/clientSelectors";
import {workflowConfig} from "appConfig/runState/runStateConstants";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import CancelResumeRestartRunButton from "features/cashPool/CancelResumeRestartRunButton";
import {selectActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {cashPoolOrdersListDataSelector} from "xpool-gui-common/features/order/orderSelectors";
import {orderStates} from "xpool-gui-common/features/order/orderConstants";


function RunPoolButtons(props) {

    const ormActiveClient = useSelector(shallowActiveClientSelector);
    const ormAccounts     = useSelector(activeClientBankAccountsSelector);
    const currentLanguage = useSelector(selectActiveLanguage);
    const user            = useSelector(userSelector);
    const cashPoolOrders = useSelector(cashPoolOrdersListDataSelector);
    const dispatch        = useDispatch();

    const ordersToExecute = useMemo(
        () => {
            return cashPoolOrders.filter(order => order.state === "SUGGESTED").reduce((str, order) => {
                return str + "- " + order.adjustedSideT + " " + order.currencyPairStr + " " + order.orderType + " " + order.nominalAmountCcy + "\n";
            }, "");
        },
        [cashPoolOrders]
    );


    function disableExecuteAndBalanceButton() {
        if (!ormAccounts || !ormAccounts.length) return true;
        return workflowConfig.ClientWorkflow.isRunning(ormActiveClient.cashPoolsRunState);
    }

    if (!ormActiveClient || !user) return null;

    moment.locale(currentLanguage);

    const userCanRunPoolConst           = userCanRunPool(ormActiveClient);
    const userCanRefreshBalancesConst   = userCanRefreshBalances(ormActiveClient);
    const userHasInconsistentUserRights = userCanRunPoolConst && userRightRestrictedBelowEntity(user, ormActiveClient, 'Client', 'Read');

    return (
        <div style={{paddingBottom:"7px", display: 'flex', marginRight: '-7px'}} xp-test-id='xpRunPoolButtons'>
            {userHasInconsistentUserRights &&
            <XpWarningBanner
                message={<XpTranslated trKey='runPoolButton.inconsistentUserRightsMessage'/>}
            />}

            <CancelResumeRestartRunButton/>

            <CashPoolRunText/>

            {(ormAccounts && userCanRefreshBalancesConst) &&
            <RequestReloadBalancesButton accountList={ormAccounts} disabled={disableExecuteAndBalanceButton()}/>}

            {(userCanRunPoolConst) &&
            <WaitingXpoolButton
                labelKey        = 'runPoolButton.executeSuggestedOrders.label'
                onClickCallback = {() => dispatch(executeSuggestedOrders())}
                disabled        = {disableExecuteAndBalanceButton() || (!ordersToExecute.length)}
                toolTipKey      = 'runPoolButton.executeSuggestedOrders.tooltip'
                modalKey        = 'runPoolButton.executeSuggestedOrders.confirmModal'
                modalParams     = {{orders: ordersToExecute}}
            />}

        </div>
    );
}
export default RunPoolButtons;


