import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";
import ToggleButton from "@mui/material/ToggleButton";

function XpToggle(props) {
    return (

        <ToggleButton
            disabled={props.disabled}
            value="check"
            selected={!!props.value}
            onChange={props.readOnly ? undefined : () => props.onChange(!props.value)}
            size="small"
            color="primary"
/*
            onChange={() => {
                setSelected(!selected);
            }}
*/
        >
            <span>{props.label}</span>

        </ToggleButton>
    )
}

function XpFormToggleInput (props) {
    return (
        <XpFormLabeledInput
            // inLineLayout

            {...props}

            CompToRender={XpToggle}
            getValueFromEvent={value => value}
            isToggleField
            setTouchedOnChange

            beforeChangeFunction={(before, after) => (before === after) ? null : before}
            valueRenderer={(value, props) => (value === null) ? null : (<XpTranslated trKey={value ? 'general.toggleValues.on' : 'general.toggleValues.off'} />)}
        />
    )
}

XpFormToggleInput.propTypes    = xpFormBasePropTypes;
export default XpFormToggleInput
