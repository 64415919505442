import { StyleSheet, Font } from '@react-pdf/renderer';

import TitilliumWebRegular       from 'gui-common/resources/TitilliumWeb-Regular.ttf'
import TitilliumWebSemiBold      from 'gui-common/resources/TitilliumWeb-SemiBold.ttf'
import TitilliumWebRegularItalic from 'gui-common/resources/TitilliumWeb-RegularItalic.ttf'

Font.register(
    { family: 'TitilliumWeb', fonts:
            [
                { fontWeight: 400, fontStyle: 'normal', src: TitilliumWebRegular },
                { fontWeight: 400, fontStyle: 'italic', src: TitilliumWebRegularItalic },
                { fontWeight: 600, fontStyle: 'normal', src: TitilliumWebSemiBold },
//                { fontWeight: 600, src: 'gui-common/resources/TitilliumWeb-SemiBold.ttf' },
//                { fontWeight: 600, src: 'https://fonts.googleapis.com/css?family=Titillium+Web:400,600&display=swap' },
            ]
    }
);

const cssText = {
    fontSize: 10,
    color: '#262626',
    fontWeight: 400,
};

const cssTextHeader = {
    fontSize: 12,
    color: '#262626',
    fontWeight: 600,
};
const cssMonthHeader = {
    fontSize: 10,
    color: '#262626',
    fontWeight: 600,
};

function getStylingObject(stylingConstants) {
    return {
        page: {
            fontFamily: 'TitilliumWeb',
            backgroundColor: 'white',
            textAlign: 'left',
            padding: 40,
        },
        header: {
            textAlign: 'center',
            marginTop: 10,
            ...stylingConstants.reportHeaderText,
        },
        reportConfig: {
            line: {
                marginTop: 2,
                marginLeft:  100,
                marginRight: 100,
                marginBottom: 10,
                flexDirection: 'row',
                fontSize: 10,
                color: '#262626',
                fontWeight: 400,
            },
            period: {
                width: 200,
                textAlign: 'left',
                flexGrow: 1,
            },
            printed: {
                width: 130,
                textAlign: 'right',
                flexGrow: 1,
            },
        },
        firstLevel: {
            header: {
                marginLeft :  20,
                marginRight: 20,
                marginTop  : 20,
                flexDirection: 'row',
                ...stylingConstants.topDimensionHeaderBorder,
                ...cssTextHeader,
            },
            nameCell: {
                paddingLeft: 0,
                width: 150,
                textAlign: 'left',
                flexGrow: 1,
            },
            contentCell: {
                width: 100,
                textAlign: 'right',
                flexGrow: 1,
                ...cssMonthHeader,
            },
            summaryNameCell: {
                width: 150,
                textAlign: 'left',
                border: 'solid',
                flexGrow: 1,
            },
            summary: {
                marginLeft:  20,
                marginRight: 20,
                marginTop: 0,
                flexDirection: 'row',
                ...stylingConstants.topDimensionSummaryBorder,
                ...cssTextHeader,
            }
        },
        midLevel: {
            header: {
                marginLeft:  20,
                marginRight: 20,
                marginTop: 10,
                flexDirection: 'row',
                ...cssMonthHeader,
            },
            nameCell: {
                width: 150,
                textAlign: 'left',
                border: 'solid',
                flexGrow: 1,
                textDecoration: 'underline',
            },
            contentCell: {
                width: 100,
                textAlign: 'right',
                flexGrow: 1,
            },
            summaryNameCell: {
                width: 150,
                textAlign: 'left',
                border: 'solid',
                flexGrow: 1,
            },
            summary: {
                marginLeft:  20,
                marginRight: 20,
                marginTop: 0,
                marginBottom: 10,
                flexDirection: 'row',
                ...cssMonthHeader,
            },
        },
        lastLevel: {
            line: {
                marginLeft:  20,
                marginRight: 20,
                flexDirection: 'row',
                ...cssText,
            },
            nameCell: {
                width: 150,
                textAlign: 'left',
                flexGrow: 1,
            },
            contentCell: {
                width: 100,
                textAlign: 'right',
                flexGrow: 1,
            }
        },

        pageNumber: {
            position: 'absolute',
            bottom: 13,
            left: 50,
            textAlign: 'right',
            paddingRight: 0,
            flexGrow: 1,
            fontSize: 10,
            color: '#262626',
            fontWeight: 400,
        },
        pageFooter: {
            position: 'absolute',
            bottom: 13,
            left: 22,
            width: '105%',
            textAlign: 'left',
            paddingRight: 0,
            paddingTop: 3,
            flexGrow: 1,
            ...stylingConstants.pageFooterText,
            ...stylingConstants.pageFooterBorder,
        },
    }
}

export function getStyleSheet(reportStyling) {
    const stylingObject = getStylingObject(reportStyling);
    return StyleSheet.create(stylingObject);
}

