import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {userCanUpdate} from "gui-common/functions/userRights";
import {pushModalWindow} from "redux-promising-modals";
import {INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

export default function XpAdminFormEntityGridTemplate (props) {

    const translate     = useXpTranslateFunction();
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const dispatch  = useDispatch();

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {
            if (props.suppressRowClickSelection) {
                dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: props.trKeyBase + '.cantSelectWhenInEditOrNew'}));
            }
        }, [props.suppressRowClickSelection]),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (props.itemSelectedCallback) props.itemSelectedCallback(data);
            },
            [props.itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];
                let menuItems = props.contextMenuItems ? props.contextMenuItems(params) : [];
                if (props.disableXpAdminFormFunctions) return menuItems;

                if (params.node && userCanUpdate(params.node.data)) menuItems.push({
                    name: translateRef.current(props.trKeyBase + '.gridContextMenuItems.editItem'),
                    action: function () {
                        // const node = this.context.params.node;
                        // if (!node || !node.data) return;
                        if (this.context.callingProps.xpAdminFormSetEditCallback) this.context.callingProps.xpAdminFormSetEditCallback();
                    },
                    context: {callingProps: props, params: params, dispatch: dispatch},
                    disabled: !params.node || props.suppressRowClickSelection,
                });
/*
                if (params.node && userCanDelete(params.node.data, props.ormModel)) menuItems.push({
                    name: translateRef.current(props.trKeyBase + '.gridContextMenuItems.disableItem'),
                    action: function () {
                        // const node         = this.context.params.node;
                        if (this.context.callingProps.xpAdminFormDisableCallback) this.context.callingProps.xpAdminFormDisableCallback();
                        // this.context.dispatch(checkAndDisableOrmEntity(this.context.callingProps.instanceId, node.data));
                    },
                    context: {callingProps: props, params: params, dispatch: dispatch},
                    disabled: !params.node || props.suppressRowClickSelection,
                });
*/
                return menuItems;
            }, [translate]),
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate(props.trKeyBase + '.noItemsToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? true : props.fillAvailableSpace,
                treeData                : props.treeData ? props.treeData : false,
                masterDetail            : false,
                groupDefaultExpanded    : 0,
                ormModel                : props.ormModel,
                getRowDataSelector      : props.getRowDataSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
            };
        },
        [translate, props.columnDefs, props.fillAvailableSpace, props.rowDataSelectorProps]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                {...props.gridOptions}
                columnDefs={props.columnDefs}
                rowData={props.rowData}
                setHeightCallback={props.setHeightCallback}
                suppressRowClickSelection={props.suppressRowClickSelection}
                frameworkComponents={props.frameworkComponents}
            >
            </XpGrid>
        </div>
    );
}
