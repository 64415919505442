import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {createSelector} from "reselect";
import {getCurrencyPairString, getFlatObjectValues} from "gui-common/functions/functions";

export const currencyPairConfigTypesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = [];

        const configs = session.CurrencyPairConfig.all().toRefArray();
        for (let config of configs) {
            if (returnArray.indexOf(config.configType) === -1) returnArray.push(config.configType);
        }
        return returnArray;
    }
);

export const getCurrencyPairConfigTypeSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.configType       : undefined,
    (session, configType) => {
        const configs = session.CurrencyPairConfig.all().filter({configType: configType}).orderBy('id', 'desc').toRefArray().map(config => ({
            ...config,
            name: getCurrencyPairString(config.currencyPair) + '-' + config.configType,
        }));
        return configs;
    }
);


// Todo: Support for multiple config types is not yet implemented in CurrencyPairConfigType.
export const savedCurrencyPairConfigSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector savedCurrencyPairConfigSelector running ");
        let returnArray = [];

        // In audit mode, only one line is displayed. Otherwise the selector will return multiple lines.
        const configs =session.CurrencyPairConfig.all().orderBy('id', 'desc').toRefArray();
        for (let config of configs) {
            returnArray.push({
                ...config,
                name: getCurrencyPairString(config.currencyPair) + '-' + config.configType,
            });
        }
        // console.log("Selected savedCurrencyPairConfigSelector:", returnArray);
        return returnArray;
    }
);

// Todo: Support for multiple config types is not yet implemented in CurrencyPairConfigType.
const savedConfigs   = (state, props) => savedCurrencyPairConfigSelector(state, props);
const changedConfigs = (state, props) => state.currencyPairConfigState.changedItems;
const inputData      = (state, props) => props ? props.inputData : undefined;
export const currentCurrencyPairConfigSelector = createSelector(
    [savedConfigs, changedConfigs, inputData],
    (savedConfigs, changedConfigs, inputData) => {
        if (inputData) return inputData; // Used for auditMode
        if (!savedConfigs) return [];
        // console.log("Selector currentCurrencyPairConfigSelector running ", currencyPairConfigs);

        let returnArray = [];

        for (let config of savedConfigs) returnArray.push(changedConfigs[config.id] ? changedConfigs[config.id] : config);

        // console.log("currentCurrencyPairConfigSelector done :", returnArray);
        return returnArray;
    }
);


// Todo: Support for multiple config types is not yet implemented in CurrencyPairConfigType.
const currentConfigs = (state, props) => currentCurrencyPairConfigSelector(state, props);
// const currencyPairChangedConfigs = state => state.currencyPairConfigState.changedItems;
export const currencyPairConfigListData = createSelector(
    [currentConfigs],
    (currentConfigs) => {
        if (!currentConfigs || !currentConfigs.length) return [];
        // console.log("Selector currencyPairConfigListDataSelector running ", currencyPairConfigs);

        let returnArray = [];

        for (let modifiedConfig of currentConfigs) {
            // const config = currencyPairChangedConfigs[ormConfig.id] ? currencyPairChangedConfigs[ormConfig.id] : ormConfig;
            const newRow = {
                id              : modifiedConfig.id,
                currencyPairStr : getCurrencyPairString(modifiedConfig.currencyPair),
                configType      : modifiedConfig.configType,
                changedByUser   : modifiedConfig.changedByUser,
                changedDateTime : modifiedConfig.changedDateTime,
            }
            // const savedConfig = savedConfigs.find(item => item.id === modifiedConfig.id);

            const flatModifiedConfigObject = getFlatObjectValues(modifiedConfig.configObject, '_');
            // const flatSavedConfigObject    = getFlatObjectValues(savedConfig ? savedConfig.configObject : undefined, '_');
            for (let key in flatModifiedConfigObject) {
                newRow[key] = flatModifiedConfigObject[key];
                // createFormFieldData(newRow, key, flatSavedConfigObject[key]);
            }
            returnArray.push(newRow);
        }
        // console.log("currencyPairConfigListDataSelector done :", returnArray);
        return returnArray;
    }
);
