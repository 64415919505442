import React, {useMemo} from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {childEntityInParentForm} from "gui-common/xpAdminForm/xpAdminFormFunctions";

const columnClassArray    = ["admin1Column", "admin2Column", "admin3Column"];

export default function XpAdminFormContent(props) {

    const formRenderingMatrix = useMemo(() =>
        {
            function fillMatrixFromLevel(formRenderMatrix, renderProps, currentData) {
                const thisModelFormConfig = props.xpAdminFormConfig[renderProps.ormModel];
                if (!thisModelFormConfig) return;

                const thisModelComponentMatrix = thisModelFormConfig.componentMatrix;

                for (let columnIndex in thisModelComponentMatrix) {
                    if (!formRenderMatrix[columnIndex]) formRenderMatrix[columnIndex] = [];
                    for (let formComponent of thisModelComponentMatrix[columnIndex]) {
                        formRenderMatrix[columnIndex].push({
                            formComponent: formComponent,
                            renderProps: {
                                ...renderProps,
                                formTemplate: thisModelFormConfig.formTemplate
                            },
                        });
                    }
                }

                if (props.auditMode && props.auditModeModifiedObject) {
                    return; // Only display first level since we will get one audit entry per entity type even when we integrate it in the parent form.
                }
                if (!thisModelFormConfig?.childEntities?.length) return;

                thisModelFormConfig.childEntities.forEach(childProps => {
                    if (!childProps.arrayProperty || !childProps.ormModel) return;

                    let inParentForm = childEntityInParentForm(childProps, props.formUseState, props.dependentFields, renderProps.formModel, currentData);
                    if (!inParentForm) return;

                    let nextLevelCurrentData = null;
                    if (props.formUseState === XP_FORM_VIEW) {
                        nextLevelCurrentData = currentData[childProps.arrayProperty] ? currentData[childProps.arrayProperty][0] : undefined;
                        if (!nextLevelCurrentData) return;
                    }
                    const childRenderingProps = {
                        ormModel: childProps.ormModel,
                        formModel: renderProps.formModel + '.' + childProps.arrayProperty + '.0',
                        isSubForm: true, // Needed to hide EntityBaseStaticFormComponent
                    }
                    fillMatrixFromLevel(formRenderMatrix, childRenderingProps, nextLevelCurrentData)
                });
            }

            let returnMatrix = [];
            fillMatrixFromLevel(returnMatrix, props, props.currentData)
            return returnMatrix;
        },
        [props.formModel, props.auditMode, props.dependentFields, props.currentData, props.formUseState]
    );

/*
    if (props.entityData.form.isSubmitted) return (
        <div xp-test-id={"ormFormSpinner-"+props.ormModel}>
            <XpTranslated trKey={getFormLabelKey(props, ".loading")}/>
            <img className="spinner" src={spinnerGif} alt="waiting" />
        </div>
    );
*/


    const columnCssClass      =  "adminColumn " + columnClassArray[formRenderingMatrix.length-1];

    return (
        <div className="adminFormContainer">
            {formRenderingMatrix.map((formColumn, colIndex) =>  {
                return (
                    <div className={columnCssClass} key={colIndex}>
                        {formColumn.map((formComponentData, rowIndex) =>  {
                            return (
                                <XpFormFieldset
                                    fieldModel   = {formComponentData.renderProps.formModel}
                                    key          = {rowIndex}
                                    formTemplate = {formComponentData.renderProps.formTemplate}
                                    consumerProps= {{
                                        ormModel : formComponentData.renderProps.ormModel,
                                        isSubForm: formComponentData.renderProps.isSubForm,
                                    }}
                                >
                                    <formComponentData.formComponent
                                        xpAdminFormConfig     = {props.xpAdminFormConfig}
                                        xpAdminFormInstanceId = {props.xpAdminFormInstanceId}
                                        rootOrmModel          = {props.ormModel}
                                        {...formComponentData.renderProps}
                                    />
                                </XpFormFieldset>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}


