import React, {useMemo} from "react";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {getCurrencyDashboardCurrencySettingsFormConfig} from "xpool-gui-common/appConfig/xpAdminFormConfig";

export default function CurrencyDashboardCurrencySettingsForm(props) {
    const xpAdminFormConfig = useMemo(
        () => {
            return getCurrencyDashboardCurrencySettingsFormConfig();
        },
        []
    );

    if (!props.account) return null;
    return (
        <XpAdminForm
            entityId              = {props.account.id}
            xpAdminFormInstanceId = 'currencySettingsForm'
            xpAdminFormConfig     = {xpAdminFormConfig}
            ormModel              = 'BankAccount'
            suppressAccordion     = {true}
            hideDeleteButton      = {true}
            // hideEditButton        = {!props.bankAccount.parentAccountId}
        />
    )
}
