import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {activeProvidersSelector} from "gui-common/orm/ormSelectors";
import IntegrationStatusLight from "gui-common/integrationStatus/IntegrationStatusLight";
import {selectWebSocketStatus} from "gui-common/api/webSessionSelectors";

const containerHeight = 20;
function IntegrationStatusMenuPanel (props) {
    const providers        = useSelector(activeProvidersSelector);
    const webSocketStatus  = useSelector(selectWebSocketStatus);

    const containerWidth   = useMemo(() => {
        if (!providers) return null;
        return providers.length * containerHeight
    }, [providers]);

    if (!providers) return null;

    // console.log("Rendering IntegrationStatusMenuPanel");

    return (
        <div style={{height:containerHeight +'px', width: containerWidth+'px', display:'inline-block', marginRight:'5px', marginLeft:'3px', position: 'relative', top: '2px'}} xp-test-id='xpIntegrationStatusMenuPanel'>
            {providers.map(item => {
                    const lastEvent = (item.events.length > 0) ? item.events[0] : null;
                    return (
                        <div style={{width: ((containerHeight) + 'px'), display: 'inline-block', padding: '1px', paddingTop: '0px'}} key = {item.id}>
                            <IntegrationStatusLight
                                active      = {true}
                                lastEvent   = {lastEvent}
                                isMenuLight = {true}
                                showName    = {item.name}
                                socketDown  = {webSocketStatus === "down"}
                            />
                        </div>
                    )})
            }
        </div>
    )
}
export default IntegrationStatusMenuPanel
