import {genericFetch} from "gui-common/api/apiCommon";
import {apiBaseUrlMap, ormApiBaseUrlMap} from "appConfig/api/apiConfig";
import {warnAndResolve} from "gui-common/functions/functions";
import {ormEntityUpdateStarted} from "gui-common/orm/ormReducer";
import {ormEntityClearUpdate} from "gui-common/orm/ormLoadingReducer";

export function requestReloadBalanceFromApi(account) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!account || !account.id) {
                warnAndResolve("Request reload balance called with no bankAccount ID", resolve);
                return;
            }
            let customText = "Request reload balance for bankAccount id " + account.id + " ";

            let requestUrl = apiBaseUrlMap.requestReloadBalance + "/" + account.id + "?";

            dispatch(ormEntityUpdateStarted('LedgerAccount', {id: account.ledgerBalanceLaId}));
            dispatch(genericFetch(
                requestUrl,
                customText,
                (resp) => (dispatch, getState) => {},
                requestReloadBalanceFailHandler(account),
                requestReloadBalanceFailHandler(account),
                'POST'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function requestReloadBalanceFailHandler(account) {
    return (resp) => {
        return (dispatch, getState) => {
            dispatch(ormEntityClearUpdate('LedgerAccount', {id: account.ledgerBalanceLaId}));
        }
    }
}

export function requestResetLedgerAccountsFromApi(account) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!account || !account.id) {
                warnAndResolve("Request reset ledger balances called with no bankAccount ID", resolve);
                return;
            }
            let customText = "Request reset ledger balances for bankAccount id " + account.id + " ";

            let requestUrl = ormApiBaseUrlMap.BankAccount + "/" + account.id + "/reset-ledger-accounts?";

            dispatch(ormEntityUpdateStarted('LedgerAccount', {id: account.ledgerBalanceLaId}));
            dispatch(ormEntityUpdateStarted('LedgerAccount', {id: account.pendingBalanceLaId}));
            dispatch(ormEntityUpdateStarted('LedgerAccount', {id: account.postPoolingBalanceLaId}));
            dispatch(genericFetch(
                requestUrl,
                customText,
                (resp) => (dispatch, getState) => {},
                requestResetBalancesFailHandler(account),
                requestResetBalancesFailHandler(account),
                'PUT'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function requestResetBalancesFailHandler(account) {
    return (resp) => {
        return (dispatch, getState) => {
            dispatch(ormEntityClearUpdate('LedgerAccount', {id: account.ledgerBalanceLaId}));
            dispatch(ormEntityClearUpdate('LedgerAccount', {id: account.pendingBalanceLaId}));
            dispatch(ormEntityClearUpdate('LedgerAccount', {id: account.postPoolingBalanceLaId}));
        }
    }
}

export function requestReloadBalancesFromApi(accounts) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!Array.isArray(accounts)) {
                warnAndResolve("Request reload balance called with no bankAccount IDs array", resolve);
                return;
            }
            if (!accounts?.length) {
                warnAndResolve("Request reload balance called with no bankAccount IDs", resolve);
                return;
            }
            let customText = "Request reload balance for bankAccount ids " + accounts;

            let requestUrl = apiBaseUrlMap.requestReloadBalances + "?";

            accounts.forEach(account => {
                dispatch(ormEntityUpdateStarted('LedgerAccount', {id: account.ledgerBalanceLaId}));
            })
            dispatch(genericFetch(
                requestUrl,
                customText,
                (resp) => (dispatch, getState) => {},
                requestReloadBalancesFailHandler(accounts),
                requestReloadBalancesFailHandler(accounts),
                'POST',
                accounts.map(account => account.id)
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

function requestReloadBalancesFailHandler(accounts) {
    return (resp) => {
        return (dispatch, getState) => {
            accounts.forEach(account => {
                dispatch(ormEntityClearUpdate('LedgerAccount', {id: account.ledgerBalanceLaId}));
            })
        }
    }
}
