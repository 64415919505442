import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {operatingUnitsWithFullClientCreateSelector} from "gui-common/orm/ormSelectors";
import {getDefaultObject} from "gui-common/functions/functions";
import {findAndBuildEnrichedEntity, getOrmModelObjectById,} from "gui-common/orm/ormFunctions";
import {buildLegalEntityObject} from "xpool-gui-common/features/legalEntity/legalEntitySelectors";
import {
    addParentRunConfigurationElements,
    createMergedRunConfigurationElements
} from "gui-common/runConfiguration/runConfigurationFunctions";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {createSelector} from "reselect";
import {createTranslatedFieldData, translateBoolFields} from "gui-common/xpGrid/xpGridFunctions";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {workflowConfig} from "appConfig/runState/runStateConstants";
import {runConfigurationConfig} from "appConfig/runConfiguration/runConfigurationConstants";
import {selectUserPreferencesActiveClientId} from "gui-common/userSettings/userSettingsSelectors";
import {entityIsActive, getLastChange} from "gui-common/audit/auditFunctions";
import {shallowActiveClientsSelector} from "features/client/clientSelectors";
import {buildBankAccountObject} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


const getCashPoolsState = (client) => {
    const runStates = client.runStates.orderBy('changedDateTime', 'desc').toRefArray();
    if (!runStates || !runStates.length) return {};
    return {
        cashPoolsRunStateObject: runStates[0],
        cashPoolsRunState      : runStates[0].runState,
        cashPoolsRunStateParams: runStates[0].runStateParams,
        cashPoolsRunStateReason: runStates[0].runStateParams?.reason,
    };
};

function buildShallowClient(clientModel) {
    return {
        ...clientModel.ref,
        ...getCashPoolsState(clientModel),
        clientBankAccounts: getClientBankAccounts(clientModel, false),
        clientOrders: clientModel.orders.toRefArray(),
        ...getLastChange('Client', clientModel),
    }
}

export function getXpoolActiveShallowClientsFromOrm(session) {
    let returnArray = [];
    session.Client.filter(entityIsActive).orderBy('name', 'asc').toModelArray().forEach(client => {
        returnArray.push(buildShallowClient(client));
    });
    // console.log("Shallow active clients:");
    // console.log(returnArray);
    return returnArray;
}
export function getAndBuildXpoolShallowClientFromOrm(session, clientId) {
    if (!clientId) return null;
    const client = session.Client.withId(clientId);
    if (!client) return undefined;

    return buildShallowClient(client);
}


export function getClientBankAccounts(clientModelObject, getDeep) {
    if (!clientModelObject) return [];

    let returnArray = [];
    clientModelObject.legalEntities.filter(entityIsActive).orderBy('id', 'asc').toModelArray().forEach(legalEntity => {
        legalEntity.legalEntityUnits.filter(entityIsActive).orderBy('id', 'asc').toModelArray().forEach(legalEntityUnit => {
            legalEntityUnit.bankAccounts.filter(entityIsActive).orderBy('id', 'asc').toModelArray().forEach(bankAccount => {
                const newItem = buildBankAccountObject(getDeep, bankAccount, legalEntityUnit, legalEntity, clientModelObject);
                returnArray.push(newItem);
            });
        });
    });
    return returnArray;
}

export const activeClientBankAccountsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => selectUserPreferencesActiveClientId(state),
    (session, activeClientId) => {
        //        console.log("Running activeClientBankAccountsSelector ", activeClientId);
        const client = getOrmModelObjectById(session, 'Client', activeClientId);
        if (!client) return [];
        let returnArray = getClientBankAccounts(client, true);
//        console.log("Selected active accounts: ", returnArray);
        return returnArray;
    }
);

export const activeClientLedgerAccountsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => activeClientBankAccountsSelector(state),
    (session, accounts) => {
        let returnArray = [];
        for (let account of accounts) {
            returnArray.push(account.ledgerBalanceLaId);
            returnArray.push(account.pendingBalanceLaId);
            returnArray.push(account.suggestedBalanceLaId);
            returnArray.push(account.postPoolingBalanceLaId);
            // returnArray.push(bankAccount.aggregatedBalanceLaId);
            // returnArray.push(bankAccount.aggregatedPostPoolingBalanceLaId);
        }
        return returnArray;
    }
);

export const makeClientBankAccountsSelector = () => {
    return ormCreateSelector(
        orm,
        dbStateSelector,
        state => selectUserPreferencesActiveClientId(state),
        (state, props) => props ? props.selectId : undefined,
        (session, activeClientId, selectedId) => {
//            console.log("Running clientBankAccountsSelector ", activeClientId, selectedId);
            const clientId = selectedId ? selectedId : activeClientId;
            const client = getOrmModelObjectById(session, 'Client', clientId);
            if (!client) return [];

            let returnArray = getClientBankAccounts(client, false);
            // console.log("Selected accounts: ", returnArray);
            return returnArray;
        }
    );
};

export function buildClientObject (clientModelObject, getDeep) {
    if (!clientModelObject) return undefined;

    const clientRef = clientModelObject.ref;

    const returnObject = {
        ...clientRef,
        ...getCashPoolsState(clientModelObject),
//        operatingUnit: session.OperatingUnit.idExists(obj.operatingUnitId) ? session.OperatingUnit.withId(obj.operatingUnitId).ref : null,
        legalEntities: getDeep ? clientModelObject.legalEntities.filter(entityIsActive).toModelArray().map(entity => buildLegalEntityObject(entity, getDeep)) : undefined,
        cashPools: clientModelObject.cashPools.toRefArray(),
        // clientRunState: getClientRunState(clientModelObject),
        runConfiguration: createMergedRunConfigurationElements(clientModelObject, 'Client'),
        clientBankAccounts: getDeep ? getClientBankAccounts(clientModelObject) : undefined,
    };
    return returnObject;
}

export const activeClientSelector = ormCreateSelector(
    orm, dbStateSelector,
    state => selectUserPreferencesActiveClientId(state),
    (session, clientId) => findAndBuildEnrichedEntity(session, 'Client', clientId, buildClientObject,true)
);
export const getSpecificClientSelector = () => ormCreateSelector(
    orm, dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, clientId) => findAndBuildEnrichedEntity(session, 'Client', clientId, buildClientObject,true)
);
export const getSpecificShallowClientSelector = () => ormCreateSelector(
    orm, dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, clientId) => findAndBuildEnrichedEntity(session, 'Client', clientId, buildClientObject,false)
);

export const getClientWithLegalEntitiesSelector = () => ormCreateSelector(
    orm, dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, clientId) => {
        if (!clientId) return undefined;
        const clientModel = getOrmModelObjectById(session, 'Client', clientId);
        const legalEntities = clientModel.legalEntities.toRefArray();
        return {
            ...clientModel.ref,
            legalEntities: legalEntities,
        }
    }
);


export const clientsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        return session.Client.all().toModelArray().map(item => item.ref);
    }
);

export const clientLegalEntitiesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => selectUserPreferencesActiveClientId(state),
    (state, props) => props ? props.selectId : undefined,
    (session, activeClientId, selectedId) => {
        const clientId = selectedId ? selectedId : activeClientId;
        if (!clientId) return [];

        const client = session.Client.idExists(clientId) ? session.Client.withId(clientId) : undefined;
        if (!client) return [];

        let returnArray = [];
        client.legalEntities.filter(entityIsActive).toModelArray().forEach(legalEntity => {
            const obj = legalEntity.ref;
            returnArray.push(Object.assign({}, obj, {
                client: client.ref,
//                country: session.Country.idExists(obj.countryId) ? session.Country.withId(obj.countryId).ref.country : "",
            }));
        });
        // console.log("Selected accounts: ", returnArray);
        return returnArray;
    }
);


/*
function getRunTime(entity, type, timeProp) {
    if (!entity || !type || !timeProp) return "";
    if (!entity.runConfiguration || !entity.runConfiguration[type] || !entity.runConfiguration[type].length) return ""
    for (const config of entity.runConfiguration[type]) { // Pick first config object that is local to the bankAccount.
        if (!config.isActive) continue;
        if (config.ownedByRunConfigurationId !== entity.runConfiguration.id) continue;
        return config[timeProp];
    }
}
*/

/*
function getRunTimes(client, timeProp) {
    const clientDaily   = getRunTime(client, 'dailyRun'  , timeProp);
    const clientMonthly = getRunTime(client, 'monthlyRun', timeProp);

    let returnString = clientDaily + ((clientDaily && clientMonthly) ? '/' : '') + clientMonthly;

    let accountString = "";
    for (const account of client.clientBankAccounts) {
        const accountDaily   = getRunTime(account, 'dailyRun'  , timeProp);
        const accountMonthly = getRunTime(account, 'monthlyRun', timeProp);
        if (!accountDaily && !accountMonthly) continue;

        accountString += account.currency.currency + ': ';
        accountString += accountDaily + ((accountDaily && accountMonthly) ? '/' : '') + accountMonthly + ', ';
    }
    if (accountString) {
        accountString = accountString.substring(0, accountString.length-2);
        returnString += " (" + accountString + ")";
    }
    return returnString;
}
*/

const requestStateSelector = getRequestingStateOnModelSelector();
const requestState         = state          => requestStateSelector(state, {model: 'RunState'});
const translate       = (state, props) => selectTranslateFunction(state);
const activeClients   = state          => shallowActiveClientsSelector(state);
const filterFunction  = (state, props) => props ? props.filterFunction : undefined;
const inputData       = (state, props) => props ? props.inputData      : undefined;
export const getClientListDataSelector = () => createSelector(
    [activeClients, translate, filterFunction, inputData, requestState],
    (activeClients, translate, filterFunction, inputData, requestState) => {
        // console.log("Selector getLegalEntityUnitsListDataSelector running ");

        const clients = inputData ? inputData : filterFunction ? activeClients.filter(filterFunction) : activeClients;

        let returnArray = [];
        for (let client of clients) {
            const enrichedClient = {...client};

            translateBoolFields(enrichedClient, translate);

            enrichedClient.cashPoolsRunState = client.cashPoolsRunState ? client.cashPoolsRunState : 'NONE';
            enrichedClient.cashPoolsRunStateParams = client.cashPoolsRunStateParams;

            let runStateTrKey = "clientRunState." + enrichedClient.cashPoolsRunState;
            if (client.cashPoolsRunStateParams && client.cashPoolsRunStateParams.reason) runStateTrKey += "_" + client.cashPoolsRunStateParams.reason;
            if (['NO_RUN_DAY', 'CANCELLED'].includes(client.cashPoolsRunState) && !client.cashPoolsRunStateParams?.nextRunDateTime) {
                runStateTrKey = "clientRunState.NO_RUN_THIS_MONTH";
            }

            createTranslatedFieldData(
                    enrichedClient,
                    'cashPoolsRunState',
                    translate,
                    runStateTrKey,
                    client.cashPoolsRunStateParams,
                    (requestState && client.cashPoolsRunStateObject && requestState[client.cashPoolsRunStateObject.id]) || workflowConfig.ClientWorkflow.isRunning(client.cashPoolsRunState)
                );
            // }
            enrichedClient.runPoolProcessNextRunTime = (client.cashPoolsRunState && client.cashPoolsRunStateParams) ? client.cashPoolsRunStateParams.nextRunDateTime : "";

            enrichedClient.activeOrderCount = client.clientOrders.filter(item => item.displayState !== 'IN_BALANCE').length;

            // enrichedClient.getBalancesTimeT = getRunTimes(client, 'getBalancesTime');
            // enrichedClient.executeTimeT     = getRunTimes(client, 'executeTime');
            // enrichedClient.stopTryingTimeT  = getRunTimes(client, 'stopTryingTime');

            enrichedClient.getBalancesTimeT = client.cashPoolsRunStateParams?.configuredGetBalancesTimes;
            enrichedClient.executeTimeT     = client.cashPoolsRunStateParams?.configuredExecutionTimes;
            enrichedClient.stopTryingTimeT  = client.cashPoolsRunStateParams?.configuredStopTryingTimes;

            enrichedClient.operatingUnitT   = enrichedClient.operatingUnit ? enrichedClient.operatingUnit.name : "";

            returnArray.push(enrichedClient);
        }
        // console.log("Selected getClientListDataSelector:", returnArray);
        return returnArray;
    }
);

/********* New client template selector *****************************************************************/

const availableTimeZones = state => selectAppEnvProperty(state, 'availableTimeZones');
const operatingUnits     = state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForAdmin'});
export const newClientFormTemplateSelector = ormCreateSelector(
    orm,
    dbStateSelector, availableTimeZones, operatingUnits,
    (session       , availableTimeZones, operatingUnits) => {
        const defaultOpUnit = getDefaultObject(operatingUnits);

        let parentRunConfigurations = {};
        const parentModelObject = getOrmModelObjectById(session, 'OperatingUnit', defaultOpUnit ? defaultOpUnit.id : undefined);
        addParentRunConfigurationElements(parentModelObject, 'OperatingUnit', parentRunConfigurations)

        if (!parentRunConfigurations.scheduling) parentRunConfigurations.scheduling = [runConfigurationConfig.scheduling.newTemplate];

        return {
            operatingUnitId     : defaultOpUnit ? defaultOpUnit.id : undefined,
            timeZone            : getDefaultObject(availableTimeZones).id,
            runConfiguration    : parentRunConfigurations,
            cashPools           : [{name: "default", description: ""}],
            // timeZone: momentTZ.tz.guess(),
            id:                     "",
            name:                   "",
            description:            "",
            shortName:              "",
            accountManager:         "",
            orgNumber:              "",
            clientRefId:            "",
            nettingRule:            false,
            useLegalEntities:       false,
            createdDateTime:        null,
            createdByUser:          "",
            changedDateTime:        null,
            changedByUser:          "",
        }
    }
);
