import React, {Component} from "react";
import spinnerGif from "gui-common/resources/spinner.gif";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

export default class XpGridTranslatedContent extends Component {
    constructor(props) {
        super(props);
        let set = {value: props.value, isWaiting: undefined};

        const dataKey = props.colDef.field + "RenderData";
        for (let key in props.data[dataKey]) {
            set[key] = props.data[dataKey][key];
        }
        this.state = {...set};
    }
    refresh(params) {
        let set = {};
        let isChanged = false;
        if(params.value !== this.state.value) {
            set.value = params.value;
            isChanged = true;
        }
        const dataKey = params.colDef.field + "RenderData";

        for (let key in params.data[dataKey]) {
            if (params.data[dataKey][key] !== this.state[key]) {
                isChanged = true;
                set[key] = params.data[dataKey][key];
            }
        }

        if (isChanged) this.setState({...set});
        return true;
    }

    getWaiting(){
        if (!this.state.isWaiting) return null;
        return <img className="smallTableSpinner" src={spinnerGif} alt="waiting"/>;
    }

    render () {
        if (!this.state.value && !this.state.trKey) return null;

        return (
            <span>
                {this.getWaiting()}
                {this.state.trKey ?
                    <XpTranslated trKey={this.state.trKey} trParams={this.state.trParams} fallBackText={this.state.fallBackText}/>
                    :
                    this.state.value
                }
            </span>
        );
    }
}
