import React from "react";
import {ResponsiveLine} from '@nivo/line'

function NivoLabFxRate (props) {

    return (
        <div style = {{width: 800, height: 400}}>
            <ResponsiveLine
                animate={true}
                margin = {{ top: 20, right: 20, bottom: 60, left: 80 }}
                data={[
                    {
                        id: 'fake corp. A',
                        data: [
                            { x: '2018-01-01', y: 7 },
                            { x: '2018-01-02', y: 5 },
                            { x: '2018-01-03', y: 11 },
                            { x: '2018-01-04', y: 9 },
                            { x: '2018-01-05', y: 12 },
                            { x: '2018-01-06', y: 16 },
                            { x: '2018-01-07', y: 13 },
                            { x: '2018-01-08', y: 13 },
                        ],
                    },
                    {
                        id: 'fake corp. B',
                        data: [
                            { x: '2018-01-04', y: 14 },
                            { x: '2018-01-05', y: 14 },
                            { x: '2018-01-06', y: 15 },
                            { x: '2018-01-07', y: 11 },
                            { x: '2018-01-08', y: 10 },
                            { x: '2018-01-09', y: 12 },
                            { x: '2018-01-10', y: 9 },
                            { x: '2018-01-11', y: 7 },
                        ],
                    },
                ]}

                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d',
                    useUTC: false,
                    precision: 'day',
                }}
                xFormat="time:%Y-%m-%d"
                yScale={{
                    type: 'linear',
                    stacked: true,
                }}
                // yFormat={val => val + 'H'}
                axisLeft={{
                    legend: 'linear scale',
                    legendOffset: 12,
                }}
                axisBottom={{
                    format: '%b %d',
                    tickValues: 'every 2 days',
                    legend: 'time scale',
                    legendOffset: -12,
                }}
                curve={'monotoneX'}
                enablePointLabel={true}
                pointSize={8}
                pointBorderWidth={1}
                pointBorderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.3]],
                }}
                useMesh={true}
                enableSlices={false}
                // pointSymbol={CustomPoint}
                layers={['points', 'grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'slices', 'mesh', 'legends']}
            />
        </div>
    )
}
NivoLabFxRate.propTypes = {
};
export default NivoLabFxRate

function CustomPoint(props) {
    return (
        <g>
            <foreignObject  width={5} height={5} overflow={'visible'}>
                <div className='xpChartActiveDot'/>
            </foreignObject>
        </g>
    )
}

