import PropTypes from "prop-types";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {ResponsivePie} from '@nivo/pie'
import {animated} from '@react-spring/web'
import {XpContainerTooltip} from "gui-common/components/XpContainerTooltip";

function XpPieChart (props) {
    const [endAngle, setEndAngle] = useState(props.startAngle ? props.startAngle : 0);
    const [mousePosInContainer, setMousePosInContainer] = useState();
    const [tooltipData, setTooltipData] = useState();

    const chartContainerRef = useRef(null);

    useEffect(
        () => {
            setTimeout(() => setEndAngle(props.endAngle ? props.endAngle : 360), 20);
        },
        [],
    );

    const layers = useMemo(
        () => {
            let returnArray = [];
            if (props.getCenterContent) returnArray.push(CenteredMetric(props.getCenterContent));
            returnArray.push('arcs');
            if (props.getLabelContent) returnArray.push('arcLabels');
            if (props.useArcLinkLabels) returnArray.push('arcLinkLabels');
            return returnArray;
        },
        []
    );

    function calculateAndSetMousePositionInContainer(event, pieDiv) {
        if (!pieDiv) {
            setMousePosInContainer(undefined)
        }
        const mousePos = {
            x: event.pageX - pieDiv.offsetLeft,
            y: event.pageY - pieDiv.offsetTop,
        }
        setMousePosInContainer(mousePos)
    }

    if (props.renderDebug) {
        console.log("Data length. ", props.data.length);
    }

    return (
        <div style = {{width: props.size, height: props.size, position: 'relative'}} ref={chartContainerRef}>
            <ResponsivePie
                data = {props.data}

                margin={props.margin ? props.margin : { top: 10, right: 10, bottom: 10, left:10 }}
                innerRadius={props.innerRadius}
                padAngle={props.padAngle}

                startAngle={props.startAngle}
                endAngle={endAngle}
                layers={layers}

                id    = {props.id}
                value = {props.value}

                colors={props.getColors}

                onMouseEnter={(node, event) => {
                    calculateAndSetMousePositionInContainer(event, chartContainerRef?.current);
                    setTooltipData(node);
                }}
                onMouseLeave={(node, event) => {
                    setMousePosInContainer(undefined)
                    setTooltipData(undefined);
                }}
                onMouseMove={(node, event) => {
                    calculateAndSetMousePositionInContainer(event, chartContainerRef?.current);
                }}

                arcLabelsComponent = {e => {
                    if (props.renderDebug) {
                        console.log("Arc label. ", e.datum.data.currency, e.datum.arc.angleDeg);
                        console.log("Data", props.data);
                    }

                    if (props.skipLabelAtAngle && (e.datum.arc.angleDeg < props.skipLabelAtAngle)) {
                        return null;
                    }
                    if (!props.data.find(item => props.id(item) === e.datum.id)) {
                        // This is needed to handle bug in nivo lib that does not remove old labels when data is changed.
                        return null;
                    }
                    return (
                        <animated.g transform={e.style.transform} style={{ pointerEvents: 'none' }}>
                            <foreignObject x={-15} y={-15} width={30} height={30} style={{ pointerEvents: 'none' }}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                    {props.getLabelContent(e)}
                                </div>
                            </foreignObject>
                        </animated.g>
                    );
                }}

                animate={true}
                activeOuterRadiusOffset = {6}
                arcLabelsRadiusOffset={0.5}
                tooltip={() => null}
            />
            {props.getTooltipContent && mousePosInContainer &&
            <XpContainerTooltip
                containerHeight={props.size}
                containerWidth={props.size}
                mousePosInContainer={mousePosInContainer}
                getTooltipContent={() => {
                    if (!tooltipData) {
                        return;
                    }
                    return props.getTooltipContent(tooltipData)
                }}
            />}
        </div>
    )
}

XpPieChart.propTypes = {
    data             : PropTypes.array.isRequired,
    size             : PropTypes.number.isRequired,
    margin           : PropTypes.object,
    innerRadius      : PropTypes.number,
    padAngle         : PropTypes.number,
    skipLabelAtAngle : PropTypes.number,
    startAngle       : PropTypes.number,
    endAngle         : PropTypes.number,
    getTooltipContent: PropTypes.func,
    getCenterContent : PropTypes.func,
    getLabelContent  : PropTypes.func,
    getColors        : PropTypes.func,
    useArcLinkLabels : PropTypes.bool
};
export default XpPieChart


const CenteredMetric = (getContent) => (props) => {
    return (
        <g>
            <foreignObject x={props.centerX - props.innerRadius} y={props.centerY - props.innerRadius} width={props.innerRadius * 2} height={props.innerRadius * 2} textAnchor="middle" dominantBaseline="central">
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    {getContent(props)}
                </div>
            </foreignObject>
        </g>
    )
}
