import PropTypes from "prop-types";
import React from "react";
import {smallFlagsIcons} from "gui-common/resources/flags/small/smallFlagIcons";
import {roundFlagsIcons} from "gui-common/resources/flags/round/roundFlagIcons";


const currencyMap = {
    "AUD": 'au',
    "CAD": 'ca',
    "CHF": 'ch',
    "EUR": 'eu',
    "GBP": 'gb',
    "JPY": 'jp',
    "NZD": 'nz',
    "USD": 'us',
    "SEK": 'se',
    "NOK": 'no',
    "DKK": 'dk',
    "ISK": 'is',
    "AED": 'ae',
    "BGN": 'bg',
    "CNH": 'cn',
    "CNY": 'cn',
    "CZK": 'cz',
    "HKD": 'hk',
    "HUF": 'hu',
    "ILS": 'il',
    "INR": 'in',
    "MXN": 'mx',
    "PLN": 'pl',
    "RON": 'ro',
    "RUB": 'ru',
    "SAR": 'sa',
    "SGD": 'sg',
    "THB": 'th',
    "ZAR": 'za',
}

function XpCurrencyFlag (props) {
    return (
        <div style={props.style}>
            <img draggable={props.draggable ? props.draggable : 'false'} style={ props.width ? {width: props.width + 'px'} : undefined} className="smallFlagIcon" src={roundFlagsIcons[props.currency]} alt={currencyMap[props.currency]}/>
        </div>
    )
}
XpCurrencyFlag.propTypes = {
    currency: PropTypes.string.isRequired,
    draggable: PropTypes.bool,
    style: PropTypes.object,
};
export default XpCurrencyFlag



