import PropTypes from "prop-types";
import React, {useMemo} from "react";
import BankAccountWidget from "xpool-gui-common/features/currencyDashboard/BankAccountWidget";
import {useActiveClientLE0Accounts, useBaseCurrency} from "xpool-gui-common/features/currencyDashboard/currencyDashboardFunctions";

function CurrencyDashboardAccounts (props) {
    const accounts = useActiveClientLE0Accounts();
    const baseCurrency = useBaseCurrency();

    const baseAccounts = useMemo(
        () => {
            if (!baseCurrency) {
                return [];
            }
            return accounts.reduce((res, acc) => {
                if (acc.currency.id === baseCurrency.id) {
                    res.push(acc);
                }
                return res;
            }, []);
        },
        [baseCurrency, accounts]
    );

    if (!accounts) return null;

    return (
        <div className={'currencyDashboardAccounts'}>
            {baseAccounts?.length &&
            <div>
                {baseAccounts.map((acc, index) => (<BankAccountWidget bankAccount={acc} key={index}/>))}
            </div>}
            <div className={'currencyDashboardSubAccounts'}>
                <div style={{display: 'flex'}}>
                    {accounts.map(account => {
                        if (account.currency.id === baseCurrency?.id) return null;
                        return (
                            <BankAccountWidget bankAccount={account} key={account.id}/>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default CurrencyDashboardAccounts



