import React, { Component } from 'react'
import {connect} from "react-redux";
import moment from 'moment';
import 'gui-common/dashboard/dashboard.css';
import DashboardPicker from "./DashboardPicker";
import './../../../node_modules/react-grid-layout/css/styles.css';
import './../../../node_modules/react-resizable/css/styles.css';
import DashboardCanvas from "./DashboardCanvas";
import {withRouter} from "react-router-dom";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";



class DashboardContainer extends Component {


    render() {
        moment.locale(this.props.currentLanguage);

        return (
            <div className="dashboardContainer">
                <DashboardPicker/>
                <DashboardCanvas/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboardState:         state.dashboardState,
        translate:          selectTranslateFunction(state),
        currentLanguage:    selectActiveLanguage(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardContainer));
