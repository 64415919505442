import React from 'react';
import {connect} from "react-redux";
import cloneDeep from "lodash.clonedeep";
import PropTypes from "prop-types";
import {
    formatSystemEventsGridData,
    prepareSystemEventsGridColumns
} from "gui-common/integrationStatus/integrationStatusFunctions";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


class SystemEventList extends React.Component {

    onGridCellClicked(params) {return true;}
    onGridRowClicked(params) {return true;}
    onGridObjectSelected(data) {return true;}
    gridContextMenuItems(params) {
        let menuItems = [];
        // let menuItem = {};
        return menuItems;
    }

    render() {
        const hiddenState = this.props.hiddenState ? this.props.hiddenState : {
            id:                    true,
            timeT:                 false,
            systemEventStatusType: false,
            description:           true,
        };

        const dataToRender = cloneDeep(this.props.events); // Get separate copy.
        const gridData = formatSystemEventsGridData(dataToRender, this.props.translate, this.props.currentLanguage);
        let preparedColumns = prepareSystemEventsGridColumns(this.props.translate, hiddenState);

        return (
            <div>
                <div style={{width: "100%", height: "100%"}}>
                    <XpGrid
                        gridCellClicked={(params) => this.onGridCellClicked(params)}
                        gridRowClicked={(params) => this.onGridRowClicked(params)}
                        gridObjectSelected={(data) => this.onGridObjectSelected(data)}
                        gridContextMenuItems={(params) => this.gridContextMenuItems(params)}

                        gridId={this.props.instanceId}
                        instanceId={this.props.instanceId}

                        columnDefs={preparedColumns}
                        rowData={gridData}
                        callingContext={this}

                        useResponsiveRowHeight={true}
                        suppressRowClickSelection={true}

                        treeData={false}
                        suppressDragLeaveHidesColumns={true}
                        suppressMakeColumnVisibleAfterUnGroup={true}
                        // rowGroupPanelShow="always"
                        groupUseEntireRow={false}
                        overlayNoRowsTemplate={this.props.translate('systemEventList.noEvents')}
                        ormModel='SystemEvent'
                        fillAvailableSpace={false}
                    >
                    </XpGrid>
                </div>
            </div>
        );
    };
}
const mapStateToProps = (state) => {
    return {
        translate:          selectTranslateFunction(state),
        currentLanguage:    selectActiveLanguage(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

const ConnectedSystemEventList = connect(mapStateToProps, mapDispatchToProps)(SystemEventList);

ConnectedSystemEventList.propTypes = {
    events:       PropTypes.array.isRequired,
    instanceId:   PropTypes.string.isRequired,
};

ConnectedSystemEventList.defaultProps = {
    events:          [],
};

export default ConnectedSystemEventList;
