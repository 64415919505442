import React, { Component } from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {reloadDataFromApi} from "gui-common/functions/thunks";
import {logoutUserFromApi} from "gui-common/api/userLogoutAPI";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

class FailedDataLoad extends Component {

    render() {
        return (
            <div className="welcomeScreen ">
                <h2 className="centeredText">{this.props.translate('failedDataLoad.header')}</h2>
                <div>
                    <hr></hr>
                    <p className="centeredText">{this.props.translate('failedDataLoad.message')}</p>
                    <button className="xPoolButton floatMiddle" onClick={() => this.props.reloadDataFromApi(this.props.appConfigState.appStartPath)} autoFocus>
                        {this.props.translate('failedDataLoad.retryButton')}
                    </button>
                    <button className="xPoolButton floatMiddle" onClick={() => this.props.logout()}>
                        {this.props.translate('topMenu.logout')}
                    </button>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        appConfigState  : state.appConfigState,
        translate       : selectTranslateFunction(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reloadDataFromApi:    (path)                => dispatch(reloadDataFromApi(path)),
        logout:               ()                    => dispatch(logoutUserFromApi())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FailedDataLoad));
