import PropTypes from "prop-types";
import React, {useMemo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {currencyDashboardStateSelector} from "xpool-gui-common/features/currencyDashboard/currencyDashboardSelectors";
import {currencyDashboardSetSelectedAccount} from "xpool-gui-common/features/currencyDashboard/currencyDashboardReducer";
import {getBalancesOnLedgerAccountFromApi} from "gui-common/ledger/ledgerApi";
import {useLedgerAccount} from "gui-common/ledger/ledgerHooks";
import XpFormattedAmount from "gui-common/numberFormat/XpFormattedAmount";
import XpCurrencyFlag from "gui-common/components/XpCurrencyFlag";
import {
    useBaseCurrency,
    useFxRateMap,
    useTotalLiquidity
} from "xpool-gui-common/features/currencyDashboard/currencyDashboardFunctions";
import {pushModalWindow} from "redux-promising-modals";
import {ORDER_ENTRY_DIALOG} from "appConfig/appModals";
import TradeCurrencyButton from "xpool-gui-common/features/currencyDashboard/TradeCurrencyButton";
import XpMiniLineChart from "gui-common/components/XpMiniLineChart";
import moment from "moment";
import XpTooltip from "gui-common/components/XpTooltip";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";


function BankAccountWidget (props) {

    const currencyDashboardState = useSelector(currencyDashboardStateSelector);
    const ledgerBalanceLa = useLedgerAccount(props.bankAccount?.ledgerBalanceLaId);
    const dispatch  = useDispatch();
    const fxPriceMap = useFxRateMap();
    // const totalLiquidity= useTotalLiquidity();
    const baseCurrency   = useBaseCurrency()

    const [isDropTarget, setIsDropTarget] = useState(false);

    // const fxRate = 4711;
/*
    useEffect(
        () => {
            if (currencyDashboardState.selectedAccountId === props.bankAccount.id) {
                loadBalances();
            }
        },
        [],
    );
*/

    const isSelected = useMemo(
        () => {
            if (!props?.bankAccount?.id) return false;
            return (currencyDashboardState.selectedAccountId === props.bankAccount.id)
        },
        [currencyDashboardState.selectedAccountId, props.bankAccount]
    );

    const isBaseAccount = useMemo(
        () => {
            return baseCurrency?.id === props.bankAccount.currency.id;
        },
        [baseCurrency, props.bankAccount]
    );

/*
    const exposure = useMemo(
        () => {
            if (!totalLiquidity || !props.bankAccount || !fxPriceMap || !fxPriceMap[props.bankAccount.ledgerBalanceLaId]) return "N/A";
            return Number(fxPriceMap[props.bankAccount.ledgerBalanceLaId].rateToBaseCurrency * props.bankAccount.ledgerBalance.balance * 100 /  totalLiquidity).toFixed(1) + '%';
        },
        [fxPriceMap, totalLiquidity]
    );
*/

    // Generate dummy data for historic fx rates
    const fxRateData = useMemo(
        () => {
            if (!fxPriceMap) {
                return undefined;
            }
            const returnArray = [];
            const today = moment();
            returnArray[13]={valueDate: today.format("YYYY-MM-DD"), rate: fxPriceMap[props.bankAccount.ledgerBalanceLaId].rateToBaseCurrency};
            for (let i = 12; i>=0; i--) {
                today.subtract(1, 'days');
                let newRate = returnArray[i+1].rate + returnArray[i+1].rate * Math.random() * 0.01 - returnArray[i+1].rate * Math.random() * 0.011;
                returnArray[i]={valueDate: today.format("YYYY-MM-DD"), rate: newRate};
            }
            return returnArray;
        },
        [fxPriceMap]
    );

    const accountNumberText = useMemo(
        () => {
            return (
                <span>
                    <XpTranslated trKey={'currencyDashboard.bankAccount.accountNumber'}/>
                    {'\n' + props.bankAccount.clearingNumber + '-' + props.bankAccount.accountNumber}
                </span>
            );
        },
        [props.bankAccount]
    );




/*
    function loadBalances() {
        dispatch(getBalancesOnLedgerAccountFromApi(props.bankAccount.ledgerBalanceLaId));
        dispatch(getBalancesOnLedgerAccountFromApi(props.bankAccount.pendingBalanceLaId));
        dispatch(getBalancesOnLedgerAccountFromApi(props.bankAccount.suggestedBalanceLaId));
        dispatch(getBalancesOnLedgerAccountFromApi(props.bankAccount.postPoolingBalanceLaId));
    }
*/

    if (!props.bankAccount) return null;

    return (
        <div className={'currencyDashboardBankAccountContainer' + (isBaseAccount ? ' baseCurrencyDashboardBankAccountContainer' : '')}>
            <div
                className={'currencyDashboardBankAccount' + (isSelected ? ' selectedCurrencyDashboardBankAccount' : '') + (isDropTarget ? ' currencyDashboardBankAccountDropTarget' : '')}
                onClick={() => {
/*
                    if (currencyDashboardState.selectedAccountId !== props.bankAccount.id) {
                        loadBalances();
                    }
*/
                    dispatch(currencyDashboardSetSelectedAccount(props.bankAccount))
                }}
                draggable={'true'}
                onDragOver={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.dataTransfer.dropEffect = 'move';
                    return false;
                    // console.log("onDragOver: ", props.bankAccount.currency.currency, e)
                }}
                onDrop={e => {
                    console.log("onDrop: ", props.bankAccount.currency.currency, e.dataTransfer.getData("fromCurrencyId"), e)
                    if (Number(e.dataTransfer.getData("fromCurrencyId")) === props.bankAccount.currency.id) {
                        // Dropped in same currency widget
                        return;
                    }
                    dispatch(pushModalWindow(ORDER_ENTRY_DIALOG, {fromCurrencyId: Number(e.dataTransfer.getData("fromCurrencyId")), toCurrencyId: props.bankAccount.currency.id}))
                    setIsDropTarget(false);
                }}
                // onDrag={e => console.log("onDrag: ", props.bankAccount.currency.currency, e)}
                onDragEnter={e => {
                    setIsDropTarget(true);
                    console.log("onDragEnter: ", props.bankAccount.currency.currency, e)
                }}
                onDragExit={e => {
                    console.log("onDragExit: ", props.bankAccount.currency.currency, e)
                }}
                onDragStart={e => {
                    console.log("onDragStart: ", props.bankAccount.currency.currency, e)
                    e.dataTransfer.dropEffect = 'move';
                    e.dataTransfer.setData("fromCurrencyId", props.bankAccount.currency.id)
                }}
                onDragEnd={e => console.log("onDragEnd: ", props.bankAccount.currency.currency, e)}
                onDragLeave={e => {
                    setIsDropTarget(false);
                    console.log("onDragLeave: ", props.bankAccount.currency.currency, e)
                }}
            >
                <div
                    // style={{pointerEvents: 'none'}}
                >
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <XpCurrencyFlag currency={props.bankAccount.currency.currency} width={35}/>
                        <XpTooltip title={accountNumberText}>
                            <h4 style={{margin: '3px 8px 0 10px', padding: '0px 0 0 0'}}>{props.bankAccount.currency.currency}</h4>
                        </XpTooltip>
                        <TradeCurrencyButton currency={props.bankAccount.currency} useIcon/>
                    </div>
                    {ledgerBalanceLa && <XpFormattedAmount amount={ledgerBalanceLa.balance}/>}
                    {!isBaseAccount &&
                    <div style={{width: '100%', height: '35px', marginTop: '-2px'}}>
                        <XpMiniLineChart
                            lineConfig={{
                                lineInstanceId: 'test',
                                // data: [{valueDate:'2021-01-01', rate: 1.2345}, {valueDate:'2021-01-02', rate: 1.2545}, {valueDate:'2021-01-03', rate: 1.2145}, {valueDate:'2021-01-04', rate: 1.2945}],
                                data: fxRateData,
                                dateField: 'valueDate',
                                dataKey: 'rate',
                                stroke: 'var(--xpool-primary-color-mid)',
                            }}
                            areaFill
                            // displayYTicks
                        />
                    </div>}
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '8px'}}>
                        <TradeCurrencyButton currency={props.bankAccount.currency}/>
                    </div>
                    {(!isBaseAccount && false) &&
                    <div className={'accountFxRateHeader'}>
                        {props.bankAccount.currency.currency + baseCurrency.currency}
                        {/*{': '}*/}
                        {/*{(fxPriceMap && fxPriceMap[props.bankAccount.ledgerBalanceLaId]) ? Number(fxPriceMap[props.bankAccount.ledgerBalanceLaId].rateToBaseCurrency).toFixed(4) : "N/A"}*/}
                    </div>}
                </div>
            </div>
{/*
            <div className={isBaseAccount ? 'baseAccountWidgetProperty' : 'accountWidgetProperty'}>
                {isBaseAccount ? <XpTranslated trKey={'currencyDashboard.bankAccount.rate.header'}/> : (fxPriceMap && fxPriceMap[props.bankAccount.ledgerBalanceLaId]) ? Number(fxPriceMap[props.bankAccount.ledgerBalanceLaId].rateToBaseCurrency).toFixed(4) : "N/A" }
            </div>
*/}
{/*
            <div className={isBaseAccount ? 'baseAccountWidgetProperty' : 'accountWidgetProperty'}>
                {isBaseAccount ? <XpTranslated trKey={'currencyDashboard.bankAccount.exposure.header'}/> : exposure }
            </div>
*/}
        </div>
    )
}
BankAccountWidget.propTypes = {
    bankAccount: PropTypes.object.isRequired,
};
export default BankAccountWidget
// {props.isBaseAccount ? <XpTranslated trKey={'currencyDashboard.bankAccount.rate.header'}/> : <XpFormattedAmount amount={ledgerBalanceLa?.balance}/>}



