import React, {useMemo} from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {activeCurrenciesSelector} from "gui-common/orm/ormSelectors"
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {activeClientBankAccountsSelector} from "xpool-gui-common/features/client/clientSelectors";
import {useXpFormContext, useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormUserCanEditBase} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormRadioInput from "gui-common/xpForm/core/XpFormRadioInput";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import {accountAllowedAsParent} from "xpool-gui-common/features/bankAccount/bankAccountFunctions";
import {useDisableAccountPropertyOnActiveOrders} from "features/bankAccount/bankAccountSelectors";

function AccountParentAccountSelector (props){
    const activeClientBankAccounts = useSelector(activeClientBankAccountsSelector);
    const ormCurrencies            = useSelector(activeCurrenciesSelector);
    const transferSupported        = useAppEnvProperty( 'transferSupported');
    const availableCreditTypes     = useAppEnvProperty( 'availableCreditTypes');
    const decDenLangState          = useSelector(selectDecDenLangState);
    const formContext              = useXpFormContext();
    const disableOnOpenOrders      = useDisableAccountPropertyOnActiveOrders(formContext?.currentData?.clientId, formContext);
    const formData      = useXpFormFields({ dependentFields:
            {
                currencyId: 'currencyId',
                legalEntityUnitId: 'legalEntityUnitId',
                institutionId: 'institutionId',
                id: 'id',
                accountType: 'accountType',
                creditType: 'creditType',
                parentAccountId: 'parentAccountId',
            }
    });

    const availableParentAccounts = useMemo(
        () => {
            let returnArray = [];
            let accounts = [];
            if (formContext?.auditMode) accounts = formContext.rootAuditProps.clientBankAccounts;
            else {
                accounts = props.parentItemData ? props.parentItemData.clientBankAccounts : activeClientBankAccounts;
            }
            accounts.forEach((account) => {
                if (!formContext?.auditMode && !accountAllowedAsParent(account, formData, accounts, transferSupported)) {
                    return;
                }
                let nameCurr = account.name;
                if (!account.currency) {
                    const currency = ormCurrencies.find(curr => curr.id === account.currencyId);
                    if (currency) nameCurr =  nameCurr + " (" + currency.currency + ")";
                }
                else if (typeof account.currency === 'object') {
                    nameCurr =  nameCurr + " (" + account.currency.currency + ")";
                }
                else {
                    nameCurr =  nameCurr + " (" + account.currency + ")";
                }
                let allowedParent = {...account, nameCurr: nameCurr};
                returnArray.push(allowedParent);
            });
            return returnArray
        },
        [activeClientBankAccounts, props.parentItemData, formData]
    );

    function fieldIsDisabled() {
        return !xpFormUserCanEditBase(formContext, props.ormModel, props.isSubForm);
    }

    const str2num = (val) => stringNumberToFloat(val, decDenLangState);

    return (
        <div className={props.className}>
            {(formData.accountType === "PHYSICAL") &&
            <XpFormSelectorInput
                inLineLayout
                field         = "parentAccountId"
                selectList    = {availableParentAccounts}
                itemTextField = "nameCurr"

                disabled      = {disableOnOpenOrders || fieldIsDisabled('parentAccountId')}
                labelKey      = {disableOnOpenOrders ? "accountForm.parentAccountId.labelBlockOnOrder" : undefined}
                toolTipTrKey  = {disableOnOpenOrders ? "accountForm.parentAccountId.toolTipBlockOnOrders" : undefined}
            />}

            {((availableCreditTypes.length > 1) && (!formData.parentAccountId)) &&
            <div>
                <XpFormRadioInput
                    field      = "creditType"
                    radioItems = {availableCreditTypes}
                    disabled   = {fieldIsDisabled()}
                />
                {(formData.creditType === "CREDIT") &&
                <XpFormAmountInput
                    isRequired
                    field        = "availableCredit"
                    disabled     ={fieldIsDisabled('availableCredit')}
                    errors={{
                        lowerThanZero:     (val, fields) => /\d/.test(val) && (str2num(val) <= 0),
                    }}
                />}
            </div>}

        </div>
    );
}

AccountParentAccountSelector.propTypes = {
    className:        PropTypes.string,
};

export default AccountParentAccountSelector;

