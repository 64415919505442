export const REPORT_DATA_RECEIVED       = 'REPORT_DATA_RECEIVED';
export const REPORT_DATA_LOAD_REQUESTED = 'REPORT_DATA_LOAD_REQUESTED';
export const REPORT_DATA_LOAD_CANCELLED = 'REPORT_DATA_LOAD_CANCELLED';
export const REPORT_DATA_CLEAR          = 'REPORT_DATA_CLEAR';
export const REPORT_LOADED              = 'REPORT_LOADED';
export const REPORT_LOAD_REQUESTED      = 'REPORT_LOAD_REQUESTED';
export const REPORT_LOAD_CANCELLED      = 'REPORT_LOAD_CANCELLED';
export const REPORT_SET_STYLING         = 'REPORT_SET_STYLING';

// Action creators ********************************************************
// ************************************************************************
export function reportDataReceived(data, dataSetType) {
    return {
        type: REPORT_DATA_RECEIVED,
        payload: {data: data, dataSetType: dataSetType}
    };
}
export function reportDataLoadRequested() {
    return {
        type: REPORT_DATA_LOAD_REQUESTED
    };
}
export function reportDataLoadCancelled() {
    return {
        type: REPORT_DATA_LOAD_CANCELLED
    };
}
export function clearReportData() {
    return {
        type: REPORT_DATA_CLEAR,
    };
}

export function reportLoaded()  {
    return {type: REPORT_LOADED         };
}
export function reportRequested() {
    return {type: REPORT_LOAD_REQUESTED };
}
export function reportCancelled() {
    return {type: REPORT_LOAD_CANCELLED };
}
export function reportSetStyling(styling) {
    return {type: REPORT_SET_STYLING, payload: styling };
}


// Reducer functions ********************************************************
// ************************************************************************

function loadData(state, payload) {
    if (!payload) return state;
    return {...state, reportData: payload.data, loadedDataSetType: payload.dataSetType, reportDataLoading: false, reportLoading: false};
}
function setLoading(state, payload) {
    return {...state, reportDataLoading: true};
}
function setReady(state, payload) {
    return {...state, reportDataLoading: false};
}
function clearData(state, payload) {
    return {...state, reportData: undefined};
}
function repRequested(state, payload) {
    return {...state, reportLoading: true};
}
function repCancelled(state, payload) {
    return {...state, reportLoading: false};
}
function repLoaded(state, payload) {
    return {...state, reportLoading: false};
}
function repSetStyling(state, payload) {
    return {...state, reportStyling: payload};
}

// Reducer action handlers map ************************************************
// ************************************************************************
const actionHandler = {
    [REPORT_DATA_RECEIVED]         : loadData,
    [REPORT_DATA_LOAD_REQUESTED]   : setLoading,
    [REPORT_DATA_LOAD_CANCELLED]   : setReady,
    [REPORT_DATA_CLEAR]            : clearData,
    [REPORT_LOADED]                : repLoaded,
    [REPORT_LOAD_REQUESTED]        : repRequested,
    [REPORT_LOAD_CANCELLED]        : repCancelled,
    [REPORT_SET_STYLING]           : repSetStyling,
};

const getInitialState = () => ({
    reportData        : undefined,
    reportDataLoading : false,
    reportLoading     : false,
    loadedDataSetType : undefined,
    reportStyling: {
        'reportHeaderText'          : {fontSize: 20, color: '#6AA5C1', fontWeight: 600},
        'topDimensionHeaderBorder'  : {borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: 'lightgray'},
        'topDimensionSummaryBorder' : {borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: 'lightgray'},
        'pageFooterBorder'          : {borderTopStyle: 'solid', borderTopWidth: 0.5, borderTopColor: '#6AA5C1'},
        'pageFooterText'            : {fontStyle: 'italic', fontSize: 10, color: '#262626', fontWeight: 400},
    }
});

export function reportReducer(state = getInitialState(), action) {
    const handler = actionHandler[action.type];
    return handler ? handler(state, action.payload) : state;
}
