import {useSelectorInstance} from "gui-common/functions/hooks";
import {getLedgerAccountsArraySelector, getLedgerAccountSelector} from "gui-common/ledger/ledgerSelectors";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {getBalancesOnLedgerAccountFromApi} from "gui-common/ledger/ledgerApi";

export function useLedgerAccount(ledgerAccountId) {
    const ledgerAccount = useSelectorInstance(getLedgerAccountSelector, {selectId: ledgerAccountId})
    return ledgerAccount;
}
export function useLedgerAccountsArray(ledgerAccountIds) {
    const ledgerAccounts = useSelectorInstance(getLedgerAccountsArraySelector, {selectArray: ledgerAccountIds})
    return ledgerAccounts;
}

export function useLedgerAccountWithBalances(ledgerAccountId) {
    const ledgerAccount = useLedgerAccount(ledgerAccountId);
    const dispatch = useDispatch();
    useEffect(() => {
            if (!ledgerAccount || ledgerAccount.baseLoadRequested || ledgerAccount.hasBalances) {
                return;
            }
            dispatch(getBalancesOnLedgerAccountFromApi(ledgerAccount.id));
        }, [ledgerAccount]
    );
    return ledgerAccount;
}
