import {attr, fk, Model, oneToOne} from 'redux-orm';

export const xpoolNestedOrmEntities = {
    Client: [
        {
            property : "legalEntities",
            model    : "LegalEntity",
            useSubEntityProp: 'useLegalEntities',
            nestedRightRequiredForWizard: true,
            nestedRightRequiredForAdmin : true,
        }, {
            property : "cashPools",
            model    : "CashPool"
        }, {
            property : "orders",
            model    : "Order"
        }
    ],
    LegalEntity: [
        {
            property : "legalEntityUnits",
            model    : "LegalEntityUnit",
            useSubEntityProp: 'useLegalEntityUnits',
            nestedRightRequiredForWizard: true,
            nestedRightRequiredForAdmin : true,
        },
        {
            property: "erpIntegration",
            model: "ErpIntegration"
        }
    ],
    LegalEntityUnit: [
        {
            property : "bankAccounts",
            model    : "BankAccount",
            nestedRightRequiredForWizard: true,
            nestedRightRequiredForAdmin : false,
        }
    ],
    BankAccount: [
        {
            property : "bankAccountRelations",
            model    : "BankAccountRelation"
        }, {
            property : "ledgerBalanceLa",
            model    : "LedgerAccount"
        }, {
            property : "pendingBalanceLa",
            model    : "LedgerAccount"
        }, {
            property : "suggestedBalanceLa",
            model    : "LedgerAccount"
        }, {
            property : "postPoolingBalanceLa",
            model    : "LedgerAccount"
        }, {
            property : "balanceMovements",
            model    : "BalanceMovement"
        }, {
            property : "aggregatedBalances",
            model    : "BankAccountAggregates"
        }
    ],
    BankAccountRelation: [
    ],
    CashPool: [
    ],
};

export const xpoolOrmModelParentProperties = {
    BankAccount         : {property: 'legalEntityUnitId'   , ormModel: 'LegalEntityUnit'},
    BankAccountRelation : {property: 'instructingAccountId', ormModel: 'BankAccount'},
};

class Client extends Model {}
Client.modelName = 'Client';
Client.fields = {
    id:                     attr(),
    name:                   attr(),
    description:            attr(),
    shortName:              attr(),
    accountManager:         attr(),
    operatingUnit:          attr(),
    operatingUnitId:        fk('OperatingUnit', 'includedClients'),
    orgNumber:              attr(),
    clientRefId:            attr(),
    nettingRule:            attr(),
    useLegalEntities:       attr(),
    runConfiguration:       attr(),

    createdDateTime:        attr(),
    createdByUser:          fk('User', 'createdClients'),
    changedDateTime:        attr(),
    changedByUser:          fk('User',  'changedClients'),
};

class RunState extends Model {}
RunState.modelName = 'RunState';
RunState.fields = {
    id:                         attr(),
    clientId:                   fk('Client', 'runStates'),
    cashPoolId:                 fk('CashPool', 'runStates'),
    barId:                      fk('BankAccountRelation', 'runStates'),
    accountId:                  fk('BankAccount', 'runStates'),
    runPeriod:                  attr(),
    workflow:                   attr(),
    runState:                   attr(),
    runStateParams:             attr(),
    childRunStates:             attr(),
    changedDateTime:            attr(),
};

class CashPool extends Model {}
CashPool.modelName = 'CashPool';
CashPool.fields = {
    id                      : attr(),
    clientId                : fk('Client', 'cashPools'),
    name                    : attr(),
    description             : attr(),
    includedLegalEntities   : attr(), // Not used before we have user admin of cash pools. Now we always have one default cash pool.
    createdDateTime         : attr(),
    createdByUser           : fk('User', 'createdCashPools'),
    changedDateTime         : attr(),
    changedByUser           : fk('User', 'changedCashPools'),
};

class LegalEntity extends Model {}
LegalEntity.modelName = 'LegalEntity';
LegalEntity.fields = {
    id:                     attr(),
    clientId:               fk('Client', 'legalEntities'),
    name:                   attr(),
    description:            attr(),
    countryId:              fk('Country'),
    lei:                    attr(),
    // parentEntityId:         fk('LegalEntity', 'childEntities'), // Skipped parent child feature for LE in oxyGen v2.
    useLegalEntityUnits:    attr(),
    runConfiguration:       attr(),

    createdDateTime:        attr(),
    createdByUser:          fk('User', 'createdLegalEntities'),
    changedDateTime:        attr(),
    changedByUser:          fk('User',  'changedLegalEntities'),
    erpIntegrationId:       oneToOne('ErpIntegration', 'legalEntity'),
};

class LegalEntityUnit extends Model {}
LegalEntityUnit.modelName = 'LegalEntityUnit';
LegalEntityUnit.fields = {
    id:                     attr(),
    legalEntityId:          fk('LegalEntity', 'legalEntityUnits'),
    name:                   attr(),
    description:            attr(),
    fxEnabled:              attr(),
    fxProviderId:           fk('Provider', 'consumers'),
    fxShortName:            attr(),
    runConfiguration:       attr(),

    createdDateTime:        attr(),
    createdByUser:          fk('User', 'createdLegalEntityUnits'),
    changedDateTime:        attr(),
    changedByUser:          fk('User',  'changedLegalEntityUnits'),
};

class BankAccount extends Model {}
BankAccount.modelName = 'BankAccount';
BankAccount.fields = {
    id:                         attr(),
    legalEntityUnitId:          fk('LegalEntityUnit', 'bankAccounts'),
    accountType:                attr(),
    institutionId:              fk('BankAccountInstitution', 'bankAccounts'),
    accountNumber:              attr(),
    clearingNumber:             attr(),
    currencyId:                 fk('Currency'),
    name:                       attr(),
    description:                attr(),
    bic:                        attr(),
    iban:                       attr(),
    parentAccountId:            fk('BankAccount', 'childAccounts'),
    creditType:                 attr(), // NO_CREDIT, CREDIT, UNLIMITED_CREDIT
    availableCredit:            attr(),
    runConfiguration:           attr(),

    ledgerBalanceLaId:          attr(),
    pendingBalanceLaId:         attr(),
    suggestedBalanceLaId:       attr(),
    postPoolingBalanceLaId:     attr(),

    createdDateTime:            attr(),
    createdByUser:              fk('User', 'createdBankAccounts'),
    changedDateTime:            attr(),
    changedByUser:              fk('User',  'changedBankAccounts'),

    integrationType:            attr(), //Todo: Rensa bort denna.
};

class BankAccountRelation extends Model {}
BankAccountRelation.modelName = 'BankAccountRelation';
BankAccountRelation.fields = {
    id:                     attr(),
    instructingAccountId:   fk('BankAccount', 'bankAccountRelations'),
    instructingAccountCurrency: attr(),
    name:                   attr(),
    description:            attr(),
    cashPoolId:             fk('CashPool', 'bankAccountRelations'),
    counterpartyAccountId:  fk('BankAccount'),
    runConfiguration:       attr(),
    executionConfiguration: attr(),

    createdDateTime:        attr(),
    createdByUser:          fk('User', 'createdBankAccountRelations'),
    changedDateTime:        attr(),
    changedByUser:          fk('User',  'changedBankAccountRelations'),
};

class BankAccountAggregates extends Model {}
BankAccountAggregates.modelName = 'BankAccountAggregates';
BankAccountAggregates.fields = {
    id                          : attr(),
    bankAccountId               : fk('BankAccount', 'bankAccountAggregates'),
    ledgerAggregatedBalance     : attr(),
    pendingAggregatedBalance    : attr(),
    postPoolingAggregatedBalance: attr(),
    suggestedAggregatedBalance  : attr(),
}

class BalanceMovement extends Model {}
BalanceMovement.modelName = 'BalanceMovement';
BalanceMovement.fields = {
    id:                     attr(),
    bankAccountId:          fk('BankAccount', 'balanceMovements'),

    movement:               attr(),
    valueDate:              attr(),
    comment:                attr(),

    createdDateTime:        attr(),
    createdByUser:          fk('User', 'createdBalanceMovements'),
};

class BankAccountInstitution extends Model {}
BankAccountInstitution.modelName = 'BankAccountInstitution';
BankAccountInstitution.fields = {
    id:         attr(),
    name:       attr(),
    description:attr(),
    country:    attr(),
    enabled:    attr()
};

class Order extends Model {}
Order.modelName = 'Order';
Order.fields = {
    id:                     attr(),
    clientId:               fk('Client', 'orders'),
    barId:                  fk('BankAccountRelation', 'orders'),
    currencyPair:           attr(),
    orderType:              attr(),
    nominalAmount:          attr(),
    instructedCurrencyStr:  attr(),
    side:                   attr(),
    createdDateTime:        attr(),
    modifiedDateTime:       attr(),

    currencyPairStr         : attr(),
    baseCurrencyStr         : attr(),
    baseCurrencyId          : attr(),
    quoteCurrencyStr        : attr(),
    quoteCurrencyId         : attr(),
    referenceRate           : attr(),
    executionRate           : attr(),
    executionRateFarLeg     : attr(),
    displayRate             : attr(),

    referenceCpAmount       : attr(),
    executionCpAmount       : attr(),
    displayCpAmount         : attr(),

    externalReference       : attr(),
    state                   : attr(),
    reason                  : attr(),
    displayStateT           : attr(),
    settlementDate          : attr(),
    executionDateTime       : attr(),
    adjustedSide            : attr(),

    actions:                attr(),
};

export const xpoolOrmModels = [
    Client,
    CashPool,
    // ClientSchedulingState,
    RunState,
    LegalEntity,
    LegalEntityUnit,
    BankAccount,
    BankAccountRelation,
    BankAccountAggregates,
    BalanceMovement,
    // AccountSchedulingState,
    BankAccountInstitution,
    Order
];
