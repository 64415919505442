import {createSelector} from "reselect";


const userMessages    = state => state.userMessagesState;

export const openUserMessagesSelector = createSelector(
    [userMessages],
    (userMessages) => {
        if (!userMessages)          return [];
        return userMessages.filter(item => (item.requireConfirmation && item.confirmedDateTime === null));
    }
);
