import {LinkContainer} from "react-router-bootstrap";
import {Nav, NavDropdown} from "react-bootstrap";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";

export function xpMainMenuItem(targetPath, trKey, displayCondition, testId) {
    if ((displayCondition !== undefined) && !displayCondition) return null;
    return (
        <LinkContainer to={targetPath}>
            <Nav.Link xp-test-id={testId} href={targetPath}>
                <XpTranslated trKey={trKey}/>
            </Nav.Link>
        </LinkContainer>
    )
}
export function xpMainMenuLinkContainer(targetPath, trKey, displayCondition, testId) {
    if ((displayCondition !== undefined) && !displayCondition) return null;
    return (
        <LinkContainer to={targetPath} key={targetPath}>
            <NavDropdown.Item xp-test-id={testId}>
                <XpTranslated trKey={trKey}/>
            </NavDropdown.Item>
        </LinkContainer>
    )
}
export function xpMenuItemWithConfirmation(trKey, trParams, dispatch, confirmFunction, modalType, modalProps, modalParams, disabled, testId) {
    return (
        <NavDropdown.Item xp-test-id={testId} key={trKey} disabled={disabled} onClick={disabled ? null : (e) => {
            dispatch(pushModalWindow(modalType ? modalType : CONFIRMATION_DIALOG, {modalKey: trKey, modalParams: modalParams, ...modalProps}))
                .then((result) => {
                    if (result.status === MODAL_TYPE_CONFIRM) {confirmFunction(result)}
                    if (result.status === MODAL_TYPE_CANCEL) {}
                });
        }}>
            <XpTranslated trKey={trKey+'.menuItem'} trParams={trParams}/>
        </NavDropdown.Item>
    )
}
