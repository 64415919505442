import React from 'react'
import {useSelector} from "react-redux";
import AccountBalanceList from 'features/bankAccountBalance/AccountBalanceList'
import ComponentLoading from "gui-common/components/ComponentLoading"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import moment from "moment";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function CashPoolBalanceList(props) {

    const translate = useSelector(selectTranslateFunction);
    const today = moment().endOf('day');
    return (
        <div className={props.className}>
            <XpAccordion
                header={<XpTranslated trKey='accountForm.accountBalances' trParams={{currency: props.account.currency.currency}}/>}
                headerClassName="textGenerationsHeader"
                instanceId={"cashPool-accountBalanceList"}
            >
                <div>
                    {(props.balanceListLoading) &&
                    <ComponentLoading loadingMessage={translate('accountBalanceList.loading')}/>}

                    <AccountBalanceList
                        instanceId={"cashPool-balances-list"}
                        rowDataSelectorProps={{
                            includeFuture: false,
                            includeDisabled: true,
                            sortOrder: 'desc',
                            ledgerAccountId: props.account.ledgerBalanceLaId,
                            filterFunction: item => {
                                return today.isAfter(moment(item.valueDate))
                            },
                        }}
                        account={props.account}
                    />
                </div>
            </XpAccordion>
        </div>
    );
}

export default CashPoolBalanceList;



