import {
    getSpecificShallowClientSelector,
    newClientFormTemplateSelector
} from "xpool-gui-common/features/client/clientSelectors";
import {canClientBeDisabled} from "xpool-gui-common/features/client/clientFunctions";
import RunConfigurationsList from "gui-common/runConfiguration/RunConfigurationsList";
import React from "react";
import {getSpecificBankAccountSelector,} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import {getShallowLegalEntitySelector} from "xpool-gui-common/features/legalEntity/legalEntitySelectors";
import ErpIntegrationForm from "gui-common/erpIntegration/ErpIntegrationForm";
import AccountParentAccountSelector from "features/bankAccount/formComponents/AccountParentAccountSelector";

const MonthlyRunConfigurations    = (props) => <RunConfigurationsList type={'monthlyRun'   } {...props}/>
const DailyRunConfigurations      = (props) => <RunConfigurationsList type={'dailyRun'     } {...props}/>
const SweepAndTopUpConfigurations = (props) => <RunConfigurationsList type={'sweepAndTopUp'} {...props}/>
const MaxMinSplitConfigurations   = (props) => <RunConfigurationsList type={'maxMinSplit'  } {...props}/>
const HoldOnRateConfigurations    = (props) => <RunConfigurationsList type={'holdOnRate'   } {...props}/>
const SchedulingConfigurations    = (props) => <RunConfigurationsList type={'scheduling'   } {...props}/>
const HedgeExposureConfigurations = (props) => <RunConfigurationsList type={'hedgeExposure'} {...props}/>


export const getCurrencyDashboardCurrencySettingsFormConfig = () => ({
    BankAccount: {
        formTemplate: 'cashPoolAccountForm',
        getCurrentDataSelector: getSpecificBankAccountSelector,
        newFormTemplateSelector: () => ({}),
        checkBeforeDisableFunction: () => false,
        componentMatrix: [
            [AccountParentAccountSelector, SweepAndTopUpConfigurations, HedgeExposureConfigurations, MaxMinSplitConfigurations],
            [DailyRunConfigurations, MonthlyRunConfigurations, HoldOnRateConfigurations]
        ],
        childEntities: undefined,
    }
})

export const getCurrencyDashboardClientSettingsFormConfig = () => ({
    Client: {
        formTemplate: 'clientForm',
        getCurrentDataSelector: getSpecificShallowClientSelector,
        newFormTemplateSelector: newClientFormTemplateSelector,
        checkBeforeDisableFunction: canClientBeDisabled,
        subEntitySuffix: ' (CL)',
        componentMatrix: [
            [SchedulingConfigurations, DailyRunConfigurations, MonthlyRunConfigurations]
        ],
        childEntities: undefined,
/*
        childEntities: [
            {
                ormModel        : 'LegalEntity',
                inParentFormProperty  : 'useLegalEntities',
                arrayProperty   : 'legalEntities',
                listComponent   : LegalEntitiesList,
                rowDataFilterFunction  : (item, parentItemData) => {
                    if (!item.clientId) return false;
                    return (item.clientId === parentItemData.id);
                },
            },
        ],
    },
    LegalEntity: {
        formTemplate: 'legalEntityForm',
        getCurrentDataSelector: getSpecificLegalEntitySelector,
        newFormTemplateSelector: newLegalEntityFormTemplateSelector,
        checkBeforeDisableFunction: canLegalEntityBeDisabled,
        subEntitySuffix: ' (LE)',
        componentMatrix: [
            [],
        ],
        childEntities: [
            {
                ormModel        : 'LegalEntityUnit',
                inParentFormProperty  : 'useLegalEntityUnits',
                arrayProperty   : 'legalEntityUnits',
                listComponent   : LegalEntityUnitsList,
                rowDataFilterFunction  : (item, parentItemData) => {
                    if (!item.legalEntityId) return false;
                    return (item.legalEntityId === parentItemData.id);
                },
            },
        ],
    },
    LegalEntityUnit: {
        formTemplate: 'legalEntityUnitForm',
        getCurrentDataSelector: getSpecificLegalEntityUnitSelector,
        newFormTemplateSelector: newLegalEntityUnitFormTemplateSelector,
        checkBeforeDisableFunction: canLegalEntityUnitBeDisabled,
        subEntitySuffix: ' (BU)',
        componentMatrix: [
            [],
        ],
        childEntities: undefined,
*/
/*
        [
            {
                ormModel        : 'BankAccount',
                inParentFormProperty  : undefined,
                arrayProperty   : 'bankAccounts',
                // listComponent   : AccountsList,
                rowDataFilterFunction  : (item, parentItemData) => {
                    if (!item.legalEntityUnitId) return false;
                    return (item.legalEntityUnitId === parentItemData.id);
                },
            },
        ],
*/
    },
})
export const getCurrencyDashboardErpConfigAdminFormConfig = () => ({
    LegalEntity: {
        formTemplate: 'legalEntityForm',
        getCurrentDataSelector: getShallowLegalEntitySelector,
        newFormTemplateSelector: () => ({}),
        checkBeforeDisableFunction: () => false,
        subEntitySuffix: ' (LE)',
        componentMatrix: [
            [ErpIntegrationForm],
        ],
        childEntities: undefined,
    },
})
