import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import { modalStandardParams} from "gui-common/modals/modalConstants";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormChangeField, xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import {useActiveClientLE0Accounts} from "xpool-gui-common/features/currencyDashboard/currencyDashboardFunctions";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import MaterialIcon from "material-icons-react";
import {createSelectItems, useResizeObserver} from "gui-common/functions/hooks";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {activeCurrencyPairsSelector, activeFxPricesSelector,} from "gui-common/orm/ormSelectors";
import spinnerGif from "gui-common/resources/spinner.gif";
import LabelValueDisplay from "gui-common/components/LabelValueDisplay";
import moment from "moment";
import XpFormattedAmount from "gui-common/numberFormat/XpFormattedAmount";
import XpMuiIconButton from "gui-common/components/XpMuiIconButton";
import {pushModalWindow} from "redux-promising-modals";
import {ORDER_ENTRY_DIALOG} from "appConfig/appModals";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const thisFormModel = 'orderEntryModalForm';
const buySellStyle={width: '60px', marginRight: '5px'}
const currencyStyle={width: '80px', marginRight: '5px'}
const amountStyle={width: 'calc(50% - 180px)'}
const iconStyle={width: '100px', padding: '0 26px 0 30px',marginBottom: '-9px'}
export function OrderEntryModal(props) {

    const decDenLangState = useSelector(selectDecDenLangState);
    const dispatch    = useDispatch();
    const accounts = useActiveClientLE0Accounts();

    const currencyPairs = useSelector(activeCurrencyPairsSelector);
    const fxPrices      = useSelector(activeFxPricesSelector);

    const fromCurrencyIdFormData = useXpFormField(thisFormModel + '.fromCurrencyId');
    const toCurrencyIdFormData   = useXpFormField(thisFormModel + '.toCurrencyId');

    const fromAmountFormData = useXpFormField(thisFormModel + '.fromAmount');
    const toAmountFormData   = useXpFormField(thisFormModel + '.toAmount');

    const fromBuySellFormData = useXpFormField(thisFormModel + '.fromBuySell');
    const toBuySellFormData   = useXpFormField(thisFormModel + '.toBuySell');

    const tenorFormData       = useXpFormField(thisFormModel + '.tenor');

    const buySellTypes       = createSelectItems(['BUY', 'SELL']);
    const tenors             = createSelectItems(['TD', 'TM', 'SP']);

    const [requestingPrice, setRequestingPrice] = useState(false);
    const [priceReceived  , setPriceReceived  ] = useState(false);

    const [modalContentHeight  , setModalContentHeight] = useState(0);

    const observerCallback = useCallback(box => {
        if (modalContentHeight === box.height) {
            // No need to update!
            return;
        }
        setModalContentHeight(box.height);
    }, []);
    const containerRef = useResizeObserver(observerCallback)

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalOrderEntry"}};

    const fromCurrencies = useMemo(
        () => {
            if (!accounts?.length) return [];
            let returnArray = [];
            accounts.forEach(account => {
                if (account.currency.id === toCurrencyIdFormData) return;
                returnArray.push({id: account.currency.id, name: account.currency.currency})
            })
            return returnArray;
        },
        [accounts, toCurrencyIdFormData]
    );
    const toCurrencies = useMemo(
        () => {
            if (!accounts?.length) return [];
            let returnArray = [];
            accounts.forEach(account => {
                if (account.currency.id === fromCurrencyIdFormData) return;
                returnArray.push({id: account.currency.id, name: account.currency.currency})
            })
            return returnArray;
        },
        [accounts, fromCurrencyIdFormData]
    );
    const selectedCurrencyPair = useMemo(
        () => {
            if (!fromCurrencyIdFormData || !toCurrencyIdFormData) {
                return undefined;
            }

            const possibleCurrencyPairs = currencyPairs.filter(ccyPair => {
                return (
                    [ccyPair.baseCurrencyId, ccyPair.quoteCurrencyId].includes(fromCurrencyIdFormData) ||
                    [ccyPair.baseCurrencyId, ccyPair.quoteCurrencyId].includes(toCurrencyIdFormData)
                );
            });

            const ccyPair = possibleCurrencyPairs.find(ccyPair => {
                return (ccyPair.baseCurrencyId === fromCurrencyIdFormData) && (ccyPair.quoteCurrencyId === toCurrencyIdFormData)
            });
            if (ccyPair) {
                return ccyPair;
            }
            return currencyPairs.find(ccyPair => {
                return (ccyPair.quoteCurrencyId === fromCurrencyIdFormData) && (ccyPair.baseCurrencyId === toCurrencyIdFormData)
            })
        },
        [fromCurrencyIdFormData, toCurrencyIdFormData]
    );
    const selectedCurrencyPairName = useMemo(
        () => {
            if (!selectedCurrencyPair) {
                return "N/A";
            }
            return selectedCurrencyPair.baseCurrency.currency + selectedCurrencyPair.quoteCurrency.currency;
        },
        [selectedCurrencyPair]
    );
    const selectedCurrencyPairRate = useMemo(
        () => {
            if (!selectedCurrencyPair) return "N/A"
            const fxPrice = fxPrices.find(price => price.currencyPairId === selectedCurrencyPair.id);
            if (!fxPrice) return "N/A";
            return fxPrice.midRate;
        },
        [selectedCurrencyPair, fxPrices]
    );
    const selectedFromCurrencyName = useMemo(
        () => {
            if (!fromCurrencies || !fromCurrencyIdFormData) return "";
            const currency = fromCurrencies.find(ccy => ccy.id === fromCurrencyIdFormData);
            return currency ? currency.name : "";
        },
        [fromCurrencyIdFormData, fromCurrencies]
    );
    const selectedToCurrencyName = useMemo(
        () => {
            if (!toCurrencies || !toCurrencyIdFormData) return "";
            const currency = toCurrencies.find(ccy => ccy.id === toCurrencyIdFormData);
            return currency ? currency.name : "";
        },
        [toCurrencyIdFormData, toCurrencies]
    );

    function cancelAction() {
        props.popModalWindow({ status: MODAL_TYPE_CANCEL });
        dispatch(xpFormRemoveForm(thisFormModel));
    }

    function handleSubmit() {
        setRequestingPrice(true);
        setTimeout(() => {
            setPriceReceived(true);
            setRequestingPrice(false);
        }, 1000);
    }

    function executeAction() {
        props.popModalWindow({ status: MODAL_TYPE_CONFIRM});
        dispatch(xpFormRemoveForm(thisFormModel));
    }

    function getCounterAmount(fixedAmountStr, currencyId) {
        const fixedAmount = stringNumberToFloat(fixedAmountStr, decDenLangState);

        if (selectedCurrencyPair.quoteCurrencyId === currencyId) {
            return fixedAmount * selectedCurrencyPairRate;
        }
        return fixedAmount / selectedCurrencyPairRate;
    }

    function getSettlementDate() {
        if (tenorFormData === 'TD') return moment().add(0, 'days').format('YYYY-MM-DD');
        if (tenorFormData === 'TM') return moment().add(1, 'days').format('YYYY-MM-DD');
        if (tenorFormData === 'SP') return moment().add(2, 'days').format('YYYY-MM-DD');
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Order entry modal"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalParams}
                >
                    {requestingPrice &&
                    <div>
                        <div style={{height: modalContentHeight + 'px'}}>
                            <h3 className="xpoolModalHeader"><XpTranslated trKey={'orderEntryModalForm.headerRequestingPrice'} trParams={{ccyPair: selectedCurrencyPairName}}/></h3>
                            <hr/>
                            <p className="valueTextDark"><XpTranslated trKey={'orderEntryModalForm.requestingPrice'} /></p>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <img className="spinner" src={spinnerGif} alt="waiting" />
                            </div>
                        </div>
                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                labelKey        = "orderEntryModalForm.back"
                                onClickCallback = {() => {
                                    setRequestingPrice(false);
                                }}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                labelKey        = 'general.buttons.cancel'
                                onClickCallback = {() => cancelAction()}
                            />
                        </div>
                    </div>}

                    {priceReceived &&
                        <div>
                            <div style={{height: modalContentHeight + 'px'}}>
                                <h3 className="xpoolModalHeader"><XpTranslated trKey={'orderEntryModalForm.headerPriceOffered'} trParams={{ccyPair: selectedCurrencyPairName, rate: selectedCurrencyPairRate}}/></h3>
                                <hr/>
                                <div style={{marginLeft: '25%', marginRight: '25%'}}>
                                    <LabelValueDisplay
                                        label={<span><XpTranslated trKey={'orderEntryModalForm.fromBuySell.values.' + fromBuySellFormData}/> {selectedFromCurrencyName}:</span>}
                                        value={<XpFormattedAmount amount = {fromAmountFormData ? fromAmountFormData : getCounterAmount(toAmountFormData, fromCurrencyIdFormData)} currencySuffix={selectedFromCurrencyName}/>}
                                        labelTab={40} alignRight
                                    />
                                    <LabelValueDisplay
                                        label={<span><XpTranslated trKey={'orderEntryModalForm.toBuySell.values.' + toBuySellFormData}/> {selectedToCurrencyName}:</span>}
                                        value={<XpFormattedAmount amount = {toAmountFormData ? toAmountFormData : getCounterAmount(fromAmountFormData, toCurrencyIdFormData)} currencySuffix={selectedToCurrencyName}/>}
                                        labelTab={40} alignRight
                                    />
                                    <LabelValueDisplay
                                        label={<XpTranslated trKey={'orderEntryModalForm.settlementDate'}/>}
                                        value={getSettlementDate()}
                                        labelTab={40} alignRight
                                    />
                                </div>
                            </div>
                            <div className="xpoolModalButtons">
                                <WaitingXpoolButton
                                    labelKey        = "orderEntryModalForm.back"
                                    onClickCallback = {() => setPriceReceived(false)}
                                />
                                <WaitingXpoolButton
                                    labelKey        = 'orderEntryModalForm.execute'
                                    onClickCallback = {() => executeAction()}
                                />
                                <WaitingXpoolButton
                                    labelKey        = 'general.buttons.cancel'
                                    onClickCallback = {() => cancelAction()}
                                />
                            </div>
                        </div>}

                    {(!requestingPrice && !priceReceived) &&
                    <div xp-test-id="orderEntryDialog">
                        <div ref={containerRef}>
                            <h3 className="xpoolModalHeader"><XpTranslated trKey={'orderEntryModalForm.header'}/></h3>
                            <hr/>
                            <p className="valueTextDark"><XpTranslated trKey={'orderEntryModalForm.message'} /></p>
                            <hr/>
                            <XpForm
                                formModel={thisFormModel}
                                onSubmit={(data) => handleSubmit(data)}
                                initialUseState={XP_FORM_EDIT}
                                // initialFormData={{fromAmount: "", toAmount: "", fromCurrencyId: "", toCurrencyId: "", tenor: "", clientRef: ""}}
                            >
                                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                                    <div style={buySellStyle}>
                                        <XpFormSelectorInput
                                            noTemplate noLabel
                                            field="fromBuySell"
                                            itemTextField    = "name"
                                            selectList={buySellTypes}
                                            defaultValue={"BUY"}
                                            onChangeCallback={value => dispatch(xpFormChangeField(thisFormModel + '.toBuySell', (value === 'BUY') ? 'SELL' : 'BUY'))}
                                        />
                                    </div>

                                    <div style={currencyStyle}>
                                        <XpFormSelectorInput
                                            isRequired noLabel noTemplate
                                            field="fromCurrencyId"
                                            selectList={fromCurrencies}
                                            itemTextField="name"
                                            defaultValue={props.fromCurrencyId ? props.fromCurrencyId : ""}
                                        />
                                    </div>
                                    <div style={amountStyle}>
                                        <XpFormAmountInput
                                            autoFocus noLabel
                                            field           = "fromAmount"
                                            errors={{
                                                lowerThanZero:     (val) => /\d/.test(val) && (stringNumberToFloat(val, decDenLangState) < 0),
                                            }}
                                            disabled={!!toAmountFormData}
                                        />
                                    </div>
                                    <div style={iconStyle}>
                                        <XpMuiIconButton
                                            icon={SwapHorizIcon}
                                            color={'var(--xpool-primary-color-strong)'}
                                            size={40}
                                            className={'currencyTradeIcon'}
                                            toolTipKey={'orderEntryModalForm.swapSidesButton'}
                                            onClickCb={e => {
                                                const currentFrom = fromBuySellFormData;
                                                dispatch(xpFormChangeField(thisFormModel + '.toBuySell'  , currentFrom))
                                                dispatch(xpFormChangeField(thisFormModel + '.fromBuySell', (currentFrom === 'BUY') ? 'SELL' : 'BUY'))
                                            }}
                                        />
                                    </div>
                                    <div style={buySellStyle}>
                                        <XpFormSelectorInput
                                            noTemplate noLabel
                                            field="toBuySell"
                                            selectList={buySellTypes}
                                            defaultValue={"SELL"}
                                            onChangeCallback={value => dispatch(xpFormChangeField(thisFormModel + '.fromBuySell', (value === 'BUY') ? 'SELL' : 'BUY'))}
                                        />
                                    </div>
                                    <div style={currencyStyle}>
                                        <XpFormSelectorInput
                                            isRequired noLabel noTemplate
                                            field="toCurrencyId"
                                            selectList={toCurrencies}
                                            itemTextField="name"
                                            defaultValue={props.toCurrencyId ? props.toCurrencyId : ""}
                                        />
                                    </div>
                                    <div style={amountStyle}>
                                        <XpFormAmountInput
                                            noLabel
                                            field           = "toAmount"
                                            errors={{
                                                lowerThanZero:     (val) => /\d/.test(val) && (stringNumberToFloat(val, decDenLangState) < 0),
                                            }}
                                            disabled={!!fromAmountFormData}
                                        />
                                    </div>

                                </div>

                                <div style={{marginTop: '10px', display: 'flex', alignItems: 'flex-end'}}>

                                    <div style={{width: '140px', marginRight: '5px'}}>
                                        <XpFormSelectorInput
                                            isRequired noTemplate
                                            field="tenor"
                                            selectList={tenors}
                                            itemTextField="name"
                                            defaultValue={'TD'}
                                        />
                                    </div>
                                    <div style={{width: 'calc(100% - 145px)'}}>
                                        <XpFormTextInput
                                            field="clientRef"
                                            maxLength={200}
                                        />
                                    </div>

                                </div>
                            </XpForm>
                        </div>
                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                labelKey        = "orderEntryModalForm.requestPrice"
                                onClickCallback = {() => dispatch(xpFormSubmitForm(thisFormModel))}
                                waiting={false}
                                disabled={!fromAmountFormData && !toAmountFormData}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                labelKey        = 'general.buttons.cancel'
                                onClickCallback = {() => cancelAction()}
                            />
                        </div>
                    </div>}
                </ReactModal>
            </FocusLock>
        </div>
    );
}
