import {clearModalWindows} from "redux-promising-modals";
import {push} from "connected-react-router";

import {setAppReadyState, userLoginSuccess, setLoginMessage} from "gui-common/api/loginProcessReducer";
import {emptySocketQ, wsCloseConnectionThunk, wsOpenConnectionThunk} from "gui-common/api/webSocketThunks";
import {addUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import {ormClearData} from "gui-common/orm/ormReducer";
import {selectAppReadyState, selectUserLoggedInState} from "gui-common/api/apiSelectors";
import {appSpecificOnReadyThunk} from "appConfig/appFunctions";
import {entityStateRequestClearAll} from "gui-common/requestEntityState/requestEntityStateReducer";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";

let loadPath;
let loadApplicationDataThunk;

export function loadDataProcess (targetPath, loadFunction) {
    return (dispatch, getState) => {
        if (loadFunction) loadApplicationDataThunk = loadFunction;
        if (targetPath) {
            loadPath = targetPath; // a bit uggly with global variable loadPath...
        }
        // Open socket connection. When open, the callback function wsOpenCallback will dispatch the data load process.
        dispatch(wsOpenConnectionThunk());
    }
}

export function loadDataFromApi(targetPath) {
    return (dispatch, getState) => {

        if (targetPath) {
            loadPath = targetPath; // a bit uggly with global variable loadPath...
        }
        const appReady  = selectAppReadyState(getState());
        const translate = selectTranslateFunction(getState());
        if (appReady) { // If data load is initiated by reconnecting web-socket, lock application and show loading screen
            dispatch(setAppReadyState(false));
            dispatch(push("/loadingData"));
        }
        dispatch(setLoginMessage(translate("loginProcess.loadingMessage.startDataLoad")));

        console.log("**************************************************");
        console.log("********** Starting data load process ************");
        console.log("**************************************************");

        // Start clearing ORM data
        dispatch(ormClearData());
        // Clear waiting state reducer
        dispatch(entityStateRequestClearAll());
        // Clear modal reducer
        dispatch(clearModalWindows());

        if (!loadApplicationDataThunk) {
            console.error("No loadApplicationDataThunk defined in loadDataFromApi");
            dispatch(loadProcessFailedThunk());
            return;
        }
        dispatch(loadApplicationDataThunk());
    }
}

export function loadProcessSuccessThunk() {
    return (dispatch, getState) => {
        console.log("********** Data load completed ************");
        const appConfigState = getState().appConfigState;
        const userLoggedIn = selectUserLoggedInState(getState());

        const translate = selectTranslateFunction(getState());
        dispatch(setLoginMessage(translate("loginProcess.loadingMessage.dataLoadComplete")));
        if (!userLoggedIn) {
            console.log("Login process completed");
            dispatch(userLoginSuccess());

            const umState = getState().userMessagesState;
            const unconfirmedMessages = umState ? umState.filter(item => ((item.confirmedDateTime === null) && (item.requireConfirmation))) : null;
            if (unconfirmedMessages && (unconfirmedMessages.length > 0)) dispatch(addUserMessageThunk("warning", "userMessages.warning.unconfirmedMessage", {count: unconfirmedMessages.length, linkToUmList: true}));

            dispatch(push(appConfigState.appStartPath));
        }
        else {
            dispatch(setAppReadyState(true));
            dispatch(push(loadPath ? loadPath : appConfigState.appStartPath));
            dispatch(addUserMessageThunk("warning", "userMessages.info.webSocketRunning"));
        }
        const timerDelay = selectAppEnvProperty(getState(), 'socketTimerDelay')
        dispatch(emptySocketQ(true, timerDelay)); // Process any socket messages that were queued during the data load process.
        dispatch(appSpecificOnReadyThunk());
    }
}

export function loadProcessFailedThunk() {
    return (dispatch, getState) => {
        dispatch(wsCloseConnectionThunk());
        dispatch(setAppReadyState(false));
        dispatch(push('/failedDataLoad'));
    }
}
