import {createSelector} from "reselect";

// const selectInstance = (state, props) => props.selectInstance;
// const selectId       = (state, props) => props.selectId;
/*export const getXpAdminFormDataSelector = () => createSelector(
    [formState, selectInstance, selectModel, selectId],
    (formState, selectInstance, selectModel, selectId) => {
        if (!formState || !selectInstance || !selectModel) return undefined;
        if (!formState[selectInstance] || !formState[selectInstance][selectModel]) return undefined;

        if ( selectId && formState[selectInstance][selectModel][selectId]) return formState[selectInstance][selectModel][selectId]; // Selected id is in edit mode.
        if (formState[selectInstance][selectModel].new)                    return formState[selectInstance][selectModel].new;       // Only return a new form if no selectId is provided.

        return undefined;
    }
);*/

const formState      = (state, props) => state.xpFormState.xpAdminForm
const selectModel    = (state, props) => props.selectModel;
export const getXpAdminFormOrmModelDataSelector = () => createSelector(
    [formState, selectModel],
    (formState, selectModel) => {
        if (!formState || !selectModel) return undefined;
        let returnArray = [];
        for (let instanceKey in formState) {
            if (formState[instanceKey][selectModel]) returnArray.push({
                xpAdminFormInstanceId: instanceKey,
                ...formState[instanceKey][selectModel]
            });
        }
        return returnArray;
    }
);


const xpAdminFormConfig = (state, props) => props ? props.xpAdminFormConfig : undefined;
const ormModel          = (state, props) => props ? props.ormModel          : undefined;
const allFormTemplates  = (state, props) => {
    let returnObject = {};
    for (let ormModel in props.xpAdminFormConfig) {
        returnObject[ormModel] = props.xpAdminFormConfig[ormModel].newFormTemplateSelector(state);
    }
    return returnObject;
}
const newFormTemplate = (state, props) => props.xpAdminFormConfig[props.ormModel].newFormTemplateSelector(state, props);

export const getNewXpAdminFormTemplateSelector = () => createSelector(
    [xpAdminFormConfig, ormModel, allFormTemplates, newFormTemplate],
    (xpAdminFormConfig, ormModel, allFormTemplates, newFormTemplate) => {
        if (!newFormTemplate || !xpAdminFormConfig) {
            console.error("No form template or form config to newXpAdminFormTemplateSelector. ", xpAdminFormConfig, ormModel, allFormTemplates, newFormTemplate);
            return undefined;
        }

        function addSubFormTemplates(template, thisOrmModel) {
            const childEntities = xpAdminFormConfig[thisOrmModel].childEntities;
            if (!childEntities) return;
            for (let childConfig of childEntities) {
                if (!childConfig.inParentFormProperty) continue;
                // template[childConfig.arrayProperty] = [{...allFormTemplates[childConfig.ormModel], runConfiguration: newFormTemplate.runConfiguration}];
                template[childConfig.arrayProperty] = [allFormTemplates[childConfig.ormModel]];
                addSubFormTemplates(template[childConfig.arrayProperty][0], childConfig.ormModel);
            }
        }
        addSubFormTemplates(newFormTemplate, ormModel);

        return newFormTemplate;
    }
);
export const newXpAdminFormTemplateSelector = getNewXpAdminFormTemplateSelector();
