import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {NavDropdown} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {hideContextMenu} from "gui-common/contextMenu/contextMenuReducer";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

function StandardContextMenu (props) {
    const dispatch = useDispatch();

    const menuItems = useMemo(
        () => {
            if (Array.isArray(props.menuItems)) return props.menuItems;
            if (typeof props.menuItems === 'function') return props.menuItems(props.data);
            console.error("Invalid menuItems in context menu:", props.menuItems);

            return undefined;
        },
        [props.menuItems]
    );

    if (!menuItems) return null;

    return (
        <ul className="dropdown-menu" style={{display: 'inline'}} id="contextMenuDiv">
            {menuItems.map((menuItem, index) => {
                if (menuItem.isDivider) {
                    return (
                        <NavDropdown.Divider/>
                    )
                }
                return (
                    <NavDropdown.Item
                        key={index}
                        onClick={() => {
                            menuItem.action(props.data);
                            dispatch(hideContextMenu());
                        }}
                    >
                        {menuItem.trKey ? <XpTranslated trKey={menuItem.trKey} trParams={menuItem.trParams}/> : menuItem.name}
                    </NavDropdown.Item>
                )
            })}
        </ul>
    )
}
StandardContextMenu.propTypes = {
    menuItems   : PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};
export default StandardContextMenu
