import {addUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import {apiCreateBodyMap, apiTransformerMap} from "appConfig/api/apiTransformers"
import {ormApiBaseUrlMap} from "appConfig/api/apiConfig";
import {genericFetch} from "gui-common/api/apiCommon";
import {warnAndResolve} from "gui-common/functions/functions";
import {
    ormEntitiesGetFailed, ormEntitiesGetStarted,
    ormEntitiesReceived,
    ormEntityCreated, ormEntityCreateFailed, ormEntityCreateStarted,
    ormEntityDeleted, ormEntityDeleteStarted,
    ormEntityUpdated,
    ormEntityUpdateFailed, ormEntityUpdateStarted
} from "gui-common/orm/ormReducer";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {ormEntityClearLoadAll} from "gui-common/orm/ormLoadingReducer";

export function addMockedData(ormModel, data, dispatch, getState) {
    let transformedResponse = ormItemArrayTransformer(ormModel, data, {getState: getState});
    dispatch(ormEntitiesReceived(ormModel, transformedResponse));
}

export function ormItemArrayTransformer(ormModel, items, params) {
    const transformer = apiTransformerMap[ormModel];
    if (!transformer) {
        console.error("Orm API transformer not defined for ", ormModel);
        return;
    }
    let transformedItems = [];
    for (let item of items) {
        if (params && params.removeExecutionRights && item.executionRight) delete item.executionRight; // data from web socket should not include execution rights.
        transformedItems.push(transformer(item, params));
    }
    return transformedItems;
}


function createRequestBodyItem(item, ormModel, method, getState) {
    const decDenLangState = selectDecDenLangState(getState());
    const transformedItem = apiCreateBodyMap[ormModel](item, decDenLangState, method, getState);
    return transformedItem;
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions for get ORM items for a specific Model including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getOrmItemsFromApi(ormModel, requestId, urlParams) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get " + ormModel;
            let requestUrl = ormApiBaseUrlMap[ormModel];
            if (!requestUrl) {
                console.error("No requestUrl defined for " + ormModel + " in getOrmItemsFromApi");
            }
            else if (ormModel === 'BankAccount') {
                if (!requestId) {
                    warnAndResolve("No requestId for BankAccount in ORM API", resolve);
                    return;
                }
                logText = logText + " for client id=" + requestId;
                requestUrl = requestUrl + "/client/" + requestId;
            }

            requestUrl = requestUrl + "?";
/*
            if (ormModel === 'Client') {
                requestUrl = requestUrl + "includeDisabled=true&";
            }
*/
            if (urlParams) for (let param in urlParams) requestUrl += param + '=' + String(urlParams[param]) + '&';

            dispatch(ormEntitiesGetStarted(ormModel));
            dispatch(genericFetch(
                requestUrl,
                logText,
                ormItemsResponseHandler,
                ormItemsFailHandler,
                ormItemsFailHandler,
                'GET',
                undefined, // no body in http request needed for GET.
                ormModel
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function ormItemsResponseHandler(resp, ormModel) {
    return (dispatch, getState) => {
        let transformedResponse = ormItemArrayTransformer(ormModel, resp, {getState: getState});
        dispatch(ormEntitiesReceived(ormModel, transformedResponse));
    }
}
function ormItemsFailHandler(resp, ormModel) {
    return (dispatch, getState) => {
        dispatch(ormEntitiesGetFailed(ormModel));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Function to get specific ORM item for a Model including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getOrmItemFromApi(ormModel, requestId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!requestId) {
                warnAndResolve("No requestId for get " + ormModel + " item in ORM API", resolve);
                return;
            }

            let logText = "Get " + ormModel + " id: " + requestId;
            let requestUrl = ormApiBaseUrlMap[ormModel] + "/" + requestId + "?";

            dispatch(ormEntityUpdateStarted(ormModel, {id: requestId}));
            dispatch(genericFetch(
                requestUrl,
                logText,
                getOrmItemResponseHandler,
                getOrmItemFailHandler({id: requestId}),
                getOrmItemFailHandler({id: requestId}),
                'GET',
                undefined, // no body in http request needed for GET.
                ormModel
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getOrmItemResponseHandler(resp, ormModel) {
    return (dispatch, getState) => {
        let transformedResponse = apiTransformerMap[ormModel](resp);
        dispatch(ormEntityUpdated(ormModel, transformedResponse));
    }
}
function getOrmItemFailHandler(item) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed(ormModel, item));
        }
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions for add ORM item including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function postOrmItemToApi(ormModel, item, getUrlCallback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            const logText = "Post " + ormModel;

            let requestUrl = ormApiBaseUrlMap[ormModel] + "?";
            if (getUrlCallback) {
                requestUrl = getUrlCallback(requestUrl);
            }

            const decDenLangState = selectDecDenLangState(getState());

            dispatch(ormEntityCreateStarted(ormModel, item));
            dispatch(genericFetch(
                requestUrl,
                logText,
                postOrmItemResponseHandler,
                postOrmItemFailHandler,
                postOrmItemFailHandler,
                'POST',
                apiCreateBodyMap[ormModel](item, decDenLangState, 'POST', getState),
                ormModel
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function postOrmItemResponseHandler(resp, ormModel) {
    return (dispatch, getState) => {
        let transformedResponse = ormItemArrayTransformer(ormModel, Array.isArray(resp) ? resp : [resp], {});
        dispatch(ormEntitiesReceived(ormModel, transformedResponse));
        dispatch(ormEntityCreated(ormModel, transformedResponse[0]));
    }
}
function postOrmItemFailHandler(resp, ormModel) {
    return (dispatch, getState) => {
        dispatch(ormEntityCreateFailed(ormModel));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions for add ORM items including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function postOrmItemsToApi(ormModel, items) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!Array.isArray(items)) {
                warnAndResolve("Not array input for post items " + ormModel + " item in ORM API", resolve);
            }

            const logText = "Post items " + ormModel;

            let requestUrl = ormApiBaseUrlMap[ormModel] + "?";

            const body = items.map(item => createRequestBodyItem(item, ormModel, 'POST', getState));

            dispatch(ormEntityCreateStarted(ormModel, items[0]));
            dispatch(genericFetch(
                requestUrl,
                logText,
                postOrmItemsResponseHandler,
                postOrmItemsFailHandler,
                postOrmItemsFailHandler,
                'POST',
                body,
                ormModel
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function postOrmItemsResponseHandler(resp, ormModel) {
    return (dispatch, getState) => {
        let transformedResponse = ormItemArrayTransformer(ormModel, resp, {});
        dispatch(ormEntitiesReceived(ormModel, transformedResponse));
    }
}
function postOrmItemsFailHandler(resp, ormModel) {
    return (dispatch, getState) => {
        dispatch(ormEntityCreateFailed(ormModel));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions for update orm item including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function putOrmItemToApi(ormModel, item, getUrlCallback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            const logText = "Put " + ormModel;
            let requestUrl;
            if (ormModel === 'User') {
                requestUrl = ormApiBaseUrlMap[ormModel] + "?";
            }
            else {
                if (!item || !item.id) {
                    warnAndResolve("No item.id for put " + ormModel + " item in ORM API", resolve);
                    return;
                }
                requestUrl = ormApiBaseUrlMap[ormModel] + "/" + item.id + "?";
            }
            if (getUrlCallback) {
                requestUrl = getUrlCallback(requestUrl);
            }

            dispatch(ormEntityUpdateStarted(ormModel, item));
            dispatch(genericFetch(
                requestUrl,
                logText,
                putOrmItemResponseHandler,
                putOrmItemFailHandler(item),
                putOrmItemFailHandler(item),
                'PUT',
                createRequestBodyItem(item, ormModel, 'PUT', getState),
                ormModel
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function putOrmItemResponseHandler(resp, ormModel) {
    return (dispatch, getState) => {
        let transformedResponse = apiTransformerMap[ormModel](resp);
        dispatch(ormEntityUpdated(ormModel, transformedResponse));
        // dispatch(addUserMessageThunk("info", "userMessages.info.clientUpdated", {name: transformedResponse.name, id: transformedResponse.id}));
    }
}
function putOrmItemFailHandler(item) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed(ormModel, item));
        }
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions for disable orm item including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function disableOrmItemToApi(ormModel, item) {
    return (dispatch, getState) => {

        const logText = "Disable " + ormModel;
        let requestUrl = ormApiBaseUrlMap[ormModel] + "/disable?";

        dispatch(ormEntityUpdateStarted(ormModel, item));
        dispatch(genericFetch(
            requestUrl,
            logText,
            disableOrmItemResponseHandler(item),
            disableOrmItemFailHandler(item),
            disableOrmItemFailHandler(item),
            'PUT',
            createRequestBodyItem(item, ormModel, 'PUT', getState),
            ormModel
        ));
    }
}
function disableOrmItemResponseHandler(item) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            let transformedResponse = apiTransformerMap[ormModel](resp);
            dispatch(ormEntityUpdated(ormModel, transformedResponse));
            const client = (ormModel === 'Client') ? item : item.client;

            dispatch(addUserMessageThunk("info", "userMessages.info.ormApiMessages.delete." + ormModel, {
                name: transformedResponse.name,
                id: transformedResponse.id
            }, false, client));
        }
    }
}
function disableOrmItemFailHandler(item) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed(ormModel, item));
        }
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions for delete orm item including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function deleteOrmItemToApi(ormModel, item) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!item || (item.id === undefined) || (item.id === null)) {
                warnAndResolve("No item.id for DELETE " + ormModel + " item in ORM API", resolve);
                return;
            }
            const logText = "Delete " + ormModel;
            let requestUrl = ormApiBaseUrlMap[ormModel] + "/" + item.id + "?";
    //        const decDenLangState = selectDecDenLangState(getState());

            dispatch(ormEntityDeleteStarted(ormModel, item));
            dispatch(genericFetch(
                requestUrl,
                logText,
                deleteOrmItemResponseHandler(item),
                deleteOrmItemFailHandler(item),
                deleteOrmItemFailHandler(item),
                'DELETE',
                undefined,
    //            apiCreateBodyMap[ormModel](item, decDenLangState, 'DELETE'),
                ormModel
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function deleteOrmItemResponseHandler(item) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityDeleted(ormModel, item));
        }
    }
}
function deleteOrmItemFailHandler(item) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntityUpdateFailed(ormModel, item));
        }
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions for delete orm items based on url filter parameters including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function deleteOrmItemsToApi(ormModel, urlParams) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Delete items " + ormModel;

            let requestUrl = ormApiBaseUrlMap[ormModel] + "?";
            if (urlParams) for (let param in urlParams) requestUrl += param + '=' + String(urlParams[param]) + '&';

            dispatch(ormEntitiesGetStarted(ormModel));
            dispatch(genericFetch(
                requestUrl,
                logText,
                deleteOrmItemsResponseHandler(),
                deleteOrmItemsFailHandler(),
                deleteOrmItemsFailHandler(),
                'DELETE',
                undefined,
    //            apiCreateBodyMap[ormModel](item, decDenLangState, 'DELETE'),
                ormModel
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

function deleteOrmItemsResponseHandler(item) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            if (!resp) {
                dispatch(ormEntityClearLoadAll(ormModel))
                return;
            }
            let transformedResponse = ormItemArrayTransformer(ormModel, resp, {});
            dispatch(ormEntitiesReceived(ormModel, transformedResponse));
        }
    }
}
function deleteOrmItemsFailHandler(item) {
    return (resp, ormModel) => {
        return (dispatch, getState) => {
            dispatch(ormEntitiesGetFailed(ormModel));
        }
    }
}

