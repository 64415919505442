import {createSelector} from "reselect";


const loginState    = state => state.loginProcessState;

export const selectAppReadyState = createSelector(
    [loginState],
    (loginState) => {
        if (!loginState) return false;
        return (loginState.appReady === true);
    }
);

export const selectUserLoggedInState = createSelector(
    [loginState],
    (loginState) => {
        if (!loginState) return false;
        return (loginState.userLoggedIn === true);
    }
);
