import React, {useMemo} from "react";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {getCashPoolClientParametersFormConfig} from "appConfig/xpAdminFormConfig";

export default function CashPoolClientForm(props) {
    const xpAdminFormConfig = useMemo(
        () => {
            return getCashPoolClientParametersFormConfig();
        },
        []
    );

    if (!props.client) return null;
    return (
        <XpAdminForm
            entityId              = {props.client.id}
            xpAdminFormInstanceId = 'cashPoolClientForm'
            xpAdminFormConfig     = {xpAdminFormConfig}
            ormModel              = 'Client'
            suppressAccordion     = {true}
            hideDeleteButton      = {true}
        />
    )
}
