import {createReducer} from 'gui-common/functions/reducer.js'
import ReconnectingWebSocket from "reconnecting-websocket";
import {wsClosedCallback, wsErrorCallback, wsMessageCallback, wsOpenCallback} from "gui-common/api/webSocketThunks";
import {getWebSessionAwt} from "gui-common/api/webSessionFunctions";
import {setAppReadyState} from "gui-common/api/loginProcessReducer";
import {push} from "connected-react-router";
import {selectWebSessionRefId} from "gui-common/api/webSessionSelectors";

export const SET_WEBSOCKET_HANDLE       = 'SET_WEBSOCKET_HANDLE';
export const RESET_WEBSOCKET_HANDLE     = 'RESET_WEBSOCKET_HANDLE';
export const SET_WEBSOCKET_STATE        = 'SET_WEBSOCKET_STATE';

// Action creators ********************************************************
// ************************************************************************
export function setWebsocketHandle(getState, dispatch) {
    const webSocketConnectionOptions = {
        debug             : false,
        automaticOpen     : true,
        minReconnectionDelay: 500,
    };

    let webSocketHost = window.oxygen_env.WEBSOCKET_HOST;
    if (!webSocketHost) {
        webSocketHost = ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host;
        // console.error("Web socket URL not defined in client.-env.js");
        // return  { type: SET_WEBSOCKET_HANDLE,  payload: undefined};
    }


    const refId = selectWebSessionRefId(getState());

    const getWebSocketUrl = () => {
        const token = getWebSessionAwt(dispatch, getState());
        if (!token) {
            console.error("No web session token available when opening websocket! Logoff!");
            dispatch(setAppReadyState(false));
            dispatch(push('/authenticationFailed'));
            return "";
        }
        return webSocketHost + "/ws/register?referenceId=" + refId + "&awt=" + token.awt + '&sessionId=' + token.sessionId;
    }

    const webSocketHandle = new ReconnectingWebSocket(getWebSocketUrl, [], webSocketConnectionOptions);

    webSocketHandle.addEventListener('open',    (msg)        => dispatch(wsOpenCallback(msg)));
    webSocketHandle.addEventListener('message', (msg) => dispatch(wsMessageCallback(msg)));
    webSocketHandle.addEventListener('close',   (msg)   => dispatch(wsClosedCallback(msg)));
    webSocketHandle.addEventListener('error',   (msg)   => dispatch(wsErrorCallback(msg)));

    return { type: SET_WEBSOCKET_HANDLE,  payload: webSocketHandle};
}
export function resetWebsocketHandle() {
    return { type: SET_WEBSOCKET_HANDLE,  payload: undefined};
}
export function setWebsocketState(socketStatus) {
    return { type: SET_WEBSOCKET_STATE,  payload: socketStatus};
}

// Reducer functions ********************************************************
// ************************************************************************
function setState(state, payload) {
    return {...state, socketStatus: payload};
}
function setHandle(state, payload) {
    return {...state, webSocketHandle: payload};
}
function resetHandle(state, payload) {
    return {...state, webSocketHandle: undefined, socketStatus: "down"};
}

const webSocketInitialState = {
    webSocketHandle:    undefined,
    socketStatus:       "down",
};
export default createReducer(webSocketInitialState, {
    [SET_WEBSOCKET_STATE]    : setState,
    [SET_WEBSOCKET_HANDLE]   : setHandle,
    [RESET_WEBSOCKET_HANDLE] : resetHandle,
});
