import moment from 'moment';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import {formatDateFieldRelativeNow, isMomentObject} from "gui-common/xpGrid/xpGridFunctions";

export const xpIsRequired = (parameterName) => {
//    console.trace();
    throw new Error('Function called without required parameter: ' + parameterName);
};

export const warnAndResolve = (msg, resolve) => {
    console.warn(msg);
    if (resolve) resolve(msg);
};
export const warnAndReject = (msg, reject) => {
    console.error(msg);
    if (reject) reject(msg);
};

export function dateToYMD(date) {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

export function redirectToLogin() {
    let loginUrl = window.oxygen_env.AUTHENTICATION_URL;
    if (!loginUrl) {
        console.error("Login URL not defined in client-env.js");
        loginUrl = "http://www.nordicfc.se";
    }
    window.location.assign(loginUrl);
}

export function padNumberToTwoDigits(num) {
    const s = "0" + num;
    return s.substr(s.length-2);
}

export const setTranslatedError = (trKey, trParams) => {
    return <XpTranslated trKey={trKey} trParams={trParams}/>
};


//***********************************************************************************************************
// Function to check if an item id can be parent to an item without creating a circular tree. Works recursively.
// objectArray: Use the selected array of active objects from orm
// parentToCheck: The object to test
// parentIdIndex: Name of the parentId parameter in the respective object
// checkId: Id of the item to assign parent to.
//!***********************************************************************************************************
export function selectedParentIsChildOrNotActive(objectArray, parentToCheck, parentIdIndex, checkId) {
    if (!objectArray) return true; // if object array is empty, there is no active items
    if (!parentToCheck) return true; // No parent object provided to check. Return true.
    if (!parentIdIndex) return true; // Function will not work if this is null.
    if (!checkId) return false; // If checkId is not provided, all items are OK.

    if (!parentToCheck[parentIdIndex])              return false; // parentItem is not a child at all
    if (parentToCheck[parentIdIndex] === checkId)   return true; // parentItem is child to checkId

    // Find next parent item in tree structure to check
    let parentItem = objectArray.find(item => item.id === parentToCheck[parentIdIndex]);
    if (!parentItem) return true; // parent item id not found in active objectArray, return true (cannot be used as parent)

    // check next level in the tree structure recursively
    return selectedParentIsChildOrNotActive(objectArray, parentItem, parentIdIndex, checkId);
}

export function getEntityFromId(id, entities) {
    if (!entities)          return undefined;
    if (!id)                return undefined;
    if (!entities.length)   return undefined;
    for (let i in entities) {
        if (entities[i].id === Number(id)) return entities[i];
    }
    return undefined;
}

export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
export function isString(n) {
    return (typeof n === 'string' || n instanceof String);
}


export const convertRunTimeFromUTC = (time, keepSeconds) => {
    if (!time) return "";
    if (!time.length) return "";

    let colonIndex = time.indexOf(':'); if (colonIndex === -1) return "Error";
    const hours = time.substring(0, colonIndex);

    let restSting = time.substring(colonIndex + 1);
    colonIndex = restSting.indexOf(':'); if (colonIndex === -1) return "Error";
    const minutes = restSting.substring(0, colonIndex);
    const seconds = restSting.substring(colonIndex + 1);


    let timeUtc = moment.utc(); // get local time as utc moment object
    timeUtc.seconds(Number(seconds));
    timeUtc.hours(Number(hours));
    timeUtc.minutes(Number(minutes));
    let timeL = timeUtc.local();

    let returnString = padNumberToTwoDigits(timeL.hours()) + ":" + padNumberToTwoDigits(timeL.minutes());
    if (keepSeconds) returnString = returnString + ":" + padNumberToTwoDigits(timeL.seconds());

    return returnString;
};
export const stripSecondsFromRunTime = (time) => {
    if (!time) return "";
    if (!time.length) return "";
    const firstColonIndex = time.indexOf(":");
    if (firstColonIndex === -1) return "";
    const secondColonIndex = time.substring(firstColonIndex + 1).indexOf(":");
    if (secondColonIndex === -1) return time;
    const hourMinute = time.substring(0, firstColonIndex + secondColonIndex + 1);
    return hourMinute;
};
export const getStringFromTimeObject = (time) => {
    if (!time) return "";
    if (time.hour === undefined) return "";
    if (time.minute === undefined) return "";
    return padNumberToTwoDigits(time.hour) + ":" + padNumberToTwoDigits(time.minute);
};
export const getTimeObjectFromString = (time) => {
    if (!time) return null;
    if (!time.length) return null;
    const colonIndex = time.indexOf(":");
    return {
        hour: Number(time.substring(0, colonIndex)),
        minute: Number(time.substring(colonIndex +1)),
    }
};
export const getTimeValueFromDateString = (dateString) => {
    if (!dateString) return null;
    if (dateString.length !== 10) return null;
    const year  = Number(dateString.substring(0,4));
    const month = Number(dateString.substring(5,7));
    const day   = Number(dateString.substring(8));
    return year * 500 + month * 40 + day;
};
export function setAuditUserFields(item) {
    if (!item.createdByUser) item.createdByUser = "";
    if (!item.changedByUser) item.changedByUser = "";
    if (!item.modifiedByUser) item.modifiedByUser = "";
    if (!item.disabledByUser) item.disabledByUser = "";
}
export function createMomentObjectsFromDateTimes(item) {
    return {
        createdDateTime : item.createdDateTime  ?  createMomentObjectFromUtcTimestamp(item.createdDateTime)  : null,
        modifiedDateTime: item.modifiedDateTime ?  createMomentObjectFromUtcTimestamp(item.modifiedDateTime) : null,
        changedDateTime : item.changedDateTime  ?  createMomentObjectFromUtcTimestamp(item.changedDateTime)  : null,
        disabledDateTime: item.disabledDateTime ?  createMomentObjectFromUtcTimestamp(item.disabledDateTime) : null,
    };
}


/**
 * Parses an ISO-8601 formatted date in this format only:
 * - "2019-12-03T12:30:00.000-07:00"
 */
const UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
const FAKE = moment()
export function createMomentObjectFromUtcTimestamp(input) {
    const m = {};
    m.__proto__ = moment.prototype;
    // const date = new Date(input.slice(0, 24));
    const date = new Date(input);

    m._isAMomentObject = true;
    m._i = input;
    m._f = UTC_FORMAT;
    m._tzm = 0;
    m._isUTC = false;
    m._locale = FAKE._locale;
    m._d = date;
    m._isValid = true;
    // m._offset = offset;
    return m;
}

export function createMomentObjectsForProperties2(item, propArray) {
    if (!propArray || !propArray.length) return item;
    const returnObject = {};
    for (let prop of propArray) {
        returnObject[prop] = item[prop] ? createMomentObjectFromUtcTimestamp(item[prop]) : null;
    }
    return returnObject;
}
export function createMomentObjectsForProperties(item, propArray) {
    if (!propArray || !propArray.length) return item;
    const returnObject = {};
    for (let prop of propArray) {
        returnObject[prop] = item[prop] ? createMomentObjectFromUtcTimestamp(item[prop]) : null;
        // returnObject[prop] = item[prop] ? moment(item[prop]) : null;
    }
    return returnObject;
}
export function addSecondsToRunTime(hourMinute) {
    hourMinute.replace(/[^0-9:]/g, ''); // strip potential space from form field
    return hourMinute + ":00";
}

export function formatDateField(field, currentLanguage, format) {
    if (field._isAMomentObject === true) {
        field.locale(currentLanguage);
        return field.format(format);
    }
    return field;
}

export function currencyConvert(amount, senderCur, recipientCur, currencyPrices){
    if( recipientCur === senderCur){
        return amount;
    }
    let key;
    for (key in currencyPrices) {
        let pair = currencyPrices[key].currencyPair;
        if(senderCur === pair.substring(0, 3) && recipientCur === pair.substring(3, 6)){
            return amount * currencyPrices[key].rate;
        }
        if(recipientCur === pair.substring(0, 3) && senderCur === pair.substring(3, 6)){
            return amount / currencyPrices[key].rate;
        }
    }
    return amount;
}

export function formIsChanged(form, entityArray, emptyEntity) {
    let formChanged = false;
    let originalEntity;
    if (form.formState === "edit") originalEntity = getEntityFromId(form.id, entityArray);
    else if (form.formState === "new") originalEntity = emptyEntity;

    for (let objKey in originalEntity) {
        if ((form[objKey] === null) || (form[objKey] === undefined)) continue;
        if (typeof originalEntity[objKey] === "object") continue;

        if (originalEntity[objKey] !== form[objKey]) {
            console.log("diff: ", objKey, " originalEntity: ", originalEntity[objKey], " form: ", form[objKey]);
            formChanged = true;
        }
    }
    return formChanged;
}

export function getAccountTypeText(accountType, translate) {
    return translate('accountForm.accountType.values.' + accountType);
}

export function getEntityAdminRecordFromId(entityId, userId, entities) {
    if (!entities) return undefined;
    let length = entities.length;
    for (let i = 0; i < length; i++) {
        if ((entities[i].entityId === entityId) && (entities[i].userId === userId)) {
            return entities[i];
        }
    }
    return undefined;
}

export function getAccountAdminRecordFromId(accountId, userId, accounts) {
    if (!accounts) return undefined;
    let length = accounts.length;
    for (let i = 0; i < length; i++) {
        if ((accounts[i].accountId === accountId) && (accounts[i].userId === userId)) {
            return accounts[i];
        }
    }
    return undefined;
}

export function getAccountFromId(id, accounts) {
    if (!accounts) return undefined;
    let length = accounts.length;
    for (let i = 0; i < length; i++) {
        if (accounts[i].id === Number(id)) {
            return accounts[i];
        }
    }
    return undefined;
}

export function getAccountNameFromId(id, accounts) {
    if (!accounts) return "";
    let length = accounts.length;
    for (let i = 0; i < length; i++) {
        if (accounts[i].id === Number(id)) {
            return accounts[i].name;
        }
    }
    return "";
}

export function thRoundAmount(denominator, amount, decimalSeparator, numberFormat) {
    let numeric = 0;
    if (denominator < 1) {
        let countDecimals = function (value) {
            if ((value % 1) !== 0)
                return value.toString().split(decimalSeparator)[1].length || 0;
            return 0;
        }
        let noOfdecimals = countDecimals(denominator);
        numeric = Number((amount).toFixed(noOfdecimals));
    } else {
        numeric = Math.round(amount / denominator);
        numeric = numeric * denominator;
    }
    return numberFormat ? numeric : numeric.toLocaleString('sv-SE');
}

export function fxConvert(amount, senderCur, recipientCur, currencyPrices) {
    if (recipientCur === senderCur) {
        return amount;
    }
    for (let cur of currencyPrices) {
        if (senderCur === cur.baseCurrency && recipientCur === cur.quoteCurrency) {
            return amount * cur.rate;
        }
        if (senderCur === cur.quoteCurrency && recipientCur === cur.baseCurrency) {
            return amount / cur.rate;
        }
    }
}

export function fxRate(senderCur, recipientCur, fxPrices) {
    if (recipientCur === senderCur) {
        return 1;
    }
    for (let cur in fxPrices) {
        if (senderCur === cur.baseCurrency && recipientCur === cur.quoteCurrency) {
            return cur.rate;
        }
        if (recipientCur === cur.quoteCurrency && senderCur === cur.baseCurrency) {
            return 1 / cur.rate;
        }
    }
}

export const pipe = (...funcs) => v => {
    return funcs.reduce((res, func) => {
        return func(res);
    }, v);
};

export function getDefaultObject(objectList) {
    if (!objectList || objectList.length === 0) return undefined;
    if (objectList.length === 1) return objectList[0];
    const defaultObject = objectList.find(item => ((item.default === true) || (item.isDefault === true)));
    return defaultObject ? defaultObject : objectList[0];
}

export function getDefaultItemPropValue(objectList, propName) {
    const defaultItem = getDefaultObject(objectList);
    return defaultItem ? String(defaultItem[propName]) : "";
}

export function getDefaultItemId(objectList) {
    const defaultItem = getDefaultObject(objectList);
    return defaultItem ? defaultItem.id : "";
}

export function setParentId(item, parentIdProperty, parentId) {
    if (item[parentIdProperty]) return;
    if (parentId) {
        item[parentIdProperty] = parentId;
        return;
    }
    item[parentIdProperty] = "";
}
export function setParentIdIfDefined(item, parentIdProperty, parentId) {
    if (parentId === undefined) return;
    if (parentId === null     ) return;

    item[parentIdProperty] = parentId;
}
export function setParentIdPropertyInCreateBody(item, parentIdProperty, method) {
    if (method !== 'POST') return {};
    if (item[parentIdProperty] === undefined) return {};
    if (item[parentIdProperty] === '') return {};
    if (item[parentIdProperty] === null) return {};
    let returnObject = {};
    returnObject[parentIdProperty] = Number(item[parentIdProperty]);
    return returnObject;
}
export function getNestedIdProperty(item, property) {
    if (!item[property]) return null;
    return item[property].id;
}
export function getNestedIdProperty2(item, property) {
    if (!item[property]) return undefined;
    return item[property].id;
}
export function setNestedIdProperty(item, property) {
    if (!item[property]) return null;
    return {id: item[property]};
}

export function getCurrencyPairString(currencyPair) {
    if (!currencyPair) return "";
    return currencyPair.baseCurrency.currency + currencyPair.quoteCurrency.currency
}

export function setByPath(obj, path, value) {
    const a = path.split('.');
    let o = obj;
    while (a.length - 1) {
        const n = a.shift();
        if (!(n in o)) o[n] = {};
        o = o[n];
    }
    o[a[0]] = value;
}

const buildPatch = (basePath, key, separator) => {
    return basePath ? (basePath + (separator ? separator : ".") + key) : key;
}
export function getFlatObjectValues(obj, separator, basePath) {
    let returnObject = {};

    for (let key in obj) {
        if (typeof obj[key] === 'object') returnObject = {...returnObject, ...getFlatObjectValues(obj[key], separator, buildPatch(basePath, key, separator))};
        else {
            returnObject[buildPatch(basePath, key, separator)] = obj[key];
        }
    }
    return returnObject;
}

// const getDeepProperty = (obj, path) => path.split(".").reduce((object, key) => object && object[key] ? object[key] : null, obj);
export function getByPath(obj, path) {
    path = path.replace(/\[(\w+)\]/g, '.$1');
    path = path.replace(/^\./, '');
    const a = path.split('.');
    let o = obj;
    while (a.length) {
        const n = a.shift();
        if (!(n in o)) return;
        o = o[n];
    }
    return o
}

export function calculateDisplayString(value, format, currentLanguage) {
    if (!value) return "";
    const momentObject = isMomentObject(value) ? value : createMomentObjectFromUtcTimestamp(value);
    return (format === 'relative') ? formatDateFieldRelativeNow(momentObject, currentLanguage) : formatDateField(momentObject, currentLanguage, format);
}

export const oneOfPropsRequired = (propNameArray, propTypeArray) => (props, propName, componentName) => {
    let propCount = 0;
    let propStr = "(";
    let propTypeStr = "(";
    for (let i = 0; i < propNameArray.length; i++) {
        if (props[propNameArray[i]] === undefined) continue;
        propCount++;
        propStr += propNameArray[i] + " ";

        if (typeof props[propNameArray[i]] !== propTypeArray[i]) {
            propStr += propNameArray[i] + " should be of type " + propTypeArray[i];
        }

    }
    propStr += ")";
    propTypeStr += ")";
    if (propCount !== 1)        {return new Error(`Incorrect number of required props '${propStr}' provided to '${componentName}' component.`)}
    if (propTypeStr.length > 2) {return new Error(`Incorrect prop types '${propTypeStr}' provided to '${componentName}' component.`)}
}


export function isElement(o) {
    return (
        typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
            o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
    );
}

