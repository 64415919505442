import React from 'react'
import {withRouter} from "react-router-dom";
import {redirectToLogin} from "gui-common/functions/functions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

function AuthenticationFailed(props) {
    return (
        <div className="welcomeScreen ">
            <h2 className="centeredText"><XpTranslated trKey='authenticationFailed.header' /></h2>
            <div>
                <hr></hr>
                <p className="centeredText"><XpTranslated trKey='authenticationFailed.message'/></p>
                <button className="xPoolButton floatMiddle" onClick={() => redirectToLogin()} autoFocus>
                    <XpTranslated trKey='authenticationFailed.loginButton' />
                </button>
            </div>
        </div>
    );
}
export default withRouter(AuthenticationFailed);
