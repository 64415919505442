import React, {useEffect, useMemo, useState, useRef} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {isString, padNumberToTwoDigits} from "gui-common/functions/functions";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function XpTimeCountdown ({targetTime, outputType, className}) {

    const [timeLeft      , setTimeLeft      ]  = useState(null);
    const [hoursShowed   , setHoursShowed   ]  = useState(false);
    const [minutesShowed , setMinutesShowed ]  = useState(false);
    const translate = useXpTranslateFunction();

/*    const stringIsOkAndReformatted = (str) => {
        let colonIndex = str.indexOf(':'); if (colonIndex === -1) return false;
        let restSting  = str.substring(colonIndex + 1);
        colonIndex = restSting.indexOf(':');
        if (colonIndex === -1) return str + ":00";
        return str;
    };*/

    const momentTargetTime = useMemo(
        () => {
            if (!targetTime) return null;
            if (targetTime.hasOwnProperty('_isAMomentObject')) return targetTime;

            if (isString(targetTime)) {
                return moment(targetTime);

/*
                const okTimeString = stringIsOkAndReformatted(targetTime);
                if (!okTimeString) {
                    console.error("Invalid string sent to XpTimeCountdown", targetTime);
                    return null;
                }
                let targetMoment = moment(okTimeString, 'HH:mm:ss');
                if (targetMoment.isBefore(moment().subtract(10, 'seconds'))) targetMoment = targetMoment.add(1, 'days'); // In some cases the time from the server is a few seconds before now().
                return targetMoment;
*/
            }
            else {
                console.error("Invalid targetTime sent to XpTimeCountdown", targetTime);
                return null;
            }
        },
        [targetTime]
    );

    const momentTimeRef = useRef(momentTargetTime); // Needed to transport updated memo hook to timer scope. Using momentTargetTime directly will not update.
    momentTimeRef.current = momentTargetTime;
    let timerHandle = null;
    const updateTimeLeft = () => {
        if (momentTimeRef.current) {
            let timeNow = moment();
            const diff = moment.duration(momentTimeRef.current.diff(timeNow));
            if (diff.hours())   setHoursShowed(  ()=>{ return true;}); // If once showed, show full countdown.
            if (diff.minutes()) setMinutesShowed(()=>{ return true;}); // If once showed, show full countdown.
            setTimeLeft(diff);
        }
        else setTimeLeft(null);

        timerHandle = setTimeout(()=>{updateTimeLeft()}, 1000);
    };
    useEffect(
        () => {
            if (!timeLeft) updateTimeLeft();
            return () => clearTimeout(timerHandle);
        },
        [],
    );

    const formatTextOutput = (hours, min, sec) => {
        if ((hours < 0) || (min < 0) || (sec < 0)) return translate('general.now');

        let outString = translate('general.inTime');
        if (hoursShowed)   outString = outString + hours + " " + ((hours === 1) ? translate('general.hour'  ) : translate('general.hours'  )) + ", ";
        if (minutesShowed) outString = outString + min   + " " + ((min   === 1) ? translate('general.minute') : translate('general.minutes')) + " " + translate('general.and') + " ";
        outString = outString + sec + " " + ((sec === 1) ? translate('general.second') : translate('general.seconds'));

        return outString;
    };

    const formatDigitalOutput = (hours, min, sec) => {
        let outString = "";
        if ((hours < 0) || (min < 0) || (sec < 0)) {
            outString = translate('general.now');
        }
        else {
            if (hoursShowed) outString =  padNumberToTwoDigits(hours) + ":";
            outString = outString + padNumberToTwoDigits(min) + ":" + padNumberToTwoDigits(sec);
        }
        return "(" + outString + ")";
    };

    const countdownText = useMemo(
        () => {
            if (!timeLeft || !targetTime) return null;
            if (timeLeft.days()) {
                return null; // Do not display countdown more that 24H.
            }
            const hours = timeLeft.hours();
            const min   = timeLeft.minutes();
            const sec   = timeLeft.seconds();

            let outString;
            switch (outputType) {
                case 'digital': outString = formatDigitalOutput(hours, min, sec); break;
                case 'text'   : outString = formatTextOutput(   hours, min, sec); break;
                default       : outString = "No outputType defined!"            ; break;
            }
            return outString;
        },
        [timeLeft, targetTime]
    );

//    if (!countdownText) return null;

    return (
        <div className={className}>
            {countdownText}
        </div>
    )
}
XpTimeCountdown.propTypes = {
    targetTime  : PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    className   : PropTypes.string,
    outputType  : PropTypes.string,
};

XpTimeCountdown.defaultProps = {
    outputType: "digital",
    className : "",
};
export default XpTimeCountdown
