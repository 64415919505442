import React, {useEffect, useRef, useState} from "react";

export default function XpFormFieldGroup (props) {

    const [wasCollapsed , setWasCollapsed ] = useState(!props.isExpanded);
    const [wasExpanded  , setWasExpanded  ] = useState( props.isExpanded);
    const [contentHeight, setContentHeight] = useState( 'auto');

    const contentRef = useRef(null);

    useEffect(
        () => {
            if (props.bypass) return;
            if (!props.disableAnimations) setContentHeight(contentRef.current.scrollHeight + 'px');
        },
        [],
    );

    useEffect(
        () => {
            if (props.bypass) return;
            if (!props.disableAnimations) setContentHeight(contentRef.current.scrollHeight + 'px');
            if (!props.isExpanded) setWasCollapsed(true);
            if ( props.isExpanded) setWasExpanded( true);
            if ( props.isExpanded && !contentHeight && props.disableAnimations) setContentHeight( 'auto');
            if (!props.isExpanded) setContentHeight(0);
            // console.log("Setting from ref and expanded", contentRef.current.scrollHeight, props.isExpanded);
        },
        [props.isExpanded, contentRef.current ? contentRef.current.scrollHeight : null, props.children],
    );

    const animationClassName = (() => {
        if ( props.disableAnimations)          return "";
        if ( props.isExpanded && wasCollapsed) return " animationGrowDown";
        if (!props.isExpanded && wasExpanded ) return " animationShrinkUp";
        return ""
    })();

    function animationEnd() {
        if (wasCollapsed &&  props.isExpanded) setWasCollapsed(false);
        if (wasExpanded  && !props.isExpanded) setWasExpanded( false);
    }

    if (props.bypass) {
        return (<div>{props.children}</div>)
    }
    else {
        const displayClass = 'formFieldsGroup ' + (props.className ? props.className : "") + (props.isExpanded ? ' formFieldsGroupExpanded' : '');
        // console.log("Rendering ", contentHeight, props.isExpanded, wasExpanded);

        return (
            <div className={displayClass}
            >
                <div className='formFieldsGroupController'>
                    {props.controlComponent}
                </div>
                <div className='formFieldsGroupRemoveButton'>
                    {props.removeComponent}
                </div>
                <div
                    style={{height: contentHeight}}
                    className={'formFieldsGroupContentStyle'}
                >
                    <div
                        ref={contentRef}
                        className={animationClassName}
                        onAnimationEnd={() => animationEnd()}
                    >
                        {(props.isExpanded || (wasExpanded && animationClassName)) ? props.children : null}
                    </div>
                </div>
            </div>
        )
    }
}
