import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import UserMessagesList from "gui-common/userMessages/UserMessagesList"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";


class UserMessagesPage extends React.Component {
    render() {
        return (
            <div style={{height: "100%"}}>
                <h2>
                    <XpTranslated trKey='userMessages.header'/>
                </h2>
                <hr></hr>
                <div style={{height: "calc(100vh - 150px)"}}>
                    <UserMessagesList instanceId="userMessagesPageGrid"/>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMessagesPage));
