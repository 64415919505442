import React, {Component} from "react";
import spinnerGif from "gui-common/resources/spinner.gif";
import {connect} from "react-redux";
import {selectDecDenLangState, selectNumberFormatter} from "gui-common/numberFormat/numberFormatSelectors";
import {speedFormatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";


class XpGridNumberRenderer extends Component {

    constructor(props) {
        super(props);

        const renderDataProperty = props.colDef.field + "RenderData";
        this.state = {
            value: props.value,
            renderDataProperty: renderDataProperty,
            isWaiting: props.data[renderDataProperty] ? props.data[renderDataProperty].isWaiting : false,
        };
    }
    refresh(params) {
        const renderData = params.data[this.state.renderDataProperty];
        const isChanged  = (params.value !== this.state.value) || (renderData && (renderData.isWaiting !== this.state.isWaiting));

        if (isChanged) {
            this.setState({
                ...this.state,
                value: params.value,
                isWaiting: renderData ? renderData.isWaiting : false,
            });
        }
        return true;
    }

    getWaiting(){
        if (!this.state.isWaiting) return null;
        return <img className="smallTableSpinner" src={spinnerGif} alt="waiting"/>;
    }

    render () {
        if (this.state.value === undefined) return "";
        if (this.state.value === null)      return <XpTranslated trKey='general.noValueAvailable'/>;
        const numberString = this.props.numberFormatter ? speedFormatAmount(this.state.value, this.props.decDenLangState, this.props.numberFormatter) : this.state.value;
        return (
            <span>
                {this.getWaiting()}
                {numberString}
            </span>
        );
    }
}
const mapStateToProps = (state, props) => {
    return {
        numberFormatter: selectNumberFormatter(state),
        decDenLangState: selectDecDenLangState(state)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(XpGridNumberRenderer);
