import {createSelector} from "reselect";
import {wizardConfig} from "appConfig/wizardConfig";


export const getWizardStateSelector = () => createSelector(
    [
        state => state.wizardState,
        (state, props) => props.wizardName,
    ],
    (wizardState, wizardName) => {
        return wizardState.find(element => element.wizardName === wizardName)
    }
);

export const getWizardDefaultParametersSelector = () => createSelector(
    [
        (state, props) => wizardConfig[props.wizardName].defaultParametersSelector(state, props),
    ],
    (wizardDefaults) => {
        return wizardDefaults
    }
);

export const getWizardLookupValuesSelector = () => createSelector(
    [
        (state, props) => wizardConfig[props.wizardName].lookupValuesSelector(state, props),
    ],
    (wizardDefaults) => {
        return wizardDefaults
    }
);

export const getWizardValidationParamsSelector = () => createSelector(
    [
        (state, props) => wizardConfig[props.wizardName].validationParamsSelector(state, props),
    ],
    (wizardDefaults) => {
        return wizardDefaults
    }
);


/*
//Good selector but not used currently...
export const getWizardCurrentStepSelector = () => {
    const wizardStateSelector = getWizardStateSelector();
    return createSelector(
        [
            (state, props) => wizardStateSelector(state, props)
        ],
        (wizardState) => {
            return wizardState.stepInfo[wizardState.wizardStep];
        }
    );
}
*/


