import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import {formatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import {reformatTimeString} from "gui-common/xpForm/formComponentsValidators";

export const setFormFieldModel = (props) => {
    return "." + props.field;
};
export const setFormFieldString = (props, textType) => {
    if (props.template) return props.template;
    const templateStringTextPath = props.formTemplate + "." + props.field + textType;

    let returnText = props.translate(templateStringTextPath);
    if (returnText.includes("Missing translation")) returnText = props.translate("general" + textType);
    return returnText;
};
export const setFormFieldString2 = (props, translate, textType) => {
    let returnText = translate(props.formTemplate + "." + props.field + textType);
    if (returnText.includes("Missing translation")) returnText = translate("general" + textType);
    return returnText;
};

export const getFormFieldTextPath = (props, textType) => {
    if(props.overRideLabel) {
        return props.overRideLabel + textType;
    }
    return props.formTemplate + "." + props.field + textType;
};
export const setFormFieldTooltipTextPath = (props) => {
    return props.formTemplate + "." + props.field + '.toolTip';
};
export const setErrorMsg = (props, errorType, errorTextPath, checkValue) => {
    if (props[errorType]) return props[errorType];
/*
    let returnText = props.translate(props.formTemplate + "." + props.field + errorTextPath, {checkValue: checkValue});
    if (returnText.includes("Missing translation")) returnText = props.translate("general" + errorTextPath, {checkValue: checkValue});
    return returnText;
*/
    let trKey = props.formTemplate + "." + props.field + errorTextPath;
    let trParams = {checkValue: checkValue};
    const checkTranslation = props.translate(trKey);
    if (checkTranslation.includes("Missing translation")) trKey = "general" + errorTextPath;
    return <XpTranslated trKey={trKey} trParams={trParams}/>
};
export const setErrorMsg2 = (props, errorType, translate, checkValue) => {
    if (props.errorMessages && props.errorMessages[errorType]) return props.errorMessages[errorType];

    let trKey = props.formTemplate + "." + props.field + ".errors." + errorType;
    let trParams = {checkValue: checkValue};
    const checkTranslation = translate(trKey);
    if (checkTranslation.includes("Missing translation")) trKey = "general.errors." + errorType;
    return <XpTranslated trKey={trKey} trParams={trParams}/>
};
export const setFormButtonKey = (props, textType) => {
    return props.formTemplate + ".buttons" + textType;
};
export const setFormLabel = (props, labelTextPath) => {
    let returnText = props.translate(props.formTemplate + labelTextPath);
    return returnText;
};
export const getFormLabelKey = (props, labelTextPath) => {
    return props.formTemplate + labelTextPath;
};
export const accountNumberParser = (val) => {
    if (val === undefined) return "";
    let outString;
    let parseStr = String(val);
    outString = parseStr.replace(/[^0-9]/g, '');
    return outString;
};

const getEntityDataBeforeChange = (entityData, field) => {
    if (!entityData) return undefined;
    return entityData.beforeChange ? entityData.beforeChange[field] : undefined;
}
const getEntityDataAfterChange = (entityData, field, formState) => {
    if (!entityData) return undefined;
    return (formState === "view") ?
        (entityData.current ? entityData.current[field] : undefined) :
        (entityData.form    ? entityData.form[field]    : undefined) ;
}

export function getDifferingTextValueBeforeChange(entityData, formState, field) {
    const beforeChangeValue = getEntityDataBeforeChange(entityData, field)
    if (beforeChangeValue === undefined) return undefined;

    const afterChangeValue = getEntityDataAfterChange(entityData, field, formState);
    if (afterChangeValue === undefined) return undefined;

    if (String(beforeChangeValue).trim() === String(afterChangeValue).trim()) return null;
    return beforeChangeValue;
}

export const getDifferingNumberValueBeforeChange = (entityData, formState, field, decDenLangState) => {
    let beforeChangeValue = getEntityDataBeforeChange(entityData, field)
    if (beforeChangeValue === undefined) return undefined;

    let afterChangeValue = getEntityDataAfterChange(entityData, field, formState);
    if (afterChangeValue === undefined) return undefined;

    beforeChangeValue = formatAmount(beforeChangeValue, decDenLangState);
    afterChangeValue  = formatAmount(afterChangeValue,  decDenLangState);

    beforeChangeValue = beforeChangeValue.replace(/\s/g, '');
    afterChangeValue  = afterChangeValue.replace(/\s/g, '');

    if (beforeChangeValue === afterChangeValue) return undefined;
    return beforeChangeValue;
};

export const getDifferingSelectValueBeforeChange = (entityData, formState, field, selectList, itemTextField) => {
    let beforeChangeValue = getEntityDataBeforeChange(entityData, field)
    if (beforeChangeValue === undefined) return undefined;

    let afterChangeValue = getEntityDataAfterChange(entityData, field, formState);
    if (afterChangeValue === undefined) return undefined;

    if (!afterChangeValue) afterChangeValue = "";
    if ((typeof afterChangeValue === "string") && (typeof beforeChangeValue === "number")) afterChangeValue = Number(afterChangeValue);

    if (beforeChangeValue === afterChangeValue) return null;

    const displayItem = selectList.find(item => item.id === beforeChangeValue);
    if (!displayItem) return beforeChangeValue ? ("(id:"+beforeChangeValue+")") : "()";
    return displayItem[itemTextField];
};

export const getDifferingRadioValueBeforeChange = (entityData, formState, field, textPath) => {
    let beforeChangeValue = getEntityDataBeforeChange(entityData, field)
    if (beforeChangeValue === undefined) return undefined;

    let afterChangeValue = getEntityDataAfterChange(entityData, field, formState);
    if (afterChangeValue === undefined) return undefined;

    if (!afterChangeValue) afterChangeValue = "";

    if (beforeChangeValue === afterChangeValue) return null;

    return <XpTranslated trKey={textPath + beforeChangeValue}/>;
};


export const reformatTimeInputOnChange = (value, currentValue) => {
    if (!value) return "";

    let outString = reformatTimeString(value);
    if ((currentValue === outString) || (currentValue === "")) outString = " " + outString;

    return outString;
};
