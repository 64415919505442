import {createReducer} from "gui-common/functions/reducer";
import cloneDeep from "lodash.clonedeep";
import {
    ORM_ENTITY_CREATE,
    ORM_ENTITY_LOAD_ALL,
    ORM_ENTITY_UPDATE,
    ORM_ENTITY_DELETE,
    ORM_ENTITY_LOAD_BATCH,
    ORM_ENTITY_START_LOAD_ALL,
    ORM_ENTITY_START_UPDATE,
    ORM_ENTITY_START_CREATE,
    ORM_ENTITY_START_DELETE,
    ORM_ENTITY_LOAD_ALL_FAILED,
    ORM_ENTITY_UPDATE_FAILED,
    ORM_ENTITY_CREATE_FAILED,
    ORM_ENTITY_DELETE_FAILED,
    ORM_CLEAR_DB,
    ORM_ENTITY_CLEAR_LOAD_ALL,
    ORM_ENTITY_CLEAR_UPDATE,
} from "gui-common/orm/ormConstants";


export function ormEntityClearLoadAll(model) {
    return {
        type    : ORM_ENTITY_CLEAR_LOAD_ALL,
        payload : {itemType: model}
    }
}
export function ormEntityClearUpdate(model, item) {
    return {
        type    : ORM_ENTITY_CLEAR_UPDATE,
        payload : {itemType: model, item: item}
    }
}

function SetLoadAll (state, payload) {
    if (state[payload.itemType] && state[payload.itemType].loadingAll) return state;

    let newState = cloneDeep(state);
    if (!state[payload.itemType]) newState[payload.itemType] = {};
    newState[payload.itemType].loadingAll = true;
    return newState;
}
function ClearLoadAll (state, payload) {
    if (state[payload.itemType] && (state[payload.itemType].loadingAll === false)) return state;

    let newState = cloneDeep(state);
    if (!state[payload.itemType]) newState[payload.itemType] = {};
    newState[payload.itemType].loadingAll = false;
    return newState;
}

function SetLoadEntityCreate (state, payload) {
    if (state[payload.itemType] && state[payload.itemType].loadingCreate) return state;

    let newState = cloneDeep(state);
    if (!state[payload.itemType]) newState[payload.itemType] = {};
    newState[payload.itemType].loadingCreate = true;
    return newState;
}

function ClearLoadEntityCreate (state, payload) {
    if (state[payload.itemType] && (state[payload.itemType].loadingCreate === false)) return state;

    let newState = cloneDeep(state);
    if (!state[payload.itemType]) newState[payload.itemType] = {};
    newState[payload.itemType].loadingCreate = false;
    return newState;
}

function SetLoadEntityUpdate (state, payload) {
    if (!payload.item || !payload.item.id) return state;
    if (state[payload.itemType] && state[payload.itemType][payload.item.id] && state[payload.itemType][payload.item.id].loadingUpdate) return state;

    let newState = cloneDeep(state);
    if (!newState[payload.itemType]) newState[payload.itemType] = {};
    if (!newState[payload.itemType][payload.item.id]) newState[payload.itemType][payload.item.id] = {};
    newState[payload.itemType][payload.item.id].loadingUpdate = true;
    return newState;
}

function ClearLoadEntityUpdate (state, payload) {
    if (!payload.item || !payload.item.id) return state;
    if (state[payload.itemType] && state[payload.itemType][payload.item.id] && (state[payload.itemType][payload.item.id].loadingUpdate) === false) return state;

    let newState = cloneDeep(state);
    if (!newState[payload.itemType]) newState[payload.itemType] = {};
    if (!newState[payload.itemType][payload.item.id]) newState[payload.itemType][payload.item.id] = {};
    newState[payload.itemType][payload.item.id].loadingUpdate = false;
    return newState;
}

function clearBatch (state, payload) {
    if (!payload.length) return state;

    let newState = cloneDeep(state);

    for (const item of payload) {

        // if (!item.model || !item.itemData || !item.ormEventType) continue;

        if (!newState[item.model]) newState[item.model] = {};

        if (item.ormEventType === 'create') {
            newState[item.model].loadingCreate = false;
            continue;
        }
        if (!newState[item.model][item.itemData.id]) newState[item.model][item.itemData.id] = {};
        newState[item.model][item.itemData.id].loadingUpdate = false;
    }

    return newState;
}

function ResetLoadState (state, payload) {
    return {};
}

export default createReducer({}, {
    [ORM_ENTITY_START_LOAD_ALL]  : SetLoadAll,
    [ORM_ENTITY_LOAD_ALL_FAILED] : ClearLoadAll,
    [ORM_ENTITY_LOAD_ALL]        : ClearLoadAll,
    [ORM_ENTITY_CLEAR_LOAD_ALL]  : ClearLoadAll,

    [ORM_ENTITY_START_CREATE]    : SetLoadEntityCreate,
    [ORM_ENTITY_CREATE_FAILED]   : ClearLoadEntityCreate,
    [ORM_ENTITY_CREATE]          : ClearLoadEntityCreate,

    [ORM_ENTITY_START_UPDATE]    : SetLoadEntityUpdate,
    [ORM_ENTITY_UPDATE_FAILED]   : ClearLoadEntityUpdate,
    [ORM_ENTITY_UPDATE]          : ClearLoadEntityUpdate,
    [ORM_ENTITY_CLEAR_UPDATE]    : ClearLoadEntityUpdate,

    [ORM_ENTITY_START_DELETE]    : SetLoadEntityUpdate,
    [ORM_ENTITY_DELETE_FAILED]   : ClearLoadEntityUpdate,
    [ORM_ENTITY_DELETE]          : ClearLoadEntityUpdate,

    [ORM_ENTITY_LOAD_BATCH]      : clearBatch,

    [ORM_CLEAR_DB]               : ResetLoadState,
});
