import {translateDateFields, xpColCreate} from "gui-common/xpGrid/xpGridFunctions";

export function formatFxPricesGridData(dataArray, translate, currentLanguage) {
    for (let item of dataArray) {
        translateDateFields(item, 'YYYY-MM-DD, HH:mm:ss', currentLanguage);
    }
    return dataArray;
}

export function prepareFxPricesColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'currencyPair', 80, 40, 'fxPrices.currencyPair');
    xpColCreate(translate, subArray, hiddenState, 'rate', 50, 30, 'fxPrices.rate', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'timeT', 100, 30, 'fxPrices.time');

    return subArray;
}
