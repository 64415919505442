import {userSelector} from "gui-common/orm/ormSelectors";

import {setAppReadyState} from "gui-common/api/loginProcessReducer";
import {setActiveClient} from "gui-common/userSettings/userPreferencesReducer";
import {loadDataProcess} from "gui-common/api/loginProcess.js"
import {push} from "connected-react-router";
import {setLoginMessage} from "gui-common/api/loginProcessReducer";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {getOrmItemFromApi} from "gui-common/orm/ormApi";
import {pushModalWindow} from "redux-promising-modals";
import {logoutUserFromApi} from "gui-common/api/userLogoutAPI";
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import {appOnActiveClientThunk} from "appConfig/appFunctions";
import {INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {selectUserPreferencesActiveClientId} from "gui-common/userSettings/userSettingsSelectors";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {entityIsDisabled, getDisableByUser} from "gui-common/audit/auditFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


/* -----------------------------------------------------------------------------------------------------------------
* Reload all data objects from API.
* -----------------------------------------------------------------------------------------------------------------*/
export function reloadDataFromApi(targetPath) {
    return (dispatch, getState) => {
//        const checkedTargetPath = (targetPath === '/failedDataLoad') ? '/cashPool' : targetPath;
        dispatch(setAppReadyState(false));

        const translate = selectTranslateFunction(getState());
        dispatch(setLoginMessage(translate("loginProcess.loadingMessage.startDataLoad")));
        dispatch(push("/loadingData"));
        dispatch(loadDataProcess(targetPath));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Execution rights updated. Inform user and reload data.
* -----------------------------------------------------------------------------------------------------------------*/
export function executionRightsUpdated(model, payload) {
    return (dispatch, getState) => {
        const state = getState();
        const thisUser = userSelector(state);
        if (thisUser.userId !== payload.userId) return; // Not relevant for the logged in user

        dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'general.modalConfirmExecutionRightsUpdated'}))
            .then((result) => {
                // const rerouteUrl   = window.oxygen_env.AUTHENTICATION_URL;
                dispatch(logoutUserFromApi());
            });
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Sets active client, resets all sub forms, resets order list and fetches bank accounts and orders for the active client.
* -----------------------------------------------------------------------------------------------------------------*/
export function setActiveClientThunk(client) {
    return (dispatch, getState) => {
        dispatch(setActiveClient(client));

        if (client) {
            dispatch(xpGridSetSelected('clientForm', client.id));

            // Reset selected line in order list
            dispatch(xpGridSetSelected("cashPoolOrderList", undefined));

            // Reset selected line in active accounts list
            dispatch(xpGridSetSelected('accountSettings-BankAccount-formList', undefined));

            // Set pre-selected client in audit filter page
            const auditFilterForm = getState().xpFormState.auditViewFilterForm;
            if (auditFilterForm && (!auditFilterForm.rootModel || (auditFilterForm.rootModel === 'Client'))) {
                dispatch(xpFormChangeField('auditViewFilterForm.rootObjectId', client.id));
            }

            dispatch(getOrmItemFromApi('Client', client.id))
                .catch(err => {
                    console.error("Get Client failed in setActiveClient: " + err);
                });
        }

        dispatch(appOnActiveClientThunk(client));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Check update message on Client if it is a disable on an active client or if OP-unit is changed to an OP-unit the user is not allowed to see. If so, warn user an set active client to undefined.
* -----------------------------------------------------------------------------------------------------------------*/
export function checkAndHandleUpdatedClient(item) {
    return (dispatch, getState) => {
        if (!item)  return;

/* MT 20210129: This is now handled by BE.
        const myOpUnits = operatingUnitsSelector(getState());
        if (!myOpUnits.find(unit => unit.id === item.operatingUnitId)) { // OP unit on Client is not available to user, delete client from ORM.
            dispatch(pushModalWindow(INFORMATION_DIALOG,
                {
                    modalKey: 'general.modalConfirmationClientOpUnitChangedToNotAuthorized',
                    modalParams: {clientName: item.name},
                }))
                .then(({status}) => {
                    if (status === MODAL_TYPE_CONFIRM) {
                        const rerouteUrl   = window.oxygen_env.AUTHENTICATION_URL;
                        dispatch(logoutUserFromApi(rerouteUrl));
                    }
                });
        }
*/

        if (entityIsDisabled(item)) { // Check if disabled client is the active client
            const activeClientId = selectUserPreferencesActiveClientId(getState());
            if (item.id !== activeClientId) return; // Disable of non-active client. Do nothing here.

            const thisUser = userSelector(getState());
            const modalKey = (thisUser.userId === getDisableByUser(item)) ? 'general.modalConfirmationActiveClientDisabledByMe' : 'general.modalConfirmationActiveClientDisabledByUser';
            dispatch(pushModalWindow(INFORMATION_DIALOG,
                {
                    modalKey   : modalKey,
                    modalParams: {user: getDisableByUser(item)},
                }));
            dispatch(setActiveClientThunk(undefined));
        }
    }
}


export function pushDelayedModal(modalId, modalProps, delay) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                dispatch(pushModalWindow(modalId, modalProps))
                    .then(result => {resolve(result)}).catch(err => {reject(err)})
            }, delay ? delay : 50);
        })
    }
}
