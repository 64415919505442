import moment from "moment";

export const xpoolBusinessObjectConfig = {
    "se.nordicfc.xpool.common.model.official.legal.Client": {
        ownerProperty: 'clientId',
    },
    "se.nordicfc.xpool.common.model.official.legal.CashPool": {
        ownerProperty: 'cashPoolId',
    },
    "se.nordicfc.xpool.common.model.official.legal.BankAccount": {
        ownerProperty: 'accountId',
    },
    "se.nordicfc.xpool.common.model.official.BankAccountRelation": {
        ownerProperty: 'barId',
    }
}
export const xpoolWorkflowPrototypeToWorkFlowMap = {
    'se.nordicfc.xpool.scheduler.workflows.client.ClientWorkflow'                           : "ClientWorkflow",
    'se.nordicfc.xpool.scheduler.workflows.cashflow.CahsFlowWorkflow'                       : "CashPoolWorkflow",
    'se.nordicfc.xpool.scheduler.workflows.bankaccountrelation.BankAccountRelationWorkflow' : "BarWorkflow",
    'se.nordicfc.xpool.scheduler.workflows.balanceretrieval.BalanceRetrievalWorkflow'       : "BalanceWorkflow",
    'se.nordicfc.xpool.scheduler.workflows.orderexecution.OrderExecutionWorkflow'           : "OrderWorkflow",
}

const runStateBackgroundColorFunctionMap = {
    RESTRUCTURING      : params => ('var(--xpool-client-run-status-warning-background-color)'),
    BEFORE_EXECUTION   : params => ('var(--xpool-client-run-status-waiting-for-execution-background-color)'),
    WAIT_FOR_RETRY     : params => ('var(--xpool-client-run-status-warning-background-color)'),
    INITIAL            : params => ('none'),
    WAIT_FOR_BALANCE   : params => ('var(--xpool-client-run-status-fetching-balances-background-color)'),
    WAIT_FOR_EXECUTION : params => ('var(--xpool-client-run-status-executing-background-color)'),
    CANCELLED          : params => ('var(--xpool-client-run-status-warning-background-color)'),
    COMPLETED          : params => {
        if (params.reason === 'ALREADY_EXECUTED') {
            return 'var(--xpool-client-run-status-warning-background-color)';
        }
        if (params.reason === 'PARTIAL') {
            return 'var(--xpool-client-run-status-warning-background-color)';
        }
        return 'var(--xpool-client-run-status-run-ok-background-color)';
    },
    FAILED             : params => ('var(--xpool-client-run-status-failed-background-color)'),
    NONE               : params => ('var(--xpool-client-run-status-failed-background-color)'),
    NOT_SCHEDULED      : params => ('var(--xpool-client-run-status-disabled-background-color)'),
    AFTER_STOP_TRYING  : params => ('var(--xpool-client-run-status-warning-background-color)'),
    NO_RUN_DAY         : params => ('none')
}


/*
const allowCancelMap = {
    RESTRUCTURING      : false,
    BEFORE_EXECUTION   : true,
    WAIT_FOR_RETRY     : true,
    INITIAL            : true,
    WAIT_FOR_BALANCE   : false,
    WAIT_FOR_EXECUTION : false,
    CANCELLED          : false,
    COMPLETED          : false,
    FAILED             : false,
}
*/
/*
const allowCancelWorkflow = (runState) => {
    const returnValue = allowCancelMap[runState];
    return (returnValue !== undefined) ? returnValue : false;
}
*/
const isRunningMap = {
    RESTRUCTURING      : true,
    BEFORE_EXECUTION   : false,
    WAIT_FOR_RETRY     : false,
    INITIAL            : false,
    WAIT_FOR_BALANCE   : true,
    WAIT_FOR_EXECUTION : true,
    CANCELLED          : false,
    COMPLETED          : false,
    FAILED             : false,
}
const getIsRunning = (runState) => {
    const returnValue = isRunningMap[runState];
    return (returnValue !== undefined) ? returnValue : false;
}

/*
const allowActivateWorkflow = (runState) => {
    return runState === 'CANCELLED';
}
*/


export const xpoolWorkflowConfig = {
    ClientWorkflow: {
        runStateParamsTransformer: (params) => ({
            ...params,
            nextRunTime    : params.nextRunTime ? moment(params.nextRunTime).format('HH:mm')  : "",
            nextRunDateTime: params.nextRunTime,
        }),
        getStatusBackgroundColor: clientData => {
            if (!clientData?.cashPoolsRunState || !runStateBackgroundColorFunctionMap[clientData.cashPoolsRunState]) {
                return 'none';
            }
            return runStateBackgroundColorFunctionMap[clientData.cashPoolsRunState](clientData.cashPoolsRunStateParams);
        },
        // allowCancel  : runState => allowCancelWorkflow(runState),
        // allowActivate: runState => allowActivateWorkflow(runState),
        isRunning    : runState => getIsRunning(runState),
    },
    CashPoolWorkflow: {
        runStateParamsTransformer: (params) => ({
            ...params,
        }),
    },
    BarWorkflow: {
        runStateParamsTransformer: (params) => ({
            ...params,
            nextRunTime    : params.nextRunTime ? moment(params.nextRunTime).format('HH:mm:ss')  : "",
            nextRunDateTime: params.nextRunTime,
        }),
        getStatusBackgroundColor: accountData => {
            if (!accountData?.barRunState || !runStateBackgroundColorFunctionMap[accountData.barRunState]) {
                return 'none';
            }
            return runStateBackgroundColorFunctionMap[accountData.barRunState](accountData.barRunStateParams);
        },
        // allowCancel  : runState => allowCancelWorkflow(runState),
        // allowActivate: runState => allowActivateWorkflow(runState),
        isRunning    : runState => getIsRunning(runState),
    },
    BalanceWorkflow: {
        runStateParamsTransformer: (params) => ({
            ...params,
            nextRunTime: params.nextRunTime ? moment(params.nextRunTime).format('HH:mm:ss')  : "",
        }),
        getStatusBackgroundColor: runState => {
            switch (runState) {
                case "INITIAL"            : return 'none';
                case "WAIT_FOR_BALANCE"   : return 'var(--xpool-client-run-status-fetching-balances-background-color)';
                case "WAIT_FOR_RETRY"     :
                case 'CANCELLED'          : return 'var(--xpool-client-run-status-warning-background-color)';
                case "COMPLETED"          : return 'var(--xpool-client-run-status-run-ok-background-color)';
                case "FAILED"             :
                default                   : return 'var(--xpool-client-run-status-failed-background-color)';
            }
        }
    },
    OrderWorkflow: {
        runStateParamsTransformer: (params) => ({
            ...params,
            nextRunTime    : params.nextRunTime ? moment(params.nextRunTime).format('HH:mm:ss')  : "",
            nextRunDateTime: params.nextRunTime,
        })
    }
}
