import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useXpTabSelectedIndex} from "gui-common/components/xpTabs/xpTabsSelectors";
import {useDispatch} from "react-redux";
import {xpTabsSetSelected} from "gui-common/components/xpTabs/xpTabsReducer";
import "./xpTabs.css"
import XpTooltip from "gui-common/components/XpTooltip";
import {useSpring} from "@react-spring/web";

function getId(index, instanceId) {
    return 'xpTab-' + instanceId + '-' + index;
}

export function XpTabs(props) {

    const dispatch = useDispatch();
    const selectedIndex = useXpTabSelectedIndex(props.instanceId);

    useEffect(
        () => {
            if (selectedIndex === undefined) {
                dispatch(xpTabsSetSelected(props.instanceId, (props.defaultIndex !== undefined) ? props.defaultIndex : 0));
            }
        },
        [],
    );

    const handleChange = (event, index) => {
        // Handle tab selection on tab click instead.
    };

    function handleTabClick(index) {
        if (index !== selectedIndex) {
            dispatch(xpTabsSetSelected(props.instanceId, index));
            if (props.onChange) {
                props.onChange(index);
            }
            return;
        }
        if (props.disableNoneSelectable) {
            return;
        }
        dispatch(xpTabsSetSelected(props.instanceId, false));
        if (props.onChange) {
            props.onChange(false);
        }
    }

    return (
        <div style={{
            display: props.vertical ? 'flex' : undefined,
            flexDirection: props.tabsToRight ? 'row-reverse' : undefined,
            height: props.fillHeight ? '100%' : undefined,
        }} >
            <Tabs
                value={(selectedIndex === undefined) ? 0 : selectedIndex}
                onChange={handleChange}
                orientation={props.vertical ? 'vertical' : 'horizontal'}
                selectionFollowsFocus
                variant="scrollable"
                scrollButtons="auto"
                sx={props.vertical ? {
                    minWidth: props.menuWidth,
                    maxWidth: props.menuWidth,
                    bgcolor: 'unset',
                    borderBottom: 'none',
                    borderRadius: 0,
                    paddingRight: props.tabsToRight ? '5px' : undefined,
                    paddingLeft : props.tabsToRight ? undefined: '5px',
                } : undefined}
                TabIndicatorProps={ props.vertical ? {
                    style: {
                        left: (props.vertical && !props.tabsToRight) ? "0px" : undefined,
                    }
                } : undefined}
            >
                {props.children.map((tab, index) => {
                    return (
                        <Tab
                            label={tab.props.label}
                            icon={tab.props.icon ? (
                                <XpTooltip trKey={tab.props.tooltipTrKey} bypass={!tab.props.tooltipTrKey}>
                                    {tab.props.icon}
                                </XpTooltip>
                            ) : undefined}
                            {...{id: getId(index, props.instanceId), 'aria-controls': getId(index, props.instanceId)}}
                            key={index}
                            onClick={() => handleTabClick(index)}
                            sx={props.vertical ? {
                                minWidth: 50,
                                color: 'var(--xpool-secondary-color-mid)',
                            } : undefined}
                        />
                    )
                })}
            </Tabs>
            {props.children.map((tab, index) => {
                return (
                    <div
                        hidden={selectedIndex !== index}
                        id={`xpTab-${props.instanceId}-${index}`}
                        aria-labelledby={`xpTab-${props.instanceId}-${index}`}
                        className={props.contentClass}
                        key={index}
                        style={{
                            width: props.menuWidth ? 'calc(100% - ' + props.menuWidth + 'px)' : undefined,
                            height: props.fillHeight ? '100%' : undefined,
                            overflow: 'hidden',
                        }}
                    >
                        {(selectedIndex === index) &&
                        <div
                            style={{
                                height: props.fillHeight ? '100%' : undefined,
                            }}
                        >
                            {tab}
                        </div>}
                    </div>
                )
            })}
        </div>
    )
}
XpTabs.propTypes = {
    instanceId  : PropTypes.string.isRequired,
    contentClass: PropTypes.string,
    defaultIndex: PropTypes.number,
    disableNoneSelectable: PropTypes.bool,
    vertical: PropTypes.bool,
    menuWidth: PropTypes.number,
    tabsToRight: PropTypes.bool,
    onChange: PropTypes.func,
    fillHeight: PropTypes.bool
};

export function XpTab(props) {
    return props.children
}
XpTab.propTypes = {
    label: PropTypes.node,
    icon: PropTypes.node,
    tooltipTrKey: PropTypes.string,
};
