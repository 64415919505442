import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navbar} from "react-bootstrap";

import refreshIcon  from 'gui-common/resources/refresh3.png'
import warningLight from 'gui-common/resources/warningLight.gif'
import spinnerGif   from "gui-common/resources/croppedSpinner.gif"

import XpTooltip from "gui-common/components/XpTooltip";
import {loadDataFromApi} from "gui-common/api/loginProcess";
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import PropTypes from "prop-types";
import IntegrationStatusMenuPanel from "gui-common/integrationStatus/IntegrationStatusMenuPanel";
import {selectWebSocketStatus} from "gui-common/api/webSessionSelectors";
import BankHolidayIndicator from "gui-common/bankHolidays/BankHolidayIndicator";


function XpMenuTitle ({logo, appName}) {
    const webSocketStatus = useSelector(selectWebSocketStatus);
    const appReady        = useSelector(selectAppReadyState);
    const router          = useSelector(state => state.router);
    const appStartPath    = useSelector(state => state.appConfigState.appStartPath);
    const dispatch        = useDispatch();

    function getRefreshDataTip(){
        if (webSocketStatus === "running" ) return 'topMenu.reloadButton.tooltip';
        if (webSocketStatus === "down"    ) return 'topMenu.reloadButton.socketDown';
        if (webSocketStatus === "starting") return 'topMenu.reloadButton.socketStarting';
    }
    function getRefreshIcon() {
        if (webSocketStatus === "running" ) return refreshIcon;
        if (webSocketStatus === "down"    ) return warningLight;
        if (webSocketStatus === "starting") return spinnerGif;
    }

    return (
        <Navbar.Brand>
            <div style={{verticalAlign: 'top'}}>
                <XpTooltip trKey={getRefreshDataTip()}>
                    <img
                        src={getRefreshIcon()} alt={"Refresh"} className="refreshIcon"
                        onClick={() => dispatch(loadDataFromApi(appReady ? router.location.pathname : appStartPath))}
                    />
                </XpTooltip>
                <IntegrationStatusMenuPanel/>
                <XpTooltip trKey='general.appBuildInfo' trParams={{build: window.oxygen_env.BUILD_NUMBER, branch: window.oxygen_env.BRANCH_NAME, commit: window.oxygen_env.COMMIT_HASH}}>
                    <img  src={logo} alt={appName ? appName : "App logo"} className="NFCLogo"/>
                </XpTooltip>
                <div style={{display: 'inline-block', position: 'relative', top: '-2px'}}>
                    <div style={{display: 'inline-block', width: '35px', marginLeft: '5px'}}>
                        <BankHolidayIndicator/>
                    </div>
                </div>
            </div>
        </Navbar.Brand>
    )
}

XpMenuTitle.propTypes = {
    logo:    PropTypes.string.isRequired,
    appName: PropTypes.string
};
XpMenuTitle.defaultProps = {
};

export default XpMenuTitle
