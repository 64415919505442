import {useSelector} from "react-redux";
import {usersSelector} from "gui-common/orm/ormSelectors";
import React, {useMemo} from "react";
import {getEntityFromId} from "gui-common/functions/functions";
import XpTooltip from "gui-common/components/XpTooltip";
import PropTypes from "prop-types";
import {buildUserProfilesString} from "gui-common/user/userFunctions";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

export function DisplayOperatingUser({userIdString}) {
    const translate = useXpTranslateFunction();
    const users = useSelector(usersSelector);

    const user = useMemo(
        () => {
            if (!userIdString) return undefined;
            return getEntityFromId(Number(userIdString), users);
        },
        [users, userIdString]
    );
    const toolTip = useMemo(
        () => {
            if (!user) return "";
            let returnString = translate('userPreferencesForm.userId.label') + " " + user.userId;
            returnString += "\n" + translate('userPreferencesForm.userName') + " " + user.firstName + " " + user.lastName;
            returnString += "\n" + translate("userPreferencesForm.profiles");
            returnString += buildUserProfilesString(user, translate);

            return returnString
        },
        [user, translate]
    );

    if (!user) return null;
    const userString = " (" + user.firstName + " " + user.lastName + ")";
    return (
        <XpTooltip title={toolTip}>
            <span>{userString}</span>
        </XpTooltip>
    )
}

DisplayOperatingUser.propTypes = {
    userIdString   : PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
