import React from 'react'
import 'features/onboardingWizard/onboardingWizard.css';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {createSelectItems} from "gui-common/functions/hooks";

import {useSortedAccountsArray} from "features/onboardingWizard/forms/ObWizardAccountSettingsForm";
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import RunConfigurationForm from "gui-common/runConfiguration/RunConfigurationForm";
import RunTimesTable from "xpool-gui-common/appConfig/runConfiguration/RunTimesTable";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormFieldGroup from "gui-common/xpForm/XpFormFieldGroup";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";

const runTypeConfig = {
    DAILY: {
        formTemplate: 'runConfiguration.dailyRun',
        configType: 'dailyRun'
    },
    MONTHLY : {
        formTemplate: 'runConfiguration.monthlyRun',
        configType: 'monthlyRun'
    }
}

function ClientSettingsForm(props) {
    const clientRunType  = useXpFormField('clientRunType');
    const clientRunTypes  = createSelectItems(['DAILY', 'MONTHLY']);
    return (
        <div className="wizardFormCard">
            <h3><XpTranslated trKey='onboardingWizardForm.clientRunTimesSettings'/></h3>

            <div>
                <XpFormSelectorInput
                    inLineDivider={60}
                    inLineLayout
                    field            = "clientRunType"
                    selectList       = {clientRunTypes}
                    itemTextField    = "name"
                    noTemplate
                    autoFocus        = {true}
                />
            </div>

            {runTypeConfig[clientRunType] &&
            <XpFormFieldset
                formTemplate={runTypeConfig[clientRunType].formTemplate}
            >
                <RunConfigurationForm
                    type     = {runTypeConfig[clientRunType].configType}
                    ormModel = {'BankAccount'}
                    userCanManage={true}
                    enforceExactlyOneRequired={true}
                />
            </XpFormFieldset>}
        </div>
    )
}

function RunTimesAndOrderAccountForm(props) {
    const overrideRunTimes = useXpFormField('overrideRunTimes');
    const useMaxMinSplit   = useXpFormField('useMaxMinSplit');
    return (
        <div>
            <h3 className='wizardFormCardHeader'><XpTranslated trKey='onboardingWizardForm.currencyAccountForm.accountHeader' trParams={{currency: props.account.currencyName}}/></h3>

            <XpFormFieldGroup
                isExpanded={overrideRunTimes}
                controlComponent={
                    <XpFormSwitchInput
                        noMargin
                        field= "overrideRunTimes"
                    />
                }
            >
                <XpFormFieldset
                    formTemplate="runConfiguration.runTimesTable"
                >
                    <RunTimesTable/>
                </XpFormFieldset>
            </XpFormFieldGroup>

            <XpFormFieldGroup
                isExpanded={useMaxMinSplit}
                controlComponent={
                    <XpFormSwitchInput
                        noMargin
                        field= "useMaxMinSplit"
                    />
                }
            >
                <XpFormFieldset
                    formTemplate="runConfiguration.maxMinSplit"
                >
                    <RunConfigurationForm
                        type     = {'maxMinSplit'}
                        ormModel = {'BankAccount'}
                        userCanManage={true}
                        ownedByParent={true} // Must set this to make sweep and topUp for set currencyId from bank bankAccount (not available in wizard case)
                        enforceExactlyOneRequired={true}
                    />
                </XpFormFieldset>
            </XpFormFieldGroup>
        </div>
    )
}

function ObWizardRuntimeSettingsForm(props) {
    const accountsArray = useSortedAccountsArray()

    return (
        <div className={"adminFormContainer"}>
            <ClientSettingsForm/>
            {accountsArray.map((account, index) => {
                if (index === 0) return null;
                return (
                    <div className="wizardFormCard" key={index}>
                        <XpFormFieldset
                            field={'currencies.' + account.originalIndex}
                            formTemplate="onboardingWizardForm"
                        >
                            <RunTimesAndOrderAccountForm account={account} index={index}/>
                        </XpFormFieldset>
                    </div>
                )
            })}
        </div>
    )
}
export default ObWizardRuntimeSettingsForm;
