import React from 'react'
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import EntityBaseStaticFormComponent from "gui-common/xpAdminForm/EntityBaseStaticFormComponent";
import ClientBaseStatic from "features/client/formComponents/ClientBaseStatic";
import LegalEntityBaseStatic from "xpool-gui-common/features/legalEntity/formComponents/LegalEntityBaseStatic";
import LegalEntityUnitBaseStatic from "xpool-gui-common/features/legalEntityUnit/formComponents/LegalEntityUnitBaseStatic";

function ObWizardClientBasicForm(props) {
    return (
        <div className="formCard">
            <XpFormFieldset formTemplate='clientForm'>
                <div className={"adminFormContainer"}>
                    <div className={"adminColumn admin3Column"}>
                        <EntityBaseStaticFormComponent enforceUseCanEdit suppressNameAutofocus/>
                    </div>
                    <div className={"adminColumn admin3Column"} style={{paddingTop: '7px'}}>
                        <ClientBaseStatic enforceUseCanEdit/>
                    </div>
                    <div className={"adminColumn admin3Column"} style={{paddingTop: '7px'}}>
                        <XpFormFieldset formTemplate='legalEntityForm'>
                            <LegalEntityBaseStatic enforceUseCanEdit/>
                        </XpFormFieldset>
                        <XpFormFieldset formTemplate='legalEntityUnitForm'>
                            <LegalEntityUnitBaseStatic enforceUseCanEdit bypassFieldGroup/>
                        </XpFormFieldset>
                    </div>
                </div>
            </XpFormFieldset>
        </div>
    );
}
export default ObWizardClientBasicForm;
