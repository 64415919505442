export const XP_ACCORDION_SHOW         = 'XP_ACCORDION_SHOW';
export const XP_ACCORDION_RELOAD_STATE = 'XP_ACCORDION_RELOAD_STATE';
export const XP_ACCORDION_RESET_STATE = 'XP_ACCORDION_RESET_STATE';

export function xpAccordionShow(name, show) {
    return {type: XP_ACCORDION_SHOW, payload: {name: name, show: show}}
}
export function xpAccordionReloadState(newState) {
    return {type: XP_ACCORDION_RELOAD_STATE, payload: newState}
}
export function xpAccordionResetState() {
    return {type: XP_ACCORDION_RESET_STATE}
}

export function xpAccordionReducer(state = {}, action)
{
    switch (action.type) {
        case XP_ACCORDION_SHOW:
            let newState = {...state};
            newState[action.payload.name] = action.payload.show;
            return newState;
        case XP_ACCORDION_RELOAD_STATE:
            return action.payload;
        case XP_ACCORDION_RESET_STATE:
            return {};
        default:
            return state;

    }
}
