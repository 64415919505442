import React from 'react'
import {useSelector} from "react-redux";
import 'gui-common/wizard/wizard.css';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getWizardStateSelector} from "gui-common/wizard/wizardSelectors";
import {useXpFormRootFormData} from "gui-common/xpForm/core/xpFormHooks";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function sortStepsAsc(a, b) {
    return a > b ? 1 : b > a ? -1 : 0;
}

function WizardSummary(props) {
    const translate       = useXpTranslateFunction();
    const thisWizardState = useSelectorInstance(getWizardStateSelector, props);
    const wizardFormData  = useXpFormRootFormData(props.wizardFormName);

    function getRenderValue(stepInfo, fieldName, value) {
        if (typeof value === "boolean")      return (translate('wizardSummary.' +  (value ? 'true' : 'false' )));
        if(!stepInfo.fieldLookup || !stepInfo.fieldLookup[fieldName]) return value;

        const lookArray    = props.lookupValues[stepInfo.fieldLookup[fieldName]];
        if (!lookArray) {
            console.error("Non existing lookup in wizard summary", stepInfo.fieldLookup[fieldName]);
            return value
        }
        const lookupObject = lookArray.find(item => item.id === parseInt(value, 10));
        return lookupObject ? lookupObject.name : value;
    }

    function resultRow(fieldHeading, fieldValue, key){
        return (
            <tr key={key}>
                <td className="summaryFieldText">{fieldHeading}</td>
                <td className="summaryFieldValue">{fieldValue}</td>
            </tr>
        );
    }
    function groupingRow(key, groupValue) {
        return (
          <tr key={key}>
              <td colSpan={2} className="groupingRow">
                  {groupValue}
              </td>
          </tr>
        );
    }
    function includeValue(fieldValue) {
        return fieldValue !== undefined && fieldValue !== "" && fieldValue !== null;
    }
    function allEmpty(subItem, fieldList) {
        let empty = true;
        for(let item of fieldList){
            if(subItem.hasOwnProperty(item) && subItem[item]){
                empty = false;
                break;
            }
        }
        return empty;
    }
    function arrayString(arr){
        let outStr = "";
        for(let str in arr) {
            outStr += arr[str];
            if(str < arr.length -1 ) {
                outStr += ", ";
            }
        }
        return outStr;
    }
    function subRows(topFieldName, subItems, stepInfo) {
        let renderArray = [];
        let strippedSubFields = stepInfo.subFields.filter(subField => subField !== stepInfo.summaryGroupField);
        for (let [index, subItem] of subItems.entries()) {
            if (stepInfo.excludedSubGroupingValue && stepInfo.summaryGroupField && (wizardFormData[stepInfo.excludedSubGroupingValue] === subItem[stepInfo.summaryGroupField])) {
                continue;
            }
            if (allEmpty(subItem, stepInfo.subFields)){
                continue;
            }
            let groupValue = getRenderValue(stepInfo, stepInfo.summaryGroupField, subItem[stepInfo.summaryGroupField]);
            renderArray.push(groupingRow(stepInfo.summaryGroupField + '_' + index, groupValue));

            for(let subField of strippedSubFields) {
                let fieldHeading = getFieldHeader(stepInfo, subField, props.wizardFormName + '.' + props.wizardSubFormName);
                let fieldValue   = getRenderValue(stepInfo, subField, subItem[subField]);
                if(Array.isArray(fieldValue)){
                    fieldValue = arrayString(fieldValue);
                }
                if(includeValue(fieldValue)) {
                    renderArray.push(resultRow(fieldHeading, fieldValue, index + '-' + subField + '-' + fieldValue));
                }
            }
        }
        return renderArray
    }

    function getFieldHeader(stepInfo, field, trKeyBase) {
        return <XpTranslated trKey={(stepInfo.trKeyBase ? stepInfo.trKeyBase : trKeyBase) + '.' + field + '.label'}/>;
    }
    function fieldRow(field, stepInfo) {
        if (wizardFormData[field] === undefined) return null;

        if(Array.isArray(wizardFormData[field])) return subRows(field, wizardFormData[field], stepInfo);

        if (stepInfo.excludeFieldFromSummaryFunction && stepInfo.excludeFieldFromSummaryFunction[field] && stepInfo.excludeFieldFromSummaryFunction[field](wizardFormData)) return null;

        const fieldHeading = getFieldHeader(stepInfo, field, props.wizardFormName);
        let fieldValue = getRenderValue(stepInfo, field, wizardFormData[field]);

        return resultRow(fieldHeading, fieldValue, field+'-'+fieldValue)
    }

    function fieldInfo(stepInfo) {
        if (!stepInfo?.fields?.length) return null;
        return(
            <table className="summaryFields">
                <tbody>
                    {stepInfo.fields.map(field => fieldRow(field, stepInfo ))}
                </tbody>
            </table>
        );
    }


    function summarySection(stepInfo, index) {
        return (
            <div className="wizardSummaryStepContainer"  style={{gridColumn: (index + 1)}} key={'summary-section-' + index}>
                <div>
                    <div className="summaryText">
                        <XpTranslated trKey={props.wizardName + '.' + stepInfo.heading}/>
                    </div>
                    <div className="wizardSummaryStep">
                        {fieldInfo(stepInfo)}
                    </div>
                </div>
            </div>
        );
    }

    function emptySection(stepInfo, completedSize, remainingSize, index) {
        if(index === remainingSize - 1) return null;
        return (
          <div className="wizardSummaryStepContainer"  style={{gridColumn: (completedSize + index + 1)}} key={'empty-section-' + index}>
              &nbsp;
          </div>
        );
    }

    if(!thisWizardState?.completedSteps?.length) return null;

    let completedSteps = thisWizardState.completedSteps.sort(sortStepsAsc).map(stepNo => thisWizardState.stepInfo[stepNo]);
    let remainingSteps = thisWizardState.stepInfo.filter((item, index) => !thisWizardState.completedSteps.includes(index));

    return (
        <div>
            <hr/>
            <div className="wizardSummaryContainer" xp-test-id="wizardSummaryContainer" style={{gridTemplateColumns: ('repeat(' + thisWizardState.stepInfo.length + ' 1fr)')}}>
                {completedSteps.map((stepInfo, index) => summarySection(stepInfo, index))}
                {remainingSteps.map((stepInfo, index) => emptySection(stepInfo, completedSteps.length, remainingSteps.length, index))}
            </div>
        </div>
    );
}

export default WizardSummary;
