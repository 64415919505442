import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";

export const getErpIntegrationSelector = () => ormCreateSelector(
    orm, dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, erpIntegrationId) => {
        if (!erpIntegrationId) return undefined;
        const erpIntegrationModel = getOrmModelObjectById(session, 'ErpIntegration', erpIntegrationId);
        return {
            ...erpIntegrationModel.ref,
        }
    }
);
