import {getClientAdminFormConfig} from "appConfig/xpAdminFormConfig";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {clientsSelector} from "xpool-gui-common/features/client/clientSelectors";
import {getHistoricAuditConfig} from "gui-common/audit/auditFunctions";

export const appSpecificAuditPrototypeMap = {
    'se.nordicfc.xpool.common.model.audits.v1.ClientAudit'          : { model: 'Client', rootObjectSortOrder: 1},
    'se.nordicfc.xpool.common.model.audits.v1.LegalEntityAudit'     : { model: 'LegalEntity' },
    'se.nordicfc.xpool.common.model.audits.v1.LegalEntityUnitAudit' : { model: 'LegalEntityUnit' },
    'se.nordicfc.xpool.common.model.audits.v1.BankAccountAudit'     : { model: 'BankAccount' },
};

const commonHistoricAuditData = {
    excludeField: {
        XP_V243: ['createdDateTime', 'createdByUser', 'changedDateTime', 'changedByUser', 'disabledDateTime', 'disabledByUser', 'executionRight', 'executionRights', 'userCanSetPoolingParameters'],
        XP_V30: ['createdDateTime', 'createdByUser', 'changedDateTime', 'changedByUser', 'disabledDateTime', 'disabledByUser', 'executionRight', 'executionRights', 'userCanSetPoolingParameters'],
    },
    skipParentLevels: {
        XP_V243: [],
        XP_V30: [],
    }
}
function localGetHistoricAuditConfig(data) {
    return getHistoricAuditConfig(data, commonHistoricAuditData);
}
export const appSpecificAuditConfig = {
    Client: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit',getXpAdminFormConfig: getClientAdminFormConfig},
        isRoot            : true,
        rootProps         : {
            showBefore    : true,
            selector      : clientsSelector, // Root objects provided from BE, but objects created or deleted during the session was not in the data load.
            selectorProps : {},
            getRootAuditProps: (rootObject) => {
                let bankAccounts = [];
                if (rootObject?.legalEntities?.length) {
                    rootObject.legalEntities.forEach(le => {
                        if (!le.legalEntityUnits?.length) return;
                        le.legalEntityUnits.forEach(leu => {
                            if (!leu.bankAccounts?.length) return;
                            leu.bankAccounts.forEach(ba => bankAccounts.push(ba));
                        })
                    })
                }
                return {
                    clientBankAccounts: bankAccounts,
                }
            }
        },
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['XP_V243'],
                excludeFields:    ['legalEntities', 'stopSwappingAfter', 'shortName', 'userCanViewCashPool', 'userCanViewLending', 'userCanAddEntities', 'userCanAddAccounts', 'userCanAdminLedger', 'userCanAdminPool', 'userCanAdminLending', 'userCanAdminUsers', 'userCanSetClientStatic', 'schedulingState', 'nettingRule', 'orgNumber'],
                skipParentLevels: [],
            },
            {
                apiVersions: ['XP_V30'],
                excludeFields:    ['legalEntities', 'nettingRule', 'orgNumber'],
                skipParentLevels: [],
            }
        ]),
    },
    LegalEntity: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit', getXpAdminFormConfig: getClientAdminFormConfig},
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['XP_V243'],
                excludeFields:    ['legalEntityUnits', 'subsidiaries', 'userCanSetEntityStatic'],
                skipParentLevels: ['legalEntities'],
            },
            {
                apiVersions: ['XP_V30'],
                excludeFields:    ['legalEntityUnits', 'subsidiaries', 'runConfiguration', 'erpIntegration'],
                skipParentLevels: ['legalEntities'],
            }
        ]),
    },
    LegalEntityUnit: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit', getXpAdminFormConfig: getClientAdminFormConfig},
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['XP_V243'],
                excludeFields:    ['bankAccounts', 'userCanCreateBankAccount', 'userCanDisable', 'userCanUpdate'],
                skipParentLevels: ['legalEntities', 'legalEntityUnits'],
            },
            {
                apiVersions: ['XP_V30'],
                excludeFields:    ['bankAccounts', 'runConfiguration', 'restTimeZone'],
                skipParentLevels: ['legalEntities', 'legalEntityUnits'],
            }
        ]),
    },
    BankAccount: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit', getXpAdminFormConfig: getClientAdminFormConfig},
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['XP_V243'],
                excludeFields:    ['childAccounts', 'userCanSetBalance', 'userCanSetAccountStatic', 'bankAccountBalance', 'accountSchedulingState'],
                skipParentLevels: ['legalEntities', 'legalEntityUnits', 'bankAccounts'],
            },
            {
                apiVersions: ['XP_V30'],
                excludeFields:    ['childAccounts', 'ledgerAccount', 'postPoolingAccount', 'pendingAccount', 'erpTransactions'],
                skipParentLevels: ['legalEntities', 'legalEntityUnits', 'bankAccounts'],
            }
        ]),
    },
}
export const appSpecificAuditModelsFromRootModel = {
    "Client": [
        "Client",
        "LegalEntity",
        "LegalEntityUnit",
        "BankAccount"
    ],
/*
    "BankHoliday": [
        "BankHoliday",
    ],
    "AuditUserActionType": [
        "AuditUserActionType",
    ],
*/
}
