import React, {useMemo} from 'react'
import 'features/onboardingWizard/onboardingWizard.css';
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";

function ObWizardBaseCurrencyForm(props) {
    const formCurrencies  = useXpFormField('currencies')

    const selectList = useMemo(
        () => {
            return formCurrencies.map(item => ({id: item.currencyId, currencyName: item.currencyName}));
        },
        [formCurrencies]
    );
    return (
        <div className={"adminFormContainer"}>
            <div className="wizardFormCard">
                <XpFormSelectorInput
                    field= "topCurrencyId"
                    selectList={selectList}
                    itemTextField="currencyName"
                    isRequired
                    autoFocus={true}
                />
            </div>
        </div>
    );
}
export default ObWizardBaseCurrencyForm;
