import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {
    balanceMovementSources,
    balanceMovementStates,
    balanceMovementTypes
} from "xpool-gui-common/features/balanceMovement/balanceMovementConstants";

export function balanceMovementTransformer(item, params) {
    return item;
}

export function createBalanceMovementBody(item, decDenLangState, method) {
    return {
        id: item.id,
        accountId: item.accountId,
        type: item.type,
        amount: item.amount,
        valueDate: item.valueDate,
        comment: item.comment,
    }
}

export function prepareBalanceMovementsColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id'        , 100, 50, 'balanceMovementsList.headers.id');
    xpColCreate(translate, subArray, hiddenState, 'bankAccountId' , 100, 50, 'balanceMovementsList.headers.accountId');
    xpColCreate(translate, subArray, hiddenState, 'typeT'     , 150, 50, 'balanceMovementsList.headers.type', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: balanceMovementTypes.map(item => 'balanceMovementsList.balanceMovementTypes.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState, 'amount'    , 150, 50, 'balanceMovementsList.headers.amount', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'valueDate' , 100, 50, 'balanceMovementsList.headers.valueDate');
    xpColCreate(translate, subArray, hiddenState, 'stateT'    , 150, 50, 'balanceMovementsList.headers.state', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: balanceMovementStates.map(item => 'balanceMovementsList.balanceMovementStates.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState, 'sourceT'    , 150, 50, 'balanceMovementsList.headers.source', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: balanceMovementSources.map(item => 'balanceMovementsList.balanceMovementSources.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState, 'comment'   , 100, 50, 'balanceMovementsList.headers.comment');

    xpColCreate(translate, subArray, hiddenState, 'createdDateTime', 150, 50, 'general.createdDateTimeInFlatGrid' , {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState, 'createdByUser'  , 120, 50, 'general.createdByUserInFlatGrid'   , {cellRenderer: 'xpGridUserRenderer'});

    return subArray;
}
