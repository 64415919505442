import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    AreaChart, Area
} from "recharts";
import {XpDateTime} from "gui-common/components/XpDateTime";
import {formatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import moment from "moment";

function XpMiniLineChart (props) {

    const decDenLangState = useSelector(selectDecDenLangState);

    const chartProps = useMemo(
        () => {
            return {
                // height: '100%',
                margin: {top : 5, right : 5, left : 5, bottom : 0}
            };
        },
        []
    );

    const lineConfig = useMemo(
        () => {
            return {
                type                : "monotone",
                // type                : "stepAfter",
                name                : props.lineConfig.lineInstanceId,
                fill                : "url(#gradient)",
                fillOpacity         : 0.9,
                strokeWidth         : 1,
                activeDot           : {r: 2},
                animationDuration   : 500,
                ...props.lineConfig,
            };
        },
        [props.lineConfig]
    );

    const areaGradients = useMemo(
        () => {
            return createAreaGradients(lineConfig);
        },
        [lineConfig]
    );


    // const ChartComponent = LineChart;
    // const LineComponent = Line;
    const ChartComponent = AreaChart;
    const LineComponent = Area;

    return (
        <ResponsiveContainer height='100%' width='100%'>
            <ChartComponent {...chartProps} data={lineConfig.data} o>
                {props.areaFill && areaGradients}
{/*
                <XAxis
                    stroke="lightgray"
                    tick={false}
                    // dataKey="xIndex"
                    // type='number'
                    // allowDataOverflow={true}
                    domain={['dataMin', 'dataMax']}
                    // interval={0}
                    axisLine={false}
                />
*/}
                <YAxis
                    stroke={"lightgray"}
                    // tick={false}
                    domain={['dataMin', 'dataMax']}
                    tick={<CustomizedYAxisTick displayYTicks = {props.displayYTicks}/>}
                    width={props.displayYTicks ? 60 : 0}
                    tickLine={props.displayYTicks}
                    axisLine={false}
                />


                <LineComponent
                    key={lineConfig.lineInstanceId}
                    {...lineConfig}
                    dataKey={lineConfig.dataKey}
                    // fillOpacity={1}
                    dot={false}
                    activeDot={lineConfig.activeDot}
                />

                <Tooltip
                    content={props.tooltipContentFn ? e => props.tooltipContentFn(e, lineConfig) : e => renderToolTip(e, decDenLangState, lineConfig)}
                    cursor={{ stroke: 'rgba(0,0,0,0.15)', strokeWidth: 1 , strokeDasharray:'1 1'}}
                />

            </ChartComponent>
        </ResponsiveContainer>
    )
}
XpMiniLineChart.propTypes = {
    lineConfig       : PropTypes.object.isRequired,
    tooltipContentFn : PropTypes.func,
    displayYTicks    : PropTypes.bool,
};
export default XpMiniLineChart

function CustomizedYAxisTick(props) {
    const decDenLangState = useSelector(selectDecDenLangState);
    if (props.payload?.value === undefined) return null;
    if (!props.displayYTicks) return null;
    const displayValue = props.formatAmount ? formatAmount(props.payload.value, decDenLangState) : props.payload.value;
    return (
        <g transform={`translate(${props.x},${props.y})`}>
            <text x={-2} y={4} dy={0} textAnchor="end" fill="#666" fontSize={12}>
                {displayValue}
            </text>
        </g>
    );
}


const defaultStopsConfig = [{offset: 0, stopOpacity: 0.8}, {offset: 0.9, stopOpacity: 0.05}]
function createAreaGradients(lineConfig) {
    if (lineConfig.areaGradient) return lineConfig.areaGradient;
    return (
        <defs>
            <linearGradient id={"gradient"} x1="0" y1="0" x2="0" y2="1" key={lineConfig.lineInstanceId}>
                {(lineConfig.gradientStopsConfig ? lineConfig.gradientStopsConfig : defaultStopsConfig).map((stopConfig, stopIndex) =>
                    <stop key={lineConfig.lineInstanceId + stopIndex} offset={stopConfig.offset} stopColor={stopConfig.color ? stopConfig.color : lineConfig.stroke} stopOpacity={stopConfig.stopOpacity}/>
                )}
            </linearGradient>
        </defs>
    )
}

function renderToolTip(props, decDenLangState, lineConfig) {
    const {payload} = props;
    if (!payload?.length || !payload[0]?.payload) return null;

    const valueObject = payload[0];
    const momentDate = moment(valueObject.payload[lineConfig.dateField]);

    return (
        <div className="xpMiniChartToolTipContainer">
            <div style={{width: '100%', textAlign: 'center'}}>
                <XpDateTime isoString={momentDate} format='ddd D/M'/> :
                {lineConfig.formatNumber ? formatAmount(valueObject.value, decDenLangState) : valueObject.value.toFixed(3)}
            </div>
        </div>
    );
}

