import {
    xpoolNestedOrmEntities,
    xpoolOrmModelParentProperties,
    xpoolOrmModels
} from "xpool-gui-common/appConfig/orm/ormConfig";

export const appSpecificNestedOrmEntities = {
    ...xpoolNestedOrmEntities,
};

export const appSpecificOrmModelParentProperties = {
    ...xpoolOrmModelParentProperties,
};

export const appSpecificOrmModelCompareFunctions = {
};
export const appSpecificOrmModels = [
    ...xpoolOrmModels,
];
