import {createStandardAuditFields2, xpColCreate, xpColHeadCreate} from "gui-common/xpGrid/xpGridFunctions";

export function canLegalEntityUnitBeDisabled(legalEntityUnit) {
    let returnObject = {
        canBeDisabled: true,
        modalKey: 'legalEntityUnitForm.modalConfirmDisableLegalEntityUnit',
        modalParams: {name: legalEntityUnit.name}
    };

    if (legalEntityUnit.bankAccounts && legalEntityUnit.bankAccounts.length) {
        let childrenNames = "";
        for (let account of legalEntityUnit.bankAccounts) {
            childrenNames = childrenNames + "- " + account.name + "\n";
        }
        returnObject.canBeDisabled = false;
        returnObject.modalKey = 'legalEntityUnitForm.modalLegalEntityUnitHasAccounts';
        returnObject.modalParams   = {name: legalEntityUnit.name, children: childrenNames};
        return returnObject;
    }
    // LegalEntityUnit can be disabled!
    return returnObject;
}
export function prepareLegalEntityUnitsGridColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'name', 120, 50, 'legalEntityUnitForm.name.label');
    xpColCreate(translate, subArray, hiddenState, 'description', 250, 50, 'legalEntityUnitForm.description.label');
    xpColCreate(translate, subArray, hiddenState, 'id', 80, 30, 'legalEntityUnitForm.id');
    xpColCreate(translate, subArray, hiddenState, 'fxProviderName', 200, 50, 'legalEntityUnitForm.fxProviderId.label');
    xpColCreate(translate, subArray, hiddenState, 'fxShortNameT', 150, 50, 'legalEntityUnitForm.fxShortName.label');
    xpColHeadCreate(translate, outArray, subArray, 'legalEntityUnitsList.entityInformation');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}
