import {getNestedIdProperty, setNestedIdProperty, stripSecondsFromRunTime} from "gui-common/functions/functions";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import HoldOnRateForm from "xpool-gui-common/appConfig/runConfiguration/HoldOnRateForm";
import SchedulingForm from "xpool-gui-common/appConfig/runConfiguration/SchedulingForm";
import MonthlyRunForm from "xpool-gui-common/appConfig/runConfiguration/MonthlyRunForm";
import DailyRunForm from "xpool-gui-common/appConfig/runConfiguration/DailyRunForm";
import MaxMinSplitForm from "xpool-gui-common/appConfig/runConfiguration/MaxMinSplitForm";
import SweepAndTopUpForm from "xpool-gui-common/appConfig/runConfiguration/SweepAndTopUpForm";
import {
    userCanEditHoldOnRate,
    userCanEditMinMax,
    userCanEditPoolParams,
    userCanEditRuntimes
} from "gui-common/functions/userRights";
import {createSelector} from "reselect";
import HedgeExposureForm from "xpool-gui-common/appConfig/runConfiguration/HedgeExposureForm";
import {bankAccountSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";


function addSecondsToRunTime(hourMinute) {
    hourMinute.replace(/[^0-9:]/g, ''); // strip potential space from form field
    return hourMinute + ":00";
}

function buildOutboundRunTimes(config) {
    return {
        getBalancesTime : config.getBalancesTime    ? addSecondsToRunTime(config.getBalancesTime)   : null,
        executeTime     : config.executeTime        ? addSecondsToRunTime(config.executeTime)       : null,
        stopTryingTime  : config.stopTryingTime     ? addSecondsToRunTime(config.stopTryingTime)    : null,
        // timeZone        : config.timeZone,
    }
}

function buildInboundRunTimes(config) {
    return {
        getBalancesTime : config.getBalancesTime    ? stripSecondsFromRunTime(config.getBalancesTime)   : "",
        executeTime     : config.executeTime        ? stripSecondsFromRunTime(config.executeTime)       : "",
        stopTryingTime  : config.stopTryingTime     ? stripSecondsFromRunTime(config.stopTryingTime)    : "",
        // timeZone        : config.timeZone,
    }
}

export const xpoolRunConfigurationConfig = {
    dailyRun: {
        formComponent     : DailyRunForm,
        useAccordion      : true,
        newTemplate       : {isActive: true, getBalancesTime: '', executeTime: '', stopTryingTime: '', runOnMondays: true, runOnTuesdays: true, runOnWednesdays: true, runOnThursdays: true, runOnFridays: true, runLiquidity: true, runHedge: false},
        listDependentFields: {parentAccountId: 'parentAccountId'},
        formDependentFields: {executeTime: 'executeTime'},
        getLabelKey       : (formContext, fields) => formContext.formTemplate + '.isActive.label',
        getLabelParams    : (formContext, fields) => {return {executeTime: fields.executeTime}},
        getFormTemplate   : (formContext, fields) => 'runConfiguration',
        hideConfigType    : (formContext, fields) => (formContext.ormModel === 'BankAccount') && !fields.parentAccountId,
        inputTransformer  : (config, params) => ({
            ...config,
            ...buildInboundRunTimes(config)
        }),
        outputTransformer : (config, params) => ({
            runOnMondays    : config.runOnMondays,
            runOnTuesdays   : config.runOnTuesdays,
            runOnWednesdays : config.runOnWednesdays,
            runOnThursdays  : config.runOnThursdays,
            runOnFridays    : config.runOnFridays,
            runLiquidity    : config.runLiquidity,
            runHedge        : config.runHedge,
            ...buildOutboundRunTimes(config),
        }),
        userCanManage     : (rootObjectData) => userCanEditRuntimes(rootObjectData),
    },
    monthlyRun: {
        formComponent  : MonthlyRunForm,
        useAccordion   : true,
        newTemplate    : {isActive: true, getBalancesTime: '', executeTime: '', stopTryingTime: '', runType: 'RUN', dayType: 'FIRST', runDay: 1, seekStrategy: 'LATER', runLiquidity: true, runHedge: false},
        listDependentFields: {parentAccountId: 'parentAccountId'},
        formDependentFields: {dayType: 'dayType', runDay: 'runDay'},
        getLabelKey    : (formContext, fields) => {
            switch (fields.dayType) {
                case 'FIRST': return formContext.formTemplate + '.isActiveFirst';
                case 'LAST' : return formContext.formTemplate + '.isActiveLast';
                case 'DAY'  : return formContext.formTemplate + '.isActiveDay';
                default: return '';
            }
        },
        getLabelParams : (formContext, fields) => {return {runDay: fields.runDay}},
        getFormTemplate: (formContext, fields) => 'runConfiguration',
        hideConfigType : (formContext, fields) => {
            return (formContext.ormModel === 'BankAccount') && !fields.parentAccountId
        },
        inputTransformer  : (config, params) => ({
            ...config,
            ...buildInboundRunTimes(config),
        }),
        outputTransformer : (config, params) => ({
            runType         : config.runType        ? config.runType        : undefined, // “RUN”, “NO_RUN”
            dayType         : config.dayType        ? config.dayType        : undefined, // “FIRST”, “LAST”, “DAY”
            runDay          : config.runDay         ? Number(config.runDay) : undefined, // 1 - 31
            seekStrategy    : config.seekStrategy   ? config.seekStrategy   : undefined, // “EARLIER”, “LATER”, “EXACT”
            runLiquidity    : config.runLiquidity,
            runHedge        : config.runHedge,
            ...buildOutboundRunTimes(config),
        }),
        userCanManage     : (rootObjectData) => userCanEditRuntimes(rootObjectData),
    },
    maxMinSplit: {
        formComponent  : MaxMinSplitForm,
        useAccordion   : true,
        newTemplate    : {isActive: true, useFor: 'ALL', minimumTradeSize: "", maximumTradeSize: "", useSplit: false, splitPercent: ""},
        listDependentFields: {parentAccountId: 'parentAccountId'},
        formDependentFields: {useFor: 'useFor'},
        getLabelKey    : (formContext, fields) => {
            switch (fields.useFor) {
                case 'ALL'   : return formContext.formTemplate + '.isActiveAll';
                case 'SWEEP' : return formContext.formTemplate + '.isActiveSweep';
                case 'TOP_UP': return formContext.formTemplate + '.isActiveTopUp';
                default: return '';
            }
        },
        getFormTemplate: (formContext, fields) => 'runConfiguration',
        hideConfigType : (formContext, fields) => (formContext.ormModel === 'BankAccount') && !fields.parentAccountId,
        inputTransformer  : (config, params) => ({
            ...config,
            currencyId    : getNestedIdProperty(config, 'currency'),
            minimumTradeSize : (config.minimumTradeSize !== null)      ? config.minimumTradeSize : "",
            maximumTradeSize : (config.maximumTradeSize !== null)      ? config.maximumTradeSize : "",
            useSplit         : (config.useSplit)                       ? config.useSplit         : false,
            splitPercent     : (config.splitPercent     !== null)      ? config.splitPercent     : "",
        }),
        outputTransformer : (config, params) => ({
            currency         : setNestedIdProperty(config, 'currencyId'),
            useFor           : config.useFor, // “ALL”, “SWEEP”, "TOP_UP"
            minimumTradeSize : config.minimumTradeSize ? stringNumberToFloat(config.minimumTradeSize, params.decDenLangState) : null,
            maximumTradeSize : config.maximumTradeSize ? stringNumberToFloat(config.maximumTradeSize, params.decDenLangState) : null,
            useSplit         : config.useSplit,
            splitPercent     : config.splitPercent ? stringNumberToFloat(config.splitPercent, params.decDenLangState) : '',
        }),
        userCanManage     : (rootObjectData) => userCanEditMinMax(rootObjectData),
    },
    holdOnRate: {
        formComponent  : HoldOnRateForm,
        useAccordion   : true,
        newTemplate    : {isActive: true, useFor: 'ALL', holdAboveRate: "", holdBelowRate: ""},
        listDependentFields: {parentAccountId: 'parentAccountId', currencyId: 'currencyId'},
        formDependentFields: {useFor: 'useFor'},
        getLabelKey    : (formContext, fields) => {
            switch (fields.useFor) {
                case 'ALL'   : return formContext.formTemplate + '.isActiveAll';
                case 'SWEEP' : return formContext.formTemplate + '.isActiveSweep';
                case 'TOP_UP': return formContext.formTemplate + '.isActiveTopUp';
                default: return '';
            }
        },
        getFormTemplate: (formContext, fields) => 'runConfiguration',
        hideConfigType : (formContext, fields) => {
            if (formContext.ormModel !== 'BankAccount') return true;
            if (!fields.parentAccountId) return true;
            return (formContext.ormModel === 'BankAccount') && !fields.parentAccountId
        },
        getHideRunTypeSelector: () => createSelector(
            [
                (state, props) => props.dependentFields,
                (state, props) => props.formContext,
                (state, props) => bankAccountSelector(state, {selectId: props.dependentFields.parentAccountId}),
            ],
            (dependentFields, formContext, parentBankAccount) => {
                if (formContext.auditMode) return false;
                if (parentBankAccount === undefined) return true;
                if (parentBankAccount.currencyId === dependentFields.currencyId) return true;
                return false;
            }),
        inputTransformer  : (config, params) => ({
            ...config,
            currencyPairId   : getNestedIdProperty(config, 'currencyPair'),
            holdAboveRate    : (config.holdAboveRate !== null) ? config.holdAboveRate : "",
            holdBelowRate    : (config.holdBelowRate !== null) ? config.holdBelowRate : "",
        }),
        outputTransformer : (config, params) => ({
            currencyPair     : setNestedIdProperty(config, 'currencyPairId'),
            useFor           : config.useFor, // “ALL”, “SWEEP”, "TOP_UP"
            holdAboveRate : config.holdAboveRate ? Number(config.holdAboveRate) : null,
            holdBelowRate : config.holdBelowRate ? Number(config.holdBelowRate) : null,
        }),
        userCanManage     : (rootObjectData) => userCanEditHoldOnRate(rootObjectData),
    },
    sweepAndTopUp: {
        formComponent  : SweepAndTopUpForm,
        useAccordion   : false,
        exactlyOneRequired: true,
        newTemplate    : {isActive: true, useSweep: true, sweepThreshold: "", sweepTarget: "", useSwapForSweep: false, useTopUp: true, topUpTarget: "", topUpThreshold: "", useSwapForTopUp: false, sweepHorizon: ""},
        listDependentFields: {parentAccountId: 'parentAccountId'},
        formDependentFields: {dayType: 'dayType', runDay: 'runDay'},
        getLabelKey    : (formContext, fields) => formContext.formTemplate + '.isActive.label',
        getFormTemplate: (formContext, fields) => 'runConfiguration',
        hideConfigType : (formContext, fields) => (formContext.ormModel === 'BankAccount') && !fields.parentAccountId,
        inputTransformer  : (config, params) => ({
            ...config,
            currencyId    : getNestedIdProperty(config, 'currency'),

            sweepHorizon  : (config.sweepHorizon           ) ? config.sweepHorizon   : "",

            sweepThreshold: (config.sweepThreshold !== null) ? config.sweepThreshold : "",
            sweepTarget   : (config.sweepTarget    !== null) ? config.sweepTarget    : "",
            topUpThreshold: (config.topUpThreshold !== null) ? config.topUpThreshold : "",
            topUpTarget   : (config.topUpTarget    !== null) ? config.topUpTarget    : "",
        }),
        outputTransformer : (config, params) => ({
            currency        : setNestedIdProperty(config, 'currencyId'),

            sweepHorizon    : Number(config.sweepHorizon),

            useSweep        : config.useSweep,
            sweepThreshold  : stringNumberToFloat(config.sweepThreshold, params.decDenLangState),
            sweepTarget     : stringNumberToFloat(config.sweepTarget   , params.decDenLangState),
            useSwapForSweep : config.useSwapForSweep,

            useTopUp        : config.useTopUp,
            topUpThreshold  : stringNumberToFloat(config.topUpThreshold, params.decDenLangState),
            topUpTarget     : stringNumberToFloat(config.topUpTarget   , params.decDenLangState),
            useSwapForTopUp : config.useSwapForTopUp,
        }),
        userCanManage     : (rootObjectData) => userCanEditPoolParams(rootObjectData),
    },
    scheduling: {
        formComponent  : SchedulingForm,
        useAccordion   : false,
        exactlyOneRequired: true,
        newTemplate    : {isActive: true},
        getLabelKey    : (formContext, fields) => formContext.formTemplate + '.isActive.label',
        getFormTemplate: (formContext, fields) => 'runConfiguration',
        hideConfigType : undefined,
        inputTransformer  : (config, params) => (config),
        outputTransformer : (config, params) => (config),
        userCanManage     : (rootObjectData) => userCanEditRuntimes(rootObjectData),
    },
    hedgeExposure: {
        formComponent  : HedgeExposureForm,
        useAccordion   : true,
        newTemplate    : {isActive: true, minimumHedgeSize: "", maximumHedgeSize: "", hedgePercent: 100, hedgeRollType: 'MONTHLY', hedgeStrategyType: 'LAYERED'},
        listDependentFields: {parentAccountId: 'parentAccountId'},
        formDependentFields: {},
        getLabelKey    : (formContext, fields) => formContext.formTemplate + '.isActive.label',
        getFormTemplate: (formContext, fields) => 'runConfiguration',
        hideConfigType : (formContext, fields) => {
            return (formContext.ormModel === 'BankAccount') && !fields.parentAccountId
        },
        inputTransformer  : (config, params) => ({
            ...config,
            currencyId    : getNestedIdProperty(config, 'currency'),
            minimumHedgeSize : (config.minimumHedgeSize !== null)      ? config.minimumHedgeSize : "",
            maximumHedgeSize : (config.maximumHedgeSize !== null)      ? config.maximumHedgeSize : "",
            hedgePercent     : (config.hedgePercent     !== null)      ? config.hedgePercent     : "",
            hedgeStrategyType: 'LAYERED',
        }),
        outputTransformer : (config, params) => ({
            currency         : setNestedIdProperty(config, 'currencyId'),
            hedgeRollType    : config.hedgeRollType,
            minimumHedgeSize : config.minimumHedgeSize ? stringNumberToFloat(config.minimumHedgeSize, params.decDenLangState) : null,
            maximumHedgeSize : config.maximumHedgeSize ? stringNumberToFloat(config.maximumHedgeSize, params.decDenLangState) : null,
            // hedgePercent     : config.hedgePercent     ? Number(config.hedgePercent) : '',
            hedgePercent     : config.hedgePercent     ? config.hedgePercent : '',
        }),
        userCanManage     : (rootObjectData) => true,
        // userCanManage     : (rootObjectData) => userCanEditHedgeExposure(rootObjectData),
    },
}
