import PropTypes from "prop-types";
import React, {useMemo} from "react";

import XpMuiIconButton from "gui-common/components/XpMuiIconButton";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {pushModalWindow} from "redux-promising-modals";
import {ORDER_ENTRY_DIALOG} from "appConfig/appModals";
import {
    useActiveClientLE0Accounts,
    useBaseCurrency
} from "xpool-gui-common/features/currencyDashboard/currencyDashboardFunctions";
import {useDispatch} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";

function TradeCurrencyButton (props) {

    const dispatch  = useDispatch();
    const accounts = useActiveClientLE0Accounts();
    const baseAccount = useBaseCurrency();

    const toCurrencyId = useMemo(
        () => {
            if (!accounts || accounts?.length < 2) {
                return undefined;
            }
            if (baseAccount) {
                return baseAccount.id;
            }
            return accounts[0].currency.id;
        },
        [baseAccount, accounts]
    );

    const fromCurrencyId = useMemo(
        () => {
            if (!toCurrencyId || !props.currency || (toCurrencyId === props.currency.id)) {
                return undefined;
            }
            return props.currency.id;
        },
        [toCurrencyId, props.currency]
    );

    if (!props.currency) return null;

    return props.useIcon ? (
        <XpMuiIconButton
            icon={CurrencyExchangeIcon}
            color={'var(--xpool-primary-color-strong)'}
            size={25}
            className={'currencyTradeIcon'}
            toolTipKey={'currencyDashboard.bankAccount.trade.toolTip'}
            toolTipParams={{currency: props.currency.currency}}
            onClickCb={e => {
                dispatch(pushModalWindow(ORDER_ENTRY_DIALOG, {fromCurrencyId: fromCurrencyId, toCurrencyId: toCurrencyId}));
                e.preventDefault();
                e.stopPropagation();
            }}
        />
    ) : (
        <WaitingXpoolButton
            className       = "xPoolButtonSmallPure"
            labelKey        = 'currencyDashboard.bankAccount.trade.button'
            labelParams     = {{currency: props.currency.currency}}
            onClickCallback = {() => {
                dispatch(pushModalWindow(ORDER_ENTRY_DIALOG, {fromCurrencyId: fromCurrencyId, toCurrencyId: toCurrencyId}));
            }}
            toolTipKey      = 'currencyDashboard.bankAccount.trade.toolTip'
            toolTipParams   = {{currency: props.currency.currency}}
        />
    )
}
TradeCurrencyButton.propTypes = {
    currency: PropTypes.object.isRequired,
    useIcon : PropTypes.bool
};
export default TradeCurrencyButton



