import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

export default class RemoveFileDialog extends Component {

    static propTypes = {
        fileName: PropTypes.string.isRequired,
        resultTypes: PropTypes.object,
        popModalWindow: PropTypes.func.isRequired
    };

    render() {
        const {fileName, resultTypes: {MODAL_TYPE_CONFIRM, MODAL_TYPE_CANCEL}, popModalWindow} = this.props;

        return (
            <Modal
                isOpen
                contentLabel="Remove"
                style={customStyles}
                shouldCloseOnEsc={true}
                ariaHideApp={false}
            >
                <div>
                    <p>Remove {fileName}?</p>
                    <button type="button" onClick={() => popModalWindow({status: MODAL_TYPE_CONFIRM})}>Confirm</button>
                    <button type="button" onClick={() => popModalWindow({status: MODAL_TYPE_CANCEL})}>Cancel</button>
                </div>
            </Modal>
        );
    };
}