import React, { useMemo } from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {
    operatingUnitsSelector,
    operatingUnitsWithFullClientCreateSelector
} from "gui-common/orm/ormSelectors"
import {accountNumberParser} from "gui-common/xpForm/formComponentsFunctions";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {useXpFormContext} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {xpFormUserCanEditBase} from "gui-common/xpAdminForm/xpAdminFormFunctions";


function ClientBaseStatic (props) {
    const timeZones     = useSelector(state => selectAppEnvProperty(state, 'availableTimeZones'));
    const formConfig    = useSelector(state => selectAppEnvProperty(state, 'clientFormConfig'));
    const opUnitsForNew = useSelector(state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForAdmin'}));
    const allOpUnits    = useSelector(operatingUnitsSelector);
    const formContext   = useXpFormContext();

    const operatingUnits = useMemo(() => {
        return (formContext.formUseState === XP_FORM_NEW) ? opUnitsForNew : allOpUnits;
    }, [formContext.formUseState]);

    function fieldIsDisabled() {
        if (props.enforceUseCanEdit) return false;
        return !xpFormUserCanEditBase(formContext, props.ormModel, props.isSubForm);
    }

    function onOpUnitChange(model, newValue, oldValue, changeValue) {
        return (dispatch, getState) => {
            if (formContext.formUseState === XP_FORM_NEW) {
                changeValue(newValue);
                return;
            }
            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey : 'clientForm.modalConfirmOpGroupChange'}))
                .then((result) => {
                    if (result.status === MODAL_TYPE_CONFIRM) changeValue(newValue);
                    if (result.status === MODAL_TYPE_CANCEL)  changeValue(oldValue);
                });
        }
    }

    return (
        <div className={props.className}>
            <XpFormTextInput
                field= "accountManager"
                disabled={fieldIsDisabled()}
                checkNoSpaces={false}
                checkOnlySpaces={true}
                minLength={2}
                maxLength={50}
                inLineLayout
            />
            <XpFormSelectorInput
                noTemplate inLineLayout
                isRequired
                field="operatingUnitId"
                selectList={operatingUnits}
                itemTextField="name"
                onChangeThunk={onOpUnitChange}
                disabled={fieldIsDisabled()}
            />
            {((timeZones.length > 1) && !formConfig.fieldsConfig.timeZone.hide) &&
            <XpFormSelectorInput
                noTemplate inLineLayout
                isRequired
                field="timeZone"
                selectList={timeZones}
                itemTextField="id"
                disabled={fieldIsDisabled()}
            />}
            <XpFormTextInput
                field= "clientRefId"
                disabled={fieldIsDisabled()}
                isRequired
                exactLength={12}
                checkOnlyDigits={true}
                parser={accountNumberParser}
                inLineLayout
            />
        </div>
    );
}

ClientBaseStatic.propTypes = {
    className:        PropTypes.string,
};

ClientBaseStatic.defaultProps = {
    className:  undefined,
};

export default ClientBaseStatic;

