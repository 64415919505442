import {
    ormFunctionMap,
    ormFunctionMapDeleted,
} from "gui-common/api/ormSocketMethodMaps";
import {ormEntityCreated, ormEntityDeleted, ormEntityRefreshed, ormEntityUpdated} from "gui-common/orm/ormReducer";
import {activeClientBankAccountsSelector, activeClientLedgerAccountsSelector} from "xpool-gui-common/features/client/clientSelectors";
import {getOrmItemSelectorGetter} from "gui-common/orm/ormSelectors";
import {getUserPreferencesPropertySelector} from "gui-common/userSettings/userSettingsSelectors";
import {handleBalanceRequestResponse} from "xpool-gui-common/features/bankAccount/bankAccountFunctions";

const prototypeExcludeMessageMap = {
/*
    'se.nordicfc.xpoolapi.model.legal.XpoolApiLedgerAccount': (message, state, dispatch) => {
        // Filter ledger bankAccount events for ledger accounts that are not active.
        const activeLedgerAccounts = activeClientLedgerAccountsSelector(state);
        if (!activeLedgerAccounts.includes(message.payload.ledgerAccountId)) return true;
        return false;
    },
*/
    'se.nordicfc.xpoolapi.model.legal.XpoolApiLedgerAccountBalance': (message, state, dispatch) => {
        // Filter ledger bankAccount balnce events for ledger accounts that are not active.
        const activeLedgerAccounts = activeClientLedgerAccountsSelector(state);
        if (!activeLedgerAccounts.includes(message.payload.ledgerAccountId)) return true;
        return false;
    },
    'se.nordicfc.xpool.repository.entity.RunState': (message, state, dispatch) => {
        // Todo: Fix all prototypes.
        if (message.payload.period.businessObjectPrototype === 'Client') return false; // RunState updates on client level should always be consumed.
        const activeAccounts = activeClientBankAccountsSelector(state);

        if ((message.payload.period.businessObjectPrototype === 'BankAccount'        ) && !activeAccounts.find(item => item.balanceRunStateObject && (item.balanceRunStateObject.id === message.payload.id))) return true;
        if ((message.payload.period.businessObjectPrototype === 'BankAccountRelation') && !activeAccounts.find(item => item.barRunStateObject     && (item.barRunStateObject.id     === message.payload.id))) return true;

        return false;
    },
    'se.nordicfc.xpoolapi.model.XpoolApiOrder': (message, state, dispatch) => {
        // Filter in-balance order updates for orders that are not in the ORM. This is to not display in-balance orders that should not be displayed based on the showInBalanceOrdersDays setting.
        if (message.payload.execution?.state !== 'IN_BALANCE') return false;

        const existingOrder = getOrmItemSelectorGetter('Order')()(state, {selectId: message.payload.id})
        if (!existingOrder) return true;

        const inBalanceOrdersDays = getUserPreferencesPropertySelector()(state, 'showInBalanceOrdersDays');
        if (Number(inBalanceOrdersDays) === 0) {
            dispatch(ormEntityDeleted('Order', existingOrder.ref));
            return true;
        }

        return false;
    },
    'se.nordicfc.xpool.common.model.official.request.BankAccountBalanceRequest': (message, state, dispatch) => {
        return (message.functionType === 'se.nordicfc.common.message.functions.Create');
    }
}

export function xpoolFilterSocketMessage(parsedObject, state, dispatch) {
    let excludeFunction = prototypeExcludeMessageMap[parsedObject.payloadType];
    if (!excludeFunction) return false;

    return excludeFunction(parsedObject, state, dispatch)
}

/* -----------------------------------------------------------------------------------------------------------------
* Socket message dictionaries for models and message types specific to the application. Common messages in gui-common
* -----------------------------------------------------------------------------------------------------------------*/

const bankAccountFunctionMap = {
    'se.nordicfc.common.message.functions.Create'                       : {batchPossible: false, ormEventType: 'create', functionToDispatch: (itemType, item) => ormEntityCreated(itemType, item)},
    'se.nordicfc.common.message.functions.Update'                       : {batchPossible: false, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, item)},
    'se.nordicfc.common.message.functions.Refresh'                      : {batchPossible: true , ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityRefreshed(itemType, item)},
    'se.nordicfc.common.message.functions.Delete'                       : {batchPossible: false, ormEventType: 'delete', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, item)},
};
const cashPoolFunctionMap = {
    'se.nordicfc.xpool.common.model.function.BalancesUpdating'          : {batchPossible: false, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, {...item, cashPoolState: 'INVALID_BALANCES'})},
    'se.nordicfc.xpool.common.model.function.BalancesUpdated'           : {batchPossible: false, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, {...item, cashPoolState: 'CALCULATING_ORDERS'})},
    'se.nordicfc.xpool.common.model.function.BalancesUpdatedFailed'     : {batchPossible: false, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, {...item, cashPoolState: 'IN_BALANCE'})},
    'se.nordicfc.xpool.common.model.function.CashPoolRebalanced'        : {batchPossible: false, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, {...item, cashPoolState: 'IN_BALANCE'})},
};
const balanceRequestFunctionMap = {
    'se.nordicfc.common.message.functions.Update'                       : {batchPossible: false, ormEventType: 'update', functionToDispatch: (itemType, item) => handleBalanceRequestResponse(item)},
    'se.nordicfc.common.message.functions.Create'                       : {batchPossible: false, ormEventType: 'create', functionToDispatch: undefined}, // We do not use Create event for anything currently.
};
const bankAccountAggregatesFunctionMap = {
    'se.nordicfc.xpool.common.model.function.AggregatedBalanceUpdated'  : {batchPossible: true , ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, item)},
};
/*
const orderMessageFunctionMap = {
    'se.nordicfc.common.message.functions.Create'  : {batchPossible: true, ormEventType: 'create', functionToDispatch: (itemType, item) => ormEntityCreated(itemType, item)},
    'se.nordicfc.common.message.functions.Update'  : {batchPossible: true, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, item)},
    'se.nordicfc.common.message.functions.Refresh' : {batchPossible: true, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityRefreshed(itemType, item)},
    'se.nordicfc.common.message.functions.Delete'  : {batchPossible: true, ormEventType: 'delete', functionToDispatch: (itemType, item) => ormEntityDeleted(itemType, item)},
};
*/



export const xpoolSocketPayloadPrototypeMap = {
    'se.nordicfc.xpool.common.model.event.legal.ShallowClient'              : { batchPossible: false, model: 'Client'                   , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.xpool.common.model.event.legal.ShallowLegalEntity'         : { batchPossible: false, model: 'LegalEntity'              , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.xpool.common.model.event.legal.ShallowLegalEntityUnit'     : { batchPossible: false, model: 'LegalEntityUnit'          , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.xpool.common.model.event.legal.ShallowBankAccount'         : { batchPossible: true , model: 'BankAccount'              , functionMap: bankAccountFunctionMap   , logMessage: false },
    'se.nordicfc.xpool.common.model.event.ShallowBankAccountRelation'       : { batchPossible: true , model: 'BankAccountRelation'      , functionMap: ormFunctionMap           , logMessage: false },

    'se.nordicfc.xpoolapi.model.legal.XpoolApiAggregatedBalances'           : { batchPossible: true , model: 'BankAccountAggregates'    , functionMap: bankAccountAggregatesFunctionMap , logMessage: false },

    'se.nordicfc.xpoolapi.model.legal.XpoolApiLedgerAccount'                    : { batchPossible: true , model: 'LedgerAccount'        , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.xpoolapi.model.legal.XpoolApiLedgerAccountBalance'             : { batchPossible: true , model: 'LedgerAccountBalance' , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.xpool.common.model.official.request.BankAccountBalanceRequest' : { batchPossible: false, model: undefined              , functionMap: balanceRequestFunctionMap, logMessage: false },
    'se.nordicfc.common.scheduler.RunStateResponse'                         : { batchPossible: true , model: 'RunState'                 , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.xpoolapi.model.XpoolApiOrder'                              : { batchPossible: true , model: 'Order'                    , functionMap: ormFunctionMapDeleted    , logMessage: false },

    'se.nordicfc.xpool.common.model.event.legal.ShallowCashPool'            : { batchPossible: true , model: 'CashPool'                 , functionMap: cashPoolFunctionMap      , logMessage: false },

    'se.nordicfc.xpoolapi.model.legal.XpoolApiERPTransaction'               : { batchPossible: true , model: 'BalanceMovement'          , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.common.erp.model.ERPIntegration'                           : { batchPossible: true , model: 'ErpIntegration'           , functionMap: ormFunctionMap           , logMessage: false },
};
