import {
    xpColCreate,
} from "gui-common/xpGrid/xpGridFunctions";
import {setParentId} from "gui-common/functions/functions";
import {orderBuySell, orderStates, orderTypes} from "xpool-gui-common/features/order/orderConstants";

export function prepareOrdersColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id'                    , 100, 50, 'ordersList.id');

    xpColCreate(translate, subArray, hiddenState, 'clientId'              , 100, 50, 'ordersList.clientId');
    xpColCreate(translate, subArray, hiddenState, 'clientName'            , 150, 80, 'ordersList.clientName');

    xpColCreate(translate, subArray, hiddenState, 'sourceAccountName'     , 150, 50, 'ordersList.sourceAccountName'     , {
        suppressCellFlash: true,
        cellStyle: (params) => {
            return {
                background: params.data.sourceAccountSelectedInCashPool ? 'var(--xpool-grid-selected-background-color)' : 'none',
            }}});
    xpColCreate(translate, subArray, hiddenState, 'sourceAmountCcy'       , 150, 50, 'ordersList.sourceAmount'          , {
        suppressCellFlash: true,
        filter: false,
        cellStyle: (params) => {
            return {
                textAlign: "right",
                background: params.data.sourceAccountSelectedInCashPool ? 'var(--xpool-grid-selected-background-color)' : 'none',
            }}});
    xpColCreate(translate, subArray, hiddenState, 'destinationAccountName', 150, 50, 'ordersList.destinationAccountName', {
        suppressCellFlash: true,
        cellStyle: (params) => {
            return {
                background: params.data.destinationAccountSelectedInCashPool ? 'var(--xpool-grid-selected-background-color)' : 'none',
            }}});
    xpColCreate(translate, subArray, hiddenState, 'destinationAmountCcy'  , 150, 50, 'ordersList.destinationAmount'     , {
        suppressCellFlash: true,
        filter: false,
        cellStyle: (params) => {
            return {
                textAlign: "right",
                background: params.data.destinationAccountSelectedInCashPool ? 'var(--xpool-grid-selected-background-color)' : 'none',
            }}});

    xpColCreate(translate, subArray, hiddenState, 'orderType'             , 100, 50, 'ordersList.orderType', {
        cellRenderer: 'xpTranslatedRenderer',
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true, values: orderTypes}
    });
    xpColCreate(translate, subArray, hiddenState, 'adjustedSideT'          , 100, 50, 'ordersList.buySellIndicator', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: orderBuySell.map(item => 'buySellIndicators.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState, 'currencyPairStr'       , 100, 50, 'ordersList.currencyPair');
    xpColCreate(translate, subArray, hiddenState, 'externalReference'     , 200, 50, 'ordersList.externalReference');
    xpColCreate(translate, subArray, hiddenState, 'settlementDate'        , 150, 50, 'ordersList.settlementDate');

    xpColCreate(translate, subArray, hiddenState, 'nominalAmountCcy'      , 150, 50, 'ordersList.nominalAmount'     , {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'counterAmountCcy'      , 150, 50, 'ordersList.counterpartyAmount', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'displayRate'           , 100, 50, 'ordersList.fxRate', {cellStyle: {textAlign: "right"}});

    xpColCreate(translate, subArray, hiddenState, 'displayStateT'         , 150, 50, 'ordersList.orderStatus', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: orderStates.map(item => 'orderStates.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });

    xpColCreate(translate, subArray, hiddenState, 'createdDateTime'       , 150, 50, 'ordersList.createdDateTime' , {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState, 'modifiedDateTime'      , 150, 50, 'ordersList.modifiedDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState, 'executionDateTime'      , 150, 50, 'ordersList.executionDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});

    return subArray;
}


export function prepareAccountingOrdersColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'leadText'         , 150, 50, 'accountingOrdersList.leadText', {cellStyle: {fontWeight: "600"}, suppressMovable: true, cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'cpAccountName'    , 150, 50, 'accountingOrdersList.cpAccountName');
    xpColCreate(translate, subArray, hiddenState, 'id'               , 100, 50, 'ordersList.id');
    xpColCreate(translate, subArray, hiddenState, 'orderType'        , 100, 50, 'ordersList.orderType');
    xpColCreate(translate, subArray, hiddenState, 'displayStateT'    , 150, 50, 'ordersList.orderStatus', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'currencyPairStr'  , 100, 50, 'ordersList.currencyPair');
    xpColCreate(translate, subArray, hiddenState, 'displayRate'      , 100, 50, 'ordersList.fxRate', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'externalReference', 200, 50, 'ordersList.externalReference');
    xpColCreate(translate, subArray, hiddenState, 'createdDateTime'  , 150, 50, 'ordersList.createdDateTime'  , {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState, 'modifiedDateTime' , 150, 50, 'ordersList.modifiedDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState, 'executionDateTime', 150, 50, 'ordersList.executionDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState, 'settlementDate'   , 150, 50, 'ordersList.settlementDate');
    xpColCreate(translate, subArray, hiddenState, 'amount'           , 90 , 50, 'accountingOrdersList.amount', {
        cellRenderer: 'xpGridNumberRenderer',
        suppressMovable: true,
        cellStyle: function(params) {
            if   (!params.data) return {};
            if   ((params.data.id === "in") || (params.data.id === "out")) return {fontWeight: "600"    , textAlign: "right"}; // In and Out are id on first and last row in calculation grid.
            // if   (params.data.isSelectedOrder)                             return {background: 'var(--xpool-grid-selected-order-background-color)', textAlign: "right"};
            return {background: 'none', textAlign: "right"};
        }});
    return subArray;
}


const setBuySell = (orderType, buySell) => { // This is required since far leg should be shown for Swaps.
    if (orderType !== 'FX_SWAP') return buySell;
    if (buySell === 'BUY' ) return 'SELL';
    if (buySell === 'SELL') return 'BUY';
    return buySell;
};

function getFxDisplayRate(order) {
    if (order.execution.executionRate) {
        return order.execution.executionRate;
    }
    if (order.execution.executionRateFarLeg) {
        return order.execution.executionRateFarLeg;
    }
    if (order.reference.quoteNearLegRate) {
        return order.reference.quoteNearLegRate;
    }
    return order.reference.referenceRate;
}
export function orderTransformer(order, params) {
    setParentId(order, 'clientId', params ? params.parentId : null);
    let newOrder = {
        ...order,
        barId               : order.bankAccountRelationId,
        currencyPairStr     : order.currencyPair ? order.currencyPair.key : "N/A",
        baseCurrencyStr     : order.currencyPair ? order.currencyPair.baseCurrency.currency  : "N/A",
        baseCurrencyId      : order.currencyPair ? order.currencyPair.baseCurrency.id : null,
        quoteCurrencyStr    : order.currencyPair ? order.currencyPair.quoteCurrency.currency : "N/A",
        quoteCurrencyId     : order.currencyPair ? order.currencyPair.quoteCurrency.id: null,
        instructedCurrencyId : order.instructedCurrency ? order.instructedCurrency.id: null,
        instructedCurrencyStr: order.instructedCurrency ? order.instructedCurrency.currency: null,
        referenceRate       : order.reference.referenceRate,
        quoteRate           : order.reference.quoteNearLegRate,
        executionRate       : order.execution.executionRate,
        executionRateFarLeg : order.execution.executionRateFarLeg,
        displayRate         : getFxDisplayRate(order),
        settlementDate      : order.execution.settlementDate ? order.execution.settlementDate : order.reference.settlementDate,

        referenceCpAmount   : order.reference.counterpartyAmount,
        executionCpAmount   : order.execution.counterpartyAmount,
        displayCpAmount     : order.execution.counterpartyAmount ? order.execution.counterpartyAmount : order.reference.counterpartyAmount,

        externalReference   : order.execution.externalReference,
        state               : order.execution.state,
        reason              : order.execution.reason,
        executionDateTime   : order.execution.executionDateTime,
        createdDateTime     : order.createdDateTime,
        modifiedDateTime    : order.modifiedDateTime,
        adjustedSide        : setBuySell(order.orderType, order.side),
        displayState        : order.execution.state + (order.execution.reason ? ('-' + order.execution.reason) : ""),
    }
    return newOrder;
}
