import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {formatAmount, stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {getLedgerAccountBalancesSelector} from "gui-common/ledger/ledgerSelectors";
import {useResizeObserver, useSelectorInstance} from "gui-common/functions/hooks";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useXpFormField, useXpFormFields, useXpFormUseState} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_EDIT, XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import moment from "moment";
import {getBalanceMovementsSelector} from "xpool-gui-common/features/balanceMovement/balanceMovementSelectors";
import {
    balanceMovementReverseAction,
    hedgeIsAllowed,
    reverseIsAllowed
} from "xpool-gui-common/features/balanceMovement/BalanceMovementsList";
import {pushDelayedModal} from "gui-common/functions/thunks";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {HEDGE_BALANCE_MOVEMENT_DIALOG, REGISTER_BALANCE_MOVEMENT_DIALOG} from "appConfig/appModals";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import XpChart3 from "gui-common/components/XpChart";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {XpDateTime} from "gui-common/components/XpDateTime";
import {getAnyOfItemsLoadingSelector} from "gui-common/orm/ormLoadingSelectors";
import ComponentLoading from "gui-common/components/ComponentLoading";
import LoadingDataSpinner from "gui-common/components/LoadingDataSpinner";



function AccountBalanceGraph (props) {

    const selectedAccount            = props.selectedAccount;

    const ledgerAccountBalances      = useSelectorInstance(getLedgerAccountBalancesSelector, {ledgerAccountId: selectedAccount?.ledgerBalanceLaId, includeDisabled: false, sortOrder: 'asc'});
    // const pendingAccountBalances     = useSelectorInstance(getLedgerAccountBalancesSelector, {ledgerAccountId: selectedAccount ? selectedAccount.pendingBalanceLaId     : undefined, includeDisabled: false, sortOrder: 'asc'});
    const suggestedAccountBalances   = useSelectorInstance(getLedgerAccountBalancesSelector, {ledgerAccountId: selectedAccount?.suggestedBalanceLaId, includeDisabled: false, sortOrder: 'asc'});
    // const postPoolingAccountBalances = useSelectorInstance(getLedgerAccountBalancesSelector, {ledgerAccountId: selectedAccount ? selectedAccount.postPoolingBalanceLaId : undefined, includeDisabled: false, sortOrder: 'asc'});

    const anyLedgerAccountLoading = useSelectorInstance(getAnyOfItemsLoadingSelector, {ormModel: 'LedgerAccount', itemIds: [selectedAccount?.ledgerBalanceLaId, selectedAccount?.suggestedBalanceLaId]});

    const balanceMovements           = useSelectorInstance(getBalanceMovementsSelector     , {bankAccount: selectedAccount});
    const ormActiveClient            = useSelector(shallowActiveClientSelector);
    const dispatch                   = useDispatch();
    const balanceMovementsConfig     = useAppEnvProperty('balanceMovementsConfig');

    const bankAccountFormModel  = props.bankAccountFormModel;
    const formData = useXpFormFields({dependentFieldModels: {
            useSweep        : bankAccountFormModel + '.runConfiguration.sweepAndTopUp.0.useSweep',
            sweepThreshold  : bankAccountFormModel + '.runConfiguration.sweepAndTopUp.0.sweepThreshold',
            sweepTarget     : bankAccountFormModel + '.runConfiguration.sweepAndTopUp.0.sweepTarget',
            sweepHorizon    : bankAccountFormModel + '.runConfiguration.sweepAndTopUp.0.sweepHorizon',
            useTopUp        : bankAccountFormModel + '.runConfiguration.sweepAndTopUp.0.useTopUp',
            topUpTarget     : bankAccountFormModel + '.runConfiguration.sweepAndTopUp.0.topUpTarget',
            topUpThreshold  : bankAccountFormModel + '.runConfiguration.sweepAndTopUp.0.topUpThreshold',
            parentAccountId : bankAccountFormModel + '.parentAccountId',
        }})
    const formState = useXpFormUseState(bankAccountFormModel);

    const [formContainerHeight, setFormContainerHeight]   = useState(undefined);

    const observerCallback = useCallback(box => {
        if (formContainerHeight === box.height) {
            // No need to update!
            return;
        }
        setFormContainerHeight(box.height);
    }, []);
    const formContainerRef = useResizeObserver(observerCallback)


    const switchFormModel = 'accountBalanceGraph.' + props.instanceId;
    const switchFormData   = useXpFormField(switchFormModel);

    const decDenLangState = useSelector(selectDecDenLangState);


    const yRefLines = useMemo(
        () => {
            // const dashArray = "4 2";
            const dashArray = "";
            const settings        = (formState === XP_FORM_VIEW) ? (selectedAccount.runConfiguration?.sweepAndTopUp ? selectedAccount.runConfiguration.sweepAndTopUp[0] : undefined) : formData;
            const parentAccountId = (formState === XP_FORM_VIEW) ? selectedAccount.parentAccountId : formData.parentAccountId;
            if (!parentAccountId || !settings) return []
            let retArray = []

            if (settings.useSweep && switchFormData?.liquidityParams) {
                retArray.push({trKey: 'accountBalanceChart.referenceLines.sweepThreshold.label', value: stringNumberToFloat(settings.sweepThreshold, decDenLangState), color: 'darkslateblue' , strokeDasharray: dashArray})
                retArray.push({trKey: 'accountBalanceChart.referenceLines.sweepTarget.label'   , value: stringNumberToFloat(settings.sweepTarget   , decDenLangState), color: 'darkolivegreen', strokeDasharray: dashArray})
            }
            if (settings.useTopUp && switchFormData?.liquidityParams) {
                retArray.push({trKey: 'accountBalanceChart.referenceLines.topUpTarget.label'   , value: stringNumberToFloat(settings.topUpTarget   , decDenLangState), color: 'mediumpurple'  , strokeDasharray: dashArray})
                retArray.push({trKey: 'accountBalanceChart.referenceLines.topUpThreshold.label', value: stringNumberToFloat(settings.topUpThreshold, decDenLangState), color: 'slategray'     , strokeDasharray: dashArray})
            }
            retArray.push({value: 0, color: 'gray', strokeWidth: 1})
            return retArray;
        },
        [formData, formState, selectedAccount, decDenLangState, switchFormData]
    );

    const xRefLines = useMemo(
        () => {
            // const dashArray = "4 2";
            const dashArray = "";
            let retArray = []
            retArray.push({trKey: 'accountBalanceChart.referenceLines.today.label', momentDate: moment(), color: 'steelblue', strokeDasharray: dashArray, yOffset: 0})

            const parentAccountId = (formState === XP_FORM_VIEW) ? selectedAccount.parentAccountId : formData.parentAccountId;
            if (!parentAccountId) return retArray

            const settings        = (formState === XP_FORM_VIEW) ? (selectedAccount.runConfiguration?.sweepAndTopUp ? selectedAccount.runConfiguration.sweepAndTopUp[0] : undefined) : formData;
            if (!settings) return retArray

            const bar = selectedAccount.bankAccountRelations ? selectedAccount.bankAccountRelations[0] : undefined;
            const hedgeConfiguration  = bar?.executionConfiguration?.hedgeExposure ? bar.executionConfiguration.hedgeExposure[0] : undefined;
            if (hedgeConfiguration) {
                const addPeriod = {MONTHLY: 'months', QUARTERLY: 'quarters'}[hedgeConfiguration.hedgeRollType];
                const eoPeriod  = {MONTHLY: 'month' , QUARTERLY: 'quarter' }[hedgeConfiguration.hedgeRollType];
                let periods = hedgeConfiguration.hedgePercent.split(' ');
                let periodEnd = moment().endOf(eoPeriod);
                let renderEnd = moment().endOf(eoPeriod).add(2, 'years');
                let periodIndex = 0;
                while ((periodIndex < periods.length) && renderEnd.isAfter(periodEnd)) {
                    retArray.push({trKey: 'accountBalanceChart.referenceLines.hedgePeriodEnd.label', trParams: {hedgePercent: periods[periodIndex]}, momentDate: periodEnd.clone(), color: 'palevioletred' , strokeDasharray: "1 1", yOffset: -11}); // 2 2
                    periodEnd.add(1, addPeriod).endOf(eoPeriod);
                    if (periodIndex === (periods.length - 1)) {
                        if (periods[periodIndex] === '0') break;
                        // Do not increase periodIndex. Keep rendering lines with same percent.
                    }
                    else {
                        periodIndex++;
                    }
                }
            }
            if (settings.sweepHorizon) {
                retArray.push({trKey: 'accountBalanceChart.referenceLines.sweepHorizon.label', momentDate: moment().add(settings.sweepHorizon, 'days'), color: 'darkgreen' , strokeDasharray: dashArray, yOffset: 13})
            }

            return retArray;
        },
        [formData, formState, selectedAccount, decDenLangState]
    );

    function setMinMax(currMin, currMax, newBalance) {
        if (!newBalance) {
            return [currMin, currMax]
        }
        return [
            (newBalance.balance < currMin) ? newBalance.balance : currMin,
            (newBalance.balance > currMax) ? newBalance.balance : currMax,
        ]
    }

    const [lastKnownBalanceDate, historyMin, historyMax, futureMin, futureMax] = useMemo(
        () => {
            if (!ledgerAccountBalances?.length) return [undefined, 0, 0, 0, 0];

            const todayDate = moment().endOf('day');
            let lastCheckedBalanceTime = moment(ledgerAccountBalances[0].valueDate);
            let lastKnownBalanceTimeFound = false;
            let historyMin = 0;
            let historyMax = 0;
            let futureMin = 0;
            let futureMax = 0;

            if (lastCheckedBalanceTime.isAfter(todayDate)) {
                lastCheckedBalanceTime = todayDate;
                lastKnownBalanceTimeFound = true;
            }

            let lastCheckedBalance;
            for (const balance of ledgerAccountBalances) {
                if (!lastKnownBalanceTimeFound) {
                    const thisPointTime = moment(balance.valueDate);
                    if (thisPointTime.isAfter(todayDate)) {
                        // Last checked balance was the last known. Set flag and start setting future min and max,
                        lastKnownBalanceTimeFound = true;
                        [futureMin, futureMax] = setMinMax(futureMin, futureMax, lastCheckedBalance);
                    }
                    else {
                        lastCheckedBalanceTime = moment(balance.valueDate);
                        lastCheckedBalance = {...balance};
                        [historyMin, historyMax] = setMinMax(historyMin, historyMax, balance);
                    }
                }
                // Cannot be in else statement, since we must set future min and max for the first balance after today.
                if (lastKnownBalanceTimeFound) {
                    [futureMin, futureMax] = setMinMax(futureMin, futureMax, balance);
                }
            }
            if (!lastKnownBalanceTimeFound) { // No future balance points exists
                [futureMin, futureMax] = setMinMax(futureMin, futureMax, lastCheckedBalance);
            }
            return [lastCheckedBalanceTime.endOf('day'), historyMin, historyMax, futureMin, futureMax] ;
        },
        [ledgerAccountBalances]
    );

    function getZeroOffset(max, min) {
        if (max <= 0) {
            return 0;
        }
        if (min >= 0) {
            return 1;
        }
        return max / (max - min);
    }
    const maxOpacity = 1;
    const minOpacity = 0.05;
    // const minOffset  = 0.1;
    const minOffset  = 0;
    function getMaxOpacity(globalMax, localMax) {
        if (globalMax === 0) {
            return maxOpacity;
        }
        return (maxOpacity / globalMax) * Math.max((localMax - globalMax * minOffset), 0) / (1 - minOffset);
    }
    function getMinOffset(globalMax, localMax) {
        return 0;
        return globalMax * minOffset / localMax;
    }
    function getStops(zeroOffset, localMax, localMin, globalMax, globalMin, plusColor, minusColor) {
        if (zeroOffset === 1) { // The graph is fully above zero
            return [
                {offset: 0,                                     stopOpacity: getMaxOpacity(globalMax, localMax), color: plusColor},
                {offset: 1 - getMinOffset(globalMax, localMax), stopOpacity: minOpacity,                         color: plusColor}
            ]
        }
        if (zeroOffset === 0) { // The graph is fully below zero
            return [
                {offset: getMinOffset(globalMin, localMin)    , stopOpacity: minOpacity,                           color: minusColor},
                {offset: 1,                                     stopOpacity: getMaxOpacity(-globalMin, -localMin), color: minusColor}
            ]
        }
        // The graph is both above and below zero
        return [
            {offset: 0                                                   , stopOpacity: getMaxOpacity(globalMax, localMax)  , color: plusColor },
            {offset: zeroOffset * (1 - getMinOffset(globalMax, localMax)), stopOpacity: minOpacity,                           color: plusColor },
            {offset: zeroOffset                                          , stopOpacity: minOpacity,                           color: plusColor },
            {offset: zeroOffset                                          , stopOpacity: minOpacity,                           color: minusColor},
            {offset: zeroOffset * (1 + getMinOffset(globalMin, localMin)), stopOpacity: minOpacity,                           color: minusColor},
            {offset: 1                                                   , stopOpacity: getMaxOpacity(-globalMin, -localMin), color: minusColor}
        ]
    }

    const [historyGradientStopsConfig, futureGradientStopsConfig] = useMemo(
        () => {
            if (![historyMin, historyMax, futureMin, futureMax].find(bal => bal !== 0)) return [undefined, undefined];

            const globalMin = Math.min(historyMin, futureMin);
            const globalMax = Math.max(historyMax, futureMax);

            const historyZeroOffset = getZeroOffset(historyMax, historyMin);
            const futureZeroOffset  = getZeroOffset(futureMax , futureMin);

            const historyStops = getStops(historyZeroOffset, historyMax, historyMin, globalMax, globalMin, undefined, undefined);
            const futureStops  = getStops(futureZeroOffset , futureMax , futureMin , globalMax, globalMin, undefined, undefined);


            return [historyStops, futureStops];
        },
        [historyMin, historyMax, futureMin, futureMax]
    );

    if (!selectedAccount) return null;
    const dashArray = "5 2"; // // 0 5 5 0 , 2 3 2 3

    if (!anyLedgerAccountLoading && !lastKnownBalanceDate) {
        return <h5><XpTranslated trKey='accountBalanceChart.noKnownBalancesOnAccount' /></h5>
    }

    const historyLineName   = "history"   + selectedAccount.id;
    const futureLineName    = "future"    + selectedAccount.id;
    const suggestedLineName = "suggested" + selectedAccount.id;

    return (
        <div style={{height: formContainerHeight ? 'calc(100% - ' + formContainerHeight + 'px)' : '100%'}}>
            <div ref={formContainerRef} style={{display: props.hideSwitchForm ? 'none' : undefined}}>
                <XpForm
                    formModel={switchFormModel}
                    onSubmit={(data) => {}}
                    initialUseState={XP_FORM_EDIT}
                    // currentData={clientPrice?.clientPrice}
                >
                    <div style={{display: 'flex', padding: 'var(--xpool-currency-dashboard-padding)'}}>
                        <XpFormSwitchInput
                            field={'suggested'}
                            defaultValue={true}
                            labelKey={'accountBalanceChart.graphSwitches.suggested'}
                        />
                        <XpFormSwitchInput
                            field={'liquidityParams'}
                            defaultValue={true}
                            labelKey={'accountBalanceChart.graphSwitches.liquidityParams'}
                        />
                    </div>
                </XpForm>
            </div>

            {anyLedgerAccountLoading ?
                <LoadingDataSpinner trKey='accountBalanceChart.loadingBalances'/>
                :
                <XpChart3
                    dataSetId = {selectedAccount.currency.currency + selectedAccount.id}
                    previousDefaultFromLine = {"history" + selectedAccount.id}
                    lineConfig={[ // Order of lines here determines drawing order in chart component! Do no change!
                        {
                            lineInstanceId: historyLineName,
                            stackId: 2,
                            data: ledgerAccountBalances,
                            dateField: 'valueDate',
                            dataKey: 'balance',
                            toolTipTrKey: 'accountBalanceChart.dataKeys.history',
                            visibleTo  : lastKnownBalanceDate,
                            stroke: 'var(--xpool-secondary-color-mid)',
                            gradientStopsConfig: historyGradientStopsConfig
                        },
                        // {lineInstanceId: "postPooling" + selectedAccount.id, data: postPoolingAccountBalances, dateField: 'valueDate', dataKey: 'balance', toolTipTrKey: 'accountBalanceChart.dataKeys.postPooling', toolTipDisplayOrder: 5, visibleFrom: lastKnownBalanceDate, stroke: 'green'    , strokeDasharray: dashArray},
                        // {lineInstanceId: "pending"     + selectedAccount.id, data: pendingAccountBalances    , dateField: 'valueDate', dataKey: 'balance', toolTipTrKey: 'accountBalanceChart.dataKeys.pending'    , toolTipDisplayOrder: 3, visibleFrom: lastKnownBalanceDate, stroke: 'gray'     , strokeDasharray: dashArray},
                        {lineInstanceId: futureLineName,
                            data: ledgerAccountBalances,
                            stackId: 1,
                            dateField: 'valueDate',
                            dataKey: 'balance',
                            toolTipTrKey: 'accountBalanceChart.dataKeys.future',
                            visibleFrom: lastKnownBalanceDate,
                            stroke: 'var(--xpool-secondary-color-mid)',
                            gradientStopsConfig: futureGradientStopsConfig,
                            strokeDasharray: dashArray,
                            toolTipInfoFn: pointData => {
                                return '';
                            },
                            contextMenuItemsFn: pointData => {
                                const returnArray = [];

                                if (balanceMovementsConfig?.allowManualRegistration) {
                                    returnArray.push(getRegisterPayRec(selectedAccount, 'PAYABLE'   , pointData.payload.dateString, dispatch))
                                    returnArray.push(getRegisterPayRec(selectedAccount, 'RECEIVABLE', pointData.payload.dateString, dispatch))
                                    returnArray.push({isDivider: true});
                                }

                                balanceMovements.filter(item => item.valueDate === pointData.payload.dateString).forEach(item => {
                                    if (reverseIsAllowed(item, ormActiveClient)) {
                                        returnArray.push({
                                            trKey: 'balanceChartActions.reverse.' + item.type,
                                            trParams: {amount: item.amount, type: item.type},
                                            action: () => balanceMovementReverseAction(item, dispatch)
                                        })
                                    }
                                    if (hedgeIsAllowed(item, ormActiveClient)) {
                                        returnArray.push({
                                            trKey: 'balanceChartActions.hedge.' + item.type,
                                            trParams: {amount: item.amount, type: item.type},
                                            action: () => dispatch(pushDelayedModal(HEDGE_BALANCE_MOVEMENT_DIALOG, {movement: item}))
                                        })
                                    }
                                })
                                return returnArray;
                            }
                        },
                        {
                            lineInstanceId: suggestedLineName,
                            stackId: 1,
                            deltaKey: "future" + selectedAccount.id,
                            hidden: !switchFormData?.suggested,
                            data: suggestedAccountBalances,
                            dateField: 'valueDate',
                            dataKey: 'balance',
                            toolTipTrKey     : 'accountBalanceChart.dataKeys.suggested',
                            visibleFrom: lastKnownBalanceDate,
                            stroke: 'orange',
                            fill: 'orange',
                            fillOpacity: 0.4,
                            strokeDasharray: dashArray
                        },
                    ]}
                    xReferenceLines={xRefLines}
                    yReferenceLines={yRefLines}
                    strokeWidth={1}
                    areaFill
                    tooltipContentFn = {(props, lineConfig) => {
                        const {payload} = props;
                        if (!payload?.length || !payload[0]?.payload) {
                            return null;
                        }
                        return (
                            <div className="xpChartToolTipContainer">
                                <div style={{width: '100%', textAlign: 'center', borderBottom: '1px solid lightgray', marginBottom: '5px'}}>
                                    <XpDateTime isoString={payload[0].payload.momentDate} format='L'/>
                                </div>
                                <table style={{margin: '0'}}>
                                    <tbody>
                                        {renderTooltipRow(historyLineName  , historyLineName  , props, lineConfig, decDenLangState, false)}
                                        {renderTooltipRow(futureLineName   , futureLineName   , props, lineConfig, decDenLangState, false)}
                                        {renderTooltipRow(suggestedLineName, suggestedLineName + '-delta'   , props, lineConfig, decDenLangState, true)}
                                        {renderTooltipRow(suggestedLineName, suggestedLineName + '-absolute', props, lineConfig, decDenLangState, false)}
                                    </tbody>
                                </table>
                            </div>
                        );
                    }}
                />
            }
        </div>
    )
}

export default AccountBalanceGraph;


function renderTooltipRow(name, dataKey, props, lineConfig, decDenLangState, isDelta) {
    const {payload} = props;
    const thisLineConfig = lineConfig.find(item => item.name === name);
    if (!thisLineConfig) {
        return null;
    }
    const thisLineData = payload.find(item => item.name === name);
    if (!thisLineData) {
        return null;
    }

    let numValue = formatAmount(thisLineData.payload[dataKey], decDenLangState);

    return (
        <tr key={name + '-' + dataKey + '-' + thisLineData.payload.xIndex} style={{border: 'none', fontStyle: (isDelta ? 'italic' : undefined), borderTop: (isDelta ? '12px solid transparent' : undefined)}}>
            <td style={{border: 'none'}}>
                {!isDelta &&
                <div style={{position: 'relative', width: '30px', height: '24px'}}>
                    <div className='xpChartActiveDot' style={{background: thisLineConfig.stroke}}/>
                    <div className={'xpChartActiveDotInner'}/>
                </div>}
            </td>
            <td style={{border: 'none'}}>
                <div><XpTranslated trKey={thisLineConfig.toolTipTrKey + (isDelta ? '-delta' : '')}/></div>
            </td>
            <td style={{textAlign: 'right', border: 'none'}}>
                <div style={{paddingLeft: '5px'}} className="chart-toolTip chartTooltipNumber">{numValue}</div>
            </td>
        </tr>
    )
}

function getRegisterPayRec(account, type, date, dispatch) {
    return {
        trKey:  'accountsList.gridContextMenuItems.registerBalanceMovement.' + type,
        action: () => dispatch(pushDelayedModal(REGISTER_BALANCE_MOVEMENT_DIALOG, {account: account, type: type, date: date}))
    }
}
