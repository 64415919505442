import React, { Component } from 'react'
import {connect} from "react-redux";
import moment from 'moment';
import 'gui-common/dashboard/dashboard.css';
import './../../../node_modules/react-grid-layout/css/styles.css';
import './../../../node_modules/react-resizable/css/styles.css';
import {dashboardSetLayout, dashboardSetSizeInfo, dashboardRemoveComponent, dashboardRenameComponent} from './dashboardReducer';
import removeIcon from 'gui-common/resources/removeIcon.png'
import editIcon from 'gui-common/resources/editIcon.png'
import readyIcon from 'gui-common/resources/readyIcon.png'
import escapeIcon from 'gui-common/resources/escapeIcon.png'
import XpTooltip from "gui-common/components/XpTooltip";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {dashboardConfig} from "./dashboardConfig";
import {xpGridRemoveGrid} from "gui-common/xpGrid/xpGridsReducer";
import MaterialIcon from "material-icons-react";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function EmptyHolder ({translate}) {
    return (
        <div className="dashboard-dummy-component">
            <h4><XpTranslated trKey='dashboardComponents.empty.content'/></h4>
        </div>
    )
}


class DashboardComponentContainer extends Component {

    closeComponent(key, instanceId) {
        //console.log('key:', key);
        this.props.dashboardRemoveComponent(key);
        this.props.xpGridRemoveGrid(instanceId); // Remove grid state if the component has a grid component.
    }

    getComponentName(props) {
        if (this.props.componentData.name) return this.props.componentData.name;
        if (!dashboardConfig[this.props.componentData.componentName]) return "";
        const trKey = dashboardConfig[this.props.componentData.componentName].langPath + "name";
        return  this.props.translate(trKey);
    }

    constructor(props) {
        super(props);

        this.state = {
            componentName: this.getComponentName()
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }
    escFunction(event){
        if(event.keyCode === 27) {
            //Do whatever when esc is pressed
            this.escapeEdit();
        }
    }
    handleChange(event) {
        this.setState({componentName: event.target.value});
    }
    editName(name) {
        document.addEventListener("keydown", this.escFunction, false);
        this.setState({
            componentName: name,
            editName: true,
        });
    }
    escapeEdit() {
        document.removeEventListener("keydown", this.escFunction, false);
        this.setState({
            editName: false,
        });
    }
    saveName() {
        document.removeEventListener("keydown", this.escFunction, false);
        this.props.dashboardRenameComponent(this.props.componentData.key, this.state.componentName);
        this.setState({
            editName: false,
        });
    }

    handleSubmit(event) {
        this.saveName();
        event.preventDefault();
    }

    render() {
        const myData = this.props.componentData;

        let showName = "";
        let editName = "";
        if (myData.name) {
            showName = myData.name;
            editName = myData.name;
        }
        else if (dashboardConfig[myData.componentName]) {
            showName = <XpTranslated trKey={dashboardConfig[myData.componentName].langPath + "name"}/>;
            editName = this.props.translate(dashboardConfig[myData.componentName].langPath + "name");
        }

        moment.locale(this.props.currentLanguage);
        const CompToRender = ((myData.key === '0') && (myData.componentName === 'empty')) ? EmptyHolder : dashboardConfig[myData.componentName] ? dashboardConfig[myData.componentName].component : undefined;
        if (!CompToRender) return null;

        let ratio = this.props.dashboardState.sizeInfo.panelWidth / this.props.dashboardState.sizeInfo.cols * myData.dataGrid.w /  this.props.dashboardState.sizeInfo.vwWidth;
        const instanceId = 'dashboard-' + myData.componentName + '-' + myData.key;

        return (
            <div className="dashboardHolder" xp-test-id={'xpDashboardComponent-'+myData.key}>
                {!dashboardConfig[myData.componentName] &&
                <div className="dashBoardComponentHeader" style={{height: '42px'}}>
                </div>}
                {dashboardConfig[myData.componentName] &&
                <div className="dashBoardComponentHeader">
                    <div className="dashboardIcon dashboardIconInComponent">
                        <span className="icon-i"><MaterialIcon icon={dashboardConfig[myData.componentName].icon} size={16} invert/></span>
                    </div>
                    {(this.state.editName) &&
                    <form onSubmit={this.handleSubmit}>
                        <XpTooltip trKey='dashboardComponents.cancelEditTooltip'>
                            <img type="image" src={escapeIcon} alt="escape" name="escape" className="closeDashboardComponentButton" onClick={() => this.escapeEdit()}/>
                        </XpTooltip>
                        <XpTooltip trKey='dashboardComponents.saveComponentNameTooltip'>
                            <img type="image" src={readyIcon} alt="save" name="save" className="closeDashboardComponentButton" onClick={() => this.saveName()}/>
                        </XpTooltip>
                        <div className="dashBoardComponentInputWrapper">
                            <input key="componentHeader" type="text" value={this.state.componentName} onChange={this.handleChange} className="dashBoardComponentHeaderInput" autoFocus={true}/>
                        </div>
                    </form>}
                    {(!this.state.editName) && <div>
                        <XpTooltip trKey='dashboardComponents.closeComponentTooltip'>
                            <img src={removeIcon} alt="Close" className="closeDashboardComponentButton" xp-test-id='xpDashboardComponentCloseButton' onClick={() => this.closeComponent(myData.key, instanceId)}/>
                        </XpTooltip>
                        <XpTooltip trKey='dashboardComponents.editHeaderTooltip'>
                            <img src={editIcon} alt="Edit" className="closeDashboardComponentButton" onClick={() => this.editName(editName)}/>
                        </XpTooltip>
                        <XpTooltip trKey='dashboardComponents.containerTooltip'>
                            <div className="dashBoardComponentHeaderText"  onDoubleClick={() => this.editName(editName)}>{showName}</div>
                        </XpTooltip>
                    </div>}
                </div>}
                <div className="dashBoardComponentContent">
                    <CompToRender
                        sizeRelation={ratio}
                        instanceId={instanceId}
                        fillAvailableSpace={true}
                        translate={this.props.translate}
                        componentData={this.props.componentData}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboardState:         state.dashboardState,
        translate:          selectTranslateFunction(state),
        currentLanguage:    selectActiveLanguage(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLayout :  (layouts)   => dispatch(dashboardSetLayout(layouts)),
        setSizeInfo : (sizeInfo) => dispatch(dashboardSetSizeInfo(sizeInfo)),
        dashboardRemoveComponent :  (key)   => dispatch(dashboardRemoveComponent(key)),
        dashboardRenameComponent : (key, name) => dispatch(dashboardRenameComponent(key, name)),
        xpGridRemoveGrid         : (instanceId) => dispatch(xpGridRemoveGrid(instanceId)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponentContainer);
