import {createSelector} from "reselect";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {findAndBuildEnrichedEntity, getOrAddCashedObject, getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {translateBoolFields} from "gui-common/xpGrid/xpGridFunctions";
import {shallowActiveProvidersSelector} from "gui-common/orm/ormSelectors";
import {getDefaultObject} from "gui-common/functions/functions";
import {
    addParentRunConfigurationElements,
    createMergedRunConfigurationElements
} from "gui-common/runConfiguration/runConfigurationFunctions";
import {getClientBankAccounts} from "xpool-gui-common/features/client/clientSelectors";
import {selectUserPreferencesActiveClientId} from "gui-common/userSettings/userSettingsSelectors";
import {entityIsActive, getLastChange} from "gui-common/audit/auditFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

/********* LegalEntityUnit selectors *****************************************************************/

export const shallowActiveLegalEntityUnitsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => selectUserPreferencesActiveClientId(state),
    (session, activeClientId) => {

        const activeClient = getOrmModelObjectById(session, 'Client', activeClientId);
        if (!activeClient) return [];

        let returnArray = [];
        activeClient.legalEntities.filter(entityIsActive).toModelArray().forEach(legalEntity => {
            legalEntity.legalEntityUnits.filter(entityIsActive).toModelArray().forEach(legalEntityUnits => {
                const obj = legalEntityUnits.ref;
                returnArray.push(Object.assign({}, obj, {
                    client: activeClient.ref,
                    legalEntity: legalEntity.ref,
                }));
            });
        });
//        console.log("Selected entity units:", returnArray);
        return returnArray;
    }
);

export const legalEntityUnitsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector legalEntityUnitsSelector running ");

        let cashedLes     = {};
        let cashedClients = {};
        const legalEntityUnits = session.LegalEntityUnit.filter(entityIsActive).orderBy('id', 'desc').toModelArray().map(leuModel => {
            const legalEntity =               getOrAddCashedObject(leuModel.ref.legalEntityId , 'LegalEntity' , cashedLes     , session);
            const client      = legalEntity ? getOrAddCashedObject(legalEntity.clientId       , 'Client'      , cashedClients , session) : undefined;

            return {
                ...leuModel.ref,
                client: client,
                clientId: client?.id,
                legalEntity: legalEntity,
                ...getLastChange('LegalEntityUnit', leuModel),
            }
        })

        return legalEntityUnits;
    }
);


export function buildLegalEntityUnitObject(legalEntityUnitModelObject, getDeep) {

    const legalEntityModelObject = legalEntityUnitModelObject.legalEntityId;
    const clientModelObject      = legalEntityModelObject ? legalEntityModelObject.clientId : undefined;

    const returnObject = {
        ...legalEntityUnitModelObject.ref,
        legalEntity  : legalEntityModelObject ? legalEntityModelObject.ref : undefined,
        client       : clientModelObject      ? clientModelObject.ref      : undefined,
        clientId     : clientModelObject      ? clientModelObject.ref.id   : undefined,
        bankAccounts : getDeep ? legalEntityUnitModelObject.bankAccounts.filter(entityIsActive).orderBy('id', 'asc').toRefArray() : undefined,
        clientBankAccounts: getDeep ? getClientBankAccounts(clientModelObject) : undefined,
        runConfiguration: createMergedRunConfigurationElements(legalEntityUnitModelObject, 'LegalEntityUnit'),
    };
    return returnObject;
}


export const getSpecificLegalEntityUnitSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, legalEntityId) => findAndBuildEnrichedEntity(session, 'LegalEntityUnit', legalEntityId, buildLegalEntityUnitObject,true)
);

/*
export const getSpecificLegalEntityUnitSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    legalEntityUnitsSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, legalEntityUnits, selectIdId) => {
        if (!selectIdId) return undefined;
        if (!legalEntityUnits || !legalEntityUnits.length) return undefined;

        const legalEntityUnit = legalEntityUnits.find(item => item.id === selectIdId);
        if (!legalEntityUnit) return undefined;

        return enrichLegalEntityUnit(session, legalEntityUnit);
    }
);
*/


const translate            = (state, props) => selectTranslateFunction(state);
const legalEntityUnits  = state          => legalEntityUnitsSelector(state);
const filterFunction       = (state, props) => props ? props.filterFunction : undefined;
const inputData        = (state, props) => props ? props.inputData      : undefined;

export const getLegalEntityUnitsListDataSelector = () => createSelector(
    [legalEntityUnits, translate, filterFunction, inputData],
    (legalEntityUnits, translate, filterFunction, inputData) => {
        // console.log("Selector getLegalEntityUnitsListDataSelector running ");

        const leus = inputData ? inputData : filterFunction ? legalEntityUnits.filter(filterFunction) : legalEntityUnits;

        let returnArray       = [];
        for (let leu of leus) {
            const enrichedLeu = {...leu};

            translateBoolFields(enrichedLeu, translate);

            if (leu.fxProviderId) enrichedLeu.fxProviderName = leu.fxProvider ? leu.fxProvider.name : "";
            enrichedLeu.fxShortNameT = leu.fxEnabled ? leu.fxShortName : "";

            returnArray.push(enrichedLeu);
        }
        // console.log("Selected getLegalEntityUnitsListDataSelector:", returnArray);
        return returnArray;
    }
);

/********* New legal entity unit template selector *****************************************************************/

const ormProviders   = state => shallowActiveProvidersSelector(state);
const parentItemData = (state, props) => props ? props.parentItemData : undefined;
export const newLegalEntityUnitFormTemplateSelector = ormCreateSelector(
    orm,
    dbStateSelector, ormProviders, parentItemData,
    (session       , ormProviders, parentItemData) => {
        const ormFxProviders = ormProviders.filter(item => item.providerType === 'FX');
        const defaultFxProvider = getDefaultObject(ormFxProviders);

        let parentRunConfigurations = {};
        const parentModelObject = getOrmModelObjectById(session, 'LegalEntity', parentItemData ? parentItemData.id : undefined);
        addParentRunConfigurationElements(parentModelObject, 'LegalEntity', parentRunConfigurations)

        return {
            fxProviderId : defaultFxProvider ? defaultFxProvider.id : "",
            legalEntityId: parentItemData ? parentItemData.id : "", // Set legalEntityId from super entity legalEntity, if provided
            runConfiguration:       parentRunConfigurations,
            id:                     "",
            name:                   "",
            description:            "",
            fxEnabled:              true,
            fxShortName:            "",
            createdDateTime:        null,
            createdByUser:          "",
            changedDateTime:        null,
            changedByUser:          "",
        }
    }
);



