import {genericFetch}                                   from "gui-common/api/apiCommon";
import {fxRejectConfigTransformer, createFxRejectConfigBody} from "gui-common/fxRejectConfig/fxRejectConfigFunctions"
import {apiBaseUrlMap} from "appConfig/api/apiConfig";
import {
    ormEntitiesClearModelOnCondition,
    ormEntitiesGetStarted,
    ormEntitiesReceived
} from "gui-common/orm/ormReducer";
import {xpFormApiClear, xpFormApiSubmit, xpFormLoadForm} from "gui-common/xpForm/core/xpFormReducer";

/* -----------------------------------------------------------------------------------------------------------------
* Functions to get fxRejectConfiguration from BE including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getFxRejectConfigFromApi(rejectType) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!rejectType) {
                console.error("No rejectType in getFxRejectConfigFromApi");
                return;
            }
            let logText = "Get FxRejectConfiguration for " + rejectType;
            let requestUrl = apiBaseUrlMap.fxRejectConfig + "?rejectType=" + rejectType + "&";

            dispatch(ormEntitiesGetStarted('FxRejectConfiguration'));
            dispatch(genericFetch(
                requestUrl,
                logText,
                getResponseHandler(rejectType),
                getFailHandler(rejectType),
                getFailHandler(rejectType),
                'GET',
                undefined, // no body in http request needed for GET.
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

function getResponseHandler(rejectType) {
    return (resp) => {
        return (dispatch, getState) => {
            if (!resp.rejectType) {
                console.error("No rejectType in getFxRejectConfigFromApi", resp);
                return;
            }
            if (resp.rejectType !== rejectType) {
                console.error("Received different rejectType than requested in getFxRejectConfigFromApi", rejectType, resp.rejectType);
                return;
            }
            let transformedResponse = fxRejectConfigTransformer(resp);

            dispatch(ormEntitiesClearModelOnCondition('FxRejectConfiguration', item => item.rejectType === rejectType))
            if (transformedResponse?.configArray?.length) {
                dispatch(ormEntitiesReceived('FxRejectConfiguration', transformedResponse.configArray));
            }
        }
    }
}
function getFailHandler(rejectType) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}

export function gotFxRejectConfigurationFromSocket(payload) {
    return (dispatch, getState) => {
        const formModel = 'fxRejectConfigForm.' + payload.rejectType;
        let transformedResponse = fxRejectConfigTransformer(payload);

        dispatch(xpFormApiSubmit(formModel))
        dispatch(ormEntitiesClearModelOnCondition('FxRejectConfiguration', item => item.rejectType === payload.rejectType))
        if (transformedResponse?.configArray?.length) {
            dispatch(ormEntitiesReceived('FxRejectConfiguration', transformedResponse.configArray));
        }
        dispatch(xpFormLoadForm(formModel, {}))
        dispatch(xpFormApiClear(formModel))

    }
}


/* -----------------------------------------------------------------------------------------------------------------
* Functions to add user message including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function putFxRejectConfigToApi(rejectType, configArray) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const formModel = 'fxRejectConfigForm.' + rejectType;
            if (!rejectType) {
                console.error("No rejectType in getFxRejectConfigFromApi");
                return;
            }
            if (!configArray) {
                console.error("No configArray in getFxRejectConfigFromApi");
                return;
            }

            let logText = "Put FxRejectConfiguration for " + rejectType;
            let requestUrl = apiBaseUrlMap.fxRejectConfig + "?";

            dispatch(xpFormApiSubmit(formModel))
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                // getResponseHandler(rejectType, formModel), // Yes, should be the same functions. The whole array is modified at the same time.
                getFailHandler(rejectType, formModel),
                getFailHandler(rejectType, formModel),
                'POST',
                createFxRejectConfigBody({rejectType: rejectType, configArray: configArray}),
            )).then(result => {
/*
                dispatch(xpFormLoadForm(formModel, {}))
                dispatch(xpFormApiClear(formModel))
*/
                resolve(result)
            }).catch(err => {
                dispatch(xpFormApiClear(formModel))
                // reject(err)
            })
        })
    }
}
