import hash from "object-hash"
import {setSavedUserState} from "gui-common/userSettings/userState/userStateReducer"
import {postUserSetting} from "gui-common/userSettings/userState/userSettingsApi";
import {userStatesSelector} from "gui-common/userSettings/userState/userStateSelectors";


/* -----------------------------------------------------------------------------------------------------------------
* Check if user settings has changed and update profile to BE if changed.
* -----------------------------------------------------------------------------------------------------------------*/

/*
export const userStates = {
    "xpGridsState"           : {reloadFunction: xpGridsReloadState           , resetFunction: xpGridsResetState           },
    "dashboardState"         : {reloadFunction: dashboardReloadState         , resetFunction: dashboardResetState         },
    "integrationStatusState" : {reloadFunction: integrationStatusReloadState , resetFunction: integrationStatusResetState },
    "xpAccordionState"       : {reloadFunction: xpAccordionReloadState       , resetFunction: xpAccordionResetState       },
    "userPreferencesState"   : {reloadFunction: userPreferencesReloadState   , resetFunction: userPreferencesResetState   },
    "savedUserStatesState"   : {reloadFunction: reloadSavedUserState         , resetFunction: resetSavedUserState         },
    ...getAppSpecificUserStates(),
};
*/

export function checkAndUpdateUserStateOnTimer() {
    return (dispatch, getState) => {
        if (getState().loginProcessState.appReady === true) dispatch(checkAndUpdateUserState());
        setTimeout(() => {
            dispatch(checkAndUpdateUserStateOnTimer());
        }, 5000);
    }
}

export function checkAndUpdateUserState() {
    return (dispatch, getState) => {
        const currentState = getState();
        const userStates = userStatesSelector(undefined);
        if (!currentState.loginProcessState.userLoggedIn) return;
        const allUserStates = {...userStates, ...getState().appConfigState.appUserStates};
        for (let userStateId in allUserStates) {

            const currentUserState = hash(currentState[userStateId]);
            const savedUserState = currentState.userStateState[userStateId];

            if (!savedUserState || (currentUserState !== savedUserState)) {
//                console.log("Saving " + userStateId + ": ", currentUserState);
                dispatch(postUserSetting(userStateId, currentState[userStateId]));
                dispatch(setSavedUserState(userStateId, currentUserState));
//                console.log(userStateId + " update");
            }
        }
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Clear out userBlobs.
* -----------------------------------------------------------------------------------------------------------------*/
/*
export function clearUserBlobs() {
    return (dispatch, getState) => {
        const currentState = getState();
        for (let userStateId in userStates) {
            dispatch(deleteUserSetting(userStateId, currentState[userStateId]));
            dispatch(userStates[userStateId].resetFunction());
        }
        dispatch(resetUserStateState());
    }
}
*/
