import React, { Component } from 'react'
import PropTypes from 'prop-types';
import XpTooltip from "gui-common/components/XpTooltip";

class ShowValueBeforeChange extends Component {
    render() {
        return (
            <XpTooltip trKey={ this.props.suppressToolTip ? undefined : 'general.valueBeforeChangeToolTip'} placement="top">
                <div className={"oldValueSpan " + (this.props.className ? this.props.className: "")}> {this.props.valueBeforeChange}</div>
            </XpTooltip>
        );
    }
}

ShowValueBeforeChange.propTypes = {
    valueBeforeChange: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    displayTooltip   : PropTypes.bool,
    className        : PropTypes.string,
};

ShowValueBeforeChange.defaultProps = {
    displayTooltip : true,
};

export default ShowValueBeforeChange;

