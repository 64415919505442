import React, {Component, useEffect, useMemo, useState} from "react";
import {connect, useSelector} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getOrmItemLoadingSelector} from "gui-common/orm/ormLoadingSelectors";
import {selectDecDenLangState, selectNumberFormatter} from "gui-common/numberFormat/numberFormatSelectors";
import moment from "moment";
import {calculateDisplayString} from "gui-common/functions/functions";
import {speedFormatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import spinnerGif from "gui-common/resources/spinner.gif";
import XpTooltip from "gui-common/components/XpTooltip";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function StaticPropertyField (props) {
    const rowObject      = useSelectorInstance(props.cellRendererParams.getRowObjectSelector   , {selectId: props.rowObjectId})
    const selectObject   = useSelectorInstance(props.cellRendererParams.getSelectObjectSelector, {selectId: props.selectObjectId})
    const translate = useSelector(selectTranslateFunction);

    const selectObjectLoading = useSelectorInstance(getOrmItemLoadingSelector, {ormModel: props.cellRendererParams.selectObjectOrmModel, itemId: props.selectObjectId})

    const numberFormatter = useSelector(selectNumberFormatter);
    const decDenLangState = useSelector(selectDecDenLangState);
    const currentLanguage = useSelector(selectActiveLanguage);

    const [currentRenderValue, setCurrentRenderValue]   = useState(undefined);
    const [isFirstRender     , setIsFirstRender     ]   = useState(true);
    const [flashCss          , setFlashCss          ]   = useState("");

    const isDate = useMemo(
        () => {
            if (!selectObject ||!selectObject[props.cellRendererParams.displayField]) return false;
            if (typeof selectObject[props.cellRendererParams.displayField] === 'number') return false;
            return moment(new Date(selectObject[props.cellRendererParams.displayField])).isValid();
        },
        [selectObject]
    );

    const renderValue = useMemo(
        () => {
            const toRender = selectObject ? selectObject[props.cellRendererParams.displayField] : undefined;
            if ((toRender === undefined) || (toRender === null)) {
                return {
                    toRender: <XpTranslated trKey='general.noValueAvailable'/>,
                    textValue: translate('general.noValueAvailable'),
                }
            }

            let returnValue = "";

            if (isDate) returnValue = calculateDisplayString(toRender, props.format, currentLanguage);

            if (typeof toRender === 'number') returnValue = speedFormatAmount(toRender, decDenLangState, numberFormatter);
            if (typeof toRender === 'string') returnValue = toRender;

            if (props.cellRendererParams.enrichRenderValue) returnValue = props.cellRendererParams.enrichRenderValue(selectObject, rowObject, returnValue);
            return {
                toRender: returnValue,
                textValue: String(returnValue)
            };
        },
        [props.cellRendererParams.displayField, selectObject, rowObject, isDate, decDenLangState]
    );

    const showWaitingSpinner = useMemo(
        () => {
            if (selectObjectLoading) return true;
            if (props.cellRendererParams.setWaitingSpinner && props.cellRendererParams.setWaitingSpinner(selectObject, rowObject, renderValue)) return true;
            return false;
        },
        [selectObjectLoading, renderValue, rowObject, selectObject]
    );


    useEffect(
        () => {
            if (renderValue.toRender !== currentRenderValue) {
                setCurrentRenderValue(renderValue.toRender);
                props.setCurrentTextValue(renderValue.textValue);
                if (isFirstRender) {
                    setIsFirstRender(false);
                    return;
                }
                if (props.cellRendererParams.suppressCellFlash) return;
                setFlashCss('changedRendererCell');

                setTimeout(() => {
                    setFlashCss('flashRendererCell');
                }, 200);
            }
        },
        [renderValue],
    );

    const renderStyle = useMemo(
        () => {
            return props.cellRendererParams.getStyle ? props.cellRendererParams.getStyle(selectObject, rowObject) : {};
        },
        [props.cellRendererParams.getStyle, selectObject, props.cellRendererParams.displayField, rowObject]
    );

    const tooltipTrKey = useMemo(
        () => {
            if (!props.cellRendererParams.getTooltipTrKey) return;
            return props.cellRendererParams.getTooltipTrKey(selectObject, rowObject);
        },
        [props.cellRendererParams.getTooltipTrKey, rowObject]
    );

    const tooltipTrParams = useMemo(
        () => {
            if (!props.cellRendererParams.getTooltipTrParams) return;
            return props.cellRendererParams.getTooltipTrParams(selectObject, rowObject);
        },
        [props.cellRendererParams.getTooltipTrParams, rowObject]
    );



    return (
        <XpTooltip bypass={!tooltipTrKey} trKey={tooltipTrKey} trParams={tooltipTrParams}>
            <div style={{padding: '0 8px 0 8px', ...renderStyle}} className={flashCss} onTransitionEnd={() => {if (flashCss === 'flashRendererCell') setFlashCss("");}}>
                {(showWaitingSpinner && !props.cellRendererParams.disableSpinner) &&
                <img className="smallTableSpinner" src={spinnerGif} alt="waiting"/>}
                {renderValue.toRender}
            </div>
        </XpTooltip>
    )
}

class XpGridObjectPropertyRenderer extends Component {
    constructor(props) {
        super(props)
        this.updateGridValue = this.updateGridValue.bind(this);
        this.state = {
            colId: props.colDef.colId,
            node: props.node,
            api: props.api,
            currentRenderedTextValue: undefined,
            // The grid must get the rendered value in the columns value data in order to get the filter and sorting functions to work.
            updateGridValueForSortAndFilter: (props.colDef.sortable || props.colDef.filter),
        };
    }
    refresh(params) {
        if (!this.state.updateGridValueForSortAndFilter) return true;
        if (this.state.currentRenderedTextValue === undefined) return true; // First render. State value has not been set when first refresh from render value is executed.

        if (params.value !== this.state.currentRenderedTextValue) {
            // The grid must get the rendered value in the columns value data in order to get the filter and sorting functions to work.
            params.setValue(this.state.currentRenderedTextValue);
            // params.api.refreshClientSideRowModel();
        }
        return true;
    }

    updateGridValue(value) {
        this.state.node.setDataValue(this.state.colId, value);
        this.state.api.refreshClientSideRowModel();
    }

    render () {
        // if (this.props.value === undefined) return "";
        // if (this.props.value === null)      return <XpTranslated trKey='general.noValueAvailable'/>;

        if (!this.props.colDef.cellRendererParams) return null;
        return (
            <StaticPropertyField
                rowObjectId         = {this.props.data.id}
                selectObjectId      = {this.props.data[this.props.colDef.cellRendererParams.selectObjectIdField]}
                // selectId         = {this.props.value}
                rowNode             = {this.props.node}
                column              = {this.props.column}
                setCurrentTextValue = {val => {
                    this.setState({...this.state, currentRenderedTextValue: val});
                    this.updateGridValue(val)
                }}
                cellRendererParams  = {this.props.colDef.cellRendererParams}
            />
        );
    }
}
const mapStateToProps    = (state, props) => {return {}}
const mapDispatchToProps = (dispatch)     => {return {}}
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(XpGridObjectPropertyRenderer );
