import moment from "moment";
import React from "react";
import {aggregateOverDimensions} from "gui-common/report/reportFunctions";
import {AggregateOverDimensionsReport} from "gui-common/report/reportComponents/AggregateOverDimensionsReport";

/*
export const ordersReportFields = {
    id                      : {hide: true},
    clientId                : {hide: true},
    clientName              : {hide: true},
    baseCurrency            : {hide: true},
    currencyPairStr         : {hide: true},
    buySellIndicator        : {hide: true},
    nominalAmount           : {hide: true},
    reportCurrencyAmount    : {hide: true},
    fxRateExecution         : {hide: true},
    fxRateFarLegExecution   : {hide: true},
    orderType               : {hide: true},
    orderStatus             : {hide: true},
    externalReference       : {hide: true},
    year                    : {hide: true},
    month                   : {hide: true},
    yearMonth               : {hide: true},
    yearNum                 : {hide: true},
    monthNum                : {hide: true},
    createdDateTime         : {hide: true, isDateTime: true},
    modifiedDateTime        : {hide: true, isDateTime: true},
}


const transformerMap = {
    nominalAmount         : val => Number(val).toFixed(2),
    reportCurrencyAmount  : val => Number(val).toFixed(2),
    fxRateExecution       : val => Number(val).toFixed(4),
    fxRateFarLegExecution : val => Number(val).toFixed(4),
}
*/
export function enrichOrdersReportData(item) {
    // applyFieldTransformerMap(transformerMap, item);
/*
    if (item.nominalAmount         ) item.nominalAmount         = Number(item.nominalAmount).toFixed(2);
    if (item.reportCurrencyAmount  ) item.reportCurrencyAmount  = Number(item.reportCurrencyAmount).toFixed(2);
    if (item.fxRateExecution       ) item.fxRateExecution       = Number(item.fxRateExecution).toFixed(4);
    if (item.fxRateFarLegExecution ) item.fxRateFarLegExecution = Number(item.fxRateFarLegExecution).toFixed(4);
*/
}


function addNominal(item) {
    return item.nominalAmount;
}
function addReportCurrency (item) {
    return item.reportCurrencyAmount;
}
function countItems() {
    return 1;
}

export function OrdersTurnoverAllClients({reportData, reportProps}) {
    let dimensions = [];
    dimensions.push({dimension: 'yearMonth'     , aggregators: {tradeCount: countItems, sumReportCurrency: addReportCurrency}});
    dimensions.push({dimension: 'nominalCurrency', aggregators: {tradeCount: countItems, sumNominal: addNominal, sumReportCurrency: addReportCurrency}});
    let aggregatedData = aggregateOverDimensions(reportData, dimensions);
    return (
        <AggregateOverDimensionsReport
            reportData          = {aggregatedData}
            reportProps         = {reportProps}
            dimensions          = {dimensions}
            wrapFirstLevel      = {true}
            hideFirstHeaderName = {false}
        />
    )
}
export function OrdersTurnoverPerClient({reportData, reportProps}) {
    let dimensions = [];
    dimensions.push({dimension: 'clientName'  , aggregators: {tradeCount: countItems, sumReportCurrency: addReportCurrency}});
    dimensions.push({dimension: 'yearMonth'   , aggregators: {tradeCount: countItems, sumReportCurrency: addReportCurrency}});
    let aggregatedData = aggregateOverDimensions(reportData, dimensions);
    return (
        <AggregateOverDimensionsReport
            reportData          = {aggregatedData}
            reportProps         = {reportProps}
            dimensions          = {dimensions}
            wrapFirstLevel      = {true}
            hideFirstHeaderName = {false}
        />
    )
}
export function OrdersTurnoverPerClientCompact({reportData, reportProps}) {
    let dimensions = [];
    dimensions.push({dimension: 'yearMonth' , aggregators: {tradeCount: countItems, sumReportCurrency: addReportCurrency}});
    dimensions.push({dimension: 'clientName', aggregators: {tradeCount: countItems, sumReportCurrency: addReportCurrency}});
    let aggregatedData = aggregateOverDimensions(reportData, dimensions);
    return (
        <AggregateOverDimensionsReport
            reportData          = {aggregatedData}
            reportProps         = {reportProps}
            dimensions          = {dimensions}
            wrapFirstLevel      = {true}
            hideFirstHeaderName = {false}
        />
    )
}
export function OrdersTurnoverForOneClient({reportData, reportConfig, reportProps}) {
    let dimensions = [];
    const filteredReportData = (reportConfig && reportConfig.showClientId !== "") ? reportData.filter(item => item.clientId === Number(reportConfig.showClientId)) : reportData;
    dimensions.push({dimension: 'clientName'  , aggregators: {tradeCount: countItems, sumReportCurrency: addReportCurrency}});
    dimensions.push({dimension: 'yearMonth'   , aggregators: {tradeCount: countItems, sumReportCurrency: addReportCurrency}});
    dimensions.push({dimension: 'nominalCurrency', aggregators: {tradeCount: countItems, sumNominal: addNominal, sumReportCurrency: addReportCurrency}});
    let aggregatedData = aggregateOverDimensions(filteredReportData, dimensions);
    let reportHeader = reportProps.translate(reportProps.langPath+'.name', {clientName: (reportConfig.showClientId === "") ? reportProps.translate(reportProps.langPath+'.allClients') : reportConfig.showClientName})
    return (
        <AggregateOverDimensionsReport
            reportData          = {aggregatedData}
            reportProps         = {reportProps}
            dimensions          = {dimensions}
            reportHeader        = {reportHeader}
            wrapFirstLevel      = {false}
            hideFirstHeaderName = {(reportConfig && (reportConfig.showClientId !== ""))}
        />
    )
}


export const ordersReportMockData = [
    {id:  1, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 4566  , reportCurrencyAmount: 54551  , modifiedDateTime: moment("2018-11-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 11.94714351},
    {id:  3, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 567656, reportCurrencyAmount: 6781868, modifiedDateTime: moment("2018-11-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 11.94714351},
    {id:  4, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 4566  , reportCurrencyAmount: 54551  , modifiedDateTime: moment("2018-12-23").toISOString(), buySellIndicator: "", clientName: "Svennes Segel", clientId: 35, fxRateExecution: 11.94714351},
    {id:  5, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 567656, reportCurrencyAmount: 6781868, modifiedDateTime: moment("2018-12-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 11.94714351},
    {id:  6, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 76854 , reportCurrencyAmount: 918186 , modifiedDateTime: moment("2019-01-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 11.94714351},
    {id:  7, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 223   , reportCurrencyAmount: 2664   , modifiedDateTime: moment("2019-01-23").toISOString(), buySellIndicator: "", clientName: "Svennes Segel", clientId: 35, fxRateExecution: 11.94714351},
    {id:  8, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 5678  , reportCurrencyAmount: 67836  , modifiedDateTime: moment("2019-02-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 11.94714351},
    {id:  9, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 789765, reportCurrencyAmount: 9435436, modifiedDateTime: moment("2019-02-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 11.94714351},
    {id: 10, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 12345 , reportCurrencyAmount: 147487 , modifiedDateTime: moment("2019-02-26").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 11.94714351},
    {id: 11, baseCurrencyId: 4670032, baseCurrency: "GBP", currencyPairStr: "GBP/SEK", nominalAmount: 5674  , reportCurrencyAmount: 67788  , modifiedDateTime: moment("2019-03-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 11.94714351},

    {id: 12, baseCurrencyId: 5591876, baseCurrency: "USD", currencyPairStr: "USD/SEK", nominalAmount: 45645 , reportCurrencyAmount: 441037 , modifiedDateTime: moment("2018-12-23").toISOString(), buySellIndicator: "", clientName: "Svennes Segel", clientId: 35, fxRateExecution: 9.662324},
    {id: 13, baseCurrencyId: 5591876, baseCurrency: "USD", currencyPairStr: "USD/SEK", nominalAmount: 56789 , reportCurrencyAmount: 548714 , modifiedDateTime: moment("2018-12-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 9.662324},
    {id: 14, baseCurrencyId: 5591876, baseCurrency: "USD", currencyPairStr: "USD/SEK", nominalAmount: 3434  , reportCurrencyAmount: 33180  , modifiedDateTime: moment("2019-01-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 9.662324},
    {id: 15, baseCurrencyId: 5591876, baseCurrency: "USD", currencyPairStr: "USD/SEK", nominalAmount: 356457, reportCurrencyAmount: 3444203, modifiedDateTime: moment("2019-01-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 9.662324},
    {id: 16, baseCurrencyId: 5591876, baseCurrency: "USD", currencyPairStr: "USD/SEK", nominalAmount: 3433  , reportCurrencyAmount: 33171  , modifiedDateTime: moment("2019-02-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 9.662324},
    {id: 17, baseCurrencyId: 5591876, baseCurrency: "USD", currencyPairStr: "USD/SEK", nominalAmount: 46776 , reportCurrencyAmount: 451965 , modifiedDateTime: moment("2019-03-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 9.662324},
    {id: 18, baseCurrencyId: 5591876, baseCurrency: "USD", currencyPairStr: "USD/SEK", nominalAmount: 7897  , reportCurrencyAmount: 76303  , modifiedDateTime: moment("2019-03-23").toISOString(), buySellIndicator: "", clientName: "Svennes Segel", clientId: 35, fxRateExecution: 9.662324},
    {id: 19, baseCurrencyId: 5591876, baseCurrency: "USD", currencyPairStr: "USD/SEK", nominalAmount: 46776 , reportCurrencyAmount: 451965 , modifiedDateTime: moment("2019-06-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 9.662324},
    {id: 20, baseCurrencyId: 5591876, baseCurrency: "USD", currencyPairStr: "USD/SEK", nominalAmount: 7897  , reportCurrencyAmount: 76303  , modifiedDateTime: moment("2019-06-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 9.662324},

    {id: 21, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 78978 , reportCurrencyAmount: 843154 , modifiedDateTime: moment("2018-11-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 10.67581074},
    {id: 22, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 5673  , reportCurrencyAmount: 60564  , modifiedDateTime: moment("2018-11-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 10.67581074},
    {id: 23, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 78978 , reportCurrencyAmount: 843154 , modifiedDateTime: moment("2019-01-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 10.67581074},
    {id: 24, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 5673  , reportCurrencyAmount: 60564  , modifiedDateTime: moment("2019-02-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 10.67581074},
    {id: 25, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 24524 , reportCurrencyAmount: 261814 , modifiedDateTime: moment("2019-02-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 10.67581074},
    {id: 26, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 45445 , reportCurrencyAmount: 485162 , modifiedDateTime: moment("2019-03-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 10.67581074},
    {id: 27, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 5688  , reportCurrencyAmount: 60724  , modifiedDateTime: moment("2019-03-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 10.67581074},
    {id: 28, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 745   , reportCurrencyAmount: 7953   , modifiedDateTime: moment("2019-04-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 10.67581074},
    {id: 29, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 67867 , reportCurrencyAmount: 724535 , modifiedDateTime: moment("2019-04-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 10.67581074},
//    {id: 30, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 745   , reportCurrencyAmount: 7953   , modifiedDateTime: moment("2019-05-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor", clientId: 24, fxRateExecution: 10.67581074},
    {id: 31, baseCurrencyId: 4543826, baseCurrency: "EUR", currencyPairStr: "EUR/SEK", nominalAmount: 67867 , reportCurrencyAmount: 724535 , modifiedDateTime: moment("2019-05-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 10.67581074},

    {id: 32, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 78978 , reportCurrencyAmount: 85332  , modifiedDateTime: moment("2018-12-23").toISOString(), buySellIndicator: "", clientName: "Svennes Segel", clientId: 35, fxRateExecution: 1.08045303},
    {id: 33, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 7898  , reportCurrencyAmount: 8533   , modifiedDateTime: moment("2019-02-23").toISOString(), buySellIndicator: "", clientName: "Svennes Segel", clientId: 35, fxRateExecution: 1.08045303},
    {id: 34, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 242   , reportCurrencyAmount: 261    , modifiedDateTime: moment("2019-02-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 1.08045303},
    {id: 35, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 2345  , reportCurrencyAmount: 2534   , modifiedDateTime: moment("2019-03-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 1.08045303},
    {id: 36, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 7656  , reportCurrencyAmount: 8272   , modifiedDateTime: moment("2019-03-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 1.08045303},
    {id: 37, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 234245, reportCurrencyAmount: 253091 , modifiedDateTime: moment("2019-04-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor" , clientId: 24, fxRateExecution: 1.08045303},
    {id: 38, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 7689  , reportCurrencyAmount: 8308   , modifiedDateTime: moment("2019-04-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 1.08045303},
//    {id: 39, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 234245, reportCurrencyAmount: 253091 , modifiedDateTime: moment("2019-05-23").toISOString(), buySellIndicator: "", clientName: "Kalles kulor", clientId: 24, fxRateExecution: 1.08045303},
    {id: 40, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 7689  , reportCurrencyAmount: 8308   , modifiedDateTime: moment("2019-05-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide"   , clientId: 23, fxRateExecution: 1.08045303},
//    {id: 41, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 7689  , reportCurrencyAmount: 8308   , modifiedDateTime: moment("2019-07-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide", clientId: 23, fxRateExecution: 1.08045303},
//    {id: 42, baseCurrencyId: 5132107, baseCurrency: "NOK", currencyPairStr: "NOK/SEK", nominalAmount: 7689  , reportCurrencyAmount: 8308   , modifiedDateTime: moment("2019-08-23").toISOString(), buySellIndicator: "", clientName: "Svea Smide", clientId: 23, fxRateExecution: 1.08045303},
];
