import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {getOrmItemSelectorGetter} from "gui-common/orm/ormSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG, CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import moment from "moment";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {disableLedgerAccountBalanceToApi} from "gui-common/ledger/ledgerApi";
import {accountBalanceTypes} from "xpool-gui-common/features/bankAccountBalance/accountBalanceConstants";
import {getBankAccountFromLedgerAccountIdSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import {pushDelayedModal} from "gui-common/functions/thunks";


export function prepareAccountBalanceGridColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState,'id',               50 , 30 , 'accountBalanceList.headers.id');
    xpColCreate(translate, subArray, hiddenState,'accountName' ,  120, 50 , 'accountsList.name',
        {
            cellRenderer: 'xpGridObjectPropertyRenderer',
            cellRendererParams: {
                getSelectObjectSelector: getBankAccountFromLedgerAccountIdSelector,
                getRowObjectSelector: getOrmItemSelectorGetter('LedgerAccountBalance'),
                selectObjectOrmModel: 'BankAccount',
                selectObjectIdField: 'ledgerAccountId',
                displayField: 'name',
            },
            cellStyle: {textAlign: "left"}
        });
    xpColCreate(translate, subArray, hiddenState,'valueDate',    100, 50, 'accountBalanceList.headers.valueDate');
/*
    xpColCreate(translate, subArray, hiddenState, 'typeT'     , 150, 50, 'accountBalanceList.headers.type', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: accountBalanceTypes.map(item => 'accountBalanceList.balanceTypes.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
*/
    xpColCreate(translate, subArray, hiddenState,'balance',      100, 50, 'accountBalanceList.headers.balance', {
        cellRenderer: 'xpGridNumberRenderer',
        cellStyle: function(params) {
            let outStyle = {textAlign: "right", color: 'var(--xpool-body-text-color)', fontWeight: 'var(--xpool-standard-font-weight)'};
            if ((params.data.balance < 0)) {
                outStyle = {...outStyle, color: '#CC0000', fontWeight: '400'};
            }
            return outStyle;
        }});
    xpColCreate(translate, subArray, hiddenState,'createdDateTime',  150, 50 , 'accountBalanceList.headers.createdDateTime' , {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState,'createdByUser',    100, 50 , 'accountBalanceList.headers.createdByUser'   , {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState,'createdComment',   200, 100, 'accountBalanceList.headers.createdComment');
    xpColCreate(translate, subArray, hiddenState,'disabledDateTime', 150, 50 , 'accountBalanceList.headers.disabledDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState,'disabledByUser',   100, 50 , 'accountBalanceList.headers.disabledByUser'  , {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState,'disabledComment',  200, 100, 'accountBalanceList.headers.disabledComment');

    return subArray;
}

export function disableBalance(account, balance, activeLanguage, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(pushDelayedModal(CONFIRMATION_WITH_COMMENT_DIALOG,
            {
                modalKey:    'registerBalanceForm.modalConfirmDeleteBalance',
                modalParams: {
                    balance: (account.currency.currency + " " + balance.balance),
                    date   : moment(balance.createdDateTime).locale(activeLanguage).format('ll')
                },
                commentIsRequired: true
            }))
            .then((result) => {
                if (result.status === MODAL_TYPE_CONFIRM) {
                    dispatch(disableLedgerAccountBalanceToApi(account.ledgerBalanceLaId, balance.id, result.comment));
                }
                resolve(result.status)
            });
    })
}
