import PropTypes from "prop-types";
import React, {useMemo, useState, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {isNumber} from "gui-common/functions/functions";
import {pushModalWindow} from "redux-promising-modals";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import moment from "moment";
import {INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {isValidTimeInput, reformatTimeString} from "gui-common/xpForm/formComponentsValidators";

function FileReaderButton (props) {

    const dispatch  = useDispatch();

    const [inProgress,   setInProgress]   = useState(false);

    const inputReference = useRef()

    useEffect(
        () => {},
        [],
    );

    const propsAccepted = useMemo(
        () => {
            if (
                (!props.fileSpec || (typeof props.fileSpec !== 'object') || !props.fileSpec.fileExtension || !props.fileSpec.separator)
                || (!props.labelKey && !props.label)
                || (!props.onSuccess        || (typeof props.onSuccess       !== 'function'))
                || (props.onFail            && (typeof props.onFail          !== 'function'))
                || (props.onStartBrowsing   && (typeof props.onStartBrowsing !== 'function'))
                || (props.onStartValidation && (typeof props.onStartBrowsing !== 'function'))
                || (props.fieldLookUps      && (typeof props.fieldLookUps    !== 'object'  ))
            ) {
                console.error("Invalid props in FileReaderButton: ", props);
                return false;
            }
            return true;
        },
        []
    );

    const expectedHeaders = useMemo(
        () => {
            let expectedHeaders = "";
            for (const level of props.fileSpec.levelDefinitions) {
                // eslint-disable-next-line
                level.columnDefinitions.forEach(item => expectedHeaders += (props.fileSpec.separator + item.heading));
                expectedHeaders += "\n";
            }
            return expectedHeaders;
        },
        []
    );


    if (!propsAccepted) return null;

    function renderErrorMessage(returnParams) {
        return (
            <div>
                <XpTranslated trKey={'fileReaderFailConfirmationModal.messageHeader'}/>
                {'\n'}
                <XpTranslated trKey={'fileReaderFileErrors.' + returnParams.errorCode}/>
                {returnParams.errors.length ?
                <div>
                    {'\n'}
                    <XpTranslated trKey={'fileReaderFailConfirmationModal.lineErrorsHeader'}/>
                    {'\n'}
                    {returnParams.errors.map(error =>
                        <div>
                            <XpTranslated trKey={'fileReaderLineErrors.' + error.error} trParams={error.errorParams}/>
                            {'\n'}
                        </div>
                    )}
                </div> : null}
            </div>
        )
    }

    function callPropFunction(functionName, returnParams) {
        if (!props[functionName] && props.debugLog) {
            console.log("FileReaderButton: No callback defined:", functionName, returnParams);
            return;
        }
        if (props.debugLog) console.log("FileReaderButton used callback function " + functionName + " with params: ", returnParams);
        if ((functionName === 'onFail') && !props.suppressFailDialogue) {
            dispatch(pushModalWindow(INFORMATION_DIALOG,
                {
                    modalKey:  'fileReaderFailConfirmationModal',
                    messageText: renderErrorMessage(returnParams),
                    className: 'xpoolModalFileReaderErrorMessage'
                }))
                .then(() => {
                    props[functionName](returnParams);
                });
        }
        else {
            props[functionName](returnParams);
        }
    }


    function validateFileList (fileList, errorFunction, errorCode) {
        if (errorFunction(fileList)) {
            setInProgress(false);
            callPropFunction('onFail', {errorCode: errorCode});
            return false;
        }
        return true;
    }

/*
    function logText(str, field) {
        if (!str) return;
        for (let index = 0; index < str.length; ++index) {
            console.log(field + " index: " + index + ": " + str.charCodeAt(index));
        }
    }
*/

    function getHeaderDefinition(fieldArray, returnParams, lineNumber, line) {
        if (fieldArray[fieldArray.length - 1] === '') fieldArray = fieldArray.slice(0, fieldArray.length - 1);

        let headerDefinition;
        for (let levelDefinition of props.fileSpec.levelDefinitions) {
            if (!levelDefinition.columnDefinitions || !levelDefinition.columnDefinitions.length) {
                returnParams.errorCode = "invalidFileSpecification";
                return undefined;
            }
            if (levelDefinition.columnDefinitions.length !== fieldArray.length) continue; // columnDefinitions do not match file line. Check next.

            headerDefinition = {
                ...levelDefinition,
                columns: fieldArray.map(fileHeading => levelDefinition.columnDefinitions.find(columnDefinition => columnDefinition.heading === fileHeading))
            }

            if (headerDefinition.columns.some(item => item === undefined)) { // at least one of the fields in the file line does not match the column definition. Check next.
                headerDefinition = undefined; // reset variable for next loop
                continue;
            }
            break; // We have found a column definition.
        }
        if (!headerDefinition) {
            returnParams.errorCode = "headerDefinitionDoesNotMatch";
            returnParams.errors.push({error: 'headerDefinitionDoesNotMatch', errorParams: {lineNumber: lineNumber, expected: expectedHeaders, received: line}});
            return undefined;
        }
        return headerDefinition;
    }

    function checkAndAddRecordErrors(colDef, lineRecord, returnParams, lineNumber) {
        let isValid = true;
        for (let error in colDef.errorFunctions) {
            const errorParams = colDef.errorFunctions[error](lineRecord, props.validationParams);
            if (!errorParams) continue;
            isValid = false;
            returnParams.errors.push({error: error, errorParams: {lineNumber: lineNumber, field: colDef.heading, ...errorParams}});
        }
        return isValid;
    }
    function checkAndAddFieldErrors(colDef, value, returnParams, lineNumber) {
        let isValid = true;
        if (colDef.minLength && (typeof value === 'string')) {
            if (value?.length < colDef.minLength) {
                isValid = false;
                returnParams.errors.push({error: 'minLength', errorParams: {lineNumber: lineNumber, field: colDef.heading, minLength: colDef.minLength, actualLength: value.length, fieldValue: value}});
            }
        }
        if (colDef.maxLength && (typeof value === 'string')) {
            if (value?.length > colDef.maxLength) {
                isValid = false;
                returnParams.errors.push({error: 'maxLength', errorParams: {lineNumber: lineNumber, field: colDef.heading, maxLength: colDef.maxLength, actualLength: value.length, fieldValue: value}});
            }
        }
        if (colDef.exactLength && (typeof value === 'string')) {
            if (value?.length !== colDef.exactLength) {
                isValid = false;
                returnParams.errors.push({error: 'exactLength', errorParams: {lineNumber: lineNumber, field: colDef.heading, exactLength: colDef.exactLength, actualLength: value.length, fieldValue: value}});
            }
        }
        return isValid;
    }


    const booleans = {'yes': true, 'no': false};
    function validateTypeAndSetData(colDef, value, returnParams, lineNumber, dataRecord) {
        if (!colDef.field) return true;

        if (!value || value.trim() === '') {
            // If value is "", the type should not be checked. Mandatory check is handles elsewhere.
            return true;
        }
        let isValid = false;
        switch (colDef.type) {
            case 'boolean':
                isValid = booleans[value.toLowerCase()] !== undefined;
                if (isValid) dataRecord[colDef.field] = booleans[value.toLowerCase()];
                break;
            case 'number':
                isValid = isNumber(value.trim());
                if (isValid) dataRecord[colDef.field] = parseFloat(value.trim());
                break;
            case 'string':
                isValid = (typeof value.trim() === 'string');
                if (isValid) dataRecord[colDef.field] = value.trim();
                break;
            case 'date':
                isValid =
                    moment(value.trim(),       'YYYY-MM-DD', true).isValid() ||
                    moment(value.trim(),       'YYYYMMDD', true).isValid();
                if (isValid) dataRecord[colDef.field] = value.trim();
                break;
            case 'time':
                const timeString = reformatTimeString(value.trim());
                isValid = isValidTimeInput(timeString);
                if (isValid) dataRecord[colDef.field] = timeString;
                break;
            default: break;
        }
        if (!isValid) returnParams.errors.push({error: 'incorrectFieldType', errorParams: {lineNumber: lineNumber, expectedType: colDef.type, value: value, field: colDef.heading}})
        return isValid;
    }

    function validateLookUps(colDef, value, returnParams, lineNumber) {
        if (!colDef.lookUp) return true;

        if (!value || value.trim() === '') {
            // If value is "", the prop should not be mapped. Mandatory check is handles elsewhere.
            return true;
        }
        let error;
        if (!props.fieldLookUps || !props.fieldLookUps[colDef.lookUp]) {
            error = 'lookUpMissing';
        }
        if (!props.fieldLookUps[colDef.lookUp].find(item => item === value.trim())) {
            error = 'fieldsNotInLookUp';
        }
        if (error) {
            if (!colDef.ignoreLineOnLookUpFailed) returnParams.errors.push({error: error, errorParams: {lineNumber: lineNumber, lookUp: colDef.lookUp, value: value, field: colDef.heading}});
            return false;
        }
        return true;
    }
    function validateLookUpAndSetId(colDef, value, returnParams, lineNumber, dataRecord) {
        if (!colDef.lookUpToId) return true;
        if (!props.fieldLookUpToIds || !props.fieldLookUpToIds[colDef.lookUpToId]) {
            returnParams.errors.push({error: 'lookUpMissing', errorParams: {lineNumber: lineNumber, lookUp: colDef.lookUpToId, value: value, field: colDef.heading}});
            return false;
        }
        if (!value || value.trim() === '') {
            // If value is "", the prop should not be set. Mandatory check is handles elsewhere.
            dataRecord[colDef.field] = undefined;
            return true;
        }
        const lookUpRecord = props.fieldLookUpToIds[colDef.lookUpToId].find(item => item.name === value.trim());
        if (!lookUpRecord) {
            returnParams.errors.push({error: 'fieldsNotInLookUp', errorParams: {lineNumber: lineNumber, lookUp: colDef.lookUpToId, value: value, field: colDef.heading}});
            return false;
        }
        dataRecord[colDef.field] = lookUpRecord.id;
        dataRecord[colDef.field + '_source'] = value.trim();
        return true;
    }


    function parseAndValidateLine(fieldArray, headerDefinition, returnParams, lineNumber) {
        if (!fieldArray.length || !headerDefinition.columns.length) {
            returnParams.errors.push({error: 'incorrectNumberOfFields', errorParams: {lineNumber: lineNumber, expected: headerDefinition.columns.length, actual: fieldArray.length}});
            return;
        }
        let lineFieldsValid   = true;
        let ignoreInvalidLine = false;
        let dataRecord = {};
        for (const [index, colDef] of headerDefinition.columns.entries()) {
            if (colDef.mandatory && !fieldArray[index]) {
                returnParams.errors.push({error: 'missingMandatoryField', errorParams: {lineNumber: lineNumber, field: colDef.heading}});
                lineFieldsValid = false;
                continue; // no need to check errors and types if mandatory field is missing
            }
            if (!validateTypeAndSetData(colDef, fieldArray[index], returnParams, lineNumber, dataRecord)) lineFieldsValid = false;
            if (!validateLookUps(       colDef, fieldArray[index], returnParams, lineNumber)) {
                lineFieldsValid = false;
                if (colDef.ignoreLineOnLookUpFailed) ignoreInvalidLine = true;
            }
            if (!validateLookUpAndSetId(colDef, fieldArray[index], returnParams, lineNumber, dataRecord)) lineFieldsValid = false;
            if (!checkAndAddFieldErrors(colDef, fieldArray[index], returnParams, lineNumber))             lineFieldsValid = false;
        }
        // Time do check custom field errors with validation cross fields in column.
        if (lineFieldsValid) {
            for (const colDef of headerDefinition.columns) {
                if (!checkAndAddRecordErrors(colDef, dataRecord, returnParams, lineNumber)) lineFieldsValid = false;
            }
        }
        if (lineFieldsValid) {
            if (!returnParams.data[headerDefinition.dataProp]) returnParams.data[headerDefinition.dataProp] = [];
            if (headerDefinition.enrichItemFn) headerDefinition.enrichItemFn(dataRecord)
            returnParams.data[headerDefinition.dataProp].push(dataRecord)
        }
        else {
            if (!ignoreInvalidLine) returnParams.errorCode = "invalidLine";
        }
    }

    function validHeader(fields, returnParams, lineNumber, line) {
        if ((fields.length < 2) || (fields[1] === '')) {
            returnParams.errorCode = "invalidHeaderDefinition";
            returnParams.errors.push({error: 'invalidHeaderDefinition', errorParams: {lineNumber: lineNumber, received: line}})
            return false;
        }
        return true;
    }

    function parseAndPopulateDataTree(thisNode, childConfigArray, thisNodeIdProp, returnParams) {
        if (!childConfigArray) return;
        for (const childConfig of childConfigArray) {
            const sourceData = returnParams.data[childConfig.dataProp];
            if (!sourceData) {
                returnParams.errors.push({error: 'noSourceDataFound', errorParams: {sourceDataProp: childConfig.dataProp}})
                returnParams.errorCode = 'noSourceDataFound';
                continue;
            }
            thisNode[childConfig.property] = [];
            const useParentChildIdentification = (thisNodeIdProp && childConfig.parentIdProp)
            for (const child of sourceData) {
                if (useParentChildIdentification && thisNode && thisNode[thisNodeIdProp] && (child[childConfig.parentIdProp] !== thisNode[thisNodeIdProp])) continue;
                const structuredChild = {...child};
                if (childConfig.parentIdProp) delete structuredChild[childConfig.parentIdProp];
                parseAndPopulateDataTree(structuredChild, childConfig.children, childConfig.idProp, returnParams);
                thisNode[childConfig.property].push(structuredChild)
            }
        }
    }

    function parseEnrichAndValidateDataTree(thisNode, childConfigArray, returnParams) {
        if (!childConfigArray) return;
        for (const childConfig of childConfigArray) {
            if (!thisNode[childConfig.property]) continue;
            for (const child of thisNode[childConfig.property]) {
                if (!checkAndAddRecordErrors(childConfig, child, returnParams, undefined)) {
                    returnParams.errorCode = "inconsistentData";
                }
                if (childConfig.enrichItemFn) childConfig.enrichItemFn(child);
                parseEnrichAndValidateDataTree(child, childConfig.children, returnParams);
            }
        }
    }

    function parseAndValidateFile(content, fileSpec) {
        let returnParams = {errorCode: "success", errors: [], data: {}}
        let headerDefinition;

        const lines = content.split('\n');
        let firstLineParsed = false;
        for (const [index, line] of lines.entries()) {
            if (line.trim() === '') continue; // blank line, skip
            if (fileSpec.commentLinePrefix && line[0] === fileSpec.commentLinePrefix) continue; // comment line, skip

            let fields = line.trim().split(fileSpec.separator);
            //Todo: Bygg om sa att vi kollar header pa varje rad. Skippa alltsa header prefix
            if ((fields[0] === '') || !firstLineParsed) {   // Header definition candidate
                firstLineParsed = true;
                if (!validHeader(fields, returnParams, index + 1)) continue;

                headerDefinition = getHeaderDefinition((fields[0] === '') ? fields.slice(1) : fields, returnParams, index + 1, line);
                if (!headerDefinition) return returnParams;
                continue; // Read next line
            }
            if (!headerDefinition) continue; // Skip everything before we have a confirmed header definition

            // We now have a valid line that is not a header.
            parseAndValidateLine(fields, headerDefinition, returnParams, index + 1);
        }
        if (!headerDefinition) { // No matching header definition found in the file.
            returnParams.errorCode = "noValidHeader";
            return returnParams;
        }
        if (fileSpec.dataTree) {
            returnParams.dataTree = {};
            parseAndPopulateDataTree(returnParams.dataTree, fileSpec.dataTree, null, returnParams)
            parseEnrichAndValidateDataTree(returnParams.dataTree, fileSpec.dataTree, returnParams)
        }
        return returnParams;
    }

    function handleFile(event) {
        setInProgress(true);
        callPropFunction('onStartValidation');
        const fileList = event.target.files;

        if (!validateFileList(fileList, (e) => (!e || !e.length     ), "noFileSelected"       )) return false;
        if (!validateFileList(fileList, (e) => ( e && (e.length > 1)), "multipleFilesSelected")) return false;

        let   returnParams = {errorCode: "success"}
        const file = fileList[0];
        returnParams.fileName = file.name;
        returnParams.file     = file;

        let reader = new FileReader();
        reader.onload = function (e) {
            let content = e.target.result;
            if (!content) { // No content in the file.
                setInProgress(false);
                returnParams.errorCode = "noFileContent";
                callPropFunction('onFail', returnParams);
                return;
            }

            returnParams.fileContent = content;

            returnParams = {...returnParams, ...(props.customParser ? props.customParser : parseAndValidateFile)(content, props.fileSpec)};

            setInProgress(false);
            if (returnParams.errorCode !== 'success') {
                callPropFunction('onFail', returnParams);
                return;
            }
            callPropFunction('onSuccess', returnParams);
        };
        reader.onerror = function(event) {
            console.log("File reader fail", event);
            callPropFunction('onFail', {...returnParams, errorCode: "fileReadFailed"});
        };
        reader.readAsText(file, props.fileSpec.encoding ? props.fileSpec.encoding : 'UTF-8');
        inputReference.current.value = null;
    }

    return (
        <div style={{display: props.inLineLayout ? 'inline-block' : 'block'}}>
            <input
                style={{display: 'none'}}
                id={props.instanceId}
                type="file"
                accept={props.fileSpec.fileExtension}
                hidden
                ref={inputReference}
                onChange={handleFile}
            />
            <WaitingXpoolButton
                labelKey    = {props.labelKey}
                labelParams = {props.labelParams}
                label       = {props.label}
                onClickCallback={() => {
                    inputReference.current.click()
                    callPropFunction('onStartBrowsing');
                }}
                waiting={inProgress || props.waiting}
            />
        </div>
    )
}
FileReaderButton.propTypes = {
    instanceId          : PropTypes.string.isRequired,
    fileSpec            : PropTypes.object.isRequired,
    fieldLookUps        : PropTypes.object,
    onSuccess           : PropTypes.func.isRequired,
    onFail              : PropTypes.func,
    onStartBrowsing     : PropTypes.func,
    onStartValidation   : PropTypes.func,
    customParser        : PropTypes.func,
    suppressFailDialogue: PropTypes.bool,
    inLineLayout        : PropTypes.bool,
    labelKey            : PropTypes.string,
    labelParams         : PropTypes.object,
    label               : PropTypes.string,
    className           : PropTypes.string,
    debugLog            : PropTypes.bool,
};
export default FileReaderButton



