import {genericFetch} from "gui-common/api/apiCommon";
import {warnAndResolve} from "gui-common/functions/functions";
import {ormApiBaseUrlMap} from "appConfig/api/apiConfig";
import {getUserPreferencesPropertySelector} from "gui-common/userSettings/userSettingsSelectors";
import {getOrmItemsFromApi} from "gui-common/orm/ormApi";

export function requestOrderActionOnApi(orderId, action) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!orderId) {
                warnAndResolve("Request order action called with no order ID", resolve);
                return;
            }
            if (!action) {
                warnAndResolve("Request order action called with no action", resolve);
                return;
            }
            let customText = "Request order action " + action + " for order id " + orderId + " ";
            let requestUrl = ormApiBaseUrlMap.Order + "/" + orderId + "/" + action + "?";

            dispatch(genericFetch(
                requestUrl,
                customText,
                requestOrderActionResponseHandler,
                requestOrderActionFailHandler,
                requestOrderActionFailHandler,
                'PUT'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function requestOrderActionResponseHandler(resp) {
    return (dispatch, getState) => {
    }
}
function requestOrderActionFailHandler(resp) {
    return (dispatch, getState) => {
        // TBD to handle failure to execute orders...
    }
}

export function getInBalanceOrdersFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let inBalanceOrdersDays = getUserPreferencesPropertySelector()(getState(), 'showInBalanceOrdersDays');
            if ((inBalanceOrdersDays === undefined) || Number(inBalanceOrdersDays) === 0) {
                resolve();
                return;
            }
            dispatch(getOrmItemsFromApi('Order', undefined,
                {
                    state: 'IN_BALANCE',
                    nbrOfDays: inBalanceOrdersDays,
                }
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
