import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import "gui-common/simulators/simulator.css";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import {dashboardSetComponentsState} from "gui-common/dashboard/dashboardReducer";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormRemoveForm} from "gui-common/xpForm/core/xpFormReducer";
import {
    balanceAPiSimulatorActiveConfigurationsSelector
} from "xpool-gui-common/features/balanceApiSimulator/balanceApiSimulatorSelectors";
import {bankAccountsSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import BalanceApiSimulatorConfigItem from "xpool-gui-common/features/balanceApiSimulator/BalanceApiSimulatorConfigItem";

function BalanceApiSimulator (props) {

    const balanceApiSimulatorForm = 'balanceApiSimulatorForm-' + props.instanceId;
    const activeAccountConfigurations = useSelector(balanceAPiSimulatorActiveConfigurationsSelector);
    const allAccounts            = useSelector(bankAccountsSelector);

    const accountIdToAdd   = useXpFormField(balanceApiSimulatorForm + '.accountIdToAdd')

    const dispatch  = useDispatch();

    useEffect(
        () => {
            if (props.componentData.state?.accountItems) {
                return
            }
            dispatch(dashboardSetComponentsState(
                props.componentData.key,
                {accountItems: activeAccountConfigurations}
            ));
            return () => {
                dispatch(xpFormRemoveForm(balanceApiSimulatorForm));
            }
        },
        [],
    );


    const accountsAvailableToAdd = useMemo(
        () => {
            return allAccounts?.length ? allAccounts.map(item => ({
                id:item.id ,
                name: item.client.name + ': ' + item.name + '(' + item.currency.currency + '-' + item.id + ')',
            })) : [];
        },
        [allAccounts]
    );

    function configIsSame(item, config) {
        for (let key in item) {
            if (item[key] !== config[key]) {
                return false;
            }
        }
        for (let key in config) {
            if (item[key] !== config[key]) {
                return false;
            }
        }
        return true;
    }
    function getConfigKey(item) {
        let returnKey = ""
        for (let key in item) {
            returnKey += item[key];
        }
        return returnKey;
    }

    if (!props.componentData?.state?.accountItems) {
        return null;
    }

    return (
        <div>
            <XpForm
                formModel={balanceApiSimulatorForm}
                initialUseState={XP_FORM_EDIT}
            >
                <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                    <div style={{width: '500px', marginRight: '5px'}}>
                        <XpFormSelectorInput
                            template={'Select bank account to add'}
                            label={'Add bank account'}
                            field="accountIdToAdd"
                            selectList={accountsAvailableToAdd}
                        />
                    </div>
                    <div style={{marginTop: '17px'}}>
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            onClickCallback = {() => {
                                dispatch(dashboardSetComponentsState(
                                    props.componentData.key,
                                    {accountItems: [...props.componentData.state.accountItems, {bankAccountId: accountIdToAdd}]}
                                ));
                            }}
                            label    = 'Add account'
                            disabled = {undefined !== props.componentData.state.accountItems.find(item => configIsSame(item, {bankAccountId: accountIdToAdd}))}
                        />
                    </div>
                </div>
            </XpForm>
            <hr/>
            {props.componentData?.state?.accountItems.map(config => { return (
                <BalanceApiSimulatorConfigItem
                    key            = {getConfigKey(config)}
                    bankAccountId  = {config.bankAccountId}
                    removeMe       = {() => {
                        dispatch(dashboardSetComponentsState(
                            props.componentData.key,
                            {accountItems: props.componentData.state.accountItems.filter(item => !configIsSame(item, config))}
                        ));
                    }}
                />)})
            }
        </div>
    )
}
BalanceApiSimulator.propTypes = {
};
export default BalanceApiSimulator
