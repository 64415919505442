import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {createTranslatedFieldData} from "gui-common/xpGrid/xpGridFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

export const getBalanceMovementsSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props?.bankAccount,
    state => selectTranslateFunction(state),
    (session, bankAccount, translate) => {
        if (!bankAccount?.id) return undefined;

        const bankAccountModel = getOrmModelObjectById(session, 'BankAccount', bankAccount.id);
        if (!bankAccountModel) return undefined;

        const balanceMovements = bankAccountModel.balanceMovements.orderBy('id', 'desc').toModelArray();

        return balanceMovements.map(item => {
            const newItem = {...item.ref}
            createTranslatedFieldData(newItem, 'type'  , translate, ("balanceMovementsList.balanceMovementTypes."   + item.type));
            createTranslatedFieldData(newItem, 'state' , translate, ("balanceMovementsList.balanceMovementStates."  + item.state));
            createTranslatedFieldData(newItem, 'source', translate, ("balanceMovementsList.balanceMovementSources." + item.source));
            return {
                ...newItem,
            }
        });
    }
);
