import {Nav, NavDropdown} from "react-bootstrap";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {userCan} from "gui-common/functions/userRights";
import React from "react";
import {xpMainMenuItem, xpMainMenuLinkContainer} from "gui-common/menuComponents/menuFunctions";
import XpMenuTitle from "gui-common/menuComponents/XpMenuTitle";
import {getAppLogo, getAppName} from "appConfig/appConfig";

export const useAppMenuClientSelector = true;

export function getMenuTitle() {
    return <XpMenuTitle logo={getAppLogo()} appName={getAppName()}/>
}

export function getMainMenuLeftItems(activeClientId, appEnvState) {
    return (
        <Nav className="mr-auto">
            {xpMainMenuItem('/dashboard'        , 'topMenu.dashBoard'        , true, )}
            {xpMainMenuItem('/cashPool'         , 'topMenu.cashPool'         , activeClientId !== undefined)}
            {xpMainMenuItem('/currencyDashboard', 'topMenu.currencyDashboard', appEnvState?.clientGuiConfig?.showMenu)}
        </Nav>
    )
}

export function getMainMenuSettingsItems(props) {
    const userCanAdminUsersConst      = (props.users && (props.users.length > 1));  // User endpoint will only return users that the user has rights to admin (and the user itself)
    const userCanReadNoRunDates       = userCan('Read'  , 'NoRunDate'            , props.user);
    const userCanManageNoRunDates     = userCan('Delete', 'NoRunDate'            , props.user) || userCan('Create', 'NoRunDate', props.user);
    const userCanReadFxRejectConfig   = userCan('Read'  , 'FxRejectConfiguration', props.user);
    const userCanManageFxRejectConfig = userCan('Update', 'FxRejectConfiguration', props.user);
    const userCanUpdateSystemTexts    = userCan('Create', 'SystemText'           , props.user);
    const userCanReadBankHolidays     = userCan('Read'  , 'BankHolidays'          , props.user);
    return (
        <NavDropdown title={<XpTranslated trKey={'topMenu.configDropdown'}/>} id="config-nav-dropdown" alignRight>

            {/*No authorization check on this menu. Only available clients will be visible.*/}
            {xpMainMenuLinkContainer('/clientSettings'  , 'topMenu.clients'             , true)}
            {xpMainMenuLinkContainer('/onboardingWizard', 'topMenu.clientCreateWizard'  , props.showClientCreateWizard, 'onboardingWizardMenuPoint')}
            {xpMainMenuLinkContainer('/auditView'       , 'topMenu.auditView'           , props.showAuditMenu)}
            {xpMainMenuLinkContainer('/report'          , 'topMenu.report'              , true)}

            {(userCanAdminUsersConst || userCanReadNoRunDates) && <NavDropdown.Divider/>}

            {xpMainMenuLinkContainer('/adminUserRights' , 'topMenu.adminUserRights' , userCanAdminUsersConst)}
            {xpMainMenuLinkContainer('/adminNoRunDates' , userCanManageNoRunDates ? 'topMenu.adminNoRunDates' : 'topMenu.viewNoRunDates' , userCanReadNoRunDates || userCanManageNoRunDates, )}
            {xpMainMenuLinkContainer('/fxRejectConfig'  , userCanManageFxRejectConfig ? 'topMenu.manageFxRejectConfig' : 'topMenu.viewFxRejectConfig' , userCanReadFxRejectConfig || userCanManageFxRejectConfig, )}
            {xpMainMenuLinkContainer('/bankHolidays'    , 'topMenu.bankHolidays'       , userCanReadBankHolidays)}
            {xpMainMenuLinkContainer('/editTranslations', 'topMenu.manageTranslations' , userCanUpdateSystemTexts)}

            {(props.ormActiveClientId) && <NavDropdown.Divider/>}
            {xpMainMenuLinkContainer('/accounts' , 'topMenu.accounts' , props.ormActiveClientId)}

        </NavDropdown>
    )
}


export function getAppDevMenuItems(dispatch) {
    return [
        xpMainMenuLinkContainer('/calculator'  , 'topMenu.calculator'             , true),
        xpMainMenuLinkContainer('/erpPoc'  , 'topMenu.erpPoc'             , true),
    ];
}
