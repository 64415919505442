import React, {useMemo} from "react";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {getCashPoolAccountParametersConfig} from "appConfig/xpAdminFormConfig";

export default function CashPoolAccountForm(props) {
    const xpAdminFormConfig  = useMemo(
        () => {
            return getCashPoolAccountParametersConfig();
        },
        []
    );

    if (!props.account) return null;
    return (
        <XpAdminForm
            entityId              = {props.account.id}
            xpAdminFormInstanceId = 'cashPoolAccountForm'
            xpAdminFormConfig     = {xpAdminFormConfig}
            ormModel              = 'BankAccount'
            suppressAccordion     = {true}
            hideDeleteButton      = {true}
        />
    )
}
