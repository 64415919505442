import React, {useMemo} from 'react'
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import 'react-datepicker/dist/react-datepicker.css';
import {
    usersSelector
} from "gui-common/orm/ormSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {requestAuditEntriesFromApi} from "gui-common/audit/auditApi";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {ormModelLoading} from "gui-common/orm/ormLoadingSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getDefaultObject} from "gui-common/functions/functions";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {ormEntitiesClearModel} from "gui-common/orm/ormReducer";
import {auditConfig} from "gui-common/audit/auditConstants";
import {CONFIRMATION_DIALOG, INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {
    activeAuditRootModelsSelector,
    auditEntriesSelector,
    auditRootObjectsSelector
} from "gui-common/audit/auditSelectors";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormDateInput from "gui-common/xpForm/core/XpFormDateInput";
import {xpFormChangeField, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function onFilterChange(fieldModel, newValue, currentValue, setValue) {
    return (dispatch, getState) => {

        const auditEntries = auditEntriesSelector(getState());
        if (!auditEntries || !auditEntries.length) { // No need to confirm with user if there are no audit entries loaded.
            if (fieldModel === 'auditViewFilterForm.rootModel') dispatch(xpFormChangeField('auditViewFilterForm.rootObjectId', ''));
            setValue(newValue);
            return;
        }

        // Set value so that the change is visible when the user gets the confirmation dialogue.
        setValue(newValue); // Set value so that the change is visible.
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey : 'auditViewFilterForm.changeFilterModal'}))
            .then((result) => {
                if (result.status === MODAL_TYPE_CONFIRM) {
                    if (fieldModel === 'auditViewFilterForm.rootModel') dispatch(xpFormChangeField('auditViewFilterForm.rootObjectId', ''));
                    dispatch(ormEntitiesClearModel('AuditEntry'));
                }
                if (result.status === MODAL_TYPE_CANCEL) {
                    setValue(currentValue); // Change back to previous value.
                }
            });
    }
}

function adItemsToLists(objectsToAdd, addedObjectIds, activeList, deletedList, translate, modelConfig) {
    if (!objectsToAdd?.length) return;
    for (let item of objectsToAdd) {
        if (addedObjectIds[item.id]) continue;

        const isDeletedDateTime = item.auditEntries?.length ?
            (item.auditEntries[0].operationType === 'Delete') ? item.auditEntries[0].auditDateTime : null
            :
            item.disabledByUser ? item.disabledDateTime : null;

        let calcName = item.name ? item.name : modelConfig.getNameFn ? modelConfig.getNameFn(item) : 'NoName';
        if (isDeletedDateTime) {
            deletedList.push({
                ...item,
                name: calcName,
                displayName: translate('auditViewFilterForm.disabledPrefix', {date: moment(isDeletedDateTime).format('ll')}) + calcName,
                isDeletedDateTime: isDeletedDateTime,
            })
        }
        else {
            activeList.push({
                ...item,
                name: calcName,
                displayName: calcName
            })
        }

        addedObjectIds[item.id] = true;
    }
}

function AuditViewFilterForm (props) {
    const dispatch          = useDispatch();
    const translate         = useXpTranslateFunction();
    const users             = useSelector(usersSelector);
    const auditItemsLoading = useSelector(state => ormModelLoading(state, {ormModel: 'AuditEntry'}));
    const auditRootModels   = useSelector(activeAuditRootModelsSelector);
    const rootObjectsFromOrm= useSelector(auditRootObjectsSelector);
    const auditEnvConfig    = useAppEnvProperty( 'auditConfig');
    const formData          = useXpFormFields({dependentFieldModels: {
            rootObjectId : 'auditViewFilterForm.rootObjectId',
            userId       : 'auditViewFilterForm.userId',
            fromDate     : 'auditViewFilterForm.fromDate',
            toDate       : 'auditViewFilterForm.toDate',
            rootModel    : 'auditViewFilterForm.rootModel',
        }})

    const selectedRootModel = useMemo(
        () => {
            if (!formData?.rootModel) return undefined;
            return auditRootModels.find(item => item.model === formData.rootModel);
        },
        [formData?.rootModel, auditRootModels]
    );

    const rootObjectsToDisplay = useMemo(
        () => {
            if (!selectedRootModel) return [];
            if (!rootObjectsFromOrm?.length && !selectedRootModel?.rootObjects?.length) return [];

            const addedObjectIds = {};
            let activeList  = [];
            let deletedList = [];
            adItemsToLists(rootObjectsFromOrm           , addedObjectIds, activeList, deletedList, translate, auditConfig[formData.rootModel]);
            adItemsToLists(selectedRootModel.rootObjects, addedObjectIds, activeList, deletedList, translate, auditConfig[formData.rootModel]);

            return [
                ...activeList.sort( (firstEl,secondEl) => firstEl.name.toUpperCase() - secondEl.name.toUpperCase()),
                ...deletedList.sort((firstEl,secondEl) => moment(secondEl.isDeletedDateTime).unix() - moment(firstEl.isDeletedDateTime).unix())
            ]
        },
        [rootObjectsFromOrm, selectedRootModel?.rootObjects, translate]
    );


    const auditRootModelItems = useMemo(
        () => {
            return auditRootModels.map(item => { return {
                id          : item.model,
                trKey       : 'general.modelNamesLargePlural.' + item.model,
            }});
        },
        [auditRootModels, translate]
    );
    const defaultRootModel = useMemo(
        () => {
            const defaultObject = getDefaultObject(auditRootModels);
            return defaultObject?.model;
        },
        [auditRootModels]
    );

    const showRootObjectSelector = useMemo(
        () => {
            if (!formData.rootModel) return false;
            return !auditConfig[formData.rootModel].rootProps.hideRootObjectSelector;
        },
        [formData.rootModel]
    );



    function handleSubmit(auditFilter) {
        if (auditEnvConfig.restrictFullAuditSearch && !auditFilter.rootObjectId && !auditFilter.userId) {
            dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'auditViewFilterForm.modalConfirmSelectionRequired'}));
            return;
        }
        const selectedRootModel = auditRootModels.find(item => item.model === auditFilter.rootModel);
        if (!selectedRootModel) return;

        dispatch(requestAuditEntriesFromApi(selectedRootModel.id, auditFilter));
    }

    const disableGetEntriesButton = !formData.fromDate || !formData.toDate;

    return (
        <div xp-test-id="auditViewFilterForm">

            <XpForm
                className="filterFormContainer"
                formModel="auditViewFilterForm"
                onSubmit={handleSubmit}
                initialUseState={XP_FORM_EDIT}
                initialFormData={{
                    rootModel   : defaultRootModel,
                    rootObjectId: "",
                    userId      : "",
                    fromDate    : moment().format('YYYY-MM-DD'),
                    toDate      : moment().format('YYYY-MM-DD'),
                }}
            >
                {(auditRootModelItems && (auditRootModelItems.length > 1)) &&
                <div className="filterFormField auditFilterSelect">
                    <XpFormSelectorInput
                        field         = "rootModel"
                        selectList    = {auditRootModelItems}
                        onChangeThunk = {onFilterChange}
                        noTemplate noMargin
                    />
                </div>}

                <div className="filterFormField auditFilterSelect" style={{visibility: (showRootObjectSelector && rootObjectsToDisplay?.length) ? 'visible' : 'hidden', maxWidth: '400px'}}>
                    <XpFormSelectorInput
                        noMargin
                        field         = "rootObjectId"
                        selectList    = {rootObjectsToDisplay}
                        itemTextField = "displayName"
                        label         = {<XpTranslated trKey={'general.modelNamesLarge.' + formData.rootModel}/>}
                        template      = {translate('auditViewFilterForm.rootObjectSelectorTemplate', {rootModel: translate('general.modelNamesSmallPlural.' + formData.rootModel)})}
                        onChangeThunk = {onFilterChange}
                    />
                </div>

                <div className="filterFormField auditFilterSelect">
                    <XpFormSelectorInput
                        noMargin
                        field         = "userId"
                        selectList    = {users}
                        itemTextField = "fullName"
                        onChangeThunk = {onFilterChange}
                    />
                </div>

                <div className="filterFormField auditFilterDate">
                    <XpFormDateInput
                        noTemplate alignRight noMargin showMonthYearDropdown
                        field         = "fromDate"
                        dependentFields={{toDate: 'toDate'}}
                        dependentPropsFn={fields => ({maxDate: fields.toDate})}
                        minDate       = '2019-05-01'
                        onChangeThunk = {onFilterChange}
                    />
                </div>

                <div className="filterFormField auditFilterDate">
                    <XpFormDateInput
                        noTemplate alignRight noMargin showMonthYearDropdown
                        field         = "toDate"
                        dependentFields={{fromDate: 'fromDate'}}
                        dependentPropsFn={fields => ({minDate: fields.fromDate})}
                        maxDate       = {moment().format('YYYY-MM-DD')}
                        onChangeThunk = {onFilterChange}
                    />
                </div>

                <div className="filterFormButton">
                    <WaitingXpoolButton
                        labelKey        = 'auditViewFilterForm.submitButton'
                        onClickCallback = {() => {dispatch(xpFormSubmitForm('auditViewFilterForm'))}}
                        waiting         = {auditItemsLoading}
                        disabled        = {disableGetEntriesButton}
                        toolTipKey      = {disableGetEntriesButton ? 'auditViewFilterForm.submitButtonDisabledTooltip' : 'auditViewFilterForm.submitButtonEnabledTooltip'}
                        floatRight
                    />
                </div>

            </XpForm>

        </div>
    )
}
export default AuditViewFilterForm;
