import {renderAppFromIndexJs} from "gui-common/app/appStartup.js"
import {LicenseManager} from "ag-grid-enterprise";

// LicenseManager.setLicenseKey("Nordic_Financial_Components_AB_xpool_2Devs5_June_2019__MTU1OTY4OTIwMDAwMA==99f5cedcc0ee7d6bd6cd7e1183eee43e");
LicenseManager.setLicenseKey("CompanyName=Nordic Financial Components AB,LicensedApplication=xpool,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-008654,ExpiryDate=24_June_2021_[v2]_MTYyNDQ4OTIwMDAwMA==bceccdb96675b256baa876377a3c30dc");
renderAppFromIndexJs();

/*
const loadJS = function(url, implementationCode, location){
    //url is URL of external file, implementationCode is the code
    //to be called from the file, location is the location to
    //insert the <script> element

    const scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = (params) => {
        implementationCode();
    }
    // scriptTag.onreadystatechange = implementationCode;
    scriptTag.onerror = (params) => {
        console.log("Error loading file", url);
    }

    location.appendChild(scriptTag);
};
loadJS('client-config.js', renderAppFromIndexJs, document.body);
*/
