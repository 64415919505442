import {createStandardAuditFields2, xpColCreate, xpColHeadCreate} from "gui-common/xpGrid/xpGridFunctions";

export function canLegalEntityBeDisabled(entity, fromClient) { // TODO: Use ormObject selectors to get deeper nesting.

    let returnObject = {
        canBeDisabled: true,
        modalKey: 'legalEntityForm.modalConfirmDisableEntity',
        modalParams: {name: entity.name}
    };

    const legalEntityUnits = entity.legalEntityUnits;
    if (entity.legalEntityUnits === undefined) {
        returnObject.modalKey = 'legalEntityForm.modalEntityUnitsError';
        returnObject.canBeDisabled = false;
        return returnObject;
    }

    const checkAccountLevel = (entity.useLegalEntityUnits === false) && (entity.legalEntityUnits.length === 1);
    if (checkAccountLevel) {
        const bankAccounts = entity.legalEntityUnits[0].bankAccounts;
        if (bankAccounts && (bankAccounts.length > 0)) {
            let childrenNames = "";
            for (let account of bankAccounts) {
                childrenNames = childrenNames + "- " + account.name + "\n";
            }
            returnObject.canBeDisabled = false;
            returnObject.modalKey      = fromClient ? 'clientForm.modalClientHasAccounts' : 'legalEntityForm.modalEntityHasAccounts';
            returnObject.modalParams   = {name: entity.name, children: childrenNames};
            return returnObject;
        }
    } else if (legalEntityUnits.length > 0) {
        let childrenNames = "";
        for (let legalEntityUnit of legalEntityUnits) {
            childrenNames = childrenNames + "- " + legalEntityUnit.name + "\n";
        }
        returnObject.canBeDisabled = false;
        returnObject.modalKey      = fromClient ? 'clientForm.modalClientHasBusinessUnits' : 'legalEntityForm.modalEntityHasUnits';
        returnObject.modalParams   = {name: entity.name, children: childrenNames};
        return returnObject;
    }

/* // Skipped parent child feature for LE in oxyGen v2.
    if (entity.childEntities && entity.childEntities.length) {
        let childrenNames = "";
        for (let key in entity.childEntities) {
            childrenNames = childrenNames + "- " + entity.childEntities[key].name + "\n";
        }
        returnObject.canBeDisabled = false;
        returnObject.modalKey      = 'legalEntityForm.modalEntityIsParent';
        returnObject.modalParams   = {name: entity.name, children: childrenNames};
        return returnObject;
    }
*/
    // Entity can be disabled!
    return returnObject;
}

export function prepareLegalEntityGridColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'name', 250, 50, 'legalEntityForm.name.label');
    xpColCreate(translate, subArray, hiddenState, 'description', 250, 50, 'legalEntityForm.description.label');
    xpColCreate(translate, subArray, hiddenState, 'id', 80, 30, 'legalEntityForm.id');
    xpColCreate(translate, subArray, hiddenState, 'countryName', 100, 50, 'legalEntityForm.countryId.label');
    xpColCreate(translate, subArray, hiddenState, 'lei', 150, 50, 'legalEntityForm.lei.label');
    xpColHeadCreate(translate, outArray, subArray, 'entitiesList.entityInformation');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];
    return outArray;
}
