import React from 'react';
import {withRouter} from "react-router-dom";
import ClientsList from "features/client/ClientsList"
import OrdersList from "xpool-gui-common/features/order/OrdersList";
import {allClientsOrdersHiddenState} from "xpool-gui-common/features/order/AllClientsOrdersList";


const hiddenState =  {
    name:               false,
    id:                 true,
    // userLoggedIn:       false,
    // clientStatus:       false,
    description:        false,
    accountManager:     true,
    clientRefId:        true,
    operatingUnitT:     true,
    timeZone:           true,
    activeOrderCount:   false,

    cashPoolsRunStateT:  false,
    erpStatus:           false,
    runPoolProcessNextRunTime: false,
//    runPoolProcessErrorStateT: false,
    getBalancesTimeT:    true,
    executeTimeT:        false,
    stopTryingTimeT:     true,
    createdDateTimeT:    true,
    createdByUser:       true,
    changedDateTimeT:    true,
    changedByUser:       true,
};



function ClientsStatusList (props) {
    let orderListHiddenState = {...allClientsOrdersHiddenState}
    delete orderListHiddenState.clientName;
    delete orderListHiddenState.clientId;
    return (
        <ClientsList
            hiddenState={hiddenState}
            instanceId={props.instanceId}
            fillAvailableSpace={true}
            disableXpAdminFormFunctions={true}
            disableLoadClientOnSelect={true}
            gridOptions={{
                masterDetail            : true,
                xpDetailRendererProps   : {
                    availableComponents: [
                        {
                            detailType: 'orders',
                            componentToRender: OrdersList,
                            propsToComponent: {
                                hiddenState: orderListHiddenState,
                                rowDataFilterFunction: (item, parentItemData) => {
                                    // Ugly but efficient. Filer is applied on client in selector, therefore no need to iterate over orders for non selected client.
                                    return (item.id === parentItemData.id);
                                },
                            },
                        },
                    ],
                    parentInstanceId: props.instanceId,
                },
            }}
        />
    );
}

export default withRouter(ClientsStatusList);
