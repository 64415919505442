import React, {useCallback, useState} from "react";
import {useResizeObserver} from "gui-common/functions/hooks";
import {animated, useSpring} from "@react-spring/web";
import PropTypes from "prop-types";
import XpPieChart from "gui-common/components/XpPieChart";

export function XpContainerTooltip(props) {
    const [tooltipContainerState, setTooltipContainerState] = useState();

    const observerCallback = useCallback(box => {
        if (tooltipContainerState && (tooltipContainerState.height === box.height && tooltipContainerState.width === box.width)) {
            // No need to update!
            return;
        }
        setTooltipContainerState({height: box.height, width: box.width})
        // console.log("Box", box);
    }, []);
    const tooltipContainerRef = useResizeObserver(observerCallback)

    function getTop(y) {
        if (!tooltipContainerState?.height) {
            return undefined;
        }
        if ((y + props.toolTipMargin + tooltipContainerState?.height) <= props.containerHeight) {
            return y + props.toolTipMargin;
        }
        return Math.max(y - tooltipContainerState?.height - props.toolTipMargin, 0);
    }

    function getLeft(x) {
        if (!tooltipContainerState?.width) {
            return undefined;
        }
        if ((x + props.toolTipMargin + tooltipContainerState?.width) <= props.containerWidth) {
            return x + props.toolTipMargin;
        }
        return Math.max(x - tooltipContainerState?.width - props.toolTipMargin, 0);
    }

    const {opacity, x, y} = useSpring({
        from: {opacity: 0},
        opacity: 1,
        x: getLeft(props.mousePosInContainer.x),
        y: getTop(props.mousePosInContainer.y),
    });

    if (!props.getTooltipContent || !props.mousePosInContainer) {
        return null;
    }

    return (
        <animated.div
            ref={tooltipContainerRef}
            style={{
                position: 'absolute',
                pointerEvents: 'none',
                opacity,
                top: y ? y.to(y => y + 'px') : undefined,
                left: x ? x.to(x => x + 'px') : undefined,
            }}
        >
            {props.getTooltipContent()}
        </animated.div>
    )
}

XpContainerTooltip.propTypes = {
    mousePosInContainer : PropTypes.object.isRequired,
    containerHeight     : PropTypes.number.isRequired,
    containerWidth      : PropTypes.number.isRequired,
    toolTipMargin       : PropTypes.number,
    getTooltipContent   : PropTypes.func,
};
XpContainerTooltip.defaultProps = {
    toolTipMargin       : 5,
};

