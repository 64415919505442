import {createSelector} from "reselect";

const modals     = state => state.modals;

export const selectModals = createSelector(
    [modals],
    (modals) => {
        if (!modals)          return undefined;
        return modals;
    }
);