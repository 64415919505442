import {createReducer} from 'gui-common/functions/reducer.js'

//export const LOGIN_SESSION_UPDATED      = 'LOGIN_SESSION_UPDATED';
export const USER_LOGOUT               = 'USER_LOGOUT';
export const LOGIN_SESSION_PUSH_AWT    = 'LOGIN_SESSION_PUSH_AWT';
export const LOGIN_SESSION_POP_AWT     = 'LOGIN_SESSION_POP_AWT';

// Action creators ********************************************************
// ************************************************************************
/*
export function userLoginSessionUpdated(response) {
    return { type: LOGIN_SESSION_UPDATED,payload: response};
}
*/
export function userLogout(response) {
    return { type: USER_LOGOUT,             payload: response};
}
export function webSessionPushAwt(awt) {
    return { type: LOGIN_SESSION_PUSH_AWT, payload: awt};
}
export function webSessionPopAwt() {
    return { type: LOGIN_SESSION_POP_AWT };
}


// Reducer functions ********************************************************
// ************************************************************************
/*
function sessionUpdated(state, payload) {
    return {
        ...state,
        bankIdRequestId : payload.bankIdRequestId ? payload.bankIdRequestId : "",
        userSystemId    : payload.referenceId     ? payload.referenceId     : "",
        securityKey     : payload.key             ? payload.key             : "",
        sessionId       : payload.sessionId       ? payload.sessionId       : "",
    };
}
*/
function logout(state, payload) {
    if (payload.status !== "LOGOUT") {
        console.error("User logout. Wrong Status: " + payload.status);
    }
    return getInitialSession();
}
function pushAwt(state, payload) {
    if (!payload || !payload.length) return state;
    return {...state, awtList: state.awtList.concat(payload)}
}
function popAwt(  state, payload) {
    return {...state, awtList: state.awtList.slice(1)}
}

function getInitialSession () {
    let url_string = window.location.href;
    let url         = new URL(url_string);
    let key         = url.searchParams.get("key");
    let sessionId   = url.searchParams.get("sessionId");
    let referenceId = url.searchParams.get("referenceId");

    console.log("URL:", url);
    console.log("Login referenceId and key and sessionId:", referenceId, key, sessionId);

    return {
        userSystemId:       referenceId ? Number(referenceId)   : "",
        bankIdRequestId:    "",
        loginTimestamp:     "",
        awtList: [],
    };
}

export default createReducer(getInitialSession(), {
//    [LOGIN_SESSION_UPDATED]     : sessionUpdated,
    [USER_LOGOUT]              : logout,
    [LOGIN_SESSION_PUSH_AWT]   : pushAwt,
    [LOGIN_SESSION_POP_AWT]    : popAwt,
});
