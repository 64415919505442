import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {activeCurrenciesSelector} from "gui-common/orm/ormSelectors";
import {useCurrencyFromBankAccount} from "xpool-gui-common/appConfig/runConfiguration/runConfigurationFunctions";
import {createSelectItems} from "gui-common/functions/hooks";
import {XpFormContext} from "gui-common/xpForm/core/XpForm";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import XpTooltip from "gui-common/components/XpTooltip";

function HedgeExposureForm (props) {

    const decDenLangState  = useSelector(selectDecDenLangState);
    const ormCurrencies    = useSelector(activeCurrenciesSelector);
    const formContext      = useContext(XpFormContext);

    useCurrencyFromBankAccount(props);

    // const hedgeRollTypes = createSelectItems(['WEEKLY', 'BIWEEKLY', 'MONTHLY', 'QUARTERLY', 'ANNUAL']);
    const hedgeRollTypes     = createSelectItems(['MONTHLY', 'QUARTERLY']);
    const hedgeStrategyTypes = createSelectItems(['STATIC', 'ROLLING', 'LAYERED']);

    function str2num (val) {
        return stringNumberToFloat(val, decDenLangState);
    }

    return (
        <div>
            <div>
                <XpFormSelectorInput
                    noTemplate inLineLayout
                    inLineDivider={40}
                    disabled = {props.disabled}
                    field            = "hedgeStrategyType"
                    selectList       = {hedgeStrategyTypes}
                    itemTextField    = "name"
                />
                <XpFormSelectorInput
                    noTemplate inLineLayout
                    inLineDivider={40}
                    disabled = {props.disabled}
                    field            = "hedgeRollType"
                    selectList       = {hedgeRollTypes}
                    itemTextField    = "name"
                />
                {(props.ormModel !== 'BankAccount') &&
                <XpFormSelectorInput
                    inLineDivider={40}
                    disabled = {props.disabled}
                    field         = "currencyId"
                    selectList    = {ormCurrencies}
                    itemTextField = "currency"
                    isRequired  inLineLayout
                />}

            </div>
            <div className='tableInForm'>
                <table style={{width: '100%', margin: '0'}}>
                    <tbody>
                    <tr className="HeaderRow" key="header2">
                        <th style={{width: '30%' }} className="amountColumn"><XpTooltip trKey={formContext.formTemplate + '.minimumHedgeSize.toolTip' }><label><XpTranslated trKey={formContext.formTemplate + '.minimumHedgeSize.label' }/></label></XpTooltip></th>
                        <th style={{width: '40%' }} className="amountColumn"><XpTooltip trKey={formContext.formTemplate + '.maximumHedgeSize.toolTip' }><label><XpTranslated trKey={formContext.formTemplate + '.maximumHedgeSize.label' }/></label></XpTooltip></th>
                        <th style={{width: '30%' }} className="amountColumn"><XpTooltip trKey={formContext.formTemplate + '.hedgePercent.toolTip'     }><label><XpTranslated trKey={formContext.formTemplate + '.hedgePercent.label'     }/></label></XpTooltip></th>
                    </tr>

                    <tr>
                        <td>
                            <XpFormAmountInput
                                noTemplate alignRight inTable noLabel
                                field         = "minimumHedgeSize"
                                disabled = {props.disabled}
                                errors={{
                                    lowerThanZero     : (val, fields) => /\d/.test(val) && str2num(val) <= 0,
                                    higherThanMaxSize : (val, fields) => fields.maximumTradeSize && (/\d/.test(val) && str2num(val) >= str2num(fields.maximumTradeSize)),
                                }}
                                dependentFields={{
                                    maximumTradeSize: 'maximumHedgeSize',
                                }}
                            />
                        </td>
                        <td>
                            <XpFormAmountInput
                                noTemplate alignRight inTable noLabel
                                field         = "maximumHedgeSize"
                                disabled = {props.disabled}
                                dependentFields={{
                                    minimumTradeSize: 'minimumHedgeSize',
                                }}
                                errors={{
                                    lowerThanZero             : (val, fields) => /\d/.test(val) && str2num(val) <= 0,
                                    lowerThanMinSize          : (val, fields) => {
                                        return fields.minimumTradeSize && (/\d/.test(val) && str2num(val) <= str2num(fields.minimumTradeSize))
                                    },
                                }}
                            />
                        </td>
                        <td>
                            <XpFormTextInput
                                noTemplate alignRight noLabel inTable
                                field         = "hedgePercent"
                                // parser        = {accountNumberParser}
                                disabled      = {props.disabled}
/*
                                errors        = {{
                                    between0And100: (val, fields) => /\d/.test(val) && ((str2num(val) <= 0) || (str2num(val) > 100)),
                                }}
*/
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
HedgeExposureForm.propTypes = {
};
export default HedgeExposureForm

