import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import AuditViewFilterForm from "gui-common/audit/AuditViewFilterForm"
import {loadAuditEntryFromApi} from "gui-common/audit/auditApi";
import "gui-common/audit/audit.css"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {auditEntriesSelector} from "gui-common/audit/auditSelectors";
import AuditViewContent from "gui-common/audit/AuditViewContent";

export const auditEntriesListInstanceId = "auditEntriesList";

function AuditViewPage(props) {
    const dispatch  = useDispatch();
    const auditEntries       = useSelector(auditEntriesSelector);


    const auditFilterForm    = useXpFormFields({dependentFieldModels: {
            rootModel: 'auditViewFilterForm.rootModel',
        }})


    function fullEntryLoadRequest(auditEntry) {
        dispatch(loadAuditEntryFromApi(auditEntry));
    }

    return (
        <div>
            <div>
                <h2><XpTranslated trKey='auditView.header'/></h2>
                <hr/>
                <AuditViewFilterForm/>
            </div>

            <AuditViewContent
                auditEntries         = {auditEntries}
                requestFullEntryLoad = {auditEntry => fullEntryLoadRequest(auditEntry)}
                listInstanceId       = {'auditEntriesList'}
                contentInstanceId    = {'auditPage'}
                rootModel            = {auditFilterForm.rootModel}
                hiddenState = {{
                    id                  : true,
                    auditDateTime       : false,
                    rootObjectTypeT     : true,
                    rootObjectId        : true,
                    rootObjectNameT     : false,
                    operationTypeT      : false,
                    modelT              : false,
                    modifiedObjectId    : true,
                    modifiedObjectName  : false,
                    userId              : true,
                    userFullName        : false,
                    apiVersion          : true,
                }}
            />
        </div>
    );
}
export default AuditViewPage;

// <h2><XpTranslated2 trKey='axa.fishes.header' trParams={{fish: 123}} /></h2>
