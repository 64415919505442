import {
    xpoolFilterSocketMessage,
    xpoolSocketPayloadPrototypeMap
} from "xpool-gui-common/appConfig/api/appWebSocketConfig";

export function filterSocketMessage(parsedObject, state, dispatch) {
    return xpoolFilterSocketMessage(parsedObject, state, dispatch);
}

/* -----------------------------------------------------------------------------------------------------------------
* Socket message dictionaries for models and message types specific to the application. Common messages in gui-common
* -----------------------------------------------------------------------------------------------------------------*/

export const appSpecificSocketPayloadPrototypeMap = {
    ...xpoolSocketPayloadPrototypeMap,
};
