import appConfigReducer             from "appConfig/appConfigReducer";
import {currencyDashboardReducer} from "xpool-gui-common/features/currencyDashboard/currencyDashboardReducer";

export const appReducers = {
    appConfigState:         appConfigReducer,
    // loansState:             loansReducer,
    // cashPoolState:          cashPoolReducer,
    currencyDashboardState: currencyDashboardReducer,
    // balanceHistoryChart:    balanceHistoryChartReducer,
    // dragHandleState:        dragHandleReducer,
};
