import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import XpFormFieldGroup from "gui-common/xpForm/XpFormFieldGroup";
import {firstNumberHigherThanSecond, firstNumberLowerThanSecond} from "gui-common/xpForm/formComponentsValidators";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {activeCurrenciesSelector} from "gui-common/orm/ormSelectors";
import {useCurrencyFromBankAccount} from "xpool-gui-common/appConfig/runConfiguration/runConfigurationFunctions";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getSpecificBankAccountSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import {useXpFormContext, useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {accountNumberParser} from "gui-common/xpForm/formComponentsFunctions";


function SweepPanel(props) {
    const decDenLangState = useSelector(selectDecDenLangState);
    const runConfigurationConfig = useAppEnvProperty( 'runConfigurationConfig');
    const formData    = useXpFormFields({dependentFields: {
            useSweep : 'useSweep',
            useTopUp : 'useTopUp',
        }})

    return (
        <XpFormFieldGroup
            isExpanded        = {formData.useSweep === true}
            controlComponent  = {
                <XpFormSwitchInput
                    noMargin
                    field         = "useSweep"
                    {...props.commonFieldProps}
                />
            }
        >
            <XpFormAmountInput
                field         = "sweepThreshold"
                {...props.commonFieldProps}
                dependentFields={{
                    sweepTarget: 'sweepTarget',
                    topUpTarget: 'topUpTarget',
                    topUpThreshold: 'topUpThreshold',
                    useTopUp: 'useTopUp',

                }}
                errors={{
                    lowerThanSweepTarget:     (val, fields) => firstNumberLowerThanSecond(val, fields.sweepTarget   , true   , decDenLangState),
                    lowerThanTopUpTarget:     (val, fields) => firstNumberLowerThanSecond(val, fields.topUpTarget   , fields.useTopUp    , decDenLangState),
                    lowerThanTopUpThreshold:  (val, fields) => firstNumberLowerThanSecond(val, fields.topUpThreshold, fields.useTopUp    , decDenLangState),
                }}
            />
            <XpFormAmountInput
                field         = "sweepTarget"
                {...props.commonFieldProps}
                dependentFields={{
                    sweepThreshold: 'sweepThreshold',
                    topUpThreshold: 'topUpThreshold',
                    useTopUp: 'useTopUp',
                }}
                errors={{
                    higherThanSweepThreshold: (val, fields) => firstNumberHigherThanSecond(val, fields.sweepThreshold, true , decDenLangState),
                    lowerThanTopUpThreshold:  (val, fields) => firstNumberLowerThanSecond( val, fields.topUpThreshold, fields.useTopUp  , decDenLangState),
                }}
            />
            {runConfigurationConfig?.sweepAndTopUp?.useSweepHorizon &&
            <XpFormTextInput
                inLineLayout alignRight noTemplate
                field         = "sweepHorizon"
                parser        = {accountNumberParser}
                maxAmount={100}
            />}
            {props.showSwapCheckbox &&
            <XpFormSwitchInput
                noMargin
                field        = "useSwapForSweep"
                {...props.commonFieldProps}
            />}
        </XpFormFieldGroup>
    )
}

function TopUpPanel(props) {
    const decDenLangState = useSelector(selectDecDenLangState);
    const formData = useXpFormFields({
        dependentFields: {
            useSweep: 'useSweep',
            useTopUp: 'useTopUp',
        }
    })

    return (
        <XpFormFieldGroup
            isExpanded        = {formData.useTopUp === true}
            controlComponent  = {
                <XpFormSwitchInput
                    noMargin
                    field         = "useTopUp"
                    {...props.commonFieldProps}
                />
            }
        >
            <XpFormAmountInput
                field         = "topUpTarget"
                {...props.commonFieldProps}
                dependentFields={{
                    sweepThreshold: 'sweepThreshold',
                    topUpThreshold: 'topUpThreshold',
                    useSweep: 'useSweep',
                }}
                errors={{
                    higherThanSweepThreshold: (val, fields) => firstNumberHigherThanSecond(val, fields.sweepThreshold, fields.useSweep   , decDenLangState),
                    lowerThanTopUpThreshold:  (val, fields) => {
                        return firstNumberLowerThanSecond( val, fields.topUpThreshold, true   , decDenLangState)
                    },
                }}
            />
            <XpFormAmountInput
                field         = "topUpThreshold"
                {...props.commonFieldProps}
                dependentFields={{
                    topUpTarget: 'topUpTarget',
                    sweepTarget: 'sweepTarget',
                    useTopUp: 'useTopUp',
                }}
                errors={{
                    higherThanTopUpTarget: (val, fields) => firstNumberHigherThanSecond(val, fields.topUpTarget, true    , decDenLangState),
                    higherThanSweepTarget: (val, fields) => firstNumberHigherThanSecond(val, fields.sweepTarget, fields.useSweep    , decDenLangState),
                }}
            />
            {props.showSwapCheckbox &&
            <XpFormSwitchInput
                noMargin
                field        = "useSwapForTopUp"
                {...props.commonFieldProps}
            />}
        </XpFormFieldGroup>
    )
}

function SweepAndTopUpForm (props) {
    const formContext   = useXpFormContext();
    const entityData    = useXpFormFields({dependentFieldModels: {
            parentAccountId: props.baseEntityFormModel + '.parentAccountId',
            currencyId     : props.baseEntityFormModel + '.currencyId',
        }})
    const ormCurrencies   = useSelector(activeCurrenciesSelector);
    const parentAccount   = useSelectorInstance(getSpecificBankAccountSelector, {selectId: Number(entityData.parentAccountId)});

    useCurrencyFromBankAccount(props);

    const showSwapCheckbox = useMemo(
        () => {
            if (props.enforceShowSwapCheckbox)   return true;
            if (!parentAccount)                  return false; // No parent bankAccount. Hide checkbox.
            if (parentAccount.currencyId === "") return false; // Error in parent currency setting.
            if (entityData.currencyId === "")    return false; // Error in this form currency setting.
            if (parentAccount.currencyId === Number(entityData.currencyId)) return false; // Account has same currency as parent bankAccount.
            return true; // show checkbox!
        },[parentAccount, entityData],
    );

    const commonFieldProps = {
        disabled    : props.disabled,
        isRequired  : true,
    }

    return (
        <div>
            {(props.ormModel !== 'BankAccount') &&
            <XpFormSelectorInput
                {...commonFieldProps}
                field="currencyId"
                selectList={ormCurrencies}
                itemTextField="currency"
            />}
            {formContext.useMuiFieldStyle ?
                <div style={{display: 'flex'}}>
                    <div style={{width: 'calc(50% - 5px)', marginRight: '10px'}}>
                        <SweepPanel commonFieldProps={commonFieldProps} showSwapCheckbox={showSwapCheckbox} />
                    </div>
                    <div style={{width: 'calc(50% - 5px)'}}>
                        <TopUpPanel commonFieldProps={commonFieldProps} showSwapCheckbox={showSwapCheckbox} />
                    </div>

                </div>
                :
                <div>
                    <SweepPanel commonFieldProps={commonFieldProps} showSwapCheckbox={showSwapCheckbox} />
                    <TopUpPanel commonFieldProps={commonFieldProps} showSwapCheckbox={showSwapCheckbox} />
                </div>
            }
        </div>
    )
}
SweepAndTopUpForm.propTypes = {
};
export default SweepAndTopUpForm

