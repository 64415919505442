import {createSelector} from "reselect";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getLastChangedAuditInfo} from "gui-common/audit/auditFunctions";

const fxRejectConfigState = state => state.fxRejectConfigState;
const thisRejectType = (state, rejectType) => rejectType;

export const fxRejectConfigStateSelector = createSelector(
    [fxRejectConfigState, thisRejectType],
    (fxRejectConfigState, thisRejectType) => {
        if (!thisRejectType) return undefined;
        if (!fxRejectConfigState) return undefined;
        if (!fxRejectConfigState[thisRejectType]) return undefined;
        return fxRejectConfigState[thisRejectType];
    }
);

export const getOrmFxRejectConfigSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props.rejectType,
    (session, rejectType) => {
        const configs = session.FxRejectConfiguration.all().filter({rejectType: rejectType}).toRefArray().map(item => ({
            ...item,
            ...getLastChangedAuditInfo(item),
        }));
        return configs;
    }
);
