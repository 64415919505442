import ClientsStatusList from "features/client/ClientsStatusList";
import AllClientsOrdersList from "xpool-gui-common/features/order/AllClientsOrdersList";
import {AccountCashLadderList} from "features/bankAccount/AccountCashLadderList";
import BalanceApiSimulator from "xpool-gui-common/features/balanceApiSimulator/BalanceApiSimulator";

export const appSpecificDashboardConfig = {
    'ClientsStatusList': {
        component           : ClientsStatusList,
        authorizedFunction  : null,
        langPath            : 'clientsList.dashboardComponent.',
        icon                : 'list',
        keyName             : 'clients',
    },
    'OrdersList': {
        component           : AllClientsOrdersList,
        authorizedFunction  : null,
        langPath            : 'ordersList.dashboardComponent.',
        icon                : 'swap_horizontal_circle',
        keyName             : 'orders',
    },
    'AccountCashLadder': {
        component           : AccountCashLadderList,
        authorizedFunction  : () => (window.oxygen_env.USE_CASH_LADDER === true),
        langPath            : 'accountCashLadderList.dashboardComponent.',
        icon                : 'linear_scale',
        keyName             : 'cashLadder',
    },
    'BalanceApiSimulator': {
        component           : BalanceApiSimulator,
        authorizedFunction  : () => (window.oxygen_env.DEV_MODE === true),
        langPath            : 'balanceApiSimulator.dashboardComponent.',
        icon                : 'account_balance',
        keyName             : 'balanceApiSimulator',
        defaultSize         : {w: 7, h: 12}
    },
};
