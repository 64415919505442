import React from 'react'
import PropTypes from 'prop-types';
import FormattedAmount from "gui-common/components/FormattedAmount";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import moment from "moment";
import {useLedgerAccount} from "gui-common/ledger/ledgerHooks";
import {useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";


function AccountCurrentBalance(props) {

    const formData = useXpFormFields({ dependentFields:
            {
                ledgerBalanceLaId: 'ledgerBalanceLaId',
                postPoolingBalanceLaId: 'postPoolingBalanceLaId',
                currency: 'currency',
            }
    });

    const ledgerBalanceLa = useLedgerAccount(formData?.ledgerBalanceLaId);
    const postBalanceLa   = useLedgerAccount(formData?.postPoolingBalanceLaId);

    if (props.auditMode) return null;

    if (!ledgerBalanceLa) return (
        <div>
            <div className="field">
                <label>
                    <XpTranslated trKey={'accountForm.noCurrentBalance'}/>
                </label>
            </div>
            <hr/>
        </div>
    );

    return (
        <div className={props.className}>
            <div className="field">
                <label className="inlineLabel">
                    <XpTranslated trKey={'accountForm.balance'} trParams={{date: moment(ledgerBalanceLa.lastModified).format('lll')}}/>
                </label>
                {((ledgerBalanceLa.balance !== null) && (ledgerBalanceLa.balance !== undefined)) &&
                <div className="inlineValueNumber">
                    <FormattedAmount
                        amount={ledgerBalanceLa.balance}
                        currencyPrefix={formData?.currency ? formData?.currency.currency : ""}
                    />
                </div>}
            </div>
            <div className="field">
                <label className="inlineLabel">
                    <XpTranslated trKey={'accountForm.postPoolingBalance'}/>
                </label>
                {((postBalanceLa.balance !== null) && (postBalanceLa.balance !== undefined)) &&
                <div className="inlineValueNumber">
                    <FormattedAmount
                        amount={postBalanceLa.balance}
                        currencyPrefix={formData?.currency ? formData?.currency.currency : ""}
                    />
                </div>}
            </div>
        </div>
    );
}

AccountCurrentBalance.propTypes = {
    className:        PropTypes.string,
};

export default AccountCurrentBalance;
