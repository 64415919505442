import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import OrdersList from "xpool-gui-common/features/order/OrdersList";
import {clientOrdersListDataSelector} from "xpool-gui-common/features/order/orderSelectors";
import {activeClientSelector} from "xpool-gui-common/features/client/clientSelectors";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useResizeObserver} from "gui-common/functions/hooks";

const orderListHiddenState = {

    id                      : true,

    // clientId                : true,
    // clientName              : false,

    // sourceAccountName       : false,
    // sourceAmountCcy         : false,
    // destinationAccountName  : false,
    // destinationAmountCcy    : false,

    orderType               : false,
    adjustedSideT           : false,
    currencyPairStr         : false,
    externalReference       : true,
    settlementDate          : true,

    nominalAmountCcy        : false,
    counterAmountCcy        : false,
    displayRate             : false,

    displayStateT           : false,

    createdDateTime         : true,
    modifiedDateTime        : true,
    executionDateTime        : true,
}

function CurrencyDashboardOrdersList (props) {

    // const dispatch  = useDispatch();
    // const accounts = useActiveClientLE0Accounts();
    // const baseAccount = useBaseAccount();
    // const currencyDashboardState = useSelector(currencyDashboardStateSelector)
    // const selectedAccount        = useSelectorInstance(getSpecificBankAccountSelector, {selectId: currencyDashboardState?.selectedAccountId});

    const activeClient = useSelector(activeClientSelector);

    const dashboardOrderFormModel = 'dashboardOrderListFilter';
    // const switchFormData   = useXpFormField(dashboardOrderFormModel);

    const [formContainerHeight, setFormContainerHeight]   = useState(undefined);

    const observerCallback = useCallback(box => {
        if (formContainerHeight === box.height) {
            // No need to update!
            return;
        }
        setFormContainerHeight(box.height);
    }, []);
    const formContainerRef = useResizeObserver(observerCallback)

    /*
        const fromCurrencyId = useMemo(
            () => {
                if (!accounts || accounts?.length < 2) {
                    return undefined;
                }
                if (baseAccount) {
                    return baseAccount.currency.id;
                }
                return accounts[0].currency.id;
            },
            [baseAccount, accounts]
        );

        const toCurrencyId = useMemo(
            () => {
                if (!fromCurrencyId) {
                    return undefined;
                }
                if (selectedAccount && baseAccount && (selectedAccount.id !== baseAccount.id)) {
                    return selectedAccount.currency.id;
                }
                for (const account of accounts) {
                    if (account.currency.id === fromCurrencyId) continue;
                    return account.currency.id;
                }
                return undefined;
            },
            [fromCurrencyId, baseAccount, accounts, selectedAccount]
        );
    */

    return (
        <div>
            <div ref={formContainerRef}>
                <XpForm
                    formModel={dashboardOrderFormModel}
                    onSubmit={(data) => {}}
                    initialUseState={XP_FORM_EDIT}
                    // currentData={clientPrice?.clientPrice}
                >
                    <div style={{display: 'flex', padding: 'var(--xpool-currency-dashboard-padding)', justifyContent: 'space-between', alignItems: 'center'}}>
                        <XpFormSwitchInput
                            field={'suggested'}
                            defaultValue={true}
                            labelKey={'currencyDashboard.orderListFilter.showSettledTrades'}
                        />
{/*
                        <WaitingXpoolButton
                            onClickCallback={x => {
                                dispatch(pushModalWindow(ORDER_ENTRY_DIALOG, {fromCurrencyId: fromCurrencyId, toCurrencyId: toCurrencyId}));
                            }}
                            disabled={!baseAccount}
                            labelKey={'currencyDashboard.buttons.tradeFx.label'}
                            floatRight
                        />
*/}
                    </div>
                </XpForm>
            </div>
            <OrdersList
                instanceId={"currencyDashBoardOrderList"}
                rowDataSelector={clientOrdersListDataSelector}
                rowDataSelectorProps={{
                    clientId: activeClient?.id,
                    filterFunction: order => {
                        return true;
                    }
                }}
                hiddenState={orderListHiddenState}
            />
        </div>
    )
}
CurrencyDashboardOrdersList.propTypes = {
};
export default CurrencyDashboardOrdersList



