import React, { Component } from 'react'
import {connect} from "react-redux";
import moment from 'moment';
import 'gui-common/dashboard/dashboard.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {dashboardSetLayout, dashboardSetSizeInfo, dashboardRemoveComponent} from './dashboardReducer';
import { WidthProvider, Responsive } from "react-grid-layout";
import DashboardComponentContainer from './DashboardComponentContainer'
import {userSelector} from "gui-common/orm/ormSelectors";
import {dashboardConfig} from "./dashboardConfig";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

/*
export const userAuthorizedFunction = {
    'IntegrationStatusContainer' : (user) => userCan('Read', 'SystemStatus', user),
    'ClientsStatusList'          : null,
    'UserMessagesList'           : null,
    'OrdersList'                 : null,
    'FxPriceList'                : null,
    ...appSpecificUserAuthorizedFunction,
};
*/

class DashboardCanvas extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        if (this.props.user && this.props.dashboardState && this.props.dashboardState.activeComponents) {
            for (let item of this.props.dashboardState.activeComponents) {
                if (!dashboardConfig[item.componentName] || !dashboardConfig[item.componentName].authorizedFunction) continue;
                if (!dashboardConfig[item.componentName].authorizedFunction(this.props.user)) this.props.dashboardRemoveComponent(item.key);
            }
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let sizeInfo ={};
        if(this.props !== null && this.props !== undefined) {
            sizeInfo.vwWidth = window.innerWidth;
            sizeInfo.vwHeight = window.innerHeight;
        }
        if(this.canvasRef !== null && this.canvasRef !== undefined && this.canvasRef.current !== null && this.canvasRef.current !== undefined) {
            let sizeCanvas = this.canvasRef.current.getBoundingClientRect();
            if (sizeCanvas !== null && sizeCanvas !== undefined) {
                sizeInfo.panelWidth = sizeCanvas.width;
                sizeInfo.panelHeight = sizeCanvas.height;
                sizeInfo.left = sizeCanvas.left;
                sizeInfo.top = sizeCanvas.top;
                sizeInfo.right = sizeCanvas.right;
            }
        }
        this.props.setSizeInfo(sizeInfo);
    }


    onLayoutChange(layout, layouts) {
        this.props.setLayout(layouts);
    }

    onWidthChange(containerWidth) {
        //console.log('contwidth:', containerWidth);
    }

    breakPointChange(newBreakpoint, newCols) {
        //console.log('bp1:', newBreakpoint);
        //console.log('bp2:', newCols);
    }

    contentDiv = function(item) {
        return(
            <div key={item.key} data-grid={item.dataGrid}>
                <DashboardComponentContainer componentData={item} />
            </div>
        );
    };

    render() {
        moment.locale(this.props.currentLanguage);
        return (
            <div className="dashboardCanvas" ref={this.canvasRef}>
                <ResponsiveReactGridLayout
                    className="layout"
                    cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    rowHeight={30}
                    layouts={this.props.dashboardState.layouts}
                    onLayoutChange={(layout, layouts) => this.onLayoutChange(layout, layouts)}
                    onWidthChange={(containerWidth, cols) => this.onWidthChange(containerWidth, cols)}
                    onBreakpointChange={(newBreakpoint, newCols) => this.breakPointChange(newBreakpoint, newCols)}
                    draggableHandle='.dashBoardComponentHeaderText'
                >
                    {this.props.dashboardState.activeComponents.map((item) => this.contentDiv(item))}
                </ResponsiveReactGridLayout>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboardState:         state.dashboardState,
        user:                   userSelector(state),
        translate:          selectTranslateFunction(state),
        currentLanguage:    selectActiveLanguage(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLayout :  (layouts)   => dispatch(dashboardSetLayout(layouts)),
        setSizeInfo : (sizeInfo) => dispatch(dashboardSetSizeInfo(sizeInfo)),
        dashboardRemoveComponent :  (key)   => dispatch(dashboardRemoveComponent(key)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(DashboardCanvas);
