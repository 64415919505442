import cloneDeep from "lodash/cloneDeep";

import {ORM_ENTITY_LOAD_BATCH, ORM_ENTITY_UPDATE} from "gui-common/orm/ormConstants"

export const ENTITY_STATE_REQUESTED_BULK        = 'ENTITY_STATE_REQUESTED_BULK';
export const ENTITY_STATE_REQUESTED             = 'ENTITY_STATE_REQUESTED';
export const ENTITY_STATE_RECEIVED              = 'ENTITY_STATE_RECEIVED';
export const ENTITY_STATE_REQUEST_CLEAR         = 'ENTITY_STATE_REQUEST_CLEAR';
export const ENTITY_STATE_REQUEST_CLEAR_MODEL   = 'ENTITY_STATE_REQUEST_CLEAR_MODEL';
export const ENTITY_STATE_REQUEST_CLEAR_ALL     = 'ENTITY_STATE_REQUEST_CLEAR_ALL';

// Action creators ********************************************************
// ************************************************************************

export function entityStateRequested(model, entityId, requestedState, propertyToMonitor, acceptedStatesToClearRequest) {
    if (!model || !entityId || !propertyToMonitor || ((requestedState === undefined) && !acceptedStatesToClearRequest)) {
        console.log("Incorrect parameters to entityStateRequested: ", model, entityId, requestedState, propertyToMonitor, acceptedStatesToClearRequest);
        return {type:'NONE'};
    }
    let acceptedStates = (!acceptedStatesToClearRequest || (acceptedStatesToClearRequest.length === 0)) ? [] : acceptedStatesToClearRequest;
    if (requestedState !== undefined) acceptedStates.push(requestedState);
    return {
        type: ENTITY_STATE_REQUESTED,
        payload: {
            model                        : model,
            entityId                     : entityId,
            requestedState               : requestedState,
            propertyToMonitor            : propertyToMonitor,
            acceptedStatesToClearRequest : acceptedStates,
        }
    };
}
export function entityStateRequestedBulk(requestArray) {
    if (!Array.isArray(requestArray)) {
        console.log("Incorrect parameter to entityStateRequestedBulk: ", requestArray);
        return {type:'NONE'};
    }
    for (let item of requestArray) {
        let acceptedStates = (!item.acceptedStatesToClearRequest || (item.acceptedStatesToClearRequest.length === 0)) ? [] : item.acceptedStatesToClearRequest;
        if (item.requestedState !== undefined) acceptedStates.push(item.requestedState);
        item.acceptedStatesToClearRequest = acceptedStates;
    }
    return  {
        type: ENTITY_STATE_REQUESTED_BULK,
        payload: requestArray,
    };
}
export function entityStateReceived(model, entityId, receivedState, receivedOnProperty) {
    if (!model || !entityId || (receivedState === undefined) || !receivedOnProperty) {
        console.log("Incorrect parameters to entityStateReceived: ", model, entityId, receivedState, receivedOnProperty);
        return {type:'NONE'};
    }
    return {type: ENTITY_STATE_RECEIVED, payload: {model: model, entityId: entityId, receivedState: receivedState, receivedOnProperty: receivedOnProperty}};
}
export function entityStateRequestClear(model, entityId, propertyToMonitor) {
    if (!model || !entityId || !propertyToMonitor) {
        console.log("Incorrect parameters to entityStateRequestClear: ", model, entityId, propertyToMonitor);
        return {type:'NONE'};
    }
    return {type: ENTITY_STATE_REQUEST_CLEAR, payload: {model: model, entityId: entityId, propertyToMonitor: propertyToMonitor}};
}
export function entityStateRequestClearModel(model) {
    if (!model) {
        console.log("Incorrect parameters to entityStateRequestClearModel: ", model);
        return {type:'NONE'};
    }
    return {type: ENTITY_STATE_REQUEST_CLEAR_MODEL, payload: {model: model}};
}
export function entityStateRequestClearAll() {
    return {type: ENTITY_STATE_REQUEST_CLEAR_ALL, payload: {}};
}


// Reducer functions ********************************************************
// ************************************************************************
function setRequested(state, payload) {
    let newState = cloneDeep(state);
    if (!newState[payload.model]) newState[payload.model] = {}; // create dictionary for this ormModel.
    if (!newState[payload.model][payload.entityId]) newState[payload.model][payload.entityId] = {}; // create dictionary for this instance.

    // Create property for for the property to monitor and assign array with accepted states to clear the request.
    newState[payload.model][payload.entityId][payload.propertyToMonitor] = payload.acceptedStatesToClearRequest;

    return newState;
}
function setRequestedBulk(state, payload) {
    let newState = cloneDeep(state);
    for (let request of payload) {
        if (!newState[request.model]) newState[request.model] = {}; // create dictionary for this ormModel.
        if (!newState[request.model][request.entityId]) newState[request.model][request.entityId] = {}; // create dictionary for this instance.

        // Create property for for the property to monitor and assign array with accepted states to clear the request.
        newState[request.model][request.entityId][request.propertyToMonitor] = request.acceptedStatesToClearRequest;
    }

    return newState;
}
function clearRequested(state, payload) {
    if (!state[payload.model] || !state[payload.model][payload.entityId] || !state[payload.model][payload.entityId][payload.propertyToMonitor]) return state;

    let newState = cloneDeep(state);
    delete newState[payload.model][payload.entityId][payload.propertyToMonitor];
    return newState;
}
function clearModel(state, payload) {
    if (!state[payload.model]) return state;

    let newState = cloneDeep(state);
    delete newState[payload.model];
    return newState;
}
function clearAll(state, payload) {
    return {};
}
function stateReceived(state, payload) {
    // Check if there exist a requested state for the received property for the model and entityId. If not return with no action.
    if (!state[payload.model] || !state[payload.model][payload.entityId] || !state[payload.model][payload.entityId][payload.receivedOnProperty]) return state;

    // Check if the received state exists in the acceptedStatesToClearRequest array that was assigned the property
    if (state[payload.model][payload.entityId][payload.receivedOnProperty].indexOf(payload.receivedState) === -1) return state;

    // The received state was one of the accepted states -> clear the state request!
    let newState = cloneDeep(state);
    delete newState[payload.model][payload.entityId][payload.receivedOnProperty];
    return newState;
}



function getClearObjectFromItemUpdate(state, item, itemType) {
    if (!itemType || !item.id) return false;
    if (!state[itemType]) return false; // No outstanding state requests for the ORM model.
    if (!state[itemType][item.id]) return false; // No outstanding state requests for this instance of the entity.
    for (let pendingRequestProperty in state[itemType][item.id]) {
        if (item[pendingRequestProperty] === undefined) continue; // The pending request property does not exist in the received entity -> check next pending property.
        if (state[itemType][item.id][pendingRequestProperty].indexOf(item[pendingRequestProperty]) === -1) continue; // received property value not in the acceptedStatesToClearRequest array -> next prop!

        // The received state was one of the accepted states -> return a clear request object!
        return {
            itemType: itemType,
            itemId: item.id,
            property: pendingRequestProperty,
        }
    }
    return false;
}
function getClearedStateFromClearObjects(state, clearObjects) {
    let newState = cloneDeep(state);
    for (let clearObject of clearObjects) {
        if (!newState[clearObject.itemType] || !newState[clearObject.itemType][clearObject.itemId] || !newState[clearObject.itemType][clearObject.itemId][clearObject.property]) {
            console.warn("something wrong in getClearedStateFromClearObjects: ", state, clearObjects);
            continue;
        }
        delete newState[clearObject.itemType][clearObject.itemId][clearObject.property];
        if (Object.keys(newState[clearObject.itemType][clearObject.itemId]).length === 0) delete newState[clearObject.itemType][clearObject.itemId]; // Remove id property if no requests pending
        if (Object.keys(newState[clearObject.itemType]).length === 0) delete newState[clearObject.itemType]; // Remove model property if no requests pending
    }
    return newState;
}

function ormEntityUpdateReceived(state, payload) {
    const clearObject = getClearObjectFromItemUpdate(state, payload.item, payload.itemType);
    if (clearObject) return getClearedStateFromClearObjects(state, [clearObject]);
    return state;
}

function ormEntityBatchUpdateReceived(state, payload) {
    let clearObjects = [];
    for (let updateItem of payload) {
        const clearObject = getClearObjectFromItemUpdate(state, updateItem.itemData, updateItem.model);
        // In a batch there could be several updates that will produce identical clear objects. Any add if not already present in the list.
        if (clearObject && !clearObjects.find(item => item.itemType === clearObject.itemType && item.itemId === clearObject.itemId && item.property === clearObject.property)) {
            clearObjects.push(clearObject);
        }
    }
    if (clearObjects.length > 0) return getClearedStateFromClearObjects(state, clearObjects);
    return state;
}



// Reducer action handlers map ************************************************
// ************************************************************************
const actionHandler = {
    [ENTITY_STATE_REQUESTED]           : setRequested,
    [ENTITY_STATE_REQUESTED_BULK]      : setRequestedBulk,
    [ENTITY_STATE_RECEIVED]            : stateReceived,
    [ENTITY_STATE_REQUEST_CLEAR]       : clearRequested,
    [ORM_ENTITY_UPDATE]                : ormEntityUpdateReceived,
    [ORM_ENTITY_LOAD_BATCH]            : ormEntityBatchUpdateReceived,
    [ENTITY_STATE_REQUEST_CLEAR_MODEL] : clearModel,
    [ENTITY_STATE_REQUEST_CLEAR_ALL]   : clearAll,
};

const getInitialState = () => ({});

export function requestEntityStateReducer(state = getInitialState(), action) {
    const handler = actionHandler[action.type];
    return handler ? handler(state, action.payload) : state;
}
