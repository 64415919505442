import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {currencyDashboardStateSelector} from "xpool-gui-common/features/currencyDashboard/currencyDashboardSelectors";
import "xpool-gui-common/features/currencyDashboard/currencyDashboard.css"
import {getSpecificBankAccountSelector} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import AccountBalanceGraph from "xpool-gui-common/features/bankAccount/AccountBalanceGraph";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpResizable from "gui-common/components/XpResizable";
import CurrencyDashboardHeader from "xpool-gui-common/features/currencyDashboard/CurrencyDashboardHeader";
import {XpTab, XpTabs} from "gui-common/components/xpTabs/XpTabs";
import AccountBalanceListCurrencyDashboard from "xpool-gui-common/features/bankAccountBalance/AccountBalanceListCurrencyDashboard";
import moment from "moment";
import {activeClientSelector} from "xpool-gui-common/features/client/clientSelectors";
import XpSplitPane from "gui-common/components/XpSplitPane";
import CurrencyDashboardClientSettingsForm from "xpool-gui-common/features/currencyDashboard/CurrencyDashboardClientSettingsForm";
import BalanceMovementsList from "xpool-gui-common/features/balanceMovement/BalanceMovementsList";
import NivoLabFxRate from "xpool-gui-common/features/currencyDashboard/NivoLabFxRate";

import SchoolIcon from '@mui/icons-material/School';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SsidChartIcon from '@mui/icons-material/SsidChart'
import {useXpTabSelectedIndex} from "gui-common/components/xpTabs/xpTabsSelectors";
import CurrencyDashboardErpSettingsForm from "xpool-gui-common/features/currencyDashboard/CurrencyDashboardErpSettingsForm";
import CurrencyDashboardOrdersList from "xpool-gui-common/features/currencyDashboard/CurrencyDashboardOrdersList";
import CurrencyDashboardCurrencySettingsForm from "xpool-gui-common/features/currencyDashboard/CurrencyDashboardCurrencySettingsForm";
import BalanceIcon from '@mui/icons-material/Balance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {useLedgerAccountWithBalances} from "gui-common/ledger/ledgerHooks";

const rightColumnMinWidth = '500px'
const rightPanelMenuInstanceId = 'currencyDashboardRightIconsMenu';
function CurrencyDashboardPage (props) {

    const activeClient = useSelector(activeClientSelector);
    const currencyDashboardState = useSelector(currencyDashboardStateSelector)
    const selectedAccount        = useSelectorInstance(getSpecificBankAccountSelector, {selectId: currencyDashboardState?.selectedAccountId});
    const rightPanelMenuSelectedIndex = useXpTabSelectedIndex(rightPanelMenuInstanceId);

    const activeLedgerBalanceAccount      = useLedgerAccountWithBalances(selectedAccount?.ledgerBalanceLaId);
    // const activePendingBalanceAccount     = useLedgerAccountWithBalances(selectedAccount?.pendingBalanceLaId);
    const activeSuggestedBalanceAccount   = useLedgerAccountWithBalances(selectedAccount?.suggestedBalanceLaId);
    // const activePostPoolingBalanceAccount = useLedgerAccountWithBalances(selectedAccount?.postPoolingBalanceLaId);

    const today = moment().endOf('day');

    useEffect(
        () => {
            console.log("rightPanelMenuSelectedIndex: ", rightPanelMenuSelectedIndex);
        },
        [rightPanelMenuSelectedIndex],
    );

    return (
        <div>
            <CurrencyDashboardHeader
                rightColumnMinWidth={rightColumnMinWidth}
            />
            {(activeClient && selectedAccount) &&
            <div style={{minWidth: '1000px', overflow: 'auto'}}>
                <XpSplitPane
                    defaultWidthSecondaryPixels={550}
                    minSizePrimary={600}
                    minSizeSecondary={400}
                    fixedSecondaryWidth = {rightPanelMenuSelectedIndex === false ? 55 : undefined}
                    hideDragHandle    = {rightPanelMenuSelectedIndex === false ? true : undefined}
                >
                    <div style={{paddingRight: (rightPanelMenuSelectedIndex === false) ? '0px' : 'calc(var(--xpool-currency-dashboard-padding) / 2)'}}>
                        <XpResizable
                            className='balanceGraphInCurrencyDashboard'
                            defaultHeight={600}
                        >
                            <XpTabs instanceId={'currencyDashboardGraph'} headerClass={'xpTabsHeader'} vertical menuWidth={55} disableNoneSelectable fillHeight>
                                <XpTab icon={<SsidChartIcon />} >
                                    <AccountBalanceGraph
                                        instanceId={'currencyDashboard'}
                                        selectedAccount={selectedAccount}
                                        bankAccountFormModel={selectedAccount ? 'xpAdminForm.cashPoolAccountForm.BankAccount.' + selectedAccount.id : undefined}
                                    />
                                </XpTab>
                                <XpTab icon={<TrendingUpIcon />} >
                                    <NivoLabFxRate/>
                                    FX rates history
                                </XpTab>
                            </XpTabs>
                        </XpResizable>
                        <XpTabs instanceId={'currencyDashboardLists'} headerClass={'xpTabsHeader'} style={{paddingTop: '6px'}} disableNoneSelectable>
                            <XpTab label={<XpTranslated trKey={'currencyDashboard.listTabs.balances'}/>}>
                                <AccountBalanceListCurrencyDashboard
                                    instanceId={"cashPool-balances-list"}
                                    rowDataSelectorProps={{
                                        includeFuture: false,
                                        includeDisabled: true,
                                        sortOrder: 'desc',
                                        ledgerAccountId: selectedAccount?.ledgerBalanceLaId,
                                        filterFunction: item => {
                                            return today.isAfter(moment(item.valueDate))
                                        },
                                    }}
                                />
                            </XpTab>
                            <XpTab label={<XpTranslated trKey={'currencyDashboard.listTabs.orders'}/>}>
                                <CurrencyDashboardOrdersList/>
                            </XpTab>
                            <XpTab label={<XpTranslated trKey={'currencyDashboard.listTabs.balanceMovements'}/>}>
                                <BalanceMovementsList
                                    instanceId="balanceMovementsList"
                                    rowDataSelectorProps={{bankAccount: selectedAccount}}
                                />
                            </XpTab>
                        </XpTabs>
                    </div>

                    <div style={{paddingLeft: 'calc(var(--xpool-currency-dashboard-padding) / 2)'}}>
                        <XpTabs instanceId={rightPanelMenuInstanceId} vertical tabsToRight menuWidth={55}>
                            <XpTab icon={<BalanceIcon />} tooltipTrKey={'currencyDashboard.verticalToolbar.xpoolSettings.tooltip'}>
                                <div className={'currencyDashboardSubHeader'} ><XpTranslated trKey={'currencyDashboard.verticalToolbar.xpoolSettings.header'} /></div>
                                <XpTabs instanceId={'currencyDashboardSettings'} disableNoneSelectable>
                                    <XpTab label={(<XpTranslated trKey={'currencyDashboard.verticalToolbar.xpoolSettings.settingsTabs.accountSettings'} trParams={{currency: selectedAccount?.currency?.currency}}/>)} >
                                        <div className="poolAccountForm" >
                                            {selectedAccount?.parentAccountId ?
                                                <CurrencyDashboardCurrencySettingsForm account={selectedAccount}/>
                                                :
                                                <div className="admin1ColumnsContainer">
                                                    <div className="admin1Column">
                                                        <XpTranslated trKey='cashPoolAccountForm.noPoolingParamsForTopAccount'/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </XpTab>
                                    <XpTab label={<XpTranslated trKey={'currencyDashboard.verticalToolbar.xpoolSettings.settingsTabs.clientSettings'} />}>
                                        {activeClient &&
                                            <CurrencyDashboardClientSettingsForm client={activeClient}/>
                                        }
                                    </XpTab>
                                </XpTabs>
                            </XpTab>
                            <XpTab icon={<AccountBalanceWalletIcon />} tooltipTrKey={'currencyDashboard.verticalToolbar.erpSettings.tooltip'}>
                                <div className={'currencyDashboardSubHeader'} ><XpTranslated trKey={'currencyDashboard.verticalToolbar.erpSettings.header'} /></div>
                                {activeClient &&
                                    <CurrencyDashboardErpSettingsForm client={activeClient}/>
                                }
                            </XpTab>
                            <XpTab icon={<SchoolIcon />} tooltipTrKey={'currencyDashboard.verticalToolbar.fxCampus.tooltip'}>
                                <div className={'currencyDashboardSubHeader'} ><XpTranslated trKey={'currencyDashboard.verticalToolbar.fxCampus.header'} /></div>
                            </XpTab>
                        </XpTabs>
                    </div>

                </XpSplitPane>
            </div>}
        </div>
    )
}
export default CurrencyDashboardPage


// <XpTab icon={<SsidChartIcon />} label={<XpTranslated trKey={'currencyDashboard.graphTabs.cashForecast'} trParams={{currency: selectedAccount?.currency?.currency}}/> }>

/*
                            <XpTab label={<XpTranslated trKey={'currencyDashboard.settingsTabs.suggestions'}/>}>
                                Suggestions
                            </XpTab>
                            <XpTab label={<XpTranslated trKey={'currencyDashboard.settingsTabs.messages'}/>}>
                                Messages
                            </XpTab>



                    <div style={{paddingLeft: 'calc(var(--xpool-currency-dashboard-padding) / 2)'}}>
                        <XpTabs instanceId={'currencyDashboardSettings'} >
                            <XpTab label={(<XpTranslated trKey={'currencyDashboard.settingsTabs.accountSettings'} trParams={{currency: selectedAccount?.currency?.currency}}/>)} >
                                <div className="poolAccountForm" >
                                    {selectedAccount?.parentAccountId ?
                                        <CashPoolAccountForm account={selectedAccount}/>
                                        :
                                        <div className="admin1ColumnsContainer">
                                            <div className="admin1Column">
                                                <XpTranslated trKey='cashPoolAccountForm.noPoolingParamsForTopAccount'/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </XpTab>
                            <XpTab label={<XpTranslated trKey={'currencyDashboard.settingsTabs.clientSettings'} />}>
                                {activeClient &&
                                    <ClientSettingsForm client={activeClient}/>
                                }
                            </XpTab>
                            <XpTab label={<XpTranslated trKey={'currencyDashboard.listTabs.reports'}/>}>
                                <ReportPage noHeader/>
                            </XpTab>
                        </XpTabs>
                    </div>



*/


