import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {
    getModifiedUserSelector,
    getTopDataRightsCollectionsSelector,
} from "gui-common/adminUserRights/adminUserRightsSelectors"
import {
    activateDataRightsCollectionFromApi, deleteDataRightsCollectionFromApi, getPendingUserRightsCollectionFromApi,
    getUserDataRightsFromApi, putDataRightsCollectionFromApi,
} from "gui-common/adminUserRights/adminUserRightsApi";
import {ormItemLoading, ormModelLoadingNew} from "gui-common/orm/ormLoadingSelectors";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import spinnerGif from "gui-common/resources/spinner.gif";
import AdminDataRightsCollection from "gui-common/adminUserRights/AdminDataRightsCollection";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


function AdminDataRights(props) {
    const dispatch  = useDispatch();
    const translate = useXpTranslateFunction();

    const topDataRightsCollectionsSelector = useMemo(() => getTopDataRightsCollectionsSelector()    , []);
    const dataRightsCollections = useSelector(state => topDataRightsCollectionsSelector(state, {user: props.user}));

    const readOnly = useMemo(() => {
        if (!dataRightsCollections) return true;
        return (dataRightsCollections.length === 1);
    }, [dataRightsCollections]);

    const dataRightsCollection = useMemo(() => {
        return dataRightsCollections.find(item => item.state === (readOnly ? 'ACTIVE' : 'PENDING'));
    }, [dataRightsCollections, readOnly]);

    const activeDataRightsCollection = useMemo(() => {
        return dataRightsCollections.find(item => item.state === 'ACTIVE');
    }, [dataRightsCollections]);

    const modifiedUserSelector = useMemo(() => getModifiedUserSelector(), []);
    const modifiedUser         = useSelector(state => modifiedUserSelector(state, {selectId: dataRightsCollection ? dataRightsCollection.user.userId : null}));

    const userRightsLoading            = useSelector(state => ormItemLoading    (state, {ormModel: 'User'                , itemId: props.user           ? props.user.id           : null}));
    const dataRightsCollectionLoading  = useSelector(state => ormItemLoading    (state, {ormModel: 'DataRightsCollection', itemId: dataRightsCollection ? dataRightsCollection.id : null}));
    const dataRightsCollectionCreating = useSelector(state => ormModelLoadingNew(state, {ormModel: 'DataRightsCollection'}));

    useEffect(
        () => {
            if (!props.user) return;
            if (userRightsLoading) return;
            if (dataRightsCollectionLoading) return;
            if (!dataRightsCollections || !dataRightsCollections.length) {
                // console.log("Calling API for user rights.", props.user, dataRightsCollections?.length, dataRightsCollectionLoading, userRightsLoading, dataRightsCollectionCreating);

                dispatch(getUserDataRightsFromApi(props.user.id));
            }
        },
        [props.user, dataRightsCollections, dataRightsCollectionLoading],
    );

    const pendingDiffersFromActive = useMemo(() => {
        if (!dataRightsCollection) return false;
        if (!dataRightsCollection || (dataRightsCollection.state !== 'PENDING')) return false
        if (!activeDataRightsCollection || !activeDataRightsCollection.assigned) return true;

        for (let pending of dataRightsCollection.assigned)       {
            if (!activeDataRightsCollection.assigned.find(item => item.entity.id === pending.entity.id)) return true;
        }
        for (let active  of activeDataRightsCollection.assigned) {
            if (      !dataRightsCollection.assigned.find(item => item.entity.id === active.entity.id )) return true;
        }
        return false;
    }, [dataRightsCollection, dataRightsCollection ? dataRightsCollection.assigned.length : undefined, activeDataRightsCollection]);


    function activateDataRights() {
        if (props.user.state !== 'Active') {
            dispatch(activateDataRightsCollectionFromApi(dataRightsCollection, activeDataRightsCollection))
            return;
        }
        // User is logged in. Request confirmation before saving.
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey:  'adminUserRights.confirmSaveActiveUserModal'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    dispatch(activateDataRightsCollectionFromApi(dataRightsCollection, activeDataRightsCollection))
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }

    function saveAndActivate () {
        dispatch(putDataRightsCollectionFromApi(dataRightsCollection))
            .then(resp => {
                activateDataRights()
            })
    }

    if (!dataRightsCollection) return null;

    if (userRightsLoading) return (
        <div xp-test-id="auditEntrySpinner">
            <p className="valueText">{translate("adminUserRights.loadingUser")} </p>
            <img className="spinner" src={spinnerGif} alt="waiting"/>
        </div>
    );

    return (
        <div>
            <AdminDataRightsCollection
                dataRightsCollectionId = {dataRightsCollection.id}
                activeDataRightsCollectionId = {activeDataRightsCollection.id}
                simpleMode             = {props.simpleMode}
                compressedMode         = {props.compressedMode}
                readOnly               = {dataRightsCollection.state === 'ACTIVE'}
            />
            {!props.simpleMode && <hr/>}
            <div>
                {(dataRightsCollection.state === 'ACTIVE') &&
                <WaitingXpoolButton
                    onClickCallback = {() => dispatch(getPendingUserRightsCollectionFromApi(props.user.id))}
                    labelKey        = 'general.buttons.edit'
                    waiting         = {dataRightsCollectionCreating}
                />}
                {(dataRightsCollection.state === 'PENDING') &&
                <WaitingXpoolButton
                    onClickCallback = {() => dispatch(deleteDataRightsCollectionFromApi(dataRightsCollection))}
                    labelKey        = 'general.buttons.cancel'
                    waiting         = {dataRightsCollectionLoading}
                />}
                {((dataRightsCollection.state === 'PENDING') && !props.simpleMode) &&
                <WaitingXpoolButton
                    onClickCallback = {() => activateDataRights()}
                    disabled        = {(dataRightsCollection.state !== 'PENDING') || modifiedUser}
                    labelKey        = 'adminUserRights.activateButton'
                    waiting         = {dataRightsCollectionLoading}
                    floatRight
                />}
                {((dataRightsCollection.state === 'PENDING') && !props.simpleMode) &&
                <WaitingXpoolButton
                    onClickCallback = {() => dispatch(putDataRightsCollectionFromApi(dataRightsCollection))}
                    disabled        = {!modifiedUser}
                    labelKey        = 'general.buttons.save'
                    waiting         = {dataRightsCollectionLoading}
                    floatRight
                />}
                {((dataRightsCollection.state === 'PENDING') && props.simpleMode) &&
                <WaitingXpoolButton
                    onClickCallback = {() => saveAndActivate()}
                    disabled        = {!pendingDiffersFromActive && !modifiedUser}
                    labelKey        = 'adminUserRights.saveAndActivateButton'
                    waiting         = {dataRightsCollectionLoading}
                    floatRight
                />}
            </div>
        </div>
    );
}
AdminDataRights.propTypes = {
    user       : PropTypes.object.isRequired,
    simpleMode : PropTypes.bool,
};

export default AdminDataRights;

