import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {accountNumberParser} from "gui-common/xpForm/formComponentsFunctions";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {activeCurrenciesSelector} from "gui-common/orm/ormSelectors";
import {useCurrencyFromBankAccount} from "xpool-gui-common/appConfig/runConfiguration/runConfigurationFunctions";
import {checkConflictWithOtherElements} from "gui-common/runConfiguration/runConfigurationFunctions";
import {createSelectItems} from "gui-common/functions/hooks";
import {XpFormContext} from "gui-common/xpForm/core/XpForm";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import XpFormCheckboxInput from "gui-common/xpForm/core/XpFormCheckboxInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";

function MaxMinSplitForm (props) {

    const decDenLangState  = useSelector(selectDecDenLangState);
    const ormCurrencies    = useSelector(activeCurrenciesSelector);
    const formContext      = useContext(XpFormContext);
    const formDataUseSplit = useXpFormField('useSplit');

    useCurrencyFromBankAccount(props);

    function useForOverlap(val, checkConfig) {
        if (!checkConfig.isActive) return false;
        if (checkConfig.useFor === 'ALL') return true;
        if (val === 'ALL') return true;
        if (val === checkConfig.useFor) return true
        return false;
    }

    const useForTypes = createSelectItems(['ALL', 'SWEEP', 'TOP_UP']);

    function str2num (val) {
        return stringNumberToFloat(val, decDenLangState);
    }

    return (
        <div>
            <div>
                <XpFormSelectorInput
                    noTemplate inLineLayout
                    inLineDivider={60}
                    disabled = {props.disabled}
                    field            = "useFor"
                    selectList       = {useForTypes}
                    itemTextField    = "name"
                    errors={{
                        useForOverlap: val => checkConflictWithOtherElements(val, useForOverlap, props),
                    }}
                />
                {(props.ormModel !== 'BankAccount') &&
                <XpFormSelectorInput
                    disabled = {props.disabled}
                    field         = "currencyId"
                    selectList    = {ormCurrencies}
                    itemTextField = "currency"
                    isRequired  inLineLayout
                />}

            </div>
            <div className='tableInForm'>
                <table style={{width: '100%', margin: '0'}}>
                    <tbody>
                        <tr className="HeaderRow" key="header2">
                            <th style={{width: '30%' }} className="amountColumn"   ><label><XpTranslated trKey={formContext.formTemplate + '.minimumTradeSize.label' }/></label></th>
                            <th style={{width: '40%' }} className="amountColumn"   ><label><XpTranslated trKey={formContext.formTemplate + '.maximumTradeSize.label' }/></label></th>
                            <th style={{width: '10%' }} className="checkboxColumn" ><label><XpTranslated trKey={formContext.formTemplate + '.useSplit.label'         }/></label></th>
                            <th style={{width: '20%' }} className="amountColumn"   ><label><XpTranslated trKey={formContext.formTemplate + '.splitPercent.label'     }/></label></th>
                        </tr>

                        <tr>
                            <td>
                                <XpFormAmountInput
                                    noTemplate alignRight inTable noLabel
                                    field    = "minimumTradeSize"
                                    disabled = {props.disabled}
                                    dependentFields={{
                                        maximumTradeSize: 'maximumTradeSize',
                                        useSplit: 'useSplit',
                                    }}
                                    errors={{
                                        lowerThanZero     : (val, fields) => /\d/.test(val) && str2num(val) <= 0,
                                        higherThanMaxSize : (val, fields) => fields.maximumTradeSize && (/\d/.test(val) && str2num(val) > str2num(fields.maximumTradeSize)),
                                        noMinMaxWhenNoSplit    : (val, fields) => !fields.useSplit && (!val && !fields.maximumTradeSize),
                                    }}
                                />
                            </td>
                            <td>
                                <XpFormAmountInput
                                    noTemplate alignRight inTable noLabel
                                    field    = "maximumTradeSize"
                                    disabled = {props.disabled}
                                    dependentFields={{
                                        minimumTradeSize: 'minimumTradeSize',
                                        useSplit: 'useSplit',
                                        splitPercent: 'splitPercent'
                                    }}
                                    errors={{
                                        lowerThanZero             : (val, fields) => /\d/.test(val) && str2num(val) <= 0,
                                        lowerThanMinSize          : (val, fields) => {
                                            return fields.minimumTradeSize && (/\d/.test(val) && str2num(val) < str2num(fields.minimumTradeSize))
                                        },
                                        noMaxSizeOrPercentWithSplitChecked : (val, fields) => {
                                            if (!fields.useSplit) return false;
                                            return !fields.splitPercent && !val;
                                        },
                                        noMinMaxWhenNoSplit    : (val, fields) => !fields.useSplit && (!val && !fields.minimumTradeSize),
                                    }}
                                />
                            </td>
                            <td style={{background: ((formContext.formUseState === XP_FORM_EDIT) && !props.isDeleted) ? 'white' : 'none'}}>
                                <XpFormCheckboxInput
                                    noLabel noMargin inTable
                                    field="useSplit"
                                    disabled = {props.disabled}
                                />
                            </td>

                            <td>
                                {formDataUseSplit &&
                                <XpFormTextInput
                                    noTemplate alignRight noLabel inTable
                                    field         = "splitPercent"
                                    parser        = {accountNumberParser}
                                    disabled      = {props.disabled || !formDataUseSplit}
                                    // isRequired={formDataUseSplit}
                                    dependentFields={{
                                        maximumTradeSize: 'maximumTradeSize',
                                        useSplit: 'useSplit',
                                    }}
                                    errors        = {{
                                        between0And100: (val, fields) => /\d/.test(val) && ((str2num(val) <= 0) || (str2num(val) >= 100)),
                                        noMaxSizeOrPercentWithSplitChecked : (val, fields) => {
                                            if (!fields.useSplit) return false;
                                            return !fields.maximumTradeSize && !val;
                                        },
                                    }}
                                />}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
MaxMinSplitForm.propTypes = {
};
export default MaxMinSplitForm

