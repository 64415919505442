import React, {useMemo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getUserPreferencesPropertySelector} from "gui-common/userSettings/userSettingsSelectors";
import {loadDataFromApi} from "gui-common/api/loginProcess";
import {userPreferencesSetProp} from "gui-common/userSettings/userPreferencesReducer";
import {checkAndUpdateUserState} from "gui-common/userSettings/userState/userStateThunks";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

const viewDays = [...Array(31).keys()].map((item, index) => {return {id: index, name: String(index)}});
function UserSettingsOrderHistory (props) {

    const translate = useSelector(selectTranslateFunction);
    const currentInBalanceDays = useSelectorInstance(getUserPreferencesPropertySelector, 'showInBalanceOrdersDays');
    const router          = useSelector(state => state.router);
    const dispatch  = useDispatch();


    function onInBalanceDaysChanged(fieldModel, newValue, currentValue, setValue) {
        return (dispatch, getState) => {
            // Set value so that the change is visible when the user gets the confirmation dialogue.
            setValue(newValue);
            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey : 'appUserSettings.orders.showInBalanceOrdersDays.changeInBalanceOrderDaysModal'}))
                .then((result) => {
                    if (result.status === MODAL_TYPE_CONFIRM) {
                        dispatch(userPreferencesSetProp('showInBalanceOrdersDays', newValue));
                        dispatch(checkAndUpdateUserState());
                        setTimeout(() => {
                            dispatch(loadDataFromApi(router.location.pathname));
                        }, 1000);
                    }
                    if (result.status === MODAL_TYPE_CANCEL) {
                        setValue(currentValue);
                    }
                });
        }
    }

    useEffect(
        () => {
            dispatch(xpFormChangeField('userPreferencesForm.showInBalanceOrdersDays', currentInBalanceDays));
        },
        [currentInBalanceDays],
    );

    const selectList = useMemo(
        () => {
            let returnList = viewDays;
            returnList[0].name = translate('appUserSettings.orders.showInBalanceOrdersDays.showNoInBalanceOrders');
            return returnList;
        },
        [translate]
    );

    return (
        <div xp-test-id='xpUserSettingsOrder'>
            <h3 className="userPrefsCardHeader">
                <XpTranslated trKey={'appUserSettings.orders.header'}/>
            </h3>
            <XpForm
                formModel="userPreferencesForm"
                initialUseState={XP_FORM_EDIT}
            >
                <XpFormSelectorInput
                    noTemplate inLineLayout alignRight
                    inLineDivider   = {20}
                    field           = 'showInBalanceOrdersDays'
                    formTemplate    = 'appUserSettings.orders'
                    formState       = 'edit'
                    selectList      = {selectList}
                    itemTextField   = "name"
                    onChangeThunk   = {onInBalanceDaysChanged}
                />
            </XpForm>
        </div>
    )
}
UserSettingsOrderHistory.propTypes = {
};
export default UserSettingsOrderHistory



