import React, {useEffect, useMemo, useState} from 'react';
import ReactModal from "react-modal";
import PropTypes from 'prop-types';
import FocusLock from "react-focus-lock";
import {useDispatch} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";

import {MODAL_TYPE_CONFIRM, MODAL_TYPE_CANCEL} from "gui-common/modals/modalResultTypes";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import XpTooltip from "gui-common/components/XpTooltip";
import {DisplayOperatingUser} from "gui-common/components/DisplayOperatingUser";
import XpResizable from "gui-common/components/XpResizable";
import {postSystemTextToApi} from "gui-common/appLocale/xpTranslated/systemTextApi";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import moment from "moment";
import {
    useXpTranslatedActiveLanguage,
    useXpTranslatedLanguages,
    useXpTranslateFunction, useXpTranslationObject
} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


function ModalSetTranslation (props)  {

    const translate         = useXpTranslateFunction();
    const languages         = useXpTranslatedLanguages();
    const activeLang        = useXpTranslatedActiveLanguage();
    const thisTrObject      = useXpTranslationObject(props.trKey);

    const [langText, setLangText] = useState({});
    const [comment, setComment]   = useState("");
    const dispatch                = useDispatch();

    useEffect(
        () => {
            if (!thisTrObject) {
                return;
            }
            let setMap = {};
            for (const key in thisTrObject) {
                if (!thisTrObject[key] || !thisTrObject[key][0]) {
                    continue;
                }
                setMap[key] = thisTrObject[key][0].text;
            }
            setLangText(setMap);
        },
        [thisTrObject],
    );

    const noChangedTexts = useMemo(
        () => {
            for (const key in thisTrObject) {
                if (!thisTrObject || !thisTrObject[key] || !thisTrObject[key][0]) {
                    continue;
                }
                if (langText[key] !== thisTrObject[key][0].text) {
                    return false;
                }
            }
            return true;
        },
        [langText, thisTrObject]
    );


    function LanguageLine (lang) {

        const colWidth = ['160px', '120px', '60%', '40%', '80px'];

        function setEditedText(text) {
            setLangText({...langText, [lang.code]: text});
        }

        function getTimeField(item) {
            if (item.isSystemDefault) return translate('appLangTextGenerationsList.systemDefault');
            return item.createdDateTime ? moment(item.createdDateTime).format('YYYY-MM-DD, HH:mm:ss') : ""
        }

        function renderGenerationLine(item, index) {
            return (
                <tr key = {index} >
                    <td className='generationsTableCell' style={{width: colWidth[0]}}>{getTimeField(item)}</td>
                    <td className='generationsTableCell' style={{width: colWidth[1]}}><DisplayOperatingUser userIdString={item.userId}/></td>
                    <td className='generationsTableCell' style={{width: colWidth[2]}}><XpTooltip title={item.text   }><span className="textOverflow">{item.text   }</span></XpTooltip></td>
                    <td className='generationsTableCell' style={{width: colWidth[3]}}><XpTooltip title={item.comment}><span className="textOverflow">{item.comment}</span></XpTooltip></td>
                    <td className='generationsTableCell' style={{width: colWidth[4]}}>
                        {(index !== 0 ) && <WaitingXpoolButton
                            className       = "xPoolButtonInTextGenerationTable"
                            label           = {translate('appLangTextGenerationsList.useButton')}
                            onClickCallback = {() => setEditedText(item.text)}
                            //                            toolTipKey      = 'savedUserStates.settingsList.useTooltip'
                            floatRight
                            //                            modalKey        = 'savedUserStates.useSettingsConfirmation'
                            //                            modalParams     = {{name: item.name}}
                        />}
                    </td>
                </tr>
            )
        }

        function renderTextGenerations() {
            const thisLangTranslations = thisTrObject[lang.code];
            return (
                <div>
                    <XpAccordion
                        disabled={!thisLangTranslations || (thisLangTranslations.length <= 1)}
                        header={(thisLangTranslations && (thisLangTranslations.length > 1)) ? translate('appLangTextGenerationsList.generationTableHeader') : translate('appLangTextGenerationsList.noGenerationsAvailable')}
                        headerClassName="textGenerationsHeader"
                        instanceId={"textGenerationsTable" + lang.code}
                        classNamePanel  ="textGenerationsPanel"
                        suppressEditTranslation={true}
                    >
                        <table style={{width: '100%', tableLayout: 'fixed', marginBottom: '0'}}>
                            <tbody>
                            <tr key="header">
                                <th className='generationsTableCell' style={{width: colWidth[0]}}  >{translate('appLangTextGenerationsList.time')   }</th>
                                <th className='generationsTableCell' style={{width: colWidth[1]}}  >{translate('appLangTextGenerationsList.user')   }</th>
                                <th className='generationsTableCell' style={{width: colWidth[2]}}  >{translate('appLangTextGenerationsList.text')   }</th>
                                <th className='generationsTableCell' style={{width: colWidth[3]}}  >{translate('appLangTextGenerationsList.comment')}</th>
                                <th className='generationsTableCell' style={{width: colWidth[4]}}  > </th>
                            </tr>
                            </tbody>
                        </table>
                            {(thisLangTranslations && (thisLangTranslations.length > 1)) &&
                            <XpResizable
                                defaultHeight={(thisLangTranslations.length < 5) ? (thisLangTranslations.length * 24 + 10) : (5*25-10)}
                            >
                                <div className='generationsTableBody'>
                                    <table style={{width: '100%', tableLayout: 'fixed', marginBottom: '0'}}>
                                        <tbody>
                                            {thisLangTranslations.map((item, index) => {
                                                return renderGenerationLine(item, index);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </XpResizable>}
                    </XpAccordion>
                </div>
            )
        }

        if (!thisTrObject) {
            return null;
        }

        const thisTranslation = thisTrObject[lang.code];
        return (
            <div key={lang.code} className='languageLinePanel'>
                <div>
                    <div className="inlineLangCurrent">
                        <h4>{lang.name}</h4>
                        {thisTranslation ?
                            <textarea style={{backgroundColor: 'whitesmoke'}} disabled value={thisTranslation[0].text}/>
                            :
                            translate('appLangEditModal.noDataFound')
                        }
                    </div>
                    <div className="inlineLangNew">
                        {langText ?
                            <div>
                                <label className="newTextLabel">{translate('appLangEditModal.newText')}</label>
                                <textarea value={langText[lang.code]} onChange={event => setEditedText(event.currentTarget.value)} autoFocus={lang.code === activeLang ? true : undefined}/>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                {renderTextGenerations()}
            </div>
        )
    }

    function confirmAction() {
        languages.forEach(lang => {
            const thisLangTranslations = thisTrObject[lang.code];
            if (thisLangTranslations && (langText[lang.code] === thisLangTranslations[0].text)) {
                return;
            } // No need to add generation when the language is not changed.
            if (!langText[lang.code]) {
                return;
            }
            const textItem = {
                textKey         : props.trKey,
                languageCode    : lang.code,
                text            : langText[lang.code],
                comment         : comment,
                isNewKey        : thisTrObject.isNewKey,
            };
            dispatch(postSystemTextToApi(textItem)); // Post the new generation to backend
        });
        props.popModalWindow({ status: MODAL_TYPE_CONFIRM });
    }

    function cancelAction() {
        props.popModalWindow({ status: MODAL_TYPE_CANCEL });
    }

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalLangEdit"}};

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Edit text dialog"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalParams}
                >
                    <div xp-test-id="xpModalEditSystemText">
                        <h3 className ="xpoolModalHeader">{translate('appLangEditModal.header')}</h3>
                        <hr/>
                        <p className="valueTextDark">{translate('appLangEditModal.message')}</p>
                        <p className="valueTextDark">
                            {translate('appLangEditModal.textCode') + props.trKey + '\n'}
                            {props.trParams ? Object.keys(props.trParams).map(key => key + ': ' + props.trParams[key] + '\n') : null}
                        </p>
                        <hr/>
                        {languages.map((lang, index) => LanguageLine(lang, index))}
                        <hr/>
                        <div>
                            <label className="newTextLabel">{translate('appLangEditModal.comment')}</label>
                            <textarea value={comment} onChange={event => {setComment(event.currentTarget.value)}}/>
                        </div>
                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonConfirm"}
                                label           = {translate('general.buttons.confirm')}
                                onClickCallback = {() => confirmAction()}
                                disabled        = {noChangedTexts}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                label           = {translate('general.buttons.cancel')}
                                onClickCallback = {() => cancelAction()}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}


ModalSetTranslation.propTypes = {
//    headerText        : PropTypes.string.isRequired,
//    messageText       : PropTypes.string.isRequired,
    popModalWindow    : PropTypes.func.isRequired,
};

export default ModalSetTranslation
