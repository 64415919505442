import {createSelector} from "reselect";
import isEqual from "lodash.isequal";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormGetFieldValueFromData} from "gui-common/xpForm/core/xpFormFunctions";
import cloneDeep from "lodash/cloneDeep";


function getFieldStateFromModel(state, model, addIfMissing) {
    if (!model) return undefined;
    const modelArray = model.split('.');
    if (modelArray.length < 2) return undefined; // Must be at least a form name and a field, eg "myForm.name".
    let statePointer = state;
    let field = modelArray[0];
    for (let i = 1; i < (modelArray.length); i++) {
        statePointer = statePointer[field];
        if (statePointer === undefined) return undefined;
        if (statePointer === null     ) return undefined;
        field = modelArray[i];
    }
    if (!statePointer['$' + field] && addIfMissing) {
        if (statePointer[field]) return {fieldValue: statePointer[field], addField: true}
        return undefined;
    }
    return statePointer['$' + field];
}

export function getFieldValueFromContext(fieldModel, formContext, objectProp) {
    if (!formContext?.formModel) return undefined;
    if (!fieldModel) return undefined;

    const netFieldModel = fieldModel.substring(formContext.formModel.length+1);
    return xpFormGetFieldValueFromData(formContext[objectProp], netFieldModel);

/*
    const fieldModelArray = fieldModel.split('.');
    const formModelArray  = formContext.rootFormModel.split('.');
    let fieldPathArray = [];
    for (let i = 0; i < fieldModelArray.length; i++) {
        if (i < formModelArray.length) {
            if (fieldModelArray[i] !== formModelArray[i]) {
                console.warn("Diff between formModel and fieldModel in XpFormFieldController", fieldModel, formContext.formModel);
                return undefined;
            }
            continue;
        }
        // Now we have cleared away the common path for form and field. Build the field path array.
        fieldPathArray.push(fieldModelArray[i]);
    }
    let currentDataPointer = formContext[objectProp];
    for (let field of fieldPathArray) {
        if (currentDataPointer === undefined) return undefined;
        currentDataPointer = currentDataPointer[field];
    }
    return currentDataPointer;
*/
}

const xpFormState  = state => state.xpFormState;
const model        = (state, props) => props.model;
const addIfMissing        = (state, props) => props.addIfMissing;
const getFieldStateSelector = () => createSelector(
    [xpFormState, model, addIfMissing],
    (xpFormState, model, addIfMissing) => {
        return getFieldStateFromModel(xpFormState, model, addIfMissing);
    }
);
export const getXpFormFieldStateSelector = () => createSelector(
    [getFieldStateSelector()],
    (modelState) => {
        return modelState;
    }
);
export const xpFormFieldStateSelector = getXpFormFieldStateSelector();


export const getXpFormFieldValueSelector = () => createSelector(
    [
        (state, props) => xpFormGetFieldValueFromData(state.xpFormState, props.model)
    ],
    (propValue) => {
        return propValue;
    }
);



function addDependentFields(resultObject, fieldsToAdd, currentData, xpFormState, getFromCurrent, formModel) {
    if (!fieldsToAdd) return;
    for (const field in fieldsToAdd) {
        if (resultObject[field] !== undefined) {
            console.error("Field " + field + " already defined in dependentFieldModels.", resultObject, fieldsToAdd);
            continue;
        }
        resultObject[field] = getFromCurrent ?
            xpFormGetFieldValueFromData(currentData, fieldsToAdd[field])
            :
            xpFormGetFieldValueFromData(xpFormState, (formModel ? (formModel + '.') : "") + fieldsToAdd[field])
        // getFieldStateFromModel(xpFormState, (formModel ? (formModel + '.') : "") + fieldsToAdd[field], false, true)
    }
}

export const getXpFormDependentFieldsSelector = () => createSelector(
    [
        xpFormState,
        (state, props) => props.currentState,
        (state, props) => props.formModel,
        (state, props) => props.rootFormModel,
        (state, props) => props.formUseState,
        (state, props) => props.currentData,
        (state, props) => props.rootCurrentData,
        (state, props) => props.dependentFields,
        (state, props) => props.dependentFieldModels,
    ],
    (xpFormState, currentState, formModel, rootFormModel, formUseState, currentData, rootCurrentData, dependentFields, dependentFieldModels) => {

        if (!dependentFieldModels && !dependentFields) return currentState;

        let fieldValues = {};
        const getFromCurrent = (formUseState === XP_FORM_VIEW);
        if (dependentFieldModels) {
            const rootBasedDependentFieldModels = {};
            const fullDependentFieldModels = {};
            for (const key in dependentFieldModels) {
                if (rootFormModel && dependentFieldModels[key].includes(rootFormModel)) {
                    rootBasedDependentFieldModels[key] = dependentFieldModels[key].substring(rootFormModel ? rootFormModel.length+1 : 0);
                }
                else {
                    fullDependentFieldModels[key] = dependentFieldModels[key];
                }
            }
            addDependentFields(fieldValues, rootBasedDependentFieldModels, rootCurrentData     , xpFormState, getFromCurrent, rootFormModel);
            addDependentFields(fieldValues, fullDependentFieldModels     , undefined, xpFormState, false, undefined);
        }
        addDependentFields(fieldValues, dependentFields     , currentData    , xpFormState, getFromCurrent, formModel);

        if (isEqual(fieldValues, currentState)) {
            return currentState;
        }
        else {
            return cloneDeep(fieldValues);
        }
    }
);

const errorFunctions   = (state, props) => props.errorFunctions;
const fieldState       = (state, props) => props.fieldState;
const dependentFields  = (state, props) => props.dependentFields;
const dependentData    = (state, props) => props.dependentData;
export const getXpFormFieldErrorsSelector = () => createSelector(
    [errorFunctions, fieldState, dependentFields, dependentData],
    (errorFunctions, fieldState, dependentFields, dependentData) => {

        if (!errorFunctions || !fieldState) return;

        let errors = undefined;
        for (const name in errorFunctions) {
            if (typeof errorFunctions[name] !== "function") continue;
            const error = errorFunctions[name](fieldState?.fieldValue, {...dependentFields, ...dependentData});
            if (!error) continue;
            if (errors === undefined) errors = {};
            errors[name] = error;
        }

        if (isEqual(errors, fieldState.errors)) return fieldState.errors;

        return errors;
    }
);


function getFormStatePointer(state, model) {
    if (!model) return undefined;
    const modelArray = model.split('.');
    if (modelArray.length < 1) return undefined; // Must be at least a form name, eg "myForm".
    let statePointer = state;
    for (let field of modelArray) {
        statePointer = statePointer[field];
        if (statePointer === undefined) return undefined;
    }
    return statePointer;
}

export const getXpFormRootSelector = () => createSelector(
    [xpFormState, model],
    (xpFormState, model) => {
        return getFormStatePointer(xpFormState, model);
    }
);
export const getXpFormRevisionSelector = () => createSelector(
    [xpFormState, model],
    (xpFormState, model) => {
        const formRoot = getFormStatePointer(xpFormState, model);
        return formRoot?.$_xpFormData?.revisionNumber;
    }
);
export const getXpFormStateSelector = () => createSelector(
    [xpFormState, model],
    (xpFormState, model) => {
        const formRoot = getFormStatePointer(xpFormState, model);
        return formRoot ? formRoot['$_xpFormData'] : undefined;
    }
);
export const getXpFormUseStateSelector = () => createSelector(
    [xpFormState, model],
    (xpFormState, model) => {
        const formRoot = getFormStatePointer(xpFormState, model);
        if (!formRoot || !formRoot['$_xpFormData']) return XP_FORM_VIEW;
        return formRoot['$_xpFormData'].formUseState ? formRoot['$_xpFormData'].formUseState : XP_FORM_VIEW;
    }
);


export const getXpFormBeforeChangeValueSelector = () => createSelector(
    [
        (state, props) => props.fieldModel,
        (state, props) => props.formContext,
        (state, props) => props.fieldState,
        (state, props) => props.beforeChangeFunction,
    ],
    (fieldModel, formContext, fieldState, beforeChangeFunction) => {
        const currentValue      = getFieldValueFromContext(fieldModel, formContext, 'currentData');
        const beforeChangeValue = getFieldValueFromContext(fieldModel, formContext, 'beforeChangeData');

        let before = (beforeChangeValue !== undefined) ? beforeChangeValue : currentValue;
        let after = (fieldState?.fieldValue !== undefined && (formContext.formUseState !== 'view')) ? fieldState?.fieldValue : currentValue;
        if (formContext.auditMode && formContext.beforeChangeData) {
            if (beforeChangeValue === undefined) before = "";
            if (currentValue      === undefined) after  = "";
        }

        if ((before === undefined) || (after === undefined)) return null;
        if (before === after) return null;

        const beforeValue = beforeChangeFunction ? beforeChangeFunction(before, after) : (String(before).trim() === String(after).trim()) ? null : before;
        return beforeValue;
    }
)
