/* -----------------------------------------------------------------------------------------------------------------
* Socket message dictionaries for models and message types.
* -----------------------------------------------------------------------------------------------------------------*/
import {addUserMessage, updateUserMessage} from "gui-common/userMessages/userMessagesReducer";
import {executionRightsUpdated} from "gui-common/functions/thunks";
import {ormFunctionMap, ormFunctionMapDeleted} from "gui-common/api/ormSocketMethodMaps";
import {appSpecificFilterMessageMap, appSpecificSocketPayloadPrototypeMap} from "appConfig/api/appWebSocketConfig";
import {handleSystemTextResponseThunk} from "gui-common/appLocale/xpTranslated/systemTextApi";
import {gotFxRejectConfigurationFromSocket} from "gui-common/fxRejectConfig/fxRejectConfigApi";
import {clearAndReloadBankHolidays} from "gui-common/bankHolidays/bankHolidaysFunctions";
import {fixLogContainsMessagesSelector} from "../fixLog/fxLogSelectors";

const systemTextMap = {
    'se.nordicfc.common.message.functions.Create'                    : {batchPossible: false, ormEventType: 'create', functionToDispatch: (model, payload) => handleSystemTextResponseThunk([payload])},
};

const bankHolidayFunctionMap = {
    'se.nordicfc.common.message.functions.Update'                    : {batchPossible: false, ormEventType: 'update', functionToDispatch: (model, payload) => clearAndReloadBankHolidays()},
};
const umFunctionMap = {
    'se.nordicfc.common.message.functions.Create'                    : {batchPossible: false, ormEventType: 'create', functionToDispatch: (model, payload) => addUserMessage(model, payload)},
    'se.nordicfc.common.message.functions.Update'                    : {batchPossible: false, ormEventType: 'update', functionToDispatch: (model, payload) => updateUserMessage(model, payload)},
};
const userFunctionMap = {
    ...ormFunctionMap,
    'se.nordicfc.common.message.functions.ReAuthenticateUser'        : {batchPossible: false, ormEventType: 'update', functionToDispatch: (model, payload) => executionRightsUpdated(model, payload)},
};
const fxRejectConfigFunctionMap = {
    'se.nordicfc.common.message.functions.Create'                    : {batchPossible: false, ormEventType: 'create', functionToDispatch: (model, payload) => gotFxRejectConfigurationFromSocket(payload)},
};
const commonSocketPayloadMap = {
    'se.nordicfc.common.usermessage.repository.model.UserMessageTicket'          : { batchPossible: false, model: 'UserMessage'          , functionMap: umFunctionMap            , logMessage: false },
    'se.nordicfc.common.model.User'                                              : { batchPossible: false, model: 'User'                 , functionMap: userFunctionMap          , logMessage: false },
    'se.nordicfc.common.model.SystemEvent'                                       : { batchPossible: false, model: 'SystemEvent'          , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.common.model.SystemText'                                        : { batchPossible: false, model: 'SystemText'           , functionMap: systemTextMap            , logMessage: false },
    'se.nordicfc.common.fxrejectconfiguration.model.FxRejectConfigurationGroup'  : { batchPossible: false, model: 'FxRejectConfiguration', functionMap: fxRejectConfigFunctionMap, logMessage: false },
    'se.nordicfc.xpoolapi.model.XpoolApiSharedSettings'                          : { batchPossible: true , model: 'SharedSetting'        , functionMap: ormFunctionMapDeleted    , logMessage: false },
    'se.nordicfc.common.model.FxPrice'                                           : { batchPossible: true , model: 'FxPrice'              , functionMap: ormFunctionMapDeleted    , logMessage: false },
    'se.nordicfc.common.datatypes.model.NfcFixLog'                               : { batchPossible: true , model: 'FixMessage'           , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.xpoolapi.model.XpoolApiNoRunDate'                               : { batchPossible: false, model: 'NoRunDate'            , functionMap: ormFunctionMapDeleted    , logMessage: false },
    'se.nordicfc.common.currencypairsettings.model.CurrencyPairSetting'          : { batchPossible: false, model: 'CurrencyPairConfig'   , functionMap: ormFunctionMap           , logMessage: false },
    'se.nordicfc.common.security.authorisation.model.shallow.ShallowDataRightsCollection' : { batchPossible: false, model: 'DataRightsCollection' , functionMap: ormFunctionMapDeleted    , logMessage: false },
    'se.nordicfc.common.authorisation.model.shallow.ShallowSystemRights'         : { batchPossible: false, model: 'UserSystemRight'      , functionMap: ormFunctionMapDeleted    , logMessage: false },
    'se.nordicfc.xpool.common.model.function.BankHolidayUpdated'                 : { batchPossible: false, model: undefined              , functionMap: bankHolidayFunctionMap   , logMessage: false },
};

export const socketPayloadPrototypeMap = {...commonSocketPayloadMap, ...appSpecificSocketPayloadPrototypeMap};
