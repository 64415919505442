import React from 'react';
import OrdersList from "xpool-gui-common/features/order/OrdersList";
import {getAllOrdersListDataSelector} from "xpool-gui-common/features/order/orderSelectors";


export const allClientsOrdersHiddenState = {

    id                      : true,

    clientId                : true,
    clientName              : false,

    // sourceAccountName       : false,
    // sourceAmountCcy         : false,
    // destinationAccountName  : false,
    // destinationAmountCcy    : false,

    orderType               : false,
    adjustedSideT           : false,
    currencyPairStr         : false,
    externalReference       : true,
    settlementDate          : true,

    nominalAmountCcy        : false,
    counterAmountCcy        : false,
    displayRate             : false,

    displayStateT           : false,

    createdDateTime         : true,
    modifiedDateTime        : true,
    executionDateTime        : true,
};

function AllClientsOrdersList (props) {

        return (
            <OrdersList
                instanceId={props.instanceId}
                fillAvailableSpace={true}
                hiddenState={allClientsOrdersHiddenState}
            />
        );
}

export default AllClientsOrdersList
