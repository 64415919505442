import PropTypes from "prop-types";
import React, {useMemo} from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'gui-common/css/xpCalendarOverrides.css'
import XpContextMenu from "gui-common/contextMenu/XpContextMenu";

function XpCalendar(props) {


    const localizer = useMemo(
        () => {
            // Setup the localizer by providing the moment (or globalize) Object
            // to the correct localizer.
            return momentLocalizer(moment); // or globalizeLocalizer
        },
        []
    );

    return (
        <div className={props.className} style={props.containerStyles}>
            <Calendar
                titleAccessor = {(event) =>
                    <XpContextMenu
                        menuItems = {props.eventContextMenuItems}
                        data      = {event}
                    >
                        <div style = {{width:'100%'}}>
                            {event.title}
                        </div>
                    </XpContextMenu>
                }
                localizer={localizer}
                views={['month','day']}
                events={props.events}
                // events={[{title: 'fish', start: new Date(), end: new Date(), allDay: true}]}
                // onRangeChange={dates => console.log("New range: ", dates)}
                startAccessor="start"
                endAccessor="end"
                showMultiDayTimes
                eventPropGetter={(event, start, end, isSelected
                ) => ({ style: {backgroundColor: "rgb(157,208,132)", padding: "1px 3px", color: "#262626", fontSize: '14px', borderRadius: '2px', width: '95%', margin: 'auto'} })}
            />
        </div>
    )
}
XpCalendar.propTypes = {
    className       : PropTypes.string,
    containerStyles : PropTypes.object,
    events          : PropTypes.array.isRequired
};
export default XpCalendar
