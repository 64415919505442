export const xpoolMaterialUiOverrides = {
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    background: 'var(--xpool-secondary-background-color)',
                    borderBottom: 'var(--xpool-grid-row-border)',
                    borderRadius: 'var(--xpool-currency-dashboard-border-radius) var(--xpool-currency-dashboard-border-radius) 0 0',
                    color: 'var(--xpool-body-text-color)',
                },
                indicator: {
                    backgroundColor: 'var(--xpool-secondary-color-mid)',
                    height: '3px'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: 'var(--xpool-body-text-color)',
                    textTransform: 'none',
                    '&.Mui-selected': {
                        color: 'var(--xpool-header-text-color)',
                    },
                    fontSize: 'var(--xpool-label-font-size)'
                },
            }
        },
    }
}
