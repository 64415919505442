import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
//import {popModalWindow} from "redux-promising-modals";
import connect from "react-redux/es/connect/connect";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {modalLogMessage} from "gui-common/modals/modalFunctions";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

class ModalConfirmation extends Component {

    static propTypes = {
        modalKey:       PropTypes.string,
        modalParams:    PropTypes.object,
        headerText:     PropTypes.string,
        messageText:    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        cancelText:     PropTypes.string,
        confirmText:    PropTypes.string,
        resultTypes:    PropTypes.object.isRequired,
        popModalWindow: PropTypes.func.isRequired
    };

    confirmAction(message) {
        const { resultTypes: { MODAL_TYPE_CONFIRM }, popModalWindow } = this.props;
        modalLogMessage('User confirmed message:', this.props);
        popModalWindow({ status: MODAL_TYPE_CONFIRM});
    }

    cancelAction(message) {
        const { resultTypes: { MODAL_TYPE_CANCEL }, popModalWindow } = this.props;
        modalLogMessage('User cancelled after message:\n', this.props);
        popModalWindow({ status: MODAL_TYPE_CANCEL });
    }

    render() {

        const showCancel  = this.props.cancelText  ? this.props.cancelText  : <XpTranslated trKey={this.props.modalKey ? (this.props.modalKey+'.cancel' ) : 'general.buttons.cancel' } fallbackKey='general.buttons.cancel'  trParams={this.props.modalParams}/>;
        const showConfirm = this.props.confirmText ? this.props.confirmText : <XpTranslated trKey={this.props.modalKey ? (this.props.modalKey+'.confirm') : 'general.buttons.confirm'} fallbackKey='general.buttons.confirm' trParams={this.props.modalParams}/>;
        const showHeader  = this.props.headerText  ? this.props.headerText  : <XpTranslated trKey={this.props.modalKey ? (this.props.modalKey+'.header' ) : ''} trParams={this.props.modalParams}/>;
        const showMessage = this.props.messageText ? this.props.messageText : <XpTranslated trKey={this.props.modalKey ? (this.props.modalKey+'.message') : ''} trParams={this.props.modalParams}/>;

        return (
            <div>
                <FocusLock>
                <ReactModal
                    contentLabel="Confirmation modal"
                    onRequestClose={(props) => this.cancelAction(showMessage)}
                    shouldCloseOnEsc={true}
                    {...modalStandardParams}
                >
                    <div xp-test-id="xpModalConfirmation">
                        <h3 className ="xpoolModalHeader">{showHeader}</h3>
                        <hr/>
                        <div className="valueTextDark">{showMessage}</div>
                        <hr/>

                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonConfirm"}
                                label           = {showConfirm}
                                onClickCallback = {() => this.confirmAction(showMessage)}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                label           = {showCancel}
                                onClickCallback = {() => this.cancelAction(showMessage)}
                            />
                        </div>
                    </div>
                </ReactModal>
                </FocusLock>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        translate:          selectTranslateFunction(state), // needed in modalLogMessage call.
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmation);

