

/* -----------------------------------------------------------------------------------------------------------------
* Launch action defined in integrationStatusItem.
* -----------------------------------------------------------------------------------------------------------------*/
export function launchIntegrationAction(action) {
    return (dispatch, getState) => {
        console.log("Integration status action launched" + action.description);
    }
}
