import React, {useMemo} from 'react'
import XpTooltip from "gui-common/components/XpTooltip";
import spinnerGif from "gui-common/resources/spinner.gif";
import {useSelector} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {activeClientBankAccountsSelector} from "xpool-gui-common/features/client/clientSelectors";
import {calculateAccountRunStateInfo} from "xpool-gui-common/features/bankAccount/bankAccountFunctions";
import moment from "moment";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

const isRunningMap = {
    getBalancesTime: {
        WAIT_FOR_BALANCE         : true,
        WAIT_FOR_RETRY_BALANCE   : true,
    },
    executeTime: {
        WAIT_FOR_EXECUTION       : true,
        WAIT_FOR_RETRY_EXECUTION : true,
    },
    stopTryingTime: {}
}
const stateColorMap = {
    getBalancesTime: {
        WAIT_FOR_BALANCE         : 'var(--xpool-client-run-status-fetching-balances-background-color)',
        WAIT_FOR_RETRY_BALANCE   : 'var(--xpool-client-run-status-warning-background-color)',
        BEFORE_EXECUTION         : 'var(--xpool-client-run-status-run-ok-background-color)',
        WAIT_FOR_EXECUTION       : 'var(--xpool-client-run-status-run-ok-background-color)',
        WAIT_FOR_RETRY_EXECUTION : 'var(--xpool-client-run-status-run-ok-background-color)',
        FAILED_FAILED_BALANCE_REQUEST: 'var(--xpool-client-run-status-failed-background-color)',
        FAILED_BALANCE_STOPPED_TRYING: 'var(--xpool-client-run-status-failed-background-color)',
    },
    executeTime: {
        WAIT_FOR_EXECUTION       : 'var(--xpool-client-run-status-executing-background-color)',
        WAIT_FOR_RETRY_EXECUTION : 'var(--xpool-client-run-status-warning-background-color)',
        FAILED_EXECUTION_STOPPED_TRYING: 'var(--xpool-client-run-status-failed-background-color)',
        FAILED_EXECUTION_FAILED: 'var(--xpool-client-run-status-failed-background-color)',
        FAILED_EXECUTION_TIMEOUT: 'var(--xpool-client-run-status-failed-background-color)',
    },
    stopTryingTime: {}
}

const baseStateColorMap = {
    CANCELLED          : 'var(--xpool-client-run-status-warning-background-color)',
    COMPLETED          : 'var(--xpool-client-run-status-run-ok-background-color)',
    FAILED             : 'var(--xpool-client-run-status-failed-background-color)',
}

function AccountSubState (props) {
    const subState = useMemo(
        () => {
            if (!props.subStateType || !props.account || !props.account.barRunState) return undefined;

            const stateReason = (props.account.barRunStateParams && props.account.barRunStateParams.reason) ? "_" + props.account.barRunStateParams.reason : "";
            const extendedState = props.account.barRunState + stateReason;

            let stateColor = baseStateColorMap[extendedState];
            if (stateColorMap[props.subStateType][extendedState]) stateColor = stateColorMap[props.subStateType][extendedState];

            const workflowParams = props.account?.barRunStateObject?.workflow?.workflowParams;
            return {
                isActive  : isRunningMap[props.subStateType][extendedState] === true,
                stateColor: stateColor,
                runTime   : (workflowParams && workflowParams[props.subStateType]) ? moment(workflowParams[props.subStateType]).format('HH:mm') : "",
            };
        },
        [props.account]
    );

    if (!subState) return null;
    return (
        <td>
            <div className="modifiedNumberValue" style={{background: subState.stateColor, position: 'relative'}} >
                {subState.runTime}
                {subState.isActive ? <img className="smallTableSpinnerPosAbsolute" src={spinnerGif} alt="waiting"/> : null}
            </div>
        </td>
    )
}


function accountRow (account, index) {
    const accountRunStateInfo = useMemo(() => calculateAccountRunStateInfo(account), [account]);
    if (!accountRunStateInfo) return null;
    return (
        <XpTooltip trKey={accountRunStateInfo.messageKey} trParams={{account: account.name, messageTime: accountRunStateInfo.messageTime}} key={"tooltip-"+index}>
            <tr key = {index}>
                <td><div className="modifiedNumberValue textOverflow" style={{textAlign: 'left', paddingLeft: '5px', maxWidth: '160px'}} >{account.name}</div></td>
                <AccountSubState account={account} subStateType='getBalancesTime'/>
                <AccountSubState account={account} subStateType='executeTime'/>
                <AccountSubState account={account} subStateType='stopTryingTime'/>
            </tr>
        </XpTooltip>
    )
}

function AccountsRunStatus () {
    const translate = useSelector(selectTranslateFunction);
    const accounts  = useSelector(activeClientBankAccountsSelector);

    if (!accounts) return null;

    const tableWidth = "400px";

    return (
            <table style={{width: tableWidth, margin: '0 0 5px 0'}}>
                <tbody>
                <tr className="HeaderRowTop" key="header1">
                    <th colSpan="1" style={{textAlign: 'center'}}> </th>
                    <th colSpan="3" style={{textAlign: 'center'}}><XpTranslated trKey={'runPoolButton.accountRunState.header'}/></th>
                </tr>
                <tr className="HeaderRow" key="header2">
                    <th style={{width: '40%', padding:'0 2px 0 2px'}}  ><XpTranslated trKey={'runPoolButton.accountRunState.accountName'}/></th>
                    <th style={{width: '20%'}} className="timeColumn"  ><XpTranslated trKey={'runPoolButton.accountRunState.getBalancesTime'}/></th>
                    <th style={{width: '20%'}} className="timeColumn"  ><XpTranslated trKey={'runPoolButton.accountRunState.executeTime'}/></th>
                    <th style={{width: '20%'}} className="timeColumn"  ><XpTranslated trKey={'runPoolButton.accountRunState.stopTryingTime'}/></th>
                </tr>
                {accounts.map((account, index) => accountRow(account, index, translate))}
                </tbody>
            </table>
    );
}


export default AccountsRunStatus;

