import {createSelector} from "reselect";

const ormLoadingState = state => state.ormLoadingState;
const ormModel        = (state, props) => props.ormModel;

export const ormModelLoading = createSelector(
    [ormLoadingState,ormModel],
    (ormLoadingState,ormModel) => {
        if (!ormModel)        return false;
        if (!ormLoadingState) return false;

        if (!ormLoadingState[ormModel])            return false;
        if (!ormLoadingState[ormModel].loadingAll) return false;
        return ormLoadingState[ormModel].loadingAll;
    }
);
export const ormModelLoadingNew = createSelector(
    [ormLoadingState,ormModel],
    (ormLoadingState,ormModel) => {
        if (!ormModel)        return false;
        if (!ormLoadingState) return false;

        if (!ormLoadingState[ormModel])            return false;
        if (!ormLoadingState[ormModel].loadingCreate) return false;
        return ormLoadingState[ormModel].loadingCreate;
    }
);
export const getOrmModelLoadingNewOrUpdatedSelector = () => createSelector(
    [ormLoadingState,ormModel],
    (ormLoadingState,ormModel) => {
        if (!ormModel)        return false;
        if (!ormLoadingState) return false;

        if (!ormLoadingState[ormModel])              return false;
        if (ormLoadingState[ormModel].loadingCreate) return true;
        for (let key in ormLoadingState[ormModel]) {
            if (typeof ormLoadingState[ormModel][key] !== 'object') continue;
            if (ormLoadingState[ormModel][key].loadingUpdate) return true;
        }
        return false;
    }
);
export const ormModelLoadingNewOrUpdated = getOrmModelLoadingNewOrUpdatedSelector();

const itemId = (state, props) => props.itemId;
export const getOrmItemLoadingSelector = () => createSelector(
    [ormLoadingState,ormModel,itemId],
    (ormLoadingState,ormModel,itemId) => {
        if (!ormModel)        return false;
        if (!ormLoadingState) return false;
        if (!itemId)          return false;

        if (!ormLoadingState[ormModel])         return false;
        if (!ormLoadingState[ormModel][itemId]) return false;
        if (!ormLoadingState[ormModel][itemId].loadingUpdate) return false;
        return true;
    }
);

const itemIds = (state, props) => props.itemIds;
export const getAnyOfItemsLoadingSelector = () => createSelector(
    [ormLoadingState,ormModel,itemIds],
    (ormLoadingState,ormModel,itemIds) => {
        if (!ormModel)        return false;
        if (!ormLoadingState) return false;
        if (!itemId)          return false;

        if (!ormLoadingState[ormModel])         return false;
        for (const itemId of itemIds) {
            if (!itemId || !ormLoadingState[ormModel][itemId]) {
                continue;
            }
            if (ormLoadingState[ormModel][itemId].loadingUpdate) {
                return true;
            }
        }
        return false;
    }
);

export const ormItemLoading = getOrmItemLoadingSelector();

export const getOrmModelLoading = () => createSelector(
    [ormLoadingState,ormModel],
    (ormLoadingState,ormModel) => {
            if (!ormModel)        return false;
            if (!ormLoadingState) return false;

            if (!ormLoadingState[ormModel]) return false;
            return ormLoadingState[ormModel];
    }
);
export const ormItemsLoading = getOrmModelLoading();

    export const selectOrmItemsLoading = createSelector(
    [ormLoadingState],
    (ormLoadingState) => {
        if (!ormLoadingState) return [];
        let modelsLoading = [];
        for (let model in ormLoadingState) {
            if (!ormLoadingState[model]) continue;
            if (ormLoadingState[model].loadingAll) {
                modelsLoading.push(model);
                continue;
            }
            for (let key in ormLoadingState[model]) {
                if (ormLoadingState[model][key] && ormLoadingState[model][key].loadingUpdate) {
                    modelsLoading.push(model);
                    break;
                }
            }
        }
        return modelsLoading;
    }
);
