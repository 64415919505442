import React, {useCallback, useMemo, useRef} from 'react';
import {useSelector} from "react-redux";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {getLedgerAccountBalancesSelector} from "gui-common/ledger/ledgerSelectors";
import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

const accountBalancesHiddenState = {
    id:               true,
    valueDate:        false,
    // typeT:            false,
    balance:          false,
    createdDateTime:  false,
};

function AccountBalanceListCurrencyDashboard (props) {
    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {if (props.itemSelectedCallback) props.itemSelectedCallback(data)},
            [props.itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            (params) => {
                // if (!params || !params.node || !params.node.data) return [];
                // let menuItems = [];
                return [];
            }, []),
        rowClassRules   : {
            'xp-grid-disabled-row' : (params) =>  !!params.data.disabledDateTime,
        },
    }

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('accountBalanceList.noAvailableBalance'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? false : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                getRowDataSelector      : getLedgerAccountBalancesSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'LedgerAccountBalance',
            };
        },
        [translate, props.rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareGridColumns(translate, accountBalancesHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={"accountBalanceList" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}
export default AccountBalanceListCurrencyDashboard



function prepareGridColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState,'id',               50 , 30 , 'accountBalanceList.headers.id');
    xpColCreate(translate, subArray, hiddenState,'valueDate',    100, 50, 'accountBalanceList.headers.valueDate');
/*
    xpColCreate(translate, subArray, hiddenState, 'typeT'     , 150, 50, 'accountBalanceList.headers.type', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: accountBalanceTypes.map(item => 'accountBalanceList.balanceTypes.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
*/
    xpColCreate(translate, subArray, hiddenState,'balance',      100, 50, 'accountBalanceList.headers.balance', {
        cellRenderer: 'xpGridNumberRenderer',
        cellStyle: function(params) {
            let outStyle = {textAlign: "right", color: 'var(--xpool-body-text-color)', fontWeight: 'var(--xpool-standard-font-weight)'};
            if ((params.data.balance < 0)) {
                outStyle = {...outStyle, color: '#CC0000', fontWeight: '400'};
            }
            return outStyle;
        }});
    xpColCreate(translate, subArray, hiddenState,'createdDateTime',  150, 50 , 'accountBalanceList.headers.createdDateTime' , {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});

    return subArray;
}
