import React from "react";
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {
    currencyPairConfigTypesSelector,
} from "gui-common/currencyPairConfig/currencyPairConfigSelectors";
import CurrencyPairConfigType from "gui-common/currencyPairConfig/CurrencyPairConfigType";

// Todo: Support for multiple config types is not yet implemented in CurrencyPairConfigType.
function CurrencyPairConfigPage () {
    const currencyPairOrmConfigTypes = useSelector(currencyPairConfigTypesSelector);

    return (
        <div>
            <h2><XpTranslated trKey={'currencyPairConfigPage.header'}/></h2>
            <hr/>
            <div>
                {currencyPairOrmConfigTypes.map(configType => {
                    return (
                        <div key={configType}>
                            <h3 style={{marginBottom: '5px'}}><XpTranslated trKey={'currencyPairConfigPage.configTypeHeader.' + configType}/></h3>
                            <CurrencyPairConfigType
                                configType={configType}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default withRouter(CurrencyPairConfigPage)
