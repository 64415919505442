import {nonAuthorizedFetch} from "gui-common/api/apiCommon";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";

function getRequestUrl(getState, bankAccountId) {
    return selectAppEnvProperty(getState(), 'balanceSimBaseUrl') + '/balance-api-simulator/' + bankAccountId;
}
export function getTestBalanceFromApi(bankAccountId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!bankAccountId) return;

            let requestUrl = getRequestUrl(getState, bankAccountId);
            let logText = "Get test balance for " + bankAccountId;

            dispatch(nonAuthorizedFetch(requestUrl, logText,
                (resp) => {
                    return (dispatch, getState) => {
                        // dispatch(marketSimulatorConfigUpdate(clientPrice));
                    }},
                emptyFailHandler,
                emptyFailHandler,
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)});

        })
    }
}
export function overrideTestBalanceToApi(accountBalance) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!accountBalance?.bankAccountId) return;

            let requestUrl = getRequestUrl(getState, accountBalance.bankAccountId);
            let logText = "Set balance override for " + accountBalance.bankAccountId;

            dispatch(nonAuthorizedFetch(requestUrl, logText,
                (resp) => {
                    return (dispatch, getState) => {
                        // dispatch(marketSimulatorConfigUpdate(clientPrice));
                    }},
                emptyFailHandler,
                emptyFailHandler,
                'PUT',
                {
                    balanceOverride: accountBalance.balanceOverride,
                    functionOverride: accountBalance.functionOverride
                },
            )).then(result => {resolve(result)}).catch(err => {reject(err)});
        })
    }
}
function emptyFailHandler(resp) {
    return (dispatch, getState) => {
    }
}
