import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {ShowDateInForms} from "gui-common/components/ShowDatesInForm";
import {registerLedgerBalanceToApi} from "gui-common/ledger/ledgerApi";
import {pushModalWindow} from "redux-promising-modals";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getLatestLedgerAccountBalanceSelector} from "gui-common/ledger/ledgerSelectors";
import {CONFIRMATION_DIALOG, modalStandardParams} from "gui-common/modals/modalConstants";
import LabelValueDisplay from "gui-common/components/LabelValueDisplay";
import {disableBalance} from "features/bankAccountBalance/accountBalanceFunctions";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import {xpFormLoadForm, xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_NEW, XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {selectActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

export function RegisterBalanceModal({popModalWindow, displayState, account, balance}) {
    const latestBalance  = useSelectorInstance(getLatestLedgerAccountBalanceSelector, {ledgerAccountId: account.ledgerBalanceLaId})
    const activeLanguage = useSelector(selectActiveLanguage);
    const dispatch       = useDispatch();
    const maxManualBalance = useAppEnvProperty('maxManualBalance')
    const formData       = useXpFormFields({dependentFieldModels: {
            balance        : 'registerBalanceForm.balance',
            createdComment : 'registerBalanceForm.createdComment',
        }})

    useEffect(
        () => {
            console.log("Loading balance ", balance);

            dispatch(xpFormLoadForm(
                'registerBalanceForm',
                balance ? {...balance} : {balance: "", createdComment: ""},
                displayState
            ))
        },
        [],
    );

    function closeModal(){
        popModalWindow({ status: MODAL_TYPE_CANCEL });
        dispatch(xpFormRemoveForm('registerBalanceForm'));
    }

    function cancelAction() {
        if (displayState === XP_FORM_VIEW) {
            closeModal();
            return;
        }
        if (!formData.balance && !formData.createdComment) {
            closeModal();
            return;
        }
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'general.modalConfirmCancelEditNew'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) closeModal();
            });
    }
    function handleSubmit(form) {
        dispatch(registerLedgerBalanceToApi(account.ledgerBalanceLaId, form.balance, form.createdComment));
        closeModal();
    }


    function getHeaderKey() {
        return (displayState === 'new') ? 'registerBalanceForm.header.new' : 'registerBalanceForm.header.view';
    }
    function getDisableToolTipKey() {
        if (balance.disabledDateTime)           return 'registerBalanceForm.alreadyDisabledTooltip';
        if (balance.id === latestBalance.id)    return 'registerBalanceForm.cantDisableLatestBalanceTooltip';
        return 'registerBalanceForm.disableTooltip';
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Register balance dialog"
                    onRequestClose={cancelAction}
                    shouldCloseOnEsc={true}
                    {...modalStandardParams}
                >

                    <div xp-test-id="registerBalanceDialog">
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={getHeaderKey()}/></h3>
                        <LabelValueDisplay
                            label={<XpTranslated trKey={'registerBalanceForm.accountName.label'}/>}
                            value={account.name}
                            labelTab={30}
                        />
                        <LabelValueDisplay
                            label={<XpTranslated trKey={'registerBalanceForm.accountCurrency.label'}/>}
                            value={account.currency.currency}
                            labelTab={30}
                        />
                        <hr/>

                        <XpForm
                            formModel="registerBalanceForm"
                            onSubmit={handleSubmit}
                            currentData={balance}
                            submitOnEnter
                        >
                            <XpFormAmountInput
                                isRequired autoFocus
                                field         = "balance"
                                maxAmount = {maxManualBalance}
                                minAmount = {-maxManualBalance}
                            />
                            <XpFormTextAreaInput
                                field="createdComment"
                                maxLength={100}
                            />
                        </XpForm>

                        {(displayState !== XP_FORM_NEW)  &&
                        <div>
                            <ShowDateInForms trKey={'general.createdDateTime'} date={balance.createdDateTime} user={balance.createdByUser}/>
                            {(balance.disabledDateTime)  &&
                            <ShowDateInForms trKey={'general.disabledDateTime'} date={balance.disabledDateTime} user={balance.disabledByUser}/>}
                            {(balance.disabledDateTime)  &&
                            <LabelValueDisplay notInLine label={<XpTranslated trKey={'accountBalanceList.headers.disabledComment'}/>} value={balance.disabledComment}/>}
                        </div>}


                        {(displayState === XP_FORM_NEW) ?
                            <div className="xpoolModalButtons">
                                <WaitingXpoolButton
                                    labelKey        = "general.buttons.save"
                                    onClickCallback = {() => dispatch(xpFormSubmitForm("registerBalanceForm"))}
                                    disabled={formData.balance === ""}
                                />
                                <WaitingXpoolButton
                                    xpTestId        = {"xpModalButtonCancel"}
                                    labelKey        = 'general.buttons.cancel'
                                    onClickCallback = {cancelAction}
                                />
                            </div>
                            :
                            <div className="xpoolModalButtons">
                                <WaitingXpoolButton
                                    labelKey        = "general.buttons.disable"
                                    onClickCallback = {() => disableBalance(account, balance, activeLanguage, dispatch).then(status => closeModal())}
                                    disabled        = {(balance.disabledDateTime || (latestBalance.id === balance.id))}
                                    toolTipKey      = {getDisableToolTipKey()}
                                />
                                <WaitingXpoolButton
                                    labelKey        = "general.buttons.close"
                                    onClickCallback = {closeModal}
                                />
                            </div>}
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
