import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";

function XpCheckbox(props) {

    return (
        <FormControlLabel
            disabled={props.disabled}
            control={
                <Checkbox
                    checked={!!props.value}
                    onChange={props.readOnly ? undefined : props.onChange}
                    color="primary"
                    size="small"
                />
            }
            label={props.label ? props.label : ""}
        />
    )
}

function XpFormCheckboxInput (props) {
    return (
        <XpFormLabeledInput
            // inLineLayout

            {...props}

            CompToRender={XpCheckbox}
            getValueFromEvent={event => event.target.checked}
            isCheckboxField
            setTouchedOnChange

            beforeChangeFunction={(before, after) => (before === after) ? null : before}
            valueRenderer       ={(value, props) => (value === null) ? null : (<XpTranslated trKey={value ? 'general.checkBoxValues.checked' : 'general.checkBoxValues.unchecked'} />)}
        />
    )
}
XpFormCheckboxInput.propTypes    = xpFormBasePropTypes;
export default  XpFormCheckboxInput
