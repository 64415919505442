import {genericFetch}                                   from "gui-common/api/apiCommon";
import {apiBaseUrlMap}                                  from "appConfig/api/apiConfig";
import {ormEntitiesGetStarted} from "gui-common/orm/ormReducer";
import {ormEntityClearLoadAll} from "gui-common/orm/ormLoadingReducer";
import {xpTranslatedLoadTextGenerations} from "gui-common/appLocale/xpTranslated/xpTranslatedReducer";
import {createSystemTextBody, systemTextTransformer} from "gui-common/appLocale/xpTranslated/xpTranslatedFunctions";


export function handleSystemTextResponseThunk(items) {
    return (dispatch, getState) => {
        let transformedItems = [];

        for (let item of items) {
            if (!item.languageCode || !item.textKey) {
                continue;
            }
            transformedItems.push(systemTextTransformer(item));
        }
        dispatch(xpTranslatedLoadTextGenerations(transformedItems));

/*
        // First transform SystemText items and add to textGenerations reducer. In the same iteration, keep track of what trKeys to add to translate state
        for (let item of items) {
            const textGenerationsForKey = selectTextGenerationsForKey(getState(), item.textKey);
            if (textGenerationsForKey && textGenerationsForKey[item.languageCode] && textGenerationsForKey[item.languageCode].find(textGen => textGen.id === item.id)) continue; // Do not add if already in reducer.

            transformedItems.push(systemTextTransformer(item));
            if (!translationsToAdd[item.textKey]) translationsToAdd[item.textKey] = [];
        }
        if (transformedItems.length === 0) return;

        const translations = getTranslations(getState().locale);

        dispatch(setAppTextGeneration(transformedItems, translations, getLangToIndexMap(getState())));

        // Now, calculate the new translate array that should be injected to the translate state.
        const languages = getLanguages(getState().locale);
        for (let trKey in translationsToAdd) {
            const textGenerationsForKey = selectTextGenerationsForKey(getState(), trKey);

            translationsToAdd[trKey] = languages.map((lang, index) => {
                let returnText = translations[trKey] ? translations[trKey][index] : "";
                if (textGenerationsForKey[lang.code]) returnText = textGenerationsForKey[lang.code][0].text;
                return returnText;
            });
        }
        // Inject the updated translation to the translate state
        dispatch(addTranslation(translationsToAdd));

        // Finally, reload translations to make change visible in GUI.
        const activeLanguage = selectActiveLanguage(getState());
        dispatch(setActiveLanguageThunk(activeLanguage));
*/
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to get user messages from BE including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getSystemTextsFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get SystemText ";
            let requestUrl = apiBaseUrlMap.systemText + "?";

            dispatch(ormEntitiesGetStarted('SystemText'));
            dispatch(genericFetch(
                requestUrl,
                logText,
                getResponseHandler,
                getFailHandler,
                getFailHandler,
                'GET',
                undefined, // no body in http request needed for GET.
                'UserMessage'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getResponseHandler(resp) {
    return (dispatch, getState) => {
        dispatch(handleSystemTextResponseThunk(resp));
        dispatch(ormEntityClearLoadAll('SystemText'));
    }
}
function getFailHandler(resp) {
    return (dispatch, getState) => {
        dispatch(ormEntityClearLoadAll('SystemText'))
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to add user message including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function postSystemTextToApi(item) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            const logText = "Post SystemText";
            let requestUrl = apiBaseUrlMap.systemText + "?";
            console.log('messagebody:', createSystemTextBody(item));
            dispatch(genericFetch(
                requestUrl,
                logText,
                postUserMessageResponseHandler,
                postUserMessageFailHandler,
                postUserMessageFailHandler,
                'POST',
                createSystemTextBody(item),
                'UserMessage'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function postUserMessageResponseHandler(resp) {
    return (dispatch, getState) => {
        dispatch(handleSystemTextResponseThunk([resp]));
    }
}
function postUserMessageFailHandler(originalMessage) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}
