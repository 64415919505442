import {useSelector} from "react-redux";
import React from "react";
import {withRouter} from "react-router-dom";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {shallowActiveLegalEntityUnitsSelector} from "xpool-gui-common/features/legalEntityUnit/legalEntityUnitSelectors";
import {getFormLabelKey} from "gui-common/xpForm/formComponentsFunctions";
import XpAdminFormEntityList from "gui-common/xpAdminForm/XpAdminFormEntityList";
import {getClientAdminFormConfig} from "appConfig/xpAdminFormConfig";
import {userCanCreate} from "gui-common/functions/userRights";
import AccountsList from "features/bankAccount/AccountsList";
import {activeClientBankAccountsSelector} from "xpool-gui-common/features/client/clientSelectors";
import {shallowActiveClientSelector} from "features/client/clientSelectors";

function ActiveClientAccountsSettings() {
    const activeClient   = useSelector(shallowActiveClientSelector);
    const activeLeus     = useSelector(shallowActiveLegalEntityUnitsSelector);
    const activeAccounts = useSelector(activeClientBankAccountsSelector);

    if (!activeClient) return (
        <h2><XpTranslated trKey='cashPool.headerNoClient'/></h2>
    )

    return (
        <div>
            <h2><XpTranslated trKey={getFormLabelKey({formTemplate: 'accountForm'}, ".formHeader")}/></h2>
            <hr/>
            <div>
                <XpAdminFormEntityList
                    noParentData          = {true}
                    includedListItems     = {activeAccounts}
                    xpAdminFormInstanceId = 'accountSettings'
                    xpAdminFormConfig     = {getClientAdminFormConfig()}
                    ormModel              = 'BankAccount'
                    listComponent         = {AccountsList}
                    filterFunction        = {(item) => (item.clientId === activeClient.id)}
                    formTemplate          = 'accountForm'
                    userCanAddEntity      = {userCanCreate('BankAccount', activeLeus, 'nestedRightRequiredForAdmin')}
                />
            </div>
        </div>
    )
}
export default withRouter(ActiveClientAccountsSettings);
