import {apiBaseUrlMap} from "appConfig/api/apiConfig";
import {genericFetch} from "gui-common/api/apiCommon";

export function requestErpConnectionFromApi(erpIntegration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request ERP connection for " + erpIntegration.id;
            let requestUrl = apiBaseUrlMap.erpOathConnection + "/" + erpIntegration.id + "/connect?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}
                    // windowPop(resp.redirectUri, 800, 700)
                },
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'POST',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
export function requestRevokeErpConnectionFromApi(erpIntegration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request ERP connection for " + erpIntegration.id;
            let requestUrl = apiBaseUrlMap.erpOathConnection + "/" + erpIntegration.id + "/revoke?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'PUT',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
export function requestCancelErpConnectionFromApi(erpIntegration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request ERP connection for " + erpIntegration.id;
            let requestUrl = apiBaseUrlMap.erpOathConnection + "/" + erpIntegration.id + "/connect-cancel?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'PUT',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function requestErpPayablesFromApi(erpIntegration, params) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request payables from ERP connection for " + erpIntegration.id;
            let requestUrl = apiBaseUrlMap.erpDataRequest + "/" + erpIntegration.id + "/payables?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'GET',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function requestErpReceivablesFromApi(erpIntegration, params) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request payables from ERP connection for " + erpIntegration.id;
            let requestUrl = apiBaseUrlMap.erpDataRequest + "/" + erpIntegration.id + "/receivables?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'GET',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
