import React, {useMemo} from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import XpAdminFormEntityList from "gui-common/xpAdminForm/XpAdminFormEntityList";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {childEntityInParentForm} from "gui-common/xpAdminForm/xpAdminFormFunctions";

export default function XpAdminFormSubEntities (props) {

    const childListPropArray = useMemo(() =>
        {
            if (props.formUseState === XP_FORM_NEW) return [];
            if (props.suppressSubEntities) return [];

            function findFirstUsedListProps(childListPropArray, thisOrmModel, thisFormModel, currentData) {
                const thisModelFormConfig = props.xpAdminFormConfig[thisOrmModel];
                if (!thisModelFormConfig?.childEntities?.length) return;

                thisModelFormConfig.childEntities.forEach(childProps => {
                    if (!childProps.arrayProperty || !childProps.ormModel) return;

                    let inParentForm = childEntityInParentForm(childProps, props.formUseState, props.dependentFields, thisFormModel, currentData);

                    if (inParentForm) {
                        // Child entity is included in parent form. Keep parsing downwards if possible.
                        if (!currentData[childProps.arrayProperty] || !currentData[childProps.arrayProperty][0]) {
                            return; // No child entity. Cannot parse further!
                        }
                        findFirstUsedListProps(childListPropArray, childProps.ormModel, thisFormModel + '.' + childProps.arrayProperty + '.0', currentData[childProps.arrayProperty][0])
                        return;
                    }
                    // child model is managed separately, prepare props for next list level.
                    childListPropArray.push({
                        ...childProps,
                        formTemplate  : props.xpAdminFormConfig[childProps.ormModel].formTemplate,
                        parentItemData: currentData, // This is needed to use in the filter function sent to the list data selectors.
                        noParentData: false,
                    });
                })
            }

            const childListPropArray = [];
            findFirstUsedListProps(childListPropArray, props.ormModel, props.formModel, props.currentData);
            return childListPropArray;
        },
        [props.ormModel, props.currentData, props.dependentFields, props.formUseState]
    );

    if (!childListPropArray.length) return null;

    return (
        <div className="entitySubItemsList">
            {childListPropArray.map(childListProp => (
                    <XpAdminFormEntityList
                        key={childListProp.ormModel}
                        {...props}
                        {...childListProp} // Must be loaded after ...props since many props are overwritten with child props.
                    />
                ))
            }
        </div>
    );
}



