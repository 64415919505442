import cloneDeep from "lodash.clonedeep";
import {pushOrReplaceByKey} from 'gui-common/functions/reducer';
import {dashboardConfig} from "./dashboardConfig"

export const DASHBOARD_SET_SIZEINFO = 'DASHBOARD_SET_SIZEINFO';
export const DASHBOARD_DROP_ITEM = 'DASHBOARD_DROP_ITEM';
export const DASHBOARD_CLICK_DROP_ITEM = 'DASHBOARD_CLICK_DROP_ITEM';
export const DASHBOARD_SET_LAYOUT = 'DASHBOARD_SET_LAYOUT';
export const DASHBOARD_REMOVE_COMPONENT = 'DASHBOARD_REMOVE_COMPONENT';
export const DASHBOARD_RELOAD_STATE = 'DASHBOARD_RELOAD_STATE';
export const DASHBOARD_RENAME_COMPONENT = 'DASHBOARD_RENAME_COMPONENT';
export const DRAGGING = 'DRAGGING';
export const DASHBOARD_DRAG_ITEM = 'DASHBOARD_DRAG_ITEM';
export const DASHBOARD_RESET_STATE = 'DASHBOARD_RESET_STATE';
export const DASHBOARD_SET_COMPONENT_STATE = 'DASHBOARD_SET_COMPONENT_STATE';

export function dashboardSetSizeInfo(positionInfo) {
    return {type: DASHBOARD_SET_SIZEINFO, payload: positionInfo}
}
export function dashboardDropItem(drop_item) {
    return {type: DASHBOARD_DROP_ITEM, payload: drop_item};
}
export function dashboardClickDropItem(drop_item) {
    return {type: DASHBOARD_CLICK_DROP_ITEM, payload: drop_item};
}
export function dashboardSetLayout(layout) {
    return {type: DASHBOARD_SET_LAYOUT, payload: layout};
}
export function dashboardRemoveComponent(key) {
    return {type: DASHBOARD_REMOVE_COMPONENT, payload: key};
}
export function dashboardReloadState(newState) {
    return {type: DASHBOARD_RELOAD_STATE, payload: newState};
}
export function dashboardRenameComponent(key, name) {
    return {type: DASHBOARD_RENAME_COMPONENT, payload: {key: key, name: name}};
}
export function dragging(isDragging) {
    return {type: DRAGGING, payload: isDragging};
}
export function dashboardDragItem(dragKey) {
    return {type: DASHBOARD_DRAG_ITEM, payload: dragKey};
}
export function dashboardResetState() {
    return {type: DASHBOARD_RESET_STATE, payload: undefined};
}
export function dashboardSetComponentsState(key, state) {
    return {type: DASHBOARD_SET_COMPONENT_STATE, payload: {key: key, state: state}};
}

const defaultInitialValues = {
    key : "0",
    dataGrid : {w: 8, h: 12, x: 0, y: 0, minW: 2, minH: 4 },
};

/*
export const dashboardPickerIcons = [
    {icon : 'traffic',                componentName: 'IntegrationStatusContainer',  keyName:'traffic'},
    {icon : 'list',                   componentName: 'ClientsStatusList',           keyName:'clients'},
    {icon : 'announcement',           componentName: 'UserMessagesList',            keyName:'userMessages'},
    {icon : 'swap_horizontal_circle', componentName: 'OrdersList',                  keyName:'orders'},
    {icon : 'euro_symbol',            componentName: 'FxPriceList',                 keyName:'fxPrices'},
    ...appSpecificDashboardPicketIcons,
];
*/

function getDashboardPickerIcons() {
    let outArray = [];
    for (let key in dashboardConfig) outArray.push({
        icon            : dashboardConfig[key].icon,
        componentName   : key,
        keyName         : dashboardConfig[key].keyName,
    });
    return outArray;
}
const dashboardPickerIcons = getDashboardPickerIcons();

const findInitialIcon = function(componentName) {
    for (let icon of dashboardPickerIcons) {
        if (icon.componentName === componentName) return icon;
    }
    return null;
};



export const dashboardInitialState = {
    keyNo: 2,
    sizeInfo : {
      cols: 12,
      rowHeight: 30,
      pickerWidth : 0,
      pickerHeight: 0,
      panelWidth: 0,
      panelHeight: 0,
      vwWidth : 0,
      vwHeight: 0
    },
    defaultInitialValues: defaultInitialValues,
    pickerIcons : [...dashboardPickerIcons],
    layouts : {},
    activeComponents : [],
    dragging: false,
    yFactor: 20,
};

function setComponentState(state, action) {
    let component = state.activeComponents.find(item => item.key === action.payload.key);
    if (!component) {
        console.warn("No active component in grid found when setting state. Key= " + action.payload.key);
        return state;
    }
    let newComponent = cloneDeep(component);
    newComponent.state = action.payload.state;

    let newState = cloneDeep(state);
    newState.activeComponents = state.activeComponents.filter(item => item.key !== newComponent.key);
    newState.activeComponents.push(newComponent);
    return newState;
}

export function dashboardReducer(state = dashboardInitialState, action)
{
    let newState;
    let newDashboardItem;
    switch (action.type) {
        case DASHBOARD_SET_LAYOUT:
            newState = cloneDeep(state);
            newState.layouts = action.payload;
            return newState;
        case DASHBOARD_DROP_ITEM:
            newState = cloneDeep(state);
            newDashboardItem = cloneDeep(defaultInitialValues);
            if (action.payload.sizeInfo) newDashboardItem.dataGrid = {...newDashboardItem.dataGrid, ...action.payload.sizeInfo};
            newDashboardItem.componentName = action.payload.componentName;
            newDashboardItem.key = state.keyNo + 1;
            newDashboardItem.dataGrid.x = 0;
            newDashboardItem.dataGrid.y = 0;
            newState.keyNo = newDashboardItem.key;
            newDashboardItem.key = newDashboardItem.key.toString();
            for(let key in newState.activeComponents) {
                if(newState.activeComponents[key].key === '0') {
                    newState.activeComponents[key] = Object.assign({}, newDashboardItem, {dataGrid: (Object.assign({}, newState.activeComponents[key].dataGrid))});
                }
            }
            //newState.activeComponents.push(newDashboardItem);
            for (let key in newState.pickerIcons) {
                if(newState.pickerIcons[key].componentName === action.payload.componentName) {
                    newState.pickerIcons[key] = findInitialIcon(action.payload.componentName);
                }
            }
            return newState;
        case DASHBOARD_CLICK_DROP_ITEM:
            newState = cloneDeep(state);
            newDashboardItem = cloneDeep(defaultInitialValues);
            if (action.payload.sizeInfo) newDashboardItem.dataGrid = {...newDashboardItem.dataGrid, ...action.payload.sizeInfo};
            newDashboardItem.componentName = action.payload.componentName;
            newDashboardItem.key = state.keyNo + 1;
            newDashboardItem.dataGrid.x = 0;
            newDashboardItem.dataGrid.y = 0;
            newState.keyNo = newDashboardItem.key;
            newDashboardItem.key = newDashboardItem.key.toString();
            newState.activeComponents.push(newDashboardItem);
            return newState;
        case DASHBOARD_DRAG_ITEM:
            newState = cloneDeep(state);

            let tempDashboardItem = cloneDeep(defaultInitialValues);
            tempDashboardItem.componentName = "empty";
            tempDashboardItem.key = '0';
            tempDashboardItem.dataGrid.x = action.payload.clientX;
            tempDashboardItem.dataGrid.y = action.payload.clientY;
            if (action.payload.sizeInfo) tempDashboardItem.dataGrid = {...tempDashboardItem.dataGrid, ...action.payload.sizeInfo}
            newState.activeComponents = pushOrReplaceByKey(newState.activeComponents, tempDashboardItem);
            for (let layoutGroup in newState.layouts) {
                for (let layout of newState.layouts[layoutGroup]) {
                    if (layout.i === '0') {
                        layout.moved = layout.x === action.payload.clientX && layout.y === action.payload.clientY;
                        layout.x = action.payload.clientX;
                        layout.y = action.payload.clientY;
                    } else {
                        if (layout.y === action.payload.clientY) {
                            if ((layout.x + layout.w) >= action.payload.clientX) {
                                layout.y = layout.y + tempDashboardItem.dataGrid.h;
                            }
                        }
                    }
                }
            }
            return newState;
        case DASHBOARD_RENAME_COMPONENT:
            let activeComponent = state.activeComponents.find(item => item.key === action.payload.key);
            if (!activeComponent) {
                console.warn("No active component in grid found. Key= " + action.payload.key);
                return state;
            }
            let newActiveComponent = cloneDeep(activeComponent);
            newActiveComponent.name = action.payload.name;

            newState = cloneDeep(state);
            newState.activeComponents = state.activeComponents.filter(item => item.key !== newActiveComponent.key);
            newState.activeComponents.push(newActiveComponent);
            return newState;

        case DASHBOARD_SET_COMPONENT_STATE:
            return setComponentState(state, action);

        case DASHBOARD_REMOVE_COMPONENT:
            newState = cloneDeep(state);
            let newLg = [];
            if(newState.hasOwnProperty('layouts') && newState.layouts.hasOwnProperty('lg')) {
                newState.layouts.lg.forEach(function (element) {
                    if (element.hasOwnProperty('i') && element.i !== action.payload.toString()) {
                        newLg.push(Object.assign({}, element));
                    }
                });
                newState.layouts.lg = newLg;
            }
            //for(let key in newState.activeComponents){
                let newComp = [];
                newState.activeComponents.forEach(function(element){
                   if(element.hasOwnProperty('key') && element.key !== action.payload.toString()) {
                       newComp.push(cloneDeep(element));
                   }
                });
                newState.activeComponents = newComp;
            //}
            return newState;
        case DASHBOARD_SET_SIZEINFO :
            newState = cloneDeep(state);
            let newSize = Object.assign({}, state.sizeInfo);
            newState.sizeInfo = {...newSize, ...action.payload};
            return newState;
        case DASHBOARD_RELOAD_STATE:
            newState = cloneDeep(action.payload);
            // Remove active states with componentName not found in  dashoardConfig. These components may have existed in previous versions but now removed.
            if (newState.activeComponents && newState.activeComponents.length) {
                newState.activeComponents = newState.activeComponents.filter(item => dashboardConfig[item.componentName] !== undefined);
            }
            // Dashboard icons must be restored. Othewise new icons added in new releases will not show up since users state will have old tests not to be run icon list...
            return {...newState, pickerIcons : [...dashboardPickerIcons],  defaultInitialValues: defaultInitialValues, yFactor : 20};
        case DRAGGING:
            if(action.payload !== state.dragging) {
                newState = cloneDeep(state);
                newState.dragging = action.payload;
                return newState;
            }
            return state;
        case DASHBOARD_RESET_STATE:
            return dashboardInitialState;
        default:
            return state;
    }
}
