import {createSelector} from "reselect";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {
    findAndBuildEnrichedEntity,
    getOrAddCashedObject, getOrmModelObjectById,
} from "gui-common/orm/ormFunctions";
import {translateBoolFields} from "gui-common/xpGrid/xpGridFunctions";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {activeCountriesSelector} from "gui-common/orm/ormSelectors";
import {getDefaultObject} from "gui-common/functions/functions";
import {buildLegalEntityUnitObject} from "xpool-gui-common/features/legalEntityUnit/legalEntityUnitSelectors";
import {
    addParentRunConfigurationElements,
    createMergedRunConfigurationElements
} from "gui-common/runConfiguration/runConfigurationFunctions";
import {entityIsActive, getLastChange} from "gui-common/audit/auditFunctions";
import {buildBankAccountObject} from "xpool-gui-common/features/bankAccount/bankAccountSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function getLegalEntityBankAccounts(leModelObject, client) {
    if (!leModelObject) return [];

    let returnArray = [];
    leModelObject.legalEntityUnits.filter(entityIsActive).orderBy('id', 'asc').toModelArray().forEach(legalEntityUnit => {
        legalEntityUnit.bankAccounts.filter(entityIsActive).orderBy('id', 'asc').toModelArray().forEach(bankAccount => {
            const newItem = buildBankAccountObject(true, bankAccount, legalEntityUnit, leModelObject, client);
            returnArray.push(newItem);
        });
    });
    return returnArray;
}

export const getLegalEntityBankAccountsSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, selectId) => {
        if (!selectId) return undefined;
        //        console.log("Running activeClientBankAccountsSelector ", activeClientId);
        const le = getOrmModelObjectById(session, 'LegalEntity', selectId);
        if (!le) return undefined;

        const client = le.clientId;

        let returnArray = getLegalEntityBankAccounts(le, client);
//        console.log("Selected active accounts: ", returnArray);
        return returnArray;
    }
);

export const legalEntitiesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector legalEntitiesSelector running ");

        let cashedClients = {};
        const legalEntities = session.LegalEntity.filter(entityIsActive).orderBy('id', 'desc').toModelArray().map(legalEntityModel => ({
            ...legalEntityModel.ref,
            client: getOrAddCashedObject(legalEntityModel.ref.clientId , 'Client', cashedClients, session),
            ...getLastChange('LegalEntity', legalEntityModel),
        }));

        return legalEntities;
    }
);

export function buildLegalEntityObject (legalEntityModelObject, getDeep) {

    const clientModel = legalEntityModelObject.clientId;
    const erpIntegration = legalEntityModelObject.erpIntegrationId ? legalEntityModelObject.erpIntegrationId.ref : undefined;

    const returnObject = {
        ...legalEntityModelObject.ref,
        client: clientModel ? clientModel.ref : undefined,
        legalEntityUnits: getDeep ? legalEntityModelObject.legalEntityUnits.filter(entityIsActive).toModelArray().map(unit => buildLegalEntityUnitObject(unit, getDeep)) : undefined,
        runConfiguration: createMergedRunConfigurationElements(legalEntityModelObject, 'LegalEntity'),
        erpIntegration: erpIntegration,
    };
    return returnObject;
}

export const getSpecificLegalEntitySelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, legalEntityId) => findAndBuildEnrichedEntity(session, 'LegalEntity', legalEntityId, buildLegalEntityObject,true)
);
export const getShallowLegalEntitySelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, legalEntityId) => findAndBuildEnrichedEntity(session, 'LegalEntity', legalEntityId, buildLegalEntityObject,false)
);


const translate        = (state, props) => selectTranslateFunction(state);
const allLegalEntities = state          => legalEntitiesSelector(state);
const filterFunction   = (state, props) => props ? props.filterFunction : undefined;
const inputData        = (state, props) => props ? props.inputData      : undefined;
export const getLegalEntitiesListDataSelector = () => createSelector(
    [allLegalEntities, translate, filterFunction, inputData],
    (allLegalEntities, translate, filterFunction, inputData) => {
        // console.log("Selector getLegalEntitiesListDataSelector running ");
        const legalEntities = inputData ? inputData : filterFunction ? allLegalEntities.filter(filterFunction) : allLegalEntities;
        let returnArray       = [];
        for (let le of legalEntities) {
            const enrichedLe = {...le};

            translateBoolFields(enrichedLe, translate);
            enrichedLe.countryName = le.country ? le.country.country : "";

            returnArray.push(enrichedLe);
        }
        // console.log("Selected getLegalEntitiesListDataSelector:", returnArray);
        return returnArray;
    }
);

/********* New legal entity template selector *****************************************************************/

const legalPersonTypes = state => selectAppEnvProperty(state, 'availableLegalPersonTypes');
const countries        = state => activeCountriesSelector(state);
const parentItemData   = (state, props) => props ? props.parentItemData : undefined;
export const newLegalEntityFormTemplateSelector = ormCreateSelector(
    orm,
    dbStateSelector, legalPersonTypes, countries, parentItemData,
    (session       , legalPersonTypes, countries, parentItemData) => {

        let parentRunConfigurations = {};
        const parentModelObject = getOrmModelObjectById(session, 'Client', parentItemData ? parentItemData.id : undefined);
        addParentRunConfigurationElements(parentModelObject, 'Client', parentRunConfigurations)

        return {
            clientId         : parentItemData ? parentItemData.id : "", // Set clientId from superEntity client if provided.
            legalPersonType  : getDefaultObject(legalPersonTypes).id,
            countryId        : getDefaultObject(countries).id,
            runConfiguration :       parentRunConfigurations,
            erpIntegration   : {erpEnabled: false},
            id:                     "",
            name:                   "",
            description:            "",
            country:                "",
            hasLEI:                 false,
            lei:                    "",
            useLegalEntityUnits:    false,
            createdDateTime:        null,
            createdByUser:          "",
            changedDateTime:        null,
            changedByUser:          "",
        }
    }
);
