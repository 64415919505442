import PropTypes from "prop-types";
import React from "react";
import XpTooltip from "gui-common/components/XpTooltip";

function XpWarningBanner ({message, toolTip}) {
    return (
        <XpTooltip title={toolTip}>
            <div className="xpWarningBanner">
                {message}
            </div>
        </XpTooltip>
    )
}
XpWarningBanner.propTypes = {
    message : PropTypes.string.isRequired,
    toolTip : PropTypes.string,
};
export default XpWarningBanner



