import React from 'react'
import PropTypes from 'prop-types';
import XpFormStaticField from "gui-common/xpForm/core/XpFormStaticField";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {XpDateTime} from "gui-common/components/XpDateTime";
import XpSplitPane from "gui-common/components/XpSplitPane";
import ReactJson from "react-json-view";
import AuditRecordRawDataView from "gui-common/audit/AuditRecordRawDataView";


const emptyArray = [undefined, null, ""];
function fieldIsEmpty(field) {
    return emptyArray.includes(field);
}
function getObjectFromArray(arr) {
    const res = {};
    if (!arr?.length) {
        return res;
    }
    arr.forEach(item => res[item.id] = item)
    return res;
}
function getFields(data, auditConfig) {
    if (!data) {
        return [];
    }
    if (!auditConfig.excludeFields.length) {
        return Object.keys(data);
    }
    return Object.keys(data).filter(key => auditConfig.excludeFields.indexOf(key) === -1);
}
function getModifiedContent(before, after, path, auditConfig) {

    if (fieldIsEmpty(before) && fieldIsEmpty(after)) {
        return undefined;
    }
    if (fieldIsEmpty(before)) {
        return {historicAuditChangeType: 'new', key: "", path: path, after: after, before: null}
    }
    if (fieldIsEmpty(after) || !fieldIsEmpty(after.disabledDateTime)) {
        return {historicAuditChangeType: 'delete', key: "", path: path, after: null, before: before}
    }

    const res = {};
    for (const key of getFields(before, auditConfig)) {
        if (!fieldIsEmpty(before[key]) && fieldIsEmpty(after[key])) {
            res[key] = {historicAuditChangeType: 'delete', key: key, path: path, after: null, before: before[key]}
        }
    }
    for (const key of getFields(after, auditConfig)) {
        if (fieldIsEmpty(after[key])) { // delete check is done in before iteration
            continue;
        }
        if (fieldIsEmpty(before[key])) {
            res[key] = {historicAuditChangeType: 'new', key: key, path: path, after: after[key], before: null}
        }
        if (Array.isArray(after[key])) {
            const nestedModified = getModifiedContent(getObjectFromArray(before[key]), getObjectFromArray(after[key]), path + '.' + key, auditConfig);
            if (nestedModified) {
                res[key] = Object.keys(nestedModified).map(idKey => nestedModified[idKey]);
            }
        }
        else if (typeof after[key] === 'object') {
            const nestedModified = getModifiedContent(before[key], after[key], path + '.' + key, auditConfig);
            if (nestedModified) {
                res[key] = nestedModified;
            }
        }
        else if (before[key] !== after[key]) {
            res[key] = {historicAuditChangeType: 'update', key: key, path: path, after: after[key], before: before[key]}
        }
    }
    if (Object.keys(res).length === 0) {
        return undefined;
    }
    return res;
}


function renderValue(val) {
    return (typeof val === 'boolean') ? (val ? 'true' : 'false') : val;
}
function renderLeafNode(change, propKey, changeType, padding) {
    const retObj = {
        name: propKey,
        padding: padding,
        historicAuditChangeType: changeType,
    }
    if (changeType === 'update') {
        retObj.before = renderValue(change?.before);
        retObj.after = renderValue(change?.after);
    }
    else if (changeType === 'new') {
        retObj.before = "";
        retObj.after = renderValue(change);
    }
    else if (changeType === 'delete') {
        retObj.before = renderValue(change);
        retObj.after = "";
    }
    else {
        console.error("Unsupported change typ and change object", propKey, change, changeType);
        return []
    }
    return [retObj]
}
function renderChangeTree(modifiedObject, objectKey, auditConfig, padding, renderChangeType) {

    if (renderChangeType === "update") { // This can only happen for leaf node that should be rendered
        return renderLeafNode(modifiedObject, objectKey, 'update', padding);
    }

    if (modifiedObject?.historicAuditChangeType === 'update') {
        // Must set historicAuditChangeType to undefined for next level recursion to work properly, i.e. not triggering this code again
        return renderChangeTree({...modifiedObject, historicAuditChangeType: undefined}, objectKey, auditConfig, padding, modifiedObject.historicAuditChangeType);
    }
    if (modifiedObject?.historicAuditChangeType === 'new') {
        return renderChangeTree(modifiedObject.after, objectKey, auditConfig, padding, modifiedObject.historicAuditChangeType);
    }
    if (modifiedObject?.historicAuditChangeType === 'delete') {
        return renderChangeTree(modifiedObject.before, objectKey, auditConfig, padding, modifiedObject.historicAuditChangeType);
    }

    const headerRowTemplate = {
        name: objectKey,
        before: "",
        after: "",
        padding: padding,
        historicAuditChangeType: renderChangeType,
    }
    if (Array.isArray(modifiedObject)) {
        if (!modifiedObject.length) {
            return [];
        }
        let retArray = [];
        const renderHeader = objectKey && (auditConfig.skipParentLevels.indexOf(objectKey) === -1);
        modifiedObject.forEach(item => {
            if (renderHeader) {
                retArray.push({
                    ...headerRowTemplate,
                    trKey: 'auditView.historic.arrayObjects.' + objectKey,
                });
            }
            const content = renderChangeTree(item, null, auditConfig, padding + (renderHeader ? 20 : 0), renderChangeType);
            retArray = [...retArray, ...content]
        });
        return retArray;
    }
    if ((typeof modifiedObject === 'object') && (modifiedObject !== null)) {
        let retArray = [];
        const renderHeader = objectKey && (auditConfig.skipParentLevels.indexOf(objectKey) === -1);
        if (objectKey && renderHeader) {
            retArray.push({
                ...headerRowTemplate,
                trKey: 'auditView.historic.objects.' + objectKey,
            });
        }
        Object.keys(modifiedObject).forEach(key => {
            if (auditConfig.excludeFields.indexOf(key) !== -1) {
                return;
            }
            const content = renderChangeTree(modifiedObject[key], key, auditConfig, padding + (renderHeader ? 20 : 0), renderChangeType);
            retArray = [...retArray, ...content];
        })
        return retArray;
    }
    if (renderChangeType) {
        return renderLeafNode(modifiedObject, objectKey, renderChangeType, padding);
    }
    else {
        console.error("Leaf node modifiedObject with no changeType ", modifiedObject, objectKey);
        return []
    }
}
const changeColorMap = {
    'new'   : 'var(--xpool-form-card-new-background-color)',
    'update': 'var(--xpool-form-updated-field-background-color)',
    'delete': 'var(--xpool-form-card-disabled-background-color)'
}
function Modified(props) {

    if (!props.auditEntry.before && !props.auditEntry.after) {
        return null;
    }

    if (!props?.auditConfig?.historicAudit) {
        console.error("No historic audit config for entry ", props.auditEntry);
        return null;
    }
    const histAuditConfig = props.auditConfig.historicAudit[props.auditEntry.apiVersion];
    if (!histAuditConfig) {
        console.error("No historic audit config for api version ", props.auditEntry);
        return null;
    }

    const modifiedContent = getModifiedContent(props.auditEntry.before, props.auditEntry.after, "", histAuditConfig);

    if (!modifiedContent) {
        return null;
    }
    const renderArray = renderChangeTree(modifiedContent, null, histAuditConfig, 5);
    return (
        <table style={{width: '100%'}}>
            <tbody>
                <tr className="HeaderRow" key="header2">
                    <th style={{width: '40%'}} className=""  ><XpTranslated trKey={'auditView.historic.fieldObject'}/></th>
                    <th style={{width: '30%'}} className=""  ><XpTranslated trKey={'auditView.historic.beforeChange'}/></th>
                    <th style={{width: '30%'}} className=""  ><XpTranslated trKey={'auditView.historic.afterChange'}/></th>
                </tr>
                {renderArray.map((item, index) => {
                    return (
                        <tr style={{background: changeColorMap[item.historicAuditChangeType], transition: 'unset'}} key={index}>
                            <td style={{paddingLeft: item.padding + 'px'}}>{item.trKey ? <XpTranslated trKey={item.trKey}/> : item.name}</td>
                            <td>{item.before}</td>
                            <td>{item.after}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export function HistoricAuditRecordRenderer(props) {
    return (
        <div>
            <h3 className={'formHeaderEditNew'}>
                <XpTranslated trKey={'auditView.historic.header'}/>
            </h3>
            <div className='xpAdminFormEntityCard'>
                <div className="adminFormContainer">
                    <div style={{width: '30%', padding: '5px'}}>
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.auditDateTime'         value={<XpDateTime isoString={props.auditEntry.auditDateTime} format='lll'/>}/>
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.userFullName'          value={props.auditEntry.user.firstName + ' ' + props.auditEntry.user.lastName}/>
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.apiVersion'            value={props.auditEntry.apiVersion}/>
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.operationTypeT'        value={<XpTranslated trKey={"auditEntriesList.operationType." + props.auditEntry.operationType}/>} />
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.rootObjectType'        value={<XpTranslated trKey={"general.modelNamesLarge." + props.auditEntry.rootModel}/>} />
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey={'auditEntriesList.headers.rootObjectName.' + props.auditEntry.rootModel} value={props.auditEntry.root?.name + ' (' + props.auditEntry.root?.id + ')'}/>
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.model'                 value={<XpTranslated trKey={"general.modelNamesLarge." + props.auditEntry.model}/>} />
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.modifiedObjectName'    value={props.auditEntry.modifiedObject?.name  + ' (' + props.auditEntry.modifiedObject?.id + ')'}/>
                    </div>
                    <div style={{width: '70%', padding: '5px'}}>
                        <Modified {...props} />
                    </div>
                </div>
                <AuditRecordRawDataView {...props}/>
            </div>
        </div>
    );

}

HistoricAuditRecordRenderer.propTypes = {
    auditEntry : PropTypes.object.isRequired,
    auditConfig: PropTypes.object.isRequired,
    auditProps : PropTypes.object
};
