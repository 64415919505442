import {createSelector} from "reselect";

const requestState      = (state)        => state.requestEntityState;
const modelRequestState = (state, props) => state.requestEntityState[props.model];
const checkEntityId     = (state, props) => props.entityId;
const checkProperty     = (state, props) => props.property;

export const getRequestingEntityStateSelector = () => createSelector(
    [modelRequestState, checkEntityId, checkProperty],
    (modelRequestState, checkEntityId, checkProperty) => {

        if (!modelRequestState) return false;
        if (checkEntityId && !modelRequestState[checkEntityId]) return false;
        if (checkProperty && !modelRequestState[checkEntityId][checkProperty]) return false;
        return true;
    }
);
const checkModel     = (state, props) => props.model;
export const getRequestingStateOnModelSelector = () => createSelector(
    [requestState, checkModel],
    (requestState, checkModel) => {
        if (!requestState) return undefined;
        return requestState[checkModel];
    }
);
