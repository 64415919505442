import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'

import {
    bankAccountsCashLadderDatesSelector,
    bankAccountsCashLadderListDataSelector, bankAccountsCashLadderProjectionsSelector
} from "features/bankAccount/bankAccountSelectors";
import {getCashLadderColumns} from "features/bankAccount/accountFunctions";
import XpSlider from "gui-common/components/XpSlider";
import {xpGridSetGridConfig} from "gui-common/xpGrid/xpGridsReducer";
import {selectMyXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import "features/bankAccount/account.css"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpTooltip from "gui-common/components/XpTooltip";
import moment from "moment";
import {formatDateField} from "gui-common/functions/functions";
import {TransactionsList} from "features/transaction/TransactionsList";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function getLabel(labelKey, toolTipKey) {
    return (
        <XpTooltip trKey={toolTipKey}>
            <label className='cashProjectionSelectorLabel'><XpTranslated trKey={labelKey}/></label>
        </XpTooltip>

    )
}


AccountCashLadderList.detailIcon = 'linear_scale';
AccountCashLadderList.detailIconToolTipKey = 'accountCashLadderList.detailIconToolTipKey';
export function AccountCashLadderList ({itemSelectedCallback, instanceId, setHeightCallback, fillAvailableSpace, gridId, rowDataSelectorProps}) {


    const currentLanguage = useSelector(selectActiveLanguage);

    const translate      = useSelector(selectTranslateFunction);
    const translateRef   = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridState      = useSelector(state => selectMyXpGridState(state, {instanceId: instanceId}));

    const rowDataSelector             = useMemo(() => bankAccountsCashLadderListDataSelector()    , []);
    const cashProjectionsSelector     = useMemo(() => bankAccountsCashLadderProjectionsSelector() , []);
    const cashProjectionDatesSelector = useMemo(() => bankAccountsCashLadderDatesSelector()       , []);

    const cashProjections   = useSelector(cashProjectionsSelector);
    if (!cashProjections || !cashProjections.length) return null;

    const cashProjectionDates   = useSelector(cashProjectionDatesSelector);
    if (!cashProjectionDates || !cashProjectionDates.length) return null;

    const selectedProjection    = (gridState && gridState.gridConfig && gridState.gridConfig.projection) ? gridState.gridConfig.projection : cashProjections[0];
    const selectedStartDate     = (gridState && gridState.gridConfig && gridState.gridConfig.startDate)  ? gridState.gridConfig.startDate  : cashProjectionDates[0];
    const selectedEndDate       = (gridState && gridState.gridConfig && gridState.gridConfig.endDate)    ? gridState.gridConfig.endDate    : cashProjectionDates[cashProjectionDates.length - 1];

    const selectedProjectionDates = useMemo(
        () => {
            let returnArray = [];
            let beforeStartDate = (selectedStartDate !== undefined);
            for (let date of cashProjectionDates) {
                if (date === selectedStartDate) beforeStartDate = false;
                if (beforeStartDate) continue;
                returnArray.push(date);
                if (date === selectedEndDate) break;
            }
            return returnArray;
        },[cashProjectionDates, selectedStartDate, selectedEndDate])


    const rowData               = useSelector(state => rowDataSelector(state, {projection: selectedProjection, dates: selectedProjectionDates}));

    const dispatch  = useDispatch();


    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!itemSelectedCallback) return;
                itemSelectedCallback((data === undefined) ? undefined : data.id);
            },
            [itemSelectedCallback]),
        gridContextMenuItems    : useCallback(
            (params) => {
                let menuItems = [];
                return menuItems;

            }, [translate]),
        isRowMaster     : useCallback((params) => {return true;}, []),
        rowClassRules   : {},
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : instanceId,
                overlayNoRowsTemplate   : translate('accountsList.noAccountsToShow'),
                fillAvailableSpace      : (fillAvailableSpace === undefined) ? true : fillAvailableSpace,
                groupDefaultExpanded    : 0,
                // rowDataSelector         : selectListRatesListData,
                treeData                : false,
                masterDetail            : true,
                ormModel                : 'BankAccount',
                applyColumnDefOrder     : true,
                xpDetailRendererProps   : {
                    availableComponents: [
                        {
                            detailType: 'cashLadderTransactions',
                            componentToRender: TransactionsList,
                            propsToComponent: {
                                rowDataSelectorProps    : {
                                    selectedProjectionDates: selectedProjectionDates,
                                    selectedProjection: selectedProjection
                                },
                                rowDataFilterFunction: (item, parentItemData) => {
                                    return (item.accountId === parentItemData.id);
                                },
                            },
                        },
                    ],
                    parentInstanceId: instanceId,
                },
            };
        },
        [translate, fillAvailableSpace, selectedProjection, selectedProjectionDates]
    );
    const colDefs = useMemo(
        () => {
            return getCashLadderColumns(translate, selectedProjectionDates);
        },
        [translate, selectedProjectionDates]
    );

    const projectionSteps = useMemo(
        () => {
            return cashProjections.map(projection => {
                return {value: projection}
            });
        },
        [cashProjections]
    );
    const projectionDateSteps = useMemo(
        () => {
            return cashProjectionDates.map(date => {
                return {value: date}
            });
        },
        [cashProjectionDates, currentLanguage]
    );

    const dateSelectorMaxSize = useMemo(() => projectionDateSteps.length * 90 + 'px', [projectionDateSteps]);
    const dateSelectorMinSize = useMemo(() => projectionDateSteps.length * 40 + 'px', [projectionDateSteps]);


/*
    console.log("Acc: selectedProjection: ", selectedProjection);
    console.log("Acc: selectedProjectionDates: ", selectedProjectionDates);
    console.log("Acc: selectedProjectionDates: ", gridOptions.xpDetailRendererProps.availableComponents[0].propsToComponent.rowDataSelectorProps.selectedProjectionDates);
*/

    return (
        <div className='cashProjectionContainer'>
            <div className='cashProjectionConfigPane'>
                <div className='cashProjectionSelectionSlider'>
                    {getLabel('accountCashLadderList.cashProjectionSelector.label', 'accountCashLadderList.cashProjectionSelector.toolTip')}
                    <XpSlider
                        steps={projectionSteps}
                        trPath="accountCashLadderList.cashProjectionSelector"
                        onChangeCallback={value => dispatch(xpGridSetGridConfig(instanceId,{projection: value}))}
                        defaultValue={selectedProjection}
                    />
                </div>
                <div className='projectionDatesSelectionSlider' style={{maxWidth: dateSelectorMaxSize, minWidth: dateSelectorMinSize}}>
                    {getLabel('accountCashLadderList.projectionDatesSelector.label', 'accountCashLadderList.projectionDatesSelector.toolTip')}
                    <XpSlider
                        steps={projectionDateSteps}
                        onChangeCallback={value =>  dispatch(xpGridSetGridConfig(instanceId,{startDate: value[0], endDate: value[1]}))}
                        defaultValue={[selectedStartDate, selectedEndDate]}
                        markLabelTransformer={date => {
                            if (!date) return "";
                            const momentObject = moment(date);
                            return formatDateField(momentObject, currentLanguage, 'MMM-DD');
                        }}
                    />
                </div>
            </div>
            <div className='cashProjectionList'>
                <XpGrid
                    gridId={gridId ? gridId : "CashLadder-" + instanceId}
                    {...gridCallbacks}
                    {...gridOptions}
                    rowData={rowData}
                    columnDefs={colDefs}
                    callingContext={this}
                    setHeightCallback={setHeightCallback}
                />
            </div>
        </div>
    );
}
