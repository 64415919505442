import PropTypes from "prop-types";
import React from "react";
import XpTooltip from "gui-common/components/XpTooltip";

function XpMuiIconButton (props) {
    if (!props.icon) return null;
    if (props.hidden) return null;
    return (
        <div
            style={props.style}
            className={props.className}
            onClick   = {props.disabled ? undefined : e => props.onClickCb(e)}
        >
            <XpTooltip bypass={!props.toolTipKey} trKey={props.toolTipKey} trParams={props.toolTipParams}>
                <props.icon
                    sx={{
                        height: props.size ? props.size : undefined,
                        width: props.size ? props.size : undefined,
                        color: props.color ? props.color : undefined,
                    }}
                />
            </XpTooltip>
        </div>
    )
}
XpMuiIconButton.propTypes = {
    icon: PropTypes.object.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
    toolTipKey: PropTypes.string,
    toolTipParams: PropTypes.object,
    onClickCb: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
};
export default XpMuiIconButton



