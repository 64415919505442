import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {prepareAccountingOrdersColumns} from "xpool-gui-common/features/order/orderFunctions";
import {accountingOrdersListDataSelector} from "xpool-gui-common/features/order/orderSelectors";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

const ordersHiddenState = {
    leadText          : false,
    cpAccountName     : false,
    id                : true,
    orderType         : false,
    displayStateT     : false,
    currencyPairStr   : true,
    displayRate       : true,
    externalReference : true,
    modifiedDateTime  : true,
    createdDateTime   : true,
    executionDateTime  : true,
    settlementDate    : true,
    amount            : false,
};
function notSelectedAndStateEquals(data, state) {
    if (data.isSelectedInCashPool) return false;
    if (!data.execution) return false;
    return (data.execution.state === state);
}

function AccountingOrdersList (props) {
    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;
    const dispatch = useDispatch();

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((data  ) => {
            if (props.itemSelectedCallback) props.itemSelectedCallback(data);
            dispatch(xpGridSetSelected('cashPoolOrderList', data?.id));
        }, []),
        gridContextMenuItems    : useCallback((params) => {}, []),
        getRowClass             : useCallback((params) => {
            if (params.data.id === "in") {
                return 'xp-grid-ingoing-balance-row';
            }
            if (params.data.id === "out") {
                return 'xp-grid-outgoing-balance-row';
            }
        }, []),
    }

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('ordersList.noOrdersToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? false : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                rowDataSelector         : accountingOrdersListDataSelector,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'Order',
                defaultColDef           : {
                    sortable    : false,
                    filter      : false,
                },
                rowClassRules           : {
                    'xp-grid-ingoing-outgoing-balance-row': params => ['in', 'out'].includes(params.data.id),
                    'xp-grid-inBalance-order-row': params =>
                    {
                        return notSelectedAndStateEquals(params.data, "IN_BALANCE")
                    },
                    'xp-grid-executed-order-row' : params => notSelectedAndStateEquals(params.data, "EXECUTED"),
                    'xp-grid-rejected-order-row' : params => notSelectedAndStateEquals(params.data, "REJECTED"),
                    'xp-grid-onHold-order-row'   : params => notSelectedAndStateEquals(params.data, "ON_HOLD" ),
                    'xp-grid-selected-order-row' : params => params.data.isSelectedInCashPool
                }

            };
        },
        [translate, props.rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareAccountingOrdersColumns(translate, props.hiddenState ? props.hiddenState : ordersHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={"ordersList" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}

export default AccountingOrdersList
