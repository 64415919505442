import XpGridTranslatedContent from "gui-common/xpGrid/cellRenderers/XpGridTranslatedContent";
import XpGridHeader from "gui-common/xpGrid/XpGridHeader2";
import XpGridGroupHeader from "gui-common/xpGrid/XpGridGroupHeader2";
import XpGridUserRenderer from "gui-common/xpGrid/cellRenderers/XpGridUserRenderer2";
import XpGridDetailCellRenderer from "gui-common/xpGrid/cellRenderers/XpGridDetailCellRenderer";
import XpGridDateTimeRenderer from "gui-common/xpGrid/cellRenderers/XpGridDateTimeRenderer";
import XpGridNumberRenderer from "gui-common/xpGrid/cellRenderers/XpGridNumberRenderer";
import XpGridShortDateHeader from "gui-common/xpGrid/XpGridShortDateHeader";
import XpGridFormInputEditor from "gui-common/xpGrid/cellRenderers/XpGridFormInputEditor";
import XpGridFormInputRenderer from "gui-common/xpGrid/cellRenderers/XpGridFormInputRenderer";
import XpGridObjectPropertyRenderer from "gui-common/xpGrid/cellRenderers/XpGridObjectPropertyRenderer";
import {CheckboxRenderer} from "gui-common/xpGrid/cellRenderers/CheckboxRenderer";
import {CheckboxEditor} from "gui-common/xpGrid/cellRenderers/CheckboxEditor";


export const xpGridCellRenderers = {
    xpTranslatedRenderer     : {
        component: XpGridTranslatedContent,
    },
    agColumnHeader           : {
        component: XpGridHeader,
    },
    agColumnGroupHeader      : {
        component: XpGridGroupHeader,
    },
    xpGridUserRenderer       : {
        component: XpGridUserRenderer,
    },
    xpGridDetailCellRenderer : {
        component: XpGridDetailCellRenderer,
    },
    xpGridDateTimeRenderer   : {
        component: XpGridDateTimeRenderer,
    },
    xpGridNumberRenderer     : {
        component: XpGridNumberRenderer,
        colDefProps: {filter: false}
    },
    xpGridShortDateHeader    : {
        component: XpGridShortDateHeader,
    },
    xpGridFormInputEditor    : {
        component: XpGridFormInputEditor,
    },
    xpGridFormInputRenderer  : {
        component: XpGridFormInputRenderer,
    },
    checkboxRenderer  : {
        component: CheckboxRenderer,
    },
    checkboxEditor  : {
        component: CheckboxEditor,
    },
    xpGridObjectPropertyRenderer: {
        component: XpGridObjectPropertyRenderer,
        colDefProps: {filter: false, cellStyle: {padding: '0 !important'}, suppressCellFlash: true}
    },
}

export const xpGridCellRenderersComponentMap = (() => {
    let returnMap = {}
    Object.keys(xpGridCellRenderers).forEach(key => returnMap[key] = xpGridCellRenderers[key].component)
    return returnMap
})();


