import React, {useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import AdminEntityLevel from 'gui-common/adminUserRights/AdminEntityLevel'
import 'gui-common/adminUserRights/adminUserRights.css'
import {
    getDataRightsCollectionSelector,
    getModifiedDataRightsCollectionSelector
} from "gui-common/adminUserRights/adminUserRightsSelectors";
import PropTypes from "prop-types";
import SimpleUserRightsAssignment from "gui-common/adminUserRights/SimpleUserRightsAssignment";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {SetViewCheckbox} from "gui-common/adminUserRights/SetViewCheckbox";


export function AuditUserDataRights(props) {

    const devMode   = useSelector(state => state.appEnvState.devMode);
    const userRightsConfig = useSelector( state => selectAppEnvProperty(state, 'userRightsConfig'));
    const [simpleMode    , setSimpleMode]     = useState(userRightsConfig.simpleAssignment);

    if (!props.currentData      ||!props.currentData.user     ) return null;
    if (!props.beforeChangeData ||!props.beforeChangeData.user) return null;
    return (
        <div>
            {(devMode && (props.simpleMode === undefined)) &&
            <div>
                <SetViewCheckbox
                    viewState={simpleMode}
                    setState={setSimpleMode}
                    label='Simple mode'
                />
            </div>}
            <AdminDataRightsCollection
                currentData         = {props.currentData}
                beforeChangeData    = {props.beforeChangeData}
                auditMode           = {props.auditMode}
                simpleMode          = {(props.simpleMode === undefined) ? simpleMode : props.simpleMode}
                compressedMode      = {false}
                readOnly            = {true }
            />
        </div>
    );
}
function AdminDataRightsCollection(props) {
    const dataRightsCollectionSelector = useMemo(() => getDataRightsCollectionSelector()    , []);
    const dataRightsCollection       = useSelector(state => dataRightsCollectionSelector(state, {selectId: props.dataRightsCollectionId      , auditMode: props.auditMode, dataRightsCollection: props.currentData}));
    const activeDataRightsCollection = useSelector(state => dataRightsCollectionSelector(state, {selectId: props.activeDataRightsCollectionId, auditMode: props.auditMode, dataRightsCollection: props.beforeChangeData}));

    const modifiedDataRightsCollectionSelector = useMemo(() => getModifiedDataRightsCollectionSelector(), []);
    const modifiedDataRightsCollection  = useSelector(state => modifiedDataRightsCollectionSelector(state, {selectId: props.dataRightsCollectionId}));

    if (!dataRightsCollection) return null;

    const AdminComponent = props.simpleMode ? SimpleUserRightsAssignment : AdminEntityLevel;
    const dataRightsCollectionToRender = (modifiedDataRightsCollection && !props.auditMode) ? modifiedDataRightsCollection : dataRightsCollection;

    // This is needed since after-data might have an empty assigned array.
    if (props.auditMode && activeDataRightsCollection) {
        for (const entityPrototype of activeDataRightsCollection.includedEntityPrototypes) {
            if (!dataRightsCollectionToRender.includedEntityPrototypes.includes(entityPrototype)) dataRightsCollectionToRender.includedEntityPrototypes.push(entityPrototype);
        }
    }

    if (!dataRightsCollectionToRender.includedEntityPrototypes || !dataRightsCollectionToRender.includedEntityPrototypes.length) {
        if (props.readOnly && props.parentInstance) return null;
        if (!props.readOnly && props.parentInstance) return (<h4><XpTranslated trKey="adminUserRights.noAssignableDataRightsForParent" trParams={{parentName: props.parentInstance.entity.name}}/></h4>)
        if (props.readOnly) return (<h4><XpTranslated trKey="adminUserRights.noAssignedDataRights"/></h4>)

        return (<h4><XpTranslated trKey="adminUserRights.noAssignableDataRightsForUser"/></h4>)
    }

    return (
        <div>
            {dataRightsCollectionToRender.includedEntityPrototypes.map(entityPrototypeName => {return (
                <div key = {entityPrototypeName}>
                    <AdminComponent
                        dataRightsCollection        = {dataRightsCollectionToRender}
                        activeDataRightsCollection  = {activeDataRightsCollection  }
                        entityPrototypeName         = {entityPrototypeName}
                        parentInstance              = {props.parentInstance}
                        compressedMode              = {props.compressedMode}
                        auditMode                   = {props.auditMode}
                        readOnly                    = {props.readOnly}
                        isRemoved                   = {props.isRemoved}
                    />
                </div>
            )})}
        </div>
    );
}
AdminDataRightsCollection.propTypes = {
    dataRightsCollectionId : PropTypes.number,
    simpleMode             : PropTypes.bool,
};

export default AdminDataRightsCollection;
