import React from 'react';
import ReactModal from "react-modal";
import PropTypes from 'prop-types';
import FocusLock from "react-focus-lock";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import {useXpTranslatedLanguages, useXpTrKeys} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


function addTextToStructure(keyString, trObject, textStruct, languages) {
    const dotIndex = keyString.indexOf('.');
    if (dotIndex === -1) { // last key in string, add langObject to this level in the textStruct
        textStruct[keyString] = languages.map(lang => trObject[lang.code] ? trObject[lang.code][0].text : "No text defined");
        return;
    }
    const thisLevelKey       = keyString.slice(0, dotIndex);
    const remainingKeyString = keyString.slice(dotIndex+1);
    if (!textStruct[thisLevelKey]) textStruct[thisLevelKey] = {};
    addTextToStructure(remainingKeyString, trObject, textStruct[thisLevelKey], languages)
}

function generateTextStructure(translations, type, languages) {
    if (!translations) return "";

    let newTextStructure = {};
    for (let key in translations) {
        if ((type === 'new')    && !translations[key].isNewKey) continue;
        if ((type === 'edited') &&  translations[key].isNewKey) continue;
        let anyEdit = false;
        for (const lang of languages) {
            if (!translations[key][lang.code]) continue;
            if (translations[key][lang.code].length > 1) {
                anyEdit = true;
            }
        }
        if ((type === 'edited') && !anyEdit) continue;
        addTextToStructure(key, translations[key], newTextStructure, languages);
    }
    console.log("Text structure", newTextStructure);

    return newTextStructure;
}

function generateJsonFromTextStructure(textStructure, indent) {
    if (!textStructure) return "";
    let returnText = "";
    if (Array.isArray(textStructure)) { // Last level in hierarchy
        returnText  += "[\n";
        for (let text of textStructure) returnText  += indent + '   "' + text + '",\n';
        returnText = returnText.substring(0, returnText.length-2) + "\n"
        returnText  += indent + "]";
        return returnText;
    }
    returnText += "{\n";
    for (let key in textStructure) {
        returnText += indent + '   "' + key + '": ' + generateJsonFromTextStructure(textStructure[key], indent + "   ") + ',\n';
    }
    if (returnText.length < 3) return "";
    returnText = returnText.substring(0, returnText.length-2) + "\n" + indent + "}";
    return returnText;
}

export function ModalShowNewTranslations ({resultTypes: { MODAL_TYPE_CONFIRM }, popModalWindow, type})  {

    const translations    = useXpTrKeys();
    const languages       = useXpTranslatedLanguages()

    function confirmAction() {
        popModalWindow({ status: MODAL_TYPE_CONFIRM});
    }

    const textStructure = generateTextStructure(translations, type, languages);

    const textToDisplay = generateJsonFromTextStructure(textStructure, "");

    const showConfirm = <XpTranslated trKey={'general.buttons.confirm'}/>;

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalLangEdit"}};

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Save settings dialog"
                    onRequestClose={() => confirmAction()}
                    shouldCloseOnEsc={true}
                    {...modalParams}
                >
                    <div xp-test-id="xpModalShowNewTranslations">
                        <h3 className ="xpoolModalHeader">
                            {(type === 'new')    && <XpTranslated trKey='appLangTextGenerationsList.showNewTranslationsHeader'/>}
                            {(type === 'edited') && <XpTranslated trKey='appLangTextGenerationsList.showEditedTranslationsHeader'/>}
                        </h3>
                        <hr/>
                        <p className="valueTextDark" style={{whiteSpace: 'pre-wrap'}}>
                            {textToDisplay}
                        </p>
                        <hr/>
                        <div className="xpoolModalButtonsCenter">
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonConfirm"}
                                label           = {showConfirm}
                                onClickCallback = {() => confirmAction()}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}


ModalShowNewTranslations.propTypes = {
    popModalWindow  : PropTypes.func.isRequired,
    textGenerations : PropTypes.object,
};
