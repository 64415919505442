import {reportDataLoadRequested, reportDataLoadCancelled, reportDataReceived} from "gui-common/report/reportReducer";
import {genericFetch} from "gui-common/api/apiCommon";
import {apiBaseUrlMap}    from "appConfig/api/apiConfig";

/* -----------------------------------------------------------------------------------------------------------------
* Functions to get report data from BE including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getReportSetFromApi(dataSetType, params) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get ReportSet " + dataSetType;
            let requestUrl = apiBaseUrlMap.reportSet + "/" + dataSetType + "?";
            for (let key in params) {
                requestUrl = requestUrl + key + "=" + params[key] + "&";
            }
            dispatch(reportDataLoadRequested());
            dispatch(genericFetch(
                requestUrl,
                logText,
                getResponseHandler(dataSetType),
                getFailHandler,
                getFailHandler,
                'GET',
                undefined, // no body in http request needed for GET.
                undefined
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getResponseHandler(dataSetType) {
    return (resp) => {
        return (dispatch, getState) => {
            dispatch(reportDataReceived(resp, dataSetType));
        }
    }
}
function getFailHandler(resp) {
    return (dispatch, getState) => {
        dispatch(reportDataLoadCancelled());
    }
}
