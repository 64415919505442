import {apiBaseUrlMap, ormApiBaseUrlMap} from "appConfig/api/apiConfig";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {genericFetch} from "gui-common/api/apiCommon";
import {ormEntityUpdateStarted} from "gui-common/orm/ormReducer";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";

export function registerBalanceMovementToApi(account, movement, type) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Register balance movement " + movement.amount + " on bank bankAccount " + account.id;
            let requestUrl = ormApiBaseUrlMap.BankAccount + "/" + account.id + "/register-erp-transaction?";
            const decDenLangState = selectDecDenLangState(getState());
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => { return (dispatch, getState) =>
                    dispatch(ormEntityUpdateStarted('BalanceMovement', {id: account.id}));
                },
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'POST',
                {
                    amount: stringNumberToFloat(movement.amount, decDenLangState),
                    type: type,
                    valueDate: movement.valueDate,
                    comment: movement.comment,
                }
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function reverseBalanceMovementToApi(movement, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Reverse balance movement id " + movement.id + " with amount " + movement.amount;
            let requestUrl = apiBaseUrlMap.reverseBalanceMovement + "/" + movement.id + '?';
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => { return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'POST',
                {comment: comment}
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function hedgeBalanceMovementToApi(movement, hedgePercent, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Hedge balance movement id " + movement.id + " with percent " + hedgePercent;
            let requestUrl = apiBaseUrlMap.hedgeBalanceMovement + "/" + movement.id + '?';
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => { return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'POST',
                {hedgePercent: Number(hedgePercent), comment:comment}
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
