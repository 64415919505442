
import {canLegalEntityBeDisabled} from "xpool-gui-common/features/legalEntity/legalEntityFunctions";
import {
    createStandardAuditFields2,
    xpColCreate,
    xpColHeadCreate
} from "gui-common/xpGrid/xpGridFunctions";
import {workflowConfig} from "appConfig/runState/runStateConstants";
import {clientRunStates} from "xpool-gui-common/features/client/clientConstants";
import {calculateDisplayString} from "gui-common/functions/functions";


/*
export const disableClientCancelResumeMenu = (client) => {
    if (!client.cashPoolsRunState) return true;

    const allowCancel   = workflowConfig.ClientWorkflow.allowCancel(client.cashPoolsRunState)
    if (allowCancel) return false

    const allowActivate = workflowConfig.ClientWorkflow.allowActivate(client.cashPoolsRunState)
    return !allowActivate;
};
*/

/*
export const getClientRunStateInfo = (client) => {

    let runStateTrKey = "clientRunState." +  client.cashPoolsRunState;
    if (client.cashPoolsRunStateParams && client.cashPoolsRunStateParams.reason) runStateTrKey += "_" + client.cashPoolsRunStateParams.reason;

    let returnObject = {
        stateKey      : !client.cashPoolsRunState ? 'clientRunState.MANUAL' : runStateTrKey,
        messageTime   : (client.cashPoolsRunStateParams) ? client.cashPoolsRunStateParams.nextRunTime : "",
        stateColor    : client.cashPoolsRunState ? workflowConfig.ClientWorkflow.getStatusBackgroundColor(client.cashPoolsRunState) : 'var(--xpool-client-run-status-disabled-background-color)',
        isCancelled   : (client.cashPoolsRunState === 'CANCELLED'),
        allDone       : (client.cashPoolsRunState === 'COMPLETED'),
        beforeProcess : (client.cashPoolsRunState === 'INITIAL'),
        noRunToday    : !client.cashPoolsRunState,
        noRunState    : !client.cashPoolsRunState
    }
    return returnObject
};
*/


export const getExtendedClientRunStateInfo = (client, accounts, currentLanguage) => {

    // if (!accounts || !accounts.length || !client.extendedCashPoolsRunState) return getClientRunStateInfo(client);

    let messageAccounts = [];
    let nextRunTimeText = "";
    let cashPoolsRunStateWithReason = client.cashPoolsRunState ? client.cashPoolsRunState : 'NO_RUN_STATE';
    const showCounter = !['NO_RUN_STATE', 'NOT_SCHEDULED', 'RESTRUCTURING', 'NO_RUN_DAY', 'CANCELLED', 'COMPLETED', 'FAILED'].includes(cashPoolsRunStateWithReason);

    if (client.cashPoolsRunStateParams?.reason) cashPoolsRunStateWithReason += "_" + client.cashPoolsRunStateParams.reason;

    nextRunTimeText = calculateDisplayString(client?.cashPoolsRunStateParams?.nextRunDateTime, 'relative', currentLanguage);
    accounts.forEach(account => {
        if (account.barRunState !== client.cashPoolsRunState) return;
        if (account.barRunStateParams?.nextRunDateTime !== client?.cashPoolsRunStateParams?.nextRunDateTime) return;
        messageAccounts.push(account);
    })

    if (['NO_RUN_DAY', 'CANCELLED'].includes(client.cashPoolsRunState) && !client.cashPoolsRunStateParams?.nextRunDateTime) {
        cashPoolsRunStateWithReason = "NO_RUN_THIS_MONTH";
    }

    if (cashPoolsRunStateWithReason === 'COMPLETED_PARTIAL') {
        messageAccounts = [];
        accounts.forEach(account => {
            if (account.barRunState === 'COMPLETED') return;
            messageAccounts.push(account);
        })
    }

    if (cashPoolsRunStateWithReason === 'FAILED_BALANCE') {
        messageAccounts = [];
        accounts.forEach(account => {
            if (account.barRunState !== 'FAILED') return;
            if (!['BALANCE', 'REBALANCER_DOWN', 'CLOSED', 'BALANCE_STOPPED_TRYING', 'FAILED_BALANCE_REQUEST'].includes(account.barRunStateParams?.reason)) return;
            // if (bankAccount.barRunStateParams?.reason !== 'BALANCE') return;
            messageAccounts.push(account);
        })
    }

    if (cashPoolsRunStateWithReason === 'FAILED_EXECUTION') {
        messageAccounts = [];
        accounts.forEach(account => {
            if (account.barRunState !== 'FAILED') return;
            if (!['EXECUTION', 'REBALANCER_DOWN', 'CLOSED', 'EXECUTION_TIMEOUT', 'EXECUTION_FAILED', 'EXECUTION_STOPPED_TRYING'].includes(account.barRunStateParams?.reason)) return;
            // if (bankAccount.barRunStateParams?.reason !== 'EXECUTION') return;
            messageAccounts.push(account);
        })
    }

    return {
        messageKey         : 'runPoolButton.runPoolStateMessage.' + cashPoolsRunStateWithReason,
        messageTime        : nextRunTimeText,
        rawMessageTime     : client?.cashPoolsRunStateParams?.nextRunDateTime,
        messageAccounts    : messageAccounts,
        showCounter        : showCounter,
    };
};

export function canClientBeDisabled(client) {

    let returnObject = {
        canBeDisabled: true,
        modalKey: 'clientForm.modalConfirmDisableClient',
        modalParams: {name: client.name}
    };
    // Base case: Empty client!
    if (!client.legalEntities || (client.legalEntities.length === 0)) return returnObject;

    // Nested case. Check next level!
    if (!client.useLegalEntities && (client.legalEntities.length === 1)) {
        const disableParams = canLegalEntityBeDisabled(client.legalEntities[0], true);
        if (disableParams.canBeDisabled) {
            return returnObject;
        } else {
            return {canBeDisabled: false, modalKey: disableParams.modalKey, modalParams: disableParams.modalParams};
        }
    }

    // default case. There are multiple LEs under the client.
    let childrenNames = "";
    for (let key in client.legalEntities) {
        childrenNames = childrenNames + "- " + client.legalEntities[key].name + "\n";
    }
    returnObject.modalKey = 'clientForm.modalClientHasEntities';
    returnObject.modalParams = {name: client.name, children: childrenNames};
    returnObject.canBeDisabled = false;

    return returnObject;
}


export function prepareClientsGridColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState,'name', 120, 50, 'clientForm.name.label', {cellRenderer: 'agGroupCellRenderer', sort: 'desc', lockVisible: true, lockPosition: true});
    xpColCreate(translate, subArray, hiddenState,'id', 100, 50, 'clientForm.id.label');
    xpColCreate(translate, subArray, hiddenState,'description', 200, 50, 'clientForm.description.label');

    xpColCreate(translate, subArray, hiddenState,'accountManager', 200, 50, 'clientForm.accountManager.label');
    xpColCreate(translate, subArray, hiddenState,'clientRefId'   , 200, 50, 'clientForm.clientRefId.label');
    xpColCreate(translate, subArray, hiddenState,'operatingUnitT', 200, 50, 'clientForm.operatingUnitId.label');
    xpColCreate(translate, subArray, hiddenState,'activeOrderCount', 200, 50, 'clientsList.activeOrderCount', {cellStyle: {textAlign: "right"}});

    xpColCreate(translate, subArray, hiddenState,'timeZone'      , 200, 50, 'clientForm.timeZone.label');
    xpColCreate(translate, subArray, hiddenState,'getBalancesTimeT', 100, 50, 'runConfiguration.runTimesTable.getBalancesTime.label', {tooltipField: 'getBalancesTimeT'});
    xpColCreate(translate, subArray, hiddenState,'executeTimeT'    , 100, 50, 'runConfiguration.runTimesTable.executeTime.label', {tooltipField: 'executeTimeT'});
    xpColCreate(translate, subArray, hiddenState,'stopTryingTimeT' , 100, 50, 'runConfiguration.runTimesTable.stopTryingTime.label', {tooltipField: 'stopTryingTimeT'});
    xpColCreate(translate, subArray, hiddenState,'erpStatus'       ,100, 50, 'clientsList.erpStatus', {cellRenderer: 'xpErpStatusRenderer'});
    xpColCreate(translate, subArray, hiddenState,'cashPoolsRunStateT',100, 50, 'clientsList.runPoolProcessState', {
        tooltipField: 'cashPoolsRunStateT',
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: clientRunStates.map(item => 'clientRunState.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true},
        cellStyle: function(params) {
            return {background: workflowConfig.ClientWorkflow.getStatusBackgroundColor(params.data), color: '#434343'};
        }});
    xpColCreate(translate, subArray, hiddenState,'runPoolProcessNextRunTime', 200, 100, 'clientsList.runPoolProcessNextRunTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});

    xpColHeadCreate(translate, outArray, subArray, 'clientsList.clientInformation');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}

