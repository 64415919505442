import {createSelector} from "reselect";
import {activeClientBankAccountsSelector} from "xpool-gui-common/features/client/clientSelectors";

export const balanceAPiSimulatorActiveConfigurationsSelector = createSelector(
    [state => activeClientBankAccountsSelector(state)],
    (activeAccounts) => {
        if (!activeAccounts) return [];

        return activeAccounts.map(account => ({
            bankAccountId: account.id,
        }))
    }
);
