import {
    addUserMessage,
    confirmUserMessage,
    setExitAnimation
} from "gui-common/userMessages/userMessagesReducer"
import {confirmUserMessageToApi, postUserMessageToApi} from "gui-common/userMessages/userMessagesApi"
import {userSelector} from "gui-common/orm/ormSelectors";
import moment from "moment";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {selectUserLoggedInState} from "gui-common/api/apiSelectors";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

/* -----------------------------------------------------------------------------------------------------------------
* Create userMessage and add client data.
* -----------------------------------------------------------------------------------------------------------------*/
export function generateUserMessageThunk(type, messageText, topic) {
    return (dispatch, getState) => {
        let userName = "N/A";
        let userId = null;
        const userLoggedIn = selectUserLoggedInState(getState());
        if (userLoggedIn) {
            let user = userSelector(getState());
            if(user) {
                //userName = user.firstName + ' ' + user.lastName;
                //userId   = user.userId;
            }
        }
        const message = {
            type       : type,
            topic      : topic,
            textPath   : null,
            parameters : {},
            plainText  : messageText.length <= 200 ? messageText : messageText.substring(0, 200),
            userName   : userName,
            userId     : userId,
            createdDateTime : moment(),
        };
        dispatch(postUserMessageToApi({...message, requireConfirmation: true}));
    }
}

export function addUserMessageThunk(type, textPath, parameters, localOnly, client) {
    return (dispatch, getState) => {
        let state = getState();
        let userName   = "N/A";
        let userId     = null;
        const userLoggedIn = selectUserLoggedInState(getState());
        if (userLoggedIn) {
            let user = userSelector(getState());
            if (user) {
                userName = user.firstName + user.lastName;
                userId   = user.userId;
            }
/* // MT 20191002: Removed default client association. Only feed from caller when relevant.
            let ormActiveClient = shallowActiveClientSelector(getState());
            if (ormActiveClient) {
                clientId   = ormActiveClient.id;
                clientName = ormActiveClient.name;
            }
*/
        }
        const translate = selectTranslateFunction(state);
        const plainText = translate(textPath, parameters);
        const message = {
            type       : type      ,
            topic      : "Admin"   ,
            textPath   : textPath  ,
            parameters : {...parameters, clientId: client ? client.id : null},
            plainText  : plainText.length <= 200 ? plainText.length : plainText.substring(0, 200),
            userName   : userName  ,
            userId     : userId    ,
            createdDateTime : moment(),
        };

        //dispatch(addUserMessage('UserMessage', message));

        // localOnly is used to avoid sending user messages for API errors received when creating user messages. Should be confirmed by user.
        // Before user is logged in, user messages must be created without going through API.
        if (localOnly || !userLoggedIn || (state.webSocketState.socketStatus !== "running")) dispatch(addUserMessage('UserMessage', {...message, requireConfirmation: true}));
        // All user messages generated from GUI should not be confirmed by other users.
        // The requireConfirmation flag is set to true in the return response in the api call.
        else           dispatch(postUserMessageToApi({...message, requireConfirmation: false}));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Confirm userMessage and send to API (if not already done in bulk request).
* -----------------------------------------------------------------------------------------------------------------*/
export function confirmUserMessageThunk(message) {
    return (dispatch, getState) => {
        if (!isNaN(message.id) && !message.confirmedDateTime && !message.confirmedToApi) {
            dispatch(confirmUserMessageToApi({id: message.id}));
        }
        dispatch(confirmUserMessage(message));
        // Dispatch confirmation if messageId is not a text, i.e. internal-xx,
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Confirm all un-confirmed userMessages.
* -----------------------------------------------------------------------------------------------------------------*/
export function confirmAllUserMessages() {
    return (dispatch, getState) => {
        const userMessages = getState().userMessagesState;

        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'topMenu.confirmAllUserMessages.confirmationModal'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    let messagesToConfirm = [];
                    for (let userMessage of userMessages) {
                        if (userMessage.confirmedDateTime            ) continue;
                        if (userMessage.requireConfirmation === false) continue;
                        messagesToConfirm.push(userMessage);
                        dispatch(setExitAnimation(userMessage.id, true));
//                        dispatch(confirmUserMessage(userMessage.id, moment()));
                    }
                    if (messagesToConfirm.length === 0) return;

                    dispatch(confirmUserMessageToApi(messagesToConfirm));
                    // dispatch(bulkConfirmUserMessage(messagesToConfirm, moment()));
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }
}

