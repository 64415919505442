import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {shallowActiveClientSelector} from "features/client/clientSelectors";
import {activeClientBankAccountsSelector} from "xpool-gui-common/features/client/clientSelectors";
import {userSelector} from "gui-common/orm/ormSelectors";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {requestRunStatesToApi} from "gui-common/runState/runStateApi";
import moment from "moment";
import {userCanRunPool} from "gui-common/functions/userRights";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {selectActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function CancelResumeRestartRunButton (props) {
    const ormActiveClient = useSelector(shallowActiveClientSelector);
    const ormAccounts     = useSelector(activeClientBankAccountsSelector);
    const currentLanguage = useSelector(selectActiveLanguage);
    const user            = useSelector(userSelector);
    const dispatch        = useDispatch();
    const runStateRequestState = useSelectorInstance(getRequestingStateOnModelSelector, {model: 'RunState'});

    const userCanRunPoolConst = userCanRunPool(ormActiveClient);

    function allBarRunStateMatch(accounts, matchStateArray) {
        if (!accounts?.length) {
            return false;
        }
        return !accounts.some(account => !matchStateArray.includes(account.barRunState))
    }
    function anyBarRunStateMatch(accounts, matchStateArray) {
        if (!accounts?.length) {
            return false;
        }
        return accounts.some(account => matchStateArray.includes(account.barRunState))
    }

    const currentActionProps = useMemo(
        () => {
            let returnValue = {
                labelKey    : 'runPoolButton.cancelAutoRun.label',
                labelParams : {clientName: ormActiveClient.name},
                toolTip     : 'runPoolButton.cancelAutoRun.tooltipAutoRunDisabled',
                action      : "cancel",
                disabled    : true,
            };
            if (!ormActiveClient) return returnValue;

            if (!ormActiveClient || !ormAccounts?.length || !ormActiveClient.cashPoolsRunState) {
                return returnValue;
            }

            const activeAccounts = ormAccounts.filter(account => account.barRunState && !['NO_RUN_DAY', 'AFTER_STOP_TRYING', 'NOT_SCHEDULED'].includes(account.barRunState));
            if (!activeAccounts) {
                return returnValue;
            }

            const tooLateToResume = ormActiveClient.cashPoolsRunStateParams?.latestToResume && moment().isAfter(moment(ormActiveClient.cashPoolsRunStateParams?.latestToResume));
            const tooLateToCancel = ormActiveClient.cashPoolsRunStateParams?.latestToCancel && moment().isAfter(moment(ormActiveClient.cashPoolsRunStateParams?.latestToCancel));

            if (allBarRunStateMatch(activeAccounts,['FAILED']) && !tooLateToResume) {
                returnValue.disabled   = false;
                returnValue.action     = 'restart';
                returnValue.toolTipKey = 'runPoolButton.restartAutoRun.tooltip';
                returnValue.labelKey   = 'runPoolButton.restartAutoRun.label';
            }
            else if (allBarRunStateMatch(activeAccounts, ['COMPLETED', 'FAILED'])) {
                returnValue.toolTipKey = 'runPoolButton.cancelAutoRun.tooltipAfterRun';
            }
            else if (anyBarRunStateMatch(activeAccounts, ['WAIT_FOR_EXECUTION', 'WAIT_FOR_BALANCE'])) {
                returnValue.toolTipKey = 'runPoolButton.cancelAutoRun.tooltipExecutingOrGettingBalance';
            }
            else if (anyBarRunStateMatch(activeAccounts, ['INITIAL', 'WAIT_FOR_RETRY', 'BEFORE_EXECUTION']) && !tooLateToCancel) {
                returnValue.disabled   = false;
                returnValue.toolTipKey = 'runPoolButton.cancelAutoRun.tooltipDuringRun';
            }
            else if (anyBarRunStateMatch(activeAccounts, ['CANCELLED']) && !tooLateToResume) {
                returnValue.disabled   = false;
                returnValue.action     = 'resume';
                returnValue.toolTipKey = 'runPoolButton.resumeAutoRun.tooltip';
                returnValue.labelKey   = 'runPoolButton.resumeAutoRun.label';
            }

            return returnValue;
        },
        [ormActiveClient, ormAccounts]
    );

    function actionAutoRun() {

        const requestsArray = [];
        if (currentActionProps.action === 'cancel') {
            for (let account of ormAccounts) {
                const tooLateToCancel = account.barRunStateParams?.latestToCancel && moment().isAfter(moment(account.barRunStateParams?.latestToCancel));
                if (tooLateToCancel || !['INITIAL', 'WAIT_FOR_RETRY', 'BEFORE_EXECUTION'].includes(account.barRunState)) {
                    continue;
                }
                requestsArray.push({runState: account.barRunStateObject, requestedState: 'CANCELLED', acceptedStates: undefined})
                // dispatch(requestRunStatesToApi(account.barRunStateObject,'CANCELLED'));
            }
        }
        if (currentActionProps.action === 'resume') {
            for (let account of ormAccounts) {
                const tooLateToResume = account.barRunStateParams?.latestToResume && moment().isAfter(moment(account.barRunStateParams?.latestToResume));
                if (tooLateToResume || (account.barRunState !== 'CANCELLED')) {
                    console.log("Account excluded from Resume. ", account.id, tooLateToResume, account.barRunState, account.barRunStateParams?.latestToResume);
                    continue;
                }
                requestsArray.push({runState: account.barRunStateObject, requestedState: 'INITIAL', acceptedStates: ['INITIAL', 'BEFORE_EXECUTION', 'WAIT_FOR_BALANCE']})
                // dispatch(requestRunStatesToApi(account.barRunStateObject, 'INITIAL', ['INITIAL', 'BEFORE_EXECUTION', 'WAIT_FOR_BALANCE']));
            }
        }
        if (currentActionProps.action === 'restart') {
            for (let account of ormAccounts) {
                const tooLateToResume = account.barRunStateParams?.latestToResume && moment().isAfter(moment(account.barRunStateParams?.latestToResume));
                if (tooLateToResume || (account.barRunState !== 'FAILED')) {
                    continue;
                }
                requestsArray.push({runState: account.barRunStateObject, requestedState: 'RESTART', acceptedStates: ['INITIAL', 'BEFORE_EXECUTION']})
                // dispatch(requestRunStatesToApi(account.barRunStateObject, 'RESTART', ['INITIAL', 'BEFORE_EXECUTION']));
            }
        }
        dispatch(requestRunStatesToApi(requestsArray));
    }

    function getAutoRunActionModalKey() {
        switch (currentActionProps.action) {
            case 'cancel' : return 'runPoolButton.cancelAutoRun.confirmModal';
            case 'resume' : return 'runPoolButton.resumeAutoRun.confirmModal';
            case 'restart': return 'runPoolButton.restartAutoRun.confirmModal';
            default       : return '';
        }
    }

    function waitingForRunState() {
        if (!runStateRequestState) return false;
        for (let account of ormAccounts) {
            if (!account.barRunStateObject) continue;
            if (runStateRequestState[account.barRunStateObject.id]) return true;
        }
        return false;
    }

    if (!ormActiveClient || !user) return null;

    moment.locale(currentLanguage);



    if (!userCanRunPoolConst) return null;
    if (!ormAccounts.length) return null;
    return (
        <WaitingXpoolButton
            labelKey        = {currentActionProps.labelKey}
            onClickCallback = {() => actionAutoRun()}
            disabled        = {currentActionProps.disabled}
            waiting         = {waitingForRunState()}
            toolTipKey      = {currentActionProps.toolTipKey}
            modalKey        = {getAutoRunActionModalKey()}
        />
    );
}
export default CancelResumeRestartRunButton



