import React from 'react'
import {useSelector} from "react-redux";
import {userSelector} from "gui-common/orm/ormSelectors"
import UserTopicsAdmin from "gui-common/userSettings/topics/UserTopicsAdmin"
import AdminUserStates from "gui-common/userSettings/sharedSettings/AdminUserStates"
import {EditTextModeSetting} from "gui-common/appLocale/xpTranslated/EditTextModeSetting";
import {userCan} from "gui-common/functions/userRights";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {renderUserProfiles} from "gui-common/user/userFunctions";
import {appUserSettings} from "appConfig/appUserSettings/appUserSettingConstants";


function UserInfo ({user}) {
    return (
        <div className="formCard userPrefsCard" xp-test-id='xpUserInfo'>
            <h3 className="userPrefsCardHeader">
                <XpTranslated trKey={'userPreferencesForm.userInfoHeader'}/>
            </h3>

            <div className="field">
                <label className="inlineLabel">
                    <XpTranslated trKey={'userPreferencesForm.userId.label'}/>
                </label>
                <p className="inlineValueText">{user.id}</p>
            </div>
            <div className="field">
                <label className="inlineLabel">
                    <XpTranslated trKey={'userPreferencesForm.userName'}/>
                </label>
                <p className="inlineValueText">{user.firstName + " " + user.lastName}</p>
            </div>
            <div className="field">
                <label className="">
                    <XpTranslated trKey={'userPreferencesForm.profiles'}/>
                </label>
                <div className="valueText">
                    {renderUserProfiles(user)}
                </div>
            </div>
        </div>
    )
}




function UserPreferences(props) {
    const user = useSelector(userSelector);
    if (!user) return null;

    const userCanUpdateSystemTexts   = userCan('Create', 'SystemText', user);

    return (
        <div>
            <h2>
                <XpTranslated trKey={'userPreferencesForm.header'}/>
                {user.firstName}
            </h2>

            <hr/>

            <div className={"adminFormContainer"}>
                <div className="adminColumn admin3Column">
                    <UserInfo user = {user}/>
                    <div className="formCard userPrefsCard">
                        <UserTopicsAdmin
                            disabled = {false}
                            user     = {user}
                        />
                    </div>
                    {userCanUpdateSystemTexts &&
                    <div className="formCard userPrefsCard">
                        <EditTextModeSetting/>
                    </div>}
                </div>

                <div className="adminColumn admin3Column">
                    <div className="formCard userPrefsCard">
                        <AdminUserStates/>
                    </div>
                </div>
                <div className="adminColumn admin3Column">
                    {appUserSettings.map((setting, index) => (
                        <div className="formCard userPrefsCard" key={index}>
                            {setting}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default UserPreferences;


