import React, { Component } from 'react'
import {contextMenuClickThunk} from "gui-common/contextMenu/contextMenuFunctions";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import XpTooltip from "gui-common/components/XpTooltip";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectActiveLanguage, selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

class IntegrationStatusLight extends Component {

    componentDidMount(){
//        setTimeout(() => {ReactTooltip.rebuild();},100); // MT: Required to get tool-tip to work in the component.
    }

    getToolTip() {
        if (!this.props.active)    return '';
        if (this.props.socketDown) return <span><XpTranslated trKey={'integrationStatus.socketDownTooltip'}/></span>;
        if (!this.props.lastEvent) return <span><XpTranslated trKey={'integrationStatus.noEventAvailableTooltip'} trParams={{componentName: this.props.showName}}/></span>;
        return (
            <span>
                {(this.props.showName) ? <XpTranslated trKey='integrationStatus.itemName' trParams={{componentName: this.props.showName}}/> : ""}
                <XpTranslated trKey='integrationStatus.version' trParams={{version: this.props.lastEvent.componentInformation?.version}}/>
                <XpTranslated trKey='integrationStatus.commit'  trParams={{commit: this.props.lastEvent.componentInformation?.revision}}/>
                <XpTranslated trKey='integrationStatus.itemStatus' trParams={{componentStatus: this.props.lastEvent.systemEventStatusType}}/>
                <XpTranslated trKey='integrationStatus.itemTime' trParams={{statusTime: (this.props.lastEvent.time ? this.props.lastEvent.time.format('YYYY-MM-DD, hh:mm:ss') : "")}}/>
                <XpTranslated trKey={'integrationStatus.itemMessage'}/>
                {this.props.lastEvent.description}
            </span>
/*
            ((this.props.showName) ? this.props.translate('integrationStatus.itemName') + this.props.showName + "\n": "" ) +

            this.props.translate('integrationStatus.itemStatus') +
            this.props.lastEvent.systemEventStatusType + "\n" +

            this.props.translate('integrationStatus.itemTime') +
            (this.props.lastEvent.time ? this.props.lastEvent.time.format('YYYY-MM-DD, hh:mm:ss') : "") + "\n" +
            this.props.translate('integrationStatus.itemMessage') +
            this.props.lastEvent.description
*/
        )
    }

    render () {

        let renderClassName = this.props.isMenuLight ? "menuStatusLight " : "statusLight ";
        if (this.props.socketDown) {
            renderClassName = renderClassName + " statusLightWarning";
        }
        else if (this.props.active && this.props.lastEvent) {
            if (this.props.lastEvent.time)   this.props.lastEvent.time.locale(this.props.currentLanguage);
            if (this.props.lastEvent.systemEventStatusType === "RUNNING")  renderClassName = renderClassName + "statusLightOk";
            if (this.props.lastEvent.systemEventStatusType === "STARTING") renderClassName = renderClassName + "statusLightStarting";
            if (this.props.lastEvent.systemEventStatusType === "WARNING")  renderClassName = renderClassName + "statusLightWarning";
            if (this.props.lastEvent.systemEventStatusType === "ERROR")    renderClassName = renderClassName + "statusLightError";
            if (this.props.lastEvent.systemEventStatusType === "DOWN")     renderClassName = renderClassName + "statusLightDown";
        }

        //        const disableContextMenu = !this.props.active;
        const disableContextMenu = true; // No actions for the moment.
        const renderStyle = {};
        if (this.props.isMenuLight) {
            renderStyle.boxShadow   = 'none';
        }
        return (
            <XpTooltip title={this.getToolTip()}>
                <div
                    onContextMenu={(e) => this.props.contextMenuClickThunk(e, disableContextMenu, "IntegrationStatusItemMenu", {statusItem: this.props.provider})}
                    className={renderClassName}
                    style={renderStyle}
                    xp-test-id='xpIntegrationStatusLight'
                    // data-tip={this.getToolTip()}
                >
                </div>
            </XpTooltip>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        translate:            selectTranslateFunction(state),
        currentLanguage:      selectActiveLanguage(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        contextMenuClickThunk: (e, noShow, contextMenuId, parameters) => dispatch(contextMenuClickThunk(e, noShow, contextMenuId, parameters))
    };
};

const ConnectedIntegrationStatusLight = connect(mapStateToProps, mapDispatchToProps)(IntegrationStatusLight);

ConnectedIntegrationStatusLight.propTypes = {
    active:               PropTypes.bool.isRequired,
    lastEvent:            PropTypes.object,
    noShadow:             PropTypes.bool,
    showName:             PropTypes.string,
    socketDown:           PropTypes.bool,
};

ConnectedIntegrationStatusLight.defaultProps = {
};

export default ConnectedIntegrationStatusLight;
