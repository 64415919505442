import {createReducer} from 'gui-common/functions/reducer.js'

import {USER_LOGOUT} from "gui-common/api/webSessionReducer";


export const DATA_LOADING_MESSAGE = 'DATA_LOADING_MESSAGE';
export const USER_LOGIN_SUCCESS   = 'USER_LOGIN_SUCCESS';
export const SET_APP_READY_STATE  = 'SET_APP_READY_STATE';

// Action creators ********************************************************
// ************************************************************************
export function setLoginMessage(message) {
    return { type: DATA_LOADING_MESSAGE,        payload: message};
}
export function setAppReadyState(readyState) {
    return { type: SET_APP_READY_STATE,  payload: readyState};
}
export function userLoginSuccess() {
    return { type: USER_LOGIN_SUCCESS,      payload: undefined};
}

// Reducer functions ********************************************************
// ************************************************************************
function setMessage(state, payload) {
    return {...state, loadingText: payload};
}
function loginSuccess(state) {
    return {
        ...state,
        userLoggedIn: true,
        loginReceived: true,
        appReady: true
    };
}

function setAppState(state, payload) {
    return {...state, appReady: payload};
}

function userLogout() {
    return initialState;
}

const initialState = {
    loadingText     : "",
    userLoggedIn    : false,
    loginReceived   : false,
    appReady        : false
};

export default createReducer(initialState, {
    [DATA_LOADING_MESSAGE]  : setMessage,
    [USER_LOGIN_SUCCESS]    : loginSuccess,
    [SET_APP_READY_STATE]   : setAppState,
    [USER_LOGOUT]           : userLogout,
});
