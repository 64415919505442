import {RegisterBalanceModal} from "features/bankAccountBalance/RegisterBalanceModal";
import {RegisterBalanceMovementModal} from "xpool-gui-common/features/balanceMovement/RegisterBalanceMovementModal";
import {HedgeBalanceMovementModal} from "xpool-gui-common/features/balanceMovement/HedgeBalanceMovementModal";
import {OrderEntryModal} from "xpool-gui-common/features/order/OrderEntryModal";

export const REGISTER_BALANCE_DIALOG = 'REGISTER_BALANCE_DIALOG';
export const REGISTER_BALANCE_MOVEMENT_DIALOG = 'REGISTER_BALANCE_MOVEMENT_DIALOG';
export const HEDGE_BALANCE_MOVEMENT_DIALOG = 'HEDGE_BALANCE_MOVEMENT_DIALOG';
export const ORDER_ENTRY_DIALOG = 'ORDER_ENTRY_DIALOG';

export const appModalsMap = new Map([
    [REGISTER_BALANCE_DIALOG , RegisterBalanceModal],
    [REGISTER_BALANCE_MOVEMENT_DIALOG, RegisterBalanceMovementModal],
    [HEDGE_BALANCE_MOVEMENT_DIALOG, HedgeBalanceMovementModal],
    [ORDER_ENTRY_DIALOG, OrderEntryModal]
]);
