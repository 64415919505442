import {setAmountDecimals, setAmountDenominator} from "gui-common/numberFormat/numberFormatReducer";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {reformatNumberFieldsOnChangedRules} from "gui-common/xpForm/formNumberFieldsFunctions";


function getNumberSuffix(denominator) {
    if (denominator === 1) return '';
    else if (denominator === 1000) return '\'';
    else if (denominator === 1000000) return '\'\'';
    return '?';
}

function getFormattingLocale(locale) {
    if (locale === 'sv') return 'sv-SE';
    if (locale === 'en') return 'en-IV';
    return 'en-IV';
}

function getDecimalSymbol(locale) {
    if (locale === 'sv') return ',';
    if (locale === 'en') return '.';
    return '.';
}

export const decimalNumberParser = (val, locale) => {
    if (val === undefined) return "";
    let outString;
    let parseStr = String(val);
    const decSym = getDecimalSymbol(locale);
    if (decSym === ',') outString = parseStr.replace(/[^0-9,]/g, '');
    else                outString = parseStr.replace(/[^0-9.]/g, '');

    const decSymPos = outString.indexOf(decSym);
    if (decSymPos === -1) return outString;
    return outString.substring(0,decSymPos+1) + outString.substring(decSymPos+1).replace(/[^0-9]/g, '');
};

export function speedFormatAmount(amount, decDenLangState, formatter) {
    if (amount === undefined) return "";
    if (amount === null) return "";
    if (amount === "") return "";
    if (isNaN(amount)) return amount;

    let suffix = getNumberSuffix(decDenLangState.amountDenominator);

    let denominatedValue = amount / decDenLangState.amountDenominator;
    let decimalsCompensated = denominatedValue * Math.pow(10, decDenLangState.amountDecimals);

    if ((decimalsCompensated < 1) && (decimalsCompensated > -1)) {
        let lessThanOneNumber = formatter.format((1 / Math.pow(10, decDenLangState.amountDecimals)));
        if (decimalsCompensated > 0) return ('<' + lessThanOneNumber + suffix);
        if (decimalsCompensated < 0) return ('-<' + lessThanOneNumber + suffix);
    }
    return formatter.format(denominatedValue) + suffix;
}

export function getFormatter(decDenLangState) {
    let localeString = getFormattingLocale(decDenLangState.activeLanguage);
    return new Intl.NumberFormat(localeString, {
        minimumFractionDigits: decDenLangState.amountDecimals,
        maximumFractionDigits: decDenLangState.amountDecimals
    });
}

export function formatAmount(amount, decDenLangState) {
    const formatter = getFormatter(decDenLangState);
    const outString = speedFormatAmount(amount, decDenLangState, formatter);
    return outString;
}

function fixUnicodeMinus(string) {
    for (let i = 0; i < string.length; i++) {
        if (string.charCodeAt(i) === 8722) {
            string = string.substring(0, i) + '-' + string.substring(i + 1)
        }
    }
    return string;
}

function stripStringFromNonNumerics(amount, decimalSymbol) {
    let outString;
    amount = fixUnicodeMinus(amount);
    if (decimalSymbol === ',') {
        outString = amount.replace(/[^0-9,-]/g, '');
    } else {
        outString = amount.replace(/[^0-9.-]/g, '');
    }
    outString = outString.replace(/,/g, '.');
    return outString;
}

function formatInputAmount(amount, decDenLangState) {

    if (amount === 0) return "0";
    if (amount === undefined) return "";
    if (amount === null) return "";
    if (isNaN(amount)) return amount;

    let denominatedValue = amount / decDenLangState.amountDenominator;

    let localeString = getFormattingLocale(decDenLangState.activeLanguage);

    return denominatedValue.toLocaleString(localeString, {
        minimumFractionDigits: decDenLangState.amountDecimals,
        maximumFractionDigits: decDenLangState.amountDecimals
    });
}

export function parseAndCleanInput(amount, decDenLangState) {

    if (amount === undefined) return "";
    if (amount === null) return "";
    let outString;
    if (!amount.substring) {
        outString = formatInputAmount(amount, decDenLangState);
        return outString;
    }
    amount = fixUnicodeMinus(amount);
    // for (let i=0;i<amount.length;i++) {
    //     if (amount.charCodeAt(i) === 8722) amount = replaceAt(amount, i, '-');
    // }

    // outString = amount.replace(/[^0-9,'\s\x2D]/g, '');

    if (decDenLangState.activeLanguage === 'sv') outString = amount.replace(/[^0-9,'\s-]/g, '');
    else outString = amount.replace(/[^0-9,.'\s-]/g, '');
    // console.log("inText: " + amount + " outText: " + outString + " ascii:" + amount.charCodeAt(0) +" "+amount.charCodeAt(1));
    // if (lang === 'sv') outString = amount.replace(/(?!-)[^0-9,'\s-]/g, '');
    // else               outString = amount.replace(/(?!-)[^0-9,\.'\s-]/g, '');
    // else               outString = amount.replace(/(?!-)[^0-9\s,.-/']/g, '');
    // if (lang === 'sv') outString = amount.replace(/(?!-)[^0-9\s,-/']/g, '');

    // console.log("before strip: " + amount + " after strip: " + outString + " Lang: " + lang);
    // console.log("before update model " +outString);
    // updateModel(model, outString);
    // console.log("after update model");

    return outString;
}

export function rateInputParser(rate, maxDecimals) {
    if (rate === undefined) return "";
    if (rate === null) return "";
    const stage1 = String(rate).replace(/[^0-9.]/g, '');
    const strArray = stage1.split('.');
    if (strArray.length === 1) {
        return stage1;
    }
    strArray[1] = String(strArray[1]).replace(/[^0-9]/g, '');
    if ((maxDecimals !== undefined) && (strArray[1].length > maxDecimals)) {
        strArray[1] = strArray[1].substring(0, maxDecimals);
    }
    return strArray[0] + '.' + strArray[1];
}
export function negativeNumberInputParser(rate) {
    if (rate === undefined) return "";
    if (rate === null) return "";
    return String(rate).replace(/[^0-9.-]/g, '');
}

export function stringNumberToFloat(stringNumber, decDenLangState) {

    let exitWithZero = false;
    if (stringNumber === ".") exitWithZero = true;
    if (stringNumber === ",") exitWithZero = true;
    if (stringNumber === "-") exitWithZero = true;
    if (stringNumber === undefined) exitWithZero = true;
    if (stringNumber === null) exitWithZero = true;

    if (exitWithZero === true) {
        console.log("Invalid string number: " + stringNumber + " returned with zero");
        return 0;
    }

    // Check if the variable passed is actually a string. If not, return number.
    if (!stringNumber.substring) {
        if (isNaN(stringNumber)) return stringNumber;
        return stringNumber;
    }

    let decimalSymbol = getDecimalSymbol(decDenLangState.activeLanguage);
    let cleanString = stripStringFromNonNumerics(stringNumber, decimalSymbol);

    if (cleanString === "") return 0;

    let outNumber = parseFloat(cleanString) * decDenLangState.amountDenominator;

    //console.log("Converting string number: " + stringNumber + " to float : " + outNumber);

    return outNumber;
}

export function parseAmountInput(amount, decDenLangState) {

    if (amount === ".") return "";
    if (amount === ",") return "";
    if (amount === "-") return "";
    if (amount === undefined) return "";
    if (amount === null) return "";

    let outString;

    // Check if the number passed is actually a number and therefore should not be parsed but formatted
    if (!amount.substring) {
        outString = formatInputAmount(amount, decDenLangState);
        return outString;
    }

    let decimalSymbol = getDecimalSymbol(decDenLangState.activeLanguage);
    let inString = stripStringFromNonNumerics(amount, decimalSymbol);
    if (inString === "") return "";

    let number = parseFloat(inString);
    let localeString = getFormattingLocale(decDenLangState.activeLanguage);
    let suffix = getNumberSuffix(decDenLangState.amountDenominator);

    outString = number.toLocaleString(localeString, {
        minimumFractionDigits: decDenLangState.amountDecimals,
        maximumFractionDigits: decDenLangState.amountDecimals
    }) + suffix;

    return outString;
}

export function formatAmountForInput(amount, decDenLangState) {

    if (amount === 0) return "0";
    if (amount === undefined) return "";
    if (amount === null) return "";
    if (isNaN(amount)) return amount;

    let suffix = getNumberSuffix(decDenLangState.amountDenominator);

    let denominatedValue = amount / decDenLangState.amountDenominator;

    let localeString = getFormattingLocale(decDenLangState.activeLanguage);

    return denominatedValue.toLocaleString(localeString, {
        minimumFractionDigits: decDenLangState.amountDecimals,
        maximumFractionDigits: decDenLangState.amountDecimals
    }) + suffix;
}

export function getBaseFormattingRules(decDenLangState) {
    return {
        newDen: decDenLangState.amountDenominator,
        oldDen: decDenLangState.amountDenominator,
        newDec: decDenLangState.amountDecimals,
        oldDec: decDenLangState.amountDecimals,
        newLan: decDenLangState.activeLanguage,
        oldLan: decDenLangState.activeLanguage,
    }
}
export function setAmountDenominatorThunk(den) {
    return (dispatch, getState) => {
        const decDenLangState = selectDecDenLangState(getState());
        dispatch(setAmountDenominator(den));

        let formattingRules = getBaseFormattingRules(decDenLangState);
        formattingRules.newDen = den;
        dispatch(reformatNumberFieldsOnChangedRules(formattingRules));
    }
}
export function setAmountDecimalsThunk(dec) {
    return (dispatch, getState) => {
        const decDenLangState = selectDecDenLangState(getState());
        dispatch(setAmountDecimals(dec));

        let formattingRules = getBaseFormattingRules(decDenLangState);
        formattingRules.newDec = dec;
        dispatch(reformatNumberFieldsOnChangedRules(formattingRules));
    }
}
export function padNumberStringToTwoDigits(numString) {
    if (numString.length === 1) return "0" + numString;
    if (numString.length === 0) return "00";
    return numString;
}
