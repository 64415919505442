import {createSelector} from "reselect";
import {createSelector as createOrmSelector} from 'redux-orm';
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";



function getEntityPrototypes(assigned, assignable) {
    const includedEntityPrototypes = [];
    if (assigned  ) for (let entityDataRight of assigned  ) if (includedEntityPrototypes.indexOf(entityDataRight.entityPrototypeName) === -1) includedEntityPrototypes.push(entityDataRight.entityPrototypeName);
    if (assignable) for (let entityDataRight of assignable) if (includedEntityPrototypes.indexOf(entityDataRight.entityPrototypeName) === -1) includedEntityPrototypes.push(entityDataRight.entityPrototypeName);
    return includedEntityPrototypes.sort();
}

function buildDataRightsCollection(dataRightsCollectionModel) {
    if (!dataRightsCollectionModel) return;

    const assigned = dataRightsCollectionModel.assigned.orderBy('id', 'asc').toModelArray().map(entityDataRight => entityDataRight.ref);

    const dataRightsCollectionRef = dataRightsCollectionModel.ref;
    const assignable = dataRightsCollectionRef.assignable;

    return {
        ...dataRightsCollectionRef,
        assigned: assigned,
        includedEntityPrototypes: getEntityPrototypes(assigned, assignable),
    };
}

export const getTopDataRightsCollectionsSelector = () => createOrmSelector (
    orm,
    dbStateSelector,
    (state, props) => props ? props.user : undefined,
    (session, user) => {
        if (!user) return [];

        const topDataRightsCollections = session.DataRightsCollection.filter(item => {
            if (!item.user || (item.user.userId !== user.id)) return false;
            if (item.state === 'DISABLED') return false;
            return (item.parentEntityDataRightId === null);
        }).toModelArray();

        if (!topDataRightsCollections || topDataRightsCollections.length === 0) {
            // console.warn("No top data rights found for user! ", user); // Normal case when selecting a user in admin list. We do not load data at start up.
            return [];
        }
        if (topDataRightsCollections.length > 2) {
            console.error("More than two top data right collections found for user! ", topDataRightsCollections, user);
            return [];
        }
        const returnArray = topDataRightsCollections.map(item => buildDataRightsCollection(item))
        return returnArray;
    }
)

export const getDataRightsCollectionSelector = () => createOrmSelector (
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (state, props) => props ? props.auditMode : undefined,
    (state, props) => props ? props.dataRightsCollection : undefined,
    (session, selectId, auditMode, dataRightsCollection) => {
        if (auditMode && dataRightsCollection) return {
            ...dataRightsCollection,
            includedEntityPrototypes: getEntityPrototypes(dataRightsCollection.assigned, dataRightsCollection.assignable),
        };

        if (!selectId) return undefined;

        const dataRightsCollectionModel = session.DataRightsCollection.idExists(selectId) ? session.DataRightsCollection.withId(selectId) : undefined;

        if (!dataRightsCollectionModel) {
            console.log("Data rights collection object not found for id in getDataRightsCollectionSelector: ", selectId);
            return undefined
        }
        const returnObject = buildDataRightsCollection(dataRightsCollectionModel)
        return returnObject;
    }
);

const selectedInstanceId       = (state, props) => state.adminUserRightsState.selectedInstance[props.prototype];
const assignedEntityDataRights = (state, props) => props ? props.assigned : null;
export const getSelectedInstanceSelector = () => createSelector(
    [selectedInstanceId, assignedEntityDataRights],
    (selectedInstanceId, assignedEntityDataRights) => {
        if (!selectedInstanceId || !assignedEntityDataRights ) return undefined;
        return assignedEntityDataRights.find(item => item.id === selectedInstanceId);
    }
);

const selectId                 = (state, props) => props ? props.selectId : null;
const modifiedUserSystemRights = (state, props) => state.adminUserRightsState.modifiedUserSystemRights;
const currentData              = (state, props) => props ? props.currentData : undefined;
export const getModifiedUserSystemRightSelector = () => createSelector(
    [modifiedUserSystemRights, selectId, currentData],
    (modifiedUserSystemRights, selectId, currentData) => {
        if (currentData) return currentData;
        if (!modifiedUserSystemRights || !selectId ) return undefined;
        return modifiedUserSystemRights[selectId];
    }
);

const modifiedEntityDataRights = (state, props) => state.adminUserRightsState.modifiedEntityDataRights;
export const getModifiedEntityDataRightSelector = () => createSelector(
    [modifiedEntityDataRights, selectId],
    (modifiedEntityDataRights, selectId) => {
        if (!modifiedEntityDataRights || !selectId ) return undefined;
        return modifiedEntityDataRights[selectId];
    }
);

const modifiedDataRightsCollections = (state, props) => state.adminUserRightsState.modifiedDataRightsCollections;
export const getModifiedDataRightsCollectionSelector = () => createSelector(
    [modifiedDataRightsCollections, selectId],
    (modifiedDataRightsCollections, selectId) => {
        if (!modifiedDataRightsCollections || !selectId ) return undefined;
        return modifiedDataRightsCollections[selectId];
    }
);


const modifiedUsers = (state, props) => state.adminUserRightsState.modifiedUsers;
export const getModifiedUserSelector = () => createSelector(
    [modifiedUsers, selectId],
    (modifiedUsers, selectId) => {
        if (!modifiedUsers || !selectId ) return undefined;
        return modifiedUsers[selectId];
    }
);




function buildModifiedDataRightsCollection(session, selectId, modifiedDataRightsCollections, modifiedEntityDataRights) {
    let returnObject = {
        dataRightsCollection: modifiedDataRightsCollections[selectId],
        isModified: true
    };
    if (!returnObject.dataRightsCollection) {
        returnObject.dataRightsCollection = buildDataRightsCollection(session.DataRightsCollection.idExists(selectId) ? session.DataRightsCollection.withId(selectId) : undefined);
        returnObject.isModified = false;
    }
    if (!returnObject.dataRightsCollection) {
        // selectId not found in ORM or state.
        console.log("Data rights collection object not found for id in buildModifiedDataRightsCollection: ", selectId);
        return {};
    }


    function buildModifiedEntityDataRight(entityDataRight) {
        if (modifiedEntityDataRights[entityDataRight.id]) returnObject.isModified = true;
        let returnEntityDataRight = modifiedEntityDataRights[entityDataRight.id] ? modifiedEntityDataRights[entityDataRight.id] : entityDataRight;
        if (returnEntityDataRight.childDataRightsCollectionId) {
            let childLevelData = buildModifiedDataRightsCollection(session, returnEntityDataRight.childDataRightsCollectionId, modifiedDataRightsCollections, modifiedEntityDataRights);
            if (childLevelData.isModified) returnObject.isModified = true;
            returnEntityDataRight.childDataRightsCollection = childLevelData.dataRightsCollection;
        }
        return returnEntityDataRight;
    }

    returnObject.dataRightsCollection.assigned   = returnObject.dataRightsCollection.assigned.map(  entityDataRight => buildModifiedEntityDataRight(entityDataRight));
    returnObject.dataRightsCollection.assignable = returnObject.dataRightsCollection.assignable.map(entityDataRight => buildModifiedEntityDataRight(entityDataRight));

    return returnObject;
}


export const getDeepModifiedDataRightsSelector = () => createOrmSelector (
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (state, props) => state.adminUserRightsState.modifiedDataRightsCollections,
    (state, props) => state.adminUserRightsState.modifiedEntityDataRights,
    (session, selectId, modifiedDataRightsCollections, modifiedEntityDataRights) => {
        if (!selectId) return undefined;
        return buildModifiedDataRightsCollection(session, selectId, modifiedDataRightsCollections, modifiedEntityDataRights);
    }
);






















