import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {createSelector} from "redux-orm";
import {entityIsActive} from "gui-common/audit/auditFunctions";

export const getLedgerAccountSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, accountId) => {
        if (!accountId) return undefined;

        const account = getOrmModelObjectById(session, 'LedgerAccount', accountId);
        if (!account) return undefined;

        const bal = account.balances;
        const balRef = account.balances?.toRefArray();


        return {
            ...account.ref,
            hasBalances: !!account.balances?.toRefArray().length,
        };
    }
);
export const getLedgerAccountsArraySelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectArray : undefined,
    (session, selectArray) => {
        if (!selectArray) return undefined;

        const accounts = selectArray.map(id => {
            const account = getOrmModelObjectById(session, 'LedgerAccount', id);
            if (account) return account.ref;
            console.error("Counld not find account id in getLedgerAccountsArraySelector", id);
            return {};
        });
        if (!accounts) return undefined;

        return accounts;
    }
);

export const getLedgerAccountBalanceSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, selectId) => {
        if (!selectId) return undefined;

        const ledgerAccountBalance = getOrmModelObjectById(session, 'LedgerAccountBalance', selectId);
        if (!ledgerAccountBalance) return undefined;

        return ledgerAccountBalance.ref;
    }
);

export const getLedgerAccountBalancesSelector = () => createSelector(
    orm,
    dbStateSelector,
    (state, props) => props.ledgerAccountId,
    (state, props) => props.includeDisabled,
    (state, props) => props.sortOrder,
    (state, props) => props.filterFunction,
    (session, ledgerAccountId, includeDisabled, sortOrder, filterFunction) => {
        const ledgerAccount = session.LedgerAccount.idExists(ledgerAccountId) ? session.LedgerAccount.withId(ledgerAccountId) : undefined;
        if (!ledgerAccount) return undefined;

        const returnArray = [];
        for (const item of ledgerAccount.balances.orderBy(['valueDate', 'id'], [sortOrder, sortOrder]).toRefArray()) {
            if (!includeDisabled && !entityIsActive(item)) continue;
            if (filterFunction && !filterFunction(item)) continue;
            returnArray.push({...item})
        }

/*
        returnArray = ledgerAccount.balances.filter(item => {
            if (!includeDisabled && !entityIsActive(item)) return false;
            if (filterFunction) return filterFunction(item);
            return true;
        }).orderBy(['valueDate', 'id'], [sortOrder, sortOrder]).toRefArray();
*/

        if (returnArray.length) {
            returnArray[0].isCurrent = true;
        }
        // console.log("Selected ledger bankAccount balances:", returnArray);
        return returnArray;
    }
);

export const getLatestLedgerAccountBalanceSelector = () => createSelector(
    orm,
    dbStateSelector,
    (state, props) => props.ledgerAccountId,
    (session, ledgerAccountId) => {
        const ledgerAccount = session.LedgerAccount.idExists(ledgerAccountId) ? session.LedgerAccount.withId(ledgerAccountId) : undefined;
        if (!ledgerAccount) return undefined;
        const balances = ledgerAccount.balances.all().orderBy('id', 'desc').toRefArray();

        // console.log("Selected ledger bankAccount balances:", returnArray);
        return (balances && balances.length) ? balances[0] : undefined;
    }
);
