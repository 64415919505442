import React, {Component} from "react";
import {animateAppContainer} from "gui-common/userMessages/userMessagesFunctions"
import {connect} from "react-redux";
import {confirmUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {setExitAnimation} from "gui-common/userMessages/userMessagesReducer";

class UserMessageConfirmButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        }
    }

    confirmMessage() {
        if (!this.props.data) return;
        if (this.props.data.displayPanel) {
            this.props.exitAnimation(this.props.data.id, false);
            animateAppContainer(this.props.data.id, 'appContainerSlideUp');
        }
        else {
            this.props.confirm(this.props.data);
        }
    }

    refresh(params) {
        if(params.value !== this.state.value) {
            this.setState({
                value: params.value
            })
        }
        return true;
    }

    render () {
        if (this.state.value) return (
            <span>{this.state.value}</span>
        );
        if (!this.props.data.requireConfirmation) return (
            <span> </span>
        );
        return (
            <span>
                <WaitingXpoolButton
                    className       = "xPoolButton xPoolButtonAgGrid"
                    labelKey        = 'userMessages.confirmButton'
                    onClickCallback = {() => this.confirmMessage()}
                />
            </span>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        confirm:        (message)     => dispatch(confirmUserMessageThunk(message)),
        exitAnimation:  (messageId, confirmedToApi)   => dispatch(setExitAnimation(messageId, confirmedToApi)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(UserMessageConfirmButton);
