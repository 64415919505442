
export const ORM_ENTITY_LOAD_ALL   = "ORM_ENTITY_LOAD_ALL";
export const ORM_ENTITY_LOAD_BATCH = "ORM_ENTITY_LOAD_BATCH";

export const ORM_ENTITY_UPDATE   = "ORM_ENTITY_UPDATE";
export const ORM_ENTITY_CREATE   = "ORM_ENTITY_CREATE";
export const ORM_ENTITY_DELETE   = "ORM_ENTITY_DELETE";
export const ORM_ENTITY_CLEAR_ALL= "ORM_ENTITY_CLEAR_ALL";
export const ORM_ENTITY_CLEAR_ON_CONDITION= "ORM_ENTITY_CLEAR_ON_CONDITION";
export const ORM_CLEAR_DB        = "ORM_CLEAR_DB";

export const ORM_ENTITY_START_LOAD_ALL = "ORM_ENTITY_START_LOAD_ALL";
export const ORM_ENTITY_START_UPDATE   = "ORM_ENTITY_START_UPDATE";
export const ORM_ENTITY_START_CREATE   = "ORM_ENTITY_START_CREATE";
export const ORM_ENTITY_START_DELETE   = "ORM_ENTITY_START_DELETE";

export const ORM_ENTITY_LOAD_ALL_FAILED = "ORM_ENTITY_LOAD_ALL_FAILED";
export const ORM_ENTITY_UPDATE_FAILED   = "ORM_ENTITY_UPDATE_FAILED";
export const ORM_ENTITY_CREATE_FAILED   = "ORM_ENTITY_CREATE_FAILED";
export const ORM_ENTITY_DELETE_FAILED   = "ORM_ENTITY_DELETE_FAILED";

export const ORM_ENTITY_CLEAR_LOAD_ALL  = "ORM_ENTITY_CLEAR_LOAD_ALL";
export const ORM_ENTITY_CLEAR_UPDATE    = "ORM_ENTITY_CLEAR_UPDATE";
