
/* -----------------------------------------------------------------------------------------------------------------
* Functions to request new run state for a client or bank bankAccount including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
import {warnAndResolve} from "gui-common/functions/functions";
import {ormApiBaseUrlMap} from "appConfig/api/apiConfig";
import {genericFetch} from "gui-common/api/apiCommon";
import {entityStateRequested} from "gui-common/requestEntityState/requestEntityStateReducer";

export function requestRunStateToApi(runState, requestedState, acceptedStates) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!runState?.id) {
                warnAndResolve("Request run state called with no item id. Run state: " + runState, resolve);
                return;
            }
            if (!runState.runPeriod?.businessObjectPrototype) {
                warnAndResolve("Request run state called with no businessObjectPrototype. Run state: " + runState, resolve);
                return;
            }
            if (!runState.workflow?.workflowPrototype) {
                warnAndResolve("Request run state called with no workflowPrototype. Run state: " + runState, resolve);
                return;
            }
            let model    = runState.runPeriod ? runState.runPeriod.businessObjectPrototype : "";
            let workflow = runState.workflow  ? runState.workflow.workflowPrototype : "";
            let customText = "Request run state " + requestedState + " for model: " + model + ", workflow: " + workflow + " and id: " + runState.id;

            let requestUrl = ormApiBaseUrlMap.RunState + "/" + runState.id + "/" + requestedState + "?";

            dispatch(genericFetch(
                requestUrl,
                customText,
                requestRunStateResponseHandler(runState, requestedState, acceptedStates),
                urlAddOnFailHandler(runState, requestedState, acceptedStates),
                urlAddOnFailHandler(runState, requestedState, acceptedStates),
                'POST'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function requestRunStateResponseHandler(runState, requestedState, acceptedStates) {
    return (resp) => {
        return (dispatch, getState) => {
            dispatch(entityStateRequested('RunState', runState.id, requestedState,'runState', acceptedStates));
            // dispatch(requestingRunState(model, item, requestedState));
        }
    }
}
function urlAddOnFailHandler(runState, requestedState, acceptedStates) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}


export function requestRunStatesToApi(runStateRequests) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let requestsString = "";
            const requestsArray = [];
            for (const request of runStateRequests) {
                if (!request.runState?.id) {
                    warnAndResolve("Request run state called with no item id. Run state: " + request.runState, resolve);
                    return;
                }
                if (!request.runState.runPeriod?.businessObjectPrototype) {
                    warnAndResolve("Request run state called with no businessObjectPrototype. Run state: " + request.runState, resolve);
                    return;
                }
                if (!request.runState.workflow?.workflowPrototype) {
                    warnAndResolve("Request run state called with no workflowPrototype. Run state: " + request.runState, resolve);
                    return;
                }
                requestsString += "\n(" + request.requestedState + ", " + request.runState.runPeriod.businessObjectPrototype + ", " + request.runState.workflow.workflowPrototype + ", " + request.runState.id + ")";
                requestsArray.push({
                    periodId: request.runState.id,
                    requestedState: request.requestedState,
                    saveWorkflowState: request.saveWorkflowState ? request.saveWorkflowState : null, // Only relevant for RESTART
                })
            }
            let customText = "Request run states (requested state, model, workflow, id): " + requestsString;

            let requestUrl = ormApiBaseUrlMap.RunState + "/run-state-requests?";

            dispatch(genericFetch(
                requestUrl,
                customText,
                requestRunStatesResponseHandler(runStateRequests),
                requestRunStatesFailHandler(runStateRequests),
                requestRunStatesFailHandler(runStateRequests),
                'POST',
                requestsArray
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function requestRunStatesResponseHandler(runStateRequests) {
    return (resp) => {
        return (dispatch, getState) => {
            for (const request of runStateRequests) {
                dispatch(entityStateRequested('RunState', request.runState.id, request.requestedState,'runState', request.acceptedStates));
            }
        }
    }
}
function requestRunStatesFailHandler(runStateRequests) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}
