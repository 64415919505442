import ObWizardClientBasicForm from "features/onboardingWizard/forms/ObWizardClientBasicForm";
import ObWizardCurrenciesForm from "features/onboardingWizard/forms/ObWizardCurrenciesForm";
import ObWizardBaseCurrencyForm from "features/onboardingWizard/forms/ObWizardBaseCurrencyForm";
import ObWizardAccountSettingsForm from "features/onboardingWizard/forms/ObWizardAccountSettingsForm";
import ObWizardSweepAndTopUpForm from "features/onboardingWizard/forms/ObWizardSweepAndTopUpForm";
import ObWizardRuntimeSettingsForm from "features/onboardingWizard/forms/ObWizardRuntimeSettingsForm";
import ObWizardReviewAndSaveForm from "features/onboardingWizard/forms/ObWizardReviewAndSaveForm";
import {setActiveClientThunk} from "gui-common/functions/thunks";
import {push} from "connected-react-router";
import {createSelector} from "reselect";
import {
    activeBankAccountInstitutionsSelector,
    activeCountriesSelector, activeCurrenciesSelector,
    activeFxProvidersSelector,
    operatingUnitsWithFullClientCreateSelector
} from "gui-common/orm/ormSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {findAndSetDefaultId} from "gui-common/wizard/wizardFunctions";
import {runConfigurationConfig} from "appConfig/runConfiguration/runConfigurationConstants";

export const wizardConfig = {
    'onboardingWizard': {
        ormModel: 'Client',
        stepInfo: [
            {
                formComponent: ObWizardClientBasicForm,
                heading: 'clientInfoHeading',
                fields: ["name", "description", "accountManager", "operatingUnitId", "timeZone", "clientRefId", "countryId", "fxShortName", "fxProviderId"],
                fieldLookup: {
                    operatingUnitId : 'operatingUnits',
                    countryId       : 'countries',
                    fxProviderId    : 'fxProviders'
                }
            },
            {
                formComponent: ObWizardCurrenciesForm,
                heading: 'selectCurrencyHeading',
                fields: ["currencies"],
                subFields: ["currencyId"],
                summaryGroupField: "currencyId",
                fieldLookup: {
                    currencyId : 'currencies',
                },
            },
            {
                formComponent: ObWizardBaseCurrencyForm,
                heading: 'selectTopHeading',
                fields: ["topCurrencyId"],
                fieldLookup: {
                    topCurrencyId : 'currencies',
                },
            },
            {
                formComponent: ObWizardAccountSettingsForm,
                heading: "accountInfoHeading",
                fields: ["currencies"],
                subFields: ["name", "clearingNumber", "accountNumber", "institutionId"],
                summaryGroupField: "currencyId",
                fieldLookup: {
                    institutionId : 'institutions',
                    currencyId : 'currencies',
                },
            },
            {
                formComponent: ObWizardSweepAndTopUpForm,
                heading: 'sweepTopupHeading',
                fields: ["currencies"],
                subFields: ["useSweep", "sweepThreshold", "sweepTarget", "useSwapForSweep", "useTopUp", "topUpTarget", "topUpThreshold", "useSwapForTopUp"],
                summaryGroupField: "currencyId",
                excludedSubGroupingValue: "topCurrencyId",
                fieldLookup: {
                    currencyId : 'currencies',
                },
            },
            {
                formComponent: ObWizardRuntimeSettingsForm,
                heading: 'accountSettingsHeading',
                fields: ["clientRunType", "runType", "dayType", "runDay", "seekStrategy", "getBalancesTime", "executeTime", "stopTryingTime", "runOnMondays", "runOnTuesdays", "runOnWednesdays", "runOnThursdays", "runOnFridays", "currencies"],
                subFields: ["getBalancesTime", "executeTime", "stopTryingTime", "minimumTradeSize", "maximumTradeSize", "useSplit", "splitPercent"],
                summaryGroupField: "currencyId",
                excludedSubGroupingValue: "topCurrencyId",
                fieldLookup: {
                    currencyId : 'currencies',
                },
                excludeFieldFromSummaryFunction: {
                    runType         : data => (data.clientRunType !== 'MONTHLY'),
                    dayType         : data => (data.clientRunType !== 'MONTHLY'),
                    runDay          : data => (data.clientRunType !== 'MONTHLY') || (data.dayType !== 'DAY'),
                    seekStrategy    : data => (data.clientRunType !== 'MONTHLY') || (data.dayType !== 'DAY'),
                    runOnMondays    : data => (data.clientRunType !== 'DAILY'  ),
                    runOnTuesdays   : data => (data.clientRunType !== 'DAILY'  ),
                    runOnWednesdays : data => (data.clientRunType !== 'DAILY'  ),
                    runOnThursdays  : data => (data.clientRunType !== 'DAILY'  ),
                    runOnFridays    : data => (data.clientRunType !== 'DAILY'  ),
                }
            },
            {
                formComponent: ObWizardReviewAndSaveForm,
                heading: 'finalStepHeading',
                info: 'finalStepInfo',
                fields:[],
            }
        ],
        formName: 'onboardingWizardForm',
        subFormName: 'currencyAccountForm',
        fileSpecification: 'onboardingWizardFileConfig',
        defaultParametersSelector: createSelector(
            [
                state => activeFxProvidersSelector(state),
                state => activeCountriesSelector(state),
                state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForWizard'}),
                state => selectAppEnvProperty(state, 'availableTimeZones'),
            ],
            (ormFxProviders, ormCountries, operatingUnits, timeZones) => {
                return {
                    fxProviderId    : findAndSetDefaultId(ormFxProviders),
                    countryId       : findAndSetDefaultId(ormCountries),
                    operatingUnitId : findAndSetDefaultId(operatingUnits),
                    timeZone        : findAndSetDefaultId(timeZones),
                    clientRunType   : 'DAILY',
                    runType         : 'RUN',
                    dayType         : 'FIRST',
                    fxEnabled       : true,
                    runOnMondays: true, runOnTuesdays: true, runOnWednesdays: true, runOnThursdays: true, runOnFridays: true,
                }
            }
        ),
        lookupValuesSelector: createSelector(
            [
                state => activeFxProvidersSelector(state),
                state => activeCountriesSelector(state),
                state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForWizard'}),
                state => activeCurrenciesSelector(state),
                state => activeBankAccountInstitutionsSelector(state)
            ],
            (ormFxProviders, ormCountries, operatingUnits, ormCurrencies, ormInstitutions) => {
                return {
                    countries      : ormCountries.map( item => ({...item, name: item.country })),
                    currencies     : ormCurrencies.map(item => ({...item, name: item.currency})),
                    operatingUnits : operatingUnits,
                    fxProviders    : ormFxProviders,
                    institutions   : ormInstitutions,
                    runTypes       : ['DAILY', 'MONTHLY'         ].map(item => ({id: item, name: item})),
                    dayTypes       : ['FIRST', 'LAST', 'DAY'     ].map(item => ({id: item, name: item})),
                    seekStrategies : ['EARLIER', 'LATER', 'EXACT'].map(item => ({id: item, name: item})),
                    runDays        : [...Array(31).keys()].map((item, index) => {return {id: index + 1, name: String(index + 1)}}),
                }
            }
        ),
        validationParamsSelector: createSelector(
            [
            ],
            () => {
                return {}
            }
        ),
        submitTransformer: (formData) => {
            let clientToSubmit = {
                ...formData,
                useLegalEntities: false,
                cashPools       : [{name: "default", description: ""}],
                runConfiguration: getClientRunConfiguration(formData),
            }
            const topFormAccount = formData.currencies.find(account => account.currencyId === formData.topCurrencyId);
            const topAccount = {
                ...topFormAccount,
                creditType: 'UNLIMITED_CREDIT',
                bankAccounts: [],
            };
            for (let account of formData.currencies) {
                if (account.currencyId === formData.topCurrencyId) continue;
                topAccount.bankAccounts.push({
                    ...account,
                    creditType: 'NO_CREDIT',
                    runConfiguration: getAccountRunConfiguration(clientToSubmit, account),
                })
            }
            const legalEntityUnit = {
                fxEnabled    : true,
                bankAccounts : [topAccount],
                name         : formData.name + '(BU)',
                fxProviderId : formData.fxProviderId,
                fxShortName  : formData.fxShortName
            };

            const legalEntity = {
                legalPersonType : 'CORPORATE',
                useLegalEntityUnits: false,
                name            : formData.name + '(LE)',
                countryId       : formData.countryId,
                legalEntityUnits: [legalEntityUnit],
                erpIntegration   : {erpEnabled: false},
            };

            clientToSubmit.legalEntities = [legalEntity];
            console.log("Wizard sending client to API: ", clientToSubmit);

            return clientToSubmit
        },
        onSubmitSuccessThunk: (createdObject) => (dispatch, getState) => {
            dispatch(setActiveClientThunk(createdObject));
            dispatch(push(getState().appConfigState.appStartPath));
        }
    }
}

function getClientRunConfiguration(client) {
    const base = {
        isActive: true,
        getBalancesTime: client.getBalancesTime,
        executeTime    : client.executeTime,
        stopTryingTime : client.stopTryingTime,
    }
    let returnObject = {
        scheduling: [runConfigurationConfig.scheduling.newTemplate],
    }

    if (client.clientRunType === 'DAILY') returnObject.dailyRun =  [{
        ...base,
        runOnMondays   : client.runOnMondays,
        runOnTuesdays  : client.runOnTuesdays,
        runOnWednesdays: client.runOnWednesdays,
        runOnThursdays : client.runOnThursdays,
        runOnFridays   : client.runOnFridays
    }];

    if (client.clientRunType === 'MONTHLY') returnObject.monthlyRun = [{
        ...base,
        runType      : 'RUN',
        dayType      : client.dayType,
        runDay       : client.runDay,
        seekStrategy : client.seekStrategy
    }]
    return returnObject;
}

function getAccountRunConfiguration(client, account) {
    let returnObject = {
        sweepAndTopUp: [
            {
                isActive        : true,
                useSweep        : account.useSweep,
                sweepThreshold  : account.sweepThreshold,
                sweepTarget     : account.sweepTarget,
                useSwapForSweep : account.useSwapForSweep,
                useTopUp        : account.useTopUp,
                topUpTarget     : account.topUpTarget,
                topUpThreshold  : account.topUpThreshold,
                useSwapForTopUp : account.useSwapForTopUp,
                currencyId      : Number(account.currencyId),
            }
        ]
    };
    if (account.minimumTradeSize || account.maximumTradeSize) returnObject.maxMinSplit = [
        {
            isActive: true,
            useFor: 'ALL',
            minimumTradeSize: account.minimumTradeSize,
            maximumTradeSize: account.maximumTradeSize,
            useSplit        : account.useSplit,
            splitPercent    : account.splitPercent,
            currencyId      : Number(account.currencyId),
        }];

    if (!account.getBalancesTime) return returnObject;
    const times = {
        getBalancesTime: account.getBalancesTime,
        executeTime    : account.executeTime,
        stopTryingTime : account.stopTryingTime,
    }
    if (client.clientRunType === 'DAILY')   returnObject.dailyRun   = [{...client.runConfiguration.dailyRun[0], ...times}];
    if (client.clientRunType === 'MONTHLY') returnObject.monthlyRun = [{...client.runConfiguration.monthlyRun[0], ...times}];

    return returnObject;
}
