import au from './australia.png';
import ca from './canada.png';
import ch from './switzerland.png';
import eu from './european-union.png';
import gb from './united-kingdom.png';
import jp from './japan.png';
import nz from './new-zealand.png';
import us from './united-states.png';
import se from './sweden.png';
import no from './norway.png';
import dk from './denmark.png';
import is from './iceland.png';
import ae from './united-arab-emirates.png';
import bg from './bulgaria.png';
import cn from './china.png';
import cz from './czech-republic.png';
import hk from './hong-kong.png';
import hu from './hungary.png';
import il from './israel.png';
import inr from './india.png';
import mx from './mexico.png';
import pl from './poland.png';
import ro from './romania.png';
import ru from './russia.png';
import sa from './saudi-arabia.png';
import sg from './singapore.png';
import th from './thailand.png';
import za from './south-africa.png';

// Downloaded from https://www.flaticon.com/packs/countrys-flags?k=1645632436870
export const roundFlagsIcons = {
    AUD: au,
    CAD: ca,
    CHF: ch,
    EUR: eu,
    GBP: gb,
    JPY: jp,
    NZD: nz,
    USD: us,
    SEK: se,
    NOK: no,
    DKK: dk,
    ISK: is,
    AED: ae,
    BGN: bg,
    CNH: cn,
    CNY: cn,
    CZK: cz,
    HKD: hk,
    HUF: hu,
    ILS: il,
    INR: inr,
    MXN: mx,
    PLN: pl,
    RON: ro,
    RUB: ru,
    SAR: sa,
    SGD: sg,
    THB: th,
    ZAR: za
};
