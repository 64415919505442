import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {selectUserPreferencesActiveClientId} from "gui-common/userSettings/userSettingsSelectors";
import {
    getAndBuildXpoolShallowClientFromOrm,
    getXpoolActiveShallowClientsFromOrm
} from "xpool-gui-common/features/client/clientSelectors";

export const shallowActiveClientsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        return getXpoolActiveShallowClientsFromOrm(session);
    }
);
export const shallowActiveClientSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => selectUserPreferencesActiveClientId(state),
    (session, activeClientId) => {
        return getAndBuildXpoolShallowClientFromOrm(session, activeClientId);
    }
);
export const getShallowClientSelector = () => ormCreateSelector(
    orm, dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, clientId) => {
        return getAndBuildXpoolShallowClientFromOrm(session, clientId);
    }
);
