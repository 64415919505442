import {USER_LOGOUT} from "gui-common/api/webSessionReducer";
import cloneDeep from "lodash/cloneDeep";
import {animateAppContainer} from "gui-common/userMessages/userMessagesFunctions";
import moment from "moment";
import {selectWebSessionRefId} from "gui-common/api/webSessionSelectors";

export const USER_MESSAGE_ADD             = 'USER_MESSAGES_ADD';
export const USER_MESSAGE_UPDATE          = 'USER_MESSAGES_UPDATE';
export const USER_MESSAGE_LOAD_FROM_API   = 'USER_MESSAGE_LOAD_FROM_API';
// export const USER_MESSAGE_CONFIRM_BULK    = 'USER_MESSAGES_CONFIRM_BULK';
export const USER_MESSAGE_CONFIRM         = 'USER_MESSAGES_CONFIRM';
export const USER_MESSAGE_CLEAR_ANIMATION = 'USER_MESSAGE_CLEAR_ANIMATION';
export const USER_MESSAGE_EXIT_ANIMATION  = 'USER_MESSAGE_EXIT_ANIMATION';
export const USER_MESSAGE_CLEAR_ALL       = 'USER_MESSAGE_CLEAR_ALL';

export function addUserMessage(model, message) {
    return (dispatch, getState) => {
        const loggedInUserId = selectWebSessionRefId(getState());
        if (loggedInUserId && (loggedInUserId === message.userId)) message.requireConfirmation = true;
        dispatch(addUserMessageAction(model, message));
    }
}

function addUserMessageAction(model, message) { // parameter model not used but must follow same structure as orm CRUD methods to work with webSocketThunks
    if (message.type === undefined) {
        console.warn("User message added no type");
        return {type: 'NO_TYPE'};
    }
    if ((message.textPath === "") && (message.plainText === "")) {
        console.warn("User message added with no text path or plainText");
        return {type: 'NO_TYPE'};
    }
    if ((message.type !== "info") && (message.type !== "warning") && (message.type !== "error")) {
        console.warn("User message added with invalid type parameter: " + message.type);
        return {type: 'NO_TYPE'};
    }
    return {type: USER_MESSAGE_ADD, payload: message};
}
export function updateUserMessage(model, message) { // parameter model not used but must follow same structure as orm CRUD methods to work with webSocketThunks
    return {type: USER_MESSAGE_UPDATE, payload: message};
}

/*
export function bulkConfirmUserMessage(messages, confirmedDateTime) {
    if (!messages || !confirmedDateTime) {
        console.error("Invalid parameters to confirmUserMessage");
        return {type: 'NO_TYPE'};
    }
    return {type: USER_MESSAGE_CONFIRM_BULK, payload: {messages: messages, confirmedDateTime: confirmedDateTime}};
}
*/
export function confirmUserMessage(message) {
    if (!message) {
        console.error("Invalid parameters to confirmUserMessage");
        return {type: 'NO_TYPE'};
    }
    return {type: USER_MESSAGE_CONFIRM, payload: message};
}
export function clearUserMessageAnimation(messageId) {
    if (messageId === undefined) {
        console.error("Invalid parameters to clearUserMessageAnimation");
        return {type: 'NO_TYPE'};
    }
    return {type: USER_MESSAGE_CLEAR_ANIMATION, payload: messageId};
}
export function setExitAnimation(messageId, confirmedToApi) {
    if (messageId === undefined) {
        console.error("Invalid parameters to setExitAnimation");
        return {type: 'NO_TYPE'};
    }
    return {type: USER_MESSAGE_EXIT_ANIMATION, payload: {messageId, confirmedToApi}};
}
export function clearUserMessages() {
    return {type: USER_MESSAGE_CLEAR_ALL};
}
export function loadUserMessagesFromApi(items) {
    return {type: USER_MESSAGE_LOAD_FROM_API, payload: items};
}

export const getInitialState = () => [];

export function userMessagesReducer(state = getInitialState(), action)
{
    let newState;
    switch (action.type) {
        case USER_MESSAGE_CLEAR_ALL:
        case USER_LOGOUT:
            return [];
        case USER_MESSAGE_ADD:
            if (action.payload === undefined) return state;
/*
            if (action.payload.id && state.find(item => item.id === action.payload.id)) {  // copy received from socket
                console.log("Disregard socket copy of user message ", action.payload.id);
                return state;
            }
*/
            console.log("User message added ", action.payload);

            newState = [...state, {
                ...action.payload,
                id: (action.payload.id ? action.payload.id : ("internal-" + (state.length + 1))),
                nextAnimation: (action.payload.requireConfirmation && !action.payload.confirmedDateTime) ? "userMessageEnter" : undefined,
                displayPanel: (action.payload.requireConfirmation && !action.payload.confirmedDateTime),
            }];
            return newState;
        case USER_MESSAGE_LOAD_FROM_API:
            newState = [];
            for (let item of action.payload) {
                newState.push(item);
                /*
                                if (item.requireConfirmation && !item.confirmedDateTime) newState.push({...item, nextAnimation: "userMessageEnter"});
                                else                                                     newState.push(item);
                */
            }
            return newState;

        case USER_MESSAGE_UPDATE:
            if (action.payload === undefined) return state;
            let indexToUpdate = undefined;
            for (let index in state) {
                if (state[index].id !== action.payload.id) continue;
                indexToUpdate = index;
            }
            if (!indexToUpdate) return state;

            newState = cloneDeep(state);
            if (!newState[indexToUpdate].confirmedDateTime && action.payload.confirmedDateTime && newState[indexToUpdate].displayPanel) { // Animate close on displayed message
                newState[indexToUpdate].nextAnimation = "userMessageExit";
                newState[indexToUpdate].confirmedDateTime = action.payload.confirmedDateTime;
                animateAppContainer(action.payload.id, 'appContainerSlideUp');
                return newState;
            }
            newState[indexToUpdate] = {...newState[indexToUpdate], ...action.payload};
            return newState;

        case USER_MESSAGE_CONFIRM:
            if (action.payload === undefined) return state;
            newState = cloneDeep(state);
            for (let item of newState) {
                if (item.id !== action.payload.id) continue;

                if (!item.confirmedDateTime) {
                    item.confirmedDateTime = moment();
                    console.log("User message confirmed: " + item.textPath + " " + item.plainText + ". ID: " + action.payload.id);
                }
                item.displayPanel = false;
                return newState;
            }
            return state;
        case USER_MESSAGE_CLEAR_ANIMATION:
            if (action.payload === undefined) return state;
            newState = cloneDeep(state);
            for (let item of newState) {
                if (item.id === action.payload) {
                    item.nextAnimation = "";
                    return newState;
                }
            }
            return state;
        case USER_MESSAGE_EXIT_ANIMATION:
            if (action.payload === undefined) return state;
            newState = cloneDeep(state);
            let messageFound = false;
            for (let item of newState) {
                if (item.id === action.payload.messageId) {
                    item.nextAnimation = "userMessageExit";
                    item.confirmedToApi = action.payload.confirmedToApi;
                    messageFound = true;
                }
                else {
                    if (messageFound && (item.requireConfirmation && !item.confirmedDateTime)) item.nextAnimation = "userMessageSlideUp";
                }
            }
            return newState;
        default:
            return state;
    }
}
