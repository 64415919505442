import {Text, View} from "@react-pdf/renderer";
import React from "react";

export function XpReportPageHeader ({styleSheet, header, period, printed}) {
    return (
        <View>
            <View style={styleSheet.header}>
                <Text>{header}</Text>
            </View>
            <View style={styleSheet.reportConfig.line} >
                <Text style={styleSheet.reportConfig.period} >{period}</Text>
                <Text style={styleSheet.reportConfig.printed}>{printed}</Text>
            </View>
        </View>
    )
}

export function XpReportPageNumber ({styleSheet, translate}) {
    return (
        <Text fixed style={styleSheet.pageNumber} render={({ pageNumber, totalPages }) => (
            translate('report.page') + pageNumber + translate('report.of') + totalPages
        )}/>
    )
}
export function XpReportPageFooter ({styleSheet, showText}) {
    return (
        <Text fixed style={styleSheet.pageFooter} >{showText}</Text>
    )
}

function XpReportFirstHeader ({styleSheet, name, aggregators, translate, langPath, langParams, hideName}) {
    return (
        <View style={styleSheet.header}>
            <Text style={styleSheet.nameCell} wrap={false}>{hideName ? "" : name}</Text>
            {Object.keys(aggregators).map(key => {
                return (
                    <Text style={styleSheet.contentCell} key={key}>{translate(langPath + '.' + key, langParams)}</Text>
                )})}
        </View>
    )
}

function XpReportSubHeader ({styleSheet, name, aggregators, padding}) {
    return (
        <View style={styleSheet.header} wrap={false}>
            <Text style={[styleSheet.nameCell, {paddingLeft: padding}]}>{name}</Text>
            {Object.keys(aggregators).map(key => {
                return (
                    <Text style={styleSheet.contentCell} key={key}> </Text>
                )})}
        </View>
    )
}
function XpReportLastLevel({styleSheet, bucket, formatter, padding, keyPath}) {
    if (!bucket) return null;
    return (
        <View style={styleSheet.line} wrap={false} key={keyPath + '-' + bucket.name}>
            <Text style={[styleSheet.nameCell, {paddingLeft: padding}]}>{bucket.name}</Text>
            {Object.keys(bucket.aggregates).map(key => {
                return (
                    <Text style={styleSheet.contentCell} key={key}>{formatter(bucket.aggregates[key])}</Text>
                )
            })}
        </View>
    )
}
function XpReportSummary({styleSheet, bucket, formatter, padding, aggregators, translate, langParams, langPath}) {
    if (!bucket) return null;
    if (!bucket.aggregates || (Object.keys(bucket.aggregates).length === 0)) return null;
    const nameText = (bucket.subDimension && bucket.subDimension.lastDimension) ? " " : translate(langPath + '.total', langParams) + ' ' + bucket.name;
    return (
        <View style={styleSheet.summary}  wrap={false} key={'summary-' + bucket.name}>
            <Text style={[styleSheet.summaryNameCell, {paddingLeft: padding}]}>{nameText}</Text>
            {Object.keys(aggregators).map(key => {
                const text = bucket.aggregates[key] ? formatter(bucket.aggregates[key]) : " ";
                return (
                    <Text style={styleSheet.contentCell} key={key}>{text}</Text>
                )
            })}
        </View>
    )
}
function XpReportSubLevel({styleSheet, reportData, formatter, aggregators, padding, translate, langParams, langPath, keyPath}) {
    if (!reportData || !reportData.subDimension) return null;

    return (
        Object.keys(reportData.subDimension.buckets).map(instance => {
                const thisBucket = reportData.subDimension.buckets[instance];
                const thisKeyPath = keyPath + '-' + instance;
                if (thisBucket.subDimension) return (
                    <View  key={thisKeyPath} wrap={(reportData.subDimension.levelNumber > 2)}>
                        <XpReportSubHeader
                            styleSheet  = {styleSheet.midLevel}
                            name        = {thisBucket.name}
                            aggregators = {aggregators}
                            padding     = {padding}
                            key         = {thisKeyPath+'-subHeader'}
                        />
                        <XpReportSubLevel
                            styleSheet  = {styleSheet}
                            reportData  = {thisBucket}
                            formatter   = {formatter}
                            aggregators = {aggregators}
                            padding     = {padding+20}
                            translate   = {translate}
                            langPath    = {langPath}
                            langParams  = {langParams}
                            keyPath     = {thisKeyPath}
                            key         = {thisKeyPath+'-subLevel'}
                        />
                        <XpReportSummary
                            styleSheet  = {styleSheet.midLevel}
                            formatter   = {formatter}
                            bucket      = {thisBucket}
                            aggregators = {aggregators}
                            padding     = {padding}
                            translate   = {translate}
                            langPath    = {langPath}
                            langParams  = {langParams}
                            key         = {thisKeyPath+'-summary'}
                        />
                    </View>
                );
                else return (
                    <XpReportLastLevel
                        styleSheet  = {styleSheet.lastLevel}
                        bucket      = {thisBucket}
                        aggregators = {aggregators}
                        formatter   = {formatter}
                        padding     = {padding}
                        keyPath     = {thisKeyPath}
                        key         = {thisKeyPath+'-lastLevel'}
                    />
                )
            }
        )
    )
}

export function XpReportContent({styleSheet, reportData, formatter, dimensions, langPath, langParams, translate, hideFirstHeaderName, wrapFirstLevel}) {

    if (!reportData || !dimensions || (dimensions.length === 0)) return null;

    const aggregators = dimensions[dimensions.length-1].aggregators;
    if (!aggregators || (Object.keys(aggregators).length === 0)) return null;

    return (
        Object.keys(reportData.buckets).map((instance, index) => {
            const thisBucket = reportData.buckets[instance];
            const newPage = (index !== 0 && !wrapFirstLevel);
            return (
                <View  key={instance} wrap break={newPage}>
                    <XpReportFirstHeader
                        styleSheet  = {styleSheet.firstLevel}
                        name        = {thisBucket.name}
                        aggregators = {aggregators}
                        translate   = {translate}
                        langPath    = {langPath}
                        langParams  = {langParams}
                        hideName    = {hideFirstHeaderName}
                    />
                    <XpReportSubLevel
                        styleSheet  = {styleSheet}
                        reportData  = {thisBucket}
                        formatter   = {formatter}
                        aggregators = {aggregators}
                        padding     = {20}
                        translate   = {translate}
                        langPath    = {langPath}
                        langParams  = {langParams}
                        keyPath     = {instance}
                    />
                    <XpReportSummary
                        styleSheet  = {styleSheet.firstLevel}
                        formatter   = {formatter}
                        bucket      = {thisBucket}
                        aggregators = {aggregators}
                        padding     = {0}
                        translate   = {translate}
                        langPath    = {langPath}
                        langParams  = {langParams}
                    />

                </View>
            )})
    )
}

export function ReportConfigCheckbox({parameter, reportConfig, setReportConfig, label}) {
    return (
        <div style={{display: 'inline-block'}}>
            <input
                type="checkbox"
                checked={reportConfig[parameter]}
                onChange={e => {
                    let newConfig = {...reportConfig};
                    newConfig[parameter] = e.target.checked;
                    setReportConfig(newConfig);
                }}
            />
            <label className="labelCheckbox">{label}</label>
        </div>
    )
}

export function ReportConfigDropdown ({parameter, nameParameter, reportConfig, setReportConfig, selectItems, label, template}) {
    if (!selectItems || !parameter || !reportConfig || !setReportConfig) return null;
    return (
    <div style={{display: 'inline-block'}}>
            <label style={{display: 'inline-block', marginRight: '10px'}}>{label}</label>
            <div style={{display: 'inline-block'}}>
                <select
                    value={reportConfig[parameter]}
                    style={{minWidth: '200px', maxWidth: '400px'}}
                    onChange={e => {
                        let newConfig = {...reportConfig};
                        newConfig[parameter]     = e.target.value;
                        newConfig[nameParameter] = (e.target.value === "") ? template : selectItems.find(item => item.id === Number(e.target.value)).name;
                        setReportConfig(newConfig);
                    }}
                >
                    <option value="">{template}</option>
                    {selectItems.map((item, index) => (
                        <option
                            value={item.id}
                            key={index}
                        >
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}
