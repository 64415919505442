import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";
import TextField from "@mui/material/TextField";
import React from "react";

function XpFormTextInput (props) {
    return (
        <XpFormLabeledInput
            {...props}
            controlProps={{
                autoComplete: "on",
            }}
            CompToRender={TextField}
            getValueFromEvent={event => event.target.value}
        />
    )
}
XpFormTextInput.propTypes = xpFormBasePropTypes;
export default XpFormTextInput
