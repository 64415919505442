import {auditConfig} from "gui-common/audit/auditConstants";
import {createSelector as ormCreateSelector} from "redux-orm";
import {createSelector} from "reselect";
import orm from "gui-common/orm/ormModels";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";


export const auditRootObjectsSelector = createSelector(
    [
        state => {
            const rootModel = state.xpFormState.auditViewFilterForm?.rootModel;
            if (!rootModel) return [];
            const rootObjectsSelector = auditConfig[rootModel].rootProps.selector;
            if (!rootObjectsSelector) return [];
            return rootObjectsSelector(state, auditConfig[rootModel].rootProps.selectorProps);
        }
    ],
    (rootObjects) => {
        return rootObjects;
    }
);
export const auditEntriesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.AuditEntry.all().orderBy('id', 'desc').toModelArray().map(item => item.ref);
//        console.log("Audit entries: ", returnArray);
        return returnArray;
    }
);
export const auditEntrySelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectedId : undefined,
    (session, selectedId) => {
        return session.AuditEntry.idExists(selectedId) ? session.AuditEntry.withId(selectedId).ref : undefined;
    }
);
export const activeAuditRootModelsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.AuditRootModel.filter({enabled: true}).orderBy('sortOrder', 'asc').toRefArray();
        return returnArray;
    }
);
export const activeAuditActionTypesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.AuditUserActionType.filter({enabled: true}).toRefArray();
        return returnArray.map(item => ({...item, trKey: 'auditUserActionTypes.' + item.name + '.label'}));
    }
);
export const activeAuditActionTypesMapSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    activeAuditActionTypesSelector,
    (session, actionTypes) => {
        let returnMap = {};
        actionTypes.forEach(type => {
            returnMap[type.prototype] = {...type};
        })
        return returnMap;
    }
);
