import React, { Component } from 'react'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {
    formatAmount
} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";




class FormattedAmount extends Component {

    setCurrPrefix() {
        if (this.props.currencyPrefix === "") return "";
        return this.props.currencyPrefix + " ";
    }

    render() {
        return (
            <div className={this.props.className}><p style={{margin:"0"}}>
                {this.setCurrPrefix() + formatAmount(this.props.amount, this.props.decDenLangState)}
            </p></div>
        );
    }
}

FormattedAmount.propTypes = {
    amount:     PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currencyPrefix: PropTypes.string,
    className:      PropTypes.string,

};

FormattedAmount.defaultProps = {
    currencyPrefix: "",
    className:      "inputNumber",
};

const mapStateToProps = (state) => {
    return {
        decDenLangState : selectDecDenLangState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormattedAmount);


